import icon1 from "./assets/webp/users.webp";
import icon2 from "./assets/webp/award.webp";
import icon3 from "./assets/webp/file.webp";
import icon4 from "./assets/webp/check-circle.webp";

import img1 from "./assets/testimonials/1.webp";
import img2 from "./assets/testimonials/2.webp";
import img3 from "./assets/testimonials/3.webp";
import img4 from "./assets/testimonials/4.webp";
import img5 from "./assets/testimonials/5.webp";
import img6 from "./assets/testimonials/6.webp";
import img7 from "./assets/testimonials/7.webp";
import img8 from "./assets/testimonials/8.webp";

export const tabData = [
  {
    id: "0",
    title: "Live Classes",
    description:
      "Live streamed classes with whiteboards; chat feature and 3D models.",
    icon: icon1,
  },
  {
    id: "1",
    title: "Experienced Instructors",
    description:
      "Top Instructors from leading schools share complete notes, answer questions and tips to ace exams.",
    icon: icon2,
  },
  {
    id: "2",
    title: "Past Paper Revision",
    description:
      "10 years of past papers along with marking schemes and examiner reports covered!",
    icon: icon3,
  },
  {
    id: "3",
    title: "Complete Resources",
    description:
      "Notes with formulas, quick guides and summaries to make the CIE experience stress free.",
    icon: icon4,
  },
];

export const testimonials = [
  {
    id: "0",
    image: img1,
  },
  {
    id: "1",
    image: img2,
  },
  {
    id: "2",
    image: img3,
  },
  {
    id: "3",
    image: img4,
  },
  {
    id: "4",
    image: img5,
  },
  {
    id: "5",
    image: img6,
  },
  {
    id: "6",
    image: img7,
  },
  {
    id: "7",
    image: img8,
  },
];
