import React from "react";
import { MenuOutlined } from "@ant-design/icons";
import styles from "./../styles.module.css";

const MobileMenuButton = ({ onClick }) => {
  return (
    <div className={styles.mobile_menu}>
      <MenuOutlined className="font-22 cursor-pointer" onClick={onClick} />
    </div>
  );
};

export default MobileMenuButton;
