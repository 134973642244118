import React, { useCallback, useMemo } from "react";

import { Row, Col, Space, Button } from "antd";
import slugify from "@sindresorhus/slugify";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import star from "src/modules/parent/assets/img/star2.png";
import subject from "../../assets/webp/book.webp";
import play from "src/modules/parent/assets/img/play-white.png";
import superTutor from "src/modules/parent/assets/img/super-tutor.png";
import { boardImages } from "./boardImg";

import { getGradeTagsArray, isLocal } from "src/modules/parent/utils";

import styles from "./styles.module.css";

const isPriceAvailable = (teacher) =>
  isLocal() ? teacher?.price_per_hour_pkr : teacher?.price_per_hour_usd;

const getPrice = (teacher) => {
  if (!isLocal()) {
    return `USD ${teacher?.price_per_hour_usd || 0}/hr`;
  } else {
    return `PKR ${teacher?.price_per_hour_pkr || 0}/hr`;
  }
};

const GradeItem = ({ text }) => (
  <div className={styles.gradeTag}>
    <Text
      color={"rgba(36, 57, 126, 1)"}
      size={"XS"}
      font={"SEMIBOLD"}
      title={text}
    />
  </div>
);

const getBoards = (boardName) => {
  if (boardName) {
    switch (boardName) {
      case "cambridge":
        return boardImages.cambridge;

      case "pearson edexcel":
        return boardImages.PearsonEdExcel;

      case "oxford aqa":
        return boardImages.OxfordAQA;

      case "ib":
        return boardImages.IB;

      case "ocr":
        return boardImages.OCR;

      case "vce":
        return boardImages.VCE;

      default:
        return "";
    }
  }
};

const CardItem = ({
  item = {},
  isButtonDisabled = false,
  handleShowModal = () => {},
  handleChat = () => {},
  handleBookDemo = () => {},
  viewPackageDetailHandler = () => {},
}) => {
  const titleRoute = useCallback((title) => (title ? slugify(title) : ""), []);
  const getUrl = useCallback(
    (title, id) => {
      const gotoUrl = `/courses/${titleRoute(title)}/${id}`;

      return gotoUrl;
    },
    [titleRoute]
  );

  const getTeacherBio = useMemo(() => {
    const teacher = item?.teacher;

    if (teacher && teacher?.olevel_bio && teacher?.olevel_bio.length > 0) {
      return (
        <ul className={styles.list}>
          {item?.teacher?.olevel_bio.map((item, index) => (
            <li key={index.toString()}>
              {
                <Text
                  color={"#646464"}
                  font={"LIGHTER"}
                  size={"S"}
                  lHeight={"M"}
                  className={styles.elipsis}
                  title={item}
                />
              }
            </li>
          ))}
        </ul>
      );
    }
  }, [item?.teacher]);

  const getInitials = (fullName) => {
    const nameParts = fullName.split(" ");

    const firstName = nameParts[0];
    const middleName = nameParts[1];
    const initials = nameParts
      .slice(1)
      .map((name) => name.charAt(0).toUpperCase() + ". ");

    if (isMobile) {
      if (nameParts.length > 1) {
        return `${firstName} ${initials.join("")}`;
      } else {
        return firstName;
      }
    } else {
      if (nameParts.length > 2) {
        return `${firstName} ${nameParts[1]} ${nameParts[nameParts.length - 1]
          .charAt(0)
          .toUpperCase()}.`;
      } else if (nameParts.length > 1) {
        return `${firstName} ${middleName}`;
      } else {
        return firstName;
      }
    }
  };

  const getTeacherRatings = useMemo(() => {
    if (item && item?.teacher && item?.teacher?.ratings) {
      const ratings = item?.teacher?.ratings;
      return Number(ratings).toFixed(1);
    } else {
      return "0";
    }
  }, [item]);

  const getGrades = useMemo(() => {
    if (item && item?.grades) {
      const grades = [...item?.grades];
      const sortedGrades = getGradeTagsArray(grades);

      return sortedGrades.map((item, index) => (
        <GradeItem key={index.toString()} text={item} />
      ));
    }
  }, [item]);

  return (
    <Col span={24}>
      <a
        className={styles.card}
        href={getUrl(item?.title, item?.id)}
        onClick={(e) => {
          e.preventDefault();
          viewPackageDetailHandler(e, item?.title, item?.id);
          e.stopPropagation();
        }}
      >
        <div className={styles.cardStyle}>
          <div className={styles.teacherName} align={"start"}>
            <div
              onClick={(e) => {
                e.preventDefault();
                handleShowModal(item?.teacher?.video_id);
                e.stopPropagation();
              }}
              className={styles.imageDiv}
            >
              <img src={item?.teacher?.picture} alt={"cover"} />

              <div className={styles.playIcon}>
                <img src={play} alt={"playIcon"} />
              </div>

              <div className={styles.reviews}>
                <Space>
                  <img src={star} alt="star" />

                  <Text
                    color={"#FFF"}
                    className={"mt-1"}
                    size={"S"}
                    title={getTeacherRatings}
                  />
                </Space>
              </div>
            </div>

            <div className={styles.subjects}>
              <Row
                gutter={[0, 5]}
                className="w-100"
                align={"middle"}
                justify={"space-between"}
              >
                <Space>
                  <Text
                    color={"#24397E"}
                    size={"M2"}
                    font={"SEMIBOLD"}
                    title={getInitials(item?.teacher?.name)}
                  />

                  {item?.teacher?.super_tutor > 0 && (
                    <img src={superTutor} alt={"superTutorBadge"} />
                  )}
                </Space>

                {isPriceAvailable(item?.teacher) && (
                  <div className={styles.price}>
                    <Text
                      color={"white"}
                      size={"XS"}
                      font={"SEMIBOLD"}
                      title={getPrice(item?.teacher)}
                    />
                  </div>
                )}
              </Row>

              <Row gutter={[0, 5]} align={"middle"} justify={"space-between"}>
                <Space>
                  <img src={subject} alt={"subject"} />

                  <Text
                    color={"#656565"}
                    size={"S"}
                    font={"SEMIBOLD"}
                    lHeight={"S"}
                    title={item?.subjects
                      .map((subject) => subject.subject)
                      .join(", ")}
                  />
                </Space>

                {item?.teacher?.boards && item?.teacher?.boards.length > 0 && (
                  <Space>
                    {item?.teacher?.boards.map((item, index) => (
                      <img
                        key={index}
                        width={18}
                        src={getBoards(item)}
                        alt={item}
                      />
                    ))}
                  </Space>
                )}
              </Row>

              {item?.grades && item?.grades.length > 0 && (
                <Row>
                  <Space>{getGrades}</Space>
                </Row>
              )}
            </div>
          </div>

          <div className={"mt-13 mb-10"}>{getTeacherBio}</div>

          {!isButtonDisabled && (
            <Row>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleBookDemo(item);
                  e.stopPropagation();
                }}
                loading={false}
                className={styles.searchButton}
              >
                <Text
                  color={"#fff"}
                  size={"S"}
                  font={"SEMIBOLD"}
                  title={"Book a Free Demo"}
                />
              </Button>

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleChat(item?.teacher?.id);
                  e.stopPropagation();
                }}
                loading={false}
                className={styles.learnMoreButton}
              >
                <Text
                  color={"rgba(0, 94, 255, 1)"}
                  size={"S"}
                  font={"SEMIBOLD"}
                  title={"Chat"}
                />
              </Button>
            </Row>
          )}
        </div>
      </a>
    </Col>
  );
};

export default React.memo(CardItem);
