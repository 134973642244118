import React, { forwardRef } from "react";
import { Input } from "antd";
import Text from "../text";
import styles from "./styles.module.css";

const TextInput = forwardRef(
  (
    {
      label = "",
      placeholder = "",
      required = false,
      style = {},
      value,
      allowClear = false,
      type = "text",
      onChange,
      onBlur,
      className = "",
      disabled = false,
      multiline = false,
      name="",
      rows = 5,
      suffix = null,
      prefix = null,
      handleKeyPress = () => {},
      maxLength = null,
      error = false,
      onKeyUp = () => {},
      onPressEnter = () => {},
    },
    ref
  ) => {
    return (
      <div className={"input_container"}>
        {label && <p className={styles.input_label}>{label}</p>}
        {multiline ? (
          <Input.TextArea
            name={name}
            rows={rows}
            type={type}
            style={style}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            onKeyDown={handleKeyPress}
            allowClear={allowClear}
            placeholder={placeholder}
            suffix={suffix}
            onKeyUp={onKeyUp}
            prefix={prefix}
            onPressEnter={onPressEnter}
            className={`${styles.textarea_input} ${className}`}
            maxLength={maxLength}
            ref={ref}
          />
        ) : type === "password" ? (
            <Input.Password
              name={name}
            suffix={suffix}
            prefix={prefix}
            type={type}
            style={style}
            value={value}
            onKeyDown={handleKeyPress}
            disabled={disabled}
            onKeyUp={onKeyUp}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            allowClear={allowClear}
            placeholder={placeholder}
            onPressEnter={onPressEnter}
            className={`${styles.text_input} ${className}`}
            maxLength={maxLength}
            ref={ref}
          />
        ) : (
              <Input
            name={name}
            suffix={suffix}
            prefix={prefix}
            type={type}
            style={style}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            onKeyDown={handleKeyPress}
            onKeyUp={onKeyUp}
            onPressEnter={onPressEnter}
            allowClear={allowClear}
            placeholder={placeholder}
            className={`${styles.text_input} ${className}`}
            maxLength={maxLength}
            ref={ref}
          />
        )}

        {error && (
          <Text className="mt-8" color="RED" size="XS">
            {error}
          </Text>
        )}
      </div>
    );
  }
);

export default TextInput;
