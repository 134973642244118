import React, { useState, useCallback, useEffect, useRef } from "react";

import { isBrowser } from "react-device-detect";
import TiktokPixel from "tiktok-pixel";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import slugify from "@sindresorhus/slugify";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";

import {
  BannerComponent,
  CourseFilter,
  Testimonials,
  FloatingButton,
} from "./components";
import { RequestDemoModal } from "src/modules/parent/pages/home/components/modal";
import OurPartners from "src/modules/parent/components/partners";
import ContactModal from "src/modules/parent/components/modal/contactModal";
import Footer from "src/modules/parent/components/footer";
import Header from "src/modules/parent/components/header";
import { testimonials } from "./staticData";
import Text from "src/modules/parent/components/text";
import { checkIsAuthenticated, getTimeSlots } from "src/modules/parent/utils";
import {
  epChatWithTutorCourseCard,
  epContactBtnWhatsapp,
  examPrepBookDemoHero,
  trackEvents,
} from "src/modules/parent/utils/events/query";

import {
  tiktok_pixel_id,
  fb_pixel_id_olevel,
} from "src/modules/parent/config/constant";
import YoutubeModal from "../../components/modal/youtube-modal";
import { examPrepLinks } from "../../utils/links";
import actionTypes from "../../store/action-types";
import { recommendedNotification } from "../../utils/notificationData";

import * as actions from "src/modules/parent/store/actions";

import styles from "./styles.module.css";

TiktokPixel.init(tiktok_pixel_id);
ReactPixel.init(fb_pixel_id_olevel);

const whatsappMobile = "https://wa.me/";

const NewExamPrepPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { packageReducer } = useSelector((state) => state);
  const { olevelSubjectDown } = packageReducer;
  const action = bindActionCreators(actions, dispatch);
  const [packageDetails, setPackageDetails] = useState(null);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [timeSlots, setTimeSlots] = useState(null);
  const [showContactModal, setShowContactModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [dropdownData, setDropdownData] = useState(null);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const myRef1 = useRef(null);

  useEffect(() => {
    if (olevelSubjectDown && !olevelSubjectDown.length) {
      action.getOlevelSubjects({ ...selectedItem }, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [olevelSubjectDown]);

  useEffect(() => {
    if (state) {
      const params = {};

      if (state.board) {
        params.boards = [state.board];
      }

      if (state.grade) {
        params.grades = [state.grade];
      }

      if (state.subject) {
        params.subjects = [state.subject];
      }

      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: recommendedNotification,
      });
      setSelectedItem(params);

      if (state.notFound) {
        onSubmit();
      }

      if (state.teachers && state.teachers.length) {
        setTeachers(state.teachers);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state]);

  const handleWhatsApp = () => {
    window.open(`${whatsappMobile}923460086793`);
  };

  const setModalAndMedia = (videoId) => {
    setShowModal(true);
    setModalData({ url: videoId });
  };

  const navigateToChat = (id) => {
    trackEvents(epChatWithTutorCourseCard);

    if (checkIsAuthenticated()) {
      navigate(`/chat/${id}`);
    } else {
      action.setRegisterData({
        show: true,
        type: "teacherChat",
        chat: {
          teacherId: id,
        },
      });
    }
  };

  const navigateToDetails = (e, title, id) => {
    const slugTitle = title ? slugify(title) : "";
    const route = `/courses/${slugTitle}/${id}`;

    trackEvents(examPrepBookDemoHero);
    navigate(route);
  };

  const handleClickContact = useCallback(() => {
    trackEvents(epContactBtnWhatsapp);

    if (!isBrowser) handleWhatsApp();
    else setShowContactModal(true);
  }, []);

  const handleItemSelect = useCallback(
    ({ name, label }) => {
      setSelectedItem((prev) => ({
        ...prev,
        [name]: prev[name]?.includes(label) ? [] : [label],
      }));

      setShowSelectModal(false);

      if (name === "boards" || name === "grades") {
        action.getOlevelSubjects({ ...selectedItem, [name]: [label] }, true);

        setSelectedItem((prev) => ({ ...prev, subjects: [] }));

        if (myRef1 && myRef1.current) {
          myRef1.current.scrollBy({ left: 100, behavior: "smooth" });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItem]
  );

  const onSubmit = useCallback(() => {
    setIsLoading(true);

    const payload = {
      ...selectedItem,
      limit: 6,
    };

    actions
      .getOlevelTeachers(payload)
      .then((response) => {
        setIsLoading(false);
        setTeachers(response);
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: recommendedNotification,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setTeachers([]);
      });
  }, [dispatch, selectedItem]);

  const handleBookADemo = useCallback((data) => {
    if (data && data?.time_slot) {
      const time_slots = getTimeSlots(data?.time_slot);

      setPackageDetails(data);
      setTimeSlots(time_slots);
      setShowDemoModal(true);
    }
  }, []);

  const handleClear = useCallback(
    (name) => {
      const cloneSelectedItems = { ...selectedItem };

      delete cloneSelectedItems[name];

      setSelectedItem(cloneSelectedItems);
    },
    [selectedItem]
  );

  const onSelect = useCallback(
    (item) => {
      setShowSelectModal(true);

      setDropdownData({
        ...item,
        data: item?.name === "subjects" ? olevelSubjectDown : item.data,
      });
    },
    [olevelSubjectDown]
  );

  return (
    <div>
      {modalData && showModal && (
        <YoutubeModal
          show={showModal}
          url={modalData.url}
          setShow={setShowModal}
        />
      )}

      <Header />

      <BannerComponent
        isFilter
        show={showSelectModal}
        onSelect={onSelect}
        isLoading={isLoading}
        selectedItem={selectedItem}
        setShow={setShowSelectModal}
        handleClear={handleClear}
        onSearch={onSubmit}
        handleItemSelect={handleItemSelect}
        dropdownData={dropdownData}
      />

      <div ref={myRef1} className="app-container mt-30">
        <CourseFilter
          data={teachers}
          selectedItem={selectedItem}
          handleContact={handleClickContact}
          handleProfileVideo={setModalAndMedia}
          viewDetails={navigateToDetails}
          handleChat={navigateToChat}
          handleBookDemo={handleBookADemo}
        />
      </div>

      <div className={styles.hr} />

      <div className={"mb-30 mt-30"}>
        <Text
          size={"XXL"}
          center
          font={"SEMIBOLD"}
          lHeight={"S"}
          title={"Testimonials"}
        />

        <Testimonials data={testimonials} />
      </div>

      <div className={styles.hr} />

      <OurPartners />

      <Footer links={examPrepLinks} />

      <FloatingButton handlePress={handleClickContact} />

      <ContactModal
        show={showContactModal}
        title={"Contact Representative"}
        name={"Muhammad Taha Arif:"}
        color={"#2CA58D"}
        number={"+923460086793"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal(false)}
      />

      {showDemoModal && (
        <RequestDemoModal
          isVisible={showDemoModal}
          onCancel={() => setShowDemoModal(false)}
          packageData={packageDetails}
          timeSlots={timeSlots}
        />
      )}
    </div>
  );
};

export default NewExamPrepPage;
