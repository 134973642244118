import {
  SEND_MSG,
  GET_INBOX,
  GET_MESSAGES,
  GET_CHAT_COUNT,
} from "src/modules/parent/config/api";
import axiosInstance from "src/modules/parent/config/axios";
import actionTypes from "../../action-types";

export function sendMessage(data) {
  return new Promise((resolve, reject) => {
    axiosInstance.post(SEND_MSG, data).then(resolve).catch(reject);
  });
}

export function getTeacherInbox(senderId, receiverId) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`${GET_INBOX}?sender_id=${senderId}&receiver_id=${receiverId}`)
      .then(resolve)
      .catch(reject);
  });
}

export function getInboxData(userId) {
  return async (dispatch) => {
    const endpoint = `${GET_INBOX}?sender_id=${userId}`;
    try {
      const { data } = await axiosInstance.get(endpoint);
      dispatch({
        type: actionTypes.INBOX_DATA,
        payload: data.data,
      });
    } catch (error) {
      console.log("🚀 ~ file: index.js:23 ~ return ~ error:", error);
      dispatch({
        type: actionTypes.INBOX_DATA,
        payload: [],
      });
    }
  };
}

export function getChatCount(userId) {
  return async (dispatch) => {
    const endpoint = `${GET_CHAT_COUNT}?sender_id=${userId}`;
    try {
      const { data } = await axiosInstance.get(endpoint);
      if (data && data.length) {
        dispatch({
          type: actionTypes.CHAT_COUNT,
          payload: data.length,
        });
      } else {
        dispatch({
          type: actionTypes.CHAT_COUNT,
          payload: 0,
        });
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js:23 ~ return ~ error:", error);
      dispatch({
        type: actionTypes.CHAT_COUNT,
        payload: 0,
      });
    }
  };
}

export function getMessages(conversation_id, sender_id, page, callback) {
  return async (dispatch) => {
    const defaultData = {
      data: [],
      current_page: 1,
      next: false,
      prev: false,
      total_page: 0,
    };

    const pageNum = page ? page : 1;
    const endpoint = `${GET_MESSAGES}?conversation_id=${conversation_id}&sender_id=${sender_id}&page=${pageNum}&limit=20`;
    try {
      const { data } = await axiosInstance.get(endpoint);
      dispatch({
        type: actionTypes.GET_CHAT_MESSAGES,
        payload: data.data,
        page,
      });
      if (typeof callback === "function") {
        callback();
      }
    } catch (error) {
      console.log("🚀 ~ file: index.js:23 ~ return ~ error:", error);
      dispatch({
        type: actionTypes.GET_CHAT_MESSAGES,
        payload: defaultData,
      });
      if (typeof callback === "function") {
        callback();
      }
    }
  };
}

export function appendMessages(data) {
  return async (dispatch, getState) => {
    const chatReducer = getState().chatReducer;
    const messages = chatReducer?.chatMessages?.data || [];
    const foundMsg = messages.findIndex((item) => item.id === data.id);

    if (foundMsg !== -1) {
      messages.splice(foundMsg, 1, data);
    } else {
      messages.push(data);
    }

    const dataObj = {
      ...chatReducer.chatMessages,
      data: messages,
    };

    dispatch({ type: actionTypes.GET_CHAT_MESSAGES, payload: dataObj });
    dispatch({ type: actionTypes.SCROLL_TO_BOTTOM, payload: true });
  };
}

export function appendInbox(inboxData) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.INBOX_DATA, payload: inboxData });
  };
}
