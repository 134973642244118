import React from "react";
import TriviaModalBannerImg from "../../../../assets/img/triviaModalBanner.png";
import TriviaMobileModalBannerImg from "../../../../assets/img/triviaMobileModalBanner.png";
import styles from "./styles.module.css";
import Text from "src/modules/parent/components/text";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { isBrowser } from "react-device-detect";

const TriviaHomeBanner = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.triviaHomeModalBody}>
        <div className={styles.triviaModalBannerContent}>
          {isBrowser && <Text
            customStyles={{ width: "100%" }}
            size={isBrowser ? "XXL" : "M2"}
            lHeight={"XS"}
            font={"BOLD"}
            color={"#00000"}
            title={"Dot & Line’s 4th Online Trivia Competition is HERE! 🚀"}
          />}
          <Text
            customStyles={{ width: "100%", textAlign: !isBrowser ? "center" : undefined }}
            size={isBrowser ? "M2" : "S"}
            lHeight={"XS"}
            font={"LIGHT"}
            color={"#00000"}
            title={
              isBrowser
                ? "This time, we’re collaborating with Bachaa Party to bring an even bigger & better challenge for students in Grade 3-8!"
                : "Teaming up with Bachaa Party for a bigger challenge! "
            }
          />
          {isBrowser && <ul className={styles.ul}>
            <li>
              <Text
                customStyles={{ width: "100%" }}
                size={isBrowser ? "M2" : "S"}
                lHeight={"XS"}
                font={"LIGHT"}
                color={"#00000"}
                title={"Test your knowledge"}
              />
            </li>
            <li>
              <Text
                customStyles={{ width: "100%" }}
                size={isBrowser ? "M2" : "S"}
                lHeight={"XS"}
                font={"LIGHT"}
                color={"#00000"}
                title={"Compete with students worldwide"}
              />
            </li>
            <li>
              <Text
                customStyles={{ width: "100%" }}
                size={isBrowser ? "M2" : "S"}
                lHeight={"XXS"}
                font={"LIGHT"}
                color={"#00000"}
                title={"Win exciting prizes!"}
              />
            </li>
          </ul>}
          <Button
            onClick={() => {
              navigate("/trivia");
            }}
            className={styles.searchButton}
          >
            {"Learn More!"}
          </Button>
        </div>
        <div>
          <img
            src={isBrowser ? TriviaModalBannerImg : TriviaMobileModalBannerImg}
            className={
              isBrowser
                ? styles.triviaModalBannerImg
                : styles.triviaMobileModalBannerImg
            }
            alt="TriviaModalBannerImg"
          />
        </div>
      </div>
    </>
  );
};

export default TriviaHomeBanner;
