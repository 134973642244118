import React, { Fragment, useCallback, useState } from "react";

import { Row, Button } from "antd";
import Carousel from "react-multi-carousel";
import { isBrowser } from "react-device-detect";

import { responsiveForReqDemoCards } from "src/modules/parent/utils";
import ContactModal from "src/modules/parent/components/modal/contactModal";
import Text from "src/modules/parent/components/text";
import RequestDemoCard from "../request-demo-card";
import styles from "./../styles.module.css";

const whatsappMobile = "https://wa.me/";

const RequestDemo = ({
  timeSlots,
  packageDetail,
  title = false,
  isScheduled,
  handleDemoRescheduled,
  loadingId,
  setRequestModal = () => {},
}) => {
  const [showContactModal, setShowContactModal] = useState(false);

  const handleClickContact = useCallback(() => {
    if (!isBrowser) handleWhatsApp();
    else setShowContactModal(true);
  }, []);

  const handleWhatsApp = () => {
    window.open(`${whatsappMobile}923460086795`);
  };

  return (
    <Fragment>
      {packageDetail && (
        <div>
          {!title ? (
            <p style={{ color: "#24397E" }} className={styles.title}>
              Request a Demo Class:{" "}
              {packageDetail?.is_booked ? (
                <span className={"f-sm font-weight-500-imp red-color"}>
                  (Demo is already booked)
                </span>
              ) : (
                ""
              )}
            </p>
          ) : (
            <p style={{ color: "#24397E" }} className={styles.title}>
              {title}
            </p>
          )}
          {timeSlots && timeSlots?.length > 0 && (
            <Carousel responsive={responsiveForReqDemoCards}>
              {timeSlots?.map((item, index) => (
                <RequestDemoCard
                  key={index.toString()}
                  isScheduled={isScheduled}
                  item={item}
                  loadingId={loadingId}
                  itemIndex={index}
                  setRequestModal={setRequestModal}
                  isDisabled={item.disabled}
                  handleDemoRescheduled={() => {
                    handleDemoRescheduled(item, `${item.id}-${index}}`);
                  }}
                  packageDetail={packageDetail}
                />
              ))}
            </Carousel>
          )}
        </div>
      )}

      <Row className={"mt-30"} justify={"center"}>
        <Text
          size={"M2"}
          center
          lHeight={"S"}
          color={"#000000"}
          title={"Don’t find a time slot of your choice?"}
        />
      </Row>

      <Row className={"mt-10"} justify={"center"}>
        <Button onClick={handleClickContact} className={styles.learnMoreButton}>
          {"Contact our Academic Counselor"}
        </Button>
      </Row>

      <ContactModal
        show={showContactModal}
        title={"Contact Representative"}
        name={"Aiman Ayubi:"}
        color={"#2CA58D"}
        number={"+923460086795"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal(false)}
      />
    </Fragment>
  );
};

export default RequestDemo;
