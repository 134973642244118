import React from "react";

import { Space, Button } from "antd";
import { isBrowser } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import SearchBoxComponent from "../searchBox";
import SelectModal from "src/modules/parent/pages/olevels/components/selectModal";
import childMobile from "../../assets/banner/childMobile.png";

import styles from "../../styles.module.css";

const location = [
  "Pakistan",
  "UAE",
  "Saudi Arabia",
  "UK",
  "Oman",
  "Qatar",
  "Australia",
];

const boards = ["Cambridge", "Pearson EdExcel", "AQA", "IB", "VCE", "OCR"];

const TextComponent = ({
  isFilter = false,
  isLoading = false,
  selectedItem = {},
  show = false,
  dropdownData = [],
  setShow = () => {},
  handleItemSelect = () => {},
  handleBookDemo = () => {},
  handleClear = () => {},
  onSearch = () => {},
  onSelect = () => {},
}) => (
  <div className={styles.textContent}>
    <Space size={"middle"} direction={"vertical"}>
      {isBrowser ? (
        <div
          className={[styles.list, isFilter ? styles.hideContent : ""].join(
            " "
          )}
        >
          {location.map((i, index) => {
            return (
              <span className={styles.point} key={index.toString()}>
                <span>•</span>
                {i}
              </span>
            );
          })}
        </div>
      ) : (
        !isFilter && <img src={childMobile} alt="childMobile" />
      )}

      <Text
        size={"XXL4"}
        color={"#FFFFFF"}
        font={"SEMIBOLD"}
        lHeight={"S"}
        className={isFilter ? styles.hideContent : ""}
        title={"Exam Preparation"}
      />

      <Text
        size={isBrowser ? "XXL2" : "L"}
        color={"#FFFFFF"}
        font={"SEMIBOLD"}
        lHeight={"S"}
        className={isFilter ? styles.hideContent : ""}
        title={"Certified tutors - online 1-to-1 classes"}
      />

      {!isFilter && isBrowser && (
        <div className={styles.list}>
          {boards.map((i, index) => {
            return (
              <span className={styles.point} key={index.toString()}>
                <span>•</span>
                {i}
              </span>
            );
          })}
        </div>
      )}

      {!isFilter ? (
        <div className={styles.buttonContainer}>
          <Button onClick={handleBookDemo} className={styles.searchButton}>
            {"Book a Free Demo"}
          </Button>
        </div>
      ) : (
        <SearchBoxComponent
          isLoading={isLoading}
          handleClear={handleClear}
          selectedItem={selectedItem}
          handleSearch={onSearch}
          handleSelectField={onSelect}
        />
      )}
    </Space>

    <SelectModal
      isMultiple={false}
      show={show}
      select={dropdownData}
      handleSelectItem={handleItemSelect}
      selectedItem={selectedItem}
      setShow={setShow}
    />
  </div>
);
export default React.memo(TextComponent);
