import actionTypes from "../../action-types";
import {
  GET_LEARNING_SUBJECTS,
  GET_RESOURCES_DATA,
} from "src/modules/parent/config/api";
import { getRequest, postRequest } from "src/modules/parent/config/network";

export function setActiveLearningMenu(value) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LEARNING_MENU,
      payload: value,
    });
  };
}

export function getLearningSubjects() {
  return async (dispatch) => {
    try {
      const endpoint = `${GET_LEARNING_SUBJECTS}`;
      const response = await getRequest(endpoint);

      if (response && response.data && response.data.data) {
        const subjects = response?.data?.data.filter(
          (i) => i?.subject?.toLowerCase() !== "urdu"
        );

        dispatch({
          type: actionTypes.LEARNING_SUBJECTS,
          payload: subjects,
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.LEARNING_SUBJECTS,
        payload: [],
      });
    }
  };
}

export function getResourcesData(queryObj) {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LEARNING_RESOURCES_LOADING,
        payload: true,
      });

      const endpoint = `${GET_RESOURCES_DATA}`;
      const response = await postRequest(endpoint, {
        ...queryObj,
        parent: true,
      });

      dispatch({
        type: actionTypes.LEARNING_RESOURCES_LOADING,
        payload: false,
      });

      if (response && response.data && response.data.data) {
        const { grades, lessonPack_option, lesson } = response.data.data || {};

        dispatch({
          type: actionTypes.LEARNING_RESOURCES,
          payload: lesson,
        });
        dispatch({
          type: actionTypes.LEARNING_GRADES,
          payload: grades,
        });
        dispatch({
          type: actionTypes.LEARNING_CATEGORY,
          payload: lessonPack_option,
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.LEARNING_RESOURCES_LOADING,
        payload: false,
      });
      dispatch({
        type: actionTypes.LEARNING_RESOURCES,
        payload: [],
      });
    }
  };
}
