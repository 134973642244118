/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "antd";
// import gif from "src/modules/parent/assets/img/svg/gif_image_replica.svg";
import * as actions from "src/modules/parent/store/actions";
import ModalComponent from "src/modules/parent/components/modal";
import CardsWrapper from "src/modules/parent/components/cards-wrapper";
import TextInput from "src/modules/parent/components/text-input";
// import "./index.scss";
import FlatButton from "src/modules/parent/components/flat-button";
import { increaseDemoCount } from "src/modules/parent/utils";
import actionTypes from "src/modules/parent/store/action-types";
import DemoBooked from "src/modules/parent/pages/package-details/demo-booked";
import {
  accountReadyNotification,
  freeClassNotification,
} from "src/modules/parent/utils/notificationData";
import { fillRequiredNotification } from "src/modules/parent/utils/notificationData";

const Login = ({ handleSignUp = () => {} }) => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState("");
  const [showDemoSuccess, setShowDemoSuccess] = useState(false);
  const { showRegisterModal } = useSelector(
    (state) => state.notificationReducer
  );

  const isValidated = () => {
    if (!email || !password) {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: fillRequiredNotification,
      });
      setIsError(true);
      return false;
    }
    return true;
  };

  const handleRegisteration = useCallback(
    (type) => {
      switch (type) {
        case "demo":
          const { requestDemo } = showRegisterModal;
          action
            .createDemo(requestDemo)
            .then(() => {
              increaseDemoCount();
              setLoading(false);
              setShowDemoSuccess(true);
              action.setRegisterData(null);
            })
            .catch(() => {
              action.setRegisterData(null);
              setLoading(false);
            });
          break;

        case "bookFreeClass":
          const { bookPackage } = showRegisterModal;

          action
            .registerFreeClass(bookPackage)
            .then(() => {
              setLoading(false);
              action.setRegisterData(null);

              dispatch({
                type: actionTypes.NOTIFICATIONDATA,
                payload: freeClassNotification,
              });
            })
            .catch(() => {
              action.setRegisterData(null);
              setLoading(false);
            });
          break;

        case "bookThisClass":
          action.setRegisterData({ ...showRegisterModal, isBookedModal: true });
          break;

        case "teacherChat":
          const { chat } = showRegisterModal;

          window.location.pathname = `/chat/${chat?.teacherId}`;
          break;

        case "registerClass":
          const { bookData } = showRegisterModal;

          actions
            .bookCourse(bookData)
            .then(() => {
              setLoading(false);
              action.setRegisterData(null);

              dispatch({
                type: actionTypes.NOTIFICATIONDATA,
                payload: accountReadyNotification(),
              });
            })
            .catch(() => {
              setLoading(false);
              action.setRegisterData(null);
            });
          break;

        default:
          break;
      }
    },
    [action, dispatch, showRegisterModal]
  );

  const submitHandler = async () => {
    if (isValidated()) {
      setLoading(true);

      const data = {
        email,
        password,
        role: "parent",
      };

      action
        .loginWithEmail(data, true)
        .then((userObj) => {
          if (showRegisterModal && showRegisterModal.type) {
            const { type } = showRegisterModal;
            handleRegisteration(type);
          }
        })
        .catch(() => setLoading(false));
    }
  };

  const redirectToDashboard = () => {
    setShowDemoSuccess(false);
    window.location.pathname = "/";
  };

  return (
    <div className={"auth-form mt-20"}>
      <ModalComponent
        closable={false}
        maskClosable={false}
        isModalVisible={showDemoSuccess}
        onCancel={redirectToDashboard}
        bodyClassName={"d-flex justify-content-center align-items-center"}
        Component={
          <DemoBooked
            okHandler={redirectToDashboard}
            packageTitle={showRegisterModal?.package?.title}
            teacherName={showRegisterModal?.package?.teacher?.name}
          />
        }
      />

      <CardsWrapper>
        <Fragment>
          <div className={"form-wrapper"}>
            <div className={"header-text"}>Login</div>
            <Form className="text-align-center">
              <Form.Item
                hasFeedback={email.length < 1 && isError ? true : false}
                validateStatus={
                  email.length < 1 && isError ? "error" : "success"
                }
              >
                <TextInput
                  required
                  allowClear
                  value={email}
                  type={"email"}
                  label={"Email"}
                  placeholder={"Enter email"}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                hasFeedback={password.length < 1 && isError ? true : false}
                validateStatus={
                  password.length < 1 && isError ? "error" : "success"
                }
              >
                <TextInput
                  required
                  allowClear
                  value={password}
                  type={"password"}
                  label={"Password"}
                  placeholder={"Enter password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
              <div>
                <FlatButton
                  isForm
                  width={"180px"}
                  title={"Register"}
                  loading={loading}
                  onClick={submitHandler}
                  className={"mt-20"}
                />
              </div>
              <div>
                <p className="white-color mb-20 mt-20 font-weight-300">
                  Don’t have a dot & line account?{" "}
                  <a onClick={handleSignUp} className={"link-text"}>
                    Signup
                  </a>
                </p>
              </div>
            </Form>
          </div>
          {/* <div className={"image-wrapper"}>
            <img src={gif} alt="GIF" />
          </div> */}
        </Fragment>
      </CardsWrapper>
    </div>
  );
};

export default Login;
