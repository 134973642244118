import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions";
import FlatButton from "src/modules/parent/components/flat-button";
import { removeChild } from "src/modules/parent/store/actions";
import DeleteChildErrorModal from "./deleteChildError";

const DeleteChildModal = ({ setShowModal, child }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    actions
      .deleteChild(child)
      .then(() => dispatch(removeChild(id)))
      .catch((err) => {
        if (err === "enrollError") {
          setDeleteModal(true);
        }
      });
  };

  const handleClose = () => {
    setDeleteModal(false);
    setShowModal(false);
  };

  return (
    <div
      className="d-column justify-content-center align-items-center"
      style={{ height: "350px" }}
    >
      <p className="font-22 font-weight-300 mb-30">
        <span className="black-color">Do you want to delete</span>{" "}
        <span className="blue-color" style={{ textTransform: "capitalize" }}>
          {child.name}
        </span>
      </p>
      <div className="d-row justify-content-center">
        <FlatButton
          onClick={() => handleDelete(child.id)}
          width={"85px"}
          type="Secondary"
          className="mr-25"
          title={"Yes"}
        />
        <FlatButton
          onClick={() => setShowModal(false)}
          width={"85px"}
          type="Secondary"
          title={"No"}
        />
      </div>

      <DeleteChildErrorModal
        show={deleteModal}
        handleOkay={handleClose}
        setShow={handleClose}
      />
    </div>
  );
};

export default DeleteChildModal;
