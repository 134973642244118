import actionTypes from "../../action-types";

const INITIAL_STATE = {
  userInfo: null,
  isAuthenticated: false,
  isOnboarding: false,
  fromTelenor: false,
  selectedChild: undefined,
  dashboardData: undefined,
  childLoading: false,
  whatsNewData: null,
  whatsNewLoading: false,
  eventData: null,
  eventDataLoading: false,
  activeStudents: false,
  trackerData: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGGED_IN_USER: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    case actionTypes.WHATS_NEW_DATA: {
      return {
        ...state,
        whatsNewData: action.payload,
      };
    }
    case actionTypes.TRACKER_DATA: {
      return {
        ...state,
        trackerData: action.payload,
      };
    }
    case actionTypes.ACTIVE_STUDENTS: {
      return {
        ...state,
        activeStudents: action.payload,
      };
    }
    case actionTypes.EVENTS_DATA: {
      return {
        ...state,
        eventData: action.payload,
      };
    }
    case actionTypes.EVENTS_DATA_LOADING: {
      return {
        ...state,
        eventDataLoading: action.payload,
      };
    }
    case actionTypes.WHATS_NEW_DATA_LOADING: {
      return {
        ...state,
        whatsNewLoading: action.payload,
      };
    }
    case actionTypes.SET_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: action.payload,
      };
    }
    case actionTypes.CHILD_LOADING: {
      return {
        ...state,
        childLoading: action.payload,
      };
    }
    case actionTypes.SET_CHILD_ID: {
      return {
        ...state,
        selectedChild: action.payload,
      };
    }
    case actionTypes.SET_IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    }
    case actionTypes.USER_INFO: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    case actionTypes.IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    }
    case actionTypes.IS_ONBOARDING: {
      return {
        ...state,
        isOnboarding: action.payload,
      };
    }
    case actionTypes.UPDATE_PARENT_PROFILE: {
      return {
        ...state,
        userInfo: { ...state?.userInfo, ...action.payload },
      };
    }
    case actionTypes.UPDATE_FROM_TELENOR_STATUS: {
      return {
        ...state,
        fromTelenor: action.payload,
      };
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        userInfo: null,
        isAuthenticated: false,
        isOnboarding: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
