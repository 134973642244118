import React, { Fragment, useRef, useEffect } from "react";
import Carousel from "react-multi-carousel";
import FeedbackCard from "../feedback-card";
import { responsive } from "src/modules/parent/utils";

const InstructorReviews = ({ reviews, scrollToDiv }) => {
  const myRef = useRef(null);

  useEffect(() => {
    if (scrollToDiv) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollToDiv]);

  return (
    <Fragment>
      {reviews && reviews?.length !== 0 && (
        <Fragment>
          <p ref={myRef} className={"f-md mb-5 mt-30"}>
            Reviews{" "}
            <span className="f-sm font-weight-600-imp">
              ({reviews?.length || 0})
            </span>
            :
          </p>
          <div id={"reviews"}>
            <Carousel responsive={responsive}>
              {reviews.map((review) => (
                <FeedbackCard review={review} key={review?.id} />
              ))}
            </Carousel>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default React.memo(InstructorReviews);
