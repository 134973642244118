/* eslint-disable no-unused-vars */
import actionTypes from "../../action-types";
import {
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { app, auth } from "../../../config/firebase";
import {
  LOGIN,
  LOGIN_SOCIAL,
  REGISTER_USER,
  RESET_PASSWORD,
  UPDATE_USER_PROFILE,
  UPDATE_UUID,
  USER_PROFILE,
  GET_DASHBOARD_DATA,
  GET_WHATS_NEW,
  GET_UPCOMING_EVENTS,
  GET_TRACKER_DATA,
} from "src/modules/parent/config/api";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "src/modules/parent/config/network";
import {
  axiosErrorHandler,
  getFilteredTimeSlots,
  getNotificationData,
  getStartEndTime,
} from "src/modules/parent/utils";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { analytics } from "src/modules/parent/config/firebase";
import loginImg from "src/modules/parent/assets/notificationSvgs/loggedIn.svg";
import { setUserId } from "firebase/analytics";
import {
  accountExistNotification,
  accountInUsenotification,
  facebookNotification,
  logoutNotification,
  renderContent,
} from "src/modules/parent/utils/notificationData";

export function firebaseAuthAfterEmailLogin({ email, password }) {
  return new Promise(async function (resolve, reject) {
    try {
      const userData = await fetchSignInMethodsForEmail(auth, email);
      if (userData && !userData.length) {
        const response = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        resolve(response);
      } else if (userData.includes("password")) {
        const response = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        resolve(response);
      } else {
        reject("email-exist");
      }
    } catch (e) {
      reject();
    }
  });
}

const updateUserId = (userId, uuid) => {
  return async (dispatch) => {
    try {
      const endpoint = `${UPDATE_UUID}/${userId}`;
      await patchRequest(endpoint, { uuid });
    } catch (err) {
      axiosErrorHandler(err);
    }
  };
};

const loginContent = (name) => (
  <div>{`You are now logged in as ${name} successfully! Happy Learning!`}</div>
);

export function loginWithEmail(logincredentials, isNotify = false) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await postRequest(LOGIN, logincredentials, false);
        const { data } = response?.data;
        const userObj = {
          ...data?.user,
          token: data?.token,
          type: "loginViaEmail",
        };
        dispatch({ type: actionTypes.SET_LOGGED_IN_USER, payload: userObj });
        dispatch({ type: actionTypes.SET_IS_AUTHENTICATED, payload: true });
        if (userObj?.uuid) {
          setUserId(analytics, userObj?.uuid); // setting up firebase uid for analytics

          resolve(userObj);

          if (!isNotify) {
            const notification = getNotificationData({
              image: loginImg,
              content: () => loginContent(userObj?.name),
              timer: 3000,
            });

            dispatch({
              type: actionTypes.NOTIFICATIONDATA,
              payload: notification,
            });
          }
        } else {
          firebaseAuthAfterEmailLogin(logincredentials)
            .then((credential) => {
              if (credential?.user?.uid) {
                const userId = userObj?.id;
                const uuid = credential?.user?.uid;
                setUserId(analytics, uuid); // setting up firebase uid for analytics
                dispatch(updateUserId(userId, uuid));
                dispatch({
                  type: actionTypes.UPDATE_PARENT_PROFILE,
                  payload: { uuid },
                });

                resolve(userObj);

                if (!isNotify) {
                  const notification = getNotificationData({
                    image: loginImg,
                    content: () => loginContent(userObj?.name),
                    timer: 3000,
                  });

                  dispatch({
                    type: actionTypes.NOTIFICATIONDATA,
                    payload: notification,
                  });
                }
              }
            })
            .catch((err) => {
              if (err && err === "email-exist") {
                dispatch({
                  type: actionTypes.NOTIFICATIONDATA,
                  payload: accountExistNotification,
                });
              }

              reject();
            });
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function loginUser(logincredentials) {
  return new Promise(async (resolve, reject) => {
    const response = await postRequest(LOGIN, logincredentials, false);
    const { data } = response?.data;

    console.log("🚀 ~ returnnewPromise ~ data:", data);
  });
}

export function googleLogin(isNotify = false) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const provider = new GoogleAuthProvider(app);
        const { user } = await signInWithPopup(auth, provider);
        const paramter = {
          email: user.email,
          name: user.displayName,
          login_type: "google",
          uuid: user.uid,
        };
        const response = await postRequest(LOGIN_SOCIAL, paramter, false);
        if (response?.data?.status === "success") {
          const { data } = response?.data;
          const userObj = {
            ...data?.user,
            token: data?.token,
            type: "google",
          };
          dispatch({ type: actionTypes.SET_LOGGED_IN_USER, payload: userObj });
          dispatch({ type: actionTypes.SET_IS_AUTHENTICATED, payload: true });

          if (!isNotify) {
            const notification = getNotificationData({
              image: loginImg,
              content: () => loginContent(userObj?.name),
              timer: 3000,
            });

            dispatch({
              type: actionTypes.NOTIFICATIONDATA,
              payload: notification,
            });
          }

          resolve();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function facebookLogin() {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const fbprovider = new FacebookAuthProvider();
        const { user } = await signInWithPopup(auth, fbprovider);
        const paramter = {
          email: user.email,
          name: user.displayName,
          login_type: "facebook",
          uuid: user.uid,
        };
        const response = await postRequest(LOGIN_SOCIAL, paramter, false);
        if (response?.data?.status === "success") {
          const res = response?.data?.data;
          const userObj = {
            ...res?.user,
            token: res?.token,
            type: "facebook",
          };
          dispatch({ type: actionTypes.SET_LOGGED_IN_USER, payload: userObj });
          dispatch({ type: actionTypes.SET_IS_AUTHENTICATED, payload: true });

          const notification = getNotificationData({
            image: loginImg,
            content: () => loginContent(userObj?.name),
            timer: 3000,
          });

          dispatch({
            type: actionTypes.NOTIFICATIONDATA,
            payload: notification,
          });

          resolve();
        }
      } catch (err) {
        switch (err.code) {
          case "auth/account-exists-with-different-credential": {
            dispatch({
              type: actionTypes.NOTIFICATIONDATA,
              payload: accountExistNotification,
            });
            break;
          }
          default: {
            dispatch({
              type: actionTypes.NOTIFICATIONDATA,
              payload: facebookNotification,
            });
          }
        }
        reject();
      }
    });
  };
}

export function registerUser(user) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
      const data = {
        name: user.name,
        phone: user.phone,
        email: user.email,
        password: user.password,
        user_type: user.user_type,
        country: regionNames?.of(
          parsePhoneNumberWithError(user?.phone)?.country
        ),
      };
      try {
        const firebaseUser = await createUserWithEmailAndPassword(
          auth,
          data?.email,
          data?.password
        );
        if (firebaseUser && firebaseUser?.user?.uid) {
          data.uuid = firebaseUser.user.uid;
          try {
            const response = await postRequest(REGISTER_USER, data, false);
            const { user: createdUser, token } = response?.data?.data;
            const userObj = {
              token,
              ...createdUser,
              type: "loginViaEmail",
            };
            dispatch({
              type: actionTypes.SET_LOGGED_IN_USER,
              payload: userObj,
            });
            dispatch({ type: actionTypes.SET_IS_AUTHENTICATED, payload: true });
            dispatch({ type: actionTypes.IS_ONBOARDING, payload: true });
            resolve(userObj);
          } catch (err) {
            axiosErrorHandler(err);
            reject();
          }
        }
      } catch (err) {
        switch (err.code) {
          case "auth/email-already-in-use": {
            dispatch({
              type: actionTypes.NOTIFICATIONDATA,
              payload: accountInUsenotification,
            });
            break;
          }
          default: {
            reject();
          }
        }
        reject();
      }
    });
  };
}

export function updateParentProfile(user) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const { data } = await patchRequest(UPDATE_USER_PROFILE, user);
        if (data?.status === "success") {
          dispatch({
            type: actionTypes.UPDATE_PARENT_PROFILE,
            payload: data?.data,
          });

          const notification = getNotificationData({
            type: "success",
            content: () => renderContent(data?.message),
            timer: 3000,
          });

          dispatch({
            type: actionTypes.NOTIFICATIONDATA,
            payload: notification,
          });
          resolve();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function resetPassword(params) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await postRequest(RESET_PASSWORD, params, false);
        if (response?.data?.status === "success") {
          resolve(response?.data?.message);
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function onLogOut() {
  return (dispatch) => {
    return new Promise(function (resolve, reject) {
      try {
        dispatch({ type: actionTypes.LOG_OUT });

        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: logoutNotification,
        });

        resolve();
      } catch (err) {
        reject();
      }
    });
  };
}

export function fetchParentProfile() {
  return async (dispatch) => {
    try {
      const { data } = await getRequest(USER_PROFILE);
      dispatch({
        type: actionTypes.UPDATE_PARENT_PROFILE,
        payload: data?.data,
      });
    } catch (err) {
      axiosErrorHandler(err);
    }
  };
}

export function checkStudentEnrolments() {
  return async (dispatch) => {
    try {
      const { data } = await getRequest(USER_PROFILE);
      if (data && data.data) {
        const { active_students } = data.data;

        if (active_students) {
          dispatch({
            type: actionTypes.ACTIVE_STUDENTS,
            payload: true,
          });
        } else {
          dispatch({
            type: actionTypes.ACTIVE_STUDENTS,
            payload: false,
          });
        }
      }
    } catch (err) {
      axiosErrorHandler(err);
    }
  };
}

export function updateFromTelenorStatus(value) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_FROM_TELENOR_STATUS, payload: value });
  };
}

export function getDashboardData(childId) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        dispatch({ type: actionTypes.SET_CHILD_ID, payload: childId });
        dispatch({ type: actionTypes.CHILD_LOADING, payload: true });

        const response = await getRequest(`${GET_DASHBOARD_DATA}/${childId}`);
        dispatch({ type: actionTypes.CHILD_LOADING, payload: false });

        if (response?.data?.status) {
          const data = response?.data?.data;
          let arr = [];

          if (
            data &&
            data.recomended_packages &&
            data.recomended_packages.length
          ) {
            data.recomended_packages.forEach((item) => {
              const getTimeSlot = getFilteredTimeSlots(
                item?.time_slots,
                getStartEndTime()
              );

              arr.push({
                ...item,
                time_slots: getTimeSlot,
              });
            });
          }

          dispatch({
            type: actionTypes.SET_DASHBOARD_DATA,
            payload: { ...response?.data?.data, recomended_packages: arr },
          });
          resolve();
        }
      } catch (error) {
        dispatch({ type: actionTypes.CHILD_LOADING, payload: false });
        // axiosErrorHandler(error);
        dispatch({
          type: actionTypes.SET_DASHBOARD_DATA,
          payload: undefined,
        });
        reject();
      }
    });
  };
}

export function getWhatsNewData(childId) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const endpoint = childId
          ? `${GET_WHATS_NEW}?student_id=${childId}`
          : GET_WHATS_NEW;
        dispatch({ type: actionTypes.WHATS_NEW_DATA_LOADING, payload: false });

        const response = await getRequest(endpoint);

        if (response?.data?.status) {
          dispatch({
            type: actionTypes.WHATS_NEW_DATA,
            payload: response?.data?.data,
          });
          resolve();
        }
      } catch (error) {
        dispatch({ type: actionTypes.WHATS_NEW_DATA_LOADING, payload: false });
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function getTrackerData(childId) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await getRequest(`${GET_TRACKER_DATA}/${childId}`);

        if (response?.data?.status) {
          dispatch({
            type: actionTypes.TRACKER_DATA,
            payload: response?.data?.data,
          });
          resolve();
        }
      } catch (error) {
        dispatch({ type: actionTypes.TRACKER_DATA, payload: null });
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function getUpcomingEventsData(childId) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        dispatch({ type: actionTypes.EVENTS_DATA_LOADING, payload: false });

        const response = await getRequest(`${GET_UPCOMING_EVENTS}/${childId}`);

        if (response?.data?.status) {
          dispatch({
            type: actionTypes.EVENTS_DATA,
            payload: response?.data?.data,
          });
          resolve();
        }
      } catch (error) {
        dispatch({ type: actionTypes.EVENTS_DATA_LOADING, payload: false });
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}
