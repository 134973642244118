import React, { useCallback, useState } from "react";

import { Row, Space } from "antd";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import { isBrowser, isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import { reviewSettings, reviewCard } from "src/modules/parent/utils";
import comma from "../../assets/png/comma.png";
import stars from "../../assets/png/stars.png";
import icon from "../../assets/png/Icon.png";
import UserAvatar from "src/modules/parent/components/avatar";
import ModalComponent from "src/modules/parent/components/modal";
import DetailedFeedback from "src/modules/parent/components/feedback-card/detailed-feedback";

import styles from "./styles.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const textLimitForReadMore = isMobile ? 150 : 200;

const Item = ({
  review,
  city,
  country,
  name,
  isNaplan = false,
  isTrivia = false,
  setShow = () => { },
}) => (
  <div className={styles.card}>
    <img className={styles.commaIcon} src={comma} alt={"comma"} />

    <div className={styles.content}>
      <Row>
        <Space size={"middle"}>
          <div className={styles.profilePic}>
            <UserAvatar
              name={name}
              width={"100%"}
              height={"100%"}
              color={"#096dd9"}
            />
          </div>

          <div>
            <Space size={isBrowser ? "" : 2} direction={"vertical"}>
              <Text size={"M"} font={"SEMIBOLD"} lHeight={"S"} title={name} />

              <Text
                size={"S"}
                color={"#777777"}
                font={"SEMIBOLD"}
                lHeight={"S"}
                title={city ? (country ? `${city}, ${country}` : city) : country}
              />

              <img className={styles.starIcon} src={stars} width={"100%"} alt={"stars"} />
            </Space>
          </div>
        </Space>
      </Row>

      <Row className={isBrowser ? "mt-20 mb-20" : "mt-10 mb-10"}>
        {
          <Space>
            <img src={icon} alt={"stars"} />

            <Text
              size={"M"}
              color={"#2F79E6"}
              font={"SEMIBOLD"}
              lHeight={"S"}
              title={isNaplan ? "NAPLAN test Prep" : isTrivia ? "Trivia 2024" : "Kindergarten to Grade 8"}
            />
          </Space>
        }
      </Row>

      {review?.length <= textLimitForReadMore ? (
        <Text
          size={"S"}
          color={"#6B6B6B"}
          font={"LIGHTER"}
          lHeight={"S"}
          title={review}
        />
      ) : (
        <Row>
          <span className={styles.textStyle}>
            {review?.slice(0, textLimitForReadMore)}
            {"... "}
            <span
              onClick={() => setShow({ review, name })}
              className="blue-color text-hover-underline"
            >
              <i>See more</i>
            </span>
          </span>
        </Row>
      )}
    </div>
  </div>
);

const Reviews = ({ isHeading = true, reviewsData = [], isNaplan = false, isTrivia = false, }) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});

  const handleShow = useCallback(({ review, name }) => {
    setShowModal(true);
    setData({
      rating: 5,
      review,
      parent: { name },
    });
  }, []);

  return (
    <div className={styles.reviewWrapper}>
      {isHeading && (
        <p className={styles.heading}>
          Join over
          <span>100</span>
          of our
          <span>Australian</span>
          students
        </p>
      )}

      <div className={styles.container}>
        {isMobile ? (
          <Slider {...reviewSettings}>
            {reviewsData.map((item) => (
              <Item
                {...item}
                key={item.id}
                isNaplan={isNaplan}
                isTrivia={isTrivia}
                setShow={handleShow}
              />
            ))}
          </Slider>
        ) : (
          <Carousel
            autoPlay
            infinite
            autoPlaySpeed={5000}
            responsive={reviewCard}
          >
            {reviewsData.map((item) => (
              <Item
                {...item}
                key={item.id}
                isNaplan={isNaplan}
                isTrivia={isTrivia}
                setShow={handleShow}
              />
            ))}
          </Carousel>
        )}
      </div>

      {showModal && (
        <ModalComponent
          isModalVisible={showModal}
          onCancel={() => setShowModal(false)}
          Component={<DetailedFeedback review={data} />}
        />
      )}
    </div>
  );
};

export default React.memo(Reviews);
