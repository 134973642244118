/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import Footer from "../../components/footer";
import Header from "../../components/header";
import parsePhoneNumberWithError from "libphonenumber-js";
import FlatButton from "../../components/flat-button";
import TextInput from "src/modules/parent/components/text-input";
import CardsWrapper from "src/modules/parent/components/cards-wrapper";
import PhoneWithFlagInput from "src/modules/parent/components/phone-input";
import * as actions from "../../store/actions";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import { Fragment } from "react";
import {
  containsNumbersOrSpecialCharacters,
  defaultCountry,
} from "../../utils";
import actionTypes from "../../store/action-types";
import {
  fillRequiredNotification,
  phoneNumberNotification,
} from "../../utils/notificationData";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = bindActionCreators(actions, dispatch);
  const { authReducer } = useSelector((state) => state);
  const { countries } = useSelector((state) => state.profileReducer);
  const [country, setCountry] = useState(defaultCountry);
  const { userInfo } = authReducer;
  // const { cities } = profileReducer;
  const [isError, setIsError] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    user_type: "parent",
    country: "",
    email: "",
    name: "",
    phone: "",
    house: "",
    postalCode: "",
    streetAddress: "",
    city: undefined,
    area: "",
  });

  const initializeProfile = () => {
    const data = {
      ...user,
      country: userInfo?.country || "Pakistan",
      email: userInfo?.email || "",
      name: userInfo?.name || "",
      house: userInfo?.house_floor || "",
      postalCode: userInfo?.postal_code || "",
      streetAddress: userInfo?.address || "",
      city: userInfo?.city || undefined,
      area: userInfo?.area || "",
    };
    if (userInfo?.phone) {
      const phoneNumber = parsePhoneNumberWithError(userInfo?.phone);
      if (phoneNumber && phoneNumber?.isValid()) {
        const searchPhone = countries?.find(
          (i) => i?.code === phoneNumber?.country
        );
        data.phone = phoneNumber.number;
        data.country = searchPhone.title;
        setCountry(searchPhone);
      }
    }
    setUser(data);
  };

  useEffect(() => {
    initializeProfile();
  }, [userInfo]);

  useEffect(() => {
    action.fetchParentProfile();
  }, []);

  const countryOnChange = (e) => {
    const searchPhone = countries.find((i) => i.id === e.toString());
    if (searchPhone) {
      setCountry(searchPhone);
      setUser({
        ...user,
        country: searchPhone.title,
        phone: "",
      });
    }
  };

  const isValidated = () => {
    if (!user.name || !user.phone) {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: fillRequiredNotification,
      });
      setIsError(true);
      return false;
    }
    return true;
  };

  const submitHandler = () => {
    if (isValidated()) {
      setLoading(true);
      const phoneNumber = parsePhoneNumberWithError(user?.phone);
      if (phoneNumber && phoneNumber?.isValid()) {
        const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
        // Required fields
        const data = {
          name: user.name,
          phone: phoneNumber.number,
          country: regionNames.of(phoneNumber?.country),
        };
        // Optional fields
        if (user.house) {
          data.house_floor = user.house;
        }
        if (user.postalCode) {
          data.postal_code = user.postalCode;
        }
        if (user.streetAddress) {
          data.address = user.streetAddress;
        }
        if (user.city) {
          data.city = user.city;
        }
        if (user.area) {
          data.area = user.area;
        }
        action
          .updateParentProfile(data)
          .then(() => {
            setValidPhone(false);
            setLoading(false);

            setTimeout(() => {
              navigate("/courses?program_id=5");
            }, 2000);
          })
          .catch(() => setLoading(false));
      } else {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: phoneNumberNotification,
        });
        setValidPhone(false);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="inner-container">
        <CardsWrapper>
          <Form className={"text-align-center"}>
            <Row
              gutter={[5, 5]}
              className={[styles.wrapper_col, "mt-30 mb-50"].join(" ")}
            >
              <Fragment>
                <Col span={24}>
                  <p
                    className={
                      "f-xl font-weight-500-imp mt-20 mb-15 green-color text-align-left"
                    }
                  >
                    Personal Information<span className="red-color">*</span>
                  </p>
                </Col>
                <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                  <div className={styles.col1_space}>
                    <Form.Item
                      hasFeedback={!user?.email && isError ? true : false}
                      validateStatus={
                        !user?.email && isError ? "error" : "success"
                      }
                    >
                      <TextInput
                        disabled
                        required
                        allowClear
                        type={"email"}
                        label={"Email"}
                        placeholder={"Email"}
                        value={user.email}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                  <div className={styles.col2_space}>
                    <Form.Item
                      hasFeedback={
                        user.name.length < 1 && isError ? true : false
                      }
                      validateStatus={
                        user.name.length < 1 && isError ? "error" : "success"
                      }
                    >
                      <TextInput
                        required
                        allowClear
                        label={"Full Name"}
                        placeholder={"Enter full name"}
                        value={user.name}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (!containsNumbersOrSpecialCharacters(value)) {
                            setUser({ ...user, name: value });
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                  <div className={styles.col1_space}>
                    <Form.Item
                      hasFeedback={
                        (!user?.phone && isError) || validPhone ? true : false
                      }
                      validateStatus={
                        (!user?.phone && isError) || validPhone
                          ? "error"
                          : "success"
                      }
                    >
                      <PhoneWithFlagInput
                        showSearch
                        label={"Contact Number"}
                        inputPlaceholder={"Enter phone"}
                        value={user?.phone}
                        selectedId={defaultCountry?.id}
                        countryOnChange={countryOnChange}
                        countryPlaceholder={"Select"}
                        selectedCountry={country?.value}
                        onChange={(e) =>
                          setUser({ ...user, phone: e.target.value })
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Fragment>
              {/* {user?.country && user?.country?.toLowerCase() === "pakistan" && (
                <Fragment>
                  <Fragment>
                    <Col span={24}>
                      <p
                        className={
                          "f-xl font-weight-500-imp mt-40 mb-15 green-color text-align-left"
                        }
                      >
                        Address{" "}
                        <span className="f-md font-weight-300-imp">
                          (Optional)
                        </span>
                      </p>
                    </Col>
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      <div className={styles.col1_space}>
                        <Form.Item>
                          <TextInput
                            allowClear
                            label={"House Number"}
                            placeholder={"Enter house number"}
                            value={user.house}
                            onChange={(e) =>
                              setUser({ ...user, house: e.target.value })
                            }
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      <div className={styles.col2_space}>
                        <Form.Item>
                          <TextInput
                            allowClear
                            type="number"
                            label={"Postal Code"}
                            placeholder={"Enter postal code"}
                            value={user.postalCode}
                            onChange={(e) =>
                              setUser({ ...user, postalCode: e.target.value })
                            }
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      <div className={styles.col1_space}>
                        <Form.Item>
                          <TextInput
                            allowClear
                            label={"Street Address"}
                            placeholder={"Enter street address"}
                            value={user.streetAddress}
                            onChange={(e) =>
                              setUser({
                                ...user,
                                streetAddress: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      <div className={styles.col2_space}>
                        <Form.Item>
                          <SelectInput
                            label={"City"}
                            allowClear
                            placeholder={"Enter your city"}
                            value={user.city}
                            showArrow
                            showSearch
                            options={cities && cities?.length ? cities : []}
                            onChange={(e) => setUser({ ...user, city: e })}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Fragment>
                  <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                    <div className={styles.col1_space}>
                      <Form.Item>
                        <p className={styles.input_label}>Area</p>
                        <GooglePlacesAutocomplete
                          apiKey={GOOGLE_PLACES_API_KEY}
                          autocompletionRequest={{
                            componentRestrictions: { country: ["PK"] },
                          }}
                          selectProps={{
                            value: user.area,
                            styles: placesAutocompleteStyles,
                            onChange: (e) =>
                              setUser({ ...user, area: e.label }),
                            placeholder: user.area
                              ? user.area
                              : "Enter your area",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Fragment>
              )} */}
              <Col span={24}>
                <FlatButton
                  width={"217px"}
                  title={"Update"}
                  loading={loading}
                  onClick={submitHandler}
                  className={"mt-30 mb-10"}
                />
              </Col>
            </Row>
          </Form>
        </CardsWrapper>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
