import React from "react";

import { ChatItem } from "react-chat-elements";

const ChatItemComponent = ({ data = [], handleInboxUser = () => {} }) => {
  return data.map((item, index) => (
    <ChatItem
      key={index.toString()}
      id={index}
      onClick={() => handleInboxUser(item)}
      avatar={item.picture}
      alt={"teacherPicture"}
      title={item.name}
      subtitle={item?.latest_message || ""}
      date={item.created_at}
      avatarFlexible
      lazyLoadingImage={
        "https://literacy.s3.ap-south-1.amazonaws.com/uploads/tp_images/8980.jpeg"
      }
      unread={item.unread_message_count}
    />
  ));
};

export default React.memo(ChatItemComponent);
