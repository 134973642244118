import React from "react";

import { Row, Space, Col, Button } from "antd";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import chevronDown from "../../assets/webp/chevron-down.webp";
import searchIcon from "../../assets/webp/Icon.webp";
import { olevelGrades } from "src/modules/parent/utils/static-data";
import { boards } from "src/modules/parent/pages/olevels/components/banner/staticData";

import styles from "./styles.module.css";

const searchFields = [
  {
    id: "0",
    title: "Board",
    name: "boards",
    description: "of the student",
    data: boards,
    col: {
      xxl: 6,
      xl: 6,
      lg: 6,
      md: 24,
      sm: 24,
      xs: 24,
    },
  },
  {
    id: "1",
    title: "Grade",
    name: "grades",
    description: "of the student",
    data: olevelGrades,
    col: {
      xxl: 6,
      xl: 6,
      lg: 6,
      md: 24,
      sm: 24,
      xs: 24,
    },
  },
  {
    id: "2",
    title: "Subject",
    description: "of interest",
    name: "subjects",
    data: [],
    col: {
      xxl: 6,
      xl: 6,
      lg: 6,
      md: 24,
      sm: 24,
      xs: 24,
    },
  },
];

const SearchBox = ({
  handleSelectField = () => {},
  handleSearch = () => {},
  handleClear = () => {},
  selectedItem,
  isLoading = false,
}) => (
  <div
    className={!isMobile ? styles.searchWrapper : styles.searchMobileWrapper}
  >
    <Row className="h-100" gutter={[10, 20]}>
      {searchFields.map(({ id, title, description, col, data, name }) => (
        <Col
          className={id !== "2" ? !isMobile && styles.colLine : ""}
          key={id}
          xxl={col.xxl}
          xl={col.xl}
          lg={col.lg}
          md={col.md}
          sm={col.sm}
          xs={col.xs}
        >
          <Row
            className={isMobile ? styles.selectFieldMobile : styles.selectField}
            style={
              isMobile && {
                borderBottom:
                  id !== "2" ? "1px solid rgba(227, 227, 227, 1)" : "none",
              }
            }
            onClick={() =>
              handleSelectField({
                data,
                name,
                title,
              })
            }
            justify={isMobile ? "space-between" : "center"}
            align={"middle"}
          >
            <div className={styles.searchField}>
              <Space align={"start"} direction={"vertical"}>
                <Text
                  size={isMobile ? "L" : "M2"}
                  font={"SEMIBOLD"}
                  title={title}
                />

                <Text
                  size={isMobile ? "M" : "M2"}
                  color={
                    selectedItem[name] && selectedItem[name].length > 0
                      ? "rgba(0, 94, 255, 1)"
                      : "rgba(143, 143, 143, 1)"
                  }
                  className={styles.description}
                  title={
                    selectedItem &&
                    selectedItem[name] &&
                    selectedItem[name].length > 0
                      ? selectedItem[name]
                      : description
                  }
                />
              </Space>
            </div>

            <Row justify={"end"} className={styles.iconStyle}>
              <Space>
                {selectedItem[name] && selectedItem[name].length > 0 && (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      handleClear(name);
                      e.stopPropagation();
                    }}
                    className={styles.closeIcon}
                  >
                    {"x"}
                  </div>
                )}

                <img src={chevronDown} width={20} height={20} alt={"chevron"} />
              </Space>
            </Row>
          </Row>
        </Col>
      ))}

      <Col xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
        <Button
          loading={isLoading}
          onClick={handleSearch}
          className={styles.searchButton}
        >
          <Space>
            {"Search"}

            <img src={searchIcon} width={20} height={20} alt="searchIcon" />
          </Space>
        </Button>
      </Col>
    </Row>
  </div>
);

export default React.memo(SearchBox);
