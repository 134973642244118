export const defaultLinks = {
  fb: "https://www.facebook.com/dotandlinecentres",
  instagram: "https://www.instagram.com/dotandlinelearning",
  youtube: "https://www.youtube.com/channel/UCCOxYRM-2PQdvRWP7awc9kA",
  linkedIn: "https://www.linkedin.com/company/dot-and-line",
  website: "https://www.dotandlinelearning.com",
};

export const examPrepLinks = {
  fb: "https://www.facebook.com/CIEExamPrep",
  instagram: "https://www.instagram.com/dotandlineexamprep/",
  youtube: "https://www.youtube.com/channel/UCCOxYRM-2PQdvRWP7awc9kA",
  linkedIn: "https://www.linkedin.com/company/dot-and-line",
  website: "https://www.dotandlinelearning.com",
};
