import React from "react";

import { Button, Space } from "antd";

import Text from "src/modules/parent/components/text";
import ModalComponent from "src/modules/parent/components/modal";
import InfoImg from "src/modules/parent/assets/notificationSvgs/info.svg";

import styles from "../styles.module.css";

const DeleteChildError = ({
  show = true,
  setShow = () => {},
  handleOkay = () => {},
}) => (
  <ModalComponent
    onCancel={() => setShow(false)}
    closable={false}
    isModalVisible={show}
  >
    <Space
      className={"w-100 mt-20"}
      size={"large"}
      align={"center"}
      direction={"vertical"}
    >
      <Text
        size={"XXL"}
        className={"mb-20"}
        center
        font={"SEMIBOLD"}
        title={"Oops!"}
      />

      <img src={InfoImg} width={100} alt={"Success"} />

      <div className="text-align-center">
        {
          "You cannot delete this profile as this student is currently enrolled in an active class!"
        }
      </div>

      <Button onClick={handleOkay} className={styles.successButton}>
        {"Okay"}
      </Button>
    </Space>
  </ModalComponent>
);

export default DeleteChildError;
