import React from "react";
import { Col, Row } from "antd";
import Carousel from "react-multi-carousel";
import { responsiveBlogs } from "src/modules/parent/utils";
import styles from "../../styles.module.css";
import Card from "../card";
import { useNavigate } from "react-router-dom";

const BlogComponent = ({
  data,
  editors,
  moreHide,
  showTitle = true,
  isOlevel = false,
}) => {
  const navigate = useNavigate();
  const { label, posts } = data;

  const handleSeeMore = () => {
    const categoryLowerCase = label.split(" ").join("-").toLowerCase();
    navigate(`/blogs/${categoryLowerCase}`);
  };

  return (
    <div>
      {showTitle && (
        <Row
          gutter={[0, 5]}
          justify={"space-between"}
          align={"bottom"}
          className="mt-50"
        >
          <p className={"f-xl dark-blue-color"}>{`${
            editors ? "Editor's Choice" : `${label} Blogs`
          } `}</p>

          {!moreHide && (
            <p
              onClick={handleSeeMore}
              className={["font-18", styles.seeMore].join(" ")}
            >
              {"See more articles"}
            </p>
          )}
        </Row>
      )}

      <Carousel responsive={responsiveBlogs}>
        {posts.map((item, index) => (
          <Col key={index}>
            <Card
              {...item}
              isOlevel={isOlevel}
              category={label}
              key={item.toString()}
            />
          </Col>
        ))}
      </Carousel>
    </div>
  );
};

export default BlogComponent;
