import React from "react";

import { Button, Row, Col, Space } from "antd";

import SelectInput from "src/modules/parent/components/select-input";

import styles from "../styles.module.css";

const getOrdinalSuffix = (number) => {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${number}th`; // Special case for 11, 12, 13
  }
  switch (lastDigit) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

// Function to display the grade correctly
const getGradeDisplay = (grade) => {
  if (grade === "Nursery" || grade === "KG") {
    return `${grade} Grader`;
  } else {
    // Extracting the grade number if it's in the format "Grade X"
    const gradeNumber = grade.replace("Grade", "").trim();
    return `${getOrdinalSuffix(gradeNumber)} Grader`;
  }
};

const StepNo2 = ({
  subject,
  setSubject,
  grade,
  apiSubjects = [],
  handleNext = () => {},
  handlePrevious = () => {},
}) => (
  <>
    <Row justify={"center"}>
      <Space align={"center"} direction={"vertical"}>
        <div className={styles.description}>
          {"Pick a subject you’re interested in for your"}
        </div>

        <div className={styles.highlightedText}>{getGradeDisplay(grade)}</div>
      </Space>
    </Row>

    <div className={"mt-30"}>
      <h2>{"What subject are you looking for?"}</h2>

      <Col span={24}>
        <SelectInput
          allowClear
          className={"w-95"}
          value={subject}
          placeholder={"Select Subject"}
          onChange={setSubject}
          options={apiSubjects}
        />
      </Col>

      <Row justify={"center"}>
        <Space>
          <Button onClick={handlePrevious} className={styles.previousButton}>
            {"Previous"}
          </Button>

          <Button onClick={handleNext} className={styles.nextButton}>
            {"Next"}
          </Button>
        </Space>
      </Row>
    </div>
  </>
);

export default React.memo(StepNo2);
