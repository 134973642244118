import React from "react";

import AddChildComponent from "./addChild";
import SelectChildComponent from "./selectChild";

const defaultStudentData = {
  name: "",
  gender: undefined,
  dob: "",
  level: undefined,
};

const initialValuesForAddChild = {
  students: [defaultStudentData],
};

const initialValuesForAnotherChild = {
  students: [],
};

const StudentRegistration = ({
  formikRef,
  handleSubmit,
  isRegistered = true,
  studentIds,
  disabledIds,
  handleStudentChange = () => {},
  showInEligibleModal = () => {},
  handleValidate = () => {},
}) => {
  return !isRegistered ? (
    <AddChildComponent
      formikRef={formikRef}
      removedValue={1}
      initialValues={initialValuesForAddChild}
      handleSubmit={handleSubmit}
    />
  ) : (
    <SelectChildComponent
      formikRef={formikRef}
      checkedIds={studentIds}
      handleSubmit={handleSubmit}
      disabledIds={disabledIds}
      initialValues={initialValuesForAnotherChild}
      handleValidate={handleValidate}
      showInEligibleModal={showInEligibleModal}
      handleChange={handleStudentChange}
    />
  );
};

export default StudentRegistration;
