import React, { useCallback, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";

import ModalComponent from "../../modal";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import LoadingScreen from "./steps/loading";
import { defaultUAE } from "src/modules/parent/utils";
import actionTypes from "src/modules/parent/store/action-types";
import { getSubjectByGradeLevel } from "src/modules/parent/store/actions";
import {
  gradeSelectNotification,
  selectSubjectNotification,
  phoneNumberNotification,
  phoneValidateNotification,
} from "src/modules/parent/utils/notificationData";

import styles from "./styles.module.css";
import { isBrowser } from "react-device-detect";

const AssistMe = ({
  show = false,
  handleClose = () => {},
  // handleShowVideo = () => {},
  // handleBookADemo = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stepper, setStepper] = useState(0);
  const [country, setCountry] = useState(defaultUAE);
  const [grade, setGrade] = useState(null);
  const [subject, setSubject] = useState(null);
  const [phone, setPhone] = useState(null);
  const { countries } = useSelector((state) => state.profileReducer);
  const [subjectLoading, setSubjectLoading] = useState(false);
  const [error, setError] = useState(false);
  const [apiSubjects, setApiSubjects] = useState([]);
  // const [teacherPackages, setTeacherPackages] = useState([]);

  const handleNext = useCallback(() => {
    if (stepper === 0 && !grade) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: gradeSelectNotification,
      });
    }

    if (stepper === 1 && !subject) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: selectSubjectNotification,
      });
    }

    switch (stepper) {
      case 0:
        setSubjectLoading(true);
        getSubjectByGradeLevel({ grades: [grade] })
          .then((response) => {
            setSubjectLoading(false);

            if (response && response.length) {
              setApiSubjects(response);
              setStepper((prev) => prev + 1);
            }
          })
          .catch(() => {
            setSubjectLoading(false);
          });
        break;

      case 1:
        setStepper((prev) => prev + 1);
        break;

      default:
        break;
    }

    // setStepper((prev) => prev + 1);
  }, [dispatch, grade, stepper, subject]);

  const handlePrevious = useCallback(() => {
    setStepper((prev) => prev - 1);
  }, []);

  const clearData = () => {
    setStepper(0);
    setGrade(null);
    setSubject(null);
    setApiSubjects([]);
    setError(false);
    setPhone(null);
  };

  const saveToDB = useCallback(
    (number) => {
      setStepper("loading");

      const params = {
        grades: [grade],
        subjects: [subject],
        phone: number,
        limit: 6,
      };

      getSubjectByGradeLevel(params).then((response) => {
        dispatch({ type: actionTypes.ASSIST_ME_MODAL, payload: false });
        navigate("/courses", {
          state: {
            grades: [grade],
            keywords: [subject],
            teacherPackages: response.data,
          },
        });
        clearData();
      });
    },
    [dispatch, grade, navigate, subject]
  );

  const onSubmit = useCallback(() => {
    if (stepper === 2 && !phone) {
      return setError(true);
    }

    const phoneNumber = parsePhoneNumberWithError(phone);

    if (phoneNumber.isValid()) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: phoneValidateNotification(phoneNumber?.number, saveToDB),
      });
    } else {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: phoneNumberNotification,
      });
    }
  }, [dispatch, phone, saveToDB, stepper]);

  const countryOnChange = useCallback(
    (e) => {
      const searchPhone = countries.find((i) => i.id === e.toString());
      if (searchPhone) {
        setCountry(searchPhone);
      }
    },
    [countries]
  );

  const getSteps = useMemo(() => {
    switch (stepper) {
      case 0:
        return (
          <Step1
            grade={grade}
            isLoading={subjectLoading}
            handleNext={handleNext}
            setGrade={setGrade}
          />
        );

      case 1:
        return (
          <Step2
            grade={grade}
            apiSubjects={apiSubjects}
            subject={subject}
            setSubject={setSubject}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );

      case 2:
        return (
          <Step3
            country={country}
            grade={grade}
            subject={subject}
            isError={error}
            phone={phone}
            setPhone={setPhone}
            countryOnChange={countryOnChange}
            handleSubmit={onSubmit}
            handlePrevious={handlePrevious}
          />
        );

      default:
        return <LoadingScreen />;
    }
  }, [
    apiSubjects,
    country,
    countryOnChange,
    error,
    grade,
    handleNext,
    handlePrevious,
    onSubmit,
    phone,
    stepper,
    subject,
    subjectLoading,
  ]);

  return (
    <ModalComponent
      containerClassName={[
        styles.modalContainer,
        stepper === 3 && styles.packageModal,
      ].join(" ")}
      isModalVisible={show}
      centered={isBrowser}
      onCancel={() => {
        clearData();
        handleClose();
      }}
      footer={false}
    >
      <div className={styles.container}>{getSteps}</div>
    </ModalComponent>
  );
};

export default React.memo(AssistMe);
