import React from "react";

import whatsapp from "../../assets/whatsappBig.svg";
import styles from "../../styles.module.css";
import { Tooltip } from "antd";

const FloatingButton = ({ handlePress, tooltip = true }) => {
  return (
    <Tooltip
      placement={"topLeft"}
      title={tooltip ? "Contact a Representative" : ""}
    >
      <div onClick={handlePress} className={styles.floatingButton}>
        <img src={whatsapp} alt={"Whatsapp"} />
      </div>
    </Tooltip>
  );
};

export default React.memo(FloatingButton);
