import { WhatsAppOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { isBrowser } from "react-device-detect";
import { useDispatch } from "react-redux";
import FlatButton from "src/modules/parent/components/flat-button";
import styles from "./../styles.module.css";
import * as actions from "../../../store/actions";
import { bindActionCreators } from "redux";
import {
  attendClass,
  checkIsAuthenticated,
  getFreeClassMessage,
  getIsDisabled,
  getWhatsAppUrlToOpen,
} from "src/modules/parent/utils";
import ModalComponent from "src/modules/parent/components/modal";
import FreeClassConfirm from "src/modules/parent/components/free-class-card/free-class-confirm";
import moment from "moment-timezone";
import {
  MOBILE_WHATSAPP_URL,
  WEB_WHATSAPP_URL,
} from "src/modules/parent/config/constant";
import actionTypes from "src/modules/parent/store/action-types";
import {
  classConductedNotification,
  freeClassNotification,
} from "src/modules/parent/utils/notificationData";

const RegisterNow = ({ packageDetail, fetchSinglePackage }) => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isDisabled = getIsDisabled(packageDetail?.slots);

  const registerHandler = () => {
    setLoading(true);
    const data = {
      teacher_id: Number(packageDetail?.teacher?.id),
      package_id: Number(packageDetail?.id),
    };
    action
      .registerFreeClass(data)
      .then(() => {
        setLoading(false);
        setShowConfirmModal(false);
        fetchSinglePackage();

        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: freeClassNotification,
        });
      })
      .catch(() => {
        setLoading(false);
        setShowConfirmModal(false);
      });
  };

  const registerConfirmHandler = (e) => {
    e?.stopPropagation();
    if (checkIsAuthenticated()) {
      if (isDisabled) {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: classConductedNotification,
        });
      } else {
        setShowConfirmModal(true);
      }
    } else {
      const data = {
        teacher_id: Number(packageDetail?.teacher?.id),
        package_id: Number(packageDetail?.id),
      };

      action.setRegisterData({
        show: true,
        bookPackage: data,
        type: "bookFreeClass",
      });
    }
  };

  const openInNewTab = (url) => {
    window?.open(url, "_blank", "noopener,noreferrer");
  };

  const handleJoin = (item) => {
    if (item?.slots) {
      const slotDay = moment(item?.slots?.start_time)
        ?.format("ddd")
        ?.toLocaleLowerCase();
      const isFree = true;
      if (attendClass(item?.slots, slotDay, isFree)) {
        if (item?.zoom_link) {
          openInNewTab(item?.zoom_link);
        }
      } else {
        getFreeClassMessage(item?.slots, slotDay);
      }
    }
  };

  const getText = () => {
    return getWhatsAppUrlToOpen("free course");
  };

  return (
    <div>
      <ModalComponent
        closable={false}
        maskClosable={false}
        isModalVisible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        bodyClassName={"d-flex justify-content-center align-items-center"}
        Component={
          <FreeClassConfirm
            loading={loading}
            onOk={registerHandler}
            teacherName={packageDetail?.teacher?.name}
            onCancel={() => setShowConfirmModal(false)}
            bookingDate={moment(packageDetail?.slots?.start_time)?.format(
              "dddd DD MMMM, YYYY"
            )}
          />
        }
      />
      <div className="mt-50 mb-50">
        <div className={styles.book_btn_div}>
          {packageDetail?.is_booked ? (
            <FlatButton
              onClick={(e) => {
                e?.stopPropagation();
                handleJoin(packageDetail);
              }}
              title={"Attend free class"}
              className={styles.book_btn}
            />
          ) : (
            <FlatButton
              title={"Register"}
              className={styles.book_btn}
              onClick={registerConfirmHandler}
            />
          )}
          {isBrowser ? (
            <FlatButton
              className={styles.share_btn}
              onClick={() => window.open(`${WEB_WHATSAPP_URL}${getText()}`)}
              title={
                <div>
                  <span>Share</span>
                  <WhatsAppOutlined className="font-18 ml-15" />
                </div>
              }
            />
          ) : (
            <a
              href={`${MOBILE_WHATSAPP_URL}${getText()}`}
              data-action={"share/whatsapp/share"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              <FlatButton
                className={styles.share_btn}
                title={
                  <div>
                    <span>Share</span>
                    <WhatsAppOutlined className="font-18 ml-15" />
                  </div>
                }
              />
            </a>
          )}
        </div>
        {packageDetail?.is_booked && (
          <p className="text-align-center red-color">
            You are now registered for this Free class! See you soon!
          </p>
        )}
      </div>
    </div>
  );
};

export default RegisterNow;
