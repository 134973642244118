import React, { Fragment } from "react";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const DetailedFeedback = ({ review }) => {
  return (
    <div>
      <div className=" d-div align-items-center yellow-color mb-5">
        {[1, 2, 3, 4, 5].map((element) => (
          <Fragment key={element}>
            {element > review.rating ? (
              <StarOutlined className="font-20 mr-5" />
            ) : (
              <StarFilled className="font-20 mr-5" />
            )}
          </Fragment>
        ))}
      </div>
      <p className="mb-12 f-sm">{review?.review}</p>
      <p className={"f-sm font-weight-600-imp"}>-{review?.parent?.name}</p>
    </div>
  );
};

export default DetailedFeedback;
