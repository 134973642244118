import React, { useCallback } from "react";

import { Row, Button } from "antd";
import { isBrowser, isMobile } from "react-device-detect";
import Carousel from "react-multi-carousel";

import { homeCarousel } from "src/modules/parent/utils";

import styles from "./styles.module.css";
import "react-lazy-load-image-component/src/effects/blur.css";

const BannerComponent = ({ data = [], handleClick = () => {} }) => {
  const Items = useCallback(
    ({ web, mobile, ...rest }) => (
      <div key={rest.index} className={styles.banner}>
        <img src={isBrowser ? web : mobile} width={"100%"} alt="Banner2" />

        <div
          className={styles.imageContent}
          style={{ top: isMobile ? "75%" : "55%" }}
        >
          {!isMobile && (
            <>
              <div className={styles.bannerText}>
                <div>{"Our"}</div>
                <div>{"One-On-One"}</div>
                <div>{"Tuitions"}</div>
              </div>

              <div className={styles.bannerDescription}>
                {
                  "We believe in the power of personalised support. Contact our academic counselor to set up your free demo today!"
                }
              </div>
            </>
          )}

          <Row>
            <Button onClick={handleClick} className={styles.searchButton}>
              {"Contact Us"}
            </Button>
          </Row>
        </div>
      </div>
    ),
    [handleClick]
  );

  return (
    <Carousel
      autoPlay
      autoPlaySpeed={7000}
      arrows={isBrowser}
      infinite
      responsive={homeCarousel}
    >
      {data.map((item, index) => (
        <Items {...item} key={index} index={index} />
      ))}
    </Carousel>
  );
};

export default React.memo(BannerComponent);
