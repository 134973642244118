import React, { useCallback, useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import AddChild from "../modules/parent/pages/add-child";
import FindATutor from "src/modules/parent/pages/find-a-tutor";
import EditChild from "../modules/parent/pages/edit-child";
import EnrolledCourse from "../modules/parent/pages/enrolled-course";
import FAQs from "../modules/parent/pages/faqs";
import ResetPassword from "../modules/parent/pages/reset-password";
import Home from "../modules/parent/pages/home";
import Invoices from "../modules/parent/pages/invoices";
import Login from "../modules/parent/pages/login";
import MyChildren from "../modules/parent/pages/my-children";
import PackageDetails from "../modules/parent/pages/package-details";
import NotFoundPage from "../modules/parent/pages/page-not-found";
import PrivacyAndPolicy from "../modules/parent/pages/privacy-and-policy";
import Profile from "../modules/parent/pages/profile";
import SignUp from "../modules/parent/pages/sign-up";
import TeacherProfile from "../modules/parent/pages/teacher-profile";
import TeacherRecruitment from "../modules/parent/pages/teacher-recruitment";
// import Teachers from "../modules/parent/pages/teachers";
import TermsAndConditions from "../modules/parent/pages/terms-and-conditions";
import ChangePassword from "../modules/parent/pages/change-password";
import { Naplan } from "src/modules/parent/pages/international";
import FreeClasses from "../modules/parent/pages/free-classes";
import FreeClassDetails from "../modules/parent/pages/free-class-details";
// import CoursesSearchPackage from "../modules/parent/pages/course-search-page";
import GradeLevelDetails from "src/modules/parent/pages/grade-level";
import Blogs from "src/modules/parent/pages/blogs";
import BlogDetailPage from "src/modules/parent/pages/blogs/components/blogDetail";
import InvoiceDetails from "src/modules/parent/pages/invoice-details";
import { getNotificationData } from "src/modules/parent/utils";
import actionTypes from "src/modules/parent/store/action-types";
import incomplete from "src/modules/parent/assets/notificationSvgs/incomplete.svg";
import IgniteDiploma from "src/modules/parent/pages/ignite-diploma";
import OLevels from "src/modules/parent/pages/olevels";
import ExamPreparation from "src/modules/parent/pages/olevels/newExamPrep";
import ExamPrepBadar from "src/modules/parent/pages/olevels/examPrepBadar";
import Chat from "src/modules/parent/pages/chat";
import IgniteForm from "src/modules/parent/pages/ignite-form";
import LearningLibrary from "src/modules/parent/pages/learning-library";
import { LearningResources } from "src/modules/parent/pages/learning-library/components";
import TeacherModal from "src/modules/parent/components/modal/areYouATeacher";
import {
  igniteLandingPageClick,
  trackEvents,
} from "src/modules/parent/utils/events/query";
import { RequestDemoModal } from "src/modules/parent/pages/home/components/modal";

import AssistMeModal from "src/modules/parent/components/modal/assistMe";
import { getTimeSlots } from "src/modules/parent/utils";

import ScrollToTop from "src/scrollToTop";

import * as actions from "../modules/parent/store/actions";
import Trivia from "src/modules/parent/pages/trivia/index.js";


const TeacherModalComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teacherModal } = useSelector((state) => state.generalReducer);

  const handleShowModal = () => {
    dispatch({ type: actionTypes.TEACHER_MODAL, payload: false });
  };

  const handleNavigate = (route) => {
    if (route && route.includes("ignite")) {
      trackEvents(igniteLandingPageClick);
    }

    navigate(route);
    handleShowModal();
  };

  return (
    <TeacherModal
      setShow={handleShowModal}
      goToRoute={handleNavigate}
      show={teacherModal}
    />
  );
};

const RedirectionProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const action = bindActionCreators(actions, dispatch);
  const { userInfo } = useSelector((state) => state.authReducer);

  const routeToUpdateProfile = useCallback(() => {
    const notification = getNotificationData({
      image: incomplete,
      title: "Update Profile",
      content: updateProfileContent,
      successText: "Update",
      handleSuccess: () => {
        window.location.pathname = "/profile";
      },
      disableCancel: true,
      disableClose: true,
    });

    dispatch({ type: actionTypes.NOTIFICATIONDATA, payload: notification });
  }, [dispatch]);

  useEffect(() => {
    if (userInfo && userInfo.id) {
      action.getChatCount(userInfo.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    const path = location.pathname;
    if (userInfo && !userInfo.phone && path !== "/profile") {
      routeToUpdateProfile();
    }
  }, [location, routeToUpdateProfile, userInfo]);

  const updateProfileContent = () => (
    <div className="mt-20">{"Please update your profile!"}</div>
  );

  return <div></div>;
};

const ProtectedRoute = ({
  user,
  redirectPath = `/login?redirect_to=${window?.location?.pathname}`,
  children,
}) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

const HandleAssistMeModal = () => {
  const dispatch = useDispatch();
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [packageDetails, setPackageDetails] = useState(null);
  const [timeSlots, setTimeSlots] = useState(null);
  const { showAssistMeModal } = useSelector(
    (state) => state.notificationReducer
  );

  const handleBookADemo = useCallback((data) => {
    if (data && data?.time_slot) {
      const time_slots = getTimeSlots(data?.time_slot);

      setPackageDetails(data);
      setTimeSlots(time_slots);
      setShowDemoModal(true);
    }
  }, []);
  const handleAssistMe = () => {
    dispatch({
      type: actionTypes.ASSIST_ME_MODAL,
      payload: false,
    });
  };

  return (
    <>
      <AssistMeModal
        show={showAssistMeModal}
        handleClose={handleAssistMe}
        handleBookADemo={handleBookADemo}
        handleShowVideo={() => {}}
      />

      {showDemoModal && (
        <RequestDemoModal
          isVisible={showDemoModal}
          onCancel={() => setShowDemoModal(false)}
          packageData={packageDetails}
          timeSlots={timeSlots}
        />
      )}
    </>
  );
};

const AppRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);

  return (
    <Router>
      <ScrollToTop />

      <Routes>
        <Route exact path={"/"} element={<Home />} />
        <Route exact path="/ignite" element={<IgniteDiploma />} />
        <Route exact path="/courses" element={<FindATutor />} />
        <Route exact path="/learning-library" element={<LearningLibrary />} />
        <Route
          exact
          path="/learning-library/resources/:subjectName"
          element={<LearningResources />}
        />
        <Route exact path="/naplan" element={<Naplan />} />

        <Route
          exact
          path="/courses/:courseName/:id"
          element={<PackageDetails />}
        />
        {/* <Route exact path="/:subject" element={<CoursesSearchPackage />} /> */}
        <Route exact path="/ignite/form" element={<IgniteForm />} />
        <Route exact path="/trivia" element={<Trivia />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blogs/:subject" element={<BlogDetailPage />} />
        <Route
          exact
          path="/:subject/:gradeLevel"
          element={<GradeLevelDetails />}
        />
        <Route exact path="/free-classes" element={<FreeClasses />} />
        <Route exact path="/free-classes/:id" element={<FreeClassDetails />} />
        {/* <Route exact path="/teachers" element={<Teachers />} /> */}
        <Route exact path="/teachers/:id" element={<TeacherProfile />} />
        <Route
          exact
          path="/teacher/recruitment"
          element={<TeacherRecruitment />}
        />
        <Route exact path={"/login"} element={<Login />} />
        <Route exact path={"/signup"} element={<SignUp />} />
        <Route exact path={"/reset-password"} element={<ResetPassword />} />
        <Route
          exact
          path={"/privacy-and-policy"}
          element={<PrivacyAndPolicy />}
        />
        <Route exact path={"/privacy"} element={<PrivacyAndPolicy />} />
        <Route
          exact
          path={"/terms-and-conditions"}
          element={<TermsAndConditions />}
        />
        <Route
          exact
          path="/teacher/recruitment"
          element={<TeacherRecruitment />}
        />
        <Route
          exact
          path={"/terms_and_condition"}
          element={<TermsAndConditions />}
        />
        <Route exact path={"/faqs"} element={<FAQs />} />
        <Route exact path={"/exam-prep"} element={<OLevels />} />
        <Route exact path={"/exam-preparation"} element={<ExamPreparation />} />
        <Route exact path={"/online-exam-prep"} element={<ExamPrepBadar />} />
        <Route exact path={"*"} element={<NotFoundPage />} />

        <Route element={<ProtectedRoute user={isAuthenticated} />}>
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/my-children" element={<MyChildren />} />
          <Route exact path="/child/add" element={<AddChild />} />
          <Route exact path="/chat" element={<Chat />} />
          <Route exact path="/chat/:id" element={<Chat />} />
          <Route exact path="/child/edit/:childId" element={<EditChild />} />
          <Route exact path="/invoices" element={<Invoices />} />
          <Route
            exact
            path="/invoices/:invoiceId"
            element={<InvoiceDetails />}
          />
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route exact path="/course/enroll/:id" element={<EnrolledCourse />} />
          {/* <Route
              exact
              path="/enrolled-course/:id"
              element={<EnrolledCourseDetail />}
            /> */}

          <Route exact path={"*"} element={<NotFoundPage />} />
        </Route>
      </Routes>

      <RedirectionProfile />

      <TeacherModalComponent />

      <HandleAssistMeModal />
    </Router>
  );
};

export default AppRoutes;
