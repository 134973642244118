import React from "react";

import { Spin } from "antd";
import { isMobile } from "react-device-detect";

import Card from "../card";
import SearchBoxMobile from "./searchBoxMobile";
import SearchBoxWeb from "./searchBoxWeb";

const CourseFilter = ({
  data = [],
  handleShowModal = () => {},
  handleSelectField = () => {},
  handleBookADemo = () => {},
  handleClear = () => {},
  handleSearch = () => {},
  handleClickGrade = () => {},
  handleClickCard = () => {},
  loading = false,
  selectedItem,
}) => (
  <div className={"app-container"}>
    {isMobile ? (
      <SearchBoxMobile
        selectedItem={selectedItem}
        handleSelectField={handleSelectField}
        handleClear={handleClear}
        handleSearch={handleSearch}
        isLoading={loading}
      />
    ) : (
      <SearchBoxWeb
        handleClick={handleClickGrade}
        selectedItem={selectedItem && selectedItem?.grades}
      />
    )}

    <Spin spinning={loading} tip={"Loading"} size="large">
      <Card
        data={data}
        handleBookADemo={handleBookADemo}
        handleShowModal={handleShowModal}
        handleClickCard={handleClickCard}
      />
    </Spin>
  </div>
);

export default React.memo(CourseFilter);
