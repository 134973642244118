import React, { Fragment, useCallback } from "react";

import { Menu, Row, Space } from "antd";
import { useSelector } from "react-redux";
import Text from "src/modules/parent/components/text";

import mark from "../assets/svgs/mark.svg";
import certificate from "../assets/svgs/certificate.svg";
import upcoming from "../assets/svgs/upcoming.svg";
import docs from "../assets/svgs/docs.svg";
import invoice from "../assets/svgs/invoice.svg";
import chat from "../assets/svgs/chat.svg";
import dnlAvatar from "../assets/svgs/dnlAvatar.svg";
import bell from "../assets/svgs/bell.svg";

import styles from "./../styles.module.css";

const NotificationMenuOptions = ({ handleNotificationClick = () => {} }) => {
  const { notifications } = useSelector((state) => state.notificationReducer);

  const getIcon = useCallback((type) => {
    let icon;

    switch (type) {
      case "demoReminder":
        icon = upcoming;
        break;

      case "demoReschedule":
        icon = dnlAvatar;
        break;

      case "certificate":
        icon = certificate;
        break;

      case "progressReport":
        icon = mark;
        break;

      case "baselineForm":
        icon = docs;
        break;

      case "invoice":
        icon = invoice;
        break;

      case "chat":
        icon = chat;
        break;

      default:
        icon = dnlAvatar;
        break;
    }

    return icon;
  }, []);

  const checkIsRead = useCallback((isRead) => {
    if (!isRead) {
      return [styles.notificationList, styles.highlighted].join(" ");
    }

    return styles.notificationList;
  }, []);

  return (
    <Fragment>
      <Menu className={styles.notification_menu_card}>
        <div className={styles.notif}>
          <Text size={"S"} font={"500"} title={"Notifications"} />
        </div>

        {notifications && notifications.length ? (
          notifications.map((item) => (
            <Menu.Item
              key={item.id}
              onClick={() => handleNotificationClick(item)}
              className={checkIsRead(item.is_read)}
            >
              <Row>
                <Space size={"middle"}>
                  <img src={getIcon(item.type)} alt={""} />

                  <Text size={"S"} font={"400"} title={item.description} />
                </Space>
              </Row>
            </Menu.Item>
          ))
        ) : (
          <div className={styles.noNotification}>
            <img src={bell} alt={""} />

            <Text
              size={"S"}
              font={"500"}
              className={"mt-10"}
              title={"No New Notification"}
            />
          </div>
        )}
      </Menu>
    </Fragment>
  );
};

export default NotificationMenuOptions;
