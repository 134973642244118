import React from "react";

import { Space, Row, Dropdown } from "antd";

import Text from "src/modules/parent/components/text";
import chevronDown from "../../assets/webp/down_icon.webp";
import { filterData } from "../../staticData";
import crossImg from "../../assets/webp/cross.webp";

import styles from "../../styles.module.css";
import "../../index.css";

const getColor = (selectedItem, name) => {
  if (selectedItem && selectedItem[name]) {
    return "rgba(0, 94, 255, 1)";
  }

  return "rgba(0, 0, 0, 1)";
};

const getTitle = (selectedItem, name, label, items = []) => {
  if (selectedItem && selectedItem[name]) {
    const selectItem = selectedItem[name];
    const key = items.find((i) => i.key === selectItem);

    if (key && key.label) {
      return key.label;
    }
  }

  return label;
};

const isFilter = (selectedItem, name) => {
  if (selectedItem && selectedItem[name]) {
    return true;
  }

  return false;
};

const FilterComponent = ({
  handleChange = () => {},
  selectedItem = null,
  handleClear = () => {},
}) => (
  <Row gutter={[0, 20]}>
    {filterData.map(({ id, icon, name, label, items }) => (
      <Row key={id.toString()} align={"middle"} className="mr-15">
        <Dropdown
          key={id}
          trigger={["click"]}
          overlayClassName="customDropdown"
          menu={{
            items,
            selectable: true,
            selectedKeys: selectedItem[name] || [],
            onSelect: (data) => handleChange({ ...data, name }),
          }}
        >
          <div className={styles.filterTag}>
            <Space align={"center"} size={"middle"}>
              <img src={icon} alt={"dollarIcon"} />

              <Text
                size={"S"}
                color={getColor(selectedItem, name)}
                title={getTitle(selectedItem, name, label, items)}
              />

              <img src={chevronDown} alt={"Icon"} />
            </Space>
          </div>
        </Dropdown>

        {isFilter(selectedItem, name) && (
          <img
            onClick={(e) => {
              e.preventDefault();
              handleClear(name);
              e.stopPropagation();
            }}
            src={crossImg}
            width={15}
            height={15}
            alt={"cross"}
          />
        )}
      </Row>
    ))}
  </Row>
);

export default React.memo(FilterComponent);
