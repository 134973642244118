/* eslint-disable no-unused-vars */
import { Col, Row, Space } from "antd";
import React, { Fragment, useEffect, useMemo } from "react";
import { useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import styles from "./styles.module.css";
import { emptyKeysRemover } from "../../utils";
import FlatButton from "src/modules/parent/components/flat-button";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import BasicFilters from "../../components/basicFilters";
import rectangle from "src/modules/parent/assets/svgs/Rectangle.svg";
import checkmark from "src/modules/parent/assets/svgs/checkmark.svg";
import Carousel from "react-multi-carousel";
import { responsive2 } from "src/modules/parent/utils";
import TeacherCard from "src/modules/parent/components/teacher-card";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { curriculum, subjectData } from "../../utils/static-data";

const initialOptions = {
  page: 1,
  limit: 10,
  level: "All",
};

const GradeLevelDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationHistory = useLocation();
  const action = bindActionCreators(actions, dispatch);
  const { subjectReducer, teacherReducer } = useSelector((state) => state);
  const { subjectTeachers } = teacherReducer;
  const { teachers } = subjectTeachers;
  const { keywordList } = subjectReducer;
  const [grade, setGrade] = useState();
  const [gradeAdvance, setGradeAdvance] = useState();
  const [subjects, setSubjects] = useState([]);
  const [subjectsAdvance, setSubjectsAdvance] = useState([]);
  const [location, setLocation] = useState();
  const [price, setPrice] = useState();
  const [days, setDays] = useState();
  const [time, setTime] = useState();
  const [image, setImage] = useState(null);
  const [showfilterModal, setShowFilterModal] = useState(false);
  const [basicFilterApplied, setBasicFilterApplied] = useState(false);
  const [advanceFilterApplied, setAdvanceFilterApplied] = useState(false);
  const [noOfAdFiltersApplied, setNoOfAdFiltersApplied] = useState(0);
  const [pageData, setPageData] = useState(null);
  const [outComesColumn1, setOutComesColumn1] = useState([]);
  const [outComesColumn2, setOutComesColumn2] = useState([]);

  const memoizedSetData = useMemo(() => {
    const setData = () => {
      const { state, pathname } = locationHistory;

      if (state) {
        const { outComes } = state?.data || {};
        setPageData(state?.data);
        action.searchTeacherBySubject(state.data?.subject);

        const matchedSubject = subjectData.find(
          (i) => i.subject.toLowerCase() === state.data?.subject.toLowerCase()
        );

        if (matchedSubject) {
          setImage(matchedSubject.image);
        }

        if (outComes) {
          const column = Math.ceil(outComes.length / 2);
          const column1 = outComes.slice(0, column);
          const column2 = outComes.slice(column);

          setOutComesColumn1(column1);
          setOutComesColumn2(column2);
        }
      } else {
        const path = pathname.split("/");
        const subjectSplit = path[1].split("-");
        const subjectName = subjectSplit.slice(1).join(" ");
        const gradeLevelSplit = path[2].split("-");
        const gradeLevel = gradeLevelSplit[gradeLevelSplit.length - 1];
        const numGrades = Number(gradeLevel);

        const matchedSubject = subjectData.find(
          (i) => i.subject.toLowerCase() === subjectName
        );

        if (matchedSubject) {
          setImage(matchedSubject.image);
        }

        const curriculumData = curriculum.find(
          (i) => i.name.toLowerCase() === subjectName.toLowerCase()
        );

        const subData = curriculumData.grades[numGrades - 1];

        if (!subData) {
          return navigate("/");
        }

        const outComes = curriculumData.grades[numGrades - 1].outComes;

        const data = {
          ...subData,
          subject: matchedSubject.subject,
          description: matchedSubject.description,
          level: gradeLevel,
          grade: `Class ${gradeLevel}`,
          curriculum: curriculumData.curriculum,
        };

        setPageData(data);
        if (outComes) {
          const column = Math.ceil(outComes.length / 2);
          const column1 = outComes.slice(0, column);
          const column2 = outComes.slice(column);

          setOutComesColumn1(column1);
          setOutComesColumn2(column2);
        }
      }
      return null;
    };

    return setData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationHistory]);

  useEffect(() => {
    memoizedSetData();
  }, [memoizedSetData]);

  const clearAdvanceFilter = () => {
    setLocation(null);
    setPrice(null);
    setDays(null);
    setTime(null);
    setGradeAdvance(null);
    setSubjectsAdvance([]);
    setShowFilterModal(false);
    setNoOfAdFiltersApplied(0);
    setAdvanceFilterApplied(false);
  };

  const filterHandler = (queryObj) => {
    action.fetchPaginatedPackages(emptyKeysRemover(queryObj));
  };

  const basicFilterHandler = (page, pageSize) => {
    const data = subjects.toLowerCase();
    const route = data.split(" ").join("-");

    navigate(`/online-${route}`, {
      state: {
        subject: subjects,
        grade,
      },
    });
  };

  const clearBasicFilter = () => {
    setGrade(null);
    setSubjects([]);
    setBasicFilterApplied(false);
  };

  const clearAllFilter = () => {
    action.fetchPaginatedPackages({ ...initialOptions });
    clearBasicFilter();
    clearAdvanceFilter();
  };

  const openFilterModalHanlder = () => {
    if (grade) {
      setGradeAdvance(grade);
    }
    if (subjects?.length) {
      setSubjectsAdvance(JSON.parse(JSON.stringify(subjects)));
    }
    setShowFilterModal(true);
  };

  const countFilters = () => {
    let count = 0;
    if (location) {
      count += 1;
    }
    //Checking undefined and null specifically to make sure this avoids for zero, because zero is acceptable here.
    if (price !== undefined && price !== null) {
      count += 1;
    }
    if (days) {
      count += 1;
    }
    if (time && time !== "anytime") {
      count += 1;
    }
    if (gradeAdvance) {
      count += 1;
    }
    if (subjectsAdvance.length) {
      count += 1;
    }
    setNoOfAdFiltersApplied(count);
  };

  const gotoTeachers = () => {
    navigate("/teachers");
  };

  return (
    <div>
      <Helmet>
        <title>{`Sign Up for Online ${pageData?.subject} ${pageData?.grade} - Boost Your Child's Skills Now! | Dot & Line`}</title>
        <meta
          name="description"
          content={`Transform your child's ${pageData?.subject} skills with Dot & Line's online ${pageData?.subject} classes for ${pageData?.grade}. Get expert tutors, personalized attention & innovative teaching. Sign up now!`}
        />
      </Helmet>

      <Header />

      <div className="app-container mt-50 mb-50">
        <Row gutter={[24, 24]}>
          <BasicFilters
            grade={grade}
            setGrade={setGrade}
            gradeFilterDisabled
            subjects={subjects}
            setSubjects={setSubjects}
            keywordList={keywordList}
            basicFilterHandler={basicFilterHandler}
            basicFilterApplied={basicFilterApplied}
            clearAllFilter={clearAllFilter}
          />
        </Row>
      </div>

      <div className={styles.mainHeadingContainer}>
        <div className="app-container pt-50 pb-50">
          <Row>
            <Col>
              <Link to={"/courses"}>{"All Courses"}</Link>
            </Col>
            <div className={styles.slash}>{"/"}</div>
            <Col>
              <Link
                to={`/online-${pageData?.subject
                  .split(" ")
                  .join("-")
                  .toLowerCase()}`}
              >{`${pageData?.subject}`}</Link>
            </Col>

            <div className={styles.slash}>{"/"}</div>
            <Col>{pageData?.grade}</Col>
          </Row>

          <Row gutter={[20, 20]}>
            <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
              <h1 className="mt-20 mb-30" style={{ color: "#005EFF" }}>
                {pageData?.subject.toUpperCase()}
              </h1>

              <p className={styles.description}>{pageData?.description}</p>

              <FlatButton
                title={"Find Teacher"}
                type={"Quaternary"}
                className={"mt-30"}
                onClick={gotoTeachers}
              />
            </Col>

            <Col
              className={styles.illustration}
              xxl={8}
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
            >
              <img
                src={image ? image : rectangle}
                style={{ width: "300px" }}
                alt={"subject"}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="app-container mt-50 mb-20">
        <Row>
          <h2 className="sub-title">{`Dot and Line ${pageData?.subject} Curriculum`}</h2>

          <p>{pageData?.curriculum}</p>

          <h2 className="sub-title">{`${pageData?.subject} Level ${pageData?.level}`}</h2>
        </Row>
      </div>

      <div className="app-container mb-50">
        <Row gutter={[0, 20]}>
          {pageData?.categories.map((item, iInd) => (
            <Fragment key={item.id}>
              <Col span={24}>
                <h3 className={"sub-category"}>{item.category}</h3>
              </Col>

              <Space direction={"vertical"}>
                {item.subCategories.map((i, index) => (
                  <div className="d-flex" key={`${iInd}-${index}`}>
                    <div className={styles.numLength}>{index + 1}.</div>
                    <div>{i}</div>
                  </div>
                ))}
              </Space>
            </Fragment>
          ))}
        </Row>
      </div>

      <div className="app-container mt-30">
        <h2 className="sub-title">{`Our Top ${pageData?.subject} Teachers`}</h2>

        <Col span={24}>
          <Carousel responsive={responsive2}>
            {teachers.map((teacher) => (
              <TeacherCard key={teacher.id} teacher={teacher} />
            ))}
          </Carousel>
        </Col>
      </div>

      <div className={["app-container p-50 mt-30", styles.learning].join(" ")}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <h2>{"Learning Outcomes"}</h2>
          </Col>

          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row gutter={[20, 20]}>
                {outComesColumn1?.map((i, index) => (
                  <Col key={index} span={24}>
                    <Space size={"middle"}>
                      <img src={checkmark} alt={"checkmark"} />

                      <div>{i}</div>
                    </Space>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row gutter={[20, 20]}>
                {outComesColumn2?.map((i, index) => (
                  <Col key={index} span={24}>
                    <Space size={"middle"}>
                      <img src={checkmark} alt={"checkmark"} />

                      <div>{i}</div>
                    </Space>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Row>
      </div>

      <Footer />
    </div>
  );
};

export default GradeLevelDetails;
