import React from "react";

import { Col, Modal, Row } from "antd";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const categories = [
  {
    id: "0",
    title: "Kindergarten - Grade 7",
    description:
      "Personalised tutoring with top tutors for math, English, Urdu, coding and more. Clear concepts, spark critical thinking and develop a love for learning!",
    color: "rgba(0, 94, 255, 1)",
    bgColor: "rgba(228, 238, 255, 1)",
  },
  {
    id: "1",
    title: "Grade 8 - Grade 13",
    description:
      "CIE exams focused, these courses target ace results through solving past papers; concept strengthening, paper predictions and exam tips.",
    color: "rgba(34, 222, 185, 1)",
    bgColor: "rgba(229, 255, 250, 1)",
  },
];

const MobileItem = ({ item, handleClick }) => (
  <Row
    justify={"center"}
    align={"middle"}
    className={styles.mobileTab}
    onClick={() => handleClick(item)}
    style={{ backgroundColor: item.bgColor, border: `2px solid ${item.color}` }}
  >
    <Text size={"M"} font={"NORMAL"} color={item.color} title={item.title} />
  </Row>
);

const GetStartedModal = ({ show = false, setShow, handleClick = () => {} }) => (
  <Modal
    closable
    onCancel={() => setShow(false)}
    maskClosable
    centered
    footer={false}
    width={isMobile ? "100%" : "700px"}
    className={"g-modal"}
    open={show}
  >
    <div className="mt-20">
      <Row justify={"center"}>
        <Text
          size={"M"}
          className={"mt-10 mb-30"}
          font={"SEMIBOLD"}
          title={"What grade levels are you searching for?"}
        />
      </Row>

      <Row gutter={[20, 0]}>
        {categories.map((item) => (
          <Col key={item.id} xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <MobileItem
              key={item.id}
              item={item}
              handleClick={() => handleClick(item.id)}
            />
          </Col>
        ))}
      </Row>
    </div>
  </Modal>
);

export default React.memo(GetStartedModal);
