import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row, Space } from "antd";
import Header from "../../components/header";
import Footer from "../../components/footer";
import * as actions from "../../store/actions";
import { useDispatch } from "react-redux";
import FlatButton from "../../components/flat-button";
import noData from "src/modules/parent/assets/svgs/nodata.svg";
import AppLoader from "../../components/app-loader";
import moment from "moment-timezone";
import parser from "html-react-parser";
import styles from "./styles.module.css";
import ModalComponent from "../../components/modalComponent";
import { Link, useParams } from "react-router-dom";
import actionTypes from "../../store/action-types";
import { invoiceLinkNotification } from "../../utils/notificationData";
import Guidelines from "../../components/guidelines";
import Text from "../../components/text";
import {
  invoicePayNowBtnRedirect,
  trackEvents,
} from "../../utils/events/query";

const getPenaltyAmount = (data) => {
  if (data && data?.penalty) {
    const penaltyAmount = data?.penalty + data?.amount;
    return `${data?.currency} ${penaltyAmount}`;
  } else {
    if (data && data?.amount) {
      if (data?.currency === "PKR") {
        return `${data?.currency} ${data?.amount + 500}`;
      } else {
        return `${data?.currency} ${data?.amount + 4}`;
      }
    }
  }
  return `${data?.currency} 0`;
};

const setInvoiceData = (invoice) => {
  const payments = invoice?.payments[0];

  const data = [
    {
      id: "0",
      name: "Invoice Number",
      value: `INV ${invoice.id}`,
    },
    {
      id: "1",
      name: "Invoice Date",
      value: moment(invoice.invoice_date).format("DD/MM/YYYY"),
    },
    {
      id: "2",
      name: "Due Date",
      value: moment(invoice.due_date).format("DD/MM/YYYY"),
    },
    {
      id: "3",
      name: "Invoice Month",
      value: moment(invoice.invoice_date).format("MMMM"),
    },
    {
      id: "4",
      name: "Discount",
      value: invoice.discount,
    },
    {
      id: "5",
      name: "Amount",
      value:
        invoice.payment_method === "stripe"
          ? `USD ${invoice.amount}`
          : `PKR ${invoice.amount}`,
    },
    {
      id: "6",
      name: "Amount after due date",
      value: getPenaltyAmount(invoice),
    },
    {
      id: "7",
      name: "Teacher Partner",
      value: payments?.enroll?.package?.teacher?.name || "",
    },
    {
      id: "8",
      name: "Child",
      value: invoice?.student?.name,
    },
    {
      id: "9",
      name: "Status",
      value: invoice.payment_status,
    },
    {
      id: "10",
      name: "Transaction ID",
      value: invoice.transaction_id ? invoice.transaction_id : "-",
    },
    {
      id: "11",
      name: "Paid on",
      value: invoice.paid_at
        ? moment(invoice.paid_at).format("DD/MM/YYYY")
        : "-",
    },
  ];

  return {
    data,
    title: payments?.enroll?.package?.title || "",
    description: payments?.enroll?.package?.description || "",
    enroll: payments?.enroll?.student_type,
  };
};

const maxLength = 400;

const InvoiceDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [invoice, setInvoice] = useState(null);
  const [show, setShow] = useState(false);
  const [viewRoute, setViewRoute] = useState("");
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [list, setList] = useState(null);
  const [title, setTitle] = useState("");
  const [showDropOut, setShowDropOut] = useState(false);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [truncatedText, setTruncatedText] = useState("");
  const elementRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const element = elementRef.current;
      const textContent = element.textContent;

      if (textContent.length > maxLength) {
        setTruncatedText(textContent.slice(0, maxLength) + "...");
      } else {
        setTruncatedText(textContent);
      }
    }
  }, [truncatedText]);

  useEffect(() => {
    if (description) {
      setTruncatedText(description);
    }
  }, [description]);

  useEffect(() => {
    if (params && params.invoiceId) {
      const invoiceId = params.invoiceId;

      actions
        .getInvoiceDetails(invoiceId)
        .then((invoiceData) => {
          const invoiceDetail = invoiceData.data;
          const { data, title, description } = setInvoiceData(invoiceDetail);

          setDescription(description);
          setInvoice(invoiceDetail);
          setTitle(title);
          setList(data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [params]);

  const handlePayNow = () => {
    if (invoice) {
      const linkIsExist =
        invoice.payment_method === "stripe"
          ? invoice.payment_link
          : invoice.link;

      if (linkIsExist) {
        window.open(linkIsExist, "_blank");

        trackEvents(invoicePayNowBtnRedirect);
      } else {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: invoiceLinkNotification,
        });
      }
    }
  };

  const showModal = useCallback(() => {
    const link =
      invoice.payment_method === "stripe" ? invoice.payment_link : invoice.link;

    setViewRoute(link);
    setShow(true);
  }, [invoice]);

  const handleDownload = () => {
    if (invoice && invoice.payment_receipt) {
      window.open(invoice.payment_receipt, "_blank");
    }
  };

  const handleClose = () => {
    setShowDropOut(false);
  };

  const handleClosePayModal = () => {
    setShow(false);
  };

  const getHeight = useMemo(() => {
    if (invoice) {
      return `${
        ref1?.current?.offsetHeight - ref2?.current?.offsetHeight - 20
      }px`;
    }
  }, [invoice]);

  const dropoutContent = () => (
    <p>{"Looks like this student has dropped out of this course"}</p>
  );

  const descriptionContent = () => (
    <p className={styles.paymentText}>
      {
        "Looks like you have not made the payment for this course yet. Please ignore this message if you have recently made a payment. Our verification timeline is up to 8 hours to update the status of your invoice Thank you!"
      }
    </p>
  );

  return (
    <div className={"bg-light-blue"}>
      <ModalComponent
        show={show}
        title={"Payment Due"}
        type={"error"}
        handleClose={handleClosePayModal}
        link={viewRoute}
        handleSuccessBtn={handlePayNow}
        successBtnText={"Pay Now"}
        content={descriptionContent}
      />

      <ModalComponent
        show={showDropOut}
        title={"Dropout"}
        error
        handleClose={handleClose}
        disableCancelBtn
        handleSuccessBtn={handleClose}
        successBtnText={"Okay"}
        content={dropoutContent}
      />

      {isLoading && <AppLoader fullPage text={"Loading Invoice..."} />}

      <Header />

      <div className="app-container mb-50">
        <div className={[styles.invoice_table, "pt-45 pb-45"].join(" ")}>
          <Row>
            <Col>
              <div className={styles.routeName}>
                <Link to={"/"}>{"Home"}</Link>
              </div>
            </Col>
            <div className={styles.slash}>{"/"}</div>
            <Col>
              <div className={styles.routeName}>
                <Link to={"/invoices"}>{"Invoices"}</Link>
              </div>
            </Col>
            <div className={styles.slash}>{"/"}</div>
            <Col>{list ? list[0].value : "  "}</Col>
          </Row>

          <Row justify={"center"}>
            <div className={["mt-20 mb-20 font-24", styles.heading].join(" ")}>
              {"Billing and Invoices"}
            </div>
          </Row>

          <Row justify={"center"}>
            <p className={styles.viewAllText}>
              {
                "View all your invoices and receipts and make sure you make all your payments on time! Read "
              }
              <span
                onClick={() => setShowGuidelines(true)}
                className={styles.terms}
              >
                {"Terms & Conditions"}
              </span>
            </p>
          </Row>
        </div>

        {invoice && invoice.payment_status === "paid" && (
          <Row className="mb-10" justify={"end"}>
            <FlatButton
              title={"Download Receipt"}
              loading={false}
              type={"Secondary"}
              onClick={handleDownload}
              className={["mb-2", styles.buttonBorderBlue].join(" ")}
            />
          </Row>
        )}

        {!invoice && !isLoading ? (
          <Row justify={"center"} className="mt-50">
            <img src={noData} alt={"nodata"} />
          </Row>
        ) : (
          <Row gutter={[20, 20]}>
            <Col
              className={styles.column}
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
            >
              <div ref={ref1} className={styles.detailContainer}>
                <div className={["mb-50 font-24", styles.heading].join(" ")}>
                  {"Invoice"}
                </div>

                {list &&
                  list.length > 0 &&
                  // eslint-disable-next-line array-callback-return
                  list.slice(0, 9).map((item) => {
                    if (item.id === "6") {
                      if (invoice && invoice.is_break !== 1) {
                        return (
                          <Row
                            key={item.id}
                            gutter={[0, 10]}
                            className={[styles.invoiceRow, "mb-10"].join(" ")}
                            justify={"space-between"}
                          >
                            <div>{item.name}</div>
                            <div>{item.value}</div>
                          </Row>
                        );
                      }
                    } else {
                      return (
                        <Row
                          key={item.id}
                          gutter={[0, 10]}
                          className={[styles.invoiceRow, "mb-10"].join(" ")}
                          justify={"space-between"}
                        >
                          <div>{item.name}</div>
                          <div>{item.value}</div>
                        </Row>
                      );
                    }
                  })}
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <div
                ref={ref2}
                className={[styles.detailContainer, "mb-20"].join(" ")}
              >
                {list &&
                  list.length > 0 &&
                  list.slice(9).map((item, index) => (
                    <Row
                      key={index}
                      align={"middle"}
                      gutter={[0, 10]}
                      className={[
                        styles.invoiceRow,
                        styles.cardDateRow,
                        "mb-10",
                      ].join(" ")}
                      justify={"space-between"}
                    >
                      <div>{item.name}</div>

                      {item.value === "paid" ? (
                        <div className={styles.paid}>{"Paid"}</div>
                      ) : item.value === "unpaid" ? (
                        <Row align={"middle"}>
                          <Space size={"large"}>
                            <div className={styles.unPaid}>{"Unpaid"}</div>

                            <FlatButton
                              title={"Pay Now"}
                              loading={false}
                              type={"Quaternary"}
                              onClick={showModal}
                              className={"mb-2"}
                            />
                          </Space>
                        </Row>
                      ) : (
                        <div>{item.value}</div>
                      )}
                    </Row>
                  ))}
              </div>

              <div
                className={styles.detailContainer}
                style={{
                  height: getHeight,
                  marginTop: 20,
                }}
              >
                <div
                  className={[
                    "mb-40 font-24",
                    styles.heading,
                    styles.truncate,
                  ].join(" ")}
                >
                  {title || ""}
                </div>

                <div
                  ref={elementRef}
                  className={["mt-20 mb-20", styles.description].join(" ")}
                >
                  {parser(truncatedText) || ""}
                </div>
              </div>
            </Col>

            <Col span={24}>
              <Text
                size={"M2"}
                lHeight={"S"}
                color={"rgba(255, 0, 0, 1)"}
                title={
                  "There is a penalty charge of PKR 500 automatically added to the payable amount after the due date. All fee paid is non-refundable and adjustable against any class on the platform."
                }
              />
            </Col>
          </Row>
        )}
      </div>

      <Footer />

      <Guidelines setShow={setShowGuidelines} show={showGuidelines} />
    </div>
  );
};

export default InvoiceDetails;
