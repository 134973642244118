import React from "react";

import { Col, Row, Space, Button } from "antd";
import Carousel from "react-multi-carousel";

import { avatarList } from "src/modules/parent/utils";
import mathImage from "src/modules/parent/assets/svgs/maths.svg";
import { IMAGE_BASE_URL } from "src/modules/parent/config/constant";
import noImage from "../../../../../assets/img/anonymous.jpeg";
import moment from "moment-timezone";
import FlatButton from "src/modules/parent/components/flat-button";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import actionTypes from "src/modules/parent/store/action-types";
import { invoiceLinkNotification } from "src/modules/parent/utils/notificationData";
import Text from "src/modules/parent/components/text";
import { responsiveInvoices } from "src/modules/parent/utils";

import styles from "./styles.module.css";
import NoData from "./noData";
import {
  invoicePayNowBtnRedirect,
  trackEvents,
} from "src/modules/parent/utils/events/query";

const Card = ({
  invoice_date,
  payment_status,
  id,
  payments,
  student,
  payment_link,
  link,
  payment_method,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payment = payments && payments[0];
  const child = student;
  const linkIsExist = payment_method === "stripe" ? payment_link : link;

  const gotoInvoiceDetails = (e) => {
    navigate(`/invoices/${id}`);

    e.stopPropagation();
  };

  const handlePayNow = (e) => {
    if (linkIsExist) {
      window.open(linkIsExist, "_blank");

      trackEvents(invoicePayNowBtnRedirect);
    } else {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: invoiceLinkNotification,
      });
    }

    e.stopPropagation();
  };

  return (
    <div onClick={gotoInvoiceDetails} className={styles.cardContainer}>
      <Row
        align={"middle"}
        justify={"space-between"}
        className={styles.cardDateRow}
      >
        <div>{moment(invoice_date).format("DD/MM/YYYY")}</div>
        {payment_status === "paid" ? (
          <div id={styles.paid}>{"Paid"}</div>
        ) : (
          <Row gutter={[0, 10]} align={"middle"}>
            <Space size={"middle"}>
              <div id={styles.unPaid}>{"Unpaid"}</div>
              <FlatButton
                title={"Pay Now"}
                loading={false}
                type={"Quaternary"}
                onClick={handlePayNow}
                className={["mb-2", styles.button].join(" ")}
              />
            </Space>
          </Row>
        )}
      </Row>

      <Col className="mt-10" span={24}>
        <Row align={"middle"} className={styles.cardBody}>
          <div className={styles.packageImage}>
            <img
              src={mathImage}
              style={{ width: "100%", height: "100%" }}
              alt={"PackageImage"}
            />
          </div>

          <div className={styles.cardTitle}>
            <div className={styles.truncate}>
              {payment?.enroll?.package?.title || ""}
            </div>
            <p>{`INV ${id}`}</p>
          </div>

          <div className={styles.avatar}>
            <img
              src={
                child?.avatar
                  ? avatarList[child?.avatar]?.image
                  : child?.image
                  ? `${IMAGE_BASE_URL}${child?.image}`
                  : noImage
              }
              style={{ width: "100%", height: "100%" }}
              alt={"ChildImage"}
            />
          </div>
        </Row>
      </Col>
    </div>
  );
};

const Invoice = ({
  data = [],
  onHandleClick = () => {},
  handlePay = () => {},
  onClose = () => {},
}) => (
  <>
    {data && !data.length ? (
      <NoData
        title={"There are no invoices pending."}
        btnText={"Okay, thanks!"}
        handleBtnClick={onClose}
        description={
          "We appreciate your continued commitment to your child's education!"
        }
      />
    ) : (
      <>
        <div className={styles.title}>Pending invoices</div>

        <div className={"mt-10 mb-10"}>
          <h3>{data[0].date}</h3>
        </div>

        <div className={"mb-20"}>
          {data && data.length > 0 && (
            <Carousel responsive={responsiveInvoices}>
              {data[0].invoiceData.map((invoice, index) => (
                <Card {...invoice} key={index} handlePay={handlePay} />
              ))}
            </Carousel>
          )}
        </div>

        <Button onClick={onHandleClick} className={styles.button}>
          <Text
            size={"S"}
            lHeight={"S"}
            color={"#FFFFFF"}
            title={"View all invoices"}
          />
        </Button>
      </>
    )}
  </>
);

export default React.memo(Invoice);
