import React from "react";

import { Button, Space } from "antd";

import Text from "src/modules/parent/components/text";
import ModalComponent from "src/modules/parent/components/modal";
import successImg from "src/modules/parent/assets/notificationSvgs/tada.png";

import styles from "../styles.module.css";

const SuccessModal = ({
  show = true,
  setShow = () => {},
  onSuccess = () => {},
}) => (
  <ModalComponent
    onCancel={() => setShow(false)}
    closable={false}
    isModalVisible={show}
  >
    <Space
      className={"w-100 mt-20"}
      size={"large"}
      align={"center"}
      direction={"vertical"}
    >
      <Text
        size={"XXL"}
        className={"mb-20"}
        center
        font={"SEMIBOLD"}
        title={"You’re In!"}
      />

      <img src={successImg} alt={"Success"} />

      <div>
        <Text
          size={"S"}
          center
          lHeight={"S"}
          font={"LIGHTER"}
          title={
            "Congratulations! You have successfully registered for the Trivia Competition 2025. Get ready to test your knowledge and compete for the top spot! 🏆"
          }
        />

        <Text
          size={"S"}
          center
          lHeight={"S"}
          className={"mt-20"}
          font={"LIGHTER"}
          title={
            "You have been sent an email on your registered address with all details! See you in the competition! 🚀"
          }
        />
      </div>

      <Button onClick={onSuccess} className={styles.successButton}>
        {"Great!"}
      </Button>
    </Space>
  </ModalComponent>
);

export default SuccessModal;
