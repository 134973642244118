import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { signOut } from "firebase/auth";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import useFetchAuth from "./checkAuth";
import Header from "../../components/header";
import AppLoader from "../../components/app-loader";
import { postRequest } from "../../config/network";
import { LOG_OUT } from "../../config/api";
import { axiosErrorHandler } from "../../utils";
import { auth } from "../../config/firebase";

import { AuthenticatedHomePage, UnAuthenticatedHomePage } from "./pages";

import * as actions from "../../store/actions";
import { Modal } from "antd";
import TriviaHomeBanner from "../trivia/components/trivia-home-banner";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = bindActionCreators(actions, dispatch);
  const { isAuth, authLoading } = useFetchAuth();
  const { authReducer } = useSelector((state) => state);
  const { isAuthenticated, userInfo } = authReducer;
  const [loading, setLoading] = useState(false);
  const [isTriviaModalVisible, setIsTriviaModalVisible] = useState(false);

  const onLogoutSuccess = () => {
    action.onLogOut().then(() => {
      setLoading(false);
      navigate("/");
    });
  };

  const onLogoutFailure = () => {
    setLoading(false);
  };

  const logout = async () => {
    if (userInfo && userInfo?.type === "loginViaEmail") {
      setLoading(true);
      postRequest(LOG_OUT, { source: "parent_web" })
        .then(() => onLogoutSuccess())
        .catch((err) => {
          axiosErrorHandler(err);
          onLogoutFailure();
        });
    } else {
      setLoading(true);
      signOut(auth)
        .then(() => {
          setLoading(false);
          onLogoutSuccess();
        })
        .catch(() => onLogoutFailure());
    }
  };

  useEffect(() => {
    if (isAuthenticated && userInfo && !userInfo?.uuid) {
      logout();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    setIsTriviaModalVisible(true); // Show modal when component mounts
  }, []);

  const handleCancel = () => {
    setIsTriviaModalVisible(false);
  };

  return (
    <div>
      <Helmet>
        <title>{`Pakistan’s Top Online Learning Platform for Kindergarten to 12th Grade | Dot and Line`}</title>
        <meta
          name="description"
          content={`Pakistans top online tutoring platform with experienced tutors and guaranteed results . Sign up now for academic and professional courses from K-12, including O-Levels, A-Levels. Visit dotandlinelearning.com to begin your educational journey today!`}
        />
      </Helmet>

      {loading ||
        (authLoading && (
          <AppLoader
            fullPage
            text={
              authLoading
                ? "Loading..."
                : "You are being logged out. Please login again!"
            }
          />
        ))}

      <Header />

      {!authLoading && (
        <>
          {isAuthenticated && isAuth ? (
            <AuthenticatedHomePage />
          ) : (
            <UnAuthenticatedHomePage />
          )}

          <Footer />
        </>
      )}

      <Modal
        open={isTriviaModalVisible}
        footer={null} // Removes OK and Cancel buttons
        closable
        centered
        onCancel={() => handleCancel()}
        className={"triviaModalHome"}
        width={"auto"}
      >
        <TriviaHomeBanner />
      </Modal>
    </div>
  );
};

export default Home;
