import React from "react";

import { Button } from "antd";
import moment from "moment-timezone";
import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";
import NoData from "./noData";

const ClassesTaken = ({
  data = null,
  onHandleClick = () => {},
  onHandleClose = () => {},
  onTeacherClick = () => {},
  onHandleAssistMe = () => {},
}) => (
  <>
    {!data ? (
      <NoData
        btnText={"Assist me!"}
        btnText2={"No, thanks."}
        handleBtnClick2={onHandleClose}
        handleBtnClick={() => {
          onHandleAssistMe();
          onHandleClose();
        }}
        description={
          "Explore our personalised offerings to find the perfect fit for your child. We are here to assist you!"
        }
        title={
          "No classes have been taken yet, but it's never too late to begin!"
        }
      />
    ) : (
      <>
        <div className={styles.classesTaken}>
          Classes taken from {moment(data?.from).format("Do MMMM")} to{" "}
          {moment(data?.to).format("Do MMMM YYYY")} with Teacher{" "}
          <span
            className={"cursor-pointer text-underline"}
            onClick={() => onTeacherClick(data?.teacher_id)}
          >
            {data?.teacher_name}
          </span>
        </div>

        <Button
          onClick={() => onHandleClick(data?.enroll_id)}
          className={styles.button}
        >
          <Text
            size={"S"}
            lHeight={"S"}
            color={"#FFFFFF"}
            title={"View class"}
          />
        </Button>
      </>
    )}
  </>
);

export default React.memo(ClassesTaken);
