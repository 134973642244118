import React, { Fragment, useEffect, useRef } from "react";

import Carousel from "react-multi-carousel";

import { responsiveForPackageCards } from "src/modules/parent/utils";
import PackageCard from "../../../components/package-card";

const MyPackages = ({ packages, scrollToDiv }) => {
  const myRef = useRef(null);

  useEffect(() => {
    if (scrollToDiv) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollToDiv]);

  return (
    <Fragment>
      {packages && packages?.length !== 0 && (
        <Fragment>
          <p ref={myRef} className={"f-md blue-color mb-0 mt-30"}>
            Classes by Me{" "}
            <span className="f-sm font-weight-600-imp">
              ({packages?.length}):
            </span>
          </p>

          <div>
            <Carousel responsive={responsiveForPackageCards}>
              {packages?.map((item) => (
                <PackageCard
                  item={item}
                  key={item?.id}
                  teacher={item?.teacher}
                />
              ))}
            </Carousel>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default MyPackages;
