/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, message } from "antd";
import FlatButton from "../../components/flat-button";
import gif from "../../assets/gif/7.gif";
import * as actions from "../../store/actions";
import CardsWrapper from "src/modules/parent/components/cards-wrapper";
import TextInput from "src/modules/parent/components/text-input";
import Header from "../../components/header";
import Footer from "../../components/footer";
import styles from "./styles.module.css";
import actionTypes from "../../store/action-types";
import {
  passwordMisMatchNotification,
  fillRequiredNotification,
} from "../../utils/notificationData";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isError, setIsError] = useState(false);

  const isValidated = () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: fillRequiredNotification,
      });
      setIsError(true);
      return false;
    } else {
      if (newPassword !== confirmPassword) {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: passwordMisMatchNotification,
        });
        setConfirmPassword("");
        return false;
      }
    }
    return true;
  };

  const clearFields = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const submitHandler = async () => {
    if (isValidated()) {
      setLoading(true);
      const data = {
        old_password: oldPassword,
        password: newPassword,
        confirm_password: confirmPassword,
      };
      action
        .changePassword(data)
        .then((response) => {
          setLoading(false);
          clearFields();
          message.success(response.message);
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div>
      <Header />
      <div className="inner-container">
        <CardsWrapper>
          <Fragment>
            <div className={"form-wrapper"}>
              <p
                className={
                  "f-xl font-weight-500-imp mt-40 mb-35 green-color text-align-center"
                }
              >
                Change Password
              </p>
              <Form className="text-align-center">
                <Form.Item
                  hasFeedback={oldPassword.length < 1 && isError ? true : false}
                  validateStatus={
                    oldPassword.length < 1 && isError ? "error" : "success"
                  }
                >
                  <TextInput
                    required
                    allowClear
                    type={"password"}
                    value={oldPassword}
                    label={"Old Password"}
                    placeholder={"Enter old password"}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback={newPassword.length < 1 && isError ? true : false}
                  validateStatus={
                    newPassword.length < 1 && isError ? "error" : "success"
                  }
                >
                  <TextInput
                    required
                    allowClear
                    type={"password"}
                    value={newPassword}
                    label={"New Password"}
                    placeholder={"Enter new password"}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback={
                    confirmPassword.length < 1 && isError ? true : false
                  }
                  validateStatus={
                    confirmPassword.length < 1 && isError ? "error" : "success"
                  }
                >
                  <TextInput
                    required
                    allowClear
                    type={"password"}
                    value={confirmPassword}
                    label={"Confirm Password"}
                    placeholder={"Confirm password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
                <div>
                  <FlatButton
                    isForm
                    width={"200px"}
                    title={"Submit"}
                    loading={loading}
                    onClick={submitHandler}
                    className={"mt-20 mb-40"}
                  />
                </div>
              </Form>
            </div>
            <div className={"image-wrapper"}>
              <img src={gif} alt="GIF" />
            </div>
          </Fragment>
        </CardsWrapper>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
