import React from "react";

import { Col, Row } from "antd";

import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const categories = [
  {
    id: "0",
    title: "Kindergarten - Grade 7",
    description:
      "Personalised tutoring with top tutors for math, English, Urdu, coding and more. Clear concepts, spark critical thinking and develop a love for learning!",
    color: "rgba(0, 94, 255, 1)",
    bgColor: "rgba(228, 238, 255, 1)",
  },
  {
    id: "1",
    title: "NAPLAN Test Prep",
    description:
      "Prepare students for global tests with trained teachers. Cover test format, content and practice all aimed at boosting confidence for exam day.",
    color: "rgba(227, 183, 0, 1)",
    bgColor: "rgba(255, 248, 221, 1)",
  },
  {
    id: "2",
    title: "Grade 8 - Grade 13",
    description:
      "CIE exams focused, these courses target ace results through solving past papers; concept strengthening, paper predictions and exam tips.",
    color: "rgba(34, 222, 185, 1)",
    bgColor: "rgba(229, 255, 250, 1)",
  },
];

const MobileItem = ({ item, handleClick }) => (
  <Row
    justify={"start"}
    align={"middle"}
    className={styles.mobileTab}
    onClick={() => handleClick(item)}
    style={{ backgroundColor: item.bgColor, border: `2px solid ${item.color}` }}
  >
    <Text size={"M"} font={"NORMAL"} color={item.color} title={item.title} />
  </Row>
);

const ClassCategories = ({ handleClick = () => {} }) => (
  <Row gutter={[20, 0]}>
    {categories.map((item) => (
      <Col key={item.id} xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
        <MobileItem key={item.id} item={item} handleClick={handleClick} />
      </Col>
    ))}
  </Row>
);
export default React.memo(ClassCategories);
