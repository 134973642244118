import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { IMAGE_BASE_URL } from "src/modules/parent/config/constant";
import { avatarList } from "src/modules/parent/utils";
import styles from "./../styles.module.css";
import noImage from "./../../../assets/img/anonymous.jpeg";
import { CheckOutlined } from "@ant-design/icons";
import FlatButton from "src/modules/parent/components/flat-button";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

const Step2 = ({ selectedChildId, setSelectedChildId, blockChildIds = [] }) => {
  const navigate = useNavigate();
  const { children } = useSelector((state) => state.profileReducer);

  const getStyle = useCallback(
    (id) => {
      if (!blockChildIds.some((i) => i === id)) {
        return styles.child_card;
      } else {
        return styles.child_card_disabled;
      }
    },
    [blockChildIds]
  );

  return (
    <div>
      <Row gutter={[10, 10]} className="mb-10">
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
          <p className="mb-0">Click the card below to select.</p>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
          <div className="d-flex justify-content-end">
            <FlatButton
              title={"Add Child"}
              style={{
                lineHeight: 1,
                marginRight: "12px",
                height: "37px",
                fontSize: "16px",
                fontWeight: "500",
              }}
              onClick={() =>
                navigate(`/child/add?redirect_to=${window?.location?.pathname}`)
              }
            />
          </div>
        </Col>
      </Row>
      <div className={[styles.scrollable, "mb-10"].join(" ")}>
        {children && children?.length !== 0 ? (
          children.map((child, index) => (
            <div
              key={index.toString()}
              onClick={
                !blockChildIds.some((id) => id === child.id)
                  ? () => setSelectedChildId(child?.id)
                  : () => {}
              }
              className={getStyle(child.id)}
            >
              <div className="d-row align-items-center">
                <img
                  className={styles.avatar}
                  src={
                    child?.avatar
                      ? avatarList[child?.avatar]?.image
                      : child?.image
                      ? `${IMAGE_BASE_URL}${child?.image}`
                      : noImage
                  }
                  alt=""
                />
                <p className={"font-15 font-weight-500 mb-0"}>
                  {child?.name || ""}{" "}
                  <span className="dark2-grey-color font-12 font-weight-400">
                    (Level: {child?.level})
                  </span>
                </p>
              </div>
              {Number(selectedChildId) === Number(child?.id) && (
                <div className={styles.check}>
                  <CheckOutlined className="font-24" />
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="mb-0">Please add a child first.</p>
        )}
      </div>
    </div>
  );
};

export default Step2;
