import React, { useEffect, useRef, useState } from "react";

import ModalWindow from "./modalWindow";
import FloatingButton from "src/modules/parent/pages/olevels/components/floatingButton";

const ChatWidget = () => {
  const [isVisible, setIsVisible] = useState(false);
  const widgetRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (widgetRef.current && !widgetRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [widgetRef]);

  return (
    <div ref={widgetRef}>
      {isVisible && <ModalWindow show={isVisible} setShow={setIsVisible} />}

      {/* Chat Button Component */}
      <FloatingButton
        tooltip={false}
        handlePress={() => setIsVisible(!isVisible)}
      />
    </div>
  );
};

export default ChatWidget;
