import { Select } from "antd";
import React, { useRef } from "react";
import { isMobile } from "react-device-detect";

import Text from "../text";

import styles from "./styles.module.css";

const SelectInput = ({
  mode,
  value,
  label,
  options,
  required,
  onChange,
  allowClear,
  showSearch,
  placeholder,
  suffixIcon,
  onSelect,
  error,
  className = "",
  ...rest
}) => {
  const ref = useRef();
  const changeHandler = (e) => {
    // ref?.current?.blur();
    onChange(e);
  };

  const dropdownStyle = {
    border: "1px solid #B3B3B3",
    backgroundColor: "#fff",
    color: "#B3B3B3",
    borderRadius: "10px",
  };

  return (
    <>
      {label && <p className={styles.input_label}>{label}</p>}
      <Select
        {...rest}
        ref={ref}
        mode={mode}
        value={value}
        size={"large"}
        required={required}
        className={className}
        showSearch={showSearch}
        onSelect={isMobile ? onSelect : () => {}}
        suffixIcon={suffixIcon}
        allowClear={allowClear}
        placeholder={placeholder}
        onChange={changeHandler}
        dropdownStyle={dropdownStyle}
        options={options}
      />

      {error && (
        <Text className="mt-8" color="RED" size="XS">
          {error}
        </Text>
      )}
    </>
  );
};

export default SelectInput;
