import { message } from "antd";
import {
  ADD_CHILD,
  CONTACT_US,
  CREATE_DEMO,
  GET_CHILDREN,
  GET_DEMOS,
  GET_ENROLLMENTS,
  POST_REVIEW,
  GET_TEACHERS,
  UPDATE_CHILD,
  GET_KEYWORD_LIST,
  CHANGE_PASSWORD,
  PARENT_INVOICES,
  GET_PAYMENT_CARDS,
  ADD_PAYMENT_CARD,
  DELETE_PAYMENT_CARD,
  PAY_STRIPE_INVOICE,
  BOOK_FREE_PACKAGE,
  DEMO_RESCHEDULE,
  GET_INVOICE_DETAIL,
  GET_ENROLLMENT_BY_ID,
  GET_STUDENT_EVENT,
  GET_INVOICES_BY_ID,
  GET_ASSIGNMENTS_BY_ID,
  GET_NAPLAN_PACKAGES,
  CHECK_PARENT_AUTH,
  ADD_FORM_DATA,
  DELETE_CHILD,
  TRIVIA_REGISTRATION,
} from "src/modules/parent/config/api";
import { getRequest, postRequest } from "src/modules/parent/config/network";
import { axiosErrorHandler, createQueryString } from "../../../utils";
import actionTypes from "../../action-types";

export function addChild(params) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });
        const endpoint = ADD_CHILD;
        const response = await postRequest(endpoint, formData);
        if (response?.data?.status === "success") {
          resolve(response?.data?.message);
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function updateChild(params) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });
        const endpoint = `${UPDATE_CHILD}/${params.id}`;
        const response = await postRequest(endpoint, formData);
        if (response?.data?.status === "success") {
          resolve(response?.data?.message);
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}
export function deleteChild(params) {
  return new Promise(async function (resolve, reject) {
    try {
      const endpoint = `${DELETE_CHILD}/${params.id}`;
      console.log("endpoint======>", endpoint);
      //this function use post method instead of delete
      //because the api actually not delete the child
      //it update the child to deletedbyparent = true.
      const response = await postRequest(endpoint);
      if (response?.data?.status === "success") {
        resolve(response?.data?.message);
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
        "Student is already enrolled in a package!"
      ) {
        return reject("enrollError");
      }

      axiosErrorHandler(error);
    }
  });
}

export function addFormFields(params) {
  return new Promise(async function (resolve, reject) {
    try {
      const endpoint = `${ADD_FORM_DATA}`;
      const response = await postRequest(endpoint, params);
      if (response?.data?.status) {
        resolve(response?.data?.message);
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject();
    }
  });
}

export function getChildren(params) {
  return (dispatch) => {
    const endpoint = `${GET_CHILDREN}/${params}`;
    getRequest(endpoint)
      .then((response) => {
        if (response?.data?.status === "success") {
          dispatch({
            type: actionTypes.SET_CHILDREN,
            payload: response?.data?.data,
          });
        }
      })
      .catch((error) => axiosErrorHandler(error));
  };
}

export function getStudentEvent() {
  return new Promise(async function (resolve, reject) {
    try {
      const response = await getRequest(`${GET_STUDENT_EVENT}`);
      if (response?.data?.status) {
        resolve(response?.data?.data);
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject(error);
    }
  });
}

export function fetchTopTeachers() {
  return (dispatch) => {
    const queryObj = { limit: 50, top_teachers: true };
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_TEACHERS}?${queryParams}`;
    getRequest(endpoint, false)
      .then((response) => {
        dispatch({
          type: actionTypes.SET_TOP_TEACHERS,
          payload: response?.data?.data?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_TOP_TEACHERS,
          payload: [],
        });
        axiosErrorHandler(error);
      });
  };
}

export function getDemos() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_DEMOS_AND_COURSES,
      payload: { loadingDemos: true },
    });
    return new Promise(async function (resolve, reject) {
      try {
        const response = await getRequest(
          `${GET_DEMOS}?status=open&for_carousel=true`
        );
        if (response?.data?.status === "success") {
          const data = {
            loadingDemos: false,
            demos: [...response?.data?.data],
          };
          dispatch({ type: actionTypes.SET_DEMOS_AND_COURSES, payload: data });
          resolve();
        }
      } catch (error) {
        const data = {
          loadingDemos: false,
          demos: [],
        };
        dispatch({ type: actionTypes.SET_DEMOS_AND_COURSES, payload: data });
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function getEnrolmentById(id) {
  return new Promise(async function (resolve, reject) {
    try {
      const endpoint = `${GET_ENROLLMENT_BY_ID}/${id}`;
      const response = await getRequest(endpoint);
      if (response?.data?.data) {
        resolve(response?.data?.data);
      } else {
        reject("Not found");
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject();
    }
  });
}

export function checkParentAuth() {
  return new Promise(async function (resolve, reject) {
    try {
      const endpoint = `${CHECK_PARENT_AUTH}`;
      const response = await getRequest(endpoint);
      if (response?.data?.data) {
        resolve(response?.data?.data);
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject();
    }
  });
}

export function getAssignmentById(id, type) {
  return new Promise(async function (resolve, reject) {
    try {
      const endpoint = `${GET_ASSIGNMENTS_BY_ID}/${id}?type=${type}`;
      const response = await getRequest(endpoint);

      if (response?.data?.data) {
        resolve(response?.data?.data);
      } else {
        reject("Not found");
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject();
    }
  });
}

export function registerTriviaCompetition(data) {
  return new Promise(async function (resolve, reject) {
    try {
      const endpoint = `${TRIVIA_REGISTRATION}`;
      const response = await postRequest(endpoint, data);

      if (response?.data) {
        resolve(response?.data?.data);
      }
    } catch (error) {
      const errorResponse = error?.response?.data?.message;
      if (errorResponse && errorResponse?.email) {
        return reject("accountInUse");
      }
      reject();
      return axiosErrorHandler(error);
    }
  });
}

export function getInvoicesByEnrolmentId(id) {
  return new Promise(async function (resolve, reject) {
    try {
      const endpoint = `${GET_INVOICES_BY_ID}/${id}`;
      const response = await getRequest(endpoint);
      if (response?.data?.data) {
        resolve(response?.data?.data);
      } else {
        reject("Not found");
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject();
    }
  });
}

export function getEnrolledCourses() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_DEMOS_AND_COURSES,
      payload: { loadingMyCourses: true },
    });
    return new Promise(async function (resolve, reject) {
      try {
        const response = await getRequest(`${GET_ENROLLMENTS}`);
        if (response?.data.status === "success") {
          const data = {
            loadingMyCourses: false,
            myCourses: [...response?.data?.data],
          };
          dispatch({ type: actionTypes.SET_DEMOS_AND_COURSES, payload: data });
          resolve(response?.data?.data);
        }
      } catch (error) {
        const data = {
          loadingMyCourses: false,
          myCourses: [],
        };
        dispatch({ type: actionTypes.SET_DEMOS_AND_COURSES, payload: data });
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function createDemo(demo) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const formData = new FormData();
        Object.keys(demo).forEach((key) => {
          formData.append(key, demo[key]);
        });
        const endpoint = CREATE_DEMO;
        const { data } = await postRequest(endpoint, formData);
        if (data.status === "success") {
          message.success(data?.message);
          resolve();
        } else {
          reject();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function demoReschedule(dataObj) {
  return new Promise(async function (resolve, reject) {
    try {
      const demoId = dataObj.id;
      const { data } = await postRequest(
        `${DEMO_RESCHEDULE}/${demoId}`,
        dataObj
      );
      if (data.status === "success") {
        message.success(data?.message);
        resolve();
      } else {
        reject();
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject();
    }
  });
}

export function registerFreeClass(data) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        const endpoint = BOOK_FREE_PACKAGE;
        const response = await postRequest(endpoint, formData);
        if (response?.data.status === "success") {
          resolve();
        } else {
          reject();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function contactUs(params) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });
        const { data } = await postRequest(CONTACT_US, formData, false);
        if (data.success) {
          resolve();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function postReview(params) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const { data } = await postRequest(POST_REVIEW, params);
        if (data?.status === "success") {
          message.success(data?.message);
          resolve();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

// export function fetchSubjectList() {
//   return (dispatch) => {
//     const endpoint = GET_SUBJECT_LIST;
//     getRequest(endpoint, false)
//       .then((response) =>
//         dispatch({
//           type: actionTypes.SET_SUBJECT_LIST,
//           payload: response?.data?.data?.subjects,
//         })
//       )
//       .catch((error) => axiosErrorHandler(error));
//   };
// }

export function fetchKeywords(params) {
  return (dispatch) => {
    const endpoint = params
      ? `${GET_KEYWORD_LIST}?${params}`
      : GET_KEYWORD_LIST;
    getRequest(endpoint, false)
      .then((response) => {
        const { data } = response?.data;
        if (data && data?.length) {
          const arr = data?.map((i) => {
            return {
              id: i.id,
              label: i?.keyword,
              value: i?.keyword,
            };
          });

          dispatch({
            type: actionTypes.SET_KEYWORD_LIST,
            payload: arr,
          });
        }
      })
      .catch((error) => axiosErrorHandler(error));
  };
}

export function fetchPaginatedTeachers(queryObj) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_TEACHER_DATA,
      payload: { loading: true },
    });
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_TEACHERS}?${queryParams}`;
    getRequest(endpoint, false)
      .then(({ data }) => {
        if (data?.data?.data) {
          const payloadObj = {
            teachers: JSON.parse(JSON.stringify(data?.data?.data)),
            currentPage: data?.data?.meta?.current_page,
            perPage: data?.data?.meta?.per_page,
            total: data?.data?.meta?.total,
            loading: false,
          };
          dispatch({ type: actionTypes.SET_TEACHER_DATA, payload: payloadObj });
        }
      })
      .catch((error) => {
        const payloadObj = {
          loading: false,
          teachers: [],
          currentPage: 1,
          perPage: 10,
          total: 0,
        };
        dispatch({ type: actionTypes.SET_TEACHER_DATA, payload: payloadObj });
        axiosErrorHandler(error);
      });
  };
}

export function changePassword(params) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await postRequest(CHANGE_PASSWORD, params);
        if (response?.data?.status === "success") {
          resolve(response?.data);
        } else {
          reject();
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function fetchInvoices() {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.SET_INVOICE_DATA,
        payload: {
          loadingInvoices: true,
          invoices: null,
        },
      });
      const response = await getRequest(PARENT_INVOICES);
      dispatch({
        type: actionTypes.SET_INVOICE_DATA,
        payload: {
          loadingInvoices: false,
          invoices: response?.data.data,
        },
      });
    } catch (error) {
      axiosErrorHandler(error);
      dispatch({
        type: actionTypes.SET_INVOICE_DATA,
        payload: {
          loadingInvoices: false,
          invoices: [],
        },
      });
    }
  };
}

export function getInvoiceDetails(invoiceId) {
  return new Promise((resolve, reject) => {
    try {
      getRequest(`${GET_INVOICE_DETAIL}/${invoiceId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    } catch (error) {
      axiosErrorHandler(error);
    }
  });
}

export function fetchPaymentCards() {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      getRequest(GET_PAYMENT_CARDS)
        .then((response) => {
          if (response?.data?.status === "success") {
            const data = { cards: response?.data?.data };
            dispatch({
              type: actionTypes.SET_PAYMENT_CARDS,
              payload: data,
            });
            resolve();
          }
        })
        .catch((error) => {
          axiosErrorHandler(error);
          reject();
        });
    });
  };
}

export function payNewInvoice(data) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      postRequest(PAY_STRIPE_INVOICE, data)
        .then((response) => {
          if (response?.data?.status === "success") {
            message.success(response?.data?.message);
            resolve();
          }
        })
        .catch((error) => {
          axiosErrorHandler(error);
          reject();
        });
    });
  };
}

export function deletePaymentCard(cardId) {
  return new Promise((resolve, reject) => {
    const endpoint = `${DELETE_PAYMENT_CARD}/${cardId}`;
    postRequest(endpoint)
      .then((response) => {
        if (response?.data?.status === "success") {
          resolve(response?.data.message);
        }
      })
      .catch((error) => {
        axiosErrorHandler(error);
        reject();
      });
  });
}

export function addPaymentCard(data) {
  return (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        const response = await postRequest(ADD_PAYMENT_CARD, formData);
        if (response?.data?.status === "success") {
          dispatch({
            type: actionTypes.ADD_PAYMENT_CARDS,
            payload: response?.data?.data,
          });
          resolve(response?.data?.message);
        }
      } catch (error) {
        axiosErrorHandler(error);
        reject();
      }
    });
  };
}

export function getNaplanPackages(params) {
  return new Promise(async function (resolve, reject) {
    try {
      const queryParams = createQueryString(params);
      const endpoint = `${GET_NAPLAN_PACKAGES}?${queryParams}`;
      const { data } = await getRequest(endpoint);

      if (data?.data && data?.data?.data) {
        resolve(data?.data?.data);
      } else {
        reject("Not found");
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject();
    }
  });
}
