import React from "react";

import ModalComponent from "src/modules/parent/components/modal";
import PostFeedback from "src/modules/parent/components/post-feedback";

const ReviewModal = ({
  isVisible,
  onCancel = () => {},
  onSubmit = () => {},
  name = "",
  id,
}) => (
  <ModalComponent
    isModalVisible={isVisible}
    onCancel={() => onCancel(false)}
    maskClosable={false}
    Component={
      <PostFeedback
        teacherName={name}
        feedbackSubmitted={onSubmit}
        teacherId={id}
      />
    }
  />
);

export default React.memo(ReviewModal);
