import React from "react";

import { Modal } from "antd";

import RequestDemo from "src/modules/parent/pages/package-details/request-demo";
import styles from "./styles.module.css";

const RequestDemoModal = ({
  isVisible,
  onCancel = () => {},
  packageData = {},
  timeSlots = [],
  loadingId,
  isScheduled = false,
  handleDemoRescheduled = () => {},
}) => (
  <Modal
    closable
    onCancel={onCancel}
    maskClosable
    centered
    footer={false}
    width={"97%"}
    className={[styles.modal, "g-modal"].join(" ")}
    open={isVisible}
  >
    <RequestDemo
      packageDetail={packageData}
      loadingId={loadingId}
      isScheduled={isScheduled}
      setRequestModal={onCancel}
      handleDemoRescheduled={handleDemoRescheduled}
      timeSlots={timeSlots}
    />
  </Modal>
);

export default React.memo(RequestDemoModal);
