import React from "react";

import { isMobile } from "react-device-detect";
import { Col, Row } from "antd";

import error from "src/modules/parent/assets/svgs/error.svg";
import info from "src/modules/parent/assets/svgs/info.svg";
import styles from "../styles.module.css";

const ErrorScreen = () => (
  <Row gutter={[0, isMobile ? 30 : 50]}>
    <Col span={24}>
      <div className={styles.title}>{"Oops!"}</div>
    </Col>

    <Col span={24}>
      <Row justify={"center"}>
        <img src={error} alt="GIF" className={styles.banner_gif} />
      </Row>
    </Col>

    <Col span={24}>
      <Row justify={"center"}>
        <div className={styles.noFoundText}>
          {
            "It looks like we don’t have a course that matches your exact preference."
          }
        </div>
      </Row>

      <Row justify={"center"}>
        <div className="mt-20" style={{ width: "600px", textAlign: "center" }}>
          <img
            src={info}
            style={{ width: "20px", marginRight: "10px" }}
            alt="info"
          />
          {
            "Did you know we can customise a course in a jiffy just for you? Chat with our dedicated academic counsellor now!"
          }
        </div>
      </Row>
    </Col>

    <Col span={24}>
      <Row align={"middle"} justify={"center"}>
        <span className={styles.connect}>
          Connect with us on WhatsApp:{" "}
          <span
            onClick={() => window.open("https://wa.me/923474106511")}
            className={styles.colorGreen}
          >
            +92 347 4106511
          </span>{" "}
          or{" "}
          <span
            onClick={() => window.open("https://wa.me/923460086795")}
            className={styles.colorGreen}
          >
            +92 346 0086795
          </span>
        </span>
      </Row>
    </Col>
  </Row>
);

export default ErrorScreen;
