import React from "react";

import { Row, Space } from "antd";

import Text from "src/modules/parent/components/text";
import checkMark from "../../assets/svgs/check.svg";

import styles from "./styles.module.css";

const data = [
  {
    id: "0",
    text: "Access to a learning library with 10,000+ worksheets, lesson plans and resources!",
  },
  {
    id: "1",
    text: "Professional trainings and experienced Teacher Managers to support you.",
  },
  {
    id: "2",
    text: "A personalized dashboard to create courses, track payments, chat and issue certificates for students.",
  },
  {
    id: "3",
    text: "Career growth as you get promoted to Super Tutors and beyond.",
  },
  {
    id: "4",
    text: "A global community of teachers to learn and grow from via online events and meet-ups.",
  },
  {
    id: "5",
    text: "A steady stream of students to increase your earning potential",
  },
];

const List = () => (
  <Row gutter={[20, 20]} className={"mt-30"}>
    {data.map((item) => (
      <div key={item.id} className={styles.tabs}>
        <Row justify={"center"}>
          <Space dir size={"middle"}>
            <div style={{ width: "50px", textAlign: "center" }}>
              <img src={checkMark} alt={"checkmark"} />
            </div>

            <Text size={"S"} font={"LIGHTER"} lHeight={"S"} title={item.text} />
          </Space>
        </Row>
      </div>
    ))}
  </Row>
);

export default List;
