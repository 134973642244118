import actionTypes from "../../action-types";

const INITIAL_PACKAGE_DATA = {
  packages: [],
  currentPage: 1,
  perPage: 10,
  total: 0,
  loading: true,
};

const INITIAL_RECOMMENDED_PACKAGE = {
  packages: [],
  currentPage: 1,
  perPage: 10,
  total: 0,
  loading: false,
};

const INITIAL_PROFESSIONAL_PACKAGE = {
  packages: [],
  currentPage: 1,
  perPage: 50,
  total: 0,
  loading: false,
};

const INITIAL_FREE_CLASSES_DATA = {
  freeClasses: null,
  currentPage: 1,
  perPage: 10,
  total: 0,
  loading: true,
};

const INITIAL_STATE = {
  topPackages: null,
  topFreeClasses: null,
  freeClassesLoader: false,
  backToSchoolCourses: null,
  groupClassesBatch1: null,
  packageData: { ...INITIAL_PACKAGE_DATA },
  unAuthData: null,
  mathPackages: [],
  mathPackagesLoader: false,
  codingPackagesLoader: false,
  codingPackages: [],
  summerPackagesLoader: false,
  summerPackages: [],
  workShopPackages: { ...INITIAL_PACKAGE_DATA },
  professionalPackages: { ...INITIAL_PROFESSIONAL_PACKAGE },
  subjectPackages: { ...INITIAL_PACKAGE_DATA },
  subjectPackageLoading: false,
  freeClassesData: { ...INITIAL_FREE_CLASSES_DATA },
  shortCoursesBySubject: { ...INITIAL_PACKAGE_DATA },
  recommendedCourses: { ...INITIAL_RECOMMENDED_PACKAGE },
  moreCourses: { ...INITIAL_RECOMMENDED_PACKAGE },
  blogData: null,
  olevelSubjects: [],
  olevelSubjectDown: [],
};

const packageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_TOP_FREE_CLASSES: {
      return {
        ...state,
        topFreeClasses: action.payload,
      };
    }
    case actionTypes.OLEVEL_SUBJECTS: {
      return {
        ...state,
        olevelSubjects: action.payload,
      };
    }
    case actionTypes.OLEVEL_SUBJECTS_DOWN: {
      return {
        ...state,
        olevelSubjectDown: action.payload,
      };
    }
    case actionTypes.MATH_PACKAGES: {
      return {
        ...state,
        mathPackages: action.payload,
      };
    }
    case actionTypes.MATH_PACKAGES_LOADING: {
      return {
        ...state,
        mathPackagesLoader: action.payload,
      };
    }
    case actionTypes.CODING_PACKAGES_LOADING: {
      return {
        ...state,
        codingPackagesLoader: action.payload,
      };
    }
    case actionTypes.CODING_PACKAGES: {
      return {
        ...state,
        codingPackages: action.payload,
      };
    }
    case actionTypes.SUMMER_CLASSES_PACKAGES_LOADING: {
      return {
        ...state,
        summerPackagesLoader: action.payload,
      };
    }
    case actionTypes.SUMMER_CLASSES_PACKAGES: {
      return {
        ...state,
        summerPackages: action.payload,
      };
    }
    case actionTypes.FREE_CLASSES_LOADER: {
      return {
        ...state,
        freeClassesLoader: action.payload,
      };
    }
    case actionTypes.SET_TOP_PACKAGES: {
      return {
        ...state,
        topPackages: action.payload,
      };
    }
    case actionTypes.SET_BACK_TO_SCHOOL_COURSES: {
      return {
        ...state,
        backToSchoolCourses: action.payload,
      };
    }
    case actionTypes.SET_GROUP_CLASSES_BATCH_1: {
      return {
        ...state,
        groupClassesBatch1: action.payload,
      };
    }
    case actionTypes.BLOG_DATA: {
      return {
        ...state,
        blogData: action.payload,
      };
    }
    case actionTypes.SET_PACKAGE_DATA: {
      return {
        ...state,
        packageData: { ...state.packageData, ...action.payload },
      };
    }

    case actionTypes.WORKSHOP_PACKAGES: {
      return {
        ...state,
        workShopPackages: { ...state.workShopPackages, ...action.payload },
      };
    }

    case actionTypes.SET_SUBJECT_PACKAGES: {
      return {
        ...state,
        subjectPackages: { ...state.subjectPackages, ...action.payload },
      };
    }
    case actionTypes.PROFESSIONAL_PACKAGE: {
      return {
        ...state,
        professionalPackages: action.payload,
      };
    }
    case actionTypes.RECOMMENDED_COURSES: {
      return {
        ...state,
        recommendedCourses: { ...action.payload },
      };
    }
    case actionTypes.SET_BULK_PACKAGES: {
      return {
        ...state,
        unAuthData: action.payload,
      };
    }
    case actionTypes.MORE_COURSES: {
      return {
        ...state,
        moreCourses: { ...action.payload },
      };
    }
    case actionTypes.SHORT_COURSES: {
      return {
        ...state,
        shortCoursesBySubject: {
          ...state.shortCoursesBySubject,
          ...action.payload,
        },
      };
    }
    case actionTypes.SET_SUBJECT_PACKAGES_LOADING: {
      return {
        ...state,
        subjectPackageLoading: action.payload,
      };
    }
    case actionTypes.SET_FREE_CLASSES_DATA: {
      return {
        ...state,
        freeClassesData: { ...state.freeClassesData, ...action.payload },
      };
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        packageData: { ...INITIAL_PACKAGE_DATA },
        subjectPackages: { ...INITIAL_PACKAGE_DATA },
        freeClassesData: { ...INITIAL_FREE_CLASSES_DATA },
      };
    }
    default: {
      return state;
    }
  }
};

export default packageReducer;
