import React from "react";

import { Col, Row, Space, Button } from "antd";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import subject from "../../assets/webp/users.webp";
import engage from "../../assets/webp/check-circle.webp";
import afford from "../../assets/webp/award.webp";
import rotateImg from "../../assets/webp/rotateImg.webp";

import styles from "./styles.module.css";

const data = [
  {
    id: "0",
    title: "Subject Experts",
    description: isMobile ? (
      <span className={styles.text}>
        With a 3% selection rate, our teachers are highly experienced and have
        the ability to ‘connect’ with each student.
      </span>
    ) : (
      <span className={styles.text}>
        With a <span>3% selection rate</span> we select the best of the best.
        Teaching experience, <span>excellence in academic knowledge</span> and
        the ability to ‘connect’ with the student are on top of our radar.
      </span>
    ),
    icon: subject,
  },
  {
    id: "1",
    title: "Engaging",
    description: isMobile ? (
      <span className={styles.text}>
        With engaging lesson plans our teachers are trained to be your biggest
        cheerleader helping you reach your full potential!
      </span>
    ) : (
      <span className={styles.text}>
        Despite being <span>highly qualified</span>, our  tutor cost on average
        is <span>50% lower</span> than tutors based in Australia
      </span>
    ),
    icon: engage,
  },
  {
    id: "2",
    title: "Affordable",
    description: isMobile ? (
      <span className={styles.text}>
        Despite being highly qualified, our  tutor cost on average is 50% lower
        than tutors based in Australia
      </span>
    ) : (
      <span className={styles.text}>
        We believe in the <span>power of real connection</span>. With{" "}
        <span>engaging lesson plans</span> our teachers are trained to be your
        biggest cheerleader helping you <span>reach your full potential!</span>
      </span>
    ),
    icon: afford,
  },
];

const Item = ({ icon, title, description, index }) => (
  <div style={{ textAlign: isMobile ? "center" : "start", width: "100%" }}>
    <Space size={"middle"} direction={"vertical"}>
      <Space size={"middle"}>
        <img src={icon} alt={"alt"} />

        <Text
          size={"L"}
          font={"SEMIBOLD"}
          lHeight={"S"}
          center={isMobile}
          color={"#000000"}
          title={title}
        />
      </Space>

      {description}

      {isMobile && index <= 1 && <div className={styles.hrow} />}
    </Space>
  </div>
);

const Tutors = ({ handleClick = () => {} }) => (
  <div className={"app-container pb-50"}>
    <p className={styles.heading}>
      Dot and Line
      <span>tutors are:</span>
    </p>

    <Row align={"middle"} className={"mb-30"}>
      <Col xxl={15} xl={15} lg={24} md={24} sm={24} xs={24}>
        <Row
          className={"mr-40 w-100"}
          gutter={[isMobile ? 10 : 0, isMobile ? 20 : 40]}
        >
          {data.map((item, index) => (
            <Item key={item.id} {...item} index={index} />
          ))}
        </Row>
      </Col>

      {!isMobile && (
        <Col xxl={9} xl={9} lg={24} md={24} sm={24} xs={24}>
          <Row justify={"center"}>
            <img
              src={rotateImg}
              width={"100%"}
              style={{ maxWidth: "350px" }}
              alt={"image1"}
            />
          </Row>
        </Col>
      )}
    </Row>

    <Row justify={"center"}>
      <Button
        loading={false}
        onClick={handleClick}
        className={styles.searchButton}
      >
        <Space direction={"vertical"}>
          {"Book a Free Demo"}

          <Text
            size={"XS"}
            color={"#FFFFFF"}
            font={"LIGHTEST"}
            title={"with your one-on-one tutor"}
          />
        </Space>
      </Button>
    </Row>
  </div>
);

export default React.memo(Tutors);
