import React from "react";
// import image from "../../../../utils/static-data/assets/math/mathImage1.png";
import styles from "../../styles.module.css";
import { Row } from "antd";
import moment from "moment-timezone";
import { subjectData } from "src/modules/parent/utils/static-data";
import { useNavigate } from "react-router-dom";

function decodeEntities(encodedString) {
  const elem = document.createElement("textarea");
  elem.innerHTML = encodedString;
  const decodedString = elem.value;
  return decodedString;
}

const Card = ({ date, title, category, slug, source, isOlevel, link }) => {
  const navigate = useNavigate();
  const handleCardClick = (e) => {
    e.stopPropagation();
    window.location.href = link;
  };

  const handleCategoryClick = (e) => {
    e.stopPropagation();

    const initialSubject = subjectData.find(
      (i) => i.subject.toLowerCase() === category.toLowerCase()
    );

    if (isOlevel) {
      navigate("/courses?olevel=true");
    } else {
      if (initialSubject && initialSubject.content) {
        const route = initialSubject.subject.split(" ").join("-").toLowerCase();
        navigate(`/online-${route}`);
      } else {
        navigate(`/courses?program_id=5&keywords[]=${category}`);
      }
    }
  };

  return (
    <div onClick={handleCardClick} className={styles.mainContainer}>
      <div className={styles.img}>
        <img
          src={source}
          style={{ width: "100%", height: "100%" }}
          alt={"article"}
        />
      </div>

      <Row
        justify={"space-between"}
        align={"middle"}
        className="mt-10 mb-10 mr-10"
      >
        {category && (
          <div
            onClick={handleCategoryClick}
            className={["font-weight-600", styles.tabs].join(" ")}
          >
            {category}
          </div>
        )}

        <p className={["mb-0", styles.dateStyle].join(" ")}>
          {moment(date).format("DD.MM.YYYY")}
        </p>
      </Row>

      <div className={styles.head}>
        <div
          className={["mb-0 font-weight-600", styles.cardTitle].join(" ")}
          dangerouslySetInnerHTML={{ __html: decodeEntities(title) }}
        ></div>
      </div>
    </div>
  );
};

export default Card;
