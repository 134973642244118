import axios from "axios";
import moment from "moment-timezone";
import { store } from "../store";
import { API_BASE_URL } from "./constant";

const timezone = moment.tz.guess();

const request = async (method, endpoint, body = {}, withAuth) => {
  const url = API_BASE_URL + endpoint;
  const token = store.getState().authReducer?.userInfo?.token;
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (withAuth && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const getUrl = url.includes("?")
    ? `${url}&time_zone=${timezone}`
    : `${url}?time_zone=${timezone}`;

  switch (method) {
    case "get": {
      return axios.get(getUrl, config);
    }

    case "post": {
      return axios.post(url, body, config);
    }

    case "put": {
      return axios.put(url, body, config);
    }

    case "patch": {
      return axios.patch(url, body, config);
    }

    case "delete": {
      return axios.delete(url, body, config);
    }

    default: {
      return Promise.reject();
    }
  }
};

const getRequest = (endpoint, withAuth = true) =>
  request("get", endpoint, {}, withAuth);

const postRequest = (endpoint, body = {}, withAuth = true) =>
  request("post", endpoint, body, withAuth);

const putRequest = (endpoint, body = {}, withAuth = true) =>
  request("put", endpoint, body, withAuth);

const patchRequest = (endpoint, body = {}, withAuth = true) =>
  request("patch", endpoint, body, withAuth);

const deleteRequest = (endpoint, body = {}, withAuth = true) =>
  request("delete", endpoint, body, withAuth);

export { getRequest, postRequest, putRequest, patchRequest, deleteRequest };
