import { useState, useEffect } from "react";

import * as actions from "src/modules/parent/store/actions";

const useFetchPackages = (params) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      actions
        .getNaplanPackages(params)
        .then((response) => {
          setLoading(false);
          setData(response.data);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error };
};

export default useFetchPackages;
