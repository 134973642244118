import React from "react";

import { Row, Space } from "antd";

import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";
import { isMobile } from "react-device-detect";

const GreetingText = ({ image, name, title }) => (
  <Row
    align={"middle"}
    className={styles.stats}
    style={{
      height: isMobile ? "auto" : "98px",
      padding: isMobile ? "0px 20px" : "0px 40px",
    }}
  >
    <Space size={"large"}>
      <div className={styles.girlImage}>
        <img src={image} alt={"girl"} />
      </div>

      <Space className={"mt-10"} direction={"vertical"}>
        <Text size={"M"} font={"BOLD"} title={`Hi ${name || ""}!`} />

        <Text
          size={"S"}
          font={"LIGHTER"}
          className={styles.desc}
          lHeight={"S"}
          title={title}
        />
      </Space>
    </Space>
  </Row>
);

export default React.memo(GreetingText);
