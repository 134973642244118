import React, { Fragment } from "react";

import { EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from "react-device-detect";

import form1 from "../../assets/formSvgs/form1.svg";
import form2 from "../../assets/formSvgs/form2.svg";
import form3 from "../../assets/formSvgs/form3.svg";

const FormCard = () => (
  <Swiper
    loop={true}
    grabCursor={true}
    effect={"coverflow"}
    navigation
    centeredSlides={true}
    modules={[EffectCoverflow, Navigation]}
    coverflowEffect={{
      rotate: 20,
      stretch: 100,
      depth: 250,
      slideShadows: false,
    }}
    breakpoints={{
      992: {
        slidesPerView: 2,
      },
      1: {
        slidesPerView: 1,
      },
    }}
  >
    {[0, 1].map((_, index) => (
      <Fragment key={index.toString()}>
        <SwiperSlide>
          {
            <img
              alt="slider img"
              style={
                !isMobile
                  ? {
                      width: "100%",
                      height: "400px",
                    }
                  : {
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      marginBottom: "5px",
                    }
              }
              src={form3}
            />
          }
        </SwiperSlide>
        <SwiperSlide>
          <img
            alt="slider img"
            style={
              !isMobile
                ? { width: "100%", height: "400px" }
                : { boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }
            }
            src={form2}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            alt="slider img"
            style={
              !isMobile
                ? { width: "100%", height: "400px" }
                : { boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }
            }
            src={form1}
          />
        </SwiperSlide>
      </Fragment>
    ))}
  </Swiper>
);

export default FormCard;
