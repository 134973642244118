import React, { memo, useCallback, useEffect, useState } from "react";

import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Drawer, Layout, Row } from "antd";

import Header from "src/modules/parent/components/header";
import Footer from "src/modules/parent/components/footer";
import Text from "src/modules/parent/components/text";
import LayoutSidebar from "../sideBar";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "src/modules/parent/store/actions";

import styles from "../../styles.module.css";

const LearningLayout = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const minWidthForLargeScreen = 999;
  const [isLargeScreen, setIsLargeScreen] = useState(
    window.innerWidth > minWidthForLargeScreen
  );
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setIsLargeScreen(window.innerWidth > minWidthForLargeScreen);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const fetchData = useCallback(() => {
    action.getLearningSubjects();
    action.setActiveLearningMenu([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="learning_library">
      <Header />

      <div className="app-container m-20">
        <Row>
          <Text size={"XL"} font={"SEMIBOLD"} title={"Learning Library"} />
        </Row>

        <Drawer
          width={340}
          open={showSidebar}
          placement="left"
          bodyStyle={{ overflowX: "hidden" }}
          title={"Learning Library"}
          className="learning_library"
          onClose={() => setShowSidebar(false)}
        >
          <Row justify={"center"}>
            <LayoutSidebar width={"100%"} />
          </Row>
        </Drawer>

        <div>
          <Layout
            style={{ backgroundColor: "#fbfbfb", height: "100%" }}
            className="bg-inherit px-0 py-24"
          >
            {isLargeScreen ? (
              <LayoutSidebar />
            ) : (
              <div className={styles.hamburger_container}>
                <div
                  className={styles.hamburger_icon}
                  onClick={() => setShowSidebar(true)}
                >
                  <MenuUnfoldOutlined />
                </div>
              </div>
            )}

            {children && (
              <Layout.Content
                className={styles.content}
                style={{
                  // minHeight: "calc(100vh - 146px)",
                  // height: "calc(100vh - 146px)",
                  height: "100%",
                  overflow: "auto",
                  marginLeft: isLargeScreen ? 20 : 0,
                }}
              >
                {children}
              </Layout.Content>
            )}
          </Layout>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default memo(LearningLayout);
