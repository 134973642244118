import React from "react";
import { Col, Row } from "antd";

import homeGif from "src/modules/parent/assets/gif/1.gif";
import styles from "../styles.module.css";

const LoadingScreen = () => (
  <Row gutter={[0, 50]}>
    <Col span={24}>
      <div className={styles.title}>{"Please Wait"}</div>
    </Col>

    <Col span={24}>
      <Row justify={"center"}>
        <img src={homeGif} alt="GIF" className={styles.banner_gif} />
      </Row>
    </Col>

    <Col span={24}>
      <Row justify={"center"}>
        <p>{"We are looking for the best fit for you!"}</p>
      </Row>
    </Col>
  </Row>
);

export default LoadingScreen;
