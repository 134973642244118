import React from "react";

import { Space, Button } from "antd";
import { isBrowser } from "react-device-detect";

import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const location = [
  "Pakistan",
  "UAE",
  "Saudi Arabia",
  "UK",
  "Oman",
  "Qatar",
  "Australia",
];

const TextComponent = ({ handleClick = () => {} }) => (
  <div className={styles.textContent}>
    <Space size={"middle"} direction={"vertical"}>
      <div className={styles.list}>
        {location.map((i, index) => {
          return (
            <span className={styles.point} key={index.toString()}>
              <span>•</span>
              {i}
            </span>
          );
        })}
      </div>

      <Text
        size={"XXL4"}
        color={"rgba(50, 50, 50, 1)"}
        font={"SEMIBOLD"}
        lHeight={"S"}
        title={"We believe in the power of real connection"}
      />

      <Text
        size={isBrowser ? "XXL2" : "L"}
        color={"rgba(50, 50, 50, 1)"}
        font={"SEMIBOLD"}
        lHeight={"S"}
        title={"Certified tutors - online 1-to-1 classes"}
      />

      <div className={styles.buttonContainer}>
        <Button onClick={handleClick} className={styles.searchButton}>
          {"Get Started"}
        </Button>
      </div>
    </Space>
  </div>
);
export default React.memo(TextComponent);
