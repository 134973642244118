import React from "react";

import Text from "src/modules/parent/components/text";
import blue_logo from "../../assets/pngs/dotlineiconlogo.png";

import styles from "../../styles.module.css";

const list = [
  "Please keep communication respectful and professional with your teacher at all times.",
  "At Dot & Line, we maintain a safe space for teachers of all beliefs, backgrounds, and locations. We ask that you do the same.",
  "Dot and Line moderates all conversations and reserves the right to block a sender if they violate our policies and guidelines.",
];

const FirstPage = () => (
  <div className={styles.startChat}>
    <img src={blue_logo} className={styles.blue_logo} alt="" />

    <div className={styles.guidelinesWrapper}>
      <Text color={"#24397E"} size={"M"}>
        {"Dot & Line Chat Guidelines"}
      </Text>
    </div>

    <div className={styles.guidlinesListWrapper}>
      <ul className={styles.guidelines}>
        {list.map((i) => (
          <li key={i.toString()}>
            <Text size={"S"}>{i}</Text>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default FirstPage;
