/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "antd";
import FlatButton from "../../components/flat-button";
import {
  containsNumbersOrSpecialCharacters,
  defaultCountry,
  validateEmail,
} from "../../utils";
import logo from "../../assets/img/dotlinelogowithname.png";
import gif from "../../assets/img/svg/gif_image_replica.svg";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import parsePhoneNumberWithError from "libphonenumber-js";
import "./index.scss";
import TextInput from "src/modules/parent/components/text-input";
import PhoneWithFlagInput from "src/modules/parent/components/phone-input";
import CardsWrapper from "src/modules/parent/components/cards-wrapper";
import actionTypes from "../../store/action-types";
import {
  inCompleteDataNotification,
  phoneNumberNotification,
  emailNotification,
  signUpNotification,
} from "../../utils/notificationData";
import ReactPixel from "react-facebook-pixel";
import { fb_pixel_id_summer } from "src/modules/parent/config/constant";
import { signUpJoinButton, trackEvents } from "../../utils/events/query";

ReactPixel.init(fb_pixel_id_summer);

const SignUp = () => {
  let timer = null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const action = bindActionCreators(actions, dispatch);
  const { profileReducer } = useSelector((state) => state);
  const { countries } = profileReducer;
  const [validate, setValidate] = useState({ phone: false, email: false });
  const [country, setCountry] = useState(defaultCountry);
  const [isError, setIsError] = useState("");
  const [user, setUser] = useState({
    email: "",
    name: "",
    phone: "",
    password: "",
    country: "Pakistan",
    user_type: "parent",
  });

  const isValidated = () => {
    if (!user.email || !user.password || !user.name || !user.phone) {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: inCompleteDataNotification,
      });
      setIsError(true);
      return false;
    }
    return true;
  };

  const submitHandler = () => {
    if (isValidated()) {
      setLoading(true);
      const phoneNumber = parsePhoneNumberWithError(user.phone, user.country);
      if (!phoneNumber) {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: phoneNumberNotification,
        });
        setLoading(false);
        return false;
      }
      const validPhoneNumber = phoneNumber.isValid();
      if (!validPhoneNumber) {
        setValidate({ ...validate, phone: true });
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: phoneNumberNotification,
        });
        setIsError(true);
        setLoading(false);
      } else if (!validateEmail(user.email)) {
        setValidate({ ...validate, phone: false, email: true });
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: emailNotification,
        });
        setIsError(true);
        setLoading(false);
      } else {
        setValidate({ ...validate, phone: false, email: false });
        const phoneNumber = parsePhoneNumberWithError(user.phone);
        if (phoneNumber && phoneNumber?.isValid()) {
          const data = {
            ...user,
            phone: phoneNumber?.number,
          };
          action
            .registerUser(data)
            .then((userObj) => {
              setLoading(false);

              trackEvents(signUpJoinButton);

              dispatch({
                type: actionTypes.NOTIFICATIONDATA,
                payload: signUpNotification,
              });

              navigate("/child/add");
            })
            .catch(() => setLoading(false));
        }
      }
    }
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  const countryOnChange = (e) => {
    const searchPhone = countries.find((i) => i.id === e.toString());
    if (searchPhone) {
      setCountry(searchPhone);
      setUser({
        ...user,
        country: searchPhone.title,
        phone: "",
      });
    }
  };

  return (
    <div className={"container auth-form"}>
      <div className="inner-container w-100">
        <div className="logo-div">
          <Link to={"/"}>
            <img src={logo} alt={"brand-logo"} />
          </Link>
        </div>
        <CardsWrapper>
          <Fragment>
            <div className={"form-wrapper"}>
              <div className={"header-text"}>Create Account</div>
              <Form className={"text-align-center"}>
                <Form.Item
                  hasFeedback={
                    (!user?.email && isError) || validate.email ? true : false
                  }
                  validateStatus={
                    (!user?.email && isError) || validate.email
                      ? "error"
                      : "success"
                  }
                >
                  <TextInput
                    type={"email"}
                    required={true}
                    label={"Email"}
                    allowClear={true}
                    placeholder={"Email"}
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback={user.name.length < 1 && isError ? true : false}
                  validateStatus={
                    user.name.length < 1 && isError ? "error" : "success"
                  }
                >
                  <TextInput
                    label={"Name"}
                    required={true}
                    allowClear={true}
                    placeholder={"Name"}
                    value={user.name}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!containsNumbersOrSpecialCharacters(value)) {
                        setUser({ ...user, name: value });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback={
                    (!user?.phone && isError) || validate.phone ? true : false
                  }
                  validateStatus={
                    (!user?.phone && isError) || validate.phone
                      ? "error"
                      : "success"
                  }
                >
                  <PhoneWithFlagInput
                    allowClear={true}
                    showSearch={true}
                    label={"Contact Number"}
                    inputPlaceholder={"Phone"}
                    value={user?.phone}
                    selectedId={defaultCountry.id}
                    countryOnChange={countryOnChange}
                    countryPlaceholder={"Select"}
                    selectedCountry={country?.value}
                    onChange={(e) =>
                      setUser({ ...user, phone: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback={
                    user.password.length < 1 && isError ? true : false
                  }
                  validateStatus={
                    user.password.length < 1 && isError ? "error" : "success"
                  }
                >
                  <TextInput
                    required={true}
                    type={"password"}
                    label={"Password"}
                    placeholder={"Password"}
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                  />
                </Form.Item>
                <FlatButton
                  isForm={true}
                  width={"200px"}
                  title={"Sign Up"}
                  loading={loading}
                  onClick={submitHandler}
                  className={"mt-25 mb-20"}
                />
                <div className="white-color mb-30">
                  Already have an account?{" "}
                  <Link to={"/login"} className={"link-text"}>
                    Login now
                  </Link>
                </div>
              </Form>
            </div>
            <div className={"image-wrapper"}>
              <img src={gif} alt="GIF" />
            </div>
          </Fragment>
        </CardsWrapper>
      </div>
    </div>
  );
};

export default SignUp;
