import React from "react";
import FlatButton from "src/modules/parent/components/flat-button";

const DemoConfirm = ({ onCancel, onOk, teacherName = "", bookingDate, loading }) => {
  return (
    <div className="d-column justify-content-center align-items-center" style={{ height: "350px" }}>
      <p className="font-18 font-weight-300 mb-30 text-align-center">
        Are you sure you want to book a demo <br /> class with{" "}
        <span className="blue-color font-weight-500">{teacherName}</span> on <br />
        <span className="blue-color font-weight-500">{bookingDate}</span> ?
      </p>
      <div className="d-row justify-content-center">
        <FlatButton
          disabled={loading}
          onClick={onCancel}
          className={"mr-25"}
          width={"110px"}
          type="Secondary"
          title={"No"}
        />
        <FlatButton loading={loading} onClick={onOk} width={"110px"} title={"Yes"} />
      </div>
    </div>
  );
};

export default DemoConfirm;
