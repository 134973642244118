import React, { useState, useEffect } from "react";

import { Modal, Col, Row, Button, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";

import Text from "src/modules/parent/components/text";
import TextInput from "src/modules/parent/components/text-input";

import styles from "./styles.module.css";
import { isBrowser } from "react-device-detect";

const name = true;

const Item = ({
  id,
  label,
  name,
  value,
  selectedItem = [],
  handleClick = () => {},
}) =>
  selectedItem.some((item) => item === id) ? (
    <Row
      onClick={() => handleClick({ value: id, name, label: value })}
      justify={"space-between"}
    >
      <Text size={isBrowser ? "S" : "M"} font={"SEMIBOLD"} title={label} />

      <CheckOutlined />
    </Row>
  ) : (
    <Text
      handleClick={() => handleClick({ value: id, name, label: value })}
      size={isBrowser ? "S" : "M"}
      center={!isBrowser}
      font={"LIGHTER"}
      title={label}
    />
  );

const SelectModal = ({
  show = false,
  isMultiple = false,
  setShow = () => {},
  select = null,
  selectedItem = null,
  handleSelectItem = () => {},
}) => {
  const [searchedData, setSearchedData] = useState([]);
  const [value, setValue] = useState("");

  const [keyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (
        window.visualViewport &&
        window.visualViewport.height < window.innerHeight
      ) {
        // Keyboard is open
        setKeyboardOpen(true);
      } else {
        // Keyboard is closed
        setKeyboardOpen(false);
      }
    };

    window.visualViewport?.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, []);

  const onHandleChange = (e) => {
    const value = e?.target?.value;
    const items = select?.data || [];

    const filteredItems = items.filter((item) =>
      item?.label.toLowerCase().includes(value.toLowerCase())
    );

    setSearchedData(filteredItems);
    setValue(value);
  };

  return (
    <Modal
      closable
      onCancel={() => setShow(false)}
      maskClosable
      centered={!keyboardOpen}
      style={keyboardOpen ? { top: "0%" } : {}}
      footer={false}
      width={"22rem"}
      className={[styles.modal, "g-modal"].join(" ")}
      open={show}
    >
      <Row align={"middle"} className="mb-20 mt-10">
        <Space>
          <Text
            size={"M"}
            font={"SEMIBOLD"}
            title={`Select ${select?.title || ""}`}
          />

          {isMultiple && (
            <Text size={"S"} font={"LIGHTER"} title={`(multiple)`} />
          )}
        </Space>
      </Row>

      <TextInput
        allowClear={true}
        className={"mb-20"}
        placeholder={`Search ${select?.title}`}
        onChange={onHandleChange}
        suffix={
          <div className={name ? "blue-color" : "grey-color"}>
            <SearchOutlined />
          </div>
        }
      />

      <div className={styles.content}>
        {!value ? (
          <>
            {select &&
              select?.data &&
              select.data.length > 0 &&
              select.data.map((item) => (
                <Item
                  {...item}
                  key={item.id}
                  name={select?.name}
                  handleClick={(e) => handleSelectItem(e, isMultiple)}
                  selectedItem={selectedItem[select?.name]}
                />
              ))}
          </>
        ) : (
          <>
            {searchedData && searchedData.length > 0 ? (
              searchedData.map((item) => (
                <Item
                  {...item}
                  key={item.id}
                  name={select?.name}
                  handleClick={(e) => handleSelectItem(e, isMultiple)}
                  selectedItem={selectedItem[select?.name]}
                />
              ))
            ) : (
              <Col className="mt-30 mb-30 text-align-center" span={24}>
                <h3>{"No result found!"}</h3>
              </Col>
            )}
          </>
        )}
      </div>

      {isMultiple && (
        <Button onClick={() => setShow(false)} className={styles.doneBtn}>
          {"Done"}
        </Button>
      )}
    </Modal>
  );
};

export default React.memo(SelectModal);
