// API endpoints
export const WHERE_AM_I = "https://www.travelpayouts.com/whereami?locale=en";
export const GET_GRADES = "/getGrades";
export const GET_SUBJECT_LIST = "/subjects";
export const GET_KEYWORD_LIST = "/keywords";
export const GET_PACKAGES = "/packages";
export const BULK_PACKAGES = "/unauthenticated";
export const GET_WORKSHOP_PACKAGES = "/olevel-workshops";
export const GET_SHORT_COURSE_PACKAGE = "/search-package-by-subject";
export const GET_RECOMMENDED_COURSES = "/package-tool";
export const GET_SUBJECT_TEACHERS = "/search-teacher-by-subject";
export const GET_FREE_CLASSES = "/free-packages";
export const GET_SHORT_COURSE_PACKAGES = "/get-packages-with-heading";
export const GET_FREE_CLASS = "/view-freepackage";
export const GET_TEACHER_PACKAGES = "/teacher-packages";
export const POST_LEAD = "/course-checkout";
export const GET_TEACHER_PROFILE = "/teacher";
export const GET_PACKAGE = "/view-package";
export const LOGIN = "/login";
export const LOGIN_SOCIAL = "/login-social";
export const LOG_OUT = "/logout";
export const REGISTER_USER = "/register";
export const USER_PROFILE = "/profile";
export const UPDATE_USER_PROFILE = "/profile/update";
export const CHANGE_PASSWORD = "/password-change";
export const RESET_PASSWORD = "/reset-password";
export const ADD_CHILD = "/children/create";
export const UPDATE_CHILD = "/children/update";
export const DELETE_CHILD = "/student-delete";
export const GET_CHILDREN = "/childrens";
export const CONTACT_US = "/contactUs";
export const PARENT_INVOICES = "/parent-invoices";
export const GET_INVOICE_DETAIL = "/get-invoice";
export const GET_DEMOS = "/demos";
export const CREATE_DEMO = "/create-demo";
export const PAY_STRIPE_INVOICE = "/pay-stripe-invoice";
export const GET_TEACHERS = "/teachers";
export const GET_ENROLLMENTS = "/enrollments";
export const GET_ENROLLMENT_BY_ID = "/enrollment";
export const GET_ASSIGNMENTS_BY_ID = "/get-enrollment-data";
export const POST_REVIEW = "/review/create";
export const GET_TEACHER_REVIEWS = "/teacher-reviews";
export const GET_PAYMENT_CARDS = "/cc-get";
export const DELETE_PAYMENT_CARD = "/cc-remove";
export const ADD_PAYMENT_CARD = "/cc-create";
export const GET_ASSIGNMENTS = "/assignments";
export const SUBMIT_ASSIGNMENT = "/assignment/task";
export const GET_PACKAGE_AMOUNT = "/calc-fee";
export const BOOK_COURSE = "/book-package";
export const BOOK_FREE_PACKAGE = "/book-freepackage";
export const UPDATE_UUID = "/update-uuid";
export const GET_PROFESSIONAL_PACKAGE = "/packages";
export const GET_PROFESSIONAL_TEACHERS = "/teachers";
export const DEMO_RESCHEDULE = "/demo-reschedule";
export const GET_NOTIFICATION = "/parent-notification";
export const UPDATE_NOTIFICATION = "/updateNotificationStatus";
export const IGNITE_FORM = "/ignite";
export const MARK_ATTENDANCE = "/mark-attendance";
export const UPDATE_TOKEN = "/fcm-token";
export const GET_DASHBOARD_DATA = "/student-dashboard";
export const SUBSCRIBED_EMAIL = "/save-newsletter-email";
export const GET_STUDENT_EVENT = "/get-student";
export const GET_WHATS_NEW = "/whats-new";
export const GET_TRACKER_DATA = "/student-dashboard/popup";
export const GET_UPCOMING_EVENTS = "/student-dashboard/get-upcoming-events";
export const GET_INVOICES_BY_ID = "/get-enrollment-invoices";
export const GET_LEARNING_SUBJECTS = "/get-learning-library-subjects";
export const GET_RESOURCES_DATA = "/parent-premium-resources";
export const GET_OLEVEL_TEACHERS = "/o-level";
export const GET_OLEVEL_SUBJECTS = "/o-level-subject";
export const GET_NAPLAN_PACKAGES = "/naplan";
export const GET_SUBJECTS_BY_GRADE = "/get-grade-subjects";
export const CHECK_PARENT_AUTH = "/parent-dashboard";
export const TEACHER_RECOMMENDED_PACKAGE = "/teacherPackagesRecommended";
export const ADD_FORM_DATA = "/examp-prep-leads";
export const TRIVIA_REGISTRATION = "/trivia/store";

// Chat Api's
export const SEND_MSG = "/send-message";
export const SEARCH_TEACHER_INBOX = "/search-teachers";
export const GET_INBOX = "/get-inbox";
export const GET_MESSAGES = "/get-messages";
export const GET_CHAT_COUNT = "/get-unread-count";
