import React from "react";
import { useNavigate } from "react-router-dom";
import FlatButton from "../../components/flat-button";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className={"page-not-found"}>
      <p className={"font-20 font-weight-500"}>Page not found!</p>
      <FlatButton title={"Go back to home"} onClick={() => navigate("/")} />
    </div>
  );
};

export default NotFoundPage;
