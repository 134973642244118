import React from "react";

import SignUpComponent from "./signup";
import LoginComponent from "./login";

const RegistrationComponent = ({
  authId,
  parentRegisterationRef,
  parentLoginRef,
  handleSubmit = () => {},
  handleLogin = () => {},
  handleGoogleLogin = () => {},
  initialValues,
}) => {
  return authId === "1" ? (
    <LoginComponent
      formikRef={parentLoginRef}
      onGoogleLogin={handleGoogleLogin}
      handleSubmit={handleLogin}
    />
  ) : (
    <SignUpComponent
      formikRef={parentRegisterationRef}
      defaultValues={initialValues}
      handleSubmit={handleSubmit}
    />
  );
};

export default RegistrationComponent;
