import React from "react";

import { Button, Space } from "antd";
import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const NoData = ({
  title = "",
  description = "",
  btnText = "",
  btnText2 = "",
  handleBtnClick = () => {},
  handleBtnClick2 = () => {},
}) => {
  return (
    <>
      <div className={styles.title}>{title}</div>

      <div className="mt-20 mb-20">{description}</div>

      <Space>
        <Button onClick={handleBtnClick} className={styles.button}>
          <Text size={"S"} lHeight={"S"} color={"#FFFFFF"} title={btnText} />
        </Button>

        {btnText2 && (
          <Button onClick={handleBtnClick2} className={styles.seondaryBtn}>
            <Text size={"S"} lHeight={"S"} color={"#005EFF"} title={btnText2} />
          </Button>
        )}
      </Space>
    </>
  );
};

export default React.memo(NoData);
