import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import { isMobile } from "react-device-detect";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";

import Text from "src/modules/parent/components/text";
import Header from "../../components/header";
import chat_search_icon from "./assets/svgs/chat_search_icon.svg";
import TextInput from "src/modules/parent/components/text-input";
import { SendOutlined } from "@ant-design/icons";
import ChatHeader from "./components/chat-header";
import FirstPage from "./components/first-page";
import ContactPage from "./components/contact-page";
import ChatItemComponent from "./components/chat-item";
import { getTeacherInbox, getTeachers } from "../../store/actions";
import MessageCard from "./components/message-card";
import actionTypes from "../../store/action-types";
import { chatSocket, inboxSocket } from "../../config/socket";
import { appendMessages, appendInbox } from "src/modules/parent/store/actions";
import { sendMessage } from "src/modules/parent/store/actions";
import UserAvatar from "../../components/avatar";

import * as actions from "../../store/actions";

import styles from "./styles.module.css";

const Chat = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const messageContainerRef = useRef(null);
  const [fetchingMessages, setFetchingMessages] = useState(false);
  const action = bindActionCreators(actions, dispatch);
  const { userInfo } = useSelector((state) => state.authReducer);
  const { inboxData, chatMessages } = useSelector((state) => state.chatReducer);
  const [message, setMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [activeUser, setActiveUser] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [onChatUser, setOnChatUser] = useState(null);
  const [contactToggle, setContactToggle] = useState(false);
  const [contentIndex, setContentIndex] = useState("0");
  const msgRef = useRef(null);

  const getInbox = useCallback((id) => {
    action.getInboxData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInboxChatUser = useCallback((data, chatUser) => {
    const chat = data.find(
      (item) => item.conversation_id === chatUser?.conversation_id
    );

    const chat2 = data.find((item) => item.id === chatUser?.id);

    if (chat || chat2) {
      setOnChatUser(chat || chat2);
    }
  }, []);

  useEffect(() => {
    if (params && params.id && userInfo) {
      getTeacherInbox(userInfo.id, params.id).then((response) => {
        if (response?.data && response?.data?.data) {
          const { data } = response.data;
          if (data && data.length) {
            setUser(data[0]);

            if (isMobile) {
              setContentIndex("1");
            }
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, userInfo]);

  useEffect(() => {
    if (inboxData && inboxData.length) {
      if (onChatUser) {
        setInboxChatUser(inboxData, onChatUser);
      }
    }
  }, [inboxData, onChatUser, setInboxChatUser]);

  useEffect(() => {
    chatSocket.on("get-message", (data) => {
      if (data) {
        dispatch(appendMessages(data));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    inboxSocket.on("inbox-data", (data) => {
      if (data && data.length) {
        dispatch(appendInbox(data));
      }
    });
  }, [dispatch, onChatUser, setInboxChatUser]);

  const onInboxConnect = useCallback(() => {
    if (userInfo && userInfo.id) {
      inboxSocket.emit("join", `inbox_${userInfo.id}`);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo) {
      inboxSocket.on("connect", onInboxConnect);

      return () => {
        inboxSocket.disconnect();
        chatSocket.disconnect();
      };
    }
  }, [onInboxConnect, userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.id) {
      getInbox(userInfo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    const debouncing = debounce(() => {
      if (searchText) {
        getTeacher(searchText);
      }
    }, 200);

    debouncing();

    return () => {
      debouncing.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const getTeacher = (name) => {
    getTeachers(name, userInfo.id).then((response) => {
      const teacher = response.data;
      setTeachers(teacher);
    });
  };

  const handleContactInfo = () => {
    setContactToggle(!contactToggle);
  };

  const setUser = (data) => {
    const conversation_id = data.conversation_id || data.id;
    const chatUserConversationId =
      onChatUser?.conversation_id || onChatUser?.id;

    if (conversation_id !== chatUserConversationId) {
      const leave_room = `chat_${chatUserConversationId}`;
      const join_room = `chat_${conversation_id}`;

      chatSocket.emit("leave", { room: leave_room, user_id: userInfo.id });
      chatSocket.emit("join", { room: join_room, user_id: userInfo.id });

      dispatch({ type: actionTypes.GET_CHAT_MESSAGES, payload: [] });
      dispatch({ type: actionTypes.SCROLL_TO_BOTTOM, payload: true });

      getChatMessages(conversation_id);
      setOnChatUser(data);
      setActiveUser(conversation_id);
    }
  };

  const getChatMessages = (id) => {
    action.getMessages(id, userInfo.id);
  };

  const handleSearchTeacher = (data) => {
    setSearchText("");
    setUser(data);
    setContentIndex("1");

    setTimeout(() => {
      msgRef.current?.focus();
    }, 500);
  };

  const handleInboxUser = (data) => {
    setUser(data);
    setContentIndex("1");

    setTimeout(() => {
      msgRef.current?.focus();
    }, 500);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMsg();
    }
  };

  const handleSendMsg = () => {
    if (message.trim()) {
      setMessage("");

      sendMessage({
        conversation_id: onChatUser?.conversation_id || null,
        sender_id: userInfo.id,
        receiver_id: onChatUser.id,
        message_text: message,
      }).then((response) => {
        const { conversation_id } = response.data?.data;
        if (!onChatUser.conversation_id) {
          const join_room = `chat_${conversation_id}`;
          chatSocket.emit("join", { room: join_room, user_id: userInfo.id });
        }
      });
    }
  };

  const handleChatClose = () => {
    setActiveUser("");
    setOnChatUser(undefined);
  };

  const fetchMessages = () => {
    if (onChatUser && chatMessages && chatMessages.next) {
      setFetchingMessages(true);

      const pageNum = Number(chatMessages.current_page) + 1;
      action.getMessages(
        onChatUser.conversation_id,
        userInfo.id,
        pageNum,
        () => {
          setFetchingMessages(false);
          messageContainerRef.current.scrollTop = chatMessages.data.length * 30;
        }
      );
    }
  };

  const handleOnScroll = () => {
    if (messageContainerRef.current) {
      const { scrollTop } = messageContainerRef.current;
      if (scrollTop === 0 && !fetchingMessages) {
        fetchMessages();
      }
    }
  };

  const getLeftContent = useMemo(() => {
    if (isMobile && contentIndex === "1") {
      return false;
    }

    return true;
  }, [contentIndex]);

  const getRightContent = useMemo(() => {
    if (isMobile && contentIndex === "0") {
      return false;
    }

    return true;
  }, [contentIndex]);

  return (
    <div className={styles.chat}>
      <Header />

      <div className={styles.container}>
        {getLeftContent && (
          <div className={styles.left_container}>
            <div className={styles.mainHeaderName}>
              <div className={styles.chat_options}>
                <UserAvatar
                  name={userInfo.name}
                  color={userInfo.color || "blue"}
                />

                <p className="f-sm font-weight-600-imp mb-0 overflow-elipsis ml-12">
                  {userInfo?.name || ""}
                </p>
              </div>
            </div>

            <div className={styles.userBodyWrapper}>
              <div className={styles.search_bar}>
                <TextInput
                  allowClear
                  placeholder={"Search Teacher"}
                  onChange={(e) => setSearchText(e.target.value)}
                  className={styles.search_bar_input}
                  value={searchText}
                  prefix={
                    <img
                      src={chat_search_icon}
                      alt=""
                      className={styles.chat_search_icon}
                    />
                  }
                />
              </div>
              <div className={styles.users}>
                {searchText ? (
                  <ChatItemComponent
                    data={teachers}
                    handleInboxUser={handleSearchTeacher}
                  />
                ) : (
                  <ChatItemComponent
                    data={inboxData}
                    handleInboxUser={handleInboxUser}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {getRightContent && (
          <>
            {activeUser ? (
              <div className={styles.right_container}>
                <ChatHeader
                  handleCloseChat={handleChatClose}
                  handleBack={() => setContentIndex("0")}
                  isContact={contactToggle}
                  user={onChatUser}
                  handleClickContact={handleContactInfo}
                />

                <div
                  onScroll={handleOnScroll}
                  ref={messageContainerRef}
                  className={styles.messages}
                >
                  <MessageCard
                    data={chatMessages?.data || []}
                    sender={userInfo?.name || ""}
                    receiver={onChatUser?.name || ""}
                    conversationId={
                      onChatUser?.conversation_id || onChatUser?.id
                    }
                    userId={userInfo.id}
                  />
                </div>

                {onChatUser && onChatUser.status === "Pending" ? (
                  <div className={styles.chatRequest}>
                    <Text font={"LIGHTER"} className={styles.width} size={"S"}>
                      {"Chat Request is pending..."}
                    </Text>
                  </div>
                ) : onChatUser.status === "Rejected" ? (
                  <div className={styles.chatRequest}>
                    <Text font={"LIGHTER"} size={"S"}>
                      {"Chat is Blocked."}
                    </Text>
                  </div>
                ) : null}

                <div className={styles.input_field}>
                  <TextInput
                    ref={msgRef}
                    className={styles.message_input}
                    required
                    allowClear
                    value={message}
                    handleKeyPress={handleKeyPress}
                    disabled={
                      onChatUser?.status === "Pending" ||
                      onChatUser?.status === "Rejected"
                    }
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={"Type a Message"}
                    suffix={
                      <div
                        onClick={message ? handleSendMsg : () => {}}
                        className={
                          message?.length === 0
                            ? styles.grey_button
                            : styles.green_button
                        }
                      >
                        <SendOutlined className={styles.send_button} />
                      </div>
                    }
                  />
                </div>
              </div>
            ) : (
              <FirstPage />
            )}
          </>
        )}

        {contactToggle && (
          <ContactPage user={onChatUser} onClose={handleContactInfo} />
        )}
      </div>
    </div>
  );
};

export default Chat;
