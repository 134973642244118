import React from "react";

import styles from "./styles.module.css";

const UserAvatar = ({ name, color, width, height, fontSize }) => {
  const initials = name
    .split(" ")
    .map((word) => word[0])
    .join("");

  return (
    <div
      className={styles.avatar}
      style={{
        backgroundColor: color,
        width,
        height,
        fontSize,
        border: color ? `1px solid ${color}` : "none",
      }}
    >
      {initials}
    </div>
  );
};

export default UserAvatar;
