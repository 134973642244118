import React from "react";

import { Row, Space } from "antd";

import Text from "src/modules/parent/components/text";
import FlatButton from "src/modules/parent/components/flat-button";

import styles from "../../styles.module.css";

const getFilter = (grade, subject) => {
  if (grade && subject) {
    return `${grade}, ${subject}`
  }

  return `${grade || subject}`
}

const NoData = ({ handleContact = () => {}, grade = "", subject = "" }) => (
  <Space direction={"vertical"} align={"center"} className="mt-50 mb-50 w-100">
    <Text
      size={"M"}
      center
      className={"mt-20 mb-20"}
      lHeight={"S"}
      color={"rgba(44, 44, 44, 1)"}
      title={
        `We have a slot opening up for ${getFilter(grade, subject)} soon. Please leave your contact information with us so we can reach out to you.`
      }
    />

    <Row justify={"center"}>
      <FlatButton
        title={"Contact Us"}
        width={"150px"}
        onClick={handleContact}
        className={styles.contactBtn2}
      />
    </Row>
  </Space>
);

export default React.memo(NoData);
