import React from "react";

import { Space } from "antd";

import Text from "src/modules/parent/components/text";

import styles from "../../styles.module.css";

const NoChildCard = ({ handleClick = () => {} }) => (
  <div className={styles.childContainer}>
    <div onClick={handleClick} className={styles.circleContainer}>
      <Space direction={"vertical"}>
        <div className={styles.roundContainer}>+</div>

        <Text
          size={"XS"}
          className={"text-align-center"}
          lHeight={"S"}
          font={"SEMIBOLD"}
        >
          {"Add Child"}
        </Text>
      </Space>
    </div>
  </div>
);

export default React.memo(NoChildCard);
