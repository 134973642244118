import React, { useMemo, useState } from "react";

import { Row, Space } from "antd";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import union from "../../../assets/svgs/union.svg";
import { ReviewModal } from "src/modules/parent/pages/home/components/modal";
import clock_icon from "src/modules/parent/pages/home/assets/svgs/clock.svg";
import { TeacherCard } from "src/modules/parent/pages/home/components";
import InstructorReviews from "src/modules/parent/components/instructor-reviews";
import { getGrades, capitilizeFirstLetter } from "src/modules/parent/utils";

import calendar from "../../../assets/svgs/calendar.svg";

const overview = [
  {
    id: "0",
    label: "Grade 3-5",
    image: union,
  },
  {
    id: "1",
    label: "04:30pm - 04:55pm (Asia/Karachi)",
    image: clock_icon,
  },
  {
    id: "2",
    label: "Tue, Thu",
    image: calendar,
  },
];

const Overview = ({ teacher, data, slot }) => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const navigate = useNavigate();
  const getStartTime = useMemo(() => {
    return moment(slot.start_time, "HH:mm").format("hh:mm A");
  }, [slot.start_time]);

  const getEndTime = useMemo(() => {
    return moment(slot.end_time, "HH:mm").format("hh:mm A");
  }, [slot.end_time]);

  const getOverview = useMemo(() => {
    if (data.grades && data.grades.length) {
      const grades = getGrades(data);

      overview[0].label = `Grade ${grades}`;
    }

    if (slot && slot.start_time && slot.end_time) {
      overview[1].label = `${getStartTime} - ${getEndTime} (${
        slot.time_zone || "Asia/Karachi"
      })`;
    }

    if (slot && slot.days) {
      overview[2].label = slot.days
        ?.split(",")
        ?.map((el) => capitilizeFirstLetter(el))
        ?.join(", ");
    }

    return overview;
  }, [data, getEndTime, getStartTime, slot]);

  const navigateToChat = (id) => {
    navigate(`/chat/${id}`);
  };

  const onHandleReview = (item) => {
    setReviewData(item);
    setShowReviewModal(true);
  };

  return (
    <div className={isMobile ? "pb-20" : "pb-20 pl-20 pr-20"}>
      <Space direction={"vertical"}>
        {getOverview.map((item) => (
          <Row key={item.id} align={"middle"}>
            <Space align={"center"}>
              <img src={item.image} width={16} alt={"union"} />

              <Text
                size={"S"}
                font={"LIGHTER"}
                lHeight={"S"}
                color={"#000000"}
                className={"mt-5"}
                title={item.label}
              />
            </Space>
          </Row>
        ))}
      </Space>

      <Text
        size={"M"}
        className={"mb-20 mt-40"}
        font={"SEMIBOLD"}
        color={"#0E153A"}
        title={"Course Description"}
      />

      <Text
        size={"S"}
        font={"LIGHTER"}
        lHeight={"S"}
        color={"#000000"}
        className={"mt-5"}
        title={data?.description}
      />

      <Text
        size={"M"}
        className={"mb-20 mt-40"}
        font={"SEMIBOLD"}
        color={"#0E153A"}
        title={"Course Teacher"}
      />

      <Row>
        <TeacherCard
          id={teacher?.id}
          width={180}
          height={180}
          name={teacher?.name}
          picture={teacher?.picture}
          onHandleClick={navigateToChat}
          onHandleReview={onHandleReview}
        />
      </Row>

      <InstructorReviews reviews={teacher?.reviews || []} />

      <ReviewModal
        isVisible={showReviewModal}
        onCancel={() => setShowReviewModal(false)}
        onSubmit={() => setShowReviewModal(false)}
        {...reviewData}
      />
    </div>
  );
};

export default React.memo(Overview);
