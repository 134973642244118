import { Button, Col, Row } from 'antd';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Text from 'src/modules/parent/components/text';
import styles from "./styles.module.css";


const RegPauseBanner = () => {
      const navigate = useNavigate();
  return (
    <Row center gutter={[20,20]} style={{margin:"1rem"}}>
    <Col span={24}>
      <Text
        size={"XXL2"}
        font={"SEMIBOLD"}
        color={"rgba(44, 44, 44, 1)"}
        title={"Applications Currently Paused!"}
        center
      />
    </Col>

    <Col span={24}>
      <Text
        size={"S"}
        font={"NORMAL"}
        color={"rgba(44, 44, 44, 1)"}
        title={
          "We’re not actively recruiting at the moment—but you can still get ahead!"
        }
        center
      />
    </Col>

    <Col span={24} style={{textAlign:"center"}}>
      {" "}
      <svg
        width="51"
        height="80"
        viewBox="0 0 51 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.78128 15.5147C9.78128 15.5147 7.52091 33.2807 4.21109 40.8666C4.21109 40.8666 -0.157877 52.4503 6.99457 56.5869H45.198C46.0951 53.8551 46.644 51.0213 46.8319 48.1524C47.2227 42.7154 44.8267 37.9529 43.7482 32.7773C42.7601 28.0277 41.7913 23.2167 41.8107 18.3412C41.8656 9.9745 35.1039 9.88416 35.1039 9.88416L9.78128 15.5147Z"
          fill="black"
        />
        <path
          d="M25.8398 35.027C34.6935 35.027 41.8707 27.186 41.8707 17.5135C41.8707 7.84107 34.6935 0 25.8398 0C16.9862 0 9.80896 7.84107 9.80896 17.5135C9.80896 27.186 16.9862 35.027 25.8398 35.027Z"
          fill="black"
        />
        <path
          d="M50.5811 80.0001H0.567173C0.0444471 72.7809 0.847897 65.1 3.881 58.5782C6.9141 52.0564 12.1252 46.5064 18.6335 43.8719C25.1418 41.2373 32.9246 41.7668 38.6842 45.8672C43.6179 49.3799 46.7284 55.1365 48.3224 61.1159C49.9164 67.0953 50.3617 73.0618 50.5811 79.2607"
          fill="#AFD136"
        />
        <path
          d="M30.4421 46.1758L26.2413 53.807L22.0405 46.1758V34.224H30.4421V46.1758Z"
          fill="#B0503A"
        />
        <path
          d="M23.4696 39.4894C19.7231 38.7282 16.2507 36.522 14.3356 33.2901C12.4011 30.0066 11.7304 26.0942 11.724 22.2753C11.724 18.2274 12.4269 14.1183 14.2743 10.5187C16.1217 6.91913 19.175 3.84854 22.9795 2.45516C26.784 1.06178 31.3107 1.48753 34.5058 3.97755C37.3624 6.19664 38.9068 9.7833 39.4129 13.3313C39.9191 16.8792 39.5064 20.553 39.0905 24.1428C38.8036 26.6167 38.5134 29.1132 37.7235 31.4742C36.9336 33.8352 35.5956 36.0865 33.5772 37.5412C30.6497 39.6635 26.942 40.1957 23.4696 39.4894Z"
          fill="#E77A55"
        />
        <path
          d="M25.8398 24.1545C27.0168 24.1545 27.971 23.2003 27.971 22.0232C27.971 20.8462 27.0168 19.892 25.8398 19.892C24.6627 19.892 23.7085 20.8462 23.7085 22.0232C23.7085 23.2003 24.6627 24.1545 25.8398 24.1545Z"
          fill="#B0503A"
        />
        <path
          d="M20.3726 20.8186C21.055 20.8186 21.6081 20.2516 21.6081 19.5522C21.6081 18.8528 21.055 18.2858 20.3726 18.2858C19.6902 18.2858 19.1371 18.8528 19.1371 19.5522C19.1371 20.2516 19.6902 20.8186 20.3726 20.8186Z"
          fill="black"
        />
        <path
          d="M30.9981 20.8186C31.6805 20.8186 32.2336 20.2516 32.2336 19.5522C32.2336 18.8528 31.6805 18.2858 30.9981 18.2858C30.3157 18.2858 29.7626 18.8528 29.7626 19.5522C29.7626 20.2516 30.3157 20.8186 30.9981 20.8186Z"
          fill="black"
        />
        <path
          d="M25.5027 27.304C24.5601 27.3223 23.631 27.0742 22.8194 26.5872C22.0078 26.1003 21.3456 25.3937 20.9069 24.5465C20.8835 24.497 20.8701 24.4433 20.8674 24.3885C20.8646 24.3337 20.8726 24.2789 20.8908 24.2272C20.9091 24.1756 20.9373 24.1281 20.9737 24.0876C21.0102 24.0471 21.0542 24.0143 21.1032 23.9911C21.1522 23.9674 21.2054 23.9537 21.2596 23.9508C21.3138 23.948 21.3681 23.9561 21.4192 23.9746C21.4704 23.9931 21.5174 24.0217 21.5576 24.0588C21.5978 24.0958 21.6304 24.1406 21.6536 24.1904C22.0506 24.9375 22.6544 25.5503 23.3904 25.9532C24.1264 26.3561 24.9626 26.5315 25.7956 26.4578C28.8306 26.2977 29.6577 24.2525 29.706 24.1708C29.7475 24.0681 29.827 23.9859 29.9274 23.9419C30.0279 23.898 30.1413 23.8957 30.2434 23.9356C30.345 23.9764 30.4264 24.0564 30.4699 24.1581C30.5133 24.2598 30.5152 24.3748 30.4751 24.4779C30.4365 24.5857 29.4292 27.1112 25.8375 27.3007L25.5027 27.304Z"
          fill="black"
        />
        <path
          d="M18.6128 43.861C18.6128 43.861 22.0637 64.9598 35.1371 80H15.4269C15.4269 80 8.40226 54.5929 10.9032 48.8022C10.9097 48.8022 15.1619 44.9332 18.6128 43.861Z"
          fill="#EA1B48"
        />
        <path
          d="M27.9417 1.4209H25.6845C11.9157 2.38608 11.9093 16.3651 11.9093 16.3651C20.7832 16.1881 25.8135 5.93149 25.8135 5.93149C25.8135 5.93149 30.6697 16.6353 39.6436 18.2858C39.6371 18.2858 42.2845 1.7201 27.9417 1.4209Z"
          fill="black"
        />
      </svg>
    </Col>

    <Col span={24}>
      <Text
        size={"S"}
        font={"NORMAL"}
        color={"rgba(44, 44, 44, 1)"}
        lHeight={"S"}
        title={
          <>
            <span style={{ fontWeight: "700" }}>Join our{" "}
            <span style={{ color: "#564CB1" }}>
              <Link to={"/ignite"}>IGNITE Teacher Training diploma</Link>
            </span>—in collaboration with LumsX</span> and take your teaching skills to
            the next level! Master online education, build a thriving
            teaching career, and gain an edge for future recruitment with
            Dot & Line and beyond.
          </>
        }
        center
      />
    </Col>

    <Col span={24} style={{textAlign:"center"}}>
      <Button
        onClick={() => {
          navigate("/ignite");
        }}
        className={styles.searchButton}
      >
        {"Learn More!"}
      </Button>
    </Col>
  </Row>
  )
}

export default RegPauseBanner;