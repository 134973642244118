/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import FlatButton from "../../../components/flat-button";
import PhoneWithFlagInput from "../../../components/phone-input";
import TextInput from "../../../components/text-input";
import styles from "./../styles.module.css";
import * as actions from "../../../store/actions";
import { useSelector } from "react-redux";
import parsePhoneNumberWithError from "libphonenumber-js";
import actionTypes from "src/modules/parent/store/action-types";
import {
  fillRequiredNotification,
  phoneNumberNotification,
  thankUNotification,
} from "src/modules/parent/utils/notificationData";
import { defaultCountry, defaultUAE } from "src/modules/parent/utils";

const ConactUsFormContent = ({ isOlevel = false }) => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.profileReducer);
  const action = bindActionCreators(actions, dispatch);
  const { userInfo } = useSelector((state) => state.authReducer);
  const [name, setName] = useState(userInfo?.name || "");
  const [email, setEmail] = useState(userInfo?.email || "");
  const [phone, setPhone] = useState(isOlevel ? "" : userInfo?.phone || "");
  const [country, setCountry] = useState(null);
  const [comment, setComment] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validPhone, setValidPhone] = useState(false);

  useEffect(() => {
    if (isOlevel) {
      setCountry(defaultUAE);
      setPhone("");
    } else {
      setCountry(defaultCountry);
    }
  }, [isOlevel]);

  useEffect(() => {
    if (userInfo && userInfo?.phone && !isOlevel) {
      const phoneNumber = parsePhoneNumberWithError(userInfo?.phone);
      if (phoneNumber && phoneNumber.isValid()) {
        const searchPhone = countries.find(
          (i) => i.code === phoneNumber.country
        );
        setCountry(searchPhone);
        setPhone(phoneNumber.number);
      }
    }
  }, []);

  const countryOnChange = (e) => {
    const searchPhone = countries.find((i) => i.id === e.toString());
    if (searchPhone) {
      setCountry(searchPhone);
      setPhone("");
    }
  };

  const isValidated = () => {
    if (!name || !phone) {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: fillRequiredNotification,
      });
      setIsError(true);
      return false;
    }
    return true;
  };

  const submitHandler = () => {
    if (isValidated()) {
      const phoneNumber = parsePhoneNumberWithError(phone);
      if (phoneNumber && phoneNumber.isValid()) {
        setLoading(true);
        const data = {
          name,
          email,
          phone_number: phone,
          comment,
        };

        if (isOlevel) {
          delete data.email;
        }

        action
          .contactUs(data)
          .then(() => {
            setLoading(false);
            dispatch({
              type: actionTypes.NOTIFICATIONDATA,
              payload: thankUNotification,
            });
            action.setContactUsModal(false);
          })
          .catch(() => setLoading(false));
      } else {
        setValidPhone(false);
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: phoneNumberNotification,
        });
        setIsError(true);
      }
    }
  };

  return (
    <div className={[styles.contact_us, "contact-us"].join(" ")}>
      <p className={"f-lg blue-color mb-20 text-align-center"}>Contact Us</p>
      <p className={"f-sm dark-grey-color mb-40 text-align-center"}>
        Curious about something? <br /> The folks here would love to hear from
        you!
      </p>
      <Form>
        <Form.Item
          hasFeedback={!name && isError ? true : false}
          validateStatus={!name && isError ? "error" : "success"}
        >
          <TextInput
            required
            allowClear
            value={name}
            placeholder={"Enter Name"}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>

        {!isOlevel && (
          <Form.Item
            hasFeedback={!email && isError ? true : false}
            validateStatus={!email && isError ? "error" : "success"}
          >
            <TextInput
              required
              allowClear
              value={email}
              type={"email"}
              placeholder={"Enter Email"}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
        )}

        <Form.Item
          hasFeedback={(!phone && isError) || validPhone ? true : false}
          validateStatus={
            (!phone && isError) || validPhone ? "error" : "success"
          }
        >
          <PhoneWithFlagInput
            showSearch
            required
            inputPlaceholder={"Enter phone"}
            value={phone}
            countryOnChange={countryOnChange}
            countryPlaceholder={"Select"}
            selectedId={isOlevel ? defaultUAE?.id : defaultCountry?.id}
            selectedCountry={country?.value}
            onChange={(e) => setPhone(e?.target?.value)}
          />
        </Form.Item>
        <Form.Item>
          <TextInput
            rows={4}
            value={comment}
            multiline={true}
            placeholder={"Enter Message"}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <div className="d-flex justify-content-center mt-30">
            <FlatButton
              isForm={true}
              width={"217px"}
              title={"Submit"}
              loading={loading}
              onClick={submitHandler}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConactUsFormContent;
