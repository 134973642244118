import React from "react";

import Text from "src/modules/parent/components/text";
import closeIcon from "../../assets/svgs/closeIcon.svg";
import noUserImage from "../../../../assets/img/no_user.png";

import styles from "../../styles.module.css";

const anonymousAvatar = noUserImage;

export default function ContactPage({ onClose, user }) {
  return (
    <div className={styles.contactPageWrapper}>
      <div className={styles.contactHeader}>
        <Text title={"Contact Info"} font={"SEMIBOLD"} size={"M"} />

        <img
          src={closeIcon}
          alt={"close-icon"}
          onClick={onClose}
          className={styles.closeIcon}
        />
      </div>

      <div className={styles.contactBody}>
        <div>
          <img
            src={user?.picture || anonymousAvatar}
            alt=""
            className={styles.contactPic}
          />
        </div>

        <div>
          <Text
            title={user?.name || ""}
            font={"SEMIBOLD"}
            lHeight={"XXL2"}
            size={"M"}
          />

          {/* <Text title={user?.phone || ""} font={"LIGHTER"} size={"M"} /> */}
        </div>

        <div className={styles.contactDescription}>
          <Text
            title={user?.bio || ""}
            size={"S"}
            font={"LIGHTER"}
            lHeight={"S"}
            className={styles.elipsis}
          />
        </div>

        {/* <div className={styles.reportBtnWrapper}>
          <Button type={"custom"} className={styles.reportBtn}>
            {"Report"}
          </Button>
        </div> */}
      </div>
    </div>
  );
}
