import React from "react";

import { Col, Row } from "antd";
import banner from "../../assets/banner/banner.png";
import TextComponent from "./text";

import styles from "./styles.module.css";

const BannerComponent = ({ handleClick = () => {} }) => (
  <div className={styles.container}>
    <Row className="app-container">
      <Col xxl={14} xl={14} lg={14} md={24} sm={24} xs={24}>
        <TextComponent handleClick={handleClick} />
      </Col>

      <Col
        className={styles.bannerImg}
        xxl={10}
        xl={10}
        lg={10}
        md={24}
        sm={24}
        xs={24}
      >
        <Row justify={"end"}>
          <img src={banner} width={"100%"} alt={"banner"} />
        </Row>
      </Col>
    </Row>
  </div>
);

export default React.memo(BannerComponent);
