import { useState, useEffect } from "react";

import * as actions from "src/modules/parent/store/actions";

const useFetchData = (id, type) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      actions
        .getAssignmentById(id, type)
        .then((response) => {
          setLoading(false);
          setData(response);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, [id, type]);

  return { data, loading, error };
};

export default useFetchData;
