import React from "react";

import { Col, Row, Button } from "antd";
import ModalComponent from "src/modules/parent/components/modal";
import { naplanGrades } from "src/modules/parent/pages/international/staticData";

import styles from "./styles.module.css";

const GradeModal = ({
  setGrade,
  grade,
  show = false,
  setShow = () => {},
  onSearch = () => {},
}) => {
  const handleGradeSelect = (i) => setGrade(i.id);

  return (
    <ModalComponent
      containerClassName={styles.modalContainer}
      isModalVisible={show}
      onCancel={() => setShow(false)}
      style={{ margin: "0px" }}
      footer={false}
    >
      <Col span={24}>
        <p className={["font-18", styles.description].join(" ")}>
          {
            "Pick the grade you’re interested in and begin your exciting learning journey with us!"
          }
        </p>

        <div className={styles.subjectContainer}>
          <Row className="mb-10" justify={"center"} gutter={[20, 20]}>
            {naplanGrades.map((i) => (
              <Col key={i.id} xxl={8} xl={8} lg={12} md={12} sm={12} xs={12}>
                <div
                  key={i.id}
                  onClick={() => handleGradeSelect(i)}
                  className={grade === i.id ? styles.selectedTab : styles.tabs}
                >
                  {i.label}
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <Row justify={"center"}>
          <Button onClick={onSearch} className={styles.searchButton}>
            {"Search"}
          </Button>
        </Row>
      </Col>
    </ModalComponent>
  );
};

export default React.memo(GradeModal);
