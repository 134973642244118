import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Form } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import FlatButton from "../../components/flat-button";
import TextInput from "../../components/text-input";
import { feedbackText } from "../../utils/static-data";
import styles from "./styles.module.css";
import * as actions from "../../store/actions";
import actionTypes from "../../store/action-types";
import { teacherReviewNotification } from "../../utils/notificationData";

const PostFeedback = ({ teacherName, feedbackSubmitted, teacherId }) => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);

  const isValidated = () => {
    if (!rating || !feedback) {
      return false;
    }
    return true;
  };

  const clickHandler = () => {
    const data = {
      rating,
      teacher_id: teacherId,
      review: feedback,
    };
    if (isValidated()) {
      setLoading(true);
      action
        .postReview(data)
        .then(() => {
          setLoading(false);
          feedbackSubmitted();
          setRating(0);
          setFeedback("");
        })
        .catch(() => setLoading(false));
    } else {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: teacherReviewNotification,
      });
    }
  };

  return (
    <div className={styles.post_feedback}>
      <Form>
        <p className={styles.heading1}>Post your review</p>
        <p className="font-16 text-align-center mb-20">
          How was your experience with{" "}
          <span className="font-weight-500">{teacherName}</span>
        </p>
        <div className="d-row justify-content-center yellow-color mb-10">
          {[1, 2, 3, 4, 5].map((element) => (
            <div
              key={element}
              className="cursor-pointer"
              onClick={() => setRating(element)}
            >
              {element > rating ? (
                <StarOutlined className="font-30 ml-5" />
              ) : (
                <StarFilled className="font-30 ml-5" />
              )}
            </div>
          ))}
        </div>
        <p
          className={[
            styles.h_30,
            "grey-color text-align-center font-18 mb-20",
          ].join(" ")}
        >
          {feedbackText[rating - 1]}
        </p>
        <Form.Item>
          <TextInput
            rows={6}
            required={true}
            multiline={true}
            value={feedback}
            placeholder={"Add Comments"}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </Form.Item>
        <div className="d-flex justify-content-center mt-30 mb-10">
          <FlatButton
            loading={loading}
            onClick={clickHandler}
            width="217px"
            title={"Submit"}
          />
        </div>
      </Form>
    </div>
  );
};

export default PostFeedback;
