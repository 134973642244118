import actionTypes from "../../action-types";
import axios from "axios";
import {
  SEARCH_TEACHER_INBOX,
  GET_NOTIFICATION,
  UPDATE_NOTIFICATION,
  IGNITE_FORM,
  UPDATE_TOKEN,
  SUBSCRIBED_EMAIL,
} from "src/modules/parent/config/api";
import axiosInstance from "src/modules/parent/config/axios";
import { getRequest, postRequest } from "src/modules/parent/config/network";

const WordPressPosts =
  "https://dotandlinelearning.com/blog/wp-json/wp/v2/posts";

const WordPressCategories =
  "https://dotandlinelearning.com/blog/wp-json/wp/v2/categories?per_page=100";

const WordPressPostsById =
  "https://dotandlinelearning.com/blog/wp-json/wp/v2/posts";

const featuredMedia = "https://dotandlinelearning.com/blog/wp-json/wp/v2/posts";

export function setContactUsModal(value, isOlevel = false) {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_OLEVEL, payload: isOlevel });

    dispatch({ type: actionTypes.SET_CONTACT_US_MODAL, payload: value });
  };
}

const getFeaturedMedia = async (post) => {
  return new Promise((resolve, reject) => {
    axios.get(`${featuredMedia}/${post.id}?_embed`).then((response) => {
      if (response && response.data._embedded["wp:featuredmedia"]) {
        const featuredMediaUrl =
          response.data._embedded["wp:featuredmedia"][0].source_url;

        resolve({
          id: post.id,
          link: post.link,
          slug: post.slug,
          category: post.categories[0],
          date: post.date,
          title: post.title.rendered,
          source: featuredMediaUrl,
        });
      } else {
        reject(response);
      }
    });
  });
};

export function getPosts(category, page, isRandom) {
  return new Promise((resolve, reject) => {
    const endpoint = isRandom
      ? `${WordPressPosts}?per_page=3`
      : `${WordPressPosts}?categories=${category.id}&per_page=${page}`;

    axios
      .get(endpoint)
      .then((response) => {
        const posts = response.data;

        if (posts && posts.length) {
          Promise.allSettled(
            posts.map(async (i) => await getFeaturedMedia(i))
          ).then((resp) => {
            const data = resp.filter((i) => i.status === "fulfilled");

            resolve({
              ...category,
              posts: data.map((i) => i.value),
            });
          });
        }
      })
      .catch((err) => {
        console.log("🚀 ~ axios.get ~ err:", err);
      });
  });
}

export function getCategories() {
  return (dispatch) => {
    axios.get(WordPressCategories).then((response) => {
      const categories = response.data;
      if (categories && categories.length) {
        const index = categories.findIndex(
          (item) => item.name === "Uncategorized"
        );
        categories.splice(index, 1);

        const cloneCategories = categories.map((i) => {
          return {
            id: i.id,
            label: i.name,
            value: i.name,
          };
        });

        dispatch({
          type: actionTypes.BLOG_CATEGORIES,
          payload: cloneCategories,
        });
      }
    });
  };
}

export function resetBlogs() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.BLOG_POSTS,
      payload: null,
    });
  };
}

export function getPostsByCategory(categories, page, isDetails) {
  return (dispatch) => {
    Promise.all(categories.map(async (i) => await getPosts(i, page))).then(
      (response) => {
        const filteredData = response.filter((i) => i.posts && i.posts.length);

        if (isDetails) {
          dispatch({
            type: actionTypes.BLOG_POSTS,
            payload: filteredData[0],
          });
        } else {
          dispatch({
            type: actionTypes.BLOGS,
            payload: filteredData,
          });
        }
      }
    );
  };
}

export function getSingleCategoryPosts(category, page) {
  return new Promise((resolve, reject) => {
    getPosts(category, page).then(resolve).catch(reject);
  });
}

export function getOlevelPosts(posts, callback) {
  if (posts && posts.length) {
    Promise.allSettled(posts.map(async (i) => await getFeaturedMedia(i))).then(
      (response) => {
        const data = response.filter((i) => i.status === "fulfilled");
        callback({
          payload: data.map((i) => i.value),
        });
      }
    );
  }
}

export function getPostsOlevel() {
  return new Promise((resolve, reject) => {
    try {
      const postIds = [1507, 653, 1448, 1397, 1038, 1499];
      const requests = postIds.map(async (postId) => {
        const { data } = await axios.get(`${WordPressPostsById}/${postId}`);

        return data;
      });

      Promise.all(requests).then((response) => {
        getOlevelPosts(response, ({ payload }) => {
          if (payload && payload.length) {
            resolve({
              id: "0",
              label: "Exams",
              posts: payload,
            });
          }
        });
      });
    } catch (error) {
      reject(error);
      console.error("Error fetching posts:", error);
    }
  });
}

export const getTeachers = async (name, id) => {
  try {
    const teacherName = name ? `&teacher_name=${name}` : "";
    const { data } = await axiosInstance.get(
      `${SEARCH_TEACHER_INBOX}?sender_id=${id}${teacherName}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const igniteFormSubmission = async (values) => {
  try {
    const { data } = await postRequest(IGNITE_FORM, values);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const emailSubscription = (email) => {
  return new Promise((resolve, reject) => {
    try {
      postRequest(SUBSCRIBED_EMAIL, { email })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const storeFCMToken = async (token) => {
  try {
    return await postRequest(`${UPDATE_TOKEN}`, { token, app: "parent_web" });
  } catch (e) {
    return Promise.reject(e);
  }
};

export function getNotifications() {
  return async (dispatch) => {
    try {
      const endpoint = `${GET_NOTIFICATION}`;
      const response = await getRequest(endpoint);

      dispatch({
        type: actionTypes.NOTIFICATIONS,
        payload: response?.data?.data,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.NOTIFICATIONS,
        payload: [],
      });
    }
  };
}

export function setRegisterData(data) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_REGISTER_MODAL,
      payload: data,
    });
  };
}

export function updateNotificationData(id) {
  return (dispatch, getState) => {
    const { notifications } = getState().notificationReducer;
    const clone = [...notifications];
    const foundObject = clone.find((obj) => obj.id === id);

    // Update the is_read property if the object is found
    if (foundObject) {
      foundObject.is_read = 1;
    }

    dispatch({
      type: actionTypes.NOTIFICATIONS,
      payload: clone,
    });
  };
}

export async function updateNotification(id) {
  try {
    const endpoint = `${UPDATE_NOTIFICATION}`;
    const { data } = await postRequest(endpoint, { id });
    return data;
  } catch (error) {
    console.log("🚀 ~ file: index.js:145 ~ return ~ error:", error);
  }
}

export const removeChild = (childId) => ({
  type: actionTypes.REMOVE_CHILD,
  payload: childId,
});