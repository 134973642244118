import React, { useState } from "react";

import { Row, Col, Space, Input, Modal, message, Button } from "antd";
import { isBrowser, isMobile } from "react-device-detect";
import Slider from "react-slick";
import YouTube from "react-youtube";
import ReactPixel from "react-facebook-pixel";

import {
  BannerComponent,
  GetStats,
  Lists,
  BulletList,
  Steps,
  FormCard,
  Testimonials,
  HowToApply,
} from "./components";
import Text from "src/modules/parent/components/text";
import FlatButton from "src/modules/parent/components/flat-button";
import cat4Banner from "src/modules/parent/assets/webp/cat4banner.webp";
import cat4bannerMobile from "src/modules/parent/assets/webp/cat4banner_mobile.webp";
import { crouselSettings } from "src/modules/parent/utils";
import Footer from "src/modules/parent/components/footer";
import Header from "src/modules/parent/components/header";
import mapIcon from "./assets/svgs/map.svg";
import checkIcon from "./assets/svgs/checkmark.svg";
import starIcon from "./assets/svgs/star.svg";
import teacherImg from "./assets/svgs/teachers.svg";
import eligibility from "./assets/svgs/eligibilityCriteria.svg";
import envelop from "./assets/svgs/plane.svg";
import send from "./assets/svgs/send.svg";
import {
  fb_pixel_id_recruitment,
  IS_DEV_ENV,
} from "src/modules/parent/config/constant";
import ContactModal from "src/modules/parent/components/modal/contactModal";
import { FloatingButton } from "src/modules/parent/pages/olevels/components";

import styles from "./styles.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { emailSubscription } from "../../store/actions";
import {
  applyNowButtonRecruitment,
  trackEvents,
} from "src/modules/parent/utils/events/query";
import RegPauseBanner from "./components/reg-pause-banner";

ReactPixel.init(fb_pixel_id_recruitment);

const aboutData = [
  {
    id: "0",
    image: mapIcon,
    text: "60% International Students",
    description: "Diverse global student network",
  },
  {
    id: "1",
    image: checkIcon,
    text: "6 Figure Earnings",
    description: "Earned by more than 10% of our teachers",
  },
  {
    id: "2",
    image: starIcon,
    text: "90% Retention",
    description: "Teachers remain with us year upon year",
  },
];

const opts = {
  height: window?.innerWidth < 991 ? 300 : 350,
  width: "100%",
  playerVars: {
    autoplay: 0,
    rel: 0,
  },
};

const opts2 = {
  height: window?.innerWidth < 991 ? 250 : 520,
  width: "100%",
  playerVars: {
    autoplay: 0,
    rel: 0,
  },
};

const picPadding = isMobile ? "20px" : "50px";

const TeacherRecruitment = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [showModal, setShowModal] = useState(false);
  const [applyNowButtonModal, setApplyNowButtonModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const playerRef = React.useRef(null);
  const whatsappMobile = "https://wa.me/";
  const [showContactModal, setShowContactModal] = useState(false);

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  };

  const handleWhatsApp = () => {
    window.open(`${whatsappMobile}923460086796`);
  };

  const handleClickContact = () => {
    if (!isBrowser) handleWhatsApp();
    else setShowContactModal(true);
  };

  const handleClick = () => {
    if (!email) {
      message.error("Please enter your email address!");
      return;
    }

    messageApi.open({
      key: "subscribe",
      type: "loading",
      content: "Subscribing...",
      duration: 0,
    });

    emailSubscription(email)
      .then((res) => {
        if (res) {
          message.destroy("subscribe");
          message.success("Successfully subscribed!");
        }

        setEmail("");
      })
      .catch((err) => {
        setEmail("");
        message.destroy("subscribe");
        message.error(err.response.data.message);
      });
  };

  const goToRoute = (text) => {
    let route = "";

    switch (text) {
      case "k8":
        route = "https://admin.dotandlinelearning.com/K-8";
        break;
      case "shortcourse":
        route = "https://admin.dotandlinelearning.com/short-course";
        break;
      case "examprep":
        route = "https://admin.dotandlinelearning.com/exam-prep";
        break;

      default:
        break;
    }

    window.open(route, "_blank", "noopener,noreferrer");
  };

  const setModalAndMedia = (videoId) => {
    setShowModal(true);
    setModalData({ url: videoId });
  };

  const handleApplyNowButtonModal = () => {
    setApplyNowButtonModal(true);
    console.log("clicked");
  };

  const handleApplyNowButton = () => {
    setVisible(true);

    trackEvents(applyNowButtonRecruitment);

    if (!IS_DEV_ENV) {
      ReactPixel.trackSingle(
        fb_pixel_id_recruitment,
        "FBK_Recruitment_ApplyNowButton"
      );
    }
  };

  return (
    <div>
      {contextHolder}

      <Modal
        closable
        onCancel={() => setApplyNowButtonModal(false)}
        maskClosable
        centered
        footer={false}
        width={"30rem"}
        className={""}
        open={applyNowButtonModal}
      >
        <RegPauseBanner />
      </Modal>
      <Modal
        closable
        onCancel={() => setVisible(false)}
        maskClosable
        centered
        footer={false}
        width={isMobile ? "90%" : "75%"}
        className={[styles.modal, "g-modal"].join(" ")}
        open={visible}
      >
        <HowToApply handleClick={goToRoute} />
      </Modal>

      {modalData && showModal && (
        <Modal
          closable
          onCancel={() => setShowModal(false)}
          maskClosable
          centered
          footer={false}
          width={isMobile ? "100%" : "60%"}
          className={[styles.modalVideo, "g-modal"].join(" ")}
          open={showModal}
        >
          <div className="mt-20">
            <YouTube
              ref={playerRef}
              videoId={modalData.url}
              opts={opts}
              onReady={onPlayerReady}
              loading={"eager"}
            />
          </div>
        </Modal>
      )}

      <Header />

      <BannerComponent handleClick={handleApplyNowButtonModal} />

      <Row justify={"center"} className="mt-30 mb-30">
        <Text
          size={"XXL"}
          font={"SEMIBOLD"}
          color={"rgba(44, 44, 44, 1)"}
          title={"Why Choose Us?"}
        />
      </Row>

      <Row gutter={[20, 20]} className={"mb-50"} justify={"center"}>
        {isMobile ? (
          <div className="app-container">
            <Slider {...crouselSettings}>
              {aboutData.map((item, index) => (
                <Col className="mb-5 mt-5" key={item.id}>
                  <GetStats
                    image={item.image}
                    text={item.text}
                    description={item.description}
                  />
                </Col>
              ))}
            </Slider>
          </div>
        ) : (
          <Row justify={"center"} gutter={[30, 20]}>
            {aboutData.map((item, index) => (
              <Col key={item.id}>
                <GetStats
                  image={item.image}
                  text={item.text}
                  description={item.description}
                />
              </Col>
            ))}
          </Row>
        )}
      </Row>

      <Row
        gutter={[0, 30]}
        className={["app-container", styles.mainTeacherPartner].join(" ")}
      >
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <Row align={"middle"} className={styles.becomeATeacher}>
            <Space
              align={isMobile ? "center" : "start"}
              size={"large"}
              direction={"vertical"}
            >
              <Text
                size={"XXL"}
                font={"SEMIBOLD"}
                color={"rgba(44, 44, 44, 1)"}
                title={"Our Community of Teachers"}
                center
              />

              <Text
                size={"S"}
                color={"rgba(44, 44, 44, 1)"}
                lHeight={"S"}
                font={"LIGHTER"}
                className={isMobile ? "text-align-center" : "text-left pr-50"}
                title={
                  "Flexible work is the way of the future for millions of women across the globe. We believe every woman can find her purpose, upskill herself and earn from the comfort of her own home. Kickstart your journey to financial independence with an online teaching career today."
                }
              />

              <FlatButton
                title={"Apply Now!"}
                width={"160px"}
                height={"40px"}
                // onClick={handleApplyNowButton}
                onClick={handleApplyNowButtonModal}
                className={styles.applyNowBtn}
              />
            </Space>
          </Row>
        </Col>

        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <div className={styles.mediaClass}>
            <YouTube
              ref={playerRef}
              videoId={"6ATp1iHmKaY"}
              opts={opts}
              onReady={onPlayerReady}
              loading={"eager"}
            />

            <div
              className={styles.overlayClass}
              onClick={() => setModalAndMedia("6ATp1iHmKaY")}
            />
          </div>
        </Col>
      </Row>

      <Row className={["app-container", styles.mainTeacherPartner].join(" ")}>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <div style={{ padding: picPadding }}>
            <img
              src={teacherImg}
              style={{ width: "100%", height: "100%" }}
              alt="teachers"
            />
          </div>
        </Col>

        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <div className={styles.dlineTeacher}>
            <Text
              size={"XXL"}
              font={"SEMIBOLD"}
              color={"rgba(44, 44, 44, 1)"}
              lHeight={"S"}
              center
              title={"What you get as a Dot & Line Teacher Partner"}
            />

            <Lists />
          </div>
        </Col>
      </Row>

      <img
        src={isMobile ? cat4bannerMobile : cat4Banner}
        width={"100%"}
        alt={"cat4Banner"}
      />

      <Row gutter={[0, 20]} className={"app-container mb-30 mt-50"}>
        {!isBrowser && (
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <div style={{ padding: picPadding }}>
              <img
                src={eligibility}
                style={{ width: "100%", height: "100%" }}
                alt="teachers"
              />
            </div>
          </Col>
        )}

        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <div className={styles.dlineTeacher}>
            <Space
              align={isMobile ? "center" : "start"}
              size={"large"}
              direction={"vertical"}
            >
              <Text
                size={"XXL"}
                font={"SEMIBOLD"}
                color={"rgba(44, 44, 44, 1)"}
                lHeight={"S"}
                title={"Eligibility Criteria"}
              />

              <BulletList />

              <FlatButton
                title={"Apply Now!"}
                width={"160px"}
                height={"40px"}
                // onClick={handleApplyNowButton}
                onClick={handleApplyNowButtonModal}
                className={styles.applyNwBtn}
              />
            </Space>
          </div>
        </Col>

        {isBrowser && (
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <div style={{ padding: picPadding }}>
              <img
                src={eligibility}
                style={{ width: "100%", height: "100%" }}
                alt="teachers"
              />
            </div>
          </Col>
        )}
      </Row>

      <div className="app-container mb-50">
        <Steps />
      </div>

      <div className="app-container mb-50">
        <Row justify={"center"}>
          <Text
            size={"XXL"}
            font={"SEMIBOLD"}
            color={"#2C2C2C"}
            title={"What are you applying for?"}
            center
          />

          <Text
            size={"S"}
            className={"mt-20"}
            font={"LIGHTER"}
            lHeight={"S"}
            title={
              "A 3% acceptance rate from thousands of applicants highlights our commitment to recruiting top-tier talent and setting a high benchmark for excellence. Check out the different categories you can apply for as a teacher!"
            }
            center
          />
        </Row>
      </div>

      <FormCard />

      <Row justify={"center"}>
        <FlatButton
          title={"Apply Now!"}
          width={"160px"}
          height={"40px"}
          // onClick={handleApplyNowButton}
          onClick={handleApplyNowButtonModal}
          className={styles.applyNowBtn}
        />
      </Row>

      <div className={styles.testimonials}>
        <Text
          size={"XXL"}
          font={"SEMIBOLD"}
          color={"#2C2C2C"}
          title={"Testimonials"}
          center
        />

        <Testimonials />
      </div>

      <Row gutter={[20, 20]} className={"mt-20 mb-40"} justify={"center"}>
        <div style={{ width: isBrowser ? "70%" : "90%", position: "relative" }}>
          <YouTube
            ref={playerRef}
            videoId={"oGM74Y69lOU"}
            opts={opts2}
            onReady={onPlayerReady}
            loading={"eager"}
          />

          <div
            className={styles.overlayClass}
            onClick={() => setModalAndMedia("oGM74Y69lOU")}
          />
        </div>
      </Row>

      <div className={styles.stayTuned}>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <div className={styles.innerDivTuned}>
            <Space direction={"vertical"} size={"middle"}>
              <img src={envelop} alt="plane" />

              <Text
                size={"XXL"}
                font={"SEMIBOLD"}
                color={"rgba(0, 0, 0, 1)"}
                title={"Stay Tuned!"}
                center
              />

              <Text
                size={"S"}
                font={"LIGHTER"}
                color={"rgba(0, 0, 0, 1)"}
                title={
                  "Enter your email address and get notifications to stay updated!"
                }
                center
              />

              <Input
                className={styles.inputClass}
                placeholder={"Enter your e-mail address"}
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                suffix={
                  <img
                    src={send}
                    onClick={handleClick}
                    height={"57px"}
                    className={"cursor-pointer"}
                    alt="sendButton"
                  />
                }
              />
            </Space>
          </div>
        </Col>

        <Row className="mt-20" justify={"center"}>
          <div className={styles.lastText}>
            {"For Queries and Complaints, Contact us on "}
            <span className={styles.underlineText}>{"+92 346 0086796"}</span>
            {" or "}
            <span className={styles.underlineText}>{"+92 346 0086791"}</span>
          </div>
        </Row>
      </div>

      <Footer />

      {/* WHATSAPP FLOATING BUTTON */}
      <FloatingButton handlePress={handleClickContact} />

      <ContactModal
        show={showContactModal}
        title={"Contact Representative"}
        name={"Mahe Zehra:"}
        number={"+923460086796"}
        color={"#2ca58d"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal(false)}
      />
    </div>
  );
};

export default TeacherRecruitment;
