/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Pagination, Row } from "antd";
import React, { Fragment, useCallback, useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import FreeClassCard from "../../components/free-class-card";
import styles from "./styles.module.css";
import AppLoader from "../../components/app-loader";
import { bindActionCreators } from "redux";
import { logEvent } from "firebase/analytics";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import { IS_DEV_ENV } from "src/modules/parent/config/constant";
import { analytics } from "src/modules/parent/config/firebase";
import { useSearchParams } from "react-router-dom";
import events from "../../utils/events";

const initialOptions = {
  page: 1,
  limit: 20,
};

const googleInfluencerFreeClass = events.google.freeClasses.influencerPageLand;

const FreeClasses = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const isInfluencer = params.get("influencer");
  const action = bindActionCreators(actions, dispatch);
  const { freeClassesData } = useSelector((state) => state.packageReducer);

  useEffect(() => {
    if (isInfluencer) {
      localStorage.setItem("influencer_freeclasses", isInfluencer);

      if (!IS_DEV_ENV) {
        logEvent(analytics, googleInfluencerFreeClass, {
          details: isInfluencer,
        });
      }
    }
  }, [isInfluencer]);

  const getData = useCallback(() => {
    action.fetchPaginatedFreeClasses({ ...initialOptions });
  }, []);

  useEffect(() => getData(), []);

  const paginationHandler = (page, pageSize) => {
    window.scrollTo(0, 0);
    const queryObj = {
      ...initialOptions,
      page: Number(page),
      limit: Number(pageSize),
    };
    if (pageSize) {
      queryObj.page = Number(page);
      queryObj.limit = Number(pageSize);
    }
    action.fetchPaginatedFreeClasses({ ...queryObj });
  };

  return (
    <div>
      <Header />
      {freeClassesData && (
        <div className="app-container mt-50 mb-50">
          <div>
            <p className={[styles.heading, "f-xl"].join(" ")}>
              {"Free Classes"}
            </p>
            {freeClassesData?.loading ? (
              <div className={styles.loader_div}>
                <AppLoader className="font-28" />
              </div>
            ) : (
              <Fragment>
                <Row>
                  {freeClassesData?.freeClasses &&
                  freeClassesData?.freeClasses?.length !== 0 ? (
                    <Fragment>
                      {freeClassesData?.freeClasses?.map((item) => (
                        <Col
                          key={item?.id}
                          xxl={6}
                          xl={6}
                          lg={8}
                          md={12}
                          sm={12}
                          xs={24}
                        >
                          <FreeClassCard item={item} />
                        </Col>
                      ))}
                    </Fragment>
                  ) : (
                    <div className="w-100">
                      <h2 className="f-sm text-align-center pt-50 mt-50 mb-50">
                        No result was found.
                      </h2>
                    </div>
                  )}
                </Row>
                <div className="d-row justify-content-center mt-20 pagination-card">
                  <Pagination
                    hideOnSinglePage
                    total={freeClassesData?.total}
                    current={freeClassesData?.currentPage}
                    pageSize={freeClassesData?.perPage}
                    onChange={paginationHandler}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default FreeClasses;
