import React, { useState, useMemo, useCallback } from "react";
import { Modal, Row, Collapse, Button } from "antd";
import Text from "src/modules/parent/components/text";
import chevronUp from "../../assets/svg/chevron-up.svg";
import RadioGroupButton from "src/modules/parent/components/radio-group-button";
import chevronDown from "../../assets/svg/chevron-down.svg";
import { priceList, daysList, timeList, classTypeList } from "../../staticData";
import styles from "../../styles.module.css";

// Memoized Title Component
const Title = React.memo(({ text, selected, disabled, onClear }) => (
  <div className={!disabled ? styles.filterTitle : styles.icon}>
    <Row justify={"space-between"}>
      <Text
        size="M2"
        font="SEMIBOLD"
        color="rgba(26, 26, 26, 1)"
        title={text}
      />

      {selected && (
        <div>
          <div
            onClick={(e) => {
              e.preventDefault();
              onClear();
              e.stopPropagation();
            }}
            className={styles.filterCloseIcon}
          >
            {"X"}
          </div>
          <div className={styles.filterCountMobile}>{"1"} </div>
        </div>
      )}
    </Row>
  </div>
));

// Memoized Expand Icon
const ExpandIcon = React.memo(({ isActive, panelKey }) => (
  <img
    src={isActive ? chevronUp : chevronDown}
    className={
      panelKey !== "4" ? styles.iconStyleChevron : styles.iconStyleChevronActive
    }
    alt="Icon"
  />
));

// Utility Function
const getValue = (key, value) => key[value];

const FilterModalComponent = ({
  show,
  selectedItem,
  setShow,
  onHandleChange,
  handleApplyFilter,
  onClear,
}) => {
  const [activeKey, setActiveKey] = useState("1");

  // Memoized Items Array
  const items = useMemo(() => {
    const createItem = (
      key,
      labelText,
      dataKey,
      options,
      disabled = false
    ) => ({
      key,
      label: (
        <Title
          text={labelText}
          selected={selectedItem[dataKey]}
          disabled={disabled}
          onClear={() => onClear(dataKey)}
        />
      ),
      children: (
        <RadioGroupButton
          value={getValue(selectedItem, dataKey)}
          onChange={(e) =>
            onHandleChange({ name: dataKey, key: e.target.value })
          }
          direction="vertical"
          options={options}
        />
      ),
    });

    return [
      createItem("1", "Price", "price", priceList),
      createItem("2", "Days", "days", daysList),
      createItem("3", "Time", "time_slot", timeList),
      createItem("4", "Class Type", "enrollment_mode", classTypeList, true),
    ];
  }, [selectedItem, onClear, onHandleChange]);

  // Handle Active Key Change
  const handleActiveKeyChange = useCallback(
    (keys) => setActiveKey(keys[1]),
    []
  );

  return (
    <Modal
      closable
      onCancel={() => setShow(false)}
      maskClosable
      centered
      footer={null}
      open={show}
    >
      <Row>
        <Text
          size="M2"
          font="SEMIBOLD"
          color="rgba(0, 94, 255, 1)"
          title="Additional Filters"
        />
      </Row>

      <div className="mt-30 mb-30">
        <Collapse
          activeKey={activeKey}
          ghost
          onChange={handleActiveKeyChange}
          expandIconPosition="end"
          expandIcon={(props) => (
            <ExpandIcon isActive={props.isActive} panelKey={props.panelKey} />
          )}
          items={items}
        />
      </div>

      <Button onClick={handleApplyFilter} className={styles.searchButton}>
        {"Apply filter"}
      </Button>
    </Modal>
  );
};

// Export with Memoization
export default React.memo(FilterModalComponent);
