import React from "react";

import Carousel from "react-multi-carousel";
import { Space } from "antd";
import { isMobile } from "react-device-detect";

import Button from "src/modules/parent/components/flat-button";
import Text from "src/modules/parent/components/text";
import { homeCarousel } from "src/modules/parent/utils";

import styles from "../../styles.module.css";
import {
  trackEvents,
  whatsNewSectionButton,
} from "src/modules/parent/utils/events/query";

const WhatsNew = ({
  data = [],
  isLoading = false,
  handleVideoClick = () => {},
}) => (
  <div className={isMobile ? " mb-10" : "white_box h-100"}>
    <Text
      className="m-20"
      color="rgba(0, 94, 255, 1)"
      size="XL"
      font="SEMIBOLD"
    >
      {"What’s New"}
    </Text>

    {!isLoading && data && data.length > 0 && (
      <Carousel
        infinite
        autoPlay
        autoPlaySpeed={3000}
        responsive={homeCarousel}
      >
        {data?.map((element) => (
          <div key={element?.id} className={styles.what_is_new_container}>
            {element.type === "image" && (
              <img
                src={isMobile ? element.media_mobile : element?.media}
                width={"100%"}
                height={"100%"}
                alt="Banner"
              />
            )}

            {element.type === "video" && (
              <img
                src={
                  isMobile ? element?.thumbnail_mobile : element?.thumbnail_web
                }
                width={"100%"}
                height={"100%"}
                className={"cursor-pointer"}
                onClick={() => handleVideoClick(element?.media)}
                alt="Banner"
              />
            )}

            {element.title && (
              <div className={styles.footWrapperImage}>
                <Space direction={"vertical"} size={"middle"}>
                  {element.title && (
                    <Text color={"#FFFFFF"} font="SEMIBOLD" size="M">
                      {element?.title}
                    </Text>
                  )}

                  {element.button_text && (
                    <Button
                      type={"Tertiary"}
                      height={30}
                      onClick={() => {
                        if (element?.redirect_url) {
                          window.open(element?.redirect_url, "_blank");
                          trackEvents(whatsNewSectionButton);
                        }
                      }}
                      className={styles.exploreBtn}
                      title={element?.button_text}
                    />
                  )}
                </Space>
              </div>
            )}
          </div>
        ))}
      </Carousel>
    )}
  </div>
);

export default React.memo(WhatsNew);
