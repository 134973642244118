import React, { Fragment } from "react";
import { Row, Col, DatePicker } from "antd";
import moment from "moment-timezone";
import { Formik, FieldArray } from "formik";

import TextInput from "src/modules/parent/components/text-input";
import Text from "src/modules/parent/components/text";
import SelectInput from "src/modules/parent/components/select-input";
import { studentRegistrationSchema } from "../../../schema";
import FlatButton from "src/modules/parent/components/flat-button";
import { genderData, triviaGrades } from "src/modules/parent/utils/static-data";
import plusIcon from "../../../assets/svgs/plus";
import styles from "./styles.module.css";
import dayjs from "dayjs";

const defaultStudentData = {
  name: "",
  gender: undefined,
  dob: "",
  level: undefined,
};

const AddChildComponent = ({
  formikRef,
  initialValues,
  removedValue,
  handleValidate = () => {},
  handleSubmit = () => {},
}) => (
  <Formik
    innerRef={formikRef}
    initialValues={initialValues}
    validate={handleValidate}
    validateOnChange
    validateOnBlur
    validateOnMount
    validationSchema={studentRegistrationSchema}
    onSubmit={handleSubmit}
  >
    {({ values, errors, touched, handleChange, setFieldValue }) => (
      <FieldArray name="students">
        {({ push, remove }) => (
          <Fragment>
            {values.students.map((student, index) => (
              <Row key={index} className={"mt-20 mb-20"} gutter={[20, 20]}>
                <Col span={24}>
                  <Row className="w-100" justify={"space-between"}>
                    <Text
                      size={"M"}
                      lHeight={"S"}
                      font={"SEMIBOLD"}
                      color={"rgba(39, 39, 42, 1)"}
                      title={`Student ${index + 1}`}
                    />

                    {values.students.length > removedValue && (
                      <FlatButton
                        title={"Remove"}
                        type={"Secondary"}
                        onClick={() => remove(index)}
                      />
                    )}
                  </Row>
                </Col>

                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Text
                    size={"S"}
                    lHeight={"M"}
                    color={"rgba(39, 39, 42, 1)"}
                    title={"Student Name"}
                  />
                  <TextInput
                    required={true}
                    allowClear={true}
                    placeholder={"John Doe"}
                    value={student.name}
                    error={
                      errors.students?.[index]?.name &&
                      touched.students?.[index]?.name
                        ? errors.students[index].name
                        : ""
                    }
                    onChange={handleChange(`students.${index}.name`)}
                  />
                </Col>

                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Text
                    size={"S"}
                    lHeight={"M"}
                    color={"rgba(39, 39, 42, 1)"}
                    title={"Gender"}
                  />
                  <SelectInput
                    required
                    allowClear
                    className={"w-100 h-48"}
                    placeholder={"Male/Female"}
                    error={
                      errors.students?.[index]?.gender &&
                      touched.students?.[index]?.gender
                        ? errors.students[index].gender
                        : ""
                    }
                    value={student.gender}
                    onChange={(value) =>
                      setFieldValue(`students.${index}.gender`, value)
                    }
                    options={genderData}
                  />
                </Col>

                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Text
                    size={"S"}
                    lHeight={"M"}
                    color={"rgba(39, 39, 42, 1)"}
                    title={"Student’s DOB"}
                  />

                  <DatePicker
                    required
                    className={styles.dob}
                    format={"YYYY-MM-DD"}
                    showNow={false}
                    placeholder={"2012-06-05"}
                    defaultPickerValue={dayjs("2010-01-01")}
                    value={student.dob ? moment(student.dob) : null}
                    onChange={(date, dateString) =>
                      setFieldValue(`students.${index}.dob`, dateString)
                    }
                    disabledDate={(current) => {
                      const startDate = moment("2010-01-01");
                      const endDate = moment("2017-12-31");
                      return (
                        current &&
                        (current.isBefore(startDate, "day") ||
                          current.isAfter(endDate, "day"))
                      );
                    }}
                  />

                  {errors.students?.[index]?.dob &&
                    touched.students?.[index]?.dob && (
                      <Text className="mt-8" color="RED" size="XS">
                        {errors.students[index].dob}
                      </Text>
                    )}
                </Col>

                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Text
                    size={"S"}
                    lHeight={"M"}
                    color={"rgba(39, 39, 42, 1)"}
                    title={"Student’s Grade level"}
                  />
                  <SelectInput
                    required
                    allowClear
                    placeholder={"Grade 7"}
                    className={"w-100 h-48"}
                    error={
                      errors.students?.[index]?.level &&
                      touched.students?.[index]?.level
                        ? errors.students[index].level
                        : ""
                    }
                    value={student.level}
                    onChange={(value) =>
                      setFieldValue(`students.${index}.level`, value)
                    }
                    options={triviaGrades}
                  />
                </Col>
              </Row>
            ))}

            {values.students.length < 3 && (
              <Row justify={"end"} className={"mb-20"}>
                <FlatButton
                  preIcon={plusIcon}
                  title={"Add more"}
                  type={"Secondary"}
                  width={"120px"}
                  height={"40px"}
                  onClick={() => push(defaultStudentData)}
                />
              </Row>
            )}
          </Fragment>
        )}
      </FieldArray>
    )}
  </Formik>
);

export default AddChildComponent;
