import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import ChildDetails from "../../components/child-details";

const EditChild = () => {
  const { childId } = useParams();
  const child = useSelector((state) => state.profileReducer.children.find((e) => e.id === Number(childId)));

  return (
    <div>
      <Header />
      <div className="inner-container">
        <ChildDetails edit={true} child={child} />
      </div>
      <Footer />
    </div>
  );
};

export default EditChild;
