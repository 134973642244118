import React, { useState } from "react";

import { Row } from "antd";
import moment from "moment-timezone";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import closeIcon from "../../assets/svgs/chatClose.svg";

import styles from "./styles.module.css";

const contacts = [
  {
    id: "0",
    label: "Contact an Academic Counselor",
    contact: "Academic Counselor - +923474000862",
  },
  {
    id: "1",
    label: "Contact our Finance Team",
    contact: "Finance - +923474106202",
  },
];

const ModalWindow = ({ show, setShow }) => {
  const [contactNumber, setContactNumber] = useState("");
  const handleClick = (data) => {
    setContactNumber(data.contact);
  };

  return (
    <div className={styles.modalWindow} style={{ opacity: show ? "1" : "0" }}>
      <Row justify={"space-between"} align={"middle"} className={styles.header}>
        {!isMobile ? (
          <Text color="rgba(0, 0, 0, 1)" size="M" font="SEMIBOLD">
            {"Dot and Line Learning"}
          </Text>
        ) : (
          <div></div>
        )}

        <img
          className={styles.closeIcon}
          onClick={() => setShow(false)}
          src={closeIcon}
          alt="closeIcon"
        />
      </Row>

      <div className={styles.scroller}>
        <Row justify={"center"} className="mt-10">
          <Text color="rgba(150, 150, 150, 1)" size="XS">
            {moment().format("hh:mm A")}
          </Text>
        </Row>

        <div className={styles.leftDiv}>
          <div>
            <Text color="rgba(255, 255, 255, 1)" lHeight={"S"} size="S">
              {"Hello Parents and Students! How may we help you today?"}
            </Text>
          </div>
        </div>

        {contacts.map((item) => (
          <div
            onClick={() => handleClick(item)}
            key={item.id}
            className={styles.rightDiv}
          >
            <div>
              <Text
                color="rgba(0, 0, 0, 1)"
                lHeight={"S"}
                font={"SEMIBOLD"}
                size="S"
              >
                {item.label}
              </Text>
            </div>
          </div>
        ))}

        {contactNumber && (
          <div className={styles.leftDiv}>
            <div>
              <Text color="rgba(255, 255, 255, 1)" lHeight={"S"} size="S">
                {contactNumber}
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalWindow;
