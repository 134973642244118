import React from "react";

import { isMobile } from "react-device-detect";

import styles from "./styles.module.css";

const GetStats = ({ image, text, description }) => (
  <div
    className={styles.stats}
    style={{ width: isMobile ? "100%" : "", height: isMobile ? "200px" : "" }}
  >
    <img src={image} alt="environment" />

    <div className="font-weight-600 font-14">{text}</div>

    <div className={styles.hrow} />

    <p>{description}</p>
  </div>
);

export default React.memo(GetStats);
