import { combineReducers } from "redux";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import packageReducer from "./packageReducer";
import subjectReducer from "./subjectReducer";
import teacherReducer from "./teacherReducer";
import generalReducer from "./generalReducer";
import notificationReducer from "./notificationReducer";
import chatReducer from "./chatReducer";
import learningReducer from "./learningReducer";

export default combineReducers({
  notificationReducer,
  authReducer,
  profileReducer,
  packageReducer,
  subjectReducer,
  teacherReducer,
  generalReducer,
  chatReducer,
  learningReducer,
});
