import axios from "axios";
import { store } from "../store";
import { CHAT_APP_API } from "./constant";

const axiosInstance = axios.create({
  baseURL: CHAT_APP_API,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth?.user?.token;

    if (token) {
      config.headers.Accept = "application/json";
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Accept = "application/json";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//This allows you to intercept the response and check the status and error messages and if ncessary reject the promise.
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error);
    }

    return error;
  }
);

export default axiosInstance;
