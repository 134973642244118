import React from "react";

import { Row, Space } from "antd";

import { isMobile } from "react-device-detect";
import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const WhatWeDo = () => (
  <div className={"app-container pt-50 pb-30"}>
    <Row justify={"center"}>
      <Text
        size={"XXL2"}
        font={"BOLD"}
        lHeight={"S"}
        color={"#2F79E6"}
        title={"What We Do"}
      />
    </Row>

    <div className={styles.testPlan}>
      <Space size={"large"} direction={"vertical"}>
        <Text
          size={"XL"}
          font={"SEMIBOLD"}
          lHeight={"S"}
          color={"#29292A"}
          title={"NAPLAN Test Prep"}
        />

        {!isMobile && (
          <Text
            size={"M"}
            lHeight={"S"}
            color={"#404040"}
            title={
              "The NAPLAN assessment, held in years 3, 5, 7, and 9, offers a glimpse into your child's academic standing among peers and can guide decisions regarding entry into OC classes, selective schools, or accelerated programs in high school."
            }
          />
        )}

        <Text
          size={"S"}
          lHeight={"S"}
          font={"LIGHTER"}
          color={"#404040"}
          title={
            "Dot & Line tutors have high success rates, doubling as motivational coaches. Our approach involves familiarizing students with the test format, strategies to tackle difficult questions - boosting confidence for exam day."
          }
        />
      </Space>
    </div>
  </div>
);

export default React.memo(WhatWeDo);
