import React from "react";

import { CountUp } from "use-count-up";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";

import styles from "../../styles.module.css";

const duration = 5;

const ProgressTracker = ({
  image,
  title,
  getValue,
  counting,
  handleClick = () => {},
}) => (
  <div
    onClick={() => handleClick(title)}
    className={
      isMobile
        ? "white_box mt-20 px-20 py-19 mr-15 cursor-pointer"
        : "white_box px-20 py-15 mt-20 cursor-pointer"
    }
  >
    <div className="d-row">
      <img src={image} className={styles.total_packages_icon} alt="" />

      <div className="ml-12">
        <Text font="SEMIBOLD" size="XXL2">
          {counting ? (
            <CountUp duration={duration} isCounting end={getValue()} />
          ) : (
            "0"
          )}
        </Text>

        <Text font="LIGHTER" size="M2" className="mt-5">
          {title}
        </Text>
      </div>
    </div>
  </div>
);

export default React.memo(ProgressTracker);
