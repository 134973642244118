import React, { Suspense, useState } from "react";
import styles from "./styles.module.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import triviaBannerBG from "src/modules/parent/assets/img/triviaBannerBG.png";
import triviaMobileBannerBG from "src/modules/parent/assets/img/triviaMobileBannerBG.png";
import TriviaPartner from "src/modules/parent/assets/img/Trivia Partner.png";
import TriviaPartnerMobile from "src/modules/parent/assets/img/TriviaPartnerMobile.png";
import { Collapse } from "antd";
import Text from "../../components/text";
import { Button, Col, Row, Space } from "antd";
import { isBrowser, isMobile } from "react-device-detect";
import TriviaHerosectionText from "./components/triviaHerosection-text";
import SuccessModal from "src/modules/parent/components/trivia-registration-form/components/modal/success";
import TriviaRegistrationForm from "src/modules/parent/components/trivia-registration-form";
import JWVideoPlayer from "../../components/jw-video-player";
import {
  aboutData,
  faqsCardData,
  roundDetailImg,
  triviaReviewData,
  TrophyImg,
  wtDotnlinData,
} from "../find-a-tutor/staticData";
import { Review } from "../international/components";
import Slider from "react-slick";
import AppLoader from "src/modules/parent/components/app-loader";
import {
  crouselSettings,
  reviewSettings,
  checkIsAuthenticated,
} from "../../utils";
import actionTypes from "../../store/action-types";
import { useDispatch } from "react-redux";
import ContactModal from "../../components/modal/contactModal";
import { FloatingButton } from "../olevels/components";
import { epContactBtnWhatsapp, trackEvents } from "../../utils/events/query";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const LazyGetStats = React.lazy(() => import("./components/get-stats"));
const whatsappMobile = "https://wa.me/";

const Trivia = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formVisible, setFormVisible] = useState(false);

  const openModal = () => {
    dispatch({ type: actionTypes.ASSIST_ME_MODAL, payload: true });
  };

  const handleWhatsApp = () => {
    window.open(`${whatsappMobile}923474000862`);
  };

  const handleClickContact = useCallback(() => {
    trackEvents(epContactBtnWhatsapp);

    if (!isBrowser) handleWhatsApp();
    else setShowContactModal(true);
  }, []);

  const handleShowForm = () => {
    setFormVisible(true);
  };

  const handleSuccess = () => {
    setShowSuccessModal(false);

    navigate("/");
  };

  return (
    <div>
      <Header />
      {/* MAIN BANNER HEROSECTION */}
      <div id="he" className={styles.main_banner}>
        <div className={styles.textMobile}>
          <img
            className={styles.img1}
            src={isBrowser ? triviaBannerBG : triviaMobileBannerBG}
            alt="Banner"
          />
        </div>

        <div className={styles.main_banner_content}>
          <div className={styles.texts}>
            <TriviaHerosectionText handleClick={handleShowForm} />
          </div>
        </div>
      </div>
      {/* MAIN BANNER HEROSECTION */}
      {/* WHAT IS TRIVIA COMP */}
      <div
        className={`app-container position-relative ${
          isBrowser ? "mt-50 mb-50" : "mt-20 mb-20"
        }`}
      >
        <Row justify={"center"}>
          <Text
            customStyles={{ width: "100%" }}
            size={"XXL"}
            font={"SEMIBOLD"}
            color={"#2C2C2C"}
            title={`${
              isBrowser ? "What" : "When"
            } is the Dot & Line Trivia Competition?`}
            center
          />

          {isBrowser ? (
            <Text
              customStyles={{ maxWidth: "1035px" }}
              size={"S"}
              className={"mt-20 mb-20"}
              font={"LIGHTER"}
              lHeight={"S"}
              title={
                "Pakistan’s leading online learning platform, in collaboration with Bachaa Party, is bringing its 4th online Trivia Competition for students in Grade 3 - 8 globally! Participating in the online trivia will ignite your child's curiosity and cultivate essential 21st-century STEM skills!"
              }
              center
            />
          ) : (
            <div className={styles.RDDetails}>
              <p>
                Qualifying Round: <span>Saturday, 12th April 2025</span>
              </p>
              <p>
                Final Round: <span>Sunday, 13th April 2025</span>
              </p>
            </div>
          )}
        </Row>
        {isMobile && (
          <Slider {...reviewSettings}>
            {roundDetailImg.map((item, index) => (
              <Col key={index}>
                <img
                  className={styles.RDimgSlider}
                  src={item.src}
                  alt={item.alt}
                />
              </Col>
            ))}
          </Slider>
        )}
        <Row className={isBrowser ? "" : "mt-15"} justify={"center"}>
          <Button onClick={handleShowForm} className={styles.searchButton}>
            {"Register Now for Free"}
          </Button>
        </Row>
      </div>
      {/* WHAT IS TRIVIA COMP */}
      {/* TRIVIA PARTNER BANNER */}
      <div className={styles.main_banner}>
        <img
          className={styles.img1}
          src={isBrowser ? TriviaPartner : TriviaPartnerMobile}
          alt="Banner"
        />
      </div>
      {/* TRIVIA PARTNER BANNER */}
      {/* ROUND DETAILS */}
      {isBrowser && (
        <div className="app-container mb-50 mt-50">
          <Row justify={"center"}>
            <Text
              size={"XXL"}
              font={"SEMIBOLD"}
              color={"#2C2C2C"}
              title={"Round Details"}
              center
            />
            <div className={styles.RDDetails}>
              <p>
                Qualifying Round: <span>Saturday, 12th April 2025</span>
              </p>
              <p>
                Final Round: <span>Sunday, 13th April 2025</span>
              </p>
              <p>
                Only the <span>top 10 participants</span> from each age category
                will advance to the final round
              </p>
            </div>
          </Row>
          <Row justify={"center"} gutter={32}>
            {roundDetailImg.map((item, index) => (
              <Col key={index}>
                <img className={styles.RDimg} src={item.src} alt={item.alt} />
              </Col>
            ))}
          </Row>
          <Row justify={"center"}>
            <Button
              onClick={handleShowForm}
              className={`${styles.searchButton} mt-30`}
            >
              {"Register Now for Free"}
            </Button>
          </Row>
        </div>
      )}
      {/* ROUND DETAILS */}
      {/* WINNING PRIZE */}
      <div className="app-container mb-20 mt-20">
        <Row justify={"center"}>
          <Text
            className={isBrowser ? "" : "mb-20"}
            size={"XXL2"}
            font={"SEMIBOLD"}
            color={"#2C2C2C"}
            title={"Win Cash Prizes and Gifts!"}
            center
          />
        </Row>
        <Row justify={"center"} gutter={isBrowser ? 32 : 16}>
          {TrophyImg.map((item, index) => (
            <Col key={index}>
              <img
                className={
                  index !== 1 ? styles.RDTrophyimg : styles.middleRDTrophyimg
                }
                src={item.src}
                alt={item.alt}
              />
              <Text
                className={"mt-5"}
                size={"XXL"}
                font={"SEMIBOLD"}
                color={"#2C2C2C"}
                title={item.price}
                center
              />
            </Col>
          ))}
        </Row>
      </div>
      {/* WINNING PRIZE */}
      {/* VIDEO SECTION */}
      <div className={`app-container ${isBrowser ? "mb-50" : ""}`}>
        <Row gutter={[20, 20]} className={"mt-20 mb-20"} justify={"center"}>
          <div style={{ width: "885px" }}>
            <Col span={24}>
              <JWVideoPlayer
                height={window?.innerWidth < 991 ? 250 : 495}
                url={"dg7KMAvUoAA"}
              />
            </Col>
          </div>
        </Row>
      </div>
      {/* VIDEO SECTION */}
      {/* REVIEWS */}
      <Review
        isHeading={false}
        isTrivia={true}
        reviewsData={triviaReviewData}
      />
      <Row justify={"center"}>
        <Button
          onClick={handleShowForm}
          className={`${styles.searchButton} mb-30`}
        >
          {"Register Now for Free"}
        </Button>
      </Row>
      {/* REVIEWS */}
      {/* WHAT IS DOT AND LINE */}
      <div className="app-container mb-50">
        <Row justify={"center"}>
          <Text
            className={isBrowser ? "mb-20" : ""}
            size={"XXL2"}
            font={"SEMIBOLD"}
            color={"#2C2C2C"}
            title={"What is Dot & Line?"}
            center
          />
        </Row>
        {!isMobile && (
          <div className="app-container mt-20">
            <Row gutter={[20, 20]} className={styles.aboutCenter}>
              {wtDotnlinData.map((item) => (
                <Col
                  key={item.id}
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <div className={styles.tabs}>
                    <Row justify={isBrowser ? "center" : "start"}>
                      <Space size={"middle"} direction={"horizontal"}>
                        <img src={item.icon} alt={"checkmark"} />

                        <Text
                          size={"M2"}
                          color={item.color}
                          font={"SEMIBOLD"}
                          title={item.title}
                        />
                      </Space>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
        <Row gutter={[20, 20]} className={styles.appStats} justify={"center"}>
          {isMobile ? (
            <Suspense fallback={<AppLoader className="font-28" />}>
              <div className="app-container">
                <Slider {...crouselSettings}>
                  {aboutData.map((item, index) => (
                    <Col className="mb-5 mt-5" key={item.id}>
                      <LazyGetStats
                        image={item.image}
                        text={item.text}
                        description={item.description}
                      />
                    </Col>
                  ))}
                </Slider>
              </div>
            </Suspense>
          ) : (
            <Suspense fallback={<AppLoader className="font-28" />}>
              <Row justify={"center"} gutter={[30, 20]}>
                {aboutData.map((item, index) => (
                  <Col key={item.id}>
                    <LazyGetStats
                      image={item.image}
                      text={item.text}
                      description={item.description}
                    />
                  </Col>
                ))}
              </Row>
            </Suspense>
          )}
        </Row>
        <Row justify={"center"}>
          <Button
            onClick={() => openModal()}
            className={`${styles.bookDemoButton} ${isBrowser ? "mb-30" : ""}`}
          >
            {"Book a Free Demo Now!"}
          </Button>
        </Row>
      </div>
      {/* WHAT IS DOT AND LINE */}
      {/* FAQS */}
      {isBrowser ? (
        <div
          className="app-container mb-50"
          style={{ width: "calc(100% - 500px)" }}
        >
          <Row justify={"center"}>
            <Text
              className={"mb-20"}
              size={"XXL2"}
              font={"SEMIBOLD"}
              color={"#2C2C2C"}
              title={"FAQs"}
              center
            />
          </Row>
          <Row justify={"center"} gutter={16}>
            {faqsCardData.map((item, index) => (
              <Col key={index} span={8} style={{ margin: "12px 0" }}>
                <img className="mb-10" src={item.icon} alt={"Card Icon"} />
                <Text
                  className={"mb-10"}
                  customStyles={{ maxWidth: "13rem" }}
                  size={"M2"}
                  font={"NORMAL"}
                  lHeight={"XS"}
                  color={"#53686A"}
                  title={item.question}
                />
                <Text
                  className={"mb-10"}
                  customStyles={{ maxWidth: "13rem", wordBreak: "break-word" }}
                  size={"S"}
                  font={"LIGHTER"}
                  lHeight={"XS"}
                  color={"#53686A"}
                  title={item.answer}
                />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div className="app-container">
          <div className={"faqs"}>
            <div className="mt-50 mb-50">
              <Collapse defaultActiveKey={["1"]}>
                <p className={[styles.heading1, "pb-20"].join(" ")}>
                  Support - FAQs
                </p>
                {faqsCardData.map((item, index) => (
                  <Collapse.Panel
                    key={index}
                    header={
                      <span className={styles.heading3}>{`${index + 1}. ${
                        item.question
                      }`}</span>
                    }
                  >
                    <p className={styles.paragraph}>{item.answer}</p>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          </div>
        </div>
      )}
      {/* FAQS */}

      {/* Trivia Registration Form */}
      {formVisible && (
        <TriviaRegistrationForm
          show={formVisible}
          setShow={setFormVisible}
          userAlreadyLoggedIn={checkIsAuthenticated()}
          onSuccess={() => setShowSuccessModal(true)}
        />
      )}

      {/* Trivia Successfull Registration */}
      <SuccessModal
        show={showSuccessModal}
        onSuccess={handleSuccess}
        setShow={setShowSuccessModal}
      />

      {/* WHATSAPP FLOATING BUTTON */}
      <FloatingButton handlePress={handleClickContact} />

      <ContactModal
        show={showContactModal}
        title={"Contact Representative"}
        name={"Student Success:"}
        color={"#005EFF"}
        number={"+923474000862"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal(false)}
      />
      {/* WHATSAPP FLOATING BUTTON */}

      <Footer />
    </div>
  );
};

export default Trivia;
