import React from "react";

import { Space } from "antd";
import { isMobile } from "react-device-detect";

import FlatButton from "src/modules/parent/components/flat-button";
import bannerImgWeb from "../../assets/svgs/banner1_web.png";
import bannerImgMobile from "../../assets/svgs/banner1_mobile.png";

import styles from "./styles.module.css";

const BannerComponent = ({ handleClick = () => {} }) => {
  return (
    <div className={styles.imageContainer}>
      {isMobile ? (
        <div>
          <img
            src={bannerImgMobile}
            alt={"bannerImage"}
            className={styles.image}
          />

          <div className={styles.applyNowOverlay}>
            <FlatButton
              title={"Apply Now!"}
              width={"160px"}
              height={"40px"}
              onClick={handleClick}
              className={styles.applyNowBtn}
            />
          </div>
        </div>
      ) : (
        <img src={bannerImgWeb} alt={"bannerImage"} className={styles.image} />
      )}

      {!isMobile && (
        <div className={styles.textOverlay}>
          <Space size={"large"} direction={"vertical"}>
            <div className={styles.title}>{"Together, Women Can."}</div>

            <div className={styles.description}>
              {"Become a Teacher Partner with Dot & Line!"}
            </div>

            <div>
              <FlatButton
                title={"Apply Now!"}
                width={"160px"}
                height={"40px"}
                onClick={handleClick}
                className={styles.applyNowBtn}
              />
            </div>
          </Space>
        </div>
      )}
    </div>
  );
};

export default BannerComponent;
