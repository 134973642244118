import React from "react";

import Carousel from "react-multi-carousel";

import { ChildCard } from "../../components";
import Text from "src/modules/parent/components/text";
import { NoChildCard } from "../../components";
import { responsiveChildCard } from "src/modules/parent/utils";
import { isMobile } from "react-device-detect";

const MyChildrens = ({
  data = [],
  loading,
  handleSelect,
  selectedChild,
  onHandleAdd = () => {},
}) => (
  <div className={isMobile ? "mt-20" : "white_box mb-20 p-20"}>
    <Text
      color="rgba(0, 94, 255, 1)"
      size="XL"
      font="SEMIBOLD"
      className="mb-20"
    >
      {"My Children"}
    </Text>

    <Carousel responsive={responsiveChildCard}>
      {data && data.length > 0 ? (
        data.map((item) => (
          <ChildCard
            {...item}
            key={item.id}
            handleSelect={handleSelect}
            isLoading={loading}
            selected={selectedChild === item.id}
          />
        ))
      ) : (
        <NoChildCard handleClick={onHandleAdd} />
      )}
    </Carousel>
  </div>
);

export default React.memo(MyChildrens);
