import React, { useState } from "react";
import styles from "./../styles.module.css";
import FlatButton from "src/modules/parent/components/flat-button";
import ModalComponent from "src/modules/parent/components/modal";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { avatarList, capitilizeFirstLetter } from "src/modules/parent/utils";
import noImage from "./../../../assets/img/anonymous.jpeg";
import moment from "moment-timezone";
import { IMAGE_BASE_URL } from "src/modules/parent/config/constant";
import DeleteChildModal from "../delete-child-modal";

const ChildrenListItem = ({ child }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.children_list_item}>
      <Row gutter={[20, 20]}>
        <Col xxl={8} xl={12} lg={24} md={24} sm={24} xs={24}>
          <div className="d-row">
            <div className={styles.avatar_div}>
              <img
                className={styles.avatar}
                src={
                  child?.avatar
                    ? avatarList[child?.avatar]?.image
                    : child?.image
                    ? `${IMAGE_BASE_URL}${child?.image}`
                    : noImage
                }
                alt="Avatar"
              />
            </div>
            <div className="d-row justify-content-center align-items-center ml-15">
              <p className={"f-lg font-weight-500-imp mb-0"}>
                {capitilizeFirstLetter(child?.name)}
              </p>
            </div>
          </div>
        </Col>
        <Col
          xxl={16}
          xl={12}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className="d-flex-imp justify-content-between align-items-center"
        >
          <div className="d-row">
            <FlatButton
              className="mr-20"
              title={`Age ${moment().diff(child?.dob, "years")}`}
            />
            <FlatButton title={child?.level || ""} />
          </div>
          <div className="d-row">
            <FlatButton
              onClick={() => navigate(`/child/edit/${child?.id}`)}
              type={"Secondary"}
              title={"Edit"}
            />
            <FlatButton onClick={() => setShowModal(true)} type={"Tertiary"} className="mr-10" title={"Delete"} />
            
          </div>
        </Col>
      </Row>
      <ModalComponent
        closable={false}
        maskClosable={false}
        isModalVisible={showModal}
        onCancel={() => setShowModal(false)}
        Component={<DeleteChildModal setShowModal={setShowModal} child={child} />}
        bodyClassName={"d-flex justify-content-center align-items-center"}
      />
    </div>
  );
};

export default ChildrenListItem;
