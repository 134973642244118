import React from "react";

import { Button, Col, Row, Space } from "antd";
import { olevelGrades } from "src/modules/parent/utils/static-data";
import SelectInput from "src/modules/parent/components/select-input";
import Text from "src/modules/parent/components/text";

import styles from "../styles.module.css";

const StepNo1 = ({
  board,
  grade,
  setGrade,
  isLoading = false,
  handlePrevious = () => {},
  handleNext = () => {},
}) => (
  <>
    <Row justify={"center"}>
      <Space align={"center"} direction={"vertical"}>
        <div className={styles.description}>
          {"Tell us the Grade level of your child in"}
        </div>

        <div className={styles.highlightedText}>{board}</div>
      </Space>
    </Row>

    <div className={"mt-30"}>
      <Text
        size={"L"}
        className={"mb-5"}
        color={"#373737"}
        title={"What grade is your child in?"}
      />

      <Col span={24}>
        <SelectInput
          allowClear
          className={"w-95"}
          value={grade}
          placeholder={"Select Grade"}
          onChange={setGrade}
          options={olevelGrades}
        />
      </Col>

      <Row justify={"center"}>
        <Space>
          <Button onClick={handlePrevious} className={styles.previousButton}>
            {"Previous"}
          </Button>

          <Button
            loading={isLoading}
            onClick={handleNext}
            className={styles.nextButton}
          >
            {"Next"}
          </Button>
        </Space>
      </Row>
    </div>
  </>
);

export default React.memo(StepNo1);
