import React, { Fragment, useEffect, useState } from "react";
import styles from "./styles.module.css";
import img1 from "src/modules/parent/assets/img/ignite/1.png";
import imgWeb from "src/modules/parent/assets/img/ignite/imgWeb.png";
import ignite_yellow from "src/modules/parent/assets/img/ignite/ignite_yellow.svg";
import { Button, Col, Row } from "antd";
import Header from "src/modules/parent/components/header";
import map from "src/modules/parent/assets/img/ignite/map.svg";
import { logEvent } from "firebase/analytics";
import star from "src/modules/parent/assets/img/ignite/star.svg";
import check_circle from "src/modules/parent/assets/img/ignite/check_circle.svg";
import ignite_diploma_2 from "src/modules/parent/assets/img/ignite/ignite_diploma_2.png";
import ignite_diploma_3 from "src/modules/parent/assets/img/ignite/ignite_diploma_3.png";
import swiper1 from "src/modules/parent/assets/img/ignite/swiper_lvl_1.webp";
import ContactModal from "src/modules/parent/components/modal/contactModal";
import swiper2 from "src/modules/parent/assets/img/ignite/swiper2.svg";
import ReactPixel from "react-facebook-pixel";
import swiper3 from "src/modules/parent/assets/img/ignite/swiper3.webp";
import JWVideoPlayer from "src/modules/parent/components/jw-video-player";
import { analytics } from "src/modules/parent/config/firebase";
import Footer from "../../components/footer";
import { openLinkInNewTab } from "../../utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { igniteTrainers } from "../../utils/static-data";
import "swiper/swiper-bundle.min.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import ramadanDiscountWeb from "src/modules/parent/assets/img/ignite/banner_web.png";
import ramadanDiscountMobile from "src/modules/parent/assets/img/ignite/banner_mobile.png";
import { fb_pixel_id_ignite, IS_DEV_ENV } from "../../config/constant";
import banner2 from "../../assets/img/ignite/banner2.png";
import banner1 from "../../assets/img/ignite/ig1.webp";
import { isBrowser } from "react-device-detect";
import { FloatingButton } from "src/modules/parent/pages/olevels/components";
import Text from "../../components/text";
import events from "../../utils/events";
import { igniteApplyNowBtn, trackEvents } from "../../utils/events/query";

const ignite_pdf_link =
  "https://storage.googleapis.com/dll-pub-store/IGNITE-diploma-timetable-cohort-5.pdf";

ReactPixel.init(fb_pixel_id_ignite);
const imageSource = isBrowser ? ramadanDiscountWeb : ramadanDiscountMobile;
const ignitePageLand = events.google.ignite.influencerPageView;
const whatsappMobile = "https://wa.me/";

const IgniteDiploma = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [showContactModal, setShowContactModal] = useState(false);
  const isInfluencer = params.get("influencer");

  useEffect(() => {
    if (isInfluencer) {
      localStorage.setItem("influencer_ignite", isInfluencer);

      if (!IS_DEV_ENV) {
        logEvent(analytics, ignitePageLand, {
          details: isInfluencer,
        });
      }
    }
  }, [isInfluencer]);

  const buttonStyles = {
    color: "#fff",
    border: "none",
    height: "40px",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "10px",
    backgroundColor: "#EA367F",
    padding: "0px 35px",
    minWidth: "190px",
  };

  const applyNowButton = () => {
    trackEvents(igniteApplyNowBtn);

    navigate("form");
  };

  const handleWhatsApp = () => {
    window.open(`${whatsappMobile}923460086799`);
  };

  const handleClickContact = () => {
    if (!isBrowser) handleWhatsApp();
    else setShowContactModal(true);
  };

  return (
    <>
      <Header />

      <div className={"ignite_landing_page"}>
        <div id="he" className={styles.main_banner}>
          <div className={styles.textMobile}>
            <img
              className={styles.img1}
              src={isBrowser ? imgWeb : img1}
              alt="Banner"
            />

            {!isBrowser && (
              <div className={styles.txt}>
                <Text
                  size={"XL"}
                  font={"SEMIBOLD"}
                  color={"#FFFFFF"}
                  title={"Teacher Training Diploma"}
                />

                <Button
                  className={"mt-10"}
                  onClick={applyNowButton}
                  style={{ ...buttonStyles, minWidth: "170px" }}
                >
                  Apply Now!
                </Button>
              </div>
            )}
          </div>

          <div className={styles.main_banner_content}>
            <div className={styles.texts}>
              <img
                src={ignite_yellow}
                alt=""
                className={styles.ignite_yellow}
              />
              <div className={styles.hidden_content}>
                <p className={styles.text1}>
                  Teacher Training{" "}
                  <span style={{ color: "#E7E224" }}>Diploma</span> by Dot &
                  Line
                </p>
                <p className={styles.text2}>
                  Invest in Yourself. Invest in IGNITE.
                </p>
                {/* <p className={styles.text1}>
                  Avail a{" "}
                  <span style={{ color: "#E7E224" }}>
                    {isLocal() ? "33%" : "10%"}
                  </span>{" "}
                  Early Bird Discount!
                </p> */}
                <Button
                  className={"mt-10"}
                  onClick={applyNowButton}
                  style={buttonStyles}
                >
                  Apply Now!
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#F5F5F5" }}>
          <div className="app-container pb-20 pt-20">
            <div
              className={[styles.section_row, styles.founders_row].join(" ")}
            >
              <div
                className={
                  "w-100 d-column justify-content-center align-items-center pt-25"
                }
              >
                <p
                  className="f-xl mb-15"
                  style={{ color: "#564CB1", textTransform: "uppercase" }}
                >
                  WELCOME TO IGNITE DIPLOMA!
                </p>
                <p className=" f-sm text-align-center">
                  {
                    "A comprehensive three-part teacher training by Dot & Line conducted online. This diploma is your ticket to a successful teaching career. Each of the three levels is a prerequisite to the next."
                  }
                </p>

                {isBrowser && (
                  <Button onClick={applyNowButton} style={buttonStyles}>
                    Apply Now!
                  </Button>
                )}
              </div>
              {isBrowser && (
                <div className={"w-100"}>
                  <img
                    src={imageSource}
                    className={styles.section2_img}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>

          {!isBrowser && (
            <>
              <div className={"w-100"}>
                <img src={imageSource} className={styles.section2_img} alt="" />

                <div className="w-100 justify-content-center text-align-center mt-20 pb-20 align-items-center">
                  <Button onClick={applyNowButton} style={buttonStyles}>
                    Apply Now!
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>

        <div className={styles.section1}>
          <div className="pt-20 pb-20">
            <div className="app-container">
              <p
                className={"f-xl mb-20"}
                style={{ color: "#564cb1", textTransform: "uppercase" }}
              >
                Who are we?
              </p>
              <p className={"f-sm mb-6"}>
                {isBrowser
                  ? "Dot & Line is a leading edtech company connecting teachers and students in over 12 countries. More than one thousand Dot and Line teachers have completed 500,000 classes and through the IGNITE Teacher Training Program, we share that proprietary experience with you!"
                  : "Dot & line is a leading edtech company connecting teachers and students in over 12 countries. Through this diploma, we share that proprietary experience with you!"}
              </p>
            </div>
            <div
              className={[
                "d-row justify-content-center mt-30",
                styles.container,
              ].join(" ")}
            >
              <div className={styles.slider}>
                <div className={styles.box}>
                  <img src={map} alt="" />
                  <p className={styles.box_text2}>
                    <span style={{ color: "#564CB1" }}>200+ trainees</span>
                    <br />
                    <span>Worldwide</span>
                  </p>
                  <p className={styles.box_text1}>
                    have graduated from <br /> IGNITE!
                  </p>
                </div>

                <div className={styles.box}>
                  <img src={star} alt="" />
                  <p className={styles.box_text2}>
                    <span style={{ color: "#564CB1" }}>98% trainees</span>
                    <br />
                    <div style={{ fontSize: "14px", fontWeight: "normal" }}>
                      feel more
                    </div>
                    <span>Confident</span>
                  </p>

                  <p className={styles.box_text1}>teaching online</p>
                </div>

                <div className={styles.box}>
                  <img src={check_circle} alt="" />
                  <p className={styles.box_text2}>
                    <span style={{ color: "#564CB1" }}>92% IGNITE</span>
                    <br />
                    <span>Trainees</span>
                  </p>

                  <p className={styles.box_text1}>
                    recommend the <br /> diploma to a friend
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          src={isBrowser ? banner2 : banner1}
          style={{ width: "100%", height: "auto" }}
          alt={"banner"}
        />

        <div className="pt-20 pb-20">
          <Swiper
            loop
            grabCursor
            navigation
            effect={"coverflow"}
            centeredSlides
            className={"my_swiper pb-15 my_swiper1"}
            autoplay={{ delay: 3000 }}
            modules={[EffectCoverflow, Autoplay, Navigation]}
            coverflowEffect={{
              rotate: 30,
              stretch: 10,
              depth: 200,
              slideShadows: true,
            }}
            breakpoints={{
              992: {
                slidesPerView: 2,
              },
              1: {
                slidesPerView: 1,
              },
            }}
          >
            {[0, 1].map((e) => (
              <Fragment key={e}>
                <SwiperSlide>
                  {<img alt="slider img" src={swiper1} />}
                </SwiperSlide>
                <SwiperSlide>
                  <img alt="slider img" src={swiper2} />
                </SwiperSlide>
                <SwiperSlide>
                  <img alt="slider img" src={swiper3} />
                </SwiperSlide>
              </Fragment>
            ))}
          </Swiper>
          <div className="d-flex justify-content-center mt-20 flex-wrap">
            <Button
              onClick={() => openLinkInNewTab(ignite_pdf_link)}
              className="ml-10 mr-10 mt-10"
              style={{
                ...buttonStyles,
                backgroundColor: "#fff",
                border: "2px solid #EA367F",
                color: "#EA367F",
              }}
            >
              {"View Timetable"}
            </Button>

            <Button
              className="ml-10 mr-10 mt-10"
              onClick={applyNowButton}
              style={buttonStyles}
            >
              Apply Now!
            </Button>
          </div>
        </div>

        <Row gutter={[20, 20]} className={"mt-20 mb-20"} justify={"center"}>
          <div style={{ width: isBrowser ? "70%" : "95%" }}>
            <Col span={24}>
              <JWVideoPlayer
                height={window?.innerWidth < 991 ? 250 : 450}
                url={"U_Q7n4ZjQ7o"}
              />
            </Col>
          </div>
        </Row>

        <div
          style={{ backgroundColor: "#F5F5F5" }}
          className="pt-20 pb-20 pl-10 pr-10"
        >
          <p
            className="f-xl"
            style={{
              color: "#564CB1",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            Meet Your Instructors!
          </p>
          <Swiper
            loop={true}
            grabCursor={false}
            navigation={true}
            effect={"coverflow"}
            centeredSlides={true}
            className={"my_swiper pb-15"}
            spaceBetween={20}
            autoplay={{ delay: 2500 }}
            modules={[Autoplay, Navigation]}
            breakpoints={{
              1600: {
                slidesPerView: 5,
              },
              1200: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 3,
              },
              499: {
                slidesPerView: 2,
              },
              1: {
                slidesPerView: 1,
              },
            }}
          >
            {igniteTrainers?.map((igniteTrainer, index) => (
              <SwiperSlide
                style={{
                  cursor: "text",
                  userSelect: "text",
                  pointerEvents: "all",
                }}
              >
                <div key={ignite_diploma_2?.id} className={styles.swiper_slie}>
                  <img
                    alt=""
                    src={igniteTrainer?.image}
                    style={{
                      width: "100%",
                      height: 300,
                      objectFit: "cover",
                      objectPosition: "top",
                      borderBottom: "1px solid #e2e0e0",
                    }}
                  />
                  <div className="text-align-center p-20">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        marginBottom: 10,
                      }}
                    >
                      {igniteTrainer?.name}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        marginBottom: 10,
                        color: "#5146AB",
                      }}
                    >
                      {igniteTrainer?.trainerLevel}
                    </p>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "300",
                        fontSize: "14px",
                        lineHeight: 1.5,
                      }}
                    >
                      {igniteTrainer?.detail}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}

            {igniteTrainers?.map((igniteTrainer, index) => (
              <SwiperSlide
                style={{
                  cursor: "text",
                  userSelect: "text",
                  pointerEvents: "all",
                }}
              >
                <div key={ignite_diploma_2?.id} className={styles.swiper_slie}>
                  <img
                    alt=""
                    src={igniteTrainer?.image}
                    style={{
                      width: "100%",
                      height: 300,
                      objectFit: "cover",
                      objectPosition: "top",
                      borderBottom: "1px solid #e2e0e0",
                    }}
                  />
                  <div className="text-align-center p-20">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        marginBottom: 10,
                      }}
                    >
                      {igniteTrainer?.name}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        marginBottom: 10,
                        color: "#5146AB",
                      }}
                    >
                      {igniteTrainer?.trainerLevel}
                    </p>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "300",
                        fontSize: "14px",
                        lineHeight: 1.5,
                      }}
                    >
                      {igniteTrainer?.detail}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div>
          <div className="app-container pt-20 pb-20">
            <div
              className={[styles.section_row, styles.founders_row].join(" ")}
            >
              <div className={"w-100 text-align-center"}>
                <img
                  src={ignite_diploma_2}
                  className={styles.section2_img}
                  alt=""
                />
              </div>
              <div
                className={
                  "w-100 d-column justify-content-center align-items-center"
                }
              >
                <p
                  className="f-xl mb-15 mt-20"
                  style={{ color: "#564CB1", textTransform: "uppercase" }}
                >
                  Get Certified
                </p>
                <p className="mb-20 f-sm text-align-center">
                  {isBrowser
                    ? "With an IGNITE training certification, you gain a competitive edge, access better job opportunities and salary advancements. By investing in professional training, you demonstrate your relevance, adaptability, and commitment to staying current in your field!"
                    : "With an IGNITE training certification, you gain a competitive edge and access salary advancements. We are proud to announce LUMSx as our partner in the IGNITE Diploma. Get LUMSx certified with IGNITE!"}
                </p>

                {isBrowser && (
                  <p className="mb-20 f-sm text-align-center">
                    {
                      "We are proud to announce LUMSx as our partner for Level 3 in the IGNITE Diploma this time around. Get LUMSx certified with IGNITE!"
                    }
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "#F5F5F5" }}>
          <div className="app-container pt-20 pb-20">
            <div
              className={[styles.section_row, styles.founders_row].join(" ")}
            >
              <div
                className={
                  "w-100 d-column justify-content-center align-items-center"
                }
              >
                <p
                  className="f-xl"
                  style={{ color: "#564CB1", textTransform: "uppercase" }}
                >
                  ADDED BONUS
                </p>
                <p className="mb-20 f-sm text-align-center">
                  {
                    "Trainees that complete the IGNITE Teacher Training Diploma will automatically be advanced/fast tracked to the final interview stage of becoming a Teacher Partner at Dot & Line. Together, let’s pave the way for a more promising future!"
                  }
                </p>
              </div>
              <div className={"w-100"}>
                {isBrowser ? (
                  <img
                    src={ignite_diploma_3}
                    className={styles.section2_img}
                    alt=""
                  />
                ) : (
                  <img
                    src={ignite_diploma_3}
                    width={"100%"}
                    height={"100%"}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ backgroundColor: "#F5F5F5" }}
          className="pt-20 pb-20 mt-20"
        >
          <div className="app-container">
            <p
              className="f-xl mb-35 text-align-center"
              style={{ color: "#564CB1" }}
            >
              Applicants will be taken on a first come first serve basis. <br />{" "}
              So, what are you waiting for?
            </p>
            <div className="d-flex justify-content-center">
              <Button onClick={applyNowButton} style={buttonStyles}>
                Apply Now!
              </Button>
            </div>
          </div>
        </div>
        <Footer />

        {/* WHATSAPP FLOATING BUTTON */}
        <FloatingButton handlePress={handleClickContact} />

        <ContactModal
          show={showContactModal}
          title={"Contact Representative"}
          name={"Eiman Munan:"}
          number={"+923460086799"}
          color={"#2ca58d"}
          className={styles.contactBtn}
          setShow={() => setShowContactModal(false)}
        />
      </div>
    </>
  );
};

export default IgniteDiploma;
