import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/dotlinelogowithname.png";

const OnboardHeader = () => {
  return (
    <Fragment>
      <div className="logo-div">
        <Link to={"/"}>
          <img src={logo} alt={"brand-logo"} />
        </Link>
      </div>
      <div>
        <p className="text-align-center font-28 font-weight-700 blue-color mb-0 mt-30">
          Add your child’s information
        </p>
        <p className="text-align-center font-18 font-weight-500 grey-color mb-15">
          We’ll recommend classes they’ll love!
        </p>
      </div>
    </Fragment>
  );
};

export default OnboardHeader;
