import React, { useState, useEffect } from "react";
import HeaderLogo from "../header-logo";
import { Space, Badge } from "antd";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./../styles.module.css";
import MobileMenuButton from "../mobile-menu-button";
import MobileMenuOptions from "../mobile-menu-options";
import NoAuthContent from "../no-auth-content";
import AuthContent from "../auth-content";
import messageIcon from "src/modules/parent/assets/svgs/message.svg";
import * as actions from "src/modules/parent/store/actions";
import { bindActionCreators } from "redux";
import actionTypes from "src/modules/parent/store/action-types";
import { openLinkInNewTab } from "src/modules/parent/utils";
import { isMobile } from "react-device-detect";

const HeaderContent = ({ isAuthenticated, logout, scrolled, userInfo }) => {
  const [showMobileMenuOptions, setShowMobileMenuOptions] = useState(false);
  const { chatCount } = useSelector((state) => state.chatReducer);
  const dispatch = useDispatch();

  const action = bindActionCreators(actions, dispatch);

  useEffect(() => {
    if (isAuthenticated) {
      action.getNotifications();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const redirectUser = (item) => {
    let redirect;

    switch (item.type) {
      case "certificate":
      case "progressReport":
      case "baselineForm":
        redirect = `${item.link}`;
        break;

      default:
        redirect = item.link;
        break;
    }

    // window.location.href = redirect;
    openLinkInNewTab(redirect);
  };

  const handleClick = (item) => {
    if (item && !item.is_read) {
      actions.updateNotification(item.id).then((response) => {
        if (response && response.status === "success") {
          action.updateNotificationData(item.id);
        }
      });
    }

    redirectUser(item);
  };

  const openModal = () => {
    dispatch({ type: actionTypes.TEACHER_MODAL, payload: true });
  };

  return (
    <div className={[styles.header_container, "d-flex h-100"].join(" ")}>
      {isMobile ? (
        <div className={styles.mobileHeader}>
          <Space size={"middle"}>
            <MobileMenuButton onClick={() => setShowMobileMenuOptions(true)} />
            <HeaderLogo />
          </Space>

          {isMobile && isAuthenticated && (
            <div>
              <NavLink to={"/chat"}>
                <Badge count={chatCount}>
                  <img src={messageIcon} alt={"message-icon"} />
                </Badge>
              </NavLink>
            </div>
          )}
        </div>
      ) : (
        <>
          <MobileMenuButton onClick={() => setShowMobileMenuOptions(true)} />
          <HeaderLogo />
        </>
      )}

      <MobileMenuOptions
        logout={logout}
        isAuthenticated={isAuthenticated}
        showMobileMenuOptions={showMobileMenuOptions}
        setShowMobileMenuOptions={setShowMobileMenuOptions}
      />
      {isAuthenticated ? (
        <AuthContent
          scrolled={scrolled}
          handleTeacherModal={openModal}
          onClickNotification={handleClick}
          userInfo={userInfo}
          logout={logout}
        />
      ) : (
        <NoAuthContent scrolled={scrolled} handleTeacherModal={openModal} />
      )}
    </div>
  );
};

export default HeaderContent;
