import React from "react";
import { Col, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import FlatButton from "src/modules/parent/components/flat-button";
import {
  gradesData,
  getShortCourseGrades,
} from "src/modules/parent/utils/static-data";
import SelectInput from "src/modules/parent/components/select-input";
import styles from "./styles.module.css";

const BasicFilters = ({
  grade,
  setGrade,
  subjects,
  setSubjects,
  keywordList,
  basicFilterHandler,
  basicFilterApplied,
  clearAllFilter,
  gradeFilterDisabled,
  suffixIcon,
  onSelect,
  allowClear = true,
  title,
  isSummerClass = false,
  isDisabled = false,
}) => {
  const noBasicFilter = !grade && !subjects?.length;
  const buttonDisabled = noBasicFilter && !basicFilterApplied;

  return (
    <Col xxl={17} xl={24} lg={24} md={24} sm={24} xs={24}>
      <Row gutter={[24, 24]}>
        {!gradeFilterDisabled && (
          <Col xxl={6} xl={7} lg={24} md={24} sm={24} xs={24}>
            <SelectInput
              allowClear
              className={"w-100"}
              placeholder={"Select Grade"}
              value={grade}
              onChange={setGrade}
              options={isSummerClass ? getShortCourseGrades : gradesData}
            />
          </Col>
        )}

        <Col
          xxl={gradeFilterDisabled ? 21 : 15}
          xl={gradeFilterDisabled ? 20 : 13}
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <Row style={{ position: "relative" }}>
            <SelectInput
              allowClear={allowClear}
              showSearch
              className={"w-100"}
              onSelect={onSelect}
              placeholder={title ? title : "Search by Subject"}
              value={subjects}
              onChange={setSubjects}
              options={
                keywordList && keywordList?.length !== 0 ? keywordList : []
              }
            />

            {suffixIcon && (
              <div className={styles.suffixStyle}>{suffixIcon}</div>
            )}
          </Row>
        </Col>

        {!isDisabled && (
          <Col>
            <FlatButton
              title={"Search"}
              onClick={noBasicFilter ? clearAllFilter : basicFilterHandler}
              postIcon={<SearchOutlined />}
              className={styles.btnStyle}
              height={47}
              disabled={buttonDisabled}
            />
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default BasicFilters;
