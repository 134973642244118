import React from "react";
import { Button, Row, Space } from "antd";
import styles from "./styles.module.css";

const FlatButton = ({
  title,
  onClick,
  preIcon,
  postIcon,
  isForm,
  disabled = false,
  className = "",
  loading = false,
  width = "",
  type = "Primary", // all possible types are 'Primary', 'Secondary', 'Tertiary', 'Quaternary'
  style = {},
  height,
}) => {
  const isMobile = window.innerWidth <= 1399;
  const buttonStyles = {
    height: height ? height : "40px",
    borderRadius: "60px",
    fontWeight: "600",
    fontSize: isMobile ? "15px" : "16px",
    color: "#005eff",
    opacity: disabled ? 0.5 : 1,
    width: width || "auto",
    ...style,
  };

  return (
    <Button
      className={[styles[type], className].join(" ")}
      htmlType={isForm ? "submit" : "button"}
      disabled={disabled}
      style={buttonStyles}
      loading={loading}
      onClick={onClick}
    >
      <Row justify={"center"} align={"middle"}>
        <Space>
          {preIcon || null}
          {loading ? "" : title || ""}
          {postIcon || null}
        </Space>
      </Row>
    </Button>
  );
};

export default FlatButton;
