import React, { useState } from "react";

import { Col, Row, Space, Form, Button } from "antd";
import { gradesData } from "src/modules/parent/utils/static-data";
import { useDispatch, useSelector } from "react-redux";
import PhoneWithFlagInput from "src/modules/parent/components/phone-input";
import SelectInput from "src/modules/parent/components/select-input";
import { parsePhoneNumberWithError } from "libphonenumber-js";

import styles from "../styles.module.css";
import {
  gradeNotification,
  phoneNumberNotification,
} from "src/modules/parent/utils/notificationData";
import actionTypes from "src/modules/parent/store/action-types";
import { naplanGrades } from "src/modules/parent/pages/international/staticData";

const StepNo2 = ({ handlePrevious, finishStep, subject }) => {
  const dispatch = useDispatch();
  const [grade, setGrade] = useState(null);
  const [stepperNo, setStepperNo] = useState(0);
  const [phone, setPhone] = useState(null);
  const { countries } = useSelector((state) => state.profileReducer);
  const defaultCountry = countries?.find((i) => i?.id === "9");
  const [country, setCountry] = useState(defaultCountry);
  const [isError, setIsError] = useState(false);

  const errorReturn = () => {
    const stepNoError =
      stepperNo === 0 ? gradeNotification : phoneNumberNotification;

    dispatch({ type: actionTypes.NOTIFICATIONDATA, payload: stepNoError });
    setIsError(true);
  };

  const isValidated = () => {
    if (stepperNo === 0 && !grade) {
      return errorReturn();
    } else if (stepperNo === 1 && !phone) {
      return errorReturn();
    }
    return true;
  };

  const increaseStep = () => {
    if (isValidated()) {
      if (stepperNo < 1) {
        setIsError(false);
        setStepperNo(stepperNo + 1);
      } else {
        const phoneNumber = parsePhoneNumberWithError(phone);
        if (phoneNumber.isValid()) {
          finishStep({ grade, phone });
        } else {
          dispatch({
            type: actionTypes.NOTIFICATIONDATA,
            payload: phoneNumberNotification,
          });
        }
      }
    }
  };

  const decreaseStep = () => {
    if (stepperNo > 0) {
      setStepperNo(stepperNo - 1);
    } else {
      handlePrevious();
    }
  };

  const countryOnChange = (e) => {
    const searchPhone = countries.find((i) => i.id === e.toString());
    if (searchPhone) {
      setCountry(searchPhone);
    }
  };

  return (
    <Col className={styles.rightColumn} span={24}>
      <p className={["font-18", styles.description, styles.answer].join(" ")}>
        {"Answer some easy questions to help us find your teacher!"}
      </p>

      {stepperNo === 0 ? (
        <Row align={"bottom"} justify={"start"}>
          <h2>{"What grade is your child in?"}</h2>

          <Col span={24}>
            <SelectInput
              allowClear
              className={"w-95"}
              value={grade}
              placeholder={"Select Grade"}
              onChange={setGrade}
              options={subject === "NAPLAN" ? naplanGrades : gradesData}
            />
          </Col>
        </Row>
      ) : (
        <Row justify={"start"}>
          <h2>{"Enter your phone number"}</h2>

          <Col span={24}>
            <Form className={"text-align-center"}>
              <Form.Item
                hasFeedback={!phone && isError}
                validateStatus={!phone && isError ? "error" : "success"}
              >
                <PhoneWithFlagInput
                  showSearch
                  inputPlaceholder={"Enter phone"}
                  value={phone}
                  countryOnChange={countryOnChange}
                  countryPlaceholder={"Select"}
                  selectedCountry={country?.value}
                  selectedId={defaultCountry?.id}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}

      <Row justify={"center"}>
        <Space>
          <Button onClick={decreaseStep} className={styles.previousButton}>
            {"Previous"}
          </Button>

          <Button onClick={increaseStep} className={styles.searchButton}>
            {"Next"}
          </Button>
        </Space>
      </Row>
    </Col>
  );
};

export default StepNo2;
