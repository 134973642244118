import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import ReactPixel from "react-facebook-pixel";

import ModalComponent from "../../modal";
import Step1 from "./steps/step1";
import { fb_pixel_id_examPrep } from "src/modules/parent/config/constant";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step5 from "./steps/step5";
import LoadingScreen from "./steps/loading";
import { defaultUAE } from "src/modules/parent/utils";
import actionTypes from "src/modules/parent/store/action-types";
import { getExamPrepSubjects } from "src/modules/parent/store/actions";
import {
  gradeSelectNotification,
  selectSubjectNotification,
  boardSelectNotification,
  phoneNumberNotification,
  phoneValidateNotification,
} from "src/modules/parent/utils/notificationData";

import * as actions from "src/modules/parent/store/actions";

import styles from "./styles.module.css";

// const whatsappMobile = "https://wa.me/";
ReactPixel.init(fb_pixel_id_examPrep);

const AssistMe = ({
  show = false,
  handleClose = () => {},
  onHandleFallback = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stepper, setStepper] = useState(0);
  const [country, setCountry] = useState(defaultUAE);
  const [grade, setGrade] = useState(null);
  const [board, setBoard] = useState(null);
  const [subject, setSubject] = useState(null);
  const [phone, setPhone] = useState(null);
  const { countries } = useSelector((state) => state.profileReducer);
  const [subjectLoading, setSubjectLoading] = useState(false);
  const [error, setError] = useState(false);
  const [apiSubjects, setApiSubjects] = useState([]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = "auto"; // Enable scroll
    }

    const preventScroll = (e) => e.preventDefault();

    // Add touchmove listener to prevent scrolling
    if (show) {
      document.addEventListener("touchmove", preventScroll, { passive: false });
    }

    return () => {
      document.body.style.overflow = "auto"; // Cleanup
      document.removeEventListener("touchmove", preventScroll);
    };
  }, [show]);

  const handleNext = useCallback(() => {
    if (stepper === 0 && !board) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: boardSelectNotification,
      });
    }

    if (stepper === 1 && !grade) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: gradeSelectNotification,
      });
    }

    if (stepper === 2 && !subject) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: selectSubjectNotification,
      });
    }

    switch (stepper) {
      case 0:
        setStepper((prev) => prev + 1);
        break;

      case 1:
        setSubjectLoading(true);
        getExamPrepSubjects({ grades: [grade], boards: [board] })
          .then((response) => {
            setSubjectLoading(false);

            if (response && response.length) {
              setApiSubjects(response);
              setStepper((prev) => prev + 1);
            } else {
              onHandleFallback({ board, grade });
              handleClose();
              clearData();
            }
          })
          .catch(() => {
            setSubjectLoading(false);
          });
        break;

      case 2:
        setStepper((prev) => prev + 1);
        break;

      default:
        break;
    }

    // setStepper((prev) => prev + 1);
  }, [board, dispatch, grade, handleClose, onHandleFallback, stepper, subject]);

  const handlePrevious = useCallback(() => {
    setStepper((prev) => prev - 1);
  }, []);

  const saveToDB = useCallback(
    (number) => {
      setStepper("loading");

      const params = {
        grades: [grade],
        boards: [board],
        subjects: [subject],
        phone: number,
        limit: 6,
      };

      actions.getOlevelTeachers(params).then((response) => {
        const state = {
          grade,
          board,
          subject,
          teachers: [],
        };

        if (response && response.length) {
          const teacherPackages = response;
          state.teachers = teacherPackages;
        }

        ReactPixel.trackSingle(fb_pixel_id_examPrep, "Subscribe");

        navigate("/exam-preparation", {
          state,
        });
      });
    },
    [board, grade, navigate, subject]
  );

  const onSubmit = useCallback(() => {
    if (stepper === 3 && !phone) {
      return setError(true);
    }

    const phoneNumber = parsePhoneNumberWithError(phone);

    if (phoneNumber.isValid()) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: phoneValidateNotification(phoneNumber?.number, saveToDB),
      });
    } else {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: phoneNumberNotification,
      });
    }
  }, [dispatch, phone, saveToDB, stepper]);

  const countryOnChange = useCallback(
    (e) => {
      const searchPhone = countries.find((i) => i.id === e.toString());
      if (searchPhone) {
        setCountry(searchPhone);
        setPhone("");
      }
    },
    [countries]
  );

  const getSteps = useMemo(() => {
    switch (stepper) {
      case 0:
        return (
          <Step5 board={board} handleNext={handleNext} setBoard={setBoard} />
        );

      case 1:
        return (
          <Step1
            board={board}
            grade={grade}
            isLoading={subjectLoading}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            setGrade={setGrade}
          />
        );

      case 2:
        return (
          <Step2
            grade={grade}
            apiSubjects={apiSubjects}
            subject={subject}
            board={board}
            setSubject={setSubject}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );

      case 3:
        return (
          <Step3
            country={country}
            grade={grade}
            subject={subject}
            isError={error}
            board={board}
            phone={phone}
            setPhone={setPhone}
            countryOnChange={countryOnChange}
            handleSubmit={onSubmit}
            handlePrevious={handlePrevious}
          />
        );

      default:
        return <LoadingScreen />;
    }
  }, [
    apiSubjects,
    board,
    country,
    countryOnChange,
    error,
    grade,
    handleNext,
    handlePrevious,
    onSubmit,
    phone,
    stepper,
    subject,
    subjectLoading,
  ]);

  const clearData = () => {
    setStepper(0);
    setBoard(null);
    setGrade(null);
    setSubject(null);
    setApiSubjects([]);
    setError(false);
    setPhone(null);
  };

  return (
    <ModalComponent
      centered={isBrowser}
      containerClassName={[
        styles.modalContainer,
        stepper === 3 && styles.packageModal,
      ].join(" ")}
      isModalVisible={show}
      onCancel={() => {
        clearData();
        handleClose();
      }}
      footer={false}
    >
      <div className={styles.container}>{getSteps}</div>
    </ModalComponent>
  );
};

export default React.memo(AssistMe);
