import React from "react";

import { Row, Space, Button } from "antd";

import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import star from "../../assets/webp/Star.webp";
import play from "../../assets/webp/play.webp";
import { isLocal } from "src/modules/parent/utils";

import styles from "./styles.module.css";

const isPriceAvailable = (teacher) =>
  isLocal() ? teacher?.price_per_hour_pkr : teacher?.price_per_hour_usd;

const getPrice = (teacher) => {
  if (!isLocal()) {
    return `USD ${teacher?.price_per_hour_usd || 0}/hr`;
  } else {
    return `PKR ${teacher?.price_per_hour_pkr || 0}/hr`;
  }
};

const Card = ({
  id,
  teacher,
  title,
  handleClickCard = () => {},
  handleBookADemo = () => {},
  handleShowModal = () => {},
  ...rest
}) => (
  <div className={styles.cardStyle}>
    <div
      onClick={() =>
        handleShowModal(teacher?.naplan_video || teacher?.video_id)
      }
      className={styles.imageDiv}
    >
      <img
        src={teacher?.picture}
        style={{ width: "100%", height: isMobile ? "33vh" : "40vh" }}
        alt={"cover"}
      />

      <img src={play} className={styles.overlayImage} alt={"playButton"} />

      {isPriceAvailable(teacher) && (
        <div className={styles.price}>
          <Text
            color={"white"}
            size={"M2"}
            font={"SEMIBOLD"}
            title={getPrice(teacher)}
          />
        </div>
      )}
    </div>

    <div
      onClick={(e) => {
        e.preventDefault();
        handleClickCard(id, title);
        e.stopPropagation();
      }}
      className={styles.cardBody}
    >
      <Row align={"middle"}>
        <Space>
          <img src={star} width={20} height={20} alt={"star"} />

          <Text
            color={"#787878"}
            size={"M"}
            font={"SEMIBOLD"}
            title={teacher?.ratings || "0"}
          />
        </Space>
      </Row>

      <Text
        color={"#2CA58D"}
        size={"XL"}
        className={"mt-10"}
        font={"SEMIBOLD"}
        title={teacher?.name}
      />

      {teacher?.olevel_bio && teacher?.olevel_bio.length > 0 && (
        <ul className={styles.list}>
          {teacher?.olevel_bio.map((item, index) => (
            <li key={index.toString()}>
              <Text
                color={"#646464"}
                font={"LIGHTER"}
                size={"S"}
                lHeight={"M"}
                title={item}
              />
            </li>
          ))}
        </ul>
      )}

      <Button
        onClick={(e) => {
          e.preventDefault();
          handleBookADemo({ ...rest, id, title, teacher });
          e.stopPropagation();
        }}
        loading={false}
        className={styles.learnMoreButton}
      >
        <Space>{"Book a Free Demo"}</Space>
      </Button>
    </div>
  </div>
);

export default React.memo(Card);
