import React, { useEffect, useMemo, useState } from "react";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { message, Row } from "antd";
import styles from "./../styles.module.css";
import {
  checkIsAuthenticated,
  getConvertedTime,
  increaseDemoCount,
} from "src/modules/parent/utils";
import FlatButton from "src/modules/parent/components/flat-button";
import ModalComponent from "src/modules/parent/components/modal";
import DemoBooked from "../demo-booked";
import { bindActionCreators } from "redux";
import * as actions from "../../../store/actions";
import { useDispatch } from "react-redux";
import DemoConfirm from "../demo-confirm";
import { useNavigate } from "react-router-dom";
import { countries } from "src/modules/parent/utils/static-data/countries";
import {
  requestDemoClassBtn,
  trackEvents,
} from "src/modules/parent/utils/events/query";

const RequestDemoCard = ({
  item,
  packageDetail,
  isScheduled,
  handleDemoRescheduled,
  loadingId,
  itemIndex,
  isDisabled,
  setRequestModal = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const date = moment(item?.date).format("ddd D MMM");
  const [country, setCountry] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccesssModal, setShowSuccesssModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const index = `${item.id}-${itemIndex}}`;

  useEffect(() => {
    const guess = moment.tz.guess();
    const countryName = moment.tz.zone(guess).countries();

    const cName = countries.find((i) => i.code === countryName[0]);

    if (cName && cName.title) {
      setCountry(cName.title);
    }
  }, []);

  const requestDemoHandler = () => {
    setLoading(true);

    const data = {
      slot_id: item.id,
      package_id: item.teacher_package_id,
      booking_date: moment(item.date).format("YYYY-MM-DD"),
      country,
    };

    action
      .createDemo(data)
      .then(() => {
        increaseDemoCount();
        setLoading(false);
        setShowConfirmModal(false);
        setShowSuccesssModal(true);
      })
      .catch(() => setLoading(false));
  };

  const accessBookDemo = () => {
    const date = localStorage.getItem("demo_date");
    const demoCount = localStorage.getItem("demo_count");
    const count = demoCount ? Number(demoCount) : 0;
    if (count < 3) {
      return true;
    } else {
      const startTime = moment(Number(date));
      const endTime = moment();
      const duration = moment.duration(endTime.diff(startTime));
      const hours = duration.asHours();
      if (hours > 48) {
        localStorage.removeItem("demo_count");
        localStorage.removeItem("demo_date");
        return true;
      }
    }
    return false;
  };

  const getRemainingTime = () => {
    const date = localStorage.getItem("demo_date");
    const startTime = moment(Number(date)).add(48, "hours");
    const endTime = moment();
    const duration = moment.duration(startTime.diff(endTime));
    const hours = duration.asHours();
    return Math.floor(hours);
  };

  const checkRequestDemoLimit = () => {
    if (checkIsAuthenticated()) {
      if (accessBookDemo()) {
        setShowConfirmModal(true);

        trackEvents(requestDemoClassBtn);
      } else {
        const msg = `You have reached your limit for booking a demo for the next ${getRemainingTime()} hours.`;
        message.info(msg, 5);
      }
    } else {
      const data = {
        slot_id: item.id,
        package_id: item.teacher_package_id,
        booking_date: moment(item.date).format("YYYY-MM-DD"),
        country,
      };

      action.setRegisterData({
        show: true,
        requestDemo: data,
        package: packageDetail,
        type: "demo",
      });
    }
  };

  const redirectToDashboard = () => {
    navigate("/");
    setShowSuccesssModal(false);
    setRequestModal(false);
  };

  const getDemoDisable = useMemo(() => {
    if (packageDetail) {
      if (packageDetail.is_booked) {
        return true;
      } else if (!packageDetail?.teacher?.assignDemos) {
        return true;
      }
    }

    return false;
  }, [packageDetail]);

  return (
    <div
      className={[
        styles.time_slot_card,
        isDisabled ? styles.disableStyle : {},
      ].join(" ")}
    >
      <ModalComponent
        closable={false}
        maskClosable={false}
        isModalVisible={showSuccesssModal}
        onCancel={redirectToDashboard}
        bodyClassName={"d-flex justify-content-center align-items-center"}
        Component={
          <DemoBooked
            okHandler={redirectToDashboard}
            packageTitle={packageDetail?.title}
            teacherName={packageDetail?.teacher?.name}
          />
        }
      />
      <ModalComponent
        closable={false}
        maskClosable={false}
        isModalVisible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        bodyClassName={"d-flex justify-content-center align-items-center"}
        Component={
          <DemoConfirm
            loading={loading}
            onOk={requestDemoHandler}
            teacherName={packageDetail?.teacher?.name}
            onCancel={() => {
              setShowConfirmModal(false);
            }}
            bookingDate={moment(item.date).format("dddd DD MMMM, YYYY")}
          />
        }
      />
      <div>
        <Row className="dark-grey-color d-flex align-items-center">
          <CalendarOutlined className="font-20" style={{ color: "#EC1847" }} />
          <p className={styles.demo_card_text}>{date}</p>
        </Row>

        <Row className="dark-grey-color d-flex align-items-center mt-5">
          <ClockCircleOutlined
            style={{ color: "#EC1847" }}
            className="font-20"
          />
          <p className={styles.demo_card_text}>
            {getConvertedTime(item.start_time, item.end_time, item.time_zone)}
          </p>
        </Row>

        <Row className="d-flex justify-content-center">
          <FlatButton
            width={"144px"}
            title={isScheduled ? "Reschedule" : "Request"}
            type="Quaternary"
            loading={index === loadingId}
            className={"mt-18 mb-10"}
            onClick={
              isScheduled ? handleDemoRescheduled : checkRequestDemoLimit
            }
            disabled={
              isDisabled ? isDisabled : isScheduled ? false : getDemoDisable
            }
          />
        </Row>
      </div>
    </div>
  );
};

export default RequestDemoCard;
