import React, { useState } from "react";

import { Col, Row, Space } from "antd";
import { isMobile } from "react-device-detect";
import moment from "moment-timezone";

import Text from "src/modules/parent/components/text";
import Modal from "src/modules/parent/components/modal";
import FlatButton from "src/modules/parent/components/flat-button";
import noData from "src/modules/parent/assets/svgs/noDataupdated.svg";
import pdfFile from "src/modules/parent/assets/img/pdf-file.png";
import downloadIcon from "../../../assets/svgs/download.svg";
import eyeIcon from "../../../assets/svgs/eye.svg";

import styles from "./styles.module.css";

const Card = ({
  name,
  picture,
  type,
  created_at,
  link,
  filepath,
  handleView = () => {},
}) => (
  <div className="white_box p-20">
    <Row>
      <Space size={"large"}>
        <div className={styles.teacherImage} style={{ width: 71, height: 71 }}>
          <img src={picture} alt={"teacherImage"} />
        </div>

        <Row justify={"center"}>
          <Space direction={"vertical"}>
            <Text
              color={"rgba(0, 94, 255, 1)"}
              size={"M2"}
              lHeight={"XS"}
              font="SEMIBOLD"
            >
              {name}
            </Text>

            <Text color={"rgba(153, 153, 153, 1)"} lHeight={"S"} size={"XS"}>
              {`Posted on ${moment(created_at).format("DD MMM YYYY, hh:mm a")}`}
            </Text>
          </Space>
        </Row>
      </Space>
    </Row>

    <Text
      color={"rgba(85, 85, 85, 1)"}
      className={"mt-30"}
      size={"M2"}
      font="SEMIBOLD"
    >
      {type}
    </Text>

    <Row
      gutter={[0, 20]}
      justify={isMobile ? "start" : "space-between"}
      align={"bottom"}
      className={"mt-10"}
    >
      <div className={styles.rightIcon}>
        <div className={styles.pdfIcon}>
          <img
            src={pdfFile}
            alt={"pdfIcon"}
            style={{ width: "50px", height: "50px" }}
          />
        </div>

        <Row className={styles.name}>
          <Space direction={"vertical"}>
            <Text color={"rgba(0, 94, 255, 1)"} size={"S"} font="SEMIBOLD">
              {type}
            </Text>

            <Text color={"rgba(153, 153, 153, 1)"} size={"S"}>
              {"PDF"}
            </Text>
          </Space>
        </Row>
      </div>

      <div className={styles.options}>
        <FlatButton
          type={"Secondary"}
          height={33}
          width={isMobile ? "100%" : 110}
          preIcon={<img src={eyeIcon} alt="viewIcon" />}
          className={styles.joinClassBtn}
          title={"View"}
          onClick={() => handleView({ type, link: link ? link : filepath })}
        />

        <FlatButton
          type={"Secondary"}
          height={33}
          width={isMobile ? "100%" : 140}
          preIcon={<img src={downloadIcon} alt="downloadIcon" />}
          className={styles.joinClassBtn}
          title={"Download"}
          onClick={() => {
            const downloadLink = link ? link : filepath;
            fetch(downloadLink)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = `${type}.pdf`;
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
              })
              .catch((error) => {
                console.error("Error fetching the file:", error);
              });
          }}
        />
      </div>
    </Row>
  </div>
);

const Assignment = ({ data = [], teacher, type }) => {
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [videoData, setVideoData] = useState(null);

  const handleView = (params) => {
    const { type, link } = params;
    setVideoData({ title: type, url: link });
    setShowPDFModal(true);
  };

  return (
    <div className={isMobile ? "pb-20" : "pb-20 pl-20 pr-20"}>
      <Row gutter={[0, 10]}>
        {data && data.length > 0 ? (
          data.map((item) => (
            <Col key={item.id} span={24}>
              <Card
                {...item}
                name={teacher?.name}
                picture={teacher?.picture}
                type={type}
                handleView={handleView}
              />
            </Col>
          ))
        ) : (
          <div className={styles.noData}>
            <img src={noData} alt={"nodata"} />

            {type === "Notes" ? (
              <Text color={"rgba(153, 153, 153, 1)"} lHeight={"S"} size={"S"}>
                {
                  "Oops! It looks like the notes and resources aren't ready just yet. They will appear as soon as the teacher uploads them! Thank you for your patience!"
                }
              </Text>
            ) : (
              <Text color={"rgba(153, 153, 153, 1)"} lHeight={"S"} size={"S"}>
                {`Oops! It looks like a ${type.toLowerCase()} for your child isn't ready just yet. It will appear as soon as the teacher sends it! Thank you for your patience!`}
              </Text>
            )}
          </div>
        )}
      </Row>

      {showPDFModal && videoData && (
        <Modal
          destroyOnClose
          showOkButton={false}
          containerClassName={styles.modalStyle}
          maskClosable={false}
          isModalVisible={showPDFModal}
          title={videoData.title}
          onCancel={() => {
            setVideoData(null);
            setShowPDFModal(false);
          }}
        >
          <Row className="mt-20">
            <iframe
              className={styles.pdf}
              title={videoData.title}
              src={`${videoData.url}#toolbar=0`}
            />
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default React.memo(Assignment);
