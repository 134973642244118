import actionTypes from "../../action-types";

const INITIAL_STATE = {
  inboxData: [],
  chatCount: 0,
  chatMessages: {
    current_page: 1,
    data: [],
    next: false,
    prev: false,
    total_page: 1,
  },
  onScrollToBottom: false,
};

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.INBOX_DATA: {
      return {
        ...state,
        inboxData: action.payload,
      };
    }

    case actionTypes.GET_CHAT_MESSAGES: {
      let dataChatMessages;

      if (action.page && action.page > 1) {
        dataChatMessages = {
          ...action.payload,
          data: Array.prototype.concat.apply(
            state.chatMessages?.data || [],
            action.payload.data
          ),
        };
      } else {
        dataChatMessages = { ...action.payload };
      }

      return {
        ...state,
        chatMessages: dataChatMessages,
      };
    }

    case actionTypes.SCROLL_TO_BOTTOM: {
      return {
        ...state,
        onScrollToBottom: action.payload,
      };
    }

    case actionTypes.CHAT_COUNT: {
      return {
        ...state,
        chatCount: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default chatReducer;
