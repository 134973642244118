import React from "react";

import { Button } from "antd";
import Text from "src/modules/parent/components/text";
import star from "../../../assets/images/star.png";
import NoData from "./noData";

import styles from "./styles.module.css";

const TopicMastered = ({
  data = [],
  onHandleClick = () => {},
  handleClose = () => {},
}) => (
  <>
    {data && !data.length ? (
      <NoData
        description={"Keep exploring to watch their learning journey unfold!"}
        btnText={"Okay, thanks!"}
        handleBtnClick={handleClose}
        title={
          "Your child is still on their way to mastering topics, but they're making great progress!"
        }
      />
    ) : (
      <>
        <span className={styles.title}>
          Topics mastered in{" "}
          <span>
            {data
              .slice(0, 1)
              .map((i) => JSON.parse(i.subject))
              .join(", ")}
          </span>
        </span>

        <Text
          size={"XL"}
          font={"SEMIBOLD"}
          lHeight={"S"}
          color={"#000000"}
          title={"include"}
        />

        <div className={styles.content}>
          {data.map((item) => (
            <div className={styles.topics}>
              <img src={star} alt={"star"} />

              <Text
                size={"M2"}
                font={"LIGHTER"}
                color={"#000000"}
                title={item?.topic}
              />
            </div>
          ))}
        </div>

        <Button
          onClick={() => onHandleClick(data[0]?.enroll_id)}
          className={styles.button}
        >
          <Text
            size={"S"}
            lHeight={"S"}
            color={"#FFFFFF"}
            title={"View Complete Progress Report"}
          />
        </Button>
      </>
    )}
  </>
);

export default React.memo(TopicMastered);
