import React, { useState } from "react";
import { Affix, message } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../config/network";
import { LOG_OUT } from "../../config/api";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import HeaderContent from "./header-content";
import AppLoader from "../app-loader";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase";
import { axiosErrorHandler } from "../../utils";

const Header = ({ headerClass = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = bindActionCreators(actions, dispatch);
  // const [scrolled, setScrolled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, userInfo } = useSelector(
    (state) => state.authReducer
  );

  const onLogoutSuccess = () => {
    action.onLogOut().then(() => {
      setLoading(false);
      navigate("/");
    });
  };

  const logoutFromFirebase = () => {
    signOut(auth)
      .then(() => onLogoutSuccess())
      .catch((err) => {
        setLoading(false);
        message.error("Something went wrong. Please try again!");
      });
  };

  const logout = async () => {
    setLoading(true);
    if (userInfo && userInfo?.type === "loginViaEmail") {
      postRequest(LOG_OUT, { source: "parent_web" })
        .then(() => logoutFromFirebase())
        .catch((err) => {
          axiosErrorHandler(err);
          setLoading(false);
        });
    } else {
      logoutFromFirebase();
    }
  };

  return (
    <Affix>
      {loading && <AppLoader fullPage text={"You are being logged out..."} />}
      <div>
        <PageHeader
          className={[
            headerClass,
            styles.site_page_header,
            styles.header_small,
            "white-background-imp",
          ].join(" ")}
          children={
            <HeaderContent
              logout={logout}
              userInfo={userInfo}
              isAuthenticated={isAuthenticated}
            />
          }
        />
      </div>
    </Affix>
  );
};

export default Header;
