import React, { Fragment } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Menu } from "antd";

import styles from "./../styles.module.css";
import actionTypes from "src/modules/parent/store/action-types";

const CoursesMenuOptions = ({ triggerEvent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuOptions = [
    {
      id: 1,
      name: "Kindergarten - Grade 7",
      clickHandler: () =>
        dispatch({ type: actionTypes.ASSIST_ME_MODAL, payload: true }),
    },
    {
      id: 4,
      name: "Grade 8 - Grade 13",
      clickHandler: () => {
        navigate("/exam-prep");
      },
    },
  ];

  return (
    <Fragment>
      <Menu className={styles.menu_card}>
        {menuOptions.map((option) => (
          <Menu.Item
            key={option.id}
            onClick={option.clickHandler}
            className={styles.profile_menu_item}
          >
            <span>{option.name}</span>
          </Menu.Item>
        ))}
      </Menu>
    </Fragment>
  );
};

export default CoursesMenuOptions;
