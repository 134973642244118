import actionTypes from "../../action-types";

const INITIAL_STATE = {
  subjectList: null,
  keywordList: [],
};

const subjectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_SUBJECT_LIST: {
      return {
        ...state,
        subjectList: action.payload,
      };
    }
    case actionTypes.SET_KEYWORD_LIST: {
      return {
        ...state,
        keywordList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default subjectReducer;
