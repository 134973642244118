import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ModalComponent from "../../components/modal";
import * as actions from "../../store/actions";
import ConactUsFormContent from "./conact-us-form-content";

const ContactUs = () => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const { showContactUsModal, is_Olevel } = useSelector(
    (state) => state.generalReducer
  );

  return (
    <Fragment>
      {showContactUsModal && (
        <ModalComponent
          maskClosable={false}
          isModalVisible={showContactUsModal}
          onCancel={() => action.setContactUsModal(false)}
          Component={<ConactUsFormContent isOlevel={is_Olevel} />}
        />
      )}
    </Fragment>
  );
};

export default ContactUs;
