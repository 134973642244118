import React from "react";

import Carousel from "react-multi-carousel";

import CardItem from "./card";
import { responsiveArticles } from "src/modules/parent/utils";
import { isMobile } from "react-device-detect";

const Card = ({
  data = [],
  carouselKey = 0,
  handleShowModal = () => {},
  handleBookADemo = () => {},
  handleClickCard = () => {},
}) => (
  <div className={isMobile ? "mt-50" : "mt-50 mb-50"}>
    {data && data.length > 0 && (
      <Carousel key={carouselKey} responsive={responsiveArticles}>
        {data?.map((item) => (
          <CardItem
            {...item}
            key={item.id}
            handleBookADemo={handleBookADemo}
            handleClickCard={handleClickCard}
            handleShowModal={handleShowModal}
          />
        ))}
      </Carousel>
    )}
  </div>
);

export default React.memo(Card);
