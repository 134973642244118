import { getNotificationData } from "../index";
import logout from "src/modules/parent/assets/notificationSvgs/logout.svg";
import incomplete from "src/modules/parent/assets/notificationSvgs/incomplete.svg";
import rocket from "src/modules/parent/assets/notificationSvgs/rocket.svg";
import conducted from "src/modules/parent/assets/notificationSvgs/conducted.svg";
import selectSubject from "src/modules/parent/assets/notificationSvgs/selectSubject.svg";
import bell from "src/modules/parent/assets/notificationSvgs/bell.svg";
import msg from "src/modules/parent/assets/notificationSvgs/msg.svg";
import success from "src/modules/parent/assets/notificationSvgs/success.svg";
import ReactPixel from "react-facebook-pixel";
import { fb_pixel_id_summer } from "src/modules/parent/config/constant";
import { findCourseSignupSuccess, trackEvents } from "../events/query";

ReactPixel.init(fb_pixel_id_summer);

const accountInUse = () => (
  <div className="mt-20">
    <div>{"An account already exists with this email address."}</div>
    <div>
      {
        "Please use the forgot password option to generate a new password or contact"
      }
    </div>
    <div>
      <span className="link-tag">info@dotandlinelearning.com</span> /{" "}
      <span className="link-tag">+923460086795</span> for assistance.
    </div>
  </div>
);

const logoutContent = () => (
  <div className="mt-20">
    <div>{"You have now logged out of your account."}</div>
    <div>
      {
        "Please log in to book a free demo or view your child’s progress reports!"
      }
    </div>
  </div>
);

const renderData = () => (
  <div className="mt-20">
    Please fill in all required fields. If you need assistance please contact{" "}
    <span className="link-tag">info@dotandlinelearning.com</span>
  </div>
);

export const renderContent = (text) => (
  <div className="mt-20 font-17">{text}</div>
);

const accountExistContent = () => (
  <div className="mt-20">
    {
      "Please use the forgot password option to generate a new password or contact"
    }
    <span className="link-tag">info@dotandlinelearning.com</span> /{" "}
    <span className="link-tag">+923460086795</span> for assistance."
  </div>
);

const zoomContent = () => (
  <div className="mt-20">
    {"Zoom Link could not be found in our system."}
    <div>
      Please contact <span className="link-tag">+923460086795</span> or{" "}
      <span className="link-tag">aiman@dotandlinelearning.com</span> for
      immediate assistance.
    </div>
  </div>
);

const invoiceContent = () => (
  <div className="mt-20">
    {"Your invoice could not be found in our system."}
    <div>
      Please contact <span className="link-tag">+923474106202</span> or{" "}
      <span className="link-tag">Baber@dotandlinelearning.com</span> for
      immediate assistance.
    </div>
  </div>
);

export const inCompleteDataNotification = getNotificationData({
  image: incomplete,
  title: "Incomplete Data",
  content: renderData,
  successText: "Okay",
  disableCancel: true,
});

export const logoutNotification = getNotificationData({
  image: logout,
  title: "Logged Out",
  content: logoutContent,
  successText: "Login",
  handleSuccess: () => {
    window.location.pathname = "/login";
  },
});

export const accountInUsenotification = getNotificationData({
  type: "info",
  title: "Account in Use",
  content: accountInUse,
  handleCancel: () => {
    window.location.pathname = "/reset-password";
  },
  cancelText: "Forgot Password",
  successText: "Got it!",
});

export const accountExistNotification = getNotificationData({
  type: "info",
  title: "An account already exists with this email address.",
  successText: "Got it!",
  content: accountExistContent,
});

export const signUpNotification = getNotificationData({
  image: rocket,
  content: () =>
    renderContent(
      "Your account has been created successfully. Happy Learning!"
    ),
  title: "Congratulations!",
  successText: "Find a Course",
  handleSuccess: () => {
    trackEvents(findCourseSignupSuccess);

    window.location.href = "/courses?program_id=5";
  },
});

export const phoneNumberNotification = getNotificationData({
  type: "error",
  content: () => renderContent("Please enter a valid phone number"),
  disableCancel: true,
  timer: 3000,
});

export const recommendedNotification = getNotificationData({
  type: "default-success",
  title:
    "Here are the top recommended tutors. Please scroll down to book a free demo.",
  disableCancel: true,
  timer: 3000,
});

export const contactNumberNotification = getNotificationData({
  type: "error",
  content: () => renderContent("Please enter a valid contact number"),
  disableCancel: true,
  timer: 3000,
});

export const emailNotification = getNotificationData({
  type: "error",
  content: () => renderContent("Please Enter Valid Email"),
  disableCancel: true,
  timer: 3000,
});

export const gradeNotification = getNotificationData({
  type: "error",
  content: () => renderContent("Please select grade"),
  disableCancel: true,
  timer: 3000,
});

export const facebookNotification = getNotificationData({
  type: "info",
  content: () =>
    renderContent(
      "There was an error while we tried logging you in with facebook. Please try again or use the sign up button below to create a new account."
    ),
  title: "Oops!",
  successText: "Sign Up",
  handleSuccess: () => {
    window.location.pathname = "/signup";
  },
});

const phoneValidateText = (number) => (
  <div>
    <div className="font-20">{"Is this number correct?"}</div>

    <div
      style={{ color: "#005eff", fontWeight: "normal" }}
      className="mt-10 font-18"
    >
      {number}
    </div>
  </div>
);

export const phoneValidateNotification = (number, handleSuccess) =>
  getNotificationData({
    noImg: true,
    content: () => phoneValidateText(number),
    successText: "Yes",
    disableCancel: false,
    cancelText: "No",
    centerAlign: true,
    handleSuccess: () => handleSuccess(number),
  });

export const ignitePhonehoneValidateNotification = (
  number,
  handleSuccess,
  handleCancel
) =>
  getNotificationData({
    noImg: true,
    content: () => phoneValidateText(number),
    successText: "Yes",
    disableCancel: false,
    cancelText: "No",
    centerAlign: true,
    handleSuccess: () => handleSuccess(number),
    handleCancel: () => handleCancel(),
  });

export const freeClassNotification = getNotificationData({
  image: rocket,
  content: () =>
    renderContent(
      "You are now registered for this fantastic class! See you soon!"
    ),
  successText: "Okay",
  disableCancel: true,
  handleSuccess: () => {
    window.location.pathname = "/";
  },
});

export const classConductedNotification = getNotificationData({
  image: conducted,
  title: "Class Status",
  content: () =>
    renderContent(
      "This class has already been conducted. Please register for another class on our app!"
    ),
  successText: "Go Back Home",
  handleSuccess: () => {
    window.location.pathname = "/";
  },
});

export const selectSubjectNotification = getNotificationData({
  image: selectSubject,
  content: () =>
    renderContent(
      "Please select only one subject and we will recommend the best tutor just for you!"
    ),
  disableCancel: true,
});

export const boardSelectNotification = getNotificationData({
  type: "error",
  content: () => renderContent("Please select your board first"),
  disableCancel: true,
  successText: "Okay",
});

export const emptySelectionNotification = getNotificationData({
  type: "error",
  content: () =>
    renderContent("Please apply the Board, Grade and Subject filter first."),
  disableCancel: true,
  successText: "Okay",
});

export const gradeSelectNotification = getNotificationData({
  type: "error",
  content: () => renderContent("Please select your grade first"),
  disableCancel: true,
  successText: "Okay",
});

export const fillRequiredNotification = getNotificationData({
  type: "error",
  content: () => renderContent("Please fill all required fields!"),
});

export const discountNotification = getNotificationData({
  image: bell,
  title: "Discount Alert!",
  content: () =>
    renderContent(
      "Get 15% off your first invoice if you make your payment in the next 48 hours! Happy Learning!"
    ),
  successText: "Okay",
  disableCancel: true,
});

export const accountReadyNotification = (callback) =>
  getNotificationData({
    image: success,
    title: "Your Account is Ready!",
    content: () =>
      renderContent(
        "Ready to dive into the course? Secure your spot now by making the payment. Click on the button to view your invoice. Let the learning adventure begin!"
      ),
    successText: "Make Payment",
    cancelText: "Cancel",
    handleSuccess: () => {
      if (typeof callback === "function") {
        callback();
      }

      window.location.pathname = "/invoices";
    },
  });

export const teacherReviewNotification = getNotificationData({
  image: selectSubject,
  content: () =>
    renderContent(
      "Please fill in the missing fields! Your teacher partner can’t wait to hear from you!"
    ),
  disableCancel: true,
});

export const passwordMisMatchNotification = getNotificationData({
  type: "info",
  title: "Error",
  content: () =>
    renderContent(`Your password does not match! 
  Please try again or use the forgot password option to generate a new password!`),
  cancelText: "Forgot Password",
  successText: "Okay",
  handleCancel: () => {
    window.location.pathname = "/reset-password";
  },
});

export const thankUNotification = getNotificationData({
  image: msg,
  content: () =>
    renderContent(
      "Thank you! Our academic counselor will be reaching out to you via whatsapp shortly."
    ),
  disableCancel: true,
});

export const assignmentNotification = getNotificationData({
  image: success,
  title: "Success!",
  content: () =>
    renderContent(
      "Your assignment for this class has been submitted successfully! Happy Learning!"
    ),
  disableCancel: true,
  successText: "Okay",
});

export const zoomLinkNotification = getNotificationData({
  image: incomplete,
  title: "Zoom Link Not Found",
  content: zoomContent,
  successText: "Okay",
  disableCancel: true,
});

export const invoiceLinkNotification = getNotificationData({
  image: incomplete,
  title: "Invoice Not Found",
  content: invoiceContent,
  successText: "Okay",
  disableCancel: true,
});

export const DownloadNotification = getNotificationData({
  type: "info",
  title: "File Download in Progress",
  content: () =>
    renderContent("Please wait while your file is being downloaded..."),
  timer: 3000,
  disableCancel: true,
});

export const onSiteNotification = getNotificationData({
  type: "info",
  title: "Oops!",
  content: () =>
    renderContent(
      "This class is offline therefore does not have a class link!"
    ),
  successText: "Okay",
  disableCancel: true,
});

export const DownloadFileSuccessNotification = getNotificationData({
  type: "success",
  title: "Download Successful",
  content: () => renderContent("Your file has been downloaded successfully."),
  disableCancel: true,
  successText: "Close",
});
