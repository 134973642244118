import React from "react";

import { Modal, Row, Button, Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const Item = ({
  label,
  value,
  id,
  selectedItem = [],
  handleClick = () => {},
}) =>
  selectedItem.some((item) => item === id) ? (
    <Row
      onClick={() => handleClick({ value: id, label: value })}
      justify={"space-between"}
    >
      <Text size={"S"} font={"SEMIBOLD"} title={label} />

      <CheckOutlined />
    </Row>
  ) : (
    <Text
      handleClick={() => handleClick({ value: id, label: value })}
      size={"S"}
      font={"LIGHTER"}
      title={label}
    />
  );

const SelectModal = ({
  show = false,
  isMultiple = false,
  setShow = () => {},
  select = null,
  selectedItem = null,
  handleSelectItem = () => {},
}) => (
  <Modal
    closable
    onCancel={() => setShow(false)}
    maskClosable
    centered
    footer={false}
    width={"22rem"}
    className={[styles.modal, "g-modal"].join(" ")}
    open={show}
  >
    <Row align={"middle"} className="mb-20 mt-10">
      <Space>
        <Text
          size={"M"}
          font={"SEMIBOLD"}
          title={`Select ${select?.title || ""}`}
        />

        {isMultiple && (
          <Text size={"S"} font={"LIGHTER"} title={`(multiple)`} />
        )}
      </Space>
    </Row>

    <div className={styles.content}>
      {select &&
        select?.data &&
        select.data.length > 0 &&
        select.data.map((item) => (
          <Item
            {...item}
            key={item.id}
            name={select?.name}
            handleClick={(e) => handleSelectItem(e, isMultiple)}
            selectedItem={selectedItem[select?.name]}
          />
        ))}
    </div>

    {isMultiple && (
      <Button onClick={() => setShow(false)} className={styles.doneBtn}>
        {"Done"}
      </Button>
    )}
  </Modal>
);

export default React.memo(SelectModal);
