import React, { useState, Fragment, useCallback } from "react";
import { Form } from "antd";
import FlatButton from "src/modules/parent/components/flat-button";
import {
  containsNumbersOrSpecialCharacters,
  defaultCountry,
  validateEmail,
} from "src/modules/parent/utils";
// import gif from "src/modules/parent/assets/img/svg/gif_image_replica.svg";
import * as actions from "src/modules/parent/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ModalComponent from "src/modules/parent/components/modal";
import parsePhoneNumberWithError from "libphonenumber-js";
import DemoBooked from "src/modules/parent/pages/package-details/demo-booked";
import TextInput from "src/modules/parent/components/text-input";
import PhoneWithFlagInput from "src/modules/parent/components/phone-input";
import {
  accountReadyNotification,
  freeClassNotification,
} from "src/modules/parent/utils/notificationData";
import CardsWrapper from "src/modules/parent/components/cards-wrapper";
import actionTypes from "src/modules/parent/store/action-types";
import { increaseDemoCount } from "src/modules/parent/utils";
import {
  inCompleteDataNotification,
  phoneNumberNotification,
  emailNotification,
} from "src/modules/parent/utils/notificationData";
import TiktokPixel from "tiktok-pixel";
import "./index.scss";
import { tiktok_pixel_id } from "src/modules/parent/config/constant";
import {
  registerBtnSignupSuccessModal,
  registerBtnClickSignUpModal,
  makePaymentBtnSignUpSuccess,
  registerBtnSuccessSignUpModal,
  trackEvents,
} from "src/modules/parent/utils/events/query";

TiktokPixel.init(tiktok_pixel_id);

const SignUp = ({ handleLogin = () => {} }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const action = bindActionCreators(actions, dispatch);
  const { profileReducer } = useSelector((state) => state);
  const { showRegisterModal } = useSelector(
    (state) => state.notificationReducer
  );
  const { countries } = profileReducer;
  const [validate, setValidate] = useState({ phone: false, email: false });
  const [country, setCountry] = useState(defaultCountry);
  const [showDemoSuccess, setShowDemoSuccess] = useState(false);
  const [isError, setIsError] = useState("");
  const [user, setUser] = useState({
    email: "",
    name: "",
    phone: "",
    password: "",
    country: "Pakistan",
    user_type: "parent",
  });

  const isValidated = () => {
    if (!user.email || !user.password || !user.name || !user.phone) {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: inCompleteDataNotification,
      });
      setIsError(true);
      return false;
    }
    return true;
  };

  const handleRegisteration = useCallback(
    (type, data) => {
      switch (type) {
        case "demo":
          const { requestDemo } = showRegisterModal;

          action
            .createDemo(requestDemo)
            .then(() => {
              increaseDemoCount();
              setLoading(false);
              setShowDemoSuccess(true);
              action.setRegisterData(null);
            })
            .catch(() => {
              action.setRegisterData(null);
              setLoading(false);
            });
          break;

        case "bookFreeClass":
          const { bookPackage } = showRegisterModal;

          action
            .registerFreeClass(bookPackage)
            .then(() => {
              setLoading(false);
              action.setRegisterData(null);

              dispatch({
                type: actionTypes.NOTIFICATIONDATA,
                payload: freeClassNotification,
              });
            })
            .catch(() => {
              action.setRegisterData(null);
              setLoading(false);
            });
          break;

        case "bookThisClass":
          action.setRegisterData({ ...showRegisterModal, isBookedModal: true });
          break;

        case "teacherChat":
          const { chat } = showRegisterModal;

          window.location.pathname = `/chat/${chat?.teacherId}`;
          break;

        case "registerClass":
          const { bookData } = showRegisterModal;

          actions
            .bookCourse(bookData)
            .then(() => {
              setLoading(false);
              action.setRegisterData(null);

              trackEvents(registerBtnSignupSuccessModal);

              dispatch({
                type: actionTypes.NOTIFICATIONDATA,
                payload: accountReadyNotification(() =>
                  trackEvents(makePaymentBtnSignUpSuccess)
                ),
              });
            })
            .catch(() => {
              action.setRegisterData(null);
              setLoading(false);
            });
          break;

        default:
          break;
      }
    },
    [action, dispatch, showRegisterModal]
  );

  const submitHandler = () => {
    if (isValidated()) {
      setLoading(true);
      const phoneNumber = parsePhoneNumberWithError(user.phone, user.country);
      if (!phoneNumber) {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: phoneNumberNotification,
        });
        setLoading(false);
        return false;
      }
      const validPhoneNumber = phoneNumber.isValid();
      if (!validPhoneNumber) {
        setValidate({ ...validate, phone: true });
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: phoneNumberNotification,
        });
        setIsError(true);
        setLoading(false);
      } else if (!validateEmail(user.email)) {
        setValidate({ ...validate, phone: false, email: true });
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: emailNotification,
        });
        setIsError(true);
        setLoading(false);
      } else {
        setValidate({ ...validate, phone: false, email: false });
        const phoneNumber = parsePhoneNumberWithError(user.phone);
        if (phoneNumber && phoneNumber?.isValid()) {
          const data = {
            ...user,
            phone: phoneNumber?.number,
          };

          trackEvents(registerBtnClickSignUpModal);

          action
            .registerUser(data)
            .then(() => {
              if (showRegisterModal && showRegisterModal.type) {
                trackEvents(registerBtnSuccessSignUpModal);
                const { type } = showRegisterModal;
                handleRegisteration(type, data);
              }
            })
            .catch(() => setLoading(false));
        }
      }
    }
  };

  const countryOnChange = (e) => {
    const searchPhone = countries.find((i) => i.id === e.toString());
    if (searchPhone) {
      setCountry(searchPhone);
      setUser({
        ...user,
        country: searchPhone.title,
        phone: "",
      });
    }
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * When the user clicks "Got it" button after successfully booking a demo,
   * this function redirects the user to the dashboard.
   * @function
   */
  /******  90d874d4-6048-4cb8-b4d2-0ebffbe42209  *******/
  const redirectToDashboard = () => {
    setShowDemoSuccess(false);
    window.location.pathname = "/";
  };

  return (
    <div className={"auth-form mt-20"}>
      <ModalComponent
        closable={false}
        maskClosable={false}
        isModalVisible={showDemoSuccess}
        onCancel={redirectToDashboard}
        bodyClassName={"d-flex justify-content-center align-items-center"}
        Component={
          <DemoBooked
            okHandler={redirectToDashboard}
            packageTitle={showRegisterModal?.package?.title}
            teacherName={showRegisterModal?.package?.teacher?.name}
          />
        }
      />

      <CardsWrapper>
        <Fragment>
          <div className={"form-wrapper"}>
            <div className={"header-text"}>Create Account</div>
            <Form className={"text-align-center"}>
              <Form.Item
                hasFeedback={
                  (!user?.email && isError) || validate.email ? true : false
                }
                validateStatus={
                  (!user?.email && isError) || validate.email
                    ? "error"
                    : "success"
                }
              >
                <TextInput
                  type={"email"}
                  required={true}
                  label={"Email"}
                  allowClear={true}
                  placeholder={"Email"}
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
              </Form.Item>

              <Form.Item
                hasFeedback={user.name.length < 1 && isError ? true : false}
                validateStatus={
                  user.name.length < 1 && isError ? "error" : "success"
                }
              >
                <TextInput
                  label={"Name"}
                  required={true}
                  allowClear={true}
                  placeholder={"Name"}
                  value={user.name}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!containsNumbersOrSpecialCharacters(value)) {
                      setUser({ ...user, name: value });
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                hasFeedback={
                  (!user?.phone && isError) || validate.phone ? true : false
                }
                validateStatus={
                  (!user?.phone && isError) || validate.phone
                    ? "error"
                    : "success"
                }
              >
                <PhoneWithFlagInput
                  allowClear={true}
                  showSearch={true}
                  label={"Contact Number"}
                  inputPlaceholder={"Phone"}
                  value={user?.phone}
                  selectedId={defaultCountry.id}
                  countryOnChange={countryOnChange}
                  countryPlaceholder={"Select"}
                  selectedCountry={country?.value}
                  onChange={(e) => setUser({ ...user, phone: e.target.value })}
                />
              </Form.Item>

              <Form.Item
                hasFeedback={user.password.length < 1 && isError ? true : false}
                validateStatus={
                  user.password.length < 1 && isError ? "error" : "success"
                }
              >
                <TextInput
                  required={true}
                  type={"password"}
                  label={"Password"}
                  placeholder={"Password"}
                  value={user.password}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                />
              </Form.Item>

              <FlatButton
                isForm={true}
                width={"200px"}
                title={"Register"}
                loading={loading}
                onClick={submitHandler}
                className={"mt-25 mb-20"}
              />

              <div className="white-color mb-30">
                Already have an account?{" "}
                <span
                  onClick={handleLogin}
                  className={"link-text cursor-pointer"}
                >
                  Login now
                </span>
              </div>
            </Form>
          </div>

          {/* <div className={"image-rocket"}>
            <img src={gif} alt="GIF" />
          </div> */}
        </Fragment>
      </CardsWrapper>
    </div>
  );
};

export default SignUp;
