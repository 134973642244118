import icon1 from "./assets/pngs/icon1.png";
import icon2 from "./assets/pngs/icon2.png";
import icon3 from "./assets/pngs/icon3.png";
import icon4 from "./assets/pngs/icon4.png";
import icon5 from "./assets/pngs/icon5.png";
import icon1Color from "./assets/pngs/icon1Color.png";
import icon2Color from "./assets/pngs/icon2Color.png";
import icon3Color from "./assets/pngs/icon3Color.png";
import icon4Color from "./assets/pngs/icon4Color.png";
import icon5Color from "./assets/pngs/icon5Color.png";

export const tabs = [
  {
    id: "0",
    title: "Overview",
    type: "no-collapse",
    isCollapsable: false,
    tabName: "overview",
  },
  {
    id: "1",
    title: "Invoice",
    type: "no-collapse",
    isCollapsable: false,
    tabName: "invoice",
  },
  {
    id: "2",
    title: "Baseline Forms",
    type: "no-collapse",
    isCollapsable: false,
    tabName: "baseline_forms",
  },
  {
    id: "3",
    title: "Progress Reports",
    type: "no-collapse",
    isCollapsable: false,
    selected: true,
    tabName: "progress_reports",
  },
  {
    id: "4",
    title: "Certificates",
    type: "no-collapse",
    isCollapsable: false,
    tabName: "certificates",
  },
  {
    id: "5",
    title: "Notes and Resources",
    type: "no-collapse",
    isCollapsable: false,
    tabName: "notes",
  },
];

export const tabMobile = [
  {
    id: "0",
    icon: icon1,
    iconColor: icon1Color,
  },
  {
    id: "1",
    icon: icon2,
    iconColor: icon2Color,
  },
  {
    id: "3",
    icon: icon3,
    iconColor: icon3Color,
  },
  {
    id: "4",
    icon: icon4,
    iconColor: icon4Color,
  },
  {
    id: "2",
    icon: icon5,
    iconColor: icon5Color,
  },
];
