import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import Header from "../../components/header";
import Footer from "../../components/footer";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AppLoader from "../../components/app-loader";
import styles from "./styles.module.css";
import InvoiceCard from "./components/invoiceCard";
import ModalComponent from "../../components/modalComponent";
import noData from "src/modules/parent/assets/svgs/nodata.svg";
import { Link } from "react-router-dom";
import FabIcon from "../../components/FabIcon";
import assistMe from "src/modules/parent/assets/svgs/assistme.svg";
import { groupArrayOfObjects } from "../../utils";
import actionTypes from "../../store/action-types";
import { invoiceLinkNotification } from "../../utils/notificationData";
import Guidelines from "../../components/guidelines";
import TiktokPixel from "tiktok-pixel";
import { tiktok_pixel_id } from "src/modules/parent/config/constant";
import {
  invoicePayNowBtnRedirect,
  trackEvents,
} from "../../utils/events/query";

TiktokPixel.init(tiktok_pixel_id);

const Invoices = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showDropOut, setShowDropOut] = useState(false);
  const [myInvoices, setMyInvoices] = useState([]);
  const [link, setLink] = useState("");
  const [viewRoute, setViewRoute] = useState("");
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [showAssist, setShowAssist] = useState(false);
  const action = bindActionCreators(actions, dispatch);
  const { loadingInvoices, invoices } = useSelector(
    (state) => state.profileReducer
  );

  useEffect(() => {
    action.fetchInvoices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoices && invoices.length) {
      const data = groupArrayOfObjects(invoices);
      setMyInvoices(data);
    }
  }, [invoices]);

  const handleClose = () => {
    setShow(false);
    setShowDropOut(false);
    setShowAssist(false);
  };

  const showPayModal = ({ link, view }) => {
    setShow(true);
    setLink(link);
    setViewRoute(view);
  };

  const showDropoutModal = () => {
    setShowDropOut(true);
  };

  const showAssistModal = () => setShowAssist(true);

  const handlePayNow = () => {
    if (link) {
      window.open(link, "_blank");

      trackEvents(invoicePayNowBtnRedirect);
    } else {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: invoiceLinkNotification,
      });
    }
    // }
  };

  const descriptionContent = () => (
    <p className={styles.paymentText}>
      {
        "Looks like you have not made the payment for this course yet. Please ignore this message if you have recently made a payment. Our verification timeline is up to 8 hours to update the status of your invoice Thank you!"
      }
    </p>
  );

  const dropoutContent = () => (
    <p className={styles.paymentText}>
      {"Looks like this student has dropped out of this course"}
    </p>
  );

  const assistContent = () => (
    <div className="mt-20 mb-20">
      <p>{"Please Contact +923474106202, for queries."}</p>
      {/* <p>{"For international: +923474106202"}</p> */}
    </div>
  );

  return (
    <div className={"bg-light-blue"}>
      <ModalComponent
        show={show}
        title={"Payment Due"}
        type={"error"}
        handleClose={handleClose}
        link={viewRoute}
        handleSuccessBtn={handlePayNow}
        linkText={"View Invoice"}
        successBtnText={"Pay Now"}
        content={descriptionContent}
      />

      <ModalComponent
        show={showDropOut}
        title={"Dropout"}
        type={"error"}
        handleClose={handleClose}
        disableCancelBtn
        handleSuccessBtn={handleClose}
        successBtnText={"Okay"}
        content={dropoutContent}
      />

      <ModalComponent
        show={showAssist}
        title={"For Further Assistance"}
        handleClose={handleClose}
        cancelBtnText={"Close"}
        handleCancelBtn={handleClose}
        handleSuccessBtn={handleClose}
        successBtnText={"Okay"}
        content={assistContent}
      />

      <Header />

      <div className="app-container mb-50">
        <div className={[styles.invoice_table, "pt-45 pb-45"].join(" ")}>
          <Row justify={"start"}>
            <Col>
              <div className={styles.routeName}>
                <Link to={"/"}>{"Home"}</Link>
              </div>
            </Col>
            <div className={styles.slash}>{"/"}</div>
            <Col>{"Invoices"}</Col>
          </Row>

          <Row justify={"center"}>
            <div className={["mt-20 mb-20 font-24", styles.heading].join(" ")}>
              {"Billing and Invoices"}
            </div>
          </Row>

          <Row justify={"center"}>
            <p className={styles.viewAllText}>
              {
                "View all your invoices and receipts and make sure you make all your payments on time! Read "
              }
              <span
                onClick={() => setShowGuidelines(true)}
                className={styles.terms}
              >
                {"Terms & Conditions"}
              </span>
            </p>
          </Row>

          <Row>
            <div className={styles.heading}>{"Courses"}</div>
          </Row>

          {loadingInvoices ? (
            <div className="d-flex justify-content-center mt-50 mb-50 pt-50 pb-50">
              <AppLoader className="font-24" />
            </div>
          ) : (
            <div>
              {invoices && invoices?.length === 0 ? (
                <Row justify={"center"} className="mt-50">
                  <img src={noData} alt={"nodata"} />
                </Row>
              ) : (
                <>
                  {myInvoices &&
                    myInvoices.length > 0 &&
                    myInvoices.map((invoice, index) => (
                      <InvoiceCard
                        {...invoice}
                        key={index}
                        handleDropOut={showDropoutModal}
                        ItemIndex={index}
                        handlePay={showPayModal}
                      />
                    ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <Footer />

      <FabIcon icon={assistMe} handleClick={showAssistModal} />

      <Guidelines setShow={setShowGuidelines} show={showGuidelines} />
    </div>
  );
};

export default Invoices;
