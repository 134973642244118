import React from "react";

import { Space } from "antd";
import { isBrowser } from "react-device-detect";

import Button from "src/modules/parent/components/flat-button";
import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const TeacherCard = ({
  id,
  picture,
  onHandleClick = () => {},
  onHandleReview = () => {},
  onHandleNameClick = () => {},
  width = 260,
  height = 260,
  name,
}) => (
  <div
    className={styles.teacherCardWrapper}
    style={{ textAlign: isBrowser ? "start" : "center" }}
  >
    <div
      className={styles.teacherImage}
      style={{ width: width, height: height }}
    >
      <img src={picture} alt={"teacherImage"} />
    </div>

    <Text
      color={"#005eff"}
      size={"M"}
      font="SEMIBOLD"
      lHeight={"S"}
      handleClick={() => onHandleNameClick(id)}
      className={styles.name}
      customStyles={{ width: width - 30 }}
    >
      {name}
    </Text>

    <Space className={"mt-10"} direction={"vertical"}>
      <Button
        className={styles.btn}
        height={30}
        width={width - 30}
        onClick={() => onHandleClick(id)}
        type={"Quaternary"}
        title={"Chat"}
      />

      <Button
        className={styles.btn}
        type={"Secondary"}
        height={30}
        width={width - 30}
        title={"Post a Review"}
        onClick={() => onHandleReview({ id, name })}
      />
    </Space>
  </div>
);

export default React.memo(TeacherCard);
