import React from "react";

import { Col, Row, Button } from "antd";

import Text from "src/modules/parent/components/text";
import bannerImg from "../../assets/webp/banner.webp";

import styles from "./styles.module.css";
import { isMobile } from "react-device-detect";

const subjects = ["English", "Math", "Coding", "NAPLAN"];

const BannerComponent = ({ handleClick = () => {} }) => (
  <Row gutter={[0, 20]} className={styles.bannerWrapper}>
    <Col
      xxl={{
        span: 15,
        order: 1,
      }}
      xl={15}
      lg={12}
      md={{
        span: 24,
        order: 2,
      }}
      sm={{
        span: 24,
        order: 2,
      }}
      xs={{
        span: 24,
        order: 2,
      }}
    >
      <div className={[styles.bannerContent, "app-container"].join(" ")}>
        <Text
          size={"XXL4"}
          font={"SEMIBOLD"}
          lHeight={"S"}
          color={"#696969"}
          center={isMobile}
          title={"World class online tutoring at a fraction of the cost"}
        />

        <div className={styles.list}>
          {subjects.map((i, index) => {
            return (
              <span className={styles.point} key={index.toString()}>
                <span>•</span>
                {i}
              </span>
            );
          })}
        </div>

        <Button
          loading={false}
          onClick={handleClick}
          className={styles.searchButton}
        >
          {"Book a Free Demo"}
        </Button>
      </div>
    </Col>

    <Col
      xxl={{
        span: 9,
        order: 2,
      }}
      xl={{
        span: 9,
        order: 2,
      }}
      lg={{
        span: 12,
        order: 2,
      }}
      md={{
        span: 24,
        order: 1,
      }}
      sm={{
        span: 24,
        order: 1,
      }}
      xs={{
        span: 24,
        order: 1,
      }}
    >
      <Row justify={"center"} className={isMobile ? "p-30" : "pr-30"}>
        <img
          src={bannerImg}
          style={{ maxWidth: "520px" }}
          width={"100%"}
          alt={"banner"}
        />
      </Row>
    </Col>
  </Row>
);

export default React.memo(BannerComponent);
