import { object, string, array } from "yup";

const nameRegExp = /^[^\d]*$/;

export const registrationFormSchema = object().shape({
  parent_name: string()
    .matches(nameRegExp, "Parent Name should not contain any number!")
    .required("Parent Name is required"),
  email: string()
    .email("Parent Email is invalid!")
    .trim()
    .nullable()
    .required("Parent Email is required"),
  phone: string().required("Phone number is required"),
  country: string().required("Country is required"),
  password: string().required("Password is required"),
});

export const loginFormSchema = object().shape({
  email: string()
    .email("Email is invalid!")
    .trim()
    .nullable()
    .required("Email is required"),
  password: string().required("Password is required"),
});

const studentRegistration = object().shape({
  name: string().required("Student Name is required"),
  gender: string().required("Gender is required"),
  dob: string().required("Date of Birth is required"),
  level: string().required("Grade is required"),
});

export const studentRegistrationSchema = object().shape({
  students: array()
    .of(studentRegistration)
    .min(1, "Minimum 1 Student is required"),
});
