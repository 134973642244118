import React, { useCallback, useEffect, useState } from "react";

import { isBrowser, isMobile } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";
import slugify from "@sindresorhus/slugify";
import { logEvent } from "firebase/analytics";

import Header from "src/modules/parent/components/header";
import { analytics } from "src/modules/parent/config/firebase";
import Footer from "src/modules/parent/components/footer";
import { reviewsData } from "../../staticData";
import { RequestDemoModal } from "src/modules/parent/pages/home/components/modal";
import ContactModal from "src/modules/parent/components/modal/contactModal";
import {
  BannerComponent,
  WhyDNL,
  Tutors,
  WhatWeDo,
  CourseFilter,
  ClassSnippet,
  Review,
  SelectModal,
  PackageModal,
  GradesModal,
} from "src/modules/parent/pages/international/components";
import reviewMobile from "src/modules/parent/pages/international/assets/webp/review_mobile.webp";
import reviewImg from "src/modules/parent/pages/international/assets/webp/review.webp";
import { useFetchPackages } from "../../apiController";
import { naplanGrades } from "../../staticData";
import YoutubeModal from "src/modules/parent/components/modal/youtube-modal";
import { getTimeSlots } from "src/modules/parent/utils";
import { IS_DEV_ENV } from "src/modules/parent/config/constant";
import { FloatingButton } from "../../../olevels/components";

import * as actions from "src/modules/parent/store/actions";

import styles from "../../styles.module.css";
import events from "src/modules/parent/utils/events";
import {
  naplanBookAFreeDemoBtnHero,
  naplanBookDemoTeacherCard,
  naplanCarouselCardClick,
  naplanGradeFilterSelect,
  trackEvents,
} from "src/modules/parent/utils/events/query";

const whatsappMobile = "https://wa.me/";
const whatsappWeb = "https://web.whatsapp.com/send";
const influencerPageView = events.google.naplan.influencerPageView;

const gradesData = {
  id: "1",
  title: "Grade",
  name: "grades",
  description: "of the student",
  data: naplanGrades,
};

const videoIds = { browser: "6aeFzkarn0Y", mobile: "NnKk1e9HE-w" };

const Naplan = () => {
  const { data } = useFetchPackages({});
  const navigate = useNavigate();
  const [showContactModal, setShowContactModal] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiItems, setApiItems] = useState({});
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [searchedData, setSearchedData] = useState(null);
  const [show, setShow] = useState(false);
  const [packageDetails, setPackageDetails] = useState(null);
  const [timeSlots, setTimeSlots] = useState(null);
  const [showGrade, setShowGrade] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [params] = useSearchParams();
  const isInfluencer = params.get("influencer");

  useEffect(() => {
    if (isInfluencer) {
      localStorage.setItem("influencer_naplan", isInfluencer);

      if (!IS_DEV_ENV) {
        logEvent(analytics, influencerPageView, {
          details: isInfluencer,
        });
      }
    }
  }, [isInfluencer]);

  const handleSearch = useCallback(() => {
    setLoading(true);

    const params = {
      ...apiItems,
    };

    actions
      .getNaplanPackages(params)
      .then((response) => {
        setLoading(false);
        setSearchedData(response);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [apiItems]);

  useEffect(() => {
    if (selectedItem) {
      handleSearch();
    }
  }, [handleSearch, selectedItem]);

  useEffect(() => {
    if (videoId) {
      setShowModal(true);
    }
  }, [videoId]);

  const handleSelectItem = useCallback(({ value, label }) => {
    setSelectedItem({
      grades: [value],
    });

    setApiItems({
      grades: [label],
    });

    trackEvents(naplanGradeFilterSelect);
    setShowSelectModal(false);
  }, []);

  const openGradesModal = useCallback(() => {
    setShowSelectModal(true);
  }, []);

  const handleClear = useCallback(() => {
    setSelectedItem({});
    setApiItems({});
    setSearchedData(null);
  }, []);

  const navigateToDetails = (id, title) => {
    const slugTitle = title ? slugify(title) : "";
    const route = `/courses/${slugTitle}/${id}`;

    trackEvents(naplanCarouselCardClick);
    navigate(route);
  };

  const handleBookADemo = useCallback((data) => {
    if (data && data?.time_slot) {
      const time_slots = getTimeSlots(data?.time_slot);

      trackEvents(naplanBookDemoTeacherCard);
      setPackageDetails(data);
      setTimeSlots(time_slots);
      setShowDemoModal(true);
    }
  }, []);

  const handleCheckGrades = useCallback(
    (data) => {
      if (selectedItem && selectedItem.grades && selectedItem.grades.length) {
        handleBookADemo(data);
      } else {
        setShowGrade(true);
      }
    },
    [handleBookADemo, selectedItem]
  );

  const onSearch = useCallback(() => {
    const selectedData = naplanGrades.find((i) => i.id === selectedGrade);

    if (selectedData) {
      handleSelectItem({ value: selectedData.id, label: selectedData.label });
      setShowGrade(false);
    }
  }, [handleSelectItem, selectedGrade]);

  const handleWhatsApp = () => {
    if (isBrowser) {
      window.open(`${whatsappWeb}?phone=+923460086795`);
    } else {
      window.open(`${whatsappMobile}923460086795`);
    }
  };

  const handleClickContact = useCallback(() => {
    if (!isBrowser) handleWhatsApp();
    else setShowContactModal(true);
  }, []);

  const handleShowAndTrack = () => {
    trackEvents(naplanBookAFreeDemoBtnHero);
    setShow(true);
  };

  return (
    <>
      {videoId && showModal && (
        <YoutubeModal
          show={showModal}
          url={videoId}
          setShow={(data) => {
            setShowModal(data);
            setVideoId("");
          }}
        />
      )}

      <Header />

      <BannerComponent handleClick={handleShowAndTrack} />

      <WhyDNL />

      <img
        src={isMobile ? reviewMobile : reviewImg}
        width={"100%"}
        alt={"Reviews"}
      />

      <WhatWeDo />

      <CourseFilter
        data={searchedData || data}
        handleClear={handleClear}
        selectedItem={selectedItem}
        loading={loading}
        handleBookADemo={handleCheckGrades}
        handleSelectField={openGradesModal}
        handleSearch={handleSearch}
        handleClickGrade={handleSelectItem}
        handleShowModal={setVideoId}
        handleClickCard={navigateToDetails}
      />

      <Tutors handleClick={handleShowAndTrack} />

      {isMobile && <div className={styles.hr} />}

      <ClassSnippet videoLink={videoIds} />

      <Review isNaplan reviewsData={reviewsData} />

      <Footer />

      <SelectModal
        show={showSelectModal}
        select={gradesData}
        handleSelectItem={handleSelectItem}
        selectedItem={selectedItem}
        setShow={setShowSelectModal}
      />

      <PackageModal
        show={show}
        setShow={setShow}
        handleWhatsApp={handleWhatsApp}
        onHandleTeacheVideo={setVideoId}
        handleBookADemo={handleBookADemo}
        handleDetails={navigateToDetails}
      />

      {showDemoModal && (
        <RequestDemoModal
          isVisible={showDemoModal}
          onCancel={() => setShowDemoModal(false)}
          packageData={packageDetails}
          timeSlots={timeSlots}
        />
      )}

      <GradesModal
        show={showGrade}
        setShow={setShowGrade}
        setGrade={setSelectedGrade}
        grade={selectedGrade}
        onSearch={onSearch}
      />

      <FloatingButton handlePress={handleClickContact} />

      <ContactModal
        show={showContactModal}
        title={"Contact Representative"}
        name={"Aiman Ayubi:"}
        color={"#2CA58D"}
        number={"+923460086795"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal(false)}
      />
    </>
  );
};

export default React.memo(Naplan);
