import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./../styles.module.css";
import { Menu } from "antd";
import * as actions from "../../../store/actions";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

const ProfileMenuOptions = ({ logout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);

  const menuOptions = [
    {
      id: 1,
      name: "Profile",
      clickHandler: () => navigate("/profile"),
    },
    {
      id: 2,
      name: "My Children",
      clickHandler: () => navigate("/my-children"),
    },
    {
      id: 3,
      name: "Invoices",
      clickHandler: () => navigate("/invoices"),
    },
    {
      id: 4,
      name: "Change Password",
      clickHandler: () => navigate("/change-password"),
    },
    {
      id: 5,
      name: "FAQs",
      clickHandler: () => navigate("/faqs"),
    },
    {
      id: 6,
      name: "Contact Us",
      clickHandler: () => action.setContactUsModal(true),
    },
    {
      id: 7,
      name: "Log out",
      clickHandler: logout,
    },
  ];

  return (
    <Fragment>
      <Menu className={styles.menu_card}>
        {menuOptions.map((option) => (
          <Menu.Item
            key={option.id}
            onClick={option.clickHandler}
            className={styles.profile_menu_item}
          >
            <span>{option.name}</span>
          </Menu.Item>
        ))}
      </Menu>
    </Fragment>
  );
};

export default ProfileMenuOptions;
