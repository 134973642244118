import React from "react";

import { Button, Space } from "antd";

import Text from "src/modules/parent/components/text";
import ModalComponent from "src/modules/parent/components/modal";
import InfoImg from "src/modules/parent/assets/notificationSvgs/info.svg";

import styles from "../styles.module.css";

const InEligibleModal = ({ show = true, setShow = () => {} }) => (
  <ModalComponent
    onCancel={() => setShow(false)}
    closable={false}
    isModalVisible={show}
  >
    <Space
      className={"w-100 mt-20"}
      size={"large"}
      align={"center"}
      direction={"vertical"}
    >
      <Text
        size={"XXL"}
        className={"mb-20"}
        center
        font={"SEMIBOLD"}
        title={"Oops! Ineligible"}
      />

      <img src={InfoImg} width={100} alt={"Success"} />

      <Text
        size={"S"}
        center
        lHeight={"S"}
        font={"LIGHTER"}
        title={
          "Thank you for your interest in the Trivia Competition 2025! However, this competition is not open to students in your grade level."
        }
      />

      <Button onClick={() => setShow(false)} className={styles.successButton}>
        {"Okay"}
      </Button>
    </Space>
  </ModalComponent>
);

export default InEligibleModal;
