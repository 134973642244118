import React from "react";

import { Button, Col, Row } from "antd";
import { boards } from "src/modules/parent/pages/olevels/components/banner/staticData";
import Text from "src/modules/parent/components/text";
import SelectInput from "src/modules/parent/components/select-input";

import styles from "../styles.module.css";

const StepNo5 = ({ handleNext, board, setBoard }) => (
  <>
    <p className={styles.description}>
      {"Answer some easy questions to help us find your teacher!"}
    </p>

    <div className={"mt-30"}>
      <Text
        size={"L"}
        className={"mb-5"}
        color={"#373737"}
        title={"Preferred Board of Examination"}
      />

      <Col span={24}>
        <SelectInput
          allowClear
          className={"w-95"}
          value={board}
          placeholder={"Select Board"}
          onChange={setBoard}
          options={boards}
        />
      </Col>

      <Row justify={"center"}>
        <Button onClick={handleNext} className={styles.nextButton}>
          {"Next"}
        </Button>
      </Row>
    </div>
  </>
);

export default React.memo(StepNo5);
