import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/footer";
import Header from "../../components/header";
import ChildDetails from "../../components/child-details";
import OnboardHeader from "./onboard-header";

const AddChild = () => {
  const { isOnboarding } = useSelector((state) => state.authReducer);

  return (
    <Fragment>
      {isOnboarding ? (
        <Fragment>
          <div className="inner-container">
            <OnboardHeader />
            <ChildDetails isOnboarding={true} />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Header />
          <div className="inner-container">
            <ChildDetails />
          </div>
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddChild;
