import React, { Fragment, useEffect, useRef, useState } from "react";

import {
  Col,
  Row,
  DatePicker,
  message as MsgToast,
  Space,
  Form,
  Select,
} from "antd";
import parsePhoneNumberWithError from "libphonenumber-js";
import { Formik } from "formik";
import igniteForm from "./assets/ignite_form.svg";
import Text from "src/modules/parent/components/text";
import TextInput from "src/modules/parent/components/text-input";
import logo from "./assets/dnl_logo_pink.svg";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import RadioGroupButton from "src/modules/parent/components/radio-group-button";
import styles from "./styles.module.css";
import { igniteFormSchema } from "./schema";
import ReactPixel from "react-facebook-pixel";
import {
  fb_pixel_id_ignite,
  fb_pixel_id_examPrep,
} from "src/modules/parent/config/constant";
import { defaultCountry } from "src/modules/parent/utils";
import PhoneWithFlagInput from "src/modules/parent/components/phone-input";
import {
  educationalQualification,
  workStatus,
  teachingExperience,
  aboutUs,
} from "../../utils/ignite";
import * as actions from "src/modules/parent/store/actions";
import { useSearchParams } from "react-router-dom";
import actionTypes from "src/modules/parent/store/action-types";
import {
  contactNumberNotification,
  ignitePhonehoneValidateNotification,
} from "src/modules/parent/utils/notificationData";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/modal/confirm-modal";
import events from "../../utils/events";
import { igniteFormSubmit, trackEvents } from "../../utils/events/query";

ReactPixel.init(fb_pixel_id_ignite);
ReactPixel.init(fb_pixel_id_examPrep);

const igniteSubmitApplicationFb = events.facebook.ignite.submitApplication;

const initialValues = {
  name: "",
  email: "",
  whatsapp_no: "",
  city: "",
  contact_no: "",
  country: "",
  dob: "",
  address: "",
  qualification: "Undergraduate",
  major: "",
  education_year: "",
  university: "",
  personal_statement: "",
  is_currently_working: "1",
  is_taught_online: "1",
  is_internet: "1",
  teaching_experience: "No Experience",
  have_laptop: "1",
  purchasing: "Level 1",
  about_us: "Influencer",
};

const buttonStyles = {
  color: "#fff",
  border: "none",
  height: "40px",
  fontSize: "16px",
  fontWeight: "600",
  borderRadius: "10px",
  backgroundColor: "#EA367F",
  padding: "0px 35px",
  minWidth: "190px",
};

const IgniteForm = () => {
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const refParam = searchParams.get("utm_source");
  const [submitModal, setSubmitModal] = useState(false);
  const [source, setSource] = useState();
  const [country, setCountry] = useState(defaultCountry);
  const { countries } = useSelector((state) => state.profileReducer);
  const navigate = useNavigate();
  const dateFormat = "YYYY/MM/DD";

  useEffect(() => {
    if (refParam && refParam === "bechlo") {
      setSource(refParam);
    }
  }, [refParam]);

  const onSubmit = (values, action) => {
    try {
      // Parse the phone number and validate it
      const phoneNumber = parsePhoneNumberWithError(values.contact_no);
      const validPhoneNumber = phoneNumber.isValid();

      if (!validPhoneNumber) {
        action.setSubmitting(false);

        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: contactNumberNotification,
        });
        scrollToField("contact_no");
        return;
      }

      // Dispatch notification data
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: ignitePhonehoneValidateNotification(
          phoneNumber.number,
          (number) =>
            handleFormSubmission({ ...values, contact_no: number }, action),
          () => {
            scrollToField("contact_no");
            action.setSubmitting(false);
          }
        ),
      });
    } catch (error) {
      console.error("Error in phone number validation:", error);
    }
  };

  // Helper function to handle form submission
  const handleFormSubmission = (values, action) => {
    const data = {
      ...values,
    };

    if (source) {
      data.source = source;
    }

    actions
      .igniteFormSubmission(data)
      .then((response) => {
        action.setSubmitting(false);

        ReactPixel.trackSingle(fb_pixel_id_examPrep, igniteSubmitApplicationFb);
        MsgToast.success("Form Successfully Submitted!");
        setSubmitModal(true);

        // Trigger tracking events
        trackEvents(igniteFormSubmit);
      })
      .catch((error) => handleSubmissionError(error, action));
  };

  // Helper function to handle submission errors
  const handleSubmissionError = (error, action) => {
    action.setSubmitting(false);

    const { message } = error?.response?.data || {};
    if (
      message &&
      typeof message === "object" &&
      Object.keys(message).length > 0
    ) {
      const [firstError] = Object.values(message);
      MsgToast.error(firstError);
    } else {
      MsgToast.error(message || "An unexpected error occurred.");
    }
  };

  const countryOnChange = (e) => {
    const searchPhone = countries.find((i) => i.id === e.toString());
    //console.log("countryOnChange====>",searchPhone)
    if (searchPhone) {
      setCountry(searchPhone);
    }
  };

  const getError = (error, touched) => {
    //console.log(error)
    return touched ? error : undefined;
  };

  const onHandleErrors = async (errors, handleSubmit, setTouched) => {
    //console.log(errors);
    const touchedFields = Object.keys(initialValues).reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});
    // Mark all fields as touched
    setTouched(touchedFields, false);

    // Validate the form and handle errors
    const validationErrors = await formikRef.current.validateForm();
    console.log(`error----->${Object.keys(validationErrors)}`);
    if (Object.keys(validationErrors).length) {
      const firstErrorField = Object.keys(validationErrors)[0];

      scrollToField(firstErrorField);
      return MsgToast.error("Please Provide the Required Credentials");
    }

    // If no errors, proceed with form submission
    handleSubmit();
  };

  const fieldRefs = useRef({}); // To store references to the fields

  const scrollToField = (fieldName) => {
    const fieldRef = fieldRefs.current[fieldName];
    if (fieldRef) {
      fieldRef.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className={"inner-container"}>
      <ConfirmModal
        isModalVisible={submitModal}
        onCancel={() => {
          setSubmitModal(false);
          navigate(-1);
        }}
        bodyStyle={{ textAlign: "center" }}
        closable
        footer={false}
        description={
          "Your form has been submitted successfully. A team member will reach out to you soon."
        }
      />

      <Row gutter={[20, 20]} align={"middle"} justify={"center"}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <img src={logo} alt={"dnl_logo"} />
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <div className={styles.igniteFormImage}>
            <img src={igniteForm} alt={"ignite_form"} />
          </div>
        </Col>
      </Row>

      <Row className={"mt-20 mb-20"}>
        <h2 className="sub-title font-weight-600">{"BASIC INFO"}</h2>
      </Row>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validateOnMount
        validationSchema={igniteFormSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          setFieldValue,
          errors,
          isSubmitting,
          touched,
          handleSubmit,
          handleBlur,
        }) => (
          <Fragment>
            <Row className="mb-20" gutter={[20, 20]}>
              <Col
                ref={(el) => (fieldRefs.current.name = el)}
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Full Name:"}
                  <span style={{ color: "red" }}>*</span>
                </Text>

                <TextInput
                  allowClear
                  value={values.name}
                  name={"name"}
                  style={{
                    borderColor: errors.name && touched.name ? "#be123c" : "",
                    backgroundColor:
                      errors.name && touched.name ? "#fff1f2" : "white",
                  }}
                  onChange={(e) => {
                    setFieldValue("name", e.target.value);
                  }}
                  onBlur={handleBlur}
                  error={getError(errors.name, touched.name)}
                />
              </Col>

              <Col
                ref={(el) => (fieldRefs.current.email = el)}
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Email:"}
                  <span style={{ color: "red" }}>*</span>
                </Text>

                <TextInput
                  name={"email"}
                  allowClear
                  value={values.email}
                  style={{
                    borderColor: errors.email && touched.email ? "#be123c" : "",
                    backgroundColor:
                      errors.email && touched.email ? "#fff1f2" : "white",
                  }}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  onBlur={handleBlur}
                  error={getError(errors.email, touched.email)}
                />
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col
                ref={(el) => (fieldRefs.current.dob = el)}
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Date of Birth:"}
                  <span style={{ color: "red" }}>*</span>
                </Text>

                <DatePicker
                  name={"dob"}
                  style={{
                    width: "100%",
                    borderColor: errors.dob && touched.dob ? "#be123c" : "",
                    backgroundColor:
                      errors.dob && touched.dob ? "#fff1f2" : "white",
                  }}
                  className={styles.text_input}
                  format={dateFormat}
                  placeholder={""}
                  onChange={(e) => setFieldValue("dob", e)}
                  onBlur={handleBlur}
                  disabledDate={(current) => current && current > new Date()} // Disable future dates
                />
                {errors.dob && touched.dob && (
                  <Text className="mt-8" color="RED" size="XS">
                    {errors.dob}
                  </Text>
                )}
              </Col>

              <Col
                ref={(el) => (fieldRefs.current.contact_no = el)}
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Contact No:"}
                  <span style={{ color: "red" }}>*</span>
                </Text>

                <Form.Item>
                  <PhoneWithFlagInput
                    name={"contact_no"}
                    style={{
                      borderColor:
                        errors.contact_no && touched.contact_no
                          ? "#be123c"
                          : "",
                      backgroundColor:
                        errors.contact_no && touched.contact_no
                          ? "#fff1f2"
                          : "white",
                    }}
                    allowClear={true}
                    showSearch={true}
                    inputPlaceholder={"Phone"}
                    value={values.contact_no}
                    selectedId={defaultCountry.id}
                    countryOnChange={countryOnChange}
                    countryPlaceholder={"Select"}
                    selectedCountry={country?.value}
                    onChange={(e) => {
                      setFieldValue("contact_no", e.target.value);
                      setFieldValue("country", country.title);
                    }}
                    onBlur={handleBlur}
                    error={getError(errors.contact_no, touched.contact_no)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col
                ref={(el) => (fieldRefs.current.whatsapp_no = el)}
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Whatsapp No:"}
                  <span style={{ color: "red" }}>*</span>
                </Text>

                <TextInput
                  name={"whatsapp_no"}
                  style={{
                    borderColor:
                      errors.whatsapp_no && touched.whatsapp_no
                        ? "#be123c"
                        : "",
                    backgroundColor:
                      errors.whatsapp_no && touched.whatsapp_no
                        ? "#fff1f2"
                        : "white",
                  }}
                  allowClear
                  //type={"number"}
                  value={values.whatsapp_no}
                  onChange={(e) => setFieldValue("whatsapp_no", e.target.value)}
                  onBlur={handleBlur}
                  error={getError(errors.whatsapp_no, touched.whatsapp_no)}
                />
              </Col>

              <Col
                ref={(el) => (fieldRefs.current.city = el)}
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"City:"}
                  <span style={{ color: "red" }}>*</span>
                </Text>

                <TextInput
                  name={"city"}
                  style={{
                    borderColor: errors.city && touched.city ? "#be123c" : "",
                    backgroundColor:
                      errors.city && touched.city ? "#fff1f2" : "white",
                  }}
                  allowClear
                  value={values.city}
                  onChange={(e) => setFieldValue("city", e.target.value)}
                  onBlur={handleBlur}
                  error={getError(errors.city, touched.city)}
                />
              </Col>
              {/* </Row> */}
              {/* </Col> */}
            </Row>

            <Row className={"mt-20 mb-20"}>
              <h2 className="sub-title font-weight-600">
                {"EDUCATION AND WORK EXPERIENCE"}
              </h2>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col span={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Please provide your highest educational qualification."}
                </Text>

                <RadioGroupButton
                  value={values?.qualification}
                  direction={"vertical-align"}
                  onChange={(e) =>
                    setFieldValue("qualification", e.target.value)
                  }
                  options={educationalQualification}
                  // error={getError(errors.qualification, touched.qualification)}
                />
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Name of University / Institute:"}
                </Text>

                <TextInput
                  allowClear
                  value={values.university}
                  onChange={(e) => setFieldValue("university", e.target.value)}
                  // error={getError(errors.university, touched.university)}
                />
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Year of Graduation:"}
                </Text>

                <TextInput
                  allowClear
                  type={"number"}
                  value={values.education_year}
                  onChange={(e) =>
                    setFieldValue("education_year", e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col span={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Are you currently working?"}
                </Text>

                <RadioGroupButton
                  value={values?.is_currently_working}
                  direction={"vertical-align"}
                  onChange={(e) =>
                    setFieldValue("is_currently_working", e.target.value)
                  }
                  options={workStatus}
                  // error={getError(
                  //   errors.is_currently_working,
                  //   touched.is_currently_working
                  // )}
                />
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col span={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Have you ever taught online?"}
                </Text>

                <RadioGroupButton
                  value={values?.is_taught_online}
                  direction={"vertical-align"}
                  onChange={(e) =>
                    setFieldValue("is_taught_online", e.target.value)
                  }
                  options={workStatus}
                  // error={getError(
                  //   errors.is_taught_online,
                  //   touched.is_taught_online
                  // )}
                />
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col span={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Please select your teaching experience."}
                </Text>

                <RadioGroupButton
                  value={values?.teaching_experience}
                  direction={"vertical-align"}
                  onChange={(e) =>
                    setFieldValue("teaching_experience", e.target.value)
                  }
                  options={teachingExperience}
                  // error={getError(
                  //   errors.teaching_experience,
                  //   touched.teaching_experience
                  // )}
                />
              </Col>
            </Row>

            <Row className={"mt-20 mb-20"}>
              <h2 className="sub-title font-weight-600">{"BASIC CRITERIA"}</h2>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col span={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Do you have a laptop with a functional camera?"}
                </Text>

                <RadioGroupButton
                  value={values?.have_laptop}
                  direction={"vertical-align"}
                  onChange={(e) => setFieldValue("have_laptop", e.target.value)}
                  options={workStatus}
                  // error={getError(errors.have_laptop, touched.have_laptop)}
                />
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col span={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"Do you have a stable internet connection?"}
                </Text>

                <RadioGroupButton
                  value={values?.is_internet}
                  direction={"vertical-align"}
                  onChange={(e) => setFieldValue("is_internet", e.target.value)}
                  options={workStatus}
                  // error={getError(errors.is_internet, touched.is_internet)}
                />
              </Col>
            </Row>

            <Row className={"mt-20 mb-20"}>
              <h2 className="sub-title font-weight-600">{"Expectations"}</h2>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <Space>
                  <Text
                    font="SEMIBOLD"
                    lHeight={"M"}
                    className="mb-10"
                    size="S"
                  >
                    {
                      "What are your expectations from the IGNITE Teacher Training Diploma and what goal do you aim to acheive through it?"
                    }
                  </Text>

                  <Text
                    font="SEMIBOLD"
                    color={"grey"}
                    lHeight={"M"}
                    className="mb-10"
                    size="S"
                  >
                    {"(optional)"}
                  </Text>
                </Space>

                <TextInput
                  allowClear
                  multiline
                  rows={4}
                  value={values.personal_statement}
                  onChange={(e) =>
                    setFieldValue("personal_statement", e.target.value)
                  }
                  // error={getError(
                  //   errors.personal_statement,
                  //   touched.personal_statement
                  // )}
                />
              </Col>
            </Row>

            <Row className="mb-20" gutter={[20, 20]}>
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text font="SEMIBOLD" lHeight={"M"} className="mb-10" size="S">
                  {"How did you hear about us?"}
                </Text>

                <Select
                  name={"aboutus"}
                  className={"select-aboutus"}
                  style={{ width: "100%" }} //When an error occurs, the 'empty-error' class will be added, and the style is defined in index.css.
                  //ref={ref}
                  //status={status}
                  //style={{ ...dropdownStyle, borderRadius: "8px", height: "50px" }}
                  onChange={(value) => setFieldValue("about_us", value)}
                  //onBlur={onBlur}
                  //showSearch={showSearch}
                  placeholder={"select where did you hear about us?"}
                  //optionFilterProp={"title"}
                  //defaultValue={selectedId}
                  value={values?.about_us}
                  options={aboutUs}
                />

                {/* <RadioGroupButton
                  value={values?.about_us}
                  direction={"vertical-align"}
                  onChange={(e) => setFieldValue("about_us", e.target.value)}
                  options={aboutUs}
                  // error={getError(errors.about_us, touched.about_us)}
                /> */}
              </Col>
            </Row>

            <Row justify={"center"} className="mb-20 mt-20" gutter={[20, 20]}>
              <Button
                onClick={() =>
                  onHandleErrors(
                    errors,
                    handleSubmit,
                    (touchedFields, shouldValidate) =>
                      formikRef.current.setTouched(
                        touchedFields,
                        shouldValidate
                      )
                  )
                }
                loading={isSubmitting}
                style={buttonStyles}
              >
                {"Submit"}
              </Button>
            </Row>
          </Fragment>
        )}
      </Formik>
    </div>
  );
};

export default IgniteForm;
