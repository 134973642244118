/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from "react";
import AppLoader from "../../components/app-loader";
import Footer from "../../components/footer";
import Header from "../../components/header";
import InstructorReviews from "../../components/instructor-reviews";
import InstructorInfo from "./instructor-info";
import PackageInfo from "./package-info";
import { axiosErrorHandler } from "../../utils";
import { useParams } from "react-router-dom";
import { getRequest } from "../../config/network";
import { GET_FREE_CLASS, GET_TEACHER_REVIEWS } from "../../config/api";
import RegisterNow from "./register-now";
import parser from "html-react-parser";

const FreeClassDetails = () => {
  const { id: packageId } = useParams();
  const [reviews, setReviews] = useState();
  const [loading, setLoading] = useState(true);
  const [packageDetail, setPackageDetail] = useState({});
  const [isMobile, setIsMobile] = useState(window?.innerWidth < 1199);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window?.innerWidth < 1199);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getData = useCallback(() => {
    fetchSinglePackage();
  }, []);

  useEffect(() => getData(), []);

  const fetchSinglePackage = async () => {
    const endpoint = `${GET_FREE_CLASS}/${packageId}`;
    const { data } = await getRequest(endpoint);
    if (data?.status === "success") {
      setPackageDetail(data?.data);
      fetchTeacherReviews(data?.data?.teacher?.id);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchTeacherReviews = async (teacherId) => {
    try {
      const endpoint = `${GET_TEACHER_REVIEWS}/${teacherId}`;
      const { data } = await getRequest(endpoint, false);
      if (data?.status === "success") {
        setReviews(data?.data);
      }
    } catch (error) {
      axiosErrorHandler(error);
    }
  };

  return (
    <div className={"package_details"}>
      <Header />
      {loading ? (
        <div className="d-flex justify-content-center mt-50 pt-40 mb-50">
          <AppLoader className="font-28" />
        </div>
      ) : (
        <Fragment>
          {packageDetail && (
            <Fragment>
              <div className="app-container mt-50 mb-50">
                <PackageInfo isMobile={isMobile} packageDetail={packageDetail} />
                <RegisterNow fetchSinglePackage={fetchSinglePackage} packageDetail={packageDetail} />
                {!isMobile && <InstructorInfo isMobile={isMobile} packageDetail={packageDetail} />}
                {isMobile && packageDetail?.description && (
                  <div className={"f-sm mt-35 mb-0 dark-grey-color"}>
                    {parser(packageDetail?.description)}
                  </div>
                )}
                <InstructorReviews reviews={reviews} />
              </div>
              <Footer />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default FreeClassDetails;
