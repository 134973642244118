import React, { useEffect, useState, Suspense, useCallback } from "react";

import { Row, Col, Space, Button, message } from "antd";
import { isMobile } from "react-device-detect";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import ReactPixel from "react-facebook-pixel";
import slugify from "@sindresorhus/slugify";

import {
  checkIsAuthenticated,
  crouselSettings,
  getTimeSlots,
} from "src/modules/parent/utils";
import { FloatingButton } from "src/modules/parent/pages/olevels/components";
import { Review } from "src/modules/parent/pages/international/components";
import { RequestDemoModal } from "src/modules/parent/pages/home/components/modal";
import YoutubeModal from "src/modules/parent/components/modal/youtube-modal";
import {
  fb_pixel_id_summer,
  fb_pixel_id_examPrep,
} from "src/modules/parent/config/constant";
import SelectModal from "src/modules/parent/pages/olevels/components/selectModal";
import ContactModal from "src/modules/parent/components/modal/contactModal";
import AssistMeModal from "src/modules/parent/components/modal/assistMe";
import rocket from "src/modules/parent/assets/webp/rocket.webp";
import {
  subjectData,
  unAuthOne2One,
} from "src/modules/parent/utils/static-data";
import { reviewData } from "src/modules/parent/pages/find-a-tutor/staticData";
import Text from "src/modules/parent/components/text";
import environ from "../../assets/svgs/map.svg";
import SuccessModal from "src/modules/parent/pages/olevels/components/modal/success";
import check from "../../../../assets/svgs/check-circle.svg";
// import AppBanner from "src/modules/parent/components/app-banner";
import star from "../../../../assets/svgs/star.svg";
import mobImage from "../../assets/images/mobImg-min.png";
import AppLoader from "src/modules/parent/components/app-loader";
import ExamPrepModal from "src/modules/parent/components/modal/examPrepModal";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import {
  phoneNumberNotification,
  phoneValidateNotification,
} from "src/modules/parent/utils/notificationData";
import { cat4Grades } from "src/modules/parent/utils/static-data";
import { Form } from "src/modules/parent/pages/olevels/components";
import actionTypes from "src/modules/parent/store/action-types";
import ModalComponent from "src/modules/parent/components/modal";

import {
  CourseCard,
  BannerComponent,
  useIntersectionObserver,
} from "../../components";
import FindCourses from "src/modules/parent/components/findCourses";
import learningSvg from "../../assets/svgs/learning.webp";
import agesSvg from "../../assets/svgs/ages.webp";
import classSvg from "../../assets/svgs/class.webp";
import videoChatSvg from "../../assets/svgs/video_chat.webp";
import LazyBannerNoAuth from "../../components/banner";
import * as actions from "src/modules/parent/store/actions";

import styles from "./styles.module.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetStartedModal from "../../components/modal/getStartedModal";
import {
  joinForFreeBtnUnAuthPage,
  trackEvents,
} from "src/modules/parent/utils/events/query";
import events from "src/modules/parent/utils/events";

ReactPixel.init(fb_pixel_id_summer);
ReactPixel.init(fb_pixel_id_examPrep);

const submitFormBadar = events.facebook.examPrep.submitFormBadar;

const whatsappMobile = "https://wa.me/";

const data = [
  {
    id: "0",
    title: "Live, online classes",
    color: "#EC1847",
    icon: videoChatSvg,
  },
  {
    id: "1",
    title: "World class teachers",
    color: "#7DE003",
    icon: classSvg,
  },
  {
    id: "2",
    title: "Flexible learning options",
    color: "#005EFF",
    icon: learningSvg,
  },
  {
    id: "3",
    title: "Learner ages 3 - 18",
    color: "#FDC53E",
    icon: agesSvg,
  },
];

const aboutData = [
  {
    id: "0",
    image: environ,
    text: "In Over 14 Countries",
    description: "International Tutors and Students",
  },
  {
    id: "1",
    image: check,
    text: "400,000+ Live Classes",
    description: "Completed",
  },
  {
    id: "2",
    image: star,
    text: "4.6/5 Average Rating",
    description: "Given by 7000+ Global Learners",
  },
];

const LazyBlogComponent = React.lazy(() =>
  import("src/modules/parent/pages/blogs/components/blogComponent")
);
const LazyCategories = React.lazy(() => import("../../components/categories"));
const LazyGetStats = React.lazy(() => import("../../components/get-stats"));
const LazyOurPartners = React.lazy(() =>
  import("src/modules/parent/components/partners")
);

const UnAuthenticatedHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState(null);
  const [linkData, setLinkData] = useState([]);
  const [videoId, setVideoId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [getStartedModal, setGetStartedModal] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [FormsData, setFormsData] = useState({});
  const [packageDetails, setPackageDetails] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [timeSlots, setTimeSlots] = useState(null);
  const [formLoading, setFormLoading] = React.useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showContactModal2, setShowContactModal2] = useState(false);
  const [showAssistMe, setShowAssistMe] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState({});
  const { keywordList } = useSelector((state) => state.subjectReducer);
  const [show, setShow] = useState(false);
  const action = bindActionCreators(actions, dispatch);
  const { unAuthData } = useSelector((state) => state.packageReducer);
  const { bulkPackages, getPackageWithHeading } = unAuthData || {};
  const [ref, isVisible] = useIntersectionObserver({
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  useEffect(() => {
    if (videoId) {
      setShowModal(true);
    }
  }, [videoId]);

  useEffect(() => {
    if (isVisible && !blogData) {
      actions.getPosts({}, 1, true).then(setBlogData);
    }
  }, [isVisible, blogData]);

  useEffect(() => {
    action.fetchPackages();

    subjectData.push({});
    setLinkData(subjectData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleClick = useCallback(
    (item) => {
      if (item && item.subject) {
        const route = item.subject.toLowerCase().split(" ").join("-");
        navigate(`/online-${route}`);
      }
    },
    [navigate]
  );

  const handleTabClick = (item) => {
    switch (item.id) {
      case "0":
        setShowAssistMe(true);
        break;
      case "1":
        navigate("/naplan");
        break;
      case "2":
        setShow(true);
        break;

      default:
        break;
    }
  };

  const handleWhatsApp = useCallback((isFloating) => {
    const phone = isFloating ? "923474000862" : "923474106511";
    window.open(`${whatsappMobile}${phone}`);
  }, []);

  const navigateToChat = (id) => {
    if (checkIsAuthenticated()) {
      navigate(`/chat/${id}`);
    } else {
      action.setRegisterData({
        show: true,
        type: "teacherChat",
        chat: {
          teacherId: id,
        },
      });
    }
  };

  const handleClickContact = useCallback((isFloating) => {
    if (!isBrowser) handleWhatsApp(isFloating);
    else {
      if (isFloating) setShowContactModal2(true);
      else setShowContactModal(true);
    }
  }, []);

  const handleBookADemo = useCallback((data) => {
    if (data && data?.time_slot) {
      const time_slots = getTimeSlots(data?.time_slot);

      setPackageDetails(data);
      setTimeSlots(time_slots);
      setShowDemoModal(true);
    }
  }, []);

  const handleItemSelect = (e) => {
    setSelectedGrade({ grade: [e.value] });
    setShowSelectModal(false);
  };

  const handleFallBack = useCallback(({ ...formData }) => {
    setFormsData(formData);
    setFormModal(true);
  }, []);

  const saveToDB = useCallback(
    (number) => {
      setFormLoading(true);

      actions
        .addFormFields({ ...FormsData, phone: number })
        .then((response) => {
          setFormLoading(false);
          ReactPixel.trackSingle(fb_pixel_id_examPrep, submitFormBadar);
          setFormsData({});
          setFormModal(false);
          setShowConfirmModal(true);
        })
        .catch((error) => {
          setFormLoading(false);
          setFormsData({});
        });
    },
    [FormsData]
  );

  const handleSubmit = useCallback(() => {
    const { parent_name, board, grade, subject, phone } = FormsData;

    if (!parent_name || !board || !grade || !subject || !phone) {
      return message.error("Please fill all required fields!");
    }

    const phoneNumber = parsePhoneNumberWithError(phone);

    if (phoneNumber.isValid()) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: phoneValidateNotification(phoneNumber?.number, saveToDB),
      });
    } else {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: phoneNumberNotification,
      });
    }
  }, [FormsData, dispatch, saveToDB]);

  const titleRoute = useCallback((title) => (title ? slugify(title) : ""), []);

  const getUrl = useCallback(
    (title, id) => {
      const gotoUrl = `/courses/${titleRoute(title)}/${id}`;

      return gotoUrl;
    },
    [titleRoute]
  );

  const handleViewPackageDetail = ({ title, id }) => {
    const gotoUrl = getUrl(title, id);

    navigate(gotoUrl);
  };

  const handleChange = useCallback((key, value) => {
    setFormsData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const onHandleClose = () => {
    setShowConfirmModal(false);
    navigate("/exam-preparation", {
      state: {
        notFound: true,
      },
    });
  };

  const handleShow = (key) => {
    if (key === "0") {
      setShowAssistMe(true);
    } else {
      setShow(true);
    }

    setGetStartedModal(false);
  };

  const onSignUp = () => {
    trackEvents(joinForFreeBtnUnAuthPage);
    navigate("/signup");
  };

  return (
    <>
      {isMobile ? (
        <Row className="mr-5" justify={"center"}>
          <img src={mobImage} width={"100%"} alt={"mobileImage"} />
        </Row>
      ) : (
        <BannerComponent handleClick={() => setGetStartedModal(true)} />
      )}

      {!isMobile && (
        <div className="app-container mt-20">
          <Row gutter={[20, 20]} className={styles.aboutCenter}>
            {data.map((item) => (
              <Col key={item.id} xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
                <div className={styles.tabs}>
                  <Row justify={isBrowser ? "center" : "start"}>
                    <Space size={"middle"} direction={"horizontal"}>
                      <img src={item.icon} alt={"checkmark"} />

                      <Text
                        size={"M2"}
                        color={item.color}
                        font={"SEMIBOLD"}
                        title={item.title}
                      />
                    </Space>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}

      {isBrowser ? (
        <FindCourses title={"We believe in the power of real connection!"} />
      ) : (
        <Row justify={"center"}>
          <div style={{ textAlign: "center" }} className={"titleHeading mt-20"}>
            {"We believe in the power of real connection!"}
          </div>

          <Button
            onClick={() => setGetStartedModal(true)}
            className={styles.searchButton}
          >
            {"Get Started"}
          </Button>
        </Row>
      )}

      <Row gutter={[20, 20]} className={styles.appStats} justify={"center"}>
        {isMobile ? (
          <Suspense fallback={<AppLoader className="font-28" />}>
            <div className="app-container">
              <Slider {...crouselSettings}>
                {aboutData.map((item, index) => (
                  <Col className="mb-5 mt-5" key={item.id}>
                    <LazyGetStats
                      image={item.image}
                      text={item.text}
                      description={item.description}
                    />
                  </Col>
                ))}
              </Slider>
            </div>
          </Suspense>
        ) : (
          <Suspense fallback={<AppLoader className="font-28" />}>
            <Row justify={"center"} gutter={[30, 20]}>
              {aboutData.map((item, index) => (
                <Col key={item.id}>
                  <LazyGetStats
                    image={item.image}
                    text={item.text}
                    description={item.description}
                  />
                </Col>
              ))}
            </Row>
          </Suspense>
        )}
      </Row>

      {/* Free Classes */}
      {/* {bulkPackages &&
        bulkPackages.length > 0 &&
        bulkPackages.map(
          (item, index) =>
            item.type === "free" && (
              <CourseCard {...item} key={index.toString()} han />
            )
        )} */}

      <Row justify={"start"} className={"mb-20 app-container"}>
        <div className={"titleHeading"}>{"Class Categories"}</div>
      </Row>

      <div className={"app-container mb-20"}>
        <Suspense fallback={<AppLoader className="font-28" />}>
          <LazyCategories handleClick={handleTabClick} />
        </Suspense>
      </div>

      {getPackageWithHeading && getPackageWithHeading?.length > 0 && (
        <div className={styles.freeClassesStyle}>
          {getPackageWithHeading.map((item, index) => (
            <CourseCard
              {...item}
              key={index.toString()}
              onClick={() => setShowAssistMe(true)}
            />
          ))}
        </div>
      )}

      <div className="mb-20">
        <LazyBannerNoAuth
          data={unAuthOne2One}
          handleClick={() => handleClickContact(false)}
        />
      </div>

      {bulkPackages &&
        bulkPackages.length > 0 &&
        bulkPackages.map(
          (item, index) =>
            item.type !== "free" && (
              <CourseCard
                {...item}
                key={index.toString()}
                handleShowModal={setVideoId}
                handleBookDemo={handleBookADemo}
                handleChat={navigateToChat}
                viewDetails={handleViewPackageDetail}
                onClick={() => setShowAssistMe(true)}
              />
            )
        )}

      <Suspense fallback={<AppLoader className="font-28" />}>
        <LazyOurPartners />
      </Suspense>

      <div ref={ref} className="app-container">
        <Row justify={"start"}>
          <div className={"titleHeading"}>{"Testimonials"}</div>
        </Row>
      </div>

      <Review isHeading={false} reviewsData={reviewData} />

      {!checkIsAuthenticated() && (
        <Row justify={"center"}>
          <Button
            onClick={onSignUp}
            loading={false}
            className={styles.searchButton}
          >
            <Space>{"Join for free"}</Space>
          </Button>
        </Row>
      )}

      {blogData && blogData?.posts && blogData?.posts.length > 0 && (
        <div
          className={[
            "app-container pt-20 pr-20 pl-20 mb-50",
            styles.blogData,
          ].join(" ")}
        >
          <Col span={24}>
            <Row justify={"start"} className={"mb-20"}>
              <div className={"titleHeading"}>{"Checkout our Blogs!"}</div>
            </Row>

            {blogData && (
              <Suspense fallback={<AppLoader className="font-28" />}>
                <LazyBlogComponent showTitle={false} data={blogData} />
              </Suspense>
            )}
          </Col>
        </div>
      )}

      {!checkIsAuthenticated() && (
        <div className={styles.joinforfree}>
          <div>
            <Space size={"large"} align={"center"} direction={"vertical"}>
              <img src={rocket} alt={"rocket"} />

              <Text
                size={"L"}
                center
                lHeight={"S"}
                font={"SEMIBOLD"}
                title={"Join for free and Unlock features"}
              />

              <Row
                justify={"center"}
                className={styles.colRow}
                gutter={[16, 10]}
              >
                <Col span={!isMobile ? 8 : 24} className={styles.colWithBorder}>
                  <Text
                    size={"S"}
                    center
                    lHeight={"S"}
                    title={"1. Chat in realtime with tutors"}
                  />
                </Col>

                <Col span={!isMobile ? 8 : 24} className={styles.colWithBorder}>
                  <Text
                    size={"S"}
                    lHeight={"S"}
                    center
                    title={"2. Access a personalised dashboard"}
                  />
                </Col>

                <Col span={!isMobile ? 8 : 24}>
                  <Text
                    size={"S"}
                    lHeight={"S"}
                    center
                    title={"3. Book free demo classes"}
                  />
                </Col>
              </Row>

              <Row justify={"center"}>
                <Button
                  onClick={onSignUp}
                  loading={false}
                  className={styles.searchButton}
                >
                  <Space>{"Join for free"}</Space>
                </Button>
              </Row>
            </Space>
          </div>
        </div>
      )}

      <div className={["inner-container", styles.links].join(" ")}>
        <Row gutter={[20, 20]} justify={"center"}>
          {linkData.map((item, index) => (
            <Col
              key={index.toString()}
              xxl={6}
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={24}
            >
              <div className={styles.linkText}>
                <Text
                  size={"M2"}
                  font={"LIGHTER"}
                  color={"#005EFF"}
                  handleClick={() => onHandleClick(item)}
                  className={styles.link}
                  title={
                    item?.subject ? `Online Classes for ${item?.subject}` : ""
                  }
                />
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <ContactModal
        show={showContactModal}
        title={"Contact Academic Counselor"}
        name={"Sarah Merchant:"}
        number={"+923474106511"}
        color={"#2ca58d"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal(false)}
      />

      <AssistMeModal
        show={showAssistMe}
        handleClose={() => setShowAssistMe(false)}
        handleBookADemo={handleBookADemo}
        handleShowVideo={setVideoId}
      />

      {videoId && showModal && (
        <YoutubeModal
          show={showModal}
          url={videoId}
          setShow={(data) => {
            setShowModal(data);
            setVideoId("");
          }}
        />
      )}

      {showDemoModal && (
        <RequestDemoModal
          isVisible={showDemoModal}
          onCancel={() => setShowDemoModal(false)}
          packageData={packageDetails}
          timeSlots={timeSlots}
        />
      )}

      {showSelectModal && (
        <SelectModal
          show={showSelectModal}
          select={cat4Grades}
          handleSelectItem={handleItemSelect}
          selectedItem={selectedGrade}
          setShow={setShowSelectModal}
        />
      )}

      <ExamPrepModal
        show={show}
        onHandleFallback={handleFallBack}
        handleClose={() => setShow(false)}
      />

      <Form
        setShow={setFormModal}
        keywordList={keywordList}
        data={FormsData}
        isLoading={formLoading}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        show={formModal}
      />

      <ModalComponent
        maskClosable
        closable={false}
        isModalVisible={showConfirmModal}
        onCancel={onHandleClose}
        bodyClassName={"d-flex justify-content-center align-items-center"}
        Component={<SuccessModal onOk={onHandleClose} />}
      />

      <GetStartedModal
        show={getStartedModal}
        setShow={setGetStartedModal}
        handleClick={handleShow}
      />

      <ContactModal
        show={showContactModal2}
        title={"Contact Representative"}
        name={"Masooma Batool:"}
        color={"#2ca58d"}
        number={"+923474000862"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal2(false)}
      />

      {/* WHATSAPP FLOATING BUTTON */}
      <FloatingButton
        handlePress={() => {
          handleClickContact(true);
        }}
      />
    </>
  );
};

export default React.memo(UnAuthenticatedHome);
