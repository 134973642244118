import { logEvent } from "firebase/analytics";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";

import { analytics } from "src/modules/parent/config/firebase";
import {
  fb_pixel_id_olevel,
  IS_DEV_ENV,
  fb_pixel_id_summer,
  fb_pixel_id_ignite,
} from "src/modules/parent/config/constant";

import events from "./index";

export const trackEvents = ({ google, facebook, tiktok }) => {
  if (!IS_DEV_ENV) {
    if (google) {
      const { name, data } = google;
      logEvent(analytics, name, data || {});
    }

    if (facebook) {
      const { key, name } = facebook;
      ReactPixel.trackSingle(key, name);
    }

    if (tiktok) {
      const { name, data } = tiktok;
      TiktokPixel.track(name, data || {});
    }
  }
};

export const examPrepBookDemoHero = {
  google: {
    name: events.google.examPrep.bookDemoHero,
  },
  facebook: {
    key: fb_pixel_id_olevel,
    name: events.facebook.examPrep.bookDemoHero,
  },
};

export const epContactBtnWhatsapp = {
  google: {
    name: events.google.examPrep.contactWhatsappBtn,
  },
  facebook: {
    key: fb_pixel_id_olevel,
    name: events.facebook.examPrep.contactWhatsappBtn,
  },
  tiktok: {
    name: events.tiktok.examPrep.contactWhatsappBtn,
  },
};

export const addChildSaveBtnClick = {
  google: {
    name: events.google.addChildren.saveClicked,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.addChildren.saveClicked,
  },
};

export const joinForFreeBtnUnAuthPage = {
  google: {
    name: events.google.unAuthPage.joinForFreeBtn,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.unAuthPage.joinForFreeBtn,
  },
};

export const K8CardClick = {
  google: {
    name: events.google.packageCard.click,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.packageCard.click,
  },
};

export const igniteApplyNowBtn = {
  google: {
    name: events.google.ignite.applyNowButton,
  },
  facebook: {
    key: fb_pixel_id_ignite,
    name: events.facebook.ignite.applyNowButton,
  },
};

export const igniteFormSubmit = {
  google: {
    name: events.google.ignite.submitForm,
  },
  facebook: {
    key: fb_pixel_id_ignite,
    name: events.facebook.ignite.submitForm,
  },
};

export const naplanBookAFreeDemoBtnHero = {
  google: {
    name: events.google.naplan.assistMeDemo,
  },
};

export const naplanBookDemoTeacherCard = {
  google: {
    name: events.google.naplan.teacherCardDemo,
  },
};

export const naplanCarouselCardClick = {
  google: {
    name: events.google.naplan.carousel,
  },
};

export const naplanGradeFilterSelect = {
  google: {
    name: events.google.naplan.carousel,
  },
};

export const unAuthBookDemoTeacherCard = {
  google: {
    name: events.google.unAuthPage.bookDemoK8,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.unAuthPage.bookDemoK8,
  },
};

export const unAuthExploreMoreBtn = {
  google: {
    name: events.google.unAuthPage.exploreMoreClassesBtn,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.unAuthPage.exploreMoreClassesBtn,
  },
};

export const epChatWithTutorCourseCard = {
  google: {
    name: events.google.examPrep.chatWithTutorCourseCard,
  },
  facebook: {
    key: fb_pixel_id_olevel,
    name: events.facebook.examPrep.chatWithTutorCourseCard,
  },
};

export const signUpJoinButton = {
  google: {
    name: events.google.signup.joinButtonClick,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.signup.joinButtonClick,
  },
};

export const registerBtnSignupSuccessModal = {
  google: {
    name: events.google.signup.modalRegisterSuccessButton,
  },
  facebook: {
    key: fb_pixel_id_olevel,
    name: events.facebook.signup.modalRegisterSuccessButton,
  },
  tiktok: {
    name: events.tiktok.signup.modalRegisterSuccessButton,
  },
};

export const makePaymentBtnSignUpSuccess = {
  google: {
    name: events.google.signup.makePaymentBtnModal,
  },
  facebook: {
    key: fb_pixel_id_olevel,
    name: events.facebook.signup.makePaymentBtnModal,
  },
  tiktok: {
    name: events.tiktok.signup.makePaymentBtnModal,
  },
};

export const registerBtnClickSignUpModal = {
  google: {
    name: events.google.signup.registerBtnClick,
  },
  facebook: {
    key: fb_pixel_id_olevel,
    name: events.facebook.signup.registerBtnClick,
  },
};

export const registerBtnSuccessSignUpModal = {
  google: {
    name: events.google.signup.registerSuccess,
  },
  facebook: {
    key: fb_pixel_id_olevel,
    name: events.facebook.signup.registerSuccess,
  },
  tiktok: {
    name: events.tiktok.signup.registerSuccess,
  },
};

export const invoicePayNowBtnRedirect = {
  google: {
    name: events.google.invoice.cardPayNowButton,
  },
  facebook: {
    key: fb_pixel_id_olevel,
    name: events.facebook.invoice.cardPayNowButton,
  },
  tiktok: {
    name: events.tiktok.invoice.cardPayNowButton,
  },
};

export const whatsNewSectionButton = {
  google: {
    name: events.google.authPage.whatsNew,
  },
};

export const joinClassBtnUpcomingClasses = {
  google: {
    name: events.google.authPage.joinClassUpcomingClasses,
  },
};

export const attendDemoBtnUpcomingClasses = {
  google: {
    name: events.google.authPage.attendDemoUpcomingClasses,
  },
};

export const postReviewBtnTeacherCard = {
  google: {
    name: events.google.authPage.postReviewBtnTeacherCard,
  },
};

export const chatWithTutorBtnRecommendedSection = {
  google: {
    name: events.google.authPage.chatWithTutorRecommended,
  },
};

export const chatWithTutorBtnTeacherAvatar = {
  google: {
    name: events.google.authPage.chatBtnTeacherAvatar,
  },
};

export const bookDemoRecommendedClasses = {
  google: {
    name: events.google.authPage.bookADemoRecommended,
  },
};

export const findCourseSignupSuccess = {
  google: {
    name: events.google.signup.findCourseBtnSuccess,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.signup.findCourseBtnSuccess,
  },
};

export const learningLibraryHeader = {
  google: {
    name: events.google.authPage.learningLibraryHeader,
  },
};

export const baselineViewWeb = {
  google: {
    name: events.google.enrollment.baselineWeb,
  },
};

export const certificateViewWeb = {
  google: {
    name: events.google.enrollment.certificateWeb,
  },
};

export const progressReportViewWeb = {
  google: {
    name: events.google.enrollment.progressReportWeb,
  },
};

export const bookDemoPackageDetails = {
  google: {
    name: events.google.unAuthPage.bookDemoCoursePage,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.unAuthPage.bookDemoCoursePage,
  },
};

export const applyNowButtonRecruitment = {
  google: {
    name: events.google.becomeTeacher.applyNow,
  },
};

export const requestDemoClassBtn = {
  google: {
    name: events.google.unAuthPage.requestDemoModal,
  },
  facebook: {
    key: fb_pixel_id_summer,
    name: events.facebook.unAuthPage.requestDemoModal,
  },
};

export const igniteLandingPageClick = {
  google: {
    name: events.google.ignite.landingPageClick,
  },
};

export const contactRepresentativeModal = {
  google: {
    name: events.google.unAuthPage.contactRepresentativeModal,
  },
};
