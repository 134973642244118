import React from "react";

import Text from "src/modules/parent/components/text";

const data = [
  {
    id: "0",
    text: "Completion of Bachelor's or Master's degree",
  },
  {
    id: "1",
    text: "Minimum 2 years of relevant teaching experience",
  },
  {
    id: "2",
    text: "Strong English communication skills",
  },
  {
    id: "3",
    text: "Personal computer/laptop and high-speed internet",
  },
];

const BulletList = () => (
  <ul>
    {data.map((item) => (
      <li className="mb-10">
        <Text size={"S"} lHeight={"S"} font={"LIGHTER"} title={item.text} />
      </li>
    ))}
  </ul>
);

export default BulletList;
