import React, { useEffect } from "react";

import { Button, Row, Col, Space, Form } from "antd";
import { useSelector } from "react-redux";
import { parsePhoneNumberWithError } from "libphonenumber-js";

import PhoneWithFlagInput from "src/modules/parent/components/phone-input";
import { defaultUAE } from "src/modules/parent/utils";

import styles from "../styles.module.css";

const StepNo2 = ({
  phone,
  isError,
  country,
  grade,
  subject,
  setPhone,
  countryOnChange = () => {},
  handlePrevious = () => {},
  handleSubmit = () => {},
}) => {
  const { userInfo } = useSelector((state) => state.authReducer);
  const { countries } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    if (userInfo && userInfo.phone) {
      const phoneNumber = parsePhoneNumberWithError(userInfo.phone);

      if (phoneNumber && phoneNumber?.isValid()) {
        const searchPhone = countries.find(
          (i) => i?.code.toLowerCase() === phoneNumber?.country.toLowerCase()
        );

        if (searchPhone && searchPhone.id) {
          countryOnChange(searchPhone.id);
          setPhone(phoneNumber?.number);
        }
      }
    }
  }, [countries, countryOnChange, setPhone, userInfo]);

  return (
    <>
      <Row justify={"center"}>
        <Space align={"center"} direction={"vertical"}>
          <div className={styles.description}>
            {"You are looking for a teacher to teach"}
          </div>

          <div className={styles.highlightedText}>{`${grade}, ${subject}`}</div>
        </Space>
      </Row>

      <div className={"mt-30"}>
        <h2>{"Enter your phone number"}</h2>

        <Col span={24}>
          <Form className={"text-align-center"}>
            <Form.Item
              hasFeedback={!phone && isError}
              validateStatus={!phone && isError ? "error" : "success"}
            >
              <PhoneWithFlagInput
                showSearch
                inputPlaceholder={"Enter phone"}
                value={phone}
                selectedId={defaultUAE.id}
                countryOnChange={countryOnChange}
                countryPlaceholder={"Select"}
                selectedCountry={country?.value}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Col>

        <Row justify={"center"}>
          <Space>
            <Button onClick={handlePrevious} className={styles.previousButton}>
              {"Previous"}
            </Button>

            <Button onClick={handleSubmit} className={styles.nextButton}>
              {"Next"}
            </Button>
          </Space>
        </Row>
      </div>
    </>
  );
};

export default React.memo(StepNo2);
