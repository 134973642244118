const actionTypes = {
  USER_INFO: "USER_INFO",
  IS_AUTHENTICATED: "IS_AUTHENTICATED",
  IS_ONBOARDING: "IS_ONBOARDING",
  COUNTRIES: "COUNTRIES",
  CITIES: "CITIES",
  LOG_OUT: "LOG_OUT",
  SET_CHILDREN: "SET_CHILDREN",
  REMOVE_CHILD: 'REMOVE_CHILD',
  SET_TOP_PACKAGES: "SET_TOP_PACKAGES",
  SET_TOP_FREE_CLASSES: "SET_TOP_FREE_CLASSES",
  SET_TOP_TEACHERS: "SET_TOP_TEACHERS",
  SET_LOGGED_IN_USER: "SET_LOGGED_IN_USER",
  SET_IS_AUTHENTICATED: "SET_IS_AUTHENTICATED",
  SET_PACKAGE_DATA: "SET_PACKAGE_DATA",
  SET_FREE_CLASSES_DATA: "SET_FREE_CLASSES_DATA",
  SET_SUBJECT_LIST: "SET_SUBJECT_LIST",
  SET_KEYWORD_LIST: "SET_KEYWORD_LIST",
  SET_TEACHER_DATA: "SET_TEACHER_DATA",
  UPDATE_PARENT_PROFILE: "UPDATE_PARENT_PROFILE",
  SET_DEMOS_AND_COURSES: "SET_DEMOS_AND_COURSES",
  SET_CONTACT_US_MODAL: "SET_CONTACT_US_MODAL",
  SET_DIMENSION: "SET_DIMENSION",
  SET_INVOICE_DATA: "SET_INVOICE_DATA",
  SET_PAYMENT_CARDS: "SET_PAYMENT_CARDS",
  ADD_PAYMENT_CARDS: "ADD_PAYMENT_CARDS",
  SET_SUBJECT_PACKAGES: "SET_SUBJECT_PACKAGES",
  SET_SUBJECT_PACKAGES_LOADING: "SET_SUBJECT_PACKAGES_LOADING",
  UPDATE_FROM_TELENOR_STATUS: "UPDATE_FROM_TELENOR_STATUS",
  SET_BACK_TO_SCHOOL_COURSES: "SET_BACK_TO_SCHOOL_COURSES",
  SET_GROUP_CLASSES_BATCH_1: "SET_GROUP_CLASSES_BATCH_1",
  SUBJECT_TEACHERS: "SUBJECT_TEACHERS",
  SHORT_COURSES: "SHORT_COURSES",
  RECOMMENDED_COURSES: "RECOMMENDED_COURSES",
  SET_BULK_PACKAGES: "SET_BULK_PACKAGES",
  SET_CHILD_ID: "SET_CHILD_ID",
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  CHILD_LOADING: "CHILD_LOADING",
  OLEVEL_SUBJECTS: "OLEVEL_SUBJECTS",
  OLEVEL_SUBJECTS_DOWN: "OLEVEL_SUBJECTS_DOWN",
  IS_OLEVEL: "IS_OLEVEL",

  BLOG_DATA: "BLOG_DATA",
  BLOG_CATEGORIES: "BLOG_CATEGORIES",
  BLOG_POSTS: "BLOG_POSTS",
  BLOGS: "BLOGS",

  NOTIFICATIONDATA: "NOTIFICATIONDATA",
  NOTIFICATIONS: "NOTIFICATIONS",
  PROFESSIONAL_PACKAGE: "PROFESSIONAL_PACKAGE",
  PROFESSIONAL_TEACHER: "PROFESSIONAL_TEACHER",
  MORE_COURSES: "MORE_COURSES",
  INBOX_DATA: "INBOX_DATA",
  GET_CHAT_MESSAGES: "GET_CHAT_MESSAGES",
  SCROLL_TO_BOTTOM: "SCROLL_TO_BOTTOM",
  CHAT_COUNT: "CHAT_COUNT",
  WORKSHOP_PACKAGES: "WORKSHOP_PACKAGES",
  FREE_CLASSES_LOADER: "FREE_CLASSES_LOADER",
  SHOW_REGISTER_MODAL: "SHOW_REGISTER_MODAL",
  MATH_PACKAGES: "MATH_PACKAGES",
  CODING_PACKAGES: "CODING_PACKAGES",
  MATH_PACKAGES_LOADING: "MATH_PACKAGES_LOADING",
  CODING_PACKAGES_LOADING: "CODING_PACKAGES_LOADING",
  SUMMER_CLASSES_PACKAGES: "SUMMER_CLASSES_PACKAGES",
  SUMMER_CLASSES_PACKAGES_LOADING: "SUMMER_CLASSES_PACKAGES_LOADING",
  WHATS_NEW_DATA_LOADING: "WHATS_NEW_DATA_LOADING",
  WHATS_NEW_DATA: "WHATS_NEW_DATA",
  EVENTS_DATA_LOADING: "EVENTS_DATA_LOADING",
  EVENTS_DATA: "EVENTS_DATA",
  LEARNING_MENU: "LEARNING_MENU",
  LEARNING_SUBJECTS: "LEARNING_SUBJECTS",
  LEARNING_RESOURCES: "LEARNING_RESOURCES",
  LEARNING_GRADES: "LEARNING_GRADES",
  LEARNING_CATEGORY: "LEARNING_CATEGORY",
  LEARNING_RESOURCES_LOADING: "LEARNING_RESOURCES_LOADING",
  ACTIVE_STUDENTS: "ACTIVE_STUDENTS",
  TRACKER_DATA: "TRACKER_DATA",
  TEACHER_MODAL: "TEACHER_MODAL",
  ASSIST_ME_MODAL: "ASSIST_ME_MODAL",
};

export default actionTypes;
