import React from "react";

import { Button } from "antd";
import Text from "src/modules/parent/components/text";
import Card from "./card";

import styles from "./styles.module.css";
import NoData from "./noData";

const Certificate = ({
  data = null,
  onHandleClick = () => {},
  handleView = () => {},
  onHandleTeacherClick = () => {},
  onHandleClose = () => {},
}) => {
  return (
    <>
      {!data ? (
        <NoData
          btnText={"Okay, thanks!"}
          handleBtnClick={onHandleClose}
          description={
            "Keep supporting their efforts, and achievements will follow!"
          }
          title={"Your child is working hard and will earn a certificate soon!"}
        />
      ) : (
        <>
          <div className={styles.title}>Latest certificate received</div>

          <div className="mt-20 mb-20">
            <Card
              name={data?.teacher_name}
              handleClick={() => onHandleTeacherClick(data?.teacher_id)}
              picture={data?.teacher_picture}
              created_at={data?.created_at}
              type={"Certificate"}
              link={data?.link}
              handleView={handleView}
            />
          </div>

          <Button
            onClick={() => onHandleClick(data?.enroll_id)}
            className={styles.button}
          >
            <Text
              size={"S"}
              lHeight={"S"}
              color={"#FFFFFF"}
              title={"View all certificates"}
            />
          </Button>
        </>
      )}
    </>
  );
};

export default React.memo(Certificate);
