import React, { useEffect, useRef, useCallback } from "react";

import { Row, Spin, Col, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import slugify from "@sindresorhus/slugify";

import NoData from "../noData";

import CardItem from "src/modules/parent/pages/olevels/components/cardItem";

const Courses = ({
  data = [],
  pageJump = false,
  grade = "",
  subject = "",
  setPageJump = () => {},
  handleBookADemo = () => {},
  handleShowModal = () => {},
  paginationHandler = () => {},
  handleChat = () => {},
  handleContact = () => {},
}) => {
  const navigate = useNavigate();
  const { packageReducer } = useSelector((state) => state);
  const { packageData } = packageReducer;
  const { currentPage, perPage, total, loading } = packageData;
  const ref = useRef(null);

  useEffect(() => {
    if (pageJump && !loading) {
      ref?.current.scrollIntoView({ behavior: "smooth" });
      setPageJump(false);
    }
  }, [loading, pageJump, setPageJump]);

  const titleRoute = useCallback((title) => (title ? slugify(title) : ""), []);
  const getUrl = useCallback(
    (title, id) => {
      const gotoUrl = `/courses/${titleRoute(title)}/${id}`;

      return gotoUrl;
    },
    [titleRoute]
  );

  const viewPackageDetailHandler = useCallback(
    (event, title, id) => {
      event.preventDefault();

      const gotoUrl = getUrl(title, id);

      navigate(gotoUrl);
      event.stopPropagation();
    },
    [getUrl, navigate]
  );

  return (
    <div ref={ref} className={"app-container"}>
      <Spin spinning={loading} tip={"Loading"} size="large">
        <Row className={"mt-30 mb-30"}>
          {data && data.length > 0
            ? data.map((item) => (
                <Col
                  key={item?.id.toString()}
                  xxl={6}
                  xl={8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                >
                  <CardItem
                    item={item}
                    isOlevel={false}
                    viewPackageDetailHandler={viewPackageDetailHandler}
                    handleBookDemo={handleBookADemo}
                    handleShowModal={handleShowModal}
                    handleChat={handleChat}
                  />
                </Col>
              ))
            : !loading && (
                <NoData
                  handleContact={handleContact}
                  grade={grade}
                  subject={subject}
                />
              )}
        </Row>
      </Spin>

      <div className="d-row justify-content-center mt-20 pagination-card">
        <Pagination
          total={total < 24 ? total : 24}
          current={currentPage}
          hideOnSinglePage={true}
          pageSize={perPage}
          onChange={paginationHandler}
        />
      </div>
    </div>
  );
};

export default React.memo(Courses);
