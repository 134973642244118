import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./styles.module.css";

const ModalComponent = ({
  isModalVisible,
  onCancel,
  Component,
  style = {},
  closable = true,
  maskClosable = true,
  bodyStyle = {},
  footer = null,
  centered = true,
  bodyClassName = "",
  containerClassName = "",
  children,
  width,
}) => {
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (
        window.visualViewport &&
        window.visualViewport.height < window.innerHeight
      ) {
        // Keyboard is open
        setKeyboardOpen(true);
      } else {
        // Keyboard is closed
        setKeyboardOpen(false);
      }
    };

    window.visualViewport?.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal
      style={keyboardOpen ? { ...style, top: "0px" } : style}
      footer={footer}
      width={width}
      centered={centered}
      closable={closable}
      onCancel={onCancel}
      open={isModalVisible}
      maskClosable={maskClosable}
      className={[
        containerClassName ? containerClassName : styles.modal_container,
        "g-modal",
      ].join(" ")}
      styles={{
        mask: { backgroundColor: "rgba(0,0,0,0.23)" },
        body: bodyStyle,
      }}
    >
      <div className={[styles.modal_body_scrollable, bodyClassName].join(" ")}>
        {Component || children}
      </div>
    </Modal>
  );
};

export default ModalComponent;
