import { useState, useEffect } from "react";

import * as actions from "src/modules/parent/store/actions";
import { checkIsAuthenticated } from "../../utils";

const useFetchAuth = () => {
  const [isAuth, setIsAuth] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setAuthLoading(true);

      actions
        .checkParentAuth()
        .then((response) => {
          setAuthLoading(false);
          setIsAuth(response === "true");
        })
        .catch((error) => {
          setAuthError(error);
          setAuthLoading(false);
        });
    };

    if (checkIsAuthenticated()) {
      fetchData();
    }

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, []);

  return { isAuth, authLoading, authError };
};

export default useFetchAuth;
