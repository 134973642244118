/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Row, Col, Space, Collapse } from "antd";
import AppLoader from "../../components/app-loader";
import { WhatsAppOutlined } from "@ant-design/icons";
import Footer from "../../components/footer";
import Header from "../../components/header";
import RequestDemo from "./request-demo";
import Text from "src/modules/parent/components/text";
import { isBrowser } from "react-device-detect";
import noImage from "./../../assets/img/anonymous.jpeg";
import FlatButton from "src/modules/parent/components/flat-button";
import BasicFilters from "../../components/basicFilters";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import JWVideoPlayer from "src/modules/parent/components/jw-video-player";
import error from "src/modules/parent/assets/svgs/error.svg";
import {
  getTimeSlots,
  responsiveForPackageCards,
  getPackageAmount,
  checkIsAuthenticated,
  getWhatsAppUrlToOpen,
  hasHtmlTags,
  convertStringToWeekdays,
  getCountryName,
  getGrades,
} from "../../utils";
import {
  MOBILE_WHATSAPP_URL,
  WEB_WHATSAPP_URL,
  fb_pixel_id_summer,
} from "src/modules/parent/config/constant";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModalComponent from "src/modules/parent/components/modal";
import PostFeedback from "src/modules/parent/components/post-feedback";
import Carousel from "react-multi-carousel";
import PackageCard from "../../components/package-card";

import degree from "./../../assets/svgs/degree.svg";
import book from "./../../assets/svgs/book.svg";
import book2 from "./../../assets/svgs/book2.svg";
import marker from "./../../assets/svgs/marker.svg";
import icon_calendar from "../../assets/svgs/icon_calendar.svg";
import icon_time from "../../assets/svgs/clock-circle-outlined.svg";
import ReactPixel from "react-facebook-pixel";

import {
  bookDemoPackageDetails,
  trackEvents,
} from "src/modules/parent/utils/events/query";
import { getRequest } from "../../config/network";
import { GET_PACKAGE } from "../../config/api";
import onlineTag from "../../assets/svgs/onlineTag.svg";
import union from "src/modules/parent/assets/webp/union.webp";
import star from "../../assets/svgs/stardetail.svg";
import { subjectData } from "../../utils/static-data";
import BookThisCourse from "./book-this-course";
import checkmark from "src/modules/parent/assets/svgs/checkmark.svg";
import styles from "./styles.module.css";
import * as actions from "../../store/actions";
import moment from "moment-timezone";
import actionTypes from "../../store/action-types";
import { accountReadyNotification } from "../../utils/notificationData";
import FindCourses from "../../components/findCourses";
import TimeSlot from "./timeslots-modal";

ReactPixel.init(fb_pixel_id_summer);

const { Panel } = Collapse;

const ids = [
  {
    id: "5441",
    title: "March: 2nd and 3rd",
  },
  {
    id: "5476",
    title: "March: 9th and 10th",
  },
  {
    id: "5440",
    title: "April: 27th and 28th",
  },
  {
    id: "5463",
    title: "April: 20th and 21st",
  },
  {
    id: "5505",
    title: "May: 4th and 5th",
  },
];

const PackageDetails = () => {
  const { id: packageId } = useParams();
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const navigate = useNavigate();
  // const [reviews, setReviews] = useState();
  const { userInfo } = useSelector((state) => state.authReducer);
  const { moreCourses } = useSelector((state) => state.packageReducer);
  const { keywordList } = useSelector((state) => state.subjectReducer);
  const { countries } = useSelector((state) => state.profileReducer);
  const { showRegisterModal } = useSelector(
    (state) => state.notificationReducer
  );
  const { packages } = moreCourses || {};
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [country, setCountry] = useState("");
  const [isProfessional, setIsProfessional] = useState(false);
  const [packageDetail, setPackageDetail] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [desc, setDesc] = useState("");
  const [topicCovered, setTopicCovered] = useState(null);
  const [showAssist, setShowAssist] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [renderVideo, setRenderVideo] = useState("");
  const [show, setShow] = useState(false);
  const [showTimeSlot, setShowTimeSlot] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [outComesColumn1, setOutComesColumn1] = useState(null);
  const [outComesColumn2, setOutComesColumn2] = useState(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (showRegisterModal && showRegisterModal.isBookedModal) {
      setShow(true);
      action.setRegisterData(null);
    }
  }, [showRegisterModal]);

  useEffect(() => {
    if (countries && countries.length) {
      const guess = moment.tz.guess();
      const countryName = moment.tz.zone(guess).countries();

      const cName = countries.find((i) => i.code === countryName[0]);

      if (cName && cName.title) {
        setCountry(cName.title);
      }
    }
  }, [countries]);

  useEffect(() => {
    action.fetchKeywords();
  }, []);

  useEffect(() => {
    fetchSinglePackage();
  }, [packageId]);

  useEffect(() => {
    if (packageDetail) {
      if (packageDetail && packageDetail.grades) {
        fetchMoreCourses();
      }

      if (packageDetail && packageDetail.id) {
        setRenderVideo(packageDetail.id);
      }

      if (packageDetail && packageDetail.is_professional) {
        setIsProfessional(true);
      }

      const desc = hasHtmlTags(packageDetail.description);

      if (desc) {
        if (packageDetail && packageDetail.description) {
          const items = extractItems(packageDetail.description);

          const startTag = "<p>"; // Start tag of the first paragraph
          const endTag = "</p>"; // End tag of the first paragraph

          const startIdx = packageDetail.description.indexOf(startTag);
          const endIdx =
            packageDetail.description.indexOf(endTag, startIdx) + endTag.length;

          const description = packageDetail.description.substring(
            startIdx,
            endIdx
          );
          setDesc(description);
          setTopicCovered(items);
        }

        if (packageDetail && packageDetail.learning_outcome) {
          const regex = /<li>(.*?)<\/li>/g;
          const matches = packageDetail.learning_outcome.matchAll(regex);
          const outComes = Array.from(matches, (match) => match[1].trim());

          if (outComes && outComes.length) {
            const column = Math.ceil(outComes.length / 2);
            const column1 = outComes.slice(0, column);
            const column2 = outComes.slice(column);

            setOutComesColumn1(column1);
            setOutComesColumn2(column2);
          }
        }
      } else {
        if (packageDetail.description) {
          setDesc(packageDetail.description);
        }
        if (packageDetail.about_the_course) {
          const aboutCourse = JSON.parse(packageDetail.about_the_course);
          setTopicCovered(aboutCourse);
        }

        if (packageDetail.learning_outcome) {
          const outComes = JSON.parse(packageDetail.learning_outcome);
          const outComesArr = [...outComes];
          if (outComesArr && outComesArr.length) {
            const column = Math.ceil(outComesArr.length / 2);
            const column1 = outComesArr.slice(0, column);
            const column2 = outComesArr.slice(column);
            setOutComesColumn1(column1);
            setOutComesColumn2(column2);
          }
        }
      }
    }
  }, [packageDetail, packageId]);

  const fetchMoreCourses = useCallback(() => {
    if (packageDetail && packageDetail.id) {
      action.getMorePackages(packageDetail.id);
    }
  }, []);

  function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const extractItems = (str) => {
    // Step 1: Remove HTML tags
    const regex = /<strong>(.*?)<\/strong>([\s\S]*?)(?=<strong>|$)/g;
    const items = [];
    let match;

    while ((match = regex.exec(str)) !== null) {
      const point = decodeHtml(match[1]);
      const descriptions = match[2].match(/<p>.*?<\/p>/g);

      items.push({
        topic_name: point,
        topic_description: descriptions,
      });
    }
    return items;
  };

  const handleClose = () => {
    setShowAssist(false);
  };

  const getText = () => {
    return getWhatsAppUrlToOpen("course");
  };

  const bookThisCourseHandler = () => {
    if (checkIsAuthenticated()) {
      setShow(true);
    } else {
      action.setRegisterData({ show: true, type: "bookThisClass" });
    }
  };

  const fetchSinglePackage = async () => {
    try {
      const endpoint = `${GET_PACKAGE}/${packageId}`;
      const { data } = await getRequest(endpoint);
      if (data?.status === "success") {
        setPackageDetail(data?.data);

        if (data && data.data && data.data.is_olevel) {
          setTimeSlots(getTimeSlots(data.data.time_slot.slice(0, 1)));
        } else {
          setTimeSlots(getTimeSlots(data.data.time_slot));
        }
        setLoading(false);
      } else {
        setNotFound(true);
        setLoading(false);
      }
    } catch (e) {
      // axiosErrorHandler(e);
      setNotFound(true);
      setLoading(false);
    }
  };

  const feedbackSubmitted = () => {
    setShowFeedbackModal(false);
  };

  const isApplyFilter = (subjectName) => {
    let data;

    data = subjectName
      ? subjectName?.toLowerCase()
      : subjects && subjects.length
      ? subjects.toLowerCase()
      : "";

    const subjectExist = subjectData?.some(
      (i) => i.subject.toLowerCase() === data
    );
    const route = data.split(" ").join("-");

    return subjectExist ? route : false;
  };

  const basicFilterHandler = (subject, newTab) => {
    const route = isApplyFilter(subject);

    if (route) {
      if (newTab) {
        window.open(`/online-${route}`, "_blank");
      } else {
        navigate(`/online-${route}`, {
          state: {
            subject: subject ? subject : subjects,
            grade: "",
          },
        });
      }
    } else {
      navigate("/courses?program_id=5");
    }
  };

  const scrollToDiv = () => {
    if (scrollRef.current) {
      trackEvents(bookDemoPackageDetails);
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getVideo = useCallback(
    () => (
      <JWVideoPlayer
        height={216}
        url={packageDetail?.teacher?.video_id}
        title={packageDetail?.teacher?.name}
        thumbnail={packageDetail?.teacher?.picture}
        teacherId={packageDetail?.teacher?.id}
        isProfessional={isProfessional}
      />
    ),
    [packageDetail, packageId]
  );

  const goToClasses = () => {
    navigate("/courses?program_id=5");
  };

  const handleRegister = () => {
    if (packageDetail) {
      const item = packageDetail;
      let timeSlotId = "";

      if (item && item.time_slot && item.time_slot.length) {
        timeSlotId = item.time_slot[0]?.id;
      }

      const country = getCountryName();
      const data = {
        package_id: item.id,
        slot_id: timeSlotId,
        student_id: userInfo?.id,
        deal_id: null,
        self_enroll: 1,
        country,
      };

      if (checkIsAuthenticated()) {
        actions.bookCourse(data).then((response) => {
          dispatch({
            type: actionTypes.NOTIFICATIONDATA,
            payload: accountReadyNotification(),
          });
        });
      } else {
        action.setRegisterData({
          show: true,
          type: "registerClass",
          bookData: data,
        });
      }
    }
  };

  const getDate = useMemo(() => {
    if (packageDetail && packageDetail.is_workshop && packageDetail.id) {
      const packageDate = ids.find((i) => packageDetail.id.toString() === i.id);
      return packageDate.title;
    }

    return "";
  }, [packageDetail]);

  const onClickChat = () => {
    if (checkIsAuthenticated()) {
      navigate(`/chat/${packageDetail?.teacher?.id}`);
    } else {
      action.setRegisterData({
        show: true,
        type: "teacherChat",
        chat: {
          teacherId: packageDetail?.teacher?.id,
        },
      });
    }
  };

  //console.log(packageDetail);

  return (
    <div className={"package_details"}>
      {showAssist && (
        <ModalComponent
          isModalVisible={showAssist}
          onCancel={handleClose}
          maskClosable
          Component={
            <div className="p-20">
              <JWVideoPlayer
                // height={216}
                url={packageDetail?.teacher?.video_id}
                title={packageDetail?.teacher?.name}
                thumbnail={packageDetail?.teacher?.picture}
                teacherId={packageDetail?.teacher?.id}
                isProfessional={isProfessional}
                autoStart
              />
            </div>
          }
        />
      )}

      <ModalComponent
        isModalVisible={showFeedbackModal}
        onCancel={() => setShowFeedbackModal(false)}
        maskClosable={false}
        Component={
          <PostFeedback
            teacherName={packageDetail?.teacher?.name}
            feedbackSubmitted={feedbackSubmitted}
            teacherId={packageDetail?.teacher?.id}
          />
        }
      />

      {show && (
        <ModalComponent
          maskClosable={false}
          isModalVisible={show}
          onCancel={() => setShow(false)}
          containerClassName={styles.modal_container}
          Component={
            <BookThisCourse country={country} packageDetail={packageDetail} />
          }
        />
      )}

      {showTimeSlot && (
        <ModalComponent
          maskClosable={false}
          isModalVisible={showTimeSlot}
          onCancel={() => setShowTimeSlot(false)}
          containerClassName={`${styles.timeslot_modal_container} timeslot_modal_container`}
          Component={
            <TimeSlot
              packageDetail={packageDetail}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          }
        />
      )}

      <Header />

      {loading ? (
        <div className="d-flex justify-content-center mt-50 pt-40 mb-50">
          <AppLoader className="font-28" />
        </div>
      ) : (
        <>
          {packageDetail && (
            <Fragment>
              {/* Search by Subject Start */}
              <div className="app-container mt-50 mb-30">
                <Row gutter={[24, 24]}>
                  <BasicFilters
                    allowClear={false}
                    gradeFilterDisabled
                    subjects={subjects}
                    keywordList={keywordList}
                    setSubjects={setSubjects}
                    basicFilterHandler={() => basicFilterHandler()}
                  />
                </Row>
              </div>
              {/* Search by Subject End */}
              {/* HeroSection Start */}
              <div className={styles.mainHeadingContainer}>
                <div className="app-container pt-20 pb-20">
                  <Row>
                    <Col>
                      <div className={styles.routeName}>
                        <Link to={"/courses"}>{"All Courses"}</Link>
                      </div>
                    </Col>
                    <div className={styles.slash}>{"/"}</div>

                    <Col>{packageDetail?.title}</Col>
                  </Row>

                  <Row gutter={[0, 20]} justify={"space-between"}>
                    <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
                      <h1
                        className="mt-30 mb-20 font-weight-600"
                        style={{ color: "#005EFF" }}
                      >
                        {packageDetail?.title?.toUpperCase()}
                      </h1>

                      <Row justify={"start"} className="mt-20">
                        <img
                          src={union}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                          alt={"union"}
                        />

                        <p
                          className={[styles.lHeight, styles.timeSlot].join(
                            " "
                          )}
                        >
                          Grade {getGrades(packageDetail)}
                        </p>
                      </Row>

                      <p
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(desc),
                        }}
                      ></p>

                      <Row
                        gutter={[10, 10]}
                        align={"middle"}
                        className="font-14 mt-20 mb-20"
                      >
                        {packageDetail.package_mode === "online" && (
                          <Col>
                            <img src={onlineTag} alt={"online"} />
                          </Col>
                        )}

                        {packageDetail?.teacher?.ratings > 0 && (
                          <Col>
                            <span style={{ marginRight: "3px" }}>
                              {packageDetail?.teacher?.ratings}
                            </span>

                            <span>
                              {new Array(5).fill(0).map((i, index) => (
                                <img key={index} src={star} alt={"Star"} />
                              ))}
                            </span>
                          </Col>
                        )}
                      </Row>

                      <Row className="font-14 mt-20 mb-20">
                        <Space size={"middle"}>
                          <Col className="font-weight-500">{"Created by "}</Col>

                          <Col>
                            <Link
                              to={`/teachers/${packageDetail?.teacher?.id}`}
                            >
                              {packageDetail?.teacher?.name}
                            </Link>
                          </Col>
                        </Space>
                      </Row>

                      {packageDetail?.is_workshop && (
                        <Row className="font-14 mt-20 mb-20">
                          <Space size={"middle"}>
                            <Col>
                              <img src={icon_calendar} alt={"Calendar"} />
                            </Col>

                            <Col className="font-weight-500">{"Date: "}</Col>

                            <Col>{getDate}</Col>
                          </Space>
                        </Row>
                      )}

                      {packageDetail?.subjects &&
                        packageDetail?.subjects.length > 0 && (
                          <Row>
                            <Space>
                              <Col>
                                <img src={book2} alt="Book" />
                              </Col>

                              <Col className="font-weight-500">
                                {"Subjects"}
                              </Col>

                              <Col>
                                {packageDetail?.subjects
                                  .map((i) => i.subject)
                                  .join(", ")}
                              </Col>
                            </Space>
                          </Row>
                        )}

                      {packageDetail &&
                        packageDetail.time_slot &&
                        packageDetail.time_slot.length > 0 &&
                        packageDetail.time_slot.map((item, index) => {
                          return (
                            <Row
                              key={index.toString()}
                              className="font-14 mt-20"
                            >
                              <Space size={"small"}>
                                <Col
                                  style={{ minWidth: "max-content" }}
                                  className="font-weight-600"
                                >
                                  {`Slot ${index + 1}:`}
                                </Col>

                                <Col>
                                  <img src={icon_calendar} alt={"Calendar"} />
                                </Col>

                                <Col>
                                  {item.days ? (
                                    <Text
                                      size={"S"}
                                      font={"500"}
                                      title={convertStringToWeekdays(item.days)}
                                    />
                                  ) : (
                                    <Text
                                      size={"S"}
                                      font={"500"}
                                      title={convertStringToWeekdays(
                                        item.slot_days_relation
                                          .map((item) => item.day)
                                          .join(",")
                                      )}
                                    />
                                  )}
                                </Col>
                                <Col
                                  style={{
                                    backgroundColor: "#EC7E18",
                                    padding: "4px 12px",
                                    "border-radius": "25px",
                                  }}
                                >
                                  {item.days ? (
                                    <Text
                                      customStyles={{ minWidth: "36px" }}
                                      size={"S"}
                                      font={"NORMAL"}
                                      hoveredTitle={" "}
                                      center
                                      color={"#fff"}
                                    >
                                      {item.days.split(",").length} days a week
                                    </Text>
                                  ) : (
                                    <Text
                                      customStyles={{ minWidth: "36px" }}
                                      size={"S"}
                                      font={"NORMAL"}
                                      hoveredTitle={" "}
                                      center
                                      color={"#fff"}
                                    >
                                      {item.slot_days_relation.length} days a
                                      week
                                    </Text>
                                  )}
                                  {/* <Text size={"S"} font={"NORMAL"} hoveredTitle={" "} center color={"#fff"}>
                                    {item.slot_days_relation.length} days a week
                                  </Text> */}
                                </Col>
                                {packageDetail &&
                                  packageDetail.time_slot &&
                                  packageDetail.time_slot.length > 0 && (
                                    <>
                                      <Col>
                                        <img src={icon_time} alt={"Calendar"} />
                                      </Col>
                                      <Col>
                                        <Text
                                          handleClick={() => {
                                            setActiveTab(index);
                                            setShowTimeSlot(true);
                                          }}
                                          className={"add-link"}
                                          size={"S"}
                                          font={"NORMAL"}
                                          hoveredTitle={" "}
                                          center
                                          color={"#005EFF"}
                                        >
                                          Click Here for Timings
                                        </Text>
                                      </Col>
                                    </>
                                  )}
                              </Space>
                            </Row>
                          );
                        })}

                      {/* {packageDetail &&
                        packageDetail.time_slot &&
                        packageDetail.time_slot.length > 0 && (
                          <>
                            <Col>
                              <FlatButton
                                title={"View Time-slots"}
                                onClick={() => setShowTimeSlot(true)}
                                className={styles.btnStyle}
                                height={47}
                                width={250}
                              />
                            </Col>
                          </>
                        )} */}
                    </Col>

                    <Col
                      className={styles.illustration}
                      xxl={8}
                      xl={8}
                      lg={8}
                      md={24}
                      sm={24}
                      xs={24}
                    >
                      <div className={styles.teacherVideo}>
                        <div className={styles.video}>
                          {packageDetail && packageDetail.id === renderVideo
                            ? getVideo()
                            : null}

                          <div
                            onClick={() => setShowAssist(true)}
                            className={styles.blur}
                          />
                        </div>

                        <Row
                          align={"middle"}
                          className="mt-30"
                          justify={"space-between"}
                        >
                          <h1 className="mb-0 font-weight-600">
                            {getPackageAmount(packageDetail).replace(
                              isProfessional ? "/ course" : "/ month",
                              ""
                            )}
                          </h1>
                          <div
                            className={[
                              "font-weight-500 font-20",
                              styles.priceMonth,
                            ].join(" ")}
                          >
                            {isProfessional
                              ? "Per course"
                              : packageDetail?.is_workshop
                              ? "Per workshop"
                              : "Per month"}
                          </div>
                        </Row>

                        <Row className="mt-10" gutter={[0, 20]}>
                          {!packageDetail?.is_workshop ? (
                            <Col span={24}>
                              <FlatButton
                                width="100%"
                                type="Quaternary"
                                onClick={scrollToDiv}
                                title={"Book a free demo"}
                              />
                            </Col>
                          ) : (
                            <Col span={24}>
                              <FlatButton
                                width="100%"
                                type="Quaternary"
                                onClick={handleRegister}
                                title={"Register Now"}
                              />
                            </Col>
                          )}

                          {isBrowser && (
                            <Col span={24}>
                              <FlatButton
                                width="100%"
                                type="Secondary"
                                onClick={onClickChat}
                                title={"Chat"}
                              />
                            </Col>
                          )}

                          <Col span={24}>
                            {isBrowser ? (
                              <FlatButton
                                width="100%"
                                type="QuaternaryOutline"
                                onClick={() =>
                                  window.open(`${WEB_WHATSAPP_URL}${getText()}`)
                                }
                                title={
                                  <div>
                                    <span>Share</span>
                                    <WhatsAppOutlined className="font-18 ml-15" />
                                  </div>
                                }
                              />
                            ) : (
                              <a
                                href={`${MOBILE_WHATSAPP_URL}${getText()}`}
                                data-action={"share/whatsapp/share"}
                                rel={"noreferrer"}
                                target={"_blank"}
                              >
                                <FlatButton
                                  width="100%"
                                  type="QuaternaryOutline"
                                  title={
                                    <div>
                                      <span>Share</span>
                                      <WhatsAppOutlined className="font-18 ml-15" />
                                    </div>
                                  }
                                />
                              </a>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* HeroSection End */}
              {/* Request a Demo Class Start */}
              {!packageDetail.is_workshop && (
                <div ref={scrollRef} className="app-container mt-30 mb-50">
                  <RequestDemo
                    packageDetail={packageDetail}
                    country={country}
                    timeSlots={timeSlots}
                  />

                  <Row
                    style={{ textAlign: "center", marginTop: "30px" }}
                    align={"middle"}
                    justify={"center"}
                  >
                    <Col span={24}>
                      <h2>{"Enjoyed this Demo Class? Buy this Course Now!"}</h2>
                    </Col>

                    <Col span={24}>
                      <FlatButton
                        type="Primary"
                        onClick={bookThisCourseHandler}
                        title={"Buy This Course!"}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {/* Request a Demo Class End */}
              {/* Learning Outcomes Start */}
              <div
                className={["app-container p-50 mt-30", styles.learning].join(
                  " "
                )}
              >
                <Row gutter={[20, 20]}>
                  <Col style={{ textAlign: "center" }} span={24}>
                    <div className="font-26 font-weight-600">
                      {"Learning Outcomes"}
                    </div>
                  </Col>

                  <Row gutter={[20, 20]}>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row gutter={[20, 20]}>
                        {outComesColumn1?.map((i, index) => (
                          <Col key={index} span={24}>
                            <Space size={"middle"}>
                              <img src={checkmark} alt={"checkmark"} />

                              <p
                                className={styles.outcomes}
                                dangerouslySetInnerHTML={{
                                  __html: decodeHtml(i),
                                }}
                              ></p>
                            </Space>
                          </Col>
                        ))}
                      </Row>
                    </Col>

                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                      <Row gutter={[20, 20]}>
                        {outComesColumn2?.map((i, index) => (
                          <Col key={index} span={24}>
                            <Space size={"middle"}>
                              <img src={checkmark} alt={"checkmark"} />

                              <p
                                className={styles.outcomes}
                                dangerouslySetInnerHTML={{
                                  __html: decodeHtml(i),
                                }}
                              ></p>
                            </Space>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Row>
              </div>
              {/* Learning Outcomes End */}
              {/* Topic Covered Start */}
              {topicCovered && topicCovered.length > 0 && (
                <div className="app-container mt-50 mb-50">
                  <div className={"titleHeading"}>{"Topics Covered"}</div>

                  <div className="mt-20">
                    {topicCovered && topicCovered.length > 0 && (
                      <Collapse
                        bordered={false}
                        defaultActiveKey={["1"]}
                        className={styles.collapse}
                        accordion
                      >
                        {topicCovered.map((i, index) => (
                          <Panel
                            header={`Module ${index + 1}: ${i.topic_name}`}
                            key={Number(index + 1)}
                          >
                            {typeof i.topic_description === "string" ? (
                              <p>{i.topic_description}</p>
                            ) : (
                              i.topic_description &&
                              i.topic_description.length > 0 &&
                              i.topic_description.map((j, jIndex) => (
                                <p
                                  key={jIndex}
                                  dangerouslySetInnerHTML={{
                                    __html: decodeHtml(j),
                                  }}
                                ></p>
                              ))
                            )}
                          </Panel>
                        ))}
                      </Collapse>
                    )}
                  </div>
                </div>
              )}
              {/* Topic Covered End */}
              {/* Instructor Start */}
              <div className="app-container mt-50 mb-50">
                <div className={"titleHeading"}>{"Instructor"}</div>

                <Row className="mt-50 mb-50">
                  <Col xxl={5} xl={5} lg={7} md={8} sm={10} xs={24}>
                    <div className={styles.teacherImage}>
                      <img
                        src={packageDetail?.teacher?.picture || noImage}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt="teacherImage"
                      />
                    </div>
                  </Col>

                  <Col xxl={19} xl={19} lg={17} md={16} sm={14} xs={24}>
                    <Row
                      gutter={[10, 10]}
                      align={"middle"}
                      className="font-14 mt-20 mb-10"
                    >
                      <Space>
                        <div className="font-28 font-weight-500">
                          {packageDetail?.teacher?.name}
                        </div>

                        {packageDetail?.teacher?.ratings > 0 && (
                          <Col>
                            <span className="font-weight-600">{`${packageDetail?.teacher?.ratings} `}</span>

                            <span>
                              {new Array(5).fill(0).map((i, index) => (
                                <img key={index} src={star} alt={"Star"} />
                              ))}
                            </span>
                          </Col>
                        )}
                      </Space>
                    </Row>

                    <Row>
                      <div className="font-20">
                        {packageDetail?.teacher?.qualification_level &&
                          packageDetail?.teacher?.qualification_level?.name}
                      </div>
                    </Row>

                    <div className={styles.details}>
                      {(packageDetail?.teacher?.city ||
                        packageDetail?.teacher?.country) && (
                        <div
                          className={[styles.t_location, "d-flex"].join(" ")}
                        >
                          <img className="mt-2" alt="" src={marker} />
                          <p className="font-14 font-weight-400 mb-0 overflow-elipsis">
                            {packageDetail?.teacher?.city
                              ? `${packageDetail?.teacher?.city}, `
                              : ""}
                            {packageDetail?.teacher?.country
                              ? `${packageDetail?.teacher?.country}`
                              : ""}
                          </p>
                        </div>
                      )}
                      {packageDetail?.teacher?.subjects && (
                        <div
                          className={[styles.t_location, "d-flex"].join(" ")}
                        >
                          <div className="dark-grey-color">
                            <img className="mt-2" alt="" src={book} />
                          </div>
                          <p className="font-14 font-weight-400 mb-0 overflow-elipsis">
                            {packageDetail?.teacher?.subjects?.join(", ")}
                          </p>
                        </div>
                      )}
                      {packageDetail?.teacher?.latest_degree && (
                        <div
                          className={[styles.t_location, "d-flex"].join(" ")}
                        >
                          <img className="mt-2" alt="" src={degree} />
                          <p className="font-14 font-weight-400 mb-0 overflow-elipsis">
                            {packageDetail.teacher?.latest_degree}
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Text
                    size={"M2"}
                    color={"#000000"}
                    lHeight={"S"}
                    font={"NORMAL"}
                    title={packageDetail?.teacher?.bio}
                  />
                </Row>
              </div>
              {/* Instructor End */}
              {/* More Courses Start */}
              <div
                className={[styles.mainHeadingContainer, "pt-50 pb-50"].join(
                  " "
                )}
              >
                <Row className="mb-20" justify={"center"}>
                  <div className={"titleHeading"}>
                    {"More Courses you might be interested in"}
                  </div>
                </Row>

                <div className="app-container">
                  {packages && packages.length > 0 && (
                    <Carousel responsive={responsiveForPackageCards}>
                      {packages.map((item) => (
                        <PackageCard
                          item={item}
                          teacher={item.teacher}
                          key={item?.id}
                        />
                      ))}
                    </Carousel>
                  )}
                </div>

                <Row justify={"center"} className="mt-20">
                  <FlatButton
                    title={"Show more"}
                    onClick={() => {
                      if (isProfessional) {
                        navigate("/professional-courses");
                      } else {
                        navigate("/courses?program_id=5");
                      }
                    }}
                    type={"Secondary"}
                    className={styles.showBtn}
                  />
                </Row>
              </div>
              {/* More Courses End */}
              <FindCourses />
              <Footer />
            </Fragment>
          )}
        </>
      )}

      {notFound && (
        <Row className={styles.errorDiv} gutter={[0, 50]}>
          <Col span={24}>
            <Row justify={"center"}>
              <div className={"titleHeading"}>{"Oops!"}</div>
            </Row>
          </Col>

          <Col span={24}>
            <Row justify={"center"}>
              <img src={error} alt="GIF" className={styles.banner_gif} />
            </Row>
          </Col>

          <Col span={24}>
            <Row justify={"center"}>
              <div className={styles.noFoundText}>
                {
                  "It looks like we don’t have a course that matches your exact preference."
                }
              </div>
            </Row>
          </Col>

          <Col span={24}>
            <Row align={"middle"} justify={"center"}>
              <FlatButton
                type="Quaternary"
                onClick={goToClasses}
                title={"Go back to Classes"}
              />
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PackageDetails;
