import React from "react";

import { Row, Space, Button } from "antd";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import chevronDown from "../../assets/webp/chevron-down.webp";
import { naplanGrades } from "../../staticData";
import searchIcon from "../../assets/webp/Search.webp";

import styles from "./styles.module.css";

const getValue = (id) => {
  if (id && id[0]) {
    const grade = naplanGrades.find((i) => i.id === id[0]);
    return grade?.label;
  }
};

const SearchBoxMobile = ({
  isLoading = false,
  handleSearch = () => {},
  handleSelectField = () => {},
  handleClear = () => {},
  selectedItem,
}) => (
  <div className={styles.searchBoxWrapper}>
    <div onClick={handleSelectField} className={styles.leftContent}>
      <Space className={"w-100"} direction={"vertical"}>
        <Text size={"S"} font={"SEMIBOLD"} title={"Grade"} />

        <Text
          size={"S"}
          color={
            selectedItem && selectedItem.grades
              ? "rgba(0, 94, 255, 1)"
              : "#8F8F8F"
          }
          className={styles.description}
          title={
            selectedItem && selectedItem.grades
              ? getValue(selectedItem.grades)
              : "of the student"
          }
        />
      </Space>

      <Row justify={"end"} className={styles.iconStyle}>
        <Space>
          {selectedItem && selectedItem.grades && (
            <div
              onClick={(e) => {
                e.preventDefault();
                handleClear();
                e.stopPropagation();
              }}
              className={styles.closeIcon}
            >
              {"x"}
            </div>
          )}

          <img src={chevronDown} width={20} height={20} alt={"chevron"} />
        </Space>
      </Row>
    </div>

    {!isMobile && (
      <div>
        <Button
          loading={isLoading}
          onClick={handleSearch}
          className={styles.searchButton}
        >
          <Space>
            {"Search"}

            <img src={searchIcon} width={30} height={30} alt="searchIcon" />
          </Space>
        </Button>
      </div>
    )}
  </div>
);

export default React.memo(SearchBoxMobile);
