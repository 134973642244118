import React, { useState } from "react";

import { Row } from "antd";
import { isBrowser } from "react-device-detect";
import YouTube from "react-youtube";

import Text from "src/modules/parent/components/text";
import YoutubeModal from "src/modules/parent/components/modal/youtube-modal";

import styles from "./styles.module.css";

const opts2 = {
  height: window?.innerWidth < 991 ? 250 : 520,
  width: "100%",
  playerVars: {
    autoplay: 0,
    rel: 0,
  },
};

const ClassSnippet = ({
  videoLink = { browser: "", mobile: "" },
  customStyles = {},
}) => {
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const playerRef = React.useRef(null);

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  };

  const setModalAndMedia = (videoId) => {
    setShowModal(true);
    setModalData({ url: videoId });
  };

  return (
    <div className={[styles.classSnippet, customStyles].join(" ")}>
      {modalData && showModal && (
        <YoutubeModal
          show={showModal}
          url={modalData.url}
          setShow={setShowModal}
        />
      )}

      <Text
        size={"XXL"}
        center
        font={"SEMIBOLD"}
        lHeight={"S"}
        title={"Hear from our Founders!"}
      />

      <Row gutter={[20, 20]} className={"mt-20 mb-50"} justify={"center"}>
        <div style={{ width: isBrowser ? "70%" : "90%", position: "relative" }}>
          <YouTube
            ref={playerRef}
            videoId={isBrowser ? videoLink.browser : videoLink.mobile}
            opts={opts2}
            onReady={onPlayerReady}
            loading={"eager"}
          />

          <div
            className={styles.overlayClass}
            onClick={() =>
              setModalAndMedia(isBrowser ? videoLink.browser : videoLink.mobile)
            }
          />
        </div>
      </Row>
    </div>
  );
};

export default React.memo(ClassSnippet);
