import { Dropdown, Badge, Button } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./../styles.module.css";
import ProfileMenuOptions from "../profile-menu-options";
import CoursesMenuOptions from "../coursesMenuOptions";
import notificationBell from "src/modules/parent/assets/svgs/bell.svg";
import messageIcon from "src/modules/parent/assets/svgs/message.svg";
import NotificationMenuOptions from "../notifications";
import dropdownIcon from "../assets/svgs/dropdown.svg";
import { useSelector } from "react-redux";
import UserAvatar from "../../avatar";
import { checkStudentGrades } from "src/modules/parent/utils";
import {
  learningLibraryHeader,
  trackEvents,
} from "src/modules/parent/utils/events/query";

const AuthContent = ({
  userInfo,
  logout,
  onClickNotification = () => {},
  handleTeacherModal = () => {},
}) => {
  const [notificationBadge, setNotificationBadge] = useState(0);
  const [isShowLibrary, setIsShowLibrary] = useState(false);
  const { notifications } = useSelector((state) => state.notificationReducer);
  const { activeStudents, selectedChild } = useSelector(
    (state) => state.authReducer
  );
  const { chatCount } = useSelector((state) => state.chatReducer);

  useEffect(() => {
    if (selectedChild) {
      const isShow = checkStudentGrades(selectedChild);
      setIsShowLibrary(isShow);
    }
  }, [selectedChild]);

  useEffect(() => {
    if (notifications && notifications.length) {
      const count = notifications.filter((obj) => obj.is_read === 0).length;
      setNotificationBadge(count);
    }
  }, [notifications]);

  return (
    <Fragment>
      <div
        className={[
          styles.web_header,
          "align-items-center",
          styles.header,
        ].join(" ")}
      >
        <Dropdown
          className={"ml-20 mr-20"}
          overlay={<CoursesMenuOptions />}
          placement={"bottomLeft"}
        >
          <div className={"d-flex cursor-pointer"}>
            <span className={styles.navbar_item}>Find your Tutor</span>
            <span className={[styles.navbar_item, "ml-10"].join(" ")}>
              <img src={dropdownIcon} alt={"Dropdown"} />
            </span>
          </div>
        </Dropdown>

        {activeStudents && isShowLibrary && (
          <NavLink
            to={"/learning-library/resources/math"}
            activeclassname={styles.active_navbar_item}
            onClick={() => trackEvents(learningLibraryHeader)}
            className={[styles.navbar_item, "ml-20 mr-20"].join(" ")}
          >
            <span>Learning Library</span>
          </NavLink>
        )}
        <NavLink
          to={"/trivia"}
          activeclassname={styles.active_navbar_item}
          className={[styles.navbar_item, "ml-20 mr-20"].join(" ")}
        >
          <span>Trivia 2025</span>
        </NavLink>
        <span className="newPillsTag">new</span>
      </div>

      <div
        className={`justify-content-end ${
          styles.web_header
        } ${"align-items-center"}`}
      >
        <Button onClick={handleTeacherModal} className={styles.teacherButton}>
          {"Are you a Teacher?"}
        </Button>

        <NavLink
          to={"/chat"}
          activeclassname={styles.active_navbar_item}
          className={[styles.navbar_item, "ml-20 mr-20"].join(" ")}
        >
          <Badge count={chatCount}>
            <img src={messageIcon} alt={"message-icon"} />
          </Badge>
          <div className={styles.navbar_item_bottom_div} />
        </NavLink>

        <Dropdown
          overlay={
            <NotificationMenuOptions
              handleNotificationClick={onClickNotification}
            />
          }
          placement={"bottomRight"}
        >
          <div className={"d-flex mr-20 cursor-pointer"}>
            <Badge count={notificationBadge}>
              <img src={notificationBell} alt={"Notification"} />
            </Badge>
          </div>
        </Dropdown>

        <span className={`${styles.username} mr-15`}>
          {userInfo?.name || ""}
        </span>
        <Dropdown
          overlay={<ProfileMenuOptions logout={logout} />}
          placement={"bottomRight"}
        >
          <div className={"d-flex cursor-pointer"}>
            <UserAvatar name={userInfo.name} color={userInfo.color || "blue"} />
          </div>
        </Dropdown>
      </div>
    </Fragment>
  );
};

export default AuthContent;
