import React from "react";

import { Col, Row, Space } from "antd";

import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";
import { isBrowser } from "react-device-detect";

const HowToApply = ({ handleClick = () => {} }) => (
  <div className={styles.applyModal}>
    <Row justify={"center"}>
      <Text
        size={isBrowser ? "XXL" : "M"}
        font={"SEMIBOLD"}
        lHeight={"S"}
        color={"rgba(0, 0, 0, 1)"}
        title={"What are you applying for?"}
        center
      />
    </Row>

    <Row gutter={[20, 20]} className="mt-50 mb-30">
      <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
        <div onClick={() => handleClick("k8")} className={styles.card1}>
          <Space direction={"vertical"}>
            <Text
              size={isBrowser ? "XXL" : "M"}
              font={"NORMAL"}
              color={"#000000"}
              lHeight={"S"}
              title={"KG - Grade 8"}
              className={isBrowser ? styles.txt : ""}
              center
            />

            {isBrowser && (
              <Text
                size={"XS"}
                color={"#000000"}
                lHeight={"S"}
                title={
                  "Looking to teach classes from Nursery to Grade 8 for subjects like math, English, Urdu or creative writing?"
                }
                center
              />
            )}
          </Space>
        </div>
      </Col>

      <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
        <div
          onClick={() => handleClick("shortcourse")}
          className={styles.card2}
        >
          <Space direction={"vertical"}>
            <Text
              size={isBrowser ? "XXL" : "M"}
              font={"NORMAL"}
              color={"rgba(0, 0, 0, 1)"}
              className={isBrowser ? styles.txt : ""}
              lHeight={"S"}
              title={"Short Course"}
              center
            />

            {isBrowser && (
              <Text
                size={"XS"}
                color={"#000000"}
                lHeight={"S"}
                title={
                  "Looking to teach a language like French, Mandarin or Arabic – or a skill like coding?"
                }
                center
              />
            )}
          </Space>
        </div>
      </Col>

      <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
        <div onClick={() => handleClick("examprep")} className={styles.card3}>
          <Space direction={"vertical"}>
            <Text
              size={isBrowser ? "XXL" : "M"}
              font={"NORMAL"}
              color={"rgba(0, 0, 0, 1)"}
              className={isBrowser ? styles.txt : ""}
              title={"Exam Prep"}
              center
            />

            {isBrowser && (
              <Text
                size={"XS"}
                color={"#000000"}
                lHeight={"S"}
                title={
                  "Looking to teach students in the 9th grade or above for Cambridge , Pearson, IB, AQA or similar boards?"
                }
                center
              />
            )}
          </Space>
        </div>
      </Col>
    </Row>
  </div>
);

export default React.memo(HowToApply);
