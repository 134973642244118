import React, { Fragment } from "react";

import { Col, Row, Space } from "antd";
import { Collapse } from "react-collapse";

import arrowUp from "../../assets/svgs/arrowUp.svg";
import arrowDown from "../../assets/svgs/arrowDown.svg";
import arrowUpColor from "../../assets/svgs/arrowUpColor.svg";
import arrowDownColor from "../../assets/svgs/arrowDownColor.svg";
import arrowColor from "../../assets/svgs/arrowColor.svg";
import arrow from "../../assets/svgs/arrow.svg";
import Text from "src/modules/parent/components/text";

import styles from "../../styles.module.css";
import "./index.css";

const getIcon = (opened, selected) => {
  if (selected) {
    return opened ? arrowDownColor : arrowUpColor;
  } else {
    return opened ? arrowDown : arrowUp;
  }
};

const CollapsableItem = ({
  title,
  data = [],
  isOpened = false,
  type,
  selectedTab,
  id,
  handleClick = () => {},
  handleCollapseClick = () => {},
}) => (
  <Fragment>
    <Row
      onClick={handleClick}
      className={
        selectedTab?.itemId !== id ? styles.content : styles.conntentSelected
      }
      justify={"space-between"}
    >
      <Text
        font={"LIGHTER"}
        className={
          selectedTab?.itemId === id
            ? styles.titleTextSelected
            : styles.titleText
        }
        size={"M2"}
      >
        {title}
      </Text>

      {type === "collapse" && (
        <img src={getIcon(isOpened, selectedTab?.itemId === id)} alt="" />
      )}
    </Row>

    {data && data.length > 0 && (
      <Collapse isOpened={isOpened}>
        <Row className={"mt-10"} gutter={[0, 10]}>
          {data.map((item, index) => (
            <Col span={24}>
              <div
                onClick={() => handleCollapseClick(index)}
                className={[
                  styles.tab,
                  selectedTab?.collapsedId === index ? styles.tabSelected : {},
                ].join(" ")}
              >
                <Space>
                  {selectedTab?.collapsedId === index ? (
                    <img src={arrowColor} alt={"arrow"} />
                  ) : (
                    <img src={arrow} alt={"arrow"} />
                  )}

                  <Text
                    font={"LIGHTER"}
                    color={
                      selectedTab?.collapsedId === index
                        ? "#005EFF"
                        : "rgba(113, 106, 107, 1)"
                    }
                    size={"S"}
                  >
                    {item.label}
                  </Text>
                </Space>
              </div>
            </Col>
          ))}
        </Row>
      </Collapse>
    )}
  </Fragment>
);

export default CollapsableItem;
