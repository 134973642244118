import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import styles from "./styles.module.css";

const PrivacyAndPolicy = () => {
  return (
    <div>
      <Header />
      <div className="app-container">
        <div className={styles.privacy_and_policy}>
          <div>
            <p className={styles.heading1}>Privacy Policy for Dot & Line</p>
            <p className="mb-0 pb-40 mt-5 grey-color text-align-center">
              Last Updated: Augist 19, 2022
            </p>
          </div>
          <div>
            <p className={styles.heading2}>Welcome to Dot & Line!</p>
            <p className={styles.paragraph}>
              Dot & Line is committed to protecting your privacy: it’s the
              foundation for a safe and trusted community. Our Dot & Line
              Privacy Statement describes our privacy practices when you use our
              services or visit our public website. <br />
              <br />
              Dot & Line provides some services to you through other
              organizations, such as schools and businesses. When your
              organization gives you access to our services or you use Dot &
              Line with an account tied to your organization, and where we work
              solely on their behalf, this Privacy Statement does not apply.
              Instead, your use of those services is subject to their privacy
              policies. When your organization gives you a voucher or offer code
              that lets you create an account directly with Dot & Line, we are
              not acting on behalf of those organizations and this Privacy
              Statement will apply. <br />
              <br />
              Because Dot & Line is a learning environment for children, we take
              additional measures to safeguard children’s privacy, including for
              children under the age of 13.
            </p>

            <p className={styles.heading2}>Our Privacy Statement</p>
            <p className={styles.paragraph}>
              Dot & Line is a service that allows registered parents or
              guardians (“Parents”) to enroll learners (“Learners”) in online
              small-group classes, and for registered teachers (“Teachers”) to
              create, publicize, and provide those classes (the “Services”).
              This Privacy Statement describes what personal information Dot and
              Line collects from registered Parents, Teachers, and Learners of
              the Dot & Line Services, as well as how Dot & Line collects and
              uses personal information from visitors to our public website and
              our mobile app (collectively, “Sites”).
            </p>

            <p className={styles.heading3}>Information We Collect</p>
            <p className={styles.paragraph}>
              When you visit our Site, we may collect information about you
              that, alone or in combination, could be used to identify you, your
              device, or household (“Personal Data”), as further described
              below:
            </p>

            <p className={styles.heading3}>
              Personal Data That You Provide Through the Site:
            </p>
            <p className={styles.paragraph}>
              Personal Data from you when you directly provide it to us, such as
              when you register for an Dot & Line account to use the Services.
              For example, we may collect the following Personal Data from you:
            </p>

            <p className={styles.heading3}>Contact Information</p>
            <ul className={"mb-30"}>
              <li>
                <p className={styles.paragraph2}>Full Name</p>
              </li>
              <li>
                <p className={styles.paragraph2}>Email address</p>
              </li>
              <li>
                <p className={styles.paragraph2}>Phone number</p>
              </li>
              <li>
                <p className={styles.paragraph2}>Postal and billing address</p>
              </li>
            </ul>
            <p className={styles.heading3}>Payment Information</p>
            <ul className={"mb-30"}>
              <li>
                <p className={styles.paragraph2}>
                  Credit card or other payment information
                </p>
              </li>
            </ul>

            <p className={styles.heading3}>Demographic Data</p>
            <p className={styles.paragraph}>
              We collect age, month and year of birth, gender, marital status,
              and similar demographic details, such as when you voluntarily
              provide information in the “Profile” section or communicate with
              us.
            </p>

            <p className={styles.heading3}>Sensitive Data</p>
            <p className={styles.paragraph}>
              Parents may voluntarily provide sensitive information such as
              health or disability information included in forms or messages
              sent to Teachers to aid with learning. Parents may also enroll
              children in special classes offered for children with
              disabilities. Teachers and Learners are otherwise not allowed to
              have discussions that may elicit or result in sharing of sensitive
              information. <br /> <br /> We may also receive sensitive criminal
              history information about Teachers when we receive background
              reports.
            </p>

            <p className={styles.heading3}>Content and Files</p>
            <ul className={"mb-30"}>
              <li>
                <p className={styles.paragraph2}>
                  Classes for which you have enrolled
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Video of your image and audio of your voice during classes
                  which are sometimes recorded
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Content and communications that you may include or upload like
                  your profile photo, and photos, documents, and messages you
                  share with the class or groups
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Your voluntarily provided profile picture
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Information voluntarily provided in the “profile” section
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Information voluntarily provided through reviews
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Information Teachers provide to let us run background checks
                  and background check reports we receive from third parties
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Notes you provide to Teachers about yourself or your Learner
                  when enrolling a Learner in a class.
                </p>
              </li>
            </ul>

            <p className={styles.heading3}>Passively Collected Data</p>
            <p className={styles.paragraph}>
              When you use or interact with us through our Site, we and our
              third-party partners may collect, receive, and store certain data
              automatically. For example,
            </p>

            <p className={styles.heading3}>Usage data</p>
            <p className={styles.paragraph}>
              We collect usage data (such as login times, search terms you used,
              and pages you viewed), and other information about your device and
              browser.
            </p>

            <p className={styles.heading3}>
              Identifiers and device information
            </p>
            <p className={styles.paragraph}>
              We collect IP Address, device identifiers such as cookie
              identifiers and mobile device identifiers (see our Cookie Policy,
              below), your device’s operating system type, and browser type. We
              may use such information and pool it with other information to
              track usage and device and system data, for example, the total
              number of visitors to our Sites and the number of visitors to each
              page of our website. We and our partners may also use this data
              for interest-based advertising.
            </p>

            <p className={styles.heading3}>Inferred Data</p>
            <p className={styles.paragraph}>
              We may infer new information from other data we collect, including
              using passive, automated means, to generate information about your
              likely interests (“inferences”). We may also infer your general
              geographic location (such as city, state, and country) based on
              your IP address.
            </p>

            <p className={styles.heading3}>Aggregated Personal Data</p>
            <p className={styles.paragraph}>
              We may aggregate data, including Personal Data, and use such
              aggregated data for any purpose. This aggregate information does
              not identify you personally.
            </p>

            <p className={styles.heading3}>Required Personal Data</p>
            <p className={styles.paragraph}>
              Some Personal Data is required such as credit card payment used by
              Parents to purchase classes and for verified parental consent.
              Teachers are also required to let us access Personal Data relating
              to background checks. Teachers are also required to have their
              cameras on while teaching a live class. Learners are required,
              subject to authorized exceptions, to have their video enabled at
              least at the start of class to verify their identity to protect
              the safety of other Learners. You may decline to provide certain
              Personal Data.
            </p>

            <p className={styles.heading3}>Sources of Personal Data</p>
            <p className={styles.paragraph2}>
              You are the main source of Personal Data we have about you. We may
              also obtain any of the above categories of information from third
              parties. These third-party sources include, for example:
            </p>
            <ul className={"mb-30"}>
              <li className="mt-10">
                <p className={styles.paragraph2}>
                  <em>Third party partners :</em> Third party applications and
                  services, including social networks you choose to connect with
                  or interact with through our services.
                </p>
                <li>
                  <p className={styles.paragraph2}>
                    <em>Co-Branding/Joint Partners :</em> Partners with which we
                    offer co-branded services or engage in joint marketing
                    activities.
                  </p>
                </li>
                <li>
                  <p className={styles.paragraph2}>
                    <em>Service providers :</em> Third parties that collect or
                    provide data in connection with work they do on our behalf,
                    for example companies that determine your device’s location
                    based on its IP address.
                  </p>
                </li>
                <li>
                  <p className={styles.paragraph2}>
                    <em>Publicly available sources :</em> Public sources of
                    information such as open government databases and background
                    check services we used for Teachers.
                  </p>
                </li>
              </li>
            </ul>

            <p className={styles.heading3}>Our Use of Your Personal Data</p>
            <p className={styles.paragraph2}>
              We may use any of the above categories of your Personal Data to :
            </p>
            <ul className={"mb-30"}>
              <li className="mt-10">
                <p className={styles.paragraph2}>
                  Provide and enhance the Dot & Line Sites, including
                  troubleshooting, maintaining, improving, and personalizing the
                  Sites;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Business operations, such as processing your payment for the
                  Dot & Line Services, billing, accounting, improving our
                  internal operations, securing our systems, detecting
                  fraudulent or illegal activity, and meeting our legal
                  obligations;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Send you emails or newsletters that you signed up for,
                  including messages about new and recommended classes, groups,
                  events, and other marketing content we may send to Parents and
                  Teachers;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Communicate with you to confirm registration and send updates,
                  provide customers support and security alerts, and other
                  administrative messages;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Conduct research and develop new services or features; analyze
                  website usage and usage of the Site features, content, and
                  pages; and
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  Conduct background checks if you are a Teacher.
                </p>
              </li>
            </ul>

            <p className={styles.paragraph}>
              We combine data we collect from different sources to achieve these
              purposes and to give you a more seamless, consistent, and
              personalized experience.
              <br />
              <br />
              Dot & Line Teachers may access recordings of their classes and may
              use the recordings to improve their curriculum and classes and may
              provide the recording to Parents and Learners to view. Dot & Line
              may also use class recordings to provide feedback to Teachers, for
              customer support, and for compliance purposes.
              <br />
              <br />
              If you are a Parent or Teacher, Dot & Line may use your contact
              data for marketing and advertising purposes, including to tell you
              about services and opportunities we believe will be of interest to
              you. If we do so, each promotional email we send you will contain
              instructions permitting you to "opt-out" of receiving future
              promotional information. In addition, if at any time you wish not
              to receive any future communications or you wish to have your
              contact information included in our opt-out lists, please contact
              us at info@dotandlinelearning.com. Please note that we will
              continue to contact you via email to respond to requests and
              provide our services.
            </p>

            <p className={styles.heading3}>
              Storage and Transfer of Personal Data
            </p>
            <p className={styles.paragraph}>
              Your information, including Personal Data, may be transferred to —
              and maintained on — computers located outside of your state,
              province, country, or other governmental jurisdiction where the
              data protection laws may differ from those in your region.
            </p>

            <p className={styles.heading3}>
              Our Disclosure of Your Personal Data
            </p>
            <p className={styles.paragraph}>
              We may share your Personal Data with certain third parties with
              your consent or authorization and with whom you have intentionally
              interacted. We may share your Personal Data as necessary to
              complete your transactions and provide the services you have
              requested or authorized. For example, we use third parties to
              process credit card payments. <br />
              <br />
              We may also share data as set forth below:
            </p>

            <p className={styles.heading3}>
              Teachers and Teacher Organizations
            </p>
            <p className={styles.paragraph}>
              When Parents sign up they agree that Teachers may receive certain
              Personal Data about the Parents and their Learners including
              classes their Learner has taken. Teachers are not our employees,
              agents, or service providers, but we do issue guidance and
              restrictions on what they can do with Personal Data. We act as
              limited agents for our Teachers to process payments from Parents
              for Classes.
            </p>

            <p className={styles.heading3}>Business Transfers</p>
            <p className={styles.paragraph}>
              If we are involved in a merger, acquisition, financing due
              diligence, reorganization, bankruptcy, receivership, sale of
              company assets, or transition of service to another provider, your
              information may be transferred to a successor or affiliate alone
              or as part of that transaction along with other assets.
            </p>

            <p className={styles.heading3}>Service Providers</p>
            <p className={styles.paragraph}>
              We engage third party service providers to perform certain
              functions on our behalf (such as payment processing). These third
              parties may have access to your Personal Data for the purpose of
              helping us market, provide and/or improve the Sites.
            </p>

            <p className={styles.heading3}>Legal Requirements</p>
            <p className={styles.paragraph}>
              Dot & Line may disclose your Personal Data if required to do so by
              law or when we believe that such action is necessary to (i) comply
              with a legal obligation, (ii) protect and defend the rights or
              property of Dot & Line , Teachers, Learners, and Parents, (iii)
              operate and maintain the security of our Sites, including to
              prevent or stop an attack on our computer systems or networks,
              (iv) to protect the personal safety of users of the Site or the
              public, or (v) protect against legal liability. Teachers are
              required to report to Dot & Line any suspected case of child abuse
              or neglect they become aware of during their work with Learners.
              Teachers may also be required to report suspected abuse or neglect
              to local authorities, and Dot & Line expects all Teachers to
              comply with their individual reporting responsibilities. Dot &
              Line itself may report instances of suspected abuse or neglect.
            </p>

            <p className={styles.heading3}>
              Third Party Analytics and Advertising Companies
            </p>
            <p className={styles.paragraph}>
              Third party analytics and advertising vendors also collect
              Personal Data through our Site including, general location data,
              usage data, and inferences associated with identifiers and device
              information (such as cookie IDs and IP address) as described in
              the Cookies section of this statement. These third-party vendors
              may combine this data across multiple sites to improve analytics
              for their own purpose and others. For example, we use Google
              Analytics on our Site to help us understand how users interact
              with our Site; you can learn how Google collects and uses
              information at{" "}
              <a
                className="blue-color"
                href="https://policies.google.com/technologies/partner-sites"
                target={"_blank"}
                rel="noreferrer"
              >
                https://policies.google.com/technologies/partner-sites
              </a>
              .
              <br />
              <br />
              Please note that our Site includes references or links to or
              suggestions to engage with third parties whose privacy practices
              differ from ours. Teachers may also suggest third party resources
              for Parents and Learners. If you provide Personal Data to any of
              those third parties, or allow us to share Personal Data with them,
              that data is governed by their privacy statements.
            </p>

            <p className={styles.heading3}>Publicly Posted Information</p>
            <p className={styles.paragraph}>
              You may be able to share Personal Data, including communications,
              with third parties, including with the general public, through use
              of our Site. When you do so we ask that you avoid sharing Personal
              Data and do not share sensitive Personal Data. <br />
              <br />
              We expect everyone to respect the privacy of others and follow our
              community standards, and other guidelines and restrictions on
              posting and sharing activities, such as sharing private materials,
              communications, or information, or sharing other Personal Data
              about others that may cause harm, embarrassment, or conflict. You
              should not encourage your Learners or other Learners to reveal any
              Personal Data about Learners or their families.
            </p>

            <p className={styles.heading3}>User Profile</p>
            <p className={styles.paragraph}>
              As a user of our Services, you will have a user profile that will
              be publicly available to all users of the Services (the “User
              Profile”). You are not required to include any Personal Data on
              your User Profile. But you will have the option to include a
              variety of types of Personal Data in your User Profile, which may
              include your first name, last name, biographical information,
              geographical location, and avatar or personal photograph. <br />
              <br />
              We urge you to think carefully about what, if any, Personal Data
              you include in your User Profile.
            </p>

            <p className={styles.heading3}>Online Classrooms and Pages</p>
            <p className={styles.paragraph}>
              If you are Teachers leading our online classes, you may publicly
              display and share your video and photographic image, name and/or
              username and certain other information, such as your profile,
              demographic data, content and files, and general location data
              (such as the city or state or province).
            </p>

            <p className={styles.heading3}>Public Forums</p>
            <p className={styles.paragraph}>
              Our Services provide Teachers and Parents the opportunity to share
              information on a variety of topics with other Dot & Line Users
              through public forums (the “Public Forums”). Use of the Public
              Forums is entirely voluntary. But please be aware that any
              information you include in the Public Forums will become available
              to all Dot & Line Users without restriction.
              <br />
              <br />
              We do not systematically monitor the content of information that
              is published in the Public Forums. Any information published in
              Public Forums becomes available to the public.
            </p>

            <p className={styles.heading3}>Messages</p>
            <p className={styles.paragraph}>
              Learners can message Teachers, and Parents and Teachers can
              message each other using the Service. Parents have access to
              messages between their Learners and Teachers.
              <br />
              <br />
              After publishing, posting, or messaging, we have no control over
              how such information is used or its further dissemination. We urge
              you to think carefully about what, if any, Personal Data you share
              with others on our Service.
            </p>

            <p className={styles.heading3}>Social Media</p>
            <p className={styles.paragraph}>
              Parents and Teachers may log into the Service using a Facebook
              account. When you login, we will receive your name, profile
              picture and email address from Facebook, and Facebook will record
              when we access this data. You will provide your Facebook login
              information directly to Facebook (and not to Dot & Line ). How
              Facebook uses, stores, and discloses your information is governed
              by the policies of Facebook; Dot and Line does not have
              responsibility for the privacy practices or other actions of
              Facebook.
            </p>

            <p className={styles.heading3}>Security</p>
            <p className={styles.paragraph}>
              We take reasonable steps to protect the Personal Data provided via
              the Site from loss, misuse and unauthorized access, disclosure,
              alteration, or destruction. However, the Internet cannot be
              guaranteed to be fully secure, and we cannot ensure or warrant the
              security of any content or information you provide to us. You are
              also responsible for helping to protect the security of your
              account credentials. For instance, never give out your password,
              and safeguard your username, password and personal credentials
              used to access the Services, so that other people will not have
              access to your Personal Data. And do not use the username and
              password you use on our Services with any other services.
            </p>

            <p className={styles.heading2}>Choice and Control</p>
            <p className={styles.heading3}>Browser Cookie Controls</p>
            <p className={styles.paragraph}>
              Most web browsers are set to accept cookies by default. If you
              prefer, you can go to your browser settings to learn how to delete
              or reject cookies. If you choose to delete or reject cookies, this
              could affect certain features or services of our website. If you
              choose to delete cookies, settings and preferences controlled by
              those cookies, including advertising preferences, may be deleted
              and may need to be recreated.
            </p>

            <p className={styles.heading3}>
              Mobile Identifier and Permission Controls
            </p>
            <p className={styles.paragraph}>
              iOS and Android operating systems provide privacy options in your
              device settings. You can limit tracking and/or reset the
              advertising identifiers and change permissions regarding push
              notifications and access to your camera and photos.
            </p>

            <p className={styles.heading3}>
              Accuracy; Correcting Personal Data.
            </p>
            <p className={styles.paragraph}>
              We use reasonable efforts to keep your Personal Data accurate and
              up-to-date, and we will provide you with mechanisms to correct,
              amend, delete, or limit the use of your Personal Data. As
              appropriate, this amended Personal Data will be transmitted to
              those parties to which we are permitted to disclose your
              information. Having accurate Personal Data about you enables us to
              give you the best possible service.
            </p>

            <p className={styles.heading3}>Retention of Personal Data</p>
            <p className={styles.paragraph}>
              We retain Personal Data for as long as necessary to provide the
              services and fulfill the transactions you have requested, comply
              with our legal obligations, resolve disputes, enforce our
              agreements, and other legitimate and lawful business purposes.
              Because these needs can vary for different data types in the
              context of different services, actual retention periods can vary
              significantly based on criteria such as user expectations or
              consent, the sensitivity of the data, the availability of
              automated controls that enable users to delete data, and our legal
              or contractual obligations.
            </p>

            <p className={styles.heading2}>Cookie Policy</p>
            <p className={styles.heading3}>What are cookies?</p>
            <p className={styles.paragraph}>
              Cookies are small text files containing a string of characters
              that can be placed on your computer or mobile device that uniquely
              identify your browser or device. Cookies are used with web beacons
              and similar technologies. Web beacons are electronic images (also
              called single-pixel or clear GIFs) that are contained within a
              website or email and can read cookies they place on your device.
            </p>

            <p className={styles.heading3}>What are mobile device IDs?</p>
            <p className={styles.paragraph}>
              Mobile device identifiers are used to identify your device to our
              app so we can collect data such as usage data, identifiers, and
              device information to conduct mobile analytics and operate our
              mobile apps. <br />
              <br />
              Mobile device identifiers are generated by operating systems for
              mobile devices (iOS and Android) and can be accessed and used by
              apps in much the same way that websites access and use cookies.{" "}
              <br />
              <br />
              Our apps contain software that enables us and our third-party
              analytics partners to access these mobile identifiers.
            </p>

            <p className={styles.heading3}>What are cookies used for?</p>
            <p className={styles.paragraph}>
              Cookies, mobile device identifiers, and similar technologies allow
              a site or service to know if your computer or device has visited
              that site or service before. These technologies can then be used
              to help understand how the site or service is being used, help you
              navigate between pages efficiently, help remember your
              preferences, and generally improve your browsing experience. We
              and our analytics and advertising partners use cookies and other
              identifiers, such as IP address, and technologies on our Sites to
              collect Personal Data (such as the pages you visit, the links you
              click on, and similar usage information) when you use our Sites,
              including Personal Data about your online activities over time and
              across different websites or online services. We and our analytics
              and advertising partners may use cookies and similar technologies
              to provide targeted advertising.
            </p>

            <p className={styles.heading3}>How to control and review cookies</p>
            <p className={styles.paragraph}>
              Dot & Line uses third-party analytics and advertising providers on
              our website and in our emails. To learn more about their privacy
              practices and how to opt-out of their use of certain of the above
              cookies and other technologies for analytics and advertising on
              our Site and in our emails, Please write to us here:{" "}
              <span className="blue-color">info@dotandlinelearning.com</span>{" "}
              <br />
              <br />
              Please note that the choices referenced in our cookie article,
              linked above, are specific to the browser and device you are
              using. If you access our Site from multiple devices or browsers,
              you will need to opt-out separately on those devices and browsers.
            </p>

            <p className={styles.heading3}>Changes to Our Privacy Statement</p>
            <p className={styles.paragraph}>
              We may change this Privacy Statement at any time. If we decide to
              change this Privacy Statement in the future, we will post or
              provide an appropriate notice. If we make material changes to the
              statement, we will provide notice or obtain consent regarding such
              changes as may be required by law. The date on which the latest
              update was made is indicated at the top of this document.
            </p>

            <p className={styles.heading3}>Contact Us</p>
            <p className={styles.paragraph}>
              Please feel free to contact us if you have any questions about Dot
              & Line ’s Privacy Statement or the information practices of our
              Sites. <br />
              <br /> You may contact us as follows: You may send an email to{" "}
              <span className="blue-color">info@dotandlinelearning.com</span> or
              send mail to:
              <br />
              <br />
              Dot & Line , Inc.
              <br />
              PO Box 19801
              <br />
              Wilmington, DE 19801
              <br />
              United States
              <br />
              +1 3022143049
              <br />
            </p>

            <p className={styles.heading2}>Children's Privacy Notice</p>
            <p className={styles.paragraph}>
              This section explains our information collection, disclosure,
              parental consent practices and parental choice procedures with
              respect to information provided by Learners.
            </p>

            <p className={styles.heading3}>Collection</p>
            <p className={styles.paragraph}>
              Dot & Line collects Personal Data about Children directly from
              Parents and, when we have verified parental consent of Parents,
              directly from Children through our website and mobile app. <br />
              <br />
              Parents provide us with the Child’s name and age. Parents may
              additionally provide us with descriptions of the type of schooling
              their Child attends, local time zone, and additional notes for the
              Teacher. In addition, Children may share information about
              themselves during online classes. The Dot & Line Class sessions
              take place over online videos in which video images and audio of
              the Children are recorded in our systems. We may collect other
              Personal Data about Children, including passively collected data,
              as described in our general Privacy Notice, with the exception
              that we do not collect payment information from Children or allow
              third parties to use Personal Data collected through analytics or
              advertising cookies for their own purposes. Children may also
              share information about themselves during online classes and when
              reporting emergency safety and compliance issues.
            </p>
            <p className={styles.heading3}>Use and Disclosure</p>
            <p className={styles.paragraph}>
              Once we obtain verified parental consent, we may use and share
              Personal Data collected from Children for many of the same
              purposes described in the general Privacy Notice. We recommend
              Classes, and new features to Parents and Children based on
              Childrens’ activities on our Site, such as when they mark classes
              or are enrolled in classes and if they are using our mobile app or
              website. We may send these messages to Children and Parents
              through push notifications if Parents enable this feature in the
              mobile app. We may also send emails to Parents about those
              recommendations and surveys and informational emails to Parents
              and Children. <br />
              <br />
              Dot & Line shares, or allows you to share, the name, age, postal
              address, local time zone, and any notes Parents have provided
              about their Child to the Class Teacher to allow the Teacher to
              provide Classes, and deliver supplies to Children. Children can
              also privately message Teachers of the Classes they’re enrolled
              in. Children’s video image and voice audio and potentially
              background images of their home and family members are shared with
              the Teacher and the rest of the Class as they participate in
              Classes. Parents should consider using virtual backgrounds for
              their Children if they have concerns about posting images of their
              home and family members. Children may also share information about
              themselves with the Teacher and the rest of their classmates
              during the Class session voluntarily, including content, images,
              and videos they may upload and share with their Teacher and
              classmates. While Dot & Line expects Teachers and all other Users
              to abide by our standards of conduct and we take reasonable steps
              to monitor certain communications and posts, please note that we
              are not able to control or monitor all Personal Data your Child
              may share with Teachers or other classmates, nor what those third
              parties ultimately do with that information. <br />
              <br />
              We urge you to talk to your Children to follow our community
              standards when posting content and messages in Class. You should
              encourage your Children to also think about their own privacy and
              safety when choosing what to share with others. In particular,
              Children should be encouraged to avoid sharing Personal Data like
              their address or contact information with other Children in
              Classes. <br />
              <br />
              At times, we may allow Children and Parents to submit content,
              including photos and artwork, along with other information about
              the Child (like age, first name or initials and city, state, and
              country) that you may allow us to display publicly. Parents should
              review Children’s submissions and avoid uploading any private
              information in the Content about the Children, family members, or
              others. Dot & Line is not responsible for what other third parties
              do with information you choose to share publicly. <br />
              <br />
              Some Teachers may ask Child Learners and their Parents to use
              third-party sites and services. Parents should review the privacy
              notices for those third party sites and services to understand
              their privacy practices. These third parties are not our service
              providers, and we are not responsible for and may not have
              reviewed those third-party sites and services.
            </p>

            <p className={styles.heading3}>Class Video Recordings</p>
            <p className={styles.paragraph}>
              As described above, Dot & Line sometimes/selectively records video
              of Children and Teachers during Dot and Line classes (“Class
              Recordings”). The Class Recordings are made available by Dot &
              Line to the Teacher of the class and may be shared by the Teacher
              for the entire class (and their Parents). <br />
              <br />
              Dot & Line will obtain additional parental consent before we use
              any Class Recordings for promotional or other unexpected purposes.
              We request that Teachers refrain from creating copies of the Class
              Recordings or from sharing the Class Recordings with anyone aside
              from Permitted Recipients, and also request that Permitted
              Recipients not download or re-share the Class Recordings. While we
              expect Teachers and Permitted Recipients to abide by our standards
              of conduct, please note that we cannot control or monitor what
              such third parties ultimately do with Class Recordings.
            </p>

            <p className={styles.heading3}>Parental Consent</p>
            <p className={styles.paragraph}>
              Dot & Line requires that you provide your credit card information
              to verify you and to register your Child for and pay for a Dot &
              Line Class. If you do not consent or withdraw consent, then we
              will not collect, use, or disclose any Personal Data about your
              Child, except as needed for legal compliance and enforcement
              purpose, and your Child will not be allowed to use the Services in
              any way. If you do not provide consent within a reasonable time
              from the date the direct notice was sent, and are not using our
              services for any other purposes, such as for teen children, we
              will also delete your online contact information from our records.
            </p>

            <p className={styles.heading3}>Parental Choices and Controls</p>
            <p className={styles.paragraph2}>
              At any time, you can review and refuse to permit us to collect
              further Personal Data from your Children in association with your
              account. You can also request that we delete from our records the
              Personal Data we have collected in connection with that account.
              Please keep in mind that a request to delete records may lead to a
              termination of an account, membership, or other service. You may
              review and update certain of your Child’s information by logging
              onto your account. You can contact Dot & Line to request access
              to, change, or delete your Child’s Personal Data, or revoke
              consent for our collection, use, and sharing of Personal Data, by
              sending an email to us at info@dotandlinelearning.com. A valid
              request to delete Personal Data will be accommodated within a
              reasonable time. In addition to the foregoing, we will exercise
              commercially reasonable efforts to delete Personal Data collected
              from Children when it is no longer needed for the purpose for
              which it was collected. <br />
              <br />
              For users of our mobile app, Parents can choose permissions to
              allow push notifications to, and camera and photo access by,
              Children. Parents should consider enabling parental controls to
              help prevent Children from changing permissions you have chosen.
              Most devices provide ways to set these controls through device
              settings.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyAndPolicy;
