import React, { memo } from "react";
import { StarFilled } from "@ant-design/icons";
import styles from "./styles.module.css";
import degree from "./../../assets/svgs/degree.svg";
import logopink from "../../assets/svgs/logopink.svg";
import book from "./../../assets/svgs/book.svg";
import anonymous from "./../../assets/img/avatar.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const TeacherCard = ({ teacher }) => {
  const route = () => {
    window.location.pathname = `teachers/${teacher?.id}`;
  };

  return (
    <div onClick={route} className={styles.teacher_card}>
      {teacher?.ratings > 0 && (
        <div className={styles.teacher_ratings}>
          <StarFilled /> {teacher?.ratings}
        </div>
      )}

      <div>
        <div className={styles.package_cover}>
          <LazyLoadImage
            src={teacher.picture}
            width={"100%"}
            height={250}
            placeholderSrc={anonymous}
            effect="blur"
          />

          <div className={styles.logo}>
            <img src={logopink} alt="logo" />
          </div>
        </div>

        <div className={styles.card_body}>
          <p
            className={
              "font-16 black-color font-weight-500 mb-0 overflow-elipsis"
            }
          >
            {teacher?.name}
          </p>

          {teacher.bio && (
            <div className={styles.descHeight}>
              <p className={styles.teacherDescription}>{teacher?.bio}</p>
            </div>
          )}

          <div className={styles.details}>
            {teacher?.latest_degree && (
              <div className={[styles.t_location, "d-flex"].join(" ")}>
                <img className="mt-2" alt="" src={degree} />
                <p className="font-14 font-weight-400 mb-0 overflow-elipsis">
                  {teacher?.latest_degree}
                </p>
              </div>
            )}

            {teacher?.subjects && (
              <div className={[styles.t_location, "d-flex"].join(" ")}>
                <div className="dark-grey-color">
                  <img className="mt-2" alt="" src={book} />
                </div>
                <p className="font-14 font-weight-400 mb-0 overflow-elipsis">
                  {teacher?.subjects?.join(", ")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TeacherCard);
