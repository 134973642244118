import React, { Fragment } from "react";
import { Col, Radio, Row, Space } from "antd";
import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const RadioGroupButton = ({
  buttonStyle,
  defaultValue,
  disabled,
  options,
  optionType,
  size,
  value,
  onChange,
  direction,
  error,
}) => {
  const column = Math.ceil(options?.length / 2);
  const column1 = options?.slice(0, column) || [];
  const column2 = options?.slice(column) || [];

  return (
    <Fragment>
      <Radio.Group
        buttonStyle={buttonStyle}
        defaultValue={defaultValue}
        disabled={disabled}
        optionType={optionType}
        size={size}
        value={value}
        onChange={onChange}
      >
        {direction && direction === "vertical-align" ? (
          <Row gutter={[20, 20]}>
            {column1.map((option) => (
              <Col key={option?.id} className={styles.option} span={12}>
                <Radio value={option?.value}>{option?.label}</Radio>
              </Col>
            ))}

            {column2.map((option) => (
              <Col key={option?.id} className={styles.option} span={12}>
                <Radio value={option?.value}>{option?.label}</Radio>
              </Col>
            ))}
          </Row>
        ) : (
          <Space direction={direction}>
            {options &&
              options?.length &&
              options?.map((option) => (
                <Radio key={option?.value} value={option?.value}>
                  {option?.label}
                </Radio>
              ))}
          </Space>
        )}
      </Radio.Group>
      {error && (
        <Text className="mt-8" color="RED" size="XS">
          {error}
        </Text>
      )}
    </Fragment>
  );
};

export default RadioGroupButton;
