import {
  CalendarOutlined,
  DollarCircleOutlined,
  FieldTimeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import React from "react";
import {
  getConvertedTime,
  capitalizeWords,
  getPackageAmount,
} from "../../../utils";
import moment from "moment-timezone";

const Step3 = ({
  packageDetail,
  slot,
  child,
  currency,
  isChildDisabled = false,
}) => {
  const { children } = useSelector((state) => state.profileReducer);

  const getTimeSLot = (slots = [], id, type) => {
    const slot = slots.find((el) => el?.id === id);
    if (slot) {
      return type === "slotDay"
        ? capitalizeWords(slot.days)
        : getConvertedTime(slot.start_time, slot.end_time, slot.time_zone);
    }
    return "";
  };

  const getChild = (id) => {
    const child = children.find((el) => el?.id === id);
    if (child) {
      return child.name;
    }
    return "";
  };

  return (
    <div>
      <div className="d-row pt-10 dark-grey-color d-row">
        <FieldTimeOutlined className="font-20 mt-2 mr-10" />
        <div>
          <p className="mb-0 black-color font-weight-500">Selected Slot</p>
          <p className="mb-0">{getTimeSLot(packageDetail.time_slot, slot)}</p>
        </div>
      </div>
      <div className="d-row pt-10 dark-grey-color d-row">
        <CalendarOutlined className="font-18 mt-2 mr-10" />
        <div>
          <p className="mb-0 black-color font-weight-500">Selected Days</p>
          <p className="mb-0">
            {getTimeSLot(packageDetail.time_slot, slot, "slotDay")}
          </p>
        </div>
      </div>

      {!isChildDisabled && (
        <div className="d-row pt-10 dark-grey-color d-row">
          <UserOutlined className="font-18 mt-2 mr-10" />
          <div>
            <p className="mb-0 black-color font-weight-500">Selected Child</p>
            <p className="mb-0">{getChild(child)}</p>
          </div>
        </div>
      )}

      <div className="d-row pt-10 dark-grey-color d-row">
        <DollarCircleOutlined className="font-18 mt-2 mr-10" />
        <div>
          <p className="mb-0 black-color font-weight-500">Total Payable</p>
          <p className="mb-0">{getPackageAmount(packageDetail)}</p>
          <em>
            <p className="mb-0 font-14">
              {`(For the month ${moment().format("Do MMMM")} to ${moment()
                .add(1, "month")
                .format("Do MMMM")})`}
            </p>
          </em>
        </div>
      </div>
    </div>
  );
};

export default Step3;
