import React, { Fragment, useState } from "react";
import styles from "./styles.module.css";
import ModalComponent from "../modal";
import { Col } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { textLimitForReadMore } from "../../utils/static-data";
import DetailedFeedback from "./detailed-feedback";

const FeedbackCard = ({ review }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Col className="h-100 pb-25 pl-0 pr-0">
      {showModal && (
        <ModalComponent
          isModalVisible={showModal}
          onCancel={() => setShowModal(false)}
          Component={<DetailedFeedback review={review} />}
        />
      )}
      <div className={styles.reviews_card}>
        <div className=" d-div align-items-center yellow-color mb-5">
          {[1, 2, 3, 4, 5].map((element) => (
            <Fragment key={element}>
              {element > review.rating ? (
                <StarOutlined className="font-20 mr-5" />
              ) : (
                <StarFilled className="font-20 mr-5" />
              )}
            </Fragment>
          ))}
        </div>
        {review?.review && (
          <p className="mb-12 f-sm">
            {review?.review?.length <= textLimitForReadMore
              ? review?.review
              : review?.review?.slice(0, textLimitForReadMore)}
            {review?.review.length > textLimitForReadMore && (
              <Fragment>
                <span>... </span>
                <span
                  onClick={() => setShowModal(true)}
                  className="blue-color text-hover-underline"
                >
                  <i>See more</i>
                </span>
              </Fragment>
            )}
          </p>
        )}
        <p className={"f-sm font-weight-600-imp"}>-{review?.parent?.name}</p>
      </div>
    </Col>
  );
};

export default FeedbackCard;
