import React from "react";

import { Row, Col } from "antd";
import Carousel from "react-multi-carousel";

import NoData from "./noData";
import CardItem from "src/modules/parent/pages/olevels/components/cardItem";

import { responsiveArticles } from "src/modules/parent/utils";

const getName = (selectedItems) => {
  if (selectedItems && selectedItems.boards && selectedItems.boards.length) {
    return selectedItems.boards[0];
  }

  if (
    selectedItems &&
    selectedItems.subjects &&
    selectedItems.subjects.length
  ) {
    return selectedItems.subjects[0];
  }

  if (selectedItems && selectedItems.grades && selectedItems.grades.length) {
    return selectedItems.grades[0];
  }
};

const CourseFilter = ({
  data = [],
  isButtonDisabled = false,
  selectedItem = {},
  isCarousel = false,
  viewDetails = () => {},
  handleProfileVideo = () => {},
  handleChat = () => {},
  handleContact = () => {},
  handleBookDemo = () => {},
}) => (
  <>
    {isCarousel ? (
      <Carousel
        key={0}
        infinite
        autoPlay
        autoPlaySpeed={3000}
        responsive={responsiveArticles}
      >
        {data.map((item, index) => (
          <CardItem
            item={item}
            isButtonDisabled={isButtonDisabled}
            handleChat={handleChat}
            handleShowModal={handleProfileVideo}
            handleBookDemo={handleBookDemo}
            viewPackageDetailHandler={viewDetails}
          />
        ))}
      </Carousel>
    ) : data && data.length > 0 ? (
      <Row gutter={[5, 16]} justify={"start"}>
        {data.map((item, index) => (
          <Col key={index} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
            <CardItem
              item={item}
              isButtonDisabled={isButtonDisabled}
              handleChat={handleChat}
              handleShowModal={handleProfileVideo}
              handleBookDemo={handleBookDemo}
              viewPackageDetailHandler={viewDetails}
            />
          </Col>
        ))}
      </Row>
    ) : (
      <NoData name={getName(selectedItem)} onHandleContact={handleContact} />
    )}
  </>
);

export default React.memo(CourseFilter);
