/* eslint-disable react-hooks/exhaustive-deps */
import {
  GET_TEACHER_PACKAGES,
  GET_TEACHER_PROFILE,
  GET_TEACHER_REVIEWS,
} from "../../config/api";
import React, { Fragment, useLayoutEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useParams } from "react-router-dom";
import { getRequest } from "../../config/network";
import AppLoader from "../../components/app-loader";
import BasicInfo from "./basic-info";
import MyPackages from "./my-packages";
import { Helmet } from "react-helmet";
import InstructorReviews from "../../components/instructor-reviews";
import { axiosErrorHandler } from "../../utils";
import ModalComponent from "src/modules/parent/components/modal";
import FlatButton from "src/modules/parent/components/flat-button";
import PostFeedback from "src/modules/parent/components/post-feedback";
import { useSelector } from "react-redux";

const TeacherProfile = () => {
  const { id } = useParams();
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [isScroll, setIsScroll] = useState(false);
  const [isScrollReview, setIsScrollReview] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.authReducer);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [loadingCoursesAndReviews, setLoadingCoursesAndReviews] =
    useState(true);
  const [teacher, setTeacher] = useState();
  const [reviews, setReviews] = useState();
  const [packages, setPackages] = useState();

  useLayoutEffect(() => {
    fetchTeacherProfile();
    fetchTeacherPackages();
    fetchTeacherReviews();
  }, []);

  const fetchTeacherProfile = async () => {
    const endpoint = `${GET_TEACHER_PROFILE}/${id}`;
    const { data } = await getRequest(endpoint, false);
    if (data?.status === "success") {
      setTeacher(data?.data);
      setLoadingDetails(false);
    } else {
      setLoadingDetails(false);
    }
  };

  const fetchTeacherPackages = async () => {
    try {
      const endpoint = `${GET_TEACHER_PACKAGES}/${id}`;
      const { data } = await getRequest(endpoint, false);
      if (data?.status === "success") {
        setPackages(data?.data?.data);
      }
    } catch (error) {
      axiosErrorHandler(error);
    }
  };

  const fetchTeacherReviews = async () => {
    try {
      const endpoint = `${GET_TEACHER_REVIEWS}/${id}`;
      const { data } = await getRequest(endpoint, false);
      if (data?.status === "success") {
        setReviews(data?.data);
        setLoadingCoursesAndReviews(false);
      }
    } catch (err) {
      setLoadingCoursesAndReviews(false);
    }
  };

  const feedbackSubmitted = () => {
    setShowFeedbackModal(false);
    fetchTeacherReviews();
  };

  const formatArray = (arr) => {
    if (arr && arr.length) {
      if (arr.length === 0) {
        return "";
      } else if (arr.length === 1) {
        return arr[0];
      } else if (arr.length === 2) {
        return arr.join(" and ");
      } else {
        const lastItem = arr.pop();
        return arr.join(", ") + ", and " + lastItem;
      }
    }
  };

  return (
    <div className={"teacher_profile"}>
      <ModalComponent
        isModalVisible={showFeedbackModal}
        onCancel={() => setShowFeedbackModal(false)}
        maskClosable={false}
        Component={
          <PostFeedback
            teacherName={teacher?.name}
            feedbackSubmitted={feedbackSubmitted}
            teacherId={teacher?.id}
          />
        }
      />

      <Helmet>
        {/* <meta property="og:title" content="My Website" /> */}
        <meta property="og:image" content={teacher?.picture} />
        <title>{`${teacher?.name}: Online ${
          formatArray(teacher?.subjects) || ""
        } Tutor at dotandline Learning | Dot and Line`}</title>
        <meta
          name="description"
          content={`Learn ${formatArray(teacher?.subjects)} with ${
            teacher?.name
          }, an experienced online ${formatArray(
            teacher?.subjects
          )} tutor at dotandline Learning. Learn live with the best teachers in the world.`}
        />
      </Helmet>

      <Header />
      {loadingDetails ? (
        <div className="d-flex justify-content-center mt-50 pt-40 mb-50">
          <AppLoader className="font-28" />
        </div>
      ) : (
        <Fragment>
          {teacher && (
            <Fragment>
              <div className="app-container mt-50 mb-50">
                <BasicInfo
                  teacher={teacher}
                  reviews={reviews}
                  handleScroll={setIsScroll}
                  handleScrollReview={setIsScrollReview}
                />

                {!loadingCoursesAndReviews && (
                  <Fragment>
                    <MyPackages packages={packages} scrollToDiv={isScroll} />
                    <InstructorReviews
                      reviews={reviews}
                      scrollToDiv={isScrollReview}
                    />
                  </Fragment>
                )}

                {isAuthenticated ? (
                  <div className="d-flex justify-content-center">
                    <FlatButton
                      onClick={() => setShowFeedbackModal(true)}
                      className="mt-30 mb-10"
                      style={{ width: "300px" }}
                      title={"Post a Review"}
                    />
                  </div>
                ) : null}
              </div>

              <Footer />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default TeacherProfile;
