import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import styles from "./styles.module.css";

const TermsAndConditions = () => {
  return (
    <div>
      <Header />
      <div className="app-container">
        <div className={styles.privacy_and_policy}>
          <div>
            <p className={styles.heading1}>Terms & Conditions of dot & line</p>
            <p className="mb-0 pb-40 mt-5 grey-color text-align-center">
              Last Updated: Augist 18, 2022
            </p>
          </div>
          <div>
            <p className={styles.heading2}>Welcome to dot&line!</p>
            <p className={styles.paragraph}>
              Dot & Line Inc. (“D&L,” “we,” “us,” “our”) provides its services
              (described below) to you through its website located at
              dotandlinelearning.com (the “Site”) and through its mobile
              applications and related services (collectively, such services,
              including any new features and applications, and the Site, the
              “Service(s)”), subject to the following Terms of Service (as
              amended from time to time, the “Terms of Service”). We reserve the
              right, at our sole discretion, to change or modify portions of
              these Terms of Service at any time. If we do this, we will post
              the changes on this page and will indicate at the top of this page
              the date these terms were last revised. We will also notify you,
              either through the Services user interface, in an email
              notification or through other reasonable means. Any such changes
              will become effective no earlier than fourteen (14) days after
              they are posted, except that changes addressing new functions of
              the Services or changes made for legal reasons will be effective
              immediately. Your continued use of the Service after the date any
              such changes become effective constitutes your acceptance of the
              new Terms of Service. <br />
              <br />
              Please read these Terms of Service carefully, as they contain an
              agreement to arbitrate and other important information regarding
              your legal rights, remedies, and obligations. The agreement to
              arbitrate requires (with limited exception) that you submit claims
              you have against us to binding and final arbitration, and further
              (1) you will only be permitted to pursue claims against Dot & Line
              on an individual basis, not as a plaintiff or class member in any
              class or representative action or proceeding, and (2) you will
              only be permitted to seek relief (including monetary, injunctive,
              and declaratory relief) on an individual basis. <br />
              <br />
              When using certain aspects of the Services or using certain
              Services, you will be subject to any additional terms applicable
              to such services that may be posted on the Service from time to
              time, including, without limitation, our{" "}
              <Link
                className="blue-color"
                to={"/privacy-and-policy"}
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              , Community Standards, Class Content Policy, and Help Center. All
              such terms are hereby incorporated by reference into these Terms
              of Service. <br />
              <br />
              The Services consist of an online marketplace and platform through
              which Teacher Partners (also referred to as TPs) may offer Classes
              for sale to Parents, and Parents may purchase such Classes for the
              benefit of their children. While Dot & Line Inc. strives to high
              standards of service, you acknowledge and agree that: (1) Dot &
              Line is not a party to any agreements entered into between
              Teachers and Parents, (2) Dot and Line only provides certain tools
              to facilitate the purchase, sale and provision of Classes, (3)
              Parents contract for classes directly with Teachers (4) Dot & Line
              is not a broker, agent (except as expressly set forth below) or
              insurer, and (5) Dot & Line disclaims all liability for the
              conduct of Teachers, Parents, or any other Users of the Site or
              Classes. Different sections of the Site and Terms of Service
              affect Teachers and Parents differently, so please be sure to read
              these Terms of Service carefully.
            </p>

            <p className={styles.heading2}>Key Terms</p>
            <ul className={"mb-30"}>
              <li>
                <p className={styles.paragraph2}>
                  “User” “you” or “your” means a person, organization or entity
                  using the Services, including Parents and Teachers.
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  “Parent(s)” means a parent or legal guardian who completes Dot
                  & Line ’s account registration process to purchase Classes on
                  the Site for the purpose of enrolling their child.
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  “Teacher(s)” means a person who completes Dot & Line’s
                  application process; is selected; signs a contract; creates a
                  package/course on the Teacher Application and agrees to the
                  registration process to sell Classes on the Site, or an
                  organization that executes an agreement with Dot & Line to
                  sell Classes on the Site.
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  “Class(es)” means any online class(es) submitted by a Teacher
                  for sale on the Site.
                </p>
              </li>
            </ul>

            <p className={styles.heading2}>Access and Use of the Service</p>
            <p className={styles.heading3}>Services Description</p>
            <p className={styles.paragraph}>
              Dot & Line ’s Service is an online marketplace for K-12 classes,
              designed for Parents to find and book Classes for the benefit of
              their child or children, and for Teachers to market, sell and
              conduct their Classes. As the provider of an online marketplace,
              Dot & Line does not own, create, sell, resell, control, or manage
              any Classes. Dot & Line 's responsibilities are limited to: (i)
              providing the Site as an online marketplace and platform to
              facilitate the sale, purchase, and conduct of Classes, and (ii)
              serving as the limited agent of each Teacher for the purpose of
              accepting payments from a Parent on behalf of the Teacher. While
              Dot & Line strives to provide a safe environment for its Users,
              you agree that all of these risks are ultimately borne by you, and
              not Dot & Line . Dot & Line does not control the behavior of Users
              or the quality of the Classes. As a result, Dot & Line cannot
              guarantee the authenticity, quality, safety, legality, or
              appropriateness of the Classes.
            </p>

            <p className={styles.heading3}>Your Registration Obligations</p>
            <p className={styles.paragraph}>
              You will be required to register with Dot & Line in order to
              access and use certain features of the Service. If you choose to
              register for the Service, you agree to provide and maintain true,
              accurate, current and complete information about yourself as
              prompted by the Service’s registration form. Registration data and
              certain other information about you are governed by our{" "}
              <Link
                className="blue-color"
                to={"/privacy-and-policy"}
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              . You must be of legal age to form a binding contract to register
              for the Service (in many jurisdictions, this age is 18). If you
              are not yet of legal age to form a binding contract, then you must
              get your Parent to read these Terms of Service and agree to them
              for you before you use the Service. If you are a Parent and you
              provide your consent to your child's use of the Service, then you
              agree to be bound by these Terms of Service with respect to your
              child’s use of the Service.
            </p>

            <p className={styles.heading3}>
              Member Account, Password and Security
            </p>
            <p className={styles.paragraph}>
              You are responsible for maintaining the confidentiality of your
              password and account, if any, and are fully responsible for any
              and all activities that occur under your password or account. You
              agree to (a) immediately notify Dot & Line of any unauthorized use
              of your password or account or any other breach of security, and
              (b) ensure that you exit from your account at the end of each
              session when accessing the Service. Dot & Line will not be liable
              for any loss or damage arising from your failure to comply with
              this Section.
            </p>

            <p className={styles.heading3}>Modifications to Service</p>
            <p className={styles.paragraph}>
              Dot & Line reserves the right to modify or discontinue,
              temporarily or permanently, the Service (or any part thereof) with
              or without notice. You agree that Dot & Line will not be liable to
              you or to any third party for any modification, suspension or
              discontinuance of the Service.
            </p>

            <p className={styles.heading3}>
              General Practices Regarding Use and Storage
            </p>
            <p className={styles.paragraph}>
              You acknowledge that Dot & Line may establish general practices
              and limits concerning use of the Service, including without
              limitation the maximum period of time that data or other content
              will be retained by the Service and the maximum storage space that
              will be allotted on Dot & Line ’s servers on your behalf. You
              agree that Dot & Line has no responsibility or liability for the
              deletion or failure to store any data or other content maintained
              or uploaded by the Service. You acknowledge that Dot & Line
              reserves the right to terminate accounts that are inactive for an
              extended period of time. You further acknowledge that Dot & Line
              reserves the right to change these general practices and limits at
              any time, in its sole discretion, with or without notice.
            </p>

            <p className={styles.heading3}>Class Recordings</p>
            <p className={styles.paragraph}>
              Classes that take place through Dot & Line ’s video chat platform
              may be automatically recorded by Dot and Line (“Class
              Recordings”). We value student and Teacher privacy, and our use of
              Class Recordings is extremely limited. Specifically, Class
              Recordings are made available only to the Teacher providing the
              Class in question, in order to allow them to (1) provide a
              viewable copy to students who missed the Class, or who wish to
              review the Class, for their personal educational purposes; and (2)
              review the Class Recording personally in order to improve their
              Classes. In addition, Dot & Line may use Class Recordings to
              provide feedback to Teachers, to improve our Services, for
              customer support, and for compliance purposes. Dot & Line retains
              Class Recordings for 90 days after the date of the applicable
              Class, subject to our{" "}
              <Link
                className="blue-color"
                to={"/privacy-and-policy"}
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              , after which they are deleted. Notwithstanding the foregoing, Dot
              & Line may also delete Class Recordings earlier than 90 days under
              the following circumstances: (a) upon the request of a Parent for
              the deletion of their child’s personally identifiable information,
              or (b) upon the reasonable determination of Dot & Line that a
              Class Recording should be removed to protect the privacy of
              certain Users or because of violations of these Terms of Service.
              We will not use Class Recordings containing images of you (or your
              children) for promotional or any other purposes without your
              express written consent.{" "}
              <span className="font-weight-600">
                By using the services, you consent to you and/or your child
                appearing in Class Recordings for the limited purposes set forth
                above.
              </span>
            </p>

            <p className={styles.heading2}>Certain Teacher Obligations</p>
            <p className={styles.heading3}>Marketplace Basics</p>
            <p className={styles.paragraph}>
              As a Teacher, you must provide Dot & Line with any information
              requested in order for Dot & Line to list your Classes through its
              Services including but not limited to a description of the Classes
              and the price for the Classes. Dot & Line has sole discretion as
              to which Classes we list on the marketplace and we reserve the
              right to reject any Classes or remove Classes from the marketplace
              for any reason. Additionally, Dot & Line has discretion to edit
              Class descriptions as needed to conform them to our marketplace
              standards. Dot & Line has sole discretion as to which Teachers are
              accepted into the marketplace and we reserve the right to reject
              any potential Teacher and remove or suspend any Teacher from the
              marketplace for any reason. Dot & Line may, but is not required
              to, conduct background checks on and interviews of Teachers in its
              discretion and solely for its own benefit. As a Teacher, you agree
              to provide written and/or electronic consent to such background
              checks and to participate truthfully in such interviews.
            </p>

            <p className={styles.heading3}>Community Standards</p>
            <p className={styles.paragraph}>
              When you join the Dot & Line community, you agree to follow our
              policies, including our community standards. Those expectations
              include thoughtful and professional communications with our
              community (colleagues, parents, children), modeling appropriate
              behavior for our learners, and acting professionally. In addition,
              you agree to use social media, blogs, and other online forums, in
              an appropriate manner. Inappropriate behavior in any Dot & Line
              -affiliated online forum includes, but is not limited to: behavior
              intended to provoke, bully, demean, or cause harm to others, or to
              create conflict; or any other posts or content that Dot & Line ,
              in its discretion, believes to be unconducive to a respectful and
              welcoming community for all. Inappropriate behavior in any online
              forum includes, but is not limited to: posting online or speaking
              to the media on Dot & Line ’s behalf without prior written
              authorization from Dot & Line ; posts that Dot & Line , in its
              discretion, finds to be offensive or inappropriate based on race,
              sex, age, gender, sexual orientation, gender identity or
              expression, national origin, disability or medical condition,
              veteran status, or any other legally protected characteristic;
              derogatory, disparaging, or disrespectful comments about learners,
              teachers, parents, or Dot & Line staff, even if the target is not
              identified by name; sharing any private material, communication,
              or information regarding parents of learners, including but not
              limited to students’ or parents’ names, photos, conversations,
              emails, student academic or performance information, screenshots
              of student work, or any other personal identifying information;
              posts that may be harmful to Dot & Line’s ongoing business
              operations; sharing content that contains or promotes hate speech,
              threats of violence, or the endangerment of children; and any
              other violation of Dot & Line’s community standards.
            </p>

            <p className={styles.heading3}>Learner Safety</p>
            <p className={styles.paragraph}>
              : Each Teacher is required to report to Dot & Line any suspected
              case of child abuse or neglect they become aware of during their
              work with Dot & Line students. Dot & Line defines “abuse or
              neglect” as: “any recent act or failure to act on the part of a
              parent or caretaker, which results in death, serious physical or
              emotional harm, sexual abuse or exploitation”; or “an act or
              failure to act which presents an imminent risk of serious harm.”
              Teachers may also be required to report suspected abuse or neglect
              to local authorities, and Dot & Line expects all teachers to
              comply with their individual reporting responsibilities. Dot &
              Line itself may report instances of suspected abuse or neglect as
              it deems appropriate.
            </p>

            <p className={styles.heading3}>Teaching School Learners</p>
            <p className={styles.paragraph}>
              From time to time Dot & Line has the opportunity to work with
              schools across Pakistan to offer educational experiences on the
              Dot & Line platform to young learners through their school. When
              this is the case, applicable law requires that Dot & Line Teachers
              learn about and familiarize themselves with their obligations
              under student data and privacy protection laws and the related
              legally-mandated Dot and Line safeguards.
            </p>

            <p className={styles.heading3}>Teacher Responsibilities</p>
            <p className={styles.paragraph}>
              Each Teacher is solely responsible for obtaining all licenses and
              other permissions required to offer or provide any Classes, and
              Dot & Line assumes no responsibility for a Teacher's failure to
              obtain such licenses or permissions or otherwise comply with any
              applicable laws, rules or regulations. <br />
              <br />
              You understand and agree that Dot & Line is not an insurer, agent
              or employer for you as a Teacher. If a Parent purchases any of
              your Classes, any agreement you enter into with such Parent is
              between you and the Parent, and Dot & Line is not a party thereto.
              Notwithstanding the foregoing, Dot & Line is authorized to serve
              as your limited agent purely for the purpose of accepting payments
              from Parents on your behalf and transmitting such payments to you
              (minus our Fees). You acknowledge and agree that, as a Teacher,
              you are responsible for your own acts and omissions while using
              the Services. With the sole exception of students enrolled in the
              applicable Class, and/or their Parents, you further agree that you
              will not share any Class Recordings made available to you by Dot &
              Line to any third parties. Allowing other Teachers within your
              organization to view the Class Recordings, however, is
              permissible.
            </p>

            <p className={styles.heading2}>Payment Terms</p>
            <p className={styles.heading3}>General</p>
            <p className={styles.paragraph}>
              Each Parent agrees to pay all applicable fees for Classes
              (“Enrollment Fees”) as set forth on the Site. All Enrollment Fees
              are payable in the currency specified on the Site at the time of
              purchase. You shall be responsible for all taxes associated with
              the Services other than taxes based on Company’s net income.
              Parent hereby authorizes Dot & Line to bill Parent’s payment
              instrument upon confirmation of a purchase, and Parent further
              agrees to pay any charges so incurred. If Parent disputes any
              charges you must let Dot and Line know within thirty (30) days
              after the date that Dot & Line charges you.
            </p>

            <p className={styles.heading3}>Teacher Payment</p>
            <p className={styles.paragraph}>
              Dot & Line will transfer the Enrollment Fees to Teacher’s Bank
              account for each sale of a set of Classes (as applicable) to a
              Parent within a reasonable period of time, minus Dot & Line ’s
              service fees (“Dot and Line Fee”), according to the schedule and
              policies detailed in our Policy Document in the Teacher Patner
              Contract. Dot & Line has discretion to act on behalf of the
              Parent, and to not transfer the Enrollment Fees to Teacher, if
              Parent reports that the Classes were not provided or adequately
              completed. This may include but is not limited to circumstances
              where a Teacher did not arrive for a Class or a Class was of
              insufficient quality. Dot & Line will independently review such
              cases, seeking input from the Parent and/or the Teacher, and may
              decide at its sole discretion to issue a refund to the Parent. All
              determinations of Dot & Line with respect to a refund shall be
              final and binding on the Parent and Teacher.
            </p>

            <p className={styles.heading3}>Limited Payment Collections Agent</p>
            <p className={styles.paragraph}>
              Each Teacher appoints Dot & Line as the Teacher’s limited payment
              collection agent solely for the purpose of accepting the
              Enrollment Fees from Parent. Each User agrees that payment of
              Enrollment Fees by a Parent to Dot & Line , as that Teacher’s
              limited payment collection agent, shall be considered the same as
              a payment made directly by such Parent to the relevant Teacher and
              the Teacher will provide the relevant Classes to the Parent, as
              outlined on the Site, as if the Teacher had received payment
              directly. Dot & Line, as limited payment collection agent for the
              Teacher, agrees to facilitate the payment of any Enrollment Fees
              (less the Dot & Line Fee) for Classes pursuant to these Terms of
              Service unless otherwise agreed between Dot & Line and the
              Teacher. In the event that Dot & Line does not remit such amounts,
              the Teacher will have recourse only against Dot & Line.
            </p>

            <p className={styles.heading2}>Conditions of Use</p>
            <p className={styles.heading3}>User Conduct</p>
            <p className={styles.paragraph2}>
              You are solely responsible for all code, video, images,
              information, data, text, software, music, sound, photographs,
              graphics, messages or other materials (“content”) that you upload,
              post, publish or display (hereinafter, “upload”) or email or
              otherwise use via the Service. The following are examples of the
              kind of content and/or use that is illegal or prohibited by Dot &
              Line . Dot & Line reserves the right to investigate and take
              appropriate legal action against anyone who, in Dot & Line ’s sole
              discretion, violates this provision, including without limitation,
              removing the offending content from the Service, suspending or
              terminating the account of such violators and reporting you to the
              law enforcement authorities. You agree to not use the Service to:
            </p>
            <ul className={"mt-10 mb-30"}>
              <li>
                <p className={styles.paragraph2}>
                  email or otherwise upload any content that
                </p>
                <ol>
                  <li className={styles.paragraph2}>
                    infringes any intellectual property or other proprietary
                    rights of any party;
                  </li>
                  <li className={styles.paragraph2}>
                    you do not have a right to upload under any law or under
                    contractual or fiduciary relationships;
                  </li>
                  <li className={styles.paragraph2}>
                    contains software viruses or any other computer code, files
                    or programs designed to interrupt, destroy or limit the
                    functionality of any computer software or hardware or
                    telecommunications equipment;
                  </li>
                  <li className={styles.paragraph2}>
                    {" "}
                    poses or creates a privacy or security risk to any person;
                  </li>
                  <li className={styles.paragraph2}>
                    constitutes unsolicited or unauthorized advertising,
                    promotional materials, commercial activities and/or sales,
                    “junk mail,” “spam,” “chain letters,” “pyramid schemes,”
                    “contests,” “sweepstakes,” or any other form of
                    solicitation;{" "}
                  </li>
                  <li className={styles.paragraph2}>
                    is unlawful, harmful, threatening, abusive, harassing,
                    tortious, excessively violent, defamatory, vulgar, obscene,
                    pornographic, libelous, invasive of another’s privacy,
                    hateful racially, ethnically or otherwise objectionable; or
                  </li>
                  <li className={styles.paragraph2}>
                    in the sole judgment of Dot & Line, is objectionable or
                    which restricts or inhibits any other person from using or
                    enjoying the Service, or which may expose Dot & Line or its
                    users to any harm or liability of any type;
                  </li>
                </ol>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  interfere with or disrupt the Service or servers or networks
                  connected to the Service, or disobey any requirements,
                  procedures, policies or regulations of networks connected to
                  the Service; or
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  violate any applicable local, state, national or international
                  law, or any regulations having the force of law;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  impersonate any person or entity, or falsely state or
                  otherwise misrepresent your affiliation with a person or
                  entity;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  solicit personal information from anyone in violation of our{" "}
                  <Link
                    className="blue-color"
                    to={"/privacy-and-policy"}
                    target={"_blank"}
                  >
                    Privacy Policy
                  </Link>
                  ;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  harvest or collect email addresses or other contact
                  information of other users from the Service by electronic or
                  other means for the purposes of sending unsolicited emails or
                  other unsolicited communications;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  advertise or offer to sell or buy any goods or services for
                  any business purpose that is not specifically authorized;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  further or promote any criminal activity or enterprise or
                  provide instructional information about illegal activities; or
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  obtain or attempt to access or otherwise obtain any materials
                  or information through any means not intentionally made
                  available or provided for through the Service.
                </p>
              </li>
            </ul>

            <p className={styles.heading3}>Commercial Use</p>
            <p className={styles.paragraph}>
              Unless otherwise expressly authorized herein or in the Service,
              you agree not to display, distribute, license, perform, publish,
              reproduce, duplicate, copy, create derivative works from, modify,
              sell, resell, exploit, transfer or upload for any commercial
              purposes, any portion of the Service, use of the Service, or
              access to the Service.
            </p>

            <p className={styles.heading2}>Intellectual Property Rights</p>
            <p className={styles.heading3}>
              Service Content, Software and Trademarks
            </p>
            <p className={styles.paragraph}>
              You acknowledge and agree that the Service may contain content or
              features (“Service Content”) that are protected by copyright,
              patent, trademark, trade secret or other proprietary rights and
              laws. Except as expressly authorized by Dot & Line, you agree not
              to modify, copy, frame, scrape, rent, lease, loan, sell,
              distribute or create derivative works based on the Service or the
              Service Content, in whole or in part, except that the foregoing
              does not apply to your own User Content (as defined below) that
              you legally upload to the Service. For the sake of clarity, you
              acknowledge and agree that Class Recordings constitute Service
              Content, not User Content. In connection with your use of the
              Service you will not engage in or use any data mining, robots,
              scraping or similar data gathering or extraction methods. If you
              are blocked by Dot & Line from accessing the Service (including by
              blocking your IP address), you agree not to implement any measures
              to circumvent such blocking (e.g., by masking your IP address or
              using a proxy IP address). Any use of the Service or the Service
              Content other than as specifically authorized herein is strictly
              prohibited. The technology and software underlying the Service or
              distributed in connection therewith are the property of Dot & Line
              , our affiliates and our partners (the “Software”). You agree not
              to copy, modify, create a derivative work of, reverse engineer,
              reverse assemble or otherwise attempt to discover any source code,
              sell, assign, sublicense, or otherwise transfer any right in the
              Software. Any rights not expressly granted herein are reserved by
              Dot & Line . The Dot & Line name and logos are trademarks and
              service marks of Dot & Line (collectively the “Dot & Line
              Trademarks”). Other Dot & Line , product, and service names and
              logos used and displayed via the Service may be trademarks or
              service marks of their respective owners who may or may not
              endorse or be affiliated with or connected to Dot & Line. You
              should not interpret anything in these Terms of Service or the
              Service to mean that Dot & Line is in any way explicitly or
              implicitly giving you any license or right to use any of Dot &
              Line Trademarks displayed on the Service, without our prior
              written permission in each instance. All goodwill generated from
              the use of Dot & Line Trademarks is only for Dot & Line ’s
              exclusive benefit.
            </p>

            <p className={styles.heading3}>Third Party Material</p>
            <p className={styles.paragraph}>
              Under no circumstances will Dot & Line be liable in any way for
              any content or materials of any third parties (including users),
              including, but not limited to, for any errors or omissions in any
              content, or for any loss or damage of any kind incurred as a
              result of the use of any such content. You acknowledge that Dot &
              Line may, but is not required to, pre-screen content, and Dot &
              Line and its designees will have the right (but not the
              obligation) in their sole discretion to refuse or remove any
              content that is available via the Service. Without limiting the
              foregoing, Dot & Line and its designees will have the right to
              remove any content that violates these Terms of Service or is
              deemed by Dot & Line , in its sole discretion, to be otherwise
              objectionable. You agree that you must evaluate, and bear all
              risks associated with, the use of any content, including any
              reliance on the accuracy, completeness, or usefulness of such
              content.
            </p>

            <p className={styles.heading3}>
              User Content Transmitted Through the Service
            </p>
            <p className={styles.paragraph2}>
              With respect to the content or other materials you upload through
              the Service or share with other users or recipients (collectively,
              “User Content”), you represent and warrant that you own all right,
              title and interest in and to such User Content, including, without
              limitation, all copyrights and rights of publicity contained
              therein. You shall retain any intellectual property rights that
              you hold in your User Content, and Dot & Line does not claim any
              ownership (copyright, trademark, or otherwise) over your User
              Content. By submitting, posting or otherwise uploading User
              Content on or through the Services you give Dot & Line a
              worldwide, nonexclusive, perpetual, irrevocable, fully
              sub-licensable, royalty-free right and license as set below:
            </p>
            <ul className={"mt-10 mb-30"}>
              <li>
                <p className={styles.paragraph2}>
                  with respect to User Content that you submit, post or
                  otherwise make publicly or generally available via the Service
                  (e.g. public forum posts), the license to use, reproduce,
                  modify, adapt, publish, translate, create derivative works
                  from, distribute, publicly perform, and publicly display such
                  User Content (in whole or part) worldwide via the Services or
                  otherwise, and/or to incorporate it in other works in any
                  form, media, or technology now known or later developed for
                  any legal business purpose; and
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  with respect to User Content that you submit, post or
                  otherwise transmit privately via the Services (e.g. via
                  private lessons or messages with other Users), the license to
                  use, reproduce, modify, adapt, publish, translate, create
                  derivative works from, distribute, publicly perform and
                  publicly display such User Content for the sole purpose of
                  enabling Dot & Line to provide you with the Services.
                </p>
              </li>
            </ul>

            <p className={styles.paragraph}>
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback or other information about the
              Service (“Submissions”), provided by you to Dot & Line are
              non-confidential and Dot & Line will be entitled to the
              unrestricted use and dissemination of these Submissions for any
              purpose, commercial or otherwise, without acknowledgment or
              compensation to you. <br />
              <br />
              You acknowledge and agree that Dot & Line may preserve content and
              may also disclose content if required to do so by law or in the
              good faith belief that such preservation or disclosure is
              reasonably necessary to: (a) comply with legal process, applicable
              laws or government requests; (b) enforce these Terms of Service;
              (c) respond to claims that any content violates the rights of
              third parties; or (d) protect the rights, property, or personal
              safety of Dot & Line , its users and the public. You understand
              that the technical processing and transmission of the Service,
              including your content, may involve (a) transmissions over various
              networks; and (b) changes to conform and adapt to technical
              requirements of connecting networks or devices.
            </p>

            <p className={styles.heading2}>Copyright Complaints</p>
            <p className={styles.paragraph2}>
              Dot & Line respects the intellectual property of others, and we
              ask our users to do the same. If you believe that your work has
              been copied in a way that constitutes copyright infringement, or
              that your intellectual property rights have been otherwise
              violated, you should notify Dot & Line of your infringement claim
              in accordance with the procedure set forth below. <br />
              <br />A notification of claimed copyright infringement should be
              emailed to Dot & Line ’s Copyright Agent at{" "}
              <span className="blue-color">
                info@dotandlinelearning.com
              </span>{" "}
              (Subject line: “Takedown Request”). You may also contact us by
              mail at: Dot & Line , Inc. 221 W 9th St PMB 260, Wilmington DE
              19801 <br />
              <br />
              To be effective, the notification must be in writing and contain
              the following information
            </p>
            <ul className={"mt-10 mb-30"}>
              <li>
                <p className={styles.paragraph2}>
                  an electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright or other
                  intellectual property interest;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  a description of the copyrighted work or other intellectual
                  property that you claim has been infringed;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  a description of where the material that you claim is
                  infringing is located on the Service, with enough detail that
                  we may find it on the Service;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  your address, telephone number, and email address; a statement
                  by you that you have a good faith belief that the disputed use
                  is not authorized by the copyright or intellectual property
                  owner, its agent, or the law;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  a statement by you, made under penalty of perjury, that the
                  above information in your Notice is accurate and that you are
                  the copyright or intellectual property owner or authorized to
                  act on the copyright or intellectual property owner’s behalf.
                </p>
              </li>
            </ul>

            <p className={styles.heading2}>Counter-Notice</p>
            <p className={styles.paragraph2}>
              : If you believe that your User Content that was removed (or to
              which access was disabled) is not infringing, or that you have the
              authorization from the copyright owner, the copyright owner’s
              agent, or pursuant to the law, to upload and use the content in
              your User Content, you may send a written counter-notice
              containing the following information to the address:
            </p>

            <ul className={"mt-10 mb-30"}>
              <li>
                <p className={styles.paragraph2}>
                  your physical or electronic signature;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  identification of the content that has been removed or to
                  which access has been disabled and the location at which the
                  content appeared before it was removed or disabled;
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  a statement that you have a good faith belief that the content
                  was removed or disabled as a result of mistake or a
                  misidentification of the content; and
                </p>
              </li>
              <li>
                <p className={styles.paragraph2}>
                  your name, address, telephone number, and email address, a
                  statement that you consent to the jurisdiction of the local
                  courts and a statement that you will accept service of process
                  from the person who provided notification of the alleged
                  infringement.
                </p>
              </li>
            </ul>
            <p className={styles.paragraph}>
              If a counter-notice is received by the Company, Dot & Line will
              send a copy of the counter-notice to the original complaining
              party informing that person that it may replace the removed
              content or cease disabling it in 10 business days. Unless the
              copyright owner files an action seeking a court order against the
              content provider, member or user, the removed content may be
              replaced, or access to it restored, in 10 to 14 business days or
              more after receipt of the counter-notice, at our sole discretion.
            </p>

            <p className={styles.heading2}>Repeat Infringer Policy</p>
            <p className={styles.paragraph}>
              In accordance with applicable law, Dot & Line has adopted a policy
              of terminating, in appropriate circumstances and at Dot & Line 's
              sole discretion, users who are deemed to be repeat infringers. Dot
              and Line may also at its sole discretion limit access to the
              Service and/or terminate the memberships of any users who infringe
              any intellectual property rights of others, whether or not there
              is any repeat infringement.
            </p>

            <p className={styles.heading2}>Third Party Websites</p>
            <p className={styles.paragraph}>
              The Service may provide, or third parties may provide, links or
              other access to other sites and resources on the Internet. Dot &
              Line has no control over such sites and resources and Dot & Line
              is not responsible for and does not endorse such sites and
              resources. You further acknowledge and agree that Dot and Line
              will not be responsible or liable, directly or indirectly, for any
              damage or loss caused or alleged to be caused by or in connection
              with use of or reliance on any content, events, goods or services
              available on or through any such site or resource. Any dealings
              you have with third parties found while using the Service are
              between you and the third party, and you agree that Dot & Line is
              not liable for any loss or claim that you may have against any
              such third party.
            </p>

            <p className={styles.heading2}>Social Networking Services</p>
            <p className={styles.paragraph}>
              You may enable or log in to the Service via various online third
              party services, such as social media and social networking
              services like Facebook or Twitter (“Social Networking Services”).
              By logging in or directly integrating these Social Networking
              Services into the Service, we make your online experiences richer
              and more personalized. To take advantage of this feature and
              capabilities, we may ask you to authenticate, register for or log
              into Social Networking Services on the websites of their
              respective providers. As part of such integration, the Social
              Networking Services will provide us with access to certain
              information that you have provided to such Social Networking
              Services, and we will use, store and disclose such information in
              accordance with our{" "}
              <Link
                className="blue-color"
                to={"/privacy-and-policy"}
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              . For more information about the implications of activating these
              Social Networking Services and Dot and Line ’s use, storage and
              disclosure of information related to you and your use of such
              services within Dot and Line (including, but not limited to, your
              friend lists), please see our{" "}
              <Link
                className="blue-color"
                to={"/privacy-and-policy"}
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              . However, please remember that the manner in which Social
              Networking Services use, store and disclose your information is
              governed solely by the policies of such third parties, and Dot &
              Line shall have no liability or responsibility for the privacy
              practices or other actions of any third party site or service that
              may be enabled within the Service. <br />
              <br />
              In addition, Dot & Line is not responsible for the accuracy,
              availability or reliability of any information, content, goods,
              data, opinions, advice or statements made available in connection
              with Social Networking Services. As such, Dot & Line is not liable
              for any damage or loss caused or alleged to be caused by or in
              connection with use of or reliance on any such Social Networking
              Services. Dot & Line enables these features merely as a
              convenience and the integration or inclusion of such features does
              not imply an endorsement or recommendation.
            </p>

            <p className={styles.heading2}>Indemnity and Release</p>
            <p className={styles.paragraph}>
              You agree to release, indemnify and hold Dot & Line and its
              affiliates and their officers, employees, directors and agents
              (collectively, “Indemnitees”) harmless from any and all losses,
              damages, expenses, including reasonable attorneys’ fees, rights,
              claims, actions of any kind and injury (including death) arising
              out of or relating to your use of the Service, any User Content,
              your connection to the Service, your violation of these Terms of
              Service or your violation of any rights of another.
            </p>

            <p className={styles.heading2}>Disclaimer of Warranties</p>
            <p className={styles.paragraph}>
              YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
              PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. Dot & Line
              EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
              IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE AND NON-INFRINGEMENT. <br />
              <br />
              Dot & Line MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR
              REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
              SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM
              THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE
              QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
              PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR
              EXPECTATIONS. <br />
              <br />
              YOU ACKNOWLEDGE AND AGREE THAT ANY CRIMINAL BACKGROUND CHECKS
              CONDUCTED BY Dot & Line ON TEACHERS ARE SOLELY FOR ITS OWN
              BENEFIT. Dot & Line MAKES NO REPRESENTATIONS OR WARRANTIES AS TO
              THE CONDUCT OF USERS ON THE SITE OR SERVICE.
            </p>

            <p className={styles.heading2}>Limitation of Liability</p>
            <p className={styles.paragraph}>
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT Dot & Line WILL NOT BE
              LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
              EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT
              NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER
              INTANGIBLE LOSSES (EVEN IF Dot & Line HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT,
              NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE
              USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF
              PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
              GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
              MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
              SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
              TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD
              PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE
              SERVICE. IN NO EVENT WILL Dot & Line ’S TOTAL LIABILITY TO YOU FOR
              ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE
              PAID Dot & Line IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE
              HUNDRED DOLLARS ($100). <br />
              <br />
              SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
              CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
              INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
              ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE
              ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH ANY
              PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE
              AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE. <br />
              <br />
              Dispute Resolution By Binding Arbitration: PLEASE READ THIS
              SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS
            </p>

            <p className={styles.heading2}>Agreement to Arbitrate</p>
            <p className={styles.paragraph}>
              This Dispute Resolution by Binding Arbitration section is referred
              to in this Terms of Service as the “Arbitration Agreement.” You
              agree that any and all disputes or claims that have arisen or may
              arise between you and Dot & Line, whether arising out of or
              relating to this Terms of Service (including any alleged breach
              thereof), the Services, any advertising, any aspect of the
              relationship or transactions between us, shall be resolved
              exclusively through final and binding arbitration, rather than a
              court, in accordance with the terms of this Arbitration Agreement,
              except that Dot & Line may seek injunctive relief in any court of
              competent jurisdiction. You agree that, by entering into this
              Terms of Service, you and Dot & Line are each waiving the right to
              a trial by jury or to participate in a class action. Your rights
              will be determined by a neutral arbitrator, not a judge or jury.
            </p>

            <p className={styles.heading2}>
              Prohibition of Class and Representative Actions and
              Non-Individualized Relief
            </p>
            <p className={styles.paragraph}>
              YOU AND Dot & Line AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST
              THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR
              CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR
              PROCEEDING. UNLESS BOTH YOU AND Dot & Line AGREE OTHERWISE, THE
              ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR
              PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
              CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE
              ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
              DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING
              RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF
              NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S).
            </p>

            <p className={styles.heading2}>
              Pre-Arbitration Dispute Resolution
            </p>
            <p className={styles.paragraph}>
              Dot & Line is always interested in resolving disputes amicably and
              efficiently, and most customer concerns can be resolved quickly
              and to the customer’s satisfaction by emailing customer support at{" "}
              <span className="blue-color">
                customercare@dotandlinelearning.com
              </span>
              . All claims and disputes whatsoever arising under this Agreement
              as are incapable of amicable resolution during the normal course
              of business, shall be referred to arbitration in accordance with
              the Arbitration Act 1940 and the rules made thereunder. Each Party
              shall be entitled to nominate an arbitrator and the arbitration
              proceedings shall be conducted by two (2) arbitrators, whose
              decision shall be final and binding upon the Parties. In the event
              that the arbitrators are unable to reach a mutually agreeable
              decision, the arbitrators shall mutually agree upon appointment of
              an umpire. The umpire shall be a retired judge of a High Court in
              Pakistan or of the Supreme Court of Pakistan, whose decision shall
              be final and binding in the matter. The language of the
              arbitration tribunal and its proceedings shall be in English. The
              seat of arbitration shall be Karachi, Pakistan. The costs of the
              arbitration shall be borne by the unsuccessful party.
            </p>

            <p className={styles.heading2}>Confidentiality</p>
            <p className={styles.paragraph}>
              All aspects of the arbitration proceeding, and any ruling,
              decision, or award by the arbitrator, will be strictly
              confidential for the benefit of all parties.
            </p>

            <p className={styles.heading2}>Severability</p>
            <p className={styles.paragraph}>
              If a court or the arbitrator decides that any term or provision of
              this Arbitration Agreement (other than the subsection (b) titled
              “Prohibition of Class and Representative Actions and
              Non-Individualized Relief” above) is invalid or unenforceable, the
              parties agree to replace such term or provision with a term or
              provision that is valid and enforceable and that comes closest to
              expressing the intention of the invalid or unenforceable term or
              provision, and this Arbitration Agreement shall be enforceable as
              so modified. The remainder of the Terms of Service will continue
              to apply
            </p>

            <p className={styles.heading2}>Termination</p>
            <p className={styles.paragraph}>
              You agree that Dot & Line , in its sole discretion, may suspend or
              terminate your account (or any part thereof) or use of the Service
              and remove and discard any content within the Service, for any
              reason, including, without limitation, for lack of use or if Dot &
              Line believes that you have violated or acted inconsistently with
              the letter or spirit of these Terms of Service. Any suspected
              fraudulent, abusive or illegal activity that may be grounds for
              termination of your use of Service, may be referred to appropriate
              law enforcement authorities. Dot & Line may also in its sole
              discretion and at any time discontinue providing the Service, or
              any part thereof, with or without notice. You agree that any
              termination of your access to the Service under any provision of
              this Terms of Service may be effected without prior notice, and
              acknowledge and agree that Dot & Line may immediately deactivate
              or delete your account and all related information and files in
              your account and/or bar any further access to such files or the
              Service. Further, you agree that Dot & Line will not be liable to
              you or any third party for any termination of your access to the
              Service.
            </p>

            <p className={styles.heading2}>User Disputes</p>
            <p className={styles.paragraph}>
              You agree that you are solely responsible for your interactions
              with any other user in connection with the Service and Dot & Line
              will have no liability or responsibility with respect thereto. Dot
              & Line reserves the right, but has no obligation, to become
              involved in any way it deems necessary with disputes between you
              and any other user of the Service.
            </p>

            <p className={styles.heading2}>General</p>
            <p className={styles.paragraph}>
              These Terms of Service constitute the entire agreement between you
              and Dot & Line and govern your use of the Service, superseding any
              prior agreements between you and Dot & Line with respect to the
              Service. You also may be subject to additional terms and
              conditions that may apply when you use affiliate or third party
              services, third party content or third party software.{" "}
              <span className="font-weight-600">
                These Terms of Service will be governed by the laws of Pakistan.
              </span>{" "}
              With respect to any disputes or claims not subject to arbitration,
              as set forth above, you and Dot and Line agree to submit to the
              personal and exclusive jurisdiction of the courts of Pakistan. The
              failure of Dot & Line to exercise or enforce any right or
              provision of these Terms of Service will not constitute a waiver
              of such right or provision. If any provision of these Terms of
              Service is found by a court of competent jurisdiction to be
              invalid, the parties nevertheless agree that the court should
              endeavor to give effect to the parties’ intentions as reflected in
              the provision, and the other provisions of these Terms of Service
              remain in full force and effect. You agree that regardless of any
              statute or law to the contrary, any claim or cause of action
              arising out of or related to use of the Service or these Terms of
              Service must be filed within one (1) year after such claim or
              cause of action arose or be forever barred. A printed version of
              this agreement and of any notice given in electronic form will be
              admissible in judicial or administrative proceedings based upon or
              relating to this agreement to the same extent and subject to the
              same conditions as other business documents and records originally
              generated and maintained in printed form. You may not assign these
              Terms of Service without the prior written consent of Dot and Line
              , but Dot & Line may assign or transfer these Terms of Service, in
              whole or in part, without restriction. The section titles in these
              Terms of Service are for convenience only and have no legal or
              contractual effect. Notices to you may be made via either email or
              regular mail. The Service may also provide notices to you of
              changes to these Terms of Service or other matters by displaying
              notices or links to notices generally on the Service.
            </p>

            <p className={styles.heading2}>Your Privacy</p>
            <p className={styles.paragraph}>
              At Dot & Line , we respect the privacy of our users. For details
              please see our{" "}
              <Link
                className="blue-color"
                to={"/privacy-and-policy"}
                target={"_blank"}
              >
                Privacy Policy
              </Link>
              . By using the Service, you consent to our collection and use of
              personal data as outlined therein.
            </p>

            <p className={styles.heading2}>Questions? Concerns? Suggestions?</p>
            <p className={styles.paragraph2}>
              Please contact us at{" "}
              <span className="blue-color">info@dotandlinelearning.com</span> to
              report any violations of these Terms of Service or to pose any
              questions regarding this Terms of Service or the Service.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
