import React from "react";

import { Modal } from "antd";
import { isMobile } from "react-device-detect";
import YouTube from "react-youtube";

import styles from "./styles.module.css";

const opts = {
  height: window?.innerWidth < 991 ? 300 : 350,
  width: "100%",
  playerVars: {
    autoplay: 0,
    rel: 0,
  },
};

const YoutubeModal = ({ show = false, setShow, url }) => {
  const playerRef = React.useRef(null);

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <Modal
      closable
      onCancel={() => setShow(false)}
      maskClosable
      centered
      footer={false}
      width={isMobile ? "100%" : "60%"}
      className={[styles.modalVideo, "g-modal"].join(" ")}
      open={show}
    >
      <div className="mt-30">
        <YouTube
          ref={playerRef}
          videoId={url}
          opts={opts}
          onReady={onPlayerReady}
          loading={"eager"}
        />
      </div>
    </Modal>
  );
};

export default React.memo(YoutubeModal);
