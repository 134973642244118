import { useState, useEffect } from "react";

import * as actions from "src/modules/parent/store/actions";

const useFetchData = (id) => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(true);
  const [InvoiceError, setInvoiceError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      actions
        .getInvoicesByEnrolmentId(id)
        .then((response) => {
          setInvoiceLoading(false);
          setInvoiceData(response);
        })
        .catch((error) => {
          setInvoiceError(error);
          setInvoiceLoading(false);
        });
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, [id]);

  return { invoiceData, invoiceLoading, InvoiceError };
};

export default useFetchData;
