import React, { useState } from "react";

import { Row, Col } from "antd";
import { useDispatch } from "react-redux";

import ModalComponent from "src/modules/parent/components/modal";
import styles from "./styles.module.css";
import LoadingScreen from "./loading";
import StepNo1 from "./steps/step1";
import StepNo2 from "./steps/step2";
import StepNo3 from "./steps/step3";
import { getNaplanPackages } from "src/modules/parent/store/actions";
import { selectSubjectNotification } from "src/modules/parent/utils/notificationData";
import actionTypes from "src/modules/parent/store/action-types";
import ErrorScreen from "./error";

const PackageModal = ({
  show,
  setShow,
  handleBookADemo = () => {},
  onHandleTeacheVideo = () => {},
  handleDetails = () => {},
  handleWhatsApp = () => {},
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [subject, setSubject] = useState("");
  const [errorScreen, setErrorScreen] = useState(false);

  const onNext = () => {
    if (!subject) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: selectSubjectNotification,
      });
    }
    setStep("1");

    setTimeout(() => {}, 100);
  };

  const handlePrevious = () => {
    setStep("0");
  };

  const handleFinish = ({ grade, phone }) => {
    const params = {
      grades: [grade],
      subjects: [subject],
      limit: 2,
      phone,
      program_id: subject === "NAPLAN" ? 7 : 5,
    };

    setIsLoading(true);

    getNaplanPackages(params)
      .then((result) => {
        setIsLoading(false);

        if (result && !result.length) {
          return setErrorScreen(true);
        }

        setTeachers(result);

        setStep("2");
      })
      .catch(() => {
        setIsLoading(false);
        setErrorScreen(true);
      });
  };

  const setSubjectData = (i) => {
    setSubject(i.name);
  };

  const resetModal = () => {
    setSubject("");
    setStep("0");
    setErrorScreen(false);
    setTeachers([]);
    setIsLoading(false);
  };

  const handleClose = () => {
    setTimeout(() => {
      setShow(false);
      resetModal();
    }, 100);
  };

  return (
    <ModalComponent
      containerClassName={styles.modalContainer}
      isModalVisible={show}
      onCancel={handleClose}
      style={{ margin: "0px" }}
      footer={false}
    >
      {isLoading ? (
        <LoadingScreen />
      ) : errorScreen ? (
        <ErrorScreen />
      ) : (
        <>
          <Row>
            {step === "0" ? (
              <Col span={24}>
                <StepNo1
                  key={"stepno1"}
                  setSubject={setSubjectData}
                  handleNext={onNext}
                  subject={subject}
                />
              </Col>
            ) : step === "1" ? (
              <Col span={24}>
                <StepNo2
                  key={"stepno2"}
                  subject={subject}
                  handlePrevious={handlePrevious}
                  finishStep={handleFinish}
                />
              </Col>
            ) : (
              <Col span={24}>
                <StepNo3
                  data={teachers}
                  subject={subject}
                  handleWhatsApp={handleWhatsApp}
                  handleShowModal={onHandleTeacheVideo}
                  handleBookADemo={handleBookADemo}
                  handleDetails={handleDetails}
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </ModalComponent>
  );
};

export default React.memo(PackageModal);
