import React, { useCallback, useEffect, useState } from "react";

import { Row, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";

import BasicFilters from "src/modules/parent/components/basicFilters";
import Text from "src/modules/parent/components/text";
import codingImage from "src/modules/parent/assets/svgs/subject/coding.png";
import englishImage from "src/modules/parent/assets/svgs/subject/english.png";
import frenchImage from "src/modules/parent/assets/svgs/subject/french.png";
import mathImage from "src/modules/parent/assets/svgs/subject/math.png";
import urduImage from "src/modules/parent/assets/svgs/subject/urdu.png";
import searchIcon from "src/modules/parent/assets/svgs/searchIcon.svg";
import * as actions from "../../store/actions";

import styles from "./styles.module.css";

const popularSubjects = [
  {
    id: "0",
    title: "English",
    image: englishImage,
  },
  {
    id: "1",
    title: "Math",
    image: mathImage,
  },
  {
    id: "2",
    title: "Urdu",
    image: urduImage,
  },
  {
    id: "3",
    title: "Coding",
    image: codingImage,
  },
  {
    id: "4",
    title: "French",
    image: frenchImage,
  },
];

const subjectTitles = [
  {
    id: "0",
    label: "English,",
    title: "English",
  },
  {
    id: "1",
    label: "Math,",
    title: "Math",
  },
  {
    id: "2",
    label: "Urdu,",
    title: "Urdu",
  },
  {
    id: "3",
    label: "Coding,",
    title: "Coding",
  },
  {
    id: "4",
    label: "French",
    title: "French",
  },
];

const FindCourses = ({ title = "Find More Courses" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const { keywordList } = useSelector((state) => state.subjectReducer);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    if (keywordList && !keywordList.length) {
      action.fetchKeywords();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordList]);

  const basicFilterHandler = useCallback(
    (subject, newTab) => {
      navigate(`/courses`, {
        state: {
          keywords: subject ? [subject] : [subjects],
        },
      });
    },
    [navigate, subjects]
  );

  const handlePopularSelect = useCallback(
    (data) => {
      basicFilterHandler(data.title, true);
    },
    [basicFilterHandler]
  );

  const Item = useCallback(
    (item) => (
      <div onClick={() => handlePopularSelect(item)} className={styles.tabs}>
        <Space>
          <img src={item.image} alt={"img"} />

          <div>{item.title}</div>
        </Space>
      </div>
    ),
    [handlePopularSelect]
  );

  return (
    <div className={styles.mainSearchContainer}>
      <div className="app-container pt-50 trophy-container">
        <div className={"titleHeading mb-30"}>{title}</div>

        <Row gutter={[24, 24]}>
          <BasicFilters
            gradeFilterDisabled
            subjects={subjects}
            isDisabled={isMobile}
            allowClear={false}
            keywordList={keywordList}
            suffixIcon={
              isMobile ? (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    handlePopularSelect({ title: subjects });
                    e.stopPropagation();
                  }}
                >
                  <img src={searchIcon} alt={"icon"} />
                </div>
              ) : (
                ""
              )
            }
            setSubjects={setSubjects}
            onSelect={setSubjects}
            basicFilterHandler={() => basicFilterHandler()}
          />
        </Row>

        <div className={"mt-30 mb-30"}></div>

        {!isMobile ? (
          <Row gutter={[20, 20]}>
            {popularSubjects.map((item) => (
              <Item key={item.id} {...item} />
            ))}
          </Row>
        ) : (
          <div className={styles.subjectStyle}>
            <Row align={"middle"}>
              <Text
                size={"S"}
                handleClick={() => {}}
                lHeight={"S"}
                className={"mr-10"}
                title={"Popular Searches right now:"}
              />

              {subjectTitles.map((item) => (
                <Text
                  key={item.id}
                  size={"S"}
                  lHeight={"M2"}
                  color={"#005EFF"}
                  className={styles.subjectClick}
                  handleClick={() => handlePopularSelect(item)}
                  title={item.label}
                />
              ))}
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(FindCourses);
