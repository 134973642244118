import React from "react";

import { Col, Row, Space, Button } from "antd";
import Modal from "../modal";
import errorImg from "src/modules/parent/assets/notificationSvgs/errorImg.svg";
import successImg from "src/modules/parent/assets/notificationSvgs/success.svg";
import info from "src/modules/parent/assets/notificationSvgs/info.svg";

import styles from "./styles.module.css";
import { Link } from "react-router-dom";

const getImage = (image, type) => {
  if (!image) {
    let svgImage;

    switch (type) {
      case "error":
        svgImage = errorImg;
        break;
      case "success":
        svgImage = successImg;
        break;

      case "default-success":
        svgImage = successImg;
        break;

      default:
        svgImage = info;
        break;
    }

    return svgImage;
  }

  return image;
};

const ModalComponent = ({
  handleClose,
  show = false,
  type,
  title,
  successBtnText,
  handleSuccessBtn,
  link,
  linkText,
  disableCancelBtn,
  content,
  cancelBtnText,
  handleCancelBtn,
  image,
  disableClose,
  customStyles,
  centerAlign = false,
  noImg = false,
}) => (
  <Modal
    style={{ width: "70%", textAlign: centerAlign ? "center" : "start" }}
    onCancel={!disableClose ? handleClose : () => {}}
    isModalVisible={show}
    closable={!disableClose}
    containerClassName={customStyles}
  >
    <Col span={24}>
      {title && (
        <Row align={"middle"}>
          <Space size={"middle"}>
            <img src={getImage(image, type)} alt={"modalImg"} />

            <div
              className={[
                styles.modalHeader,
                type === "error"
                  ? styles.error
                  : type === "success"
                  ? styles.success
                  : type === "default-success"
                  ? styles.defaultSuccess
                  : styles.info,
              ].join(" ")}
            >
              {title}
            </div>
          </Space>
        </Row>
      )}

      {content ? (
        !title ? (
          <Row className={"mt-20"}>
            {!noImg && (
              <div className={styles.modalImg}>
                <img
                  src={getImage(image, type)}
                  style={{ width: "100%", height: "100%" }}
                  alt={"modalImg"}
                />
              </div>
            )}

            <div
              className={styles.content}
              style={{ width: noImg ? "100%" : "70%" }}
            >
              {content()}
            </div>
          </Row>
        ) : (
          content()
        )
      ) : null}

      {successBtnText && (
        <Row
          className={"mt-20"}
          justify={centerAlign ? "center" : "end"}
          align={"middle"}
        >
          <Space>
            {!disableCancelBtn ? (
              link ? (
                <Link to={link}>{linkText}</Link>
              ) : (
                <Button
                  onClick={handleCancelBtn}
                  className={styles.cancelButton}
                >
                  {cancelBtnText}
                </Button>
              )
            ) : null}

            <Button onClick={handleSuccessBtn} className={styles.successButton}>
              {successBtnText}
            </Button>
          </Space>
        </Row>
      )}
    </Col>
  </Modal>
);

export default ModalComponent;
