import React, { useState } from "react";

import { useDispatch } from "react-redux";
import TiktokPixel from "tiktok-pixel";

import Text from "src/modules/parent/components/text";
import { tiktok_pixel_id } from "src/modules/parent/config/constant";
import ModalComponent from "src/modules/parent/components/modalComponent";
import AppLoader from "src/modules/parent/components/app-loader";
import noData from "src/modules/parent/assets/svgs/noDataupdated.svg";
import actionTypes from "src/modules/parent/store/action-types";
import InvoiceCard from "src/modules/parent/pages/invoices/components/invoiceCard";
import {
  invoicePayNowBtnRedirect,
  trackEvents,
} from "src/modules/parent/utils/events/query";
import { invoiceLinkNotification } from "src/modules/parent/utils/notificationData";

import styles from "./styles.module.css";

TiktokPixel.init(tiktok_pixel_id);

const Invoices = ({ data = [], loadingInvoices = false }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [link, setLink] = useState("");
  const [viewRoute, setViewRoute] = useState("");

  const showPayModal = ({ link, view }) => {
    setShow(true);
    setLink(link);
    setViewRoute(view);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePayNow = () => {
    if (link) {
      window.open(link, "_blank");

      trackEvents(invoicePayNowBtnRedirect);
    } else {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: invoiceLinkNotification,
      });
    }
  };

  const descriptionContent = () => (
    <p className={styles.paymentText}>
      {
        "Looks like you have not made the payment for this course yet. Please ignore this message if you have recently made a payment. Our verification timeline is up to 8 hours to update the status of your invoice Thank you!"
      }
    </p>
  );

  return (
    <div>
      <ModalComponent
        show={show}
        title={"Payment Due"}
        type={"error"}
        handleClose={handleClose}
        link={viewRoute}
        handleSuccessBtn={handlePayNow}
        linkText={"View Invoice"}
        successBtnText={"Pay Now"}
        content={descriptionContent}
      />

      {loadingInvoices ? (
        <div className="d-flex justify-content-center mt-50 mb-50 pt-50 pb-50">
          <AppLoader className="font-24" />
        </div>
      ) : (
        <div>
          {data && data?.length === 0 ? (
            <div className={styles.noData}>
              <img src={noData} alt={"nodata"} />

              <Text color={"rgba(153, 153, 153, 1)"} lHeight={"S"} size={"S"}>
                {
                  "Oops! It looks like your invoice isn't ready just yet. It will appear as soon as it's processed! Thank you for your patience!"
                }
              </Text>
            </div>
          ) : (
            <>
              {data &&
                data.length > 0 &&
                data.map((invoice, index) => (
                  <InvoiceCard
                    {...invoice}
                    key={index.toString()}
                    handleDropOut={() => {}}
                    ItemIndex={index}
                    handlePay={showPayModal}
                    xxl={12}
                    xl={12}
                    lg={24}
                  />
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(Invoices);
