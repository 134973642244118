/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import styles from "./../styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Row, Space, Steps } from "antd";
import Step1 from "../step1";
import { useNavigate } from "react-router-dom";
import Step3 from "../step3";
import Step2 from "../step2";
import FlatButton from "src/modules/parent/components/flat-button";
import { bookCourse } from "../../../store/actions";
import { bindActionCreators } from "redux";
import * as actions from "../../../store/actions";
import { getCurrency } from "src/modules/parent/utils";

const BookThisCourse = ({ packageDetail, selectedSlotId, country }) => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const { userInfo } = useSelector((state) => state.authReducer);

  const { profileReducer } = useSelector((state) => state);
  const { myCourses } = profileReducer;
  const [step, setStep] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState();

  const navigate = useNavigate();
  const [blockChildIds, setBlockChildIds] = useState([]);

  useEffect(() => {
    if (selectedSlotId) {
      setSelectedSlot(selectedSlotId);
    }
  }, [selectedSlotId]);

  const getData = useCallback(() => {
    if (userInfo) {
      action.getChildren(userInfo?.id);
      action.getEnrolledCourses();
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (myCourses && myCourses?.length && packageDetail) {
      const filterEnrollment = myCourses.filter(
        (item) => item.package.id === packageDetail.id
      );
      if (filterEnrollment && filterEnrollment.length) {
        setBlockChildIds(filterEnrollment.map((item) => item.student_id));
      }
    }
  }, [myCourses, packageDetail]);

  const nextHandler = () => {
    if (
      (packageDetail?.is_professional && step < 1) ||
      (!packageDetail?.is_professional && step < 2)
    ) {
      setStep(step + 1);
    } else {
      setIsLoading(true);

      const data = {
        package_id: packageDetail.id,
        slot_id: selectedSlot,
        student_id: !selectedChildId ? userInfo?.id : selectedChildId,
        deal_id: packageDetail.demo?.id || null,
        country,
      };

      if (packageDetail?.is_professional || !selectedChildId) {
        data.self_enroll = 1;
      }

      bookCourse(data)
        .then(() => {
          setIsLoading(false);
          navigate("/invoices");
        })
        .catch(() => setIsLoading(false));
    }
  };

  const previousHandler = () => {
    setStep(step - 1);
  };

  const checkButtonDisableState = () => {
    switch (step) {
      case 0:
        return !selectedSlot;
      case 1: {
        if (packageDetail?.is_professional) {
          return false;
        }
        return !selectedChildId;
      }
      default:
        return false;
    }
  };

  const handleSkip = () => {
    setStep(2);
  };

  return (
    <div className={styles.book_this_course}>
      <p className="font-22 font-weight-600 blue-color mb-0">
        {packageDetail?.title}
      </p>
      <p className="font-16 font-weight-400 blue-color mb-0">
        <span className="font-14 blue-color grey-color">By</span>{" "}
        {packageDetail?.teacher?.name}
      </p>
      <div className="mt-20">
        <Steps onChange={setStep} direction="vertical" current={step}>
          <Steps.Step
            title={
              <p className="font-16 font-weight-600 mb-0">Select Time Slot</p>
            }
            description={
              step === 0 ? (
                <Step1
                  packageDetail={packageDetail}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                />
              ) : (
                <div className={styles.h_15} />
              )
            }
          />

          {!packageDetail?.is_professional ? (
            <Steps.Step
              disabled={!selectedSlot}
              title={
                <Row align={"middle"}>
                  <Space size={"middle"}>
                    <div className="font-16 font-weight-600 mb-0">
                      Select Child
                    </div>

                    <div
                      onClick={step === 1 ? handleSkip : () => {}}
                      className={[
                        step === 1 ? styles.skipBtn : "",
                        step === 1 ? "blue-color" : " ",
                        "font-weight-500",
                      ].join(" ")}
                    >
                      {"Skip"}
                    </div>
                  </Space>
                </Row>
              }
              description={
                step === 1 ? (
                  <Step2
                    selectedChildId={selectedChildId}
                    setSelectedChildId={setSelectedChildId}
                    blockChildIds={blockChildIds}
                  />
                ) : (
                  <div className={styles.h_15} />
                )
              }
            />
          ) : null}

          <Steps.Step
            disabled={!selectedChildId && !selectedSlot}
            title={
              <p className="font-16 font-weight-600 mb-0">Confirm Details</p>
            }
            description={
              (!packageDetail?.is_professional && step === 2) ||
              (packageDetail?.is_professional && step === 1) ? (
                <Step3
                  packageDetail={packageDetail}
                  slot={selectedSlot}
                  child={selectedChildId}
                  currency={getCurrency()}
                  isChildDisabled={
                    !selectedChildId || !!packageDetail.is_professional
                  }
                />
              ) : (
                <div className={styles.h_15} />
              )
            }
          />
        </Steps>
      </div>
      <div className={styles.steps_actions}>
        <FlatButton
          title={"Back"}
          className={"mr-20"}
          type={"Tertiary"}
          disabled={step <= 0 || isloading}
          onClick={previousHandler}
        />
        <FlatButton
          loading={isloading}
          onClick={nextHandler}
          disabled={checkButtonDisableState()}
          title={
            (packageDetail?.is_professional && step === 1) ||
            (!packageDetail?.is_professional && step === 2)
              ? "Book Now"
              : "Next"
          }
        />
      </div>
    </div>
  );
};

export default BookThisCourse;
