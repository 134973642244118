import React from "react";

import { Row, Modal, Space, Form, Col, Select, Button } from "antd";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import Text from "src/modules/parent/components/text";
import TextInput from "src/modules/parent/components/text-input";
import { boards } from "src/modules/parent/pages/olevels/components/banner/staticData";
import PhoneWithFlagInput from "src/modules/parent/components/phone-input";
import { gradesData } from "src/modules/parent/utils/static-data";
import { defaultUAE } from "src/modules/parent/utils";

import styles from "./styles.module.css";

const FormModal = ({
  show = false,
  keywordList = [],
  data = {},
  isLoading = false,
  setShow = () => {},
  handleSubmit = () => {},
  handleChange = () => {},
}) => {
  const [country, setCountry] = React.useState(defaultUAE);
  const { profileReducer } = useSelector((state) => state);
  const { countries } = profileReducer;
  const parentRef = React.useRef(null);
  const boardRef = React.useRef(null);
  const gradeRef = React.useRef(null);
  const subjectRef = React.useRef(null);
  const studentRef = React.useRef(null);
  const phoneRef = React.useRef(null);

  const countryOnChange = (e) => {
    const searchPhone = countries.find((i) => i.id === e.toString());
    if (searchPhone) {
      setCountry(searchPhone);
      handleChange("phone", "");
    }
  };

  const handleKeyPress = (e, nextFieldRef) => {
    const value = e?.target?.value || e;
    if (value.length > 0) {
      nextFieldRef.current.focus();
    }
  };

  const onSubmitCheck = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Modal
      closable
      onCancel={() => setShow(false)}
      maskClosable
      centered
      footer={false}
      width={isMobile ? "100%" : "50%"}
      className={[styles.modal, "g-modal"].join(" ")}
      open={show}
    >
      <div className="mt-20">
        <Space direction={"vertical"}>
          <Text
            size={"L"}
            color={"#170F49"}
            font={"SEMIBOLD"}
            title={"Book a Free Demo"}
          />

          <Text
            size={"M"}
            color={"#6F6C90"}
            font={"LIGHTER"}
            title={
              "Enter your details and a Dot & Line academic counselor will be in touch shortly."
            }
          />
        </Space>

        <Form>
          <Row gutter={[0, 10]} className="w-100 mt-20">
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                hasFeedback={true}
                validateStatus={data?.parent_name ? "success" : ""}
              >
                <Row>
                  <Text
                    size={"M"}
                    className={"mb-5"}
                    font={"LIGHTER"}
                    color={"#170F49"}
                    title={"Parent Name"}
                  />
                  <span style={{ color: "red", fontSize: "18px" }}>{" *"}</span>
                </Row>

                <TextInput
                  ref={parentRef}
                  type={"text"}
                  required={true}
                  allowClear={true}
                  placeholder={"Eg. Mustafa"}
                  value={data?.parent_name}
                  onPressEnter={(e) => handleKeyPress(e, boardRef)}
                  onChange={(e) => handleChange("parent_name", e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                hasFeedback={true}
                style={{ marginRight: "10px" }}
                validateStatus={data?.board ? "success" : ""}
              >
                <Row>
                  <Text
                    size={"M"}
                    className={"mb-5"}
                    color={"#170F49"}
                    font={"LIGHTER"}
                    title={"Board of Examination"}
                  />
                  <span style={{ color: "red", fontSize: "18px" }}>{" *"}</span>
                </Row>

                <Select
                  ref={boardRef}
                  style={{
                    borderRadius: "8px",
                    height: "50px",
                  }}
                  className={styles.select}
                  onChange={(e) => {
                    handleChange("board", e);
                    handleKeyPress(e, gradeRef);
                  }}
                  showSearch={true}
                  allowClear
                  value={data?.board}
                  placeholder={"Eg. Cambridge"}
                  options={boards}
                />
              </Form.Item>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                hasFeedback={true}
                validateStatus={data?.grade ? "success" : ""}
              >
                <Row>
                  <Text
                    size={"M"}
                    className={"mb-5"}
                    font={"LIGHTER"}
                    color={"#170F49"}
                    title={"Grade Level"}
                  />
                  <span style={{ color: "red", fontSize: "18px" }}>{" *"}</span>
                </Row>

                <Select
                  ref={gradeRef}
                  style={{ borderRadius: "8px", height: "50px" }}
                  className={styles.select}
                  onChange={(e) => {
                    handleChange("grade", e);
                    handleKeyPress(e, subjectRef);
                  }}
                  showSearch={true}
                  allowClear
                  value={data?.grade}
                  placeholder={"Eg. Grade 4"}
                  options={gradesData}
                />
              </Form.Item>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                hasFeedback={true}
                style={{ marginRight: "10px" }}
                validateStatus={data?.subject ? "success" : ""}
              >
                <Row>
                  <Text
                    size={"M"}
                    className={"mb-5"}
                    color={"#170F49"}
                    font={"LIGHTER"}
                    title={"Subject"}
                  />
                  <span style={{ color: "red", fontSize: "18px" }}>{" *"}</span>
                </Row>

                <Select
                  ref={subjectRef}
                  style={{ borderRadius: "8px", height: "50px" }}
                  className={styles.select}
                  onChange={(e) => {
                    handleChange("subject", e);
                    handleKeyPress(e, studentRef);
                  }}
                  showSearch={true}
                  allowClear
                  value={data?.subject}
                  placeholder={"Eg. Math"}
                  options={keywordList}
                />
              </Form.Item>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item hasFeedback={false} validateStatus={"success"}>
                <Text
                  size={"M"}
                  className={"mb-7"}
                  font={"LIGHTER"}
                  color={"#170F49"}
                  title={"Student Name"}
                />

                <TextInput
                  ref={studentRef}
                  type={"text"}
                  required={false}
                  allowClear={true}
                  placeholder={"Eg. Ahmed"}
                  value={data?.student_name}
                  onPressEnter={(e) => handleKeyPress(e, phoneRef)}
                  onChange={(e) => handleChange("student_name", e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                hasFeedback={true}
                validateStatus={data?.phone ? "success" : ""}
              >
                <Row>
                  <Text
                    size={"M"}
                    className={"mb-5"}
                    color={"#170F49"}
                    font={"LIGHTER"}
                    title={"Phone No."}
                  />
                  <span style={{ color: "red", fontSize: "18px" }}>{" *"}</span>
                </Row>

                <PhoneWithFlagInput
                  ref={phoneRef}
                  allowClear={true}
                  showSearch={true}
                  inputPlaceholder={"Phone"}
                  value={data?.phone}
                  selectedId={defaultUAE.id}
                  countryOnChange={countryOnChange}
                  countryPlaceholder={"Select"}
                  selectedCountry={country?.value}
                  onKeyUp={(e) => onSubmitCheck(e)}
                  onChange={(e) => handleChange("phone", e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row justify={"center"}>
                <Button
                  onClick={handleSubmit}
                  loading={isLoading}
                  className={styles.btn}
                >
                  {"Done!"}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default React.memo(FormModal);
