import React from "react";

import { Col } from "antd";
import Carousel from "react-multi-carousel";

import { responsiveBlogs } from "src/modules/parent/utils";
import img1 from "../../assets/testimonials/img1.svg";
import img2 from "../../assets/testimonials/img2.svg";
import img3 from "../../assets/testimonials/img3.svg";
import img4 from "../../assets/testimonials/img4.svg";
import img5 from "../../assets/testimonials/img5.svg";
import img6 from "../../assets/testimonials/img6.svg";

const data = [
  {
    id: "0",
    image: img1,
  },
  {
    id: "1",
    image: img2,
  },
  {
    id: "2",
    image: img3,
  },
  {
    id: "3",
    image: img4,
  },
  {
    id: "4",
    image: img5,
  },
  {
    id: "5",
    image: img6,
  },
];

const Testimonials = () => (
  <div className={"mt-50"}>
    <Carousel infinite responsive={responsiveBlogs}>
      {data.map((item) => (
        <Col key={item.id}>
          <img src={item.image} alt="testimonials" />
        </Col>
      ))}
    </Carousel>
  </div>
);

export default Testimonials;
