import {
  CalendarOutlined,
  ClockCircleOutlined,
  ReadOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import styles from "./../styles.module.css";
import noImage from "./../../../assets/img/anonymous.jpeg";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { getConvertedTime } from "src/modules/parent/utils";

const InstructorInfo = ({ packageDetail, isMobile }) => {
  const navigate = useNavigate();
  const day = moment(packageDetail?.slots?.start_time)?.format(
    "ddd, DD MMM YYYY"
  );

  return (
    <div>
      {!isMobile && <p className={"f-md blue-color mt-50 mb-10"}>Details:</p>}
      <div className={styles.teacher_card}>
        <div className={"d-row mt-25"}>
          <div
            className={styles.profile_div}
            onClick={() => navigate(`/teachers/${packageDetail?.teacher?.id}`)}
          >
            <img src={packageDetail?.teacher?.picture || noImage} alt="" />
          </div>
          <div className={"ml-15"}>
            <p
              className="mb-0 mt-5 f-md blue-color text-hover-underline"
              onClick={() =>
                navigate(`/teachers/${packageDetail?.teacher?.id}`)
              }
            >
              {packageDetail?.teacher?.name}
            </p>
            {packageDetail?.teacher?.ratings > 0 && (
              <div className={[styles.ratings_div, "d-row mt-5"].join(" ")}>
                <p className="f-md mb-0">{packageDetail?.teacher?.ratings}</p>
                <span className="d-row mt-4 ml-6">
                  <StarFilled className="font-20" />
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-15">
          <Row className="dark-grey-color">
            <Col className="mt-15" span={24}>
              <div className="d-row">
                <CalendarOutlined className="font-24" />
                <p className="mb-0 ml-12 f-sm">{day || ""}</p>
              </div>
            </Col>
            <Col className="mt-15" span={24}>
              <div className="d-row">
                <ClockCircleOutlined className="font-24" />
                <p className="mb-0 ml-12 f-sm">
                  {getConvertedTime(
                    packageDetail?.slots?.start_time,
                    packageDetail?.slots?.end_time,
                    packageDetail?.slots?.time_zone,
                    true
                  )}
                </p>
              </div>
            </Col>
            {packageDetail?.free_subject && (
              <Col className="mt-15" span={24}>
                <div className="d-row">
                  <ReadOutlined className="font-24" />
                  <p className="mb-0 ml-12 f-sm">
                    {packageDetail?.free_subject}
                  </p>
                </div>
              </Col>
            )}
            {/* <div className={styles.highlighted_price}>
              <p className={"f-sm font-weight-600-imp blue-color mb-0 text"}>
                FREE
              </p>
            </div> */}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default InstructorInfo;
