import React, { Fragment, memo, useState, lazy, Suspense } from "react";
import FlatButton from "../flat-button";
import {
  attendClass,
  checkIsAuthenticated,
  getFreeClassMessage,
  getIsDisabled,
} from "./../../utils";
import moment from "moment-timezone";
import ModalComponent from "../modal";
import AppLoader from "../app-loader";
import FreeClassConfirm from "./free-class-confirm";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";
import actionTypes from "../../store/action-types";
import {
  freeClassNotification,
  classConductedNotification,
} from "../../utils/notificationData";
import { Row } from "antd";

const PackageCard = lazy(() =>
  import("src/modules/parent/components/package-card")
);

const FreeClassCard = ({ item }) => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isDisabled = getIsDisabled(item?.slots);

  const registerHandler = () => {
    setLoading(true);
    const data = {
      teacher_id: Number(item?.teacher?.id),
      package_id: Number(item?.id),
    };
    action
      .registerFreeClass(data)
      .then(() => {
        setLoading(false);
        setShowConfirmModal(false);

        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: freeClassNotification,
        });
      })
      .catch(() => {
        setLoading(false);
        setShowConfirmModal(false);
      });
  };

  const registerConfirmHandler = (e) => {
    e?.stopPropagation();
    if (checkIsAuthenticated()) {
      if (isDisabled) {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: classConductedNotification,
        });
      } else {
        setShowConfirmModal(true);
      }
    } else {
      const data = {
        teacher_id: Number(item?.teacher?.id),
        package_id: Number(item?.id),
      };

      action.setRegisterData({
        show: true,
        bookPackage: data,
        type: "bookFreeClass",
      });
    }
  };

  const openInNewTab = (url) => {
    window?.open(url, "_blank", "noopener,noreferrer");
  };

  const handleJoin = (item) => {
    if (item?.slots) {
      const slotDay = moment(item?.slots?.start_time)
        ?.format("ddd")
        ?.toLocaleLowerCase();
      const isFree = true;
      if (attendClass(item?.slots, slotDay, isFree)) {
        if (item?.zoom_link) {
          openInNewTab(item?.zoom_link);
        }
      } else {
        getFreeClassMessage(item?.slots, slotDay);
      }
    }
  };

  return (
    <Fragment>
      <ModalComponent
        closable={false}
        maskClosable={false}
        isModalVisible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        bodyClassName={"d-flex justify-content-center align-items-center"}
        Component={
          <FreeClassConfirm
            loading={loading}
            onOk={registerHandler}
            teacherName={item?.teacher?.name}
            onCancel={() => setShowConfirmModal(false)}
            bookingDate={moment(item?.slots?.start_time).format(
              "dddd DD MMMM, YYYY"
            )}
          />
        }
      />
      <Suspense fallback={<AppLoader className="font-28" />}>
        <PackageCard
          {...item}
          item={item}
          key={item?.id}
          teacher={item?.teacher}
        />
      </Suspense>

      <Row justify={"center"} className="mb-5">
        <FlatButton
          title={item?.is_booked ? "Attend Class" : "Register Now!"}
          type={item?.is_booked ? "Secondary" : "Quaternary"}
          width={"96%"}
          height={"40px"}
          onClick={
            item?.is_booked ? () => handleJoin(item) : registerConfirmHandler
          }
        />
      </Row>
    </Fragment>
  );
};

export default memo(FreeClassCard);
