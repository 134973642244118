import React, { useState, useEffect } from "react";
import { Drawer, Menu, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styles from "./../styles.module.css";
import logo from "./../../../assets/img/dotlinelogowithname.png";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Text from "src/modules/parent/components/text";
import icon from "src/modules/parent/assets/webp/icon.png";
import logoutIcon from "src/modules/parent/assets/webp/logout.png";
import user from "src/modules/parent/assets/webp/user.png";
import * as actions from "../../../store/actions";
import { checkStudentGrades } from "src/modules/parent/utils";
import {
  learningLibraryHeader,
  trackEvents,
} from "src/modules/parent/utils/events/query";
import actionTypes from "src/modules/parent/store/action-types";

const MobileMenuOptions = ({
  isAuthenticated,
  showMobileMenuOptions,
  setShowMobileMenuOptions,
  logout,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);
  const [isShowLibrary, setIsShowLibrary] = useState(false);
  const { activeStudents, selectedChild } = useSelector(
    (state) => state.authReducer
  );
  const action = bindActionCreators(actions, dispatch);

  useEffect(() => {
    if (selectedChild) {
      const isShow = checkStudentGrades(selectedChild);
      setIsShowLibrary(isShow);
    }
  }, [selectedChild]);

  const findTargetByKey = (options, key) => {
    for (const option of options) {
      if (option.key === key) {
        return option.target;
      }
      if (option.children) {
        const target = findTargetByKey(option.children, key);
        if (target) {
          return target;
        }
      }
    }
    return null;
  };

  const contactUsHandler = () => {
    action.setContactUsModal(true);
    setShowMobileMenuOptions(false);
  };

  const logoutHandler = () => {
    logout();
    setShowMobileMenuOptions(false);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  const noAuthDrawerOptions = [
    {
      key: "0",
      label: "Home",
      target: "/",
    },
    {
      key: "sub0",
      label: (
        <>
          Trivia 2025 <span className="newPillsTagMob">new</span>
        </>
      ),
      target: "/trivia",
    },
    {
      key: "sub1",
      label: "Find your Tutor",
      children: [
        {
          key: "1",
          label: "Kindergarten - Grade 7",
          target: "/courses?program_id=5",
        },
        {
          key: "3",
          label: "Grade 8 - Grade 13",
          target: "/exam-prep",
        },
      ],
    },
    {
      key: "sub2",
      label: "Are you a Teacher?",
      children: [
        {
          key: "6",
          label: "IGNITE Teacher Training",
          target: "/ignite",
        },
        {
          key: "7",
          label: "Become a Teacher",
          target: "/teacher/recruitment",
        },
      ],
    },
    {
      key: "12",
      label: "Contact us",
    },
  ];

  const authDrawerOptions = [
    {
      key: "0",
      label: "Home",
      target: "/",
    },
    {
      key: "sub0",
      label: (
        <>
          Trivia 2025 <span className="newPillsTagMob">new</span>
        </>
      ),
      target: "/trivia",
    },
    {
      key: "sub1",
      label: "Find your Tutor",
      children: [
        {
          key: "1",
          label: "Kindergarten - Grade 7",
          target: "/courses?program_id=5",
        },
        {
          key: "3",
          label: "Grade 8 - Grade 13",
          target: "/exam-prep",
        },
      ],
    },
    {
      key: "sub2",
      label: "Are you a Teacher?",
      children: [
        {
          key: "6",
          label: "IGNITE Teacher Training",
          target: "/ignite",
        },
        {
          key: "7",
          label: "Become a Teacher",
          target: "/teacher/recruitment",
        },
      ],
    },
    {
      key: "sub3",
      label: "Account",
      children: [
        {
          key: "8",
          label: "My Profile",
          target: "/profile",
        },
        {
          key: "9",
          label: "My Children",
          target: "/my-children",
        },
        {
          key: "10",
          label: "Invoices",
          target: "/invoices",
        },
      ],
    },
    {
      key: "12",
      label: "Contact us",
    },
  ];

  if (isAuthenticated && activeStudents && isShowLibrary) {
    authDrawerOptions.splice(4, 0, {
      key: "11",
      target: "learning-library/resources/math",
      label: (
        <Space align={"center"}>
          {"Learning Library"}

          <img src={icon} alt={"learning-library"} />
        </Space>
      ),
    });
  }

  const handleClick = ({ key }) => {
    const data = isAuthenticated ? authDrawerOptions : noAuthDrawerOptions;
    const target = findTargetByKey(data, key);

    if (key === "1") {
      setShowMobileMenuOptions(false);
      return dispatch({ type: actionTypes.ASSIST_ME_MODAL, payload: true });
    }

    if (key === "12") {
      return contactUsHandler();
    }

    if (key === "11") {
      trackEvents(learningLibraryHeader);
    }

    if (target) {
      navigate(target);
    }

    setShowMobileMenuOptions(false);
  };

  return (
    <Drawer
      placement={"left"}
      className={"mobile-drawer"}
      open={showMobileMenuOptions}
      styles={{ header: { border: "none" } }}
      onClose={() => setShowMobileMenuOptions(false)}
      width={window?.innerWidth < 350 ? window.innerWidth - 50 : 350}
      title={
        <Link className={styles.logo_div_mobile} to={"/"}>
          <img src={logo} alt="Brand-logo" />
        </Link>
      }
    >
      <div className="mt-15 h-100">
        <div className="d-column h-100">
          {isAuthenticated ? (
            <div className={styles.menubar}>
              <div>
                <Menu
                  onClick={handleClick}
                  style={{ width: "100%" }}
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  mode={"inline"}
                  items={authDrawerOptions}
                />
              </div>

              <div onClick={logoutHandler} className={styles.logoutDiv}>
                <Space>
                  <img src={logoutIcon} alt={"logout"} />

                  <Text size={"S"} color={"#000000"} title={"Logout"} />
                </Space>
              </div>
            </div>
          ) : (
            <div className={styles.menubar}>
              <div>
                <Menu
                  onClick={handleClick}
                  style={{ width: "100%" }}
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  mode={"inline"}
                  items={noAuthDrawerOptions}
                />
              </div>

              <div
                onClick={() => navigate("/login")}
                className={styles.logoutDiv}
              >
                <Space>
                  <img src={user} alt={"login"} />

                  <Text size={"S"} color={"#000000"} title={"Login"} />
                </Space>
              </div>

              <div
                onClick={() => navigate("/signup")}
                className={styles.signUp}
              >
                <Text size={"S"} color={"#000000"} title={"Join for free"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default MobileMenuOptions;
