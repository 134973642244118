/* eslint-disable no-useless-escape */
import { message } from "antd";
import moment, { tz } from "moment-timezone";
import avatars01 from "../assets/avatars/avatars-01.png";
import avatars02 from "../assets/avatars/avatars-02.png";
import avatars03 from "../assets/avatars/avatars-03.png";
import avatars04 from "../assets/avatars/avatars-04.png";
import avatars05 from "../assets/avatars/avatars-05.png";
import avatars06 from "../assets/avatars/avatars-06.png";
import avatars07 from "../assets/avatars/avatars-07.png";
import avatars08 from "../assets/avatars/avatars-08.png";
import avatars09 from "../assets/avatars/avatars-09.png";
import { fb_pixel_id_olevel } from "../config/constant";
import ReactPixel from "react-facebook-pixel";
import { store } from "../store";
import { countries } from "./static-data/countries";
export const noPackageCoverImage =
  "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png";

ReactPixel.init(fb_pixel_id_olevel);

let finalArr = [];

export const capitilizeFirstLetter = (data) => {
  if (data && typeof data === "string") {
    return data?.charAt(0)?.toUpperCase() + data?.slice(1);
  }
};

export const dayName = (day) => {
  switch (day) {
    case "mon":
      return "Monday";
    case "tue":
      return "Tuesday";
    case "wed":
      return "Wednesday";
    case "thu":
      return "Thursday";
    case "fri":
      return "Friday";
    case "sat":
      return "Saturday";
    case "sun":
      return "Sunday";
    default:
      return "Invalid day";
  }
};

export const createQueryString = (params) => {
  const queryString = Object.keys(params)
    .map((key) => {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          let paramArr = params[key]
            .map((_, index) => {
              return key + "[]=" + params[key][index];
            })
            .join("&");
          return paramArr;
        } else {
          return key + "=" + params[key];
        }
      }
      return "";
    })
    .join("&");
  return queryString;
};

export const capitalizeWords = (str) => {
  const arr = str.split(",");
  return arr
    .map((element) => {
      return (
        element?.charAt(0)?.toUpperCase() + element?.substring(1)?.toLowerCase()
      );
    })
    .join(", ");
};

export const getTime = (item, time) => {
  const timeSlot = item?.time_slots
    ? item?.time_slots
    : item.slot
    ? item.slot
    : item;
  let timezone = timeSlot.time_zone ? timeSlot.time_zone : "Asia/Karachi";
  if (timeSlot.enrolls) {
    timezone = timeSlot.time_zone;
  } else if (timeSlot.time_zone) {
    timezone = timeSlot.time_zone;
  } else {
    timezone = timeSlot.time_zone;
  }
  return tz(`2021-11-11 ${time}`, timezone).format("hh:mm A");
};

const toRanges = (values, separator = "\u2013") => {
  return values
    .slice()
    .sort((p, q) => p - q)
    .reduce((acc, cur, idx, src) => {
      if (idx > 0 && cur - src[idx - 1] === 1) acc[acc.length - 1][1] = cur;
      else acc.push([cur]);
      return acc;
    }, [])
    .map((range) => range.join(separator));
};

export const getGrades = (item) => {
  if (item?.grades) {
    const grades = [...item?.grades];
    const sortedGrades = grades?.map((grade) => {
      if (grade?.title.indexOf("Grade ") !== -1) {
        return grade?.title?.slice(grade?.title?.indexOf("Grade ") + 6);
      } else {
        return grade?.title;
      }
    });
    return toRanges(sortedGrades).join(", ");
  }
};

const modifyArray = (arr) => {
  if (arr.includes("KG")) {
    arr = arr.filter((item) => item !== "KG");
    arr.unshift("KG");
  }

  if (arr.includes("Nursery")) {
    arr = arr.filter((item) => item !== "Nursery");
    arr.unshift("Nursery");
  }

  arr = arr.map((item, index) => {
    if (item !== "KG" && item !== "Nursery") {
      return "Grade " + item;
    }
    return item;
  });

  return arr;
};

export const getGradeTagsArray = (grades = []) => {
  const sortedGrades = grades?.map((grade) => {
    if (grade?.title.indexOf("Grade ") !== -1) {
      return grade?.title?.slice(grade?.title?.indexOf("Grade ") + 6);
    } else {
      return grade?.title;
    }
  });

  const gradeRanges = toRanges(sortedGrades);
  const modifiedArray = modifyArray(gradeRanges);

  return modifiedArray;
};

export const getNextDay = function (dayName, skip = 0) {
  let date = new Date();
  let now = date.getDay();
  let days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  let day = days.indexOf(dayName.toLowerCase());
  let diff = day - now;
  diff = diff < 1 ? 7 + diff : diff;
  diff = diff + skip;
  let nextDayTimestamp = date.getTime() + 1000 * 60 * 60 * 24 * diff;
  return new Date(nextDayTimestamp);
};

export const getDiff = (dayName) => {
  let date = new Date();
  let now = date.getDay();
  let days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  let day = days.indexOf(dayName.toLowerCase());
  let diff = day - now;
  return diff < 1 ? 7 + diff : diff;
};

export const validateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  } else {
    return false;
  }
};

export const containsNumbersOrSpecialCharacters = (str) => {
  const isExist = /[^a-zA-Z\s]/.test(str);
  if (isExist) {
    message.error("Numeric digits and special characters are not allowed.");
  }
  return isExist;
};

export const error = (content) => {
  message.error({
    content,
    style: { width: "100%" },
  });
};

export const axiosErrorHandler = (error) => {
  if (error.response && error.response.data) {
    let err = error?.response?.data?.errors
      ? error?.response?.data?.errors
      : "";
    let objectKeys = Object.keys(err);
    if (objectKeys.length > 0) {
      let errormsg = error?.response?.data?.errors[objectKeys[0]];
      message.error(errormsg[0]);
    } else {
      message.error(error.response.data.message);
    }
    return error;
  }
  message.error(error.message);
};

export const generateSlots = (filter) => {
  let timeSlotArr = [];
  timeSlotArr.push(
    ...[
      ...filter.map((a) => {
        return { ...a, sortIndex: 0 };
      }),
      ...filter.map((a) => {
        return { ...a, sortIndex: 1 };
      }),
    ]
  );
  finalArr = timeSlotArr.map((slots) => {
    return {
      ...slots,
      date: getNextDay(slots.day, 7 * slots.sortIndex),
    };
  });
};

export const getTimeSlots = (slotsArr, dateToday) => {
  let getToday = dateToday
    ? moment(dateToday).format("ddd")
    : moment().format("ddd");
  let filter = slotsArr.flatMap((slots) => {
    return slots.days.split(",").map((day) => {
      return { ...slots, day };
    });
  });

  filter.sort((a, b) => {
    let day1 = a.day.toLowerCase();
    let day2 = b.day.toLowerCase();

    return sorter()[day1] - sorter()[day2];
  });

  let match = 0;
  filter.forEach((item, index) => {
    if (item.day === getToday.toLowerCase() && match === 0) {
      match = 1;
      generateSlots(filter);
    }

    if (index === filter.length - 1 && !match) {
      let id = 0;
      filter.forEach((item) => {
        let dd = getDiff(item.day);

        if (dd > 0 && id === 0) {
          id++;
          generateSlots(filter);
        }
      });
    }
  });
  return finalArr;
};

export const sorter = () => {
  let day_of_week = new Date().getDay();
  let list = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  let sorted_list = list
    .slice(day_of_week + 1)
    .concat(list.slice(0, day_of_week + 1));
  let obj = {};

  sorted_list.forEach((item, index) => {
    obj[item] = index + 1;
  });

  return obj;
};

export const emptyKeysRemover = (data) => {
  const result = {};
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const value = data[key];
      if (
        (Array.isArray(value) && value.length) ||
        (!Array.isArray(value) &&
          value !== undefined &&
          value !== null &&
          value !== "")
      ) {
        result[key] = value;
      }
    }
  }
  return result;
};

export const daysOfWeek = [
  {
    short: "mon",
    long: "Monday",
  },
  {
    short: "tue",
    long: "Tuesday",
  },
  {
    short: "wed",
    long: "Wednesday",
  },
  {
    short: "thu",
    long: "Thursday",
  },
  {
    short: "fri",
    long: "Friday",
  },
  {
    short: "sat",
    long: "Saturday",
  },
  {
    short: "sun",
    long: "Sunday",
  },
];

export const qualificationFormatter = (teacher) => {
  if (teacher?.qualification && teacher?.qualification_level) {
    return `${teacher?.qualification?.name} - ${teacher?.qualification_level?.name}`;
  }
  return teacher?.latest_degree;
};

export const checkPassed = (endTime, zone) => {
  const currentDay = moment().format("YYYY-MM-DD");
  const end = moment(`${currentDay} ${endTime}`).tz(zone);
  const start = moment();

  const diff = start.diff(end, "minutes");

  if (diff > 1) {
    return true;
  }

  return false;
};

export const getSortedDays = () => {
  const goBackDays = 7;

  const today = new Date();
  const daysSorted = [];

  for (let i = 0; i < goBackDays; i++) {
    const newDate = new Date(today.setDate(today.getDate() - 1));
    daysSorted.unshift(daysOfWeek[newDate.getDay()]);
  }

  return daysSorted;
};

export const getConvertedTime = (
  startTime,
  endTime,
  timezone,
  isForFreeClass
) => {
  if (startTime && endTime) {
    const inputTz = timezone || "Asia/karachi";
    const originTimeStart = isForFreeClass
      ? startTime
      : `2022-10-01 ${startTime}`;
    const originTimeEnd = isForFreeClass ? endTime : `2022-10-01 ${endTime}`;
    const timeStart = moment.tz(originTimeStart, inputTz);
    const timeEnd = moment.tz(originTimeEnd, inputTz);
    const localtz = moment.tz.guess();
    const start = timeStart.clone().tz(localtz).format("hh:mm A");
    const end = timeEnd.clone().tz(localtz).format("hh:mm A");
    return `${start} - ${end} (${localtz})`;
  }
  return "";
};

export const timeDifference = (startTime, emdTime) => {
  const start_time = moment(startTime, "hh:mm a");
  const end_time = moment(emdTime, "hh:mm a");
  const start_of_day = moment().startOf("day").add(9, "hours");
  const end_of_day = moment().startOf("day").add(21, "hours");

  if (
    start_time.isSameOrAfter(start_of_day) &&
    end_time.isSameOrBefore(end_of_day)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getTimezone = (startTime, endTime, timezone) => {
  if (startTime && endTime) {
    const inputTz = timezone || "Asia/karachi";
    const originTimeStart = `2022-10-01 ${startTime}`;
    const originTimeEnd = `2022-10-01 ${endTime}`;
    const timeStart = moment.tz(originTimeStart, inputTz);
    const timeEnd = moment.tz(originTimeEnd, inputTz);
    const localtz = moment.tz.guess();
    const start = timeStart.clone().tz(localtz).format("hh:mm A");
    const end = timeEnd.clone().tz(localtz).format("hh:mm A");
    return { start, end };
  }
};

const getAmountFromCurrency = (currency, data) => {
  if (data) {
    if (currency !== "PKR") {
      return `${currency} ${data.int_amount?.toLocaleString()}`;
    } else {
      return `${currency} ${data.amount?.toLocaleString()}`;
    }
  }

  return "";
};

export const checkIsAuthenticated = () => {
  const { isAuthenticated } = store?.getState()?.authReducer;
  if (isAuthenticated) {
    return true;
  }
  return false;
};

function isGradeInRange(selectedGrade) {
  // Define an array of valid grades from Grade 7 to Grade 12
  const validGrades = [
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12",
  ];

  // Check if the selectedGrade is within the valid grades
  return !validGrades.includes(selectedGrade);
}

export const checkStudentGrades = (childId) => {
  const { children } = store?.getState()?.profileReducer;

  if (children && children.length) {
    const child = children.find((c) => c?.id === childId);

    if (child) {
      return isGradeInRange(child?.level);
    }
  }
  return false;
};

export const getPackageAmount = (data) => {
  const guess = moment.tz.guess();
  const countryName = moment.tz.zone(guess).countries();
  const cName = countryName[0] === "PK" ? "PKR" : "USD";

  if (data.is_workshop) {
    return `${getAmountFromCurrency(cName, data)} / workshop`;
  } else {
    return data.is_professional
      ? `${getAmountFromCurrency(cName, data)} / course`
      : `${getAmountFromCurrency(cName, data)} / month`;
  }
};

export const isLocal = () => {
  const guess = moment.tz.guess();
  const countryName = moment.tz.zone(guess).countries();
  return countryName[0] === "PK";
};

export const getCurrency = () => {
  const guess = moment.tz.guess();
  const countryName = moment.tz.zone(guess).countries();
  return countryName[0] === "PK" ? "PKR" : "USD";
};

export const getFreeClassMessage = (slot, slotday, demo) => {
  const isDemo = demo ? "demo" : "class";
  const currentDay = moment().format("ddd").toLocaleLowerCase();
  if (slot && slotday) {
    const slotDays = slotday.split(",");
    const classDay = slotDays.find(
      (i) => i?.toLowerCase() === currentDay?.toLowerCase()
    );
    if (classDay) {
      const day = daysOfWeek.find(
        (i) => i.short?.toLowerCase() === classDay?.toLowerCase()
      );
      if (day) {
        if (checkPassed(slot.end_time, slot.time_zone)) {
          const passedMessage = demo
            ? "Hey there, Your demo date and time have already passed. Please book another demo using our Courses Tab!"
            : "Hey there, Your class time has passed. View your course card for the next time and day. See you soon!";

          message.info(passedMessage);
        } else {
          message.info(
            `Hey There, Your ${isDemo} is scheduled on today at ${getConvertedTime(
              slot.start_time,
              slot.end_time,
              slot.time_zone,
              true
            )}! See you then!`
          );
        }
      }
    } else {
      const sortedDays = getSortedDays();
      const nextDay = sortedDays.filter((i) => {
        return slotDays.find(
          (p) => p?.toLowerCase() === i.short?.toLowerCase()
        );
      });
      if (nextDay && nextDay.length) {
        const startDate = moment(slot?.start_date).format("Do MMMM");
        message.info(
          `Hey There, Your ${isDemo} is scheduled on ${
            startDate ? startDate : nextDay[0].long
          } at ${getConvertedTime(
            slot.start_time,
            slot.end_time,
            slot.time_zone,
            true
          )}! See you then!`
        );
      }
    }
  }
};

const getWeekdayString = (day) => {
  switch (day) {
    case "mon":
      return "Monday";
    case "tue":
      return "Tuesday";
    case "wed":
      return "Wednesday";
    case "thu":
      return "Thursday";
    case "fri":
      return "Friday";
    case "sat":
      return "Saturday";
    case "sun":
      return "Sunday";
    default:
      return day;
  }
};

export const convertStringToWeekdays = (daysString) => {
  const daysArray = daysString.split(",").map((day) => day.trim());
  return daysArray.map((day, index) => getWeekdayString(day)).join(", ");
};

export const getMessage = (slot, slotday, demo) => {
  const isDemo = demo ? "demo" : "class";
  const currentDay = moment().format("ddd").toLocaleLowerCase();
  if (slot && slotday) {
    const slotDays = slotday.split(",");
    const classDay = slotDays.find((i) => i === currentDay);
    if (classDay) {
      const day = daysOfWeek.find((i) => i.short === classDay);
      if (day) {
        if (checkPassed(slot.end_time, slot.time_zone)) {
          const passedMessage = demo
            ? "Hey there, Your demo date and time have already passed. Please book another demo using our Courses Tab!"
            : "Hey there, Your class time has passed. View your course card for the next time and day. See you soon!";

          message.info(passedMessage);
        } else {
          message.info(
            `Hey There, Your ${isDemo} is scheduled on today at ${getConvertedTime(
              slot.start_time,
              slot.end_time,
              slot.time_zone
            )}! See you then!`
          );
        }
      }
    } else {
      const sortedDays = getSortedDays();
      const nextDay = sortedDays.filter((i) => {
        return slotDays.find((p) => p === i.short);
      });
      if (nextDay && nextDay.length) {
        message.info(
          `Hey There, Your ${isDemo} is scheduled on ${
            nextDay[0].long
          } at ${getConvertedTime(
            slot.start_time,
            slot.end_time,
            slot.time_zone
          )}! See you then!`
        );
      }
    }
  }
};

export const attendClass = (slot, slotDays, isFree) => {
  if (slot) {
    const days = slotDays?.split(",");
    const today = moment()?.format("ddd")?.toLowerCase();
    if (days && days?.length) {
      const isClassDay = days?.some(
        (i) => i?.toLowerCase() === today?.toLowerCase()
      );
      if (isClassDay) {
        const zone = slot?.time_zone || "Asia/karachi";
        const currentDay = moment()?.format("YYYY-MM-DD");
        const start = isFree
          ? moment(slot?.start_time).tz(zone)
          : moment(`${currentDay} ${slot?.start_time}`).tz(zone);
        const end = isFree
          ? moment(slot?.end_time)?.tz(zone)
          : moment(`${currentDay} ${slot?.end_time}`)?.tz(zone);
        const now = moment();
        const startDifference = start?.diff(now, "minutes");
        const endDifference = end?.diff(now, "minutes");
        if (endDifference > 0 && startDifference <= 5) {
          return true;
        }
      }
    }
  }
  return false;
};

export const getIsDisabled = (slot) => {
  if (slot) {
    const zone = slot?.time_zone || "Asia/karachi";
    const start = moment(slot?.start_time)?.tz(zone);
    const now = moment();
    const startDifference = start?.diff(now, "minutes");
    if (startDifference < -5) {
      return true;
    }
  }
  return false;
};

export const homeCarousel = {
  large: {
    breakpoint: { min: 500, max: 6000 },
    items: 1,
  },
  small: {
    breakpoint: { min: 0, max: 499 },
    items: 1,
  },
};

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1600 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1200 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1200, min: 800 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};

export const responsiveAssistMe = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1600 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1200 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1200, min: 800 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};

export const responsive2 = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1200 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1599, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1199, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 699, min: 0 },
    items: 1,
  },
};

export const responsiveInvoices = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1200 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1599, min: 1200 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1199, min: 700 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 699, min: 0 },
    items: 1,
  },
};

export const responsiveChildCard = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1800 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1799, min: 1420 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1419, min: 992 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 991, min: 790 },
    items: 5,
  },
  smobile: {
    breakpoint: { max: 789, min: 670 },
    items: 4,
  },
  xsmobile: {
    breakpoint: { max: 669, min: 460 },
    items: 3,
  },
  xxsmobile: {
    breakpoint: { max: 459, min: 0 },
    items: 2,
  },
};

export const reviewCard = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1800 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1799, min: 1420 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1419, min: 992 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 991, min: 790 },
    items: 2,
  },
  smobile: {
    breakpoint: { max: 789, min: 670 },
    items: 1,
  },
  xsmobile: {
    breakpoint: { max: 669, min: 460 },
    items: 1,
  },
  xxsmobile: {
    breakpoint: { max: 459, min: 0 },
    items: 1,
  },
};

export const responsivePartnerCard = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1800 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 1799, min: 1450 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1449, min: 992 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 991, min: 790 },
    items: 4,
  },
  smobile: {
    breakpoint: { max: 789, min: 670 },
    items: 3,
  },
  xsmobile: {
    breakpoint: { max: 669, min: 460 },
    items: 2,
  },
  xxsmobile: {
    breakpoint: { max: 459, min: 0 },
    items: 1,
  },
};

export const childCardResponsive = {
  1350: {
    items: 3,
    itemsFit: "fill",
  },
  992: {
    items: 2,
    itemsFit: "fill",
  },
  700: {
    items: 4,
    itemsFit: "fill",
  },
  470: {
    items: 3,
    itemsFit: "fill",
  },
  300: {
    items: 2,
    itemsFit: "fill",
  },
};

export const responsiveArticles = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1600 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1599, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1199, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 699, min: 0 },
    items: 1,
  },
};

export const responsiveBlogs = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1600 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1599, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1199, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 699, min: 0 },
    items: 1,
  },
};

export const responsiveForReqDemoCards = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1700 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1699, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1399, min: 900 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 899, min: 0 },
    items: 1,
  },
};

export const responsiveForPackageCards = {
  superLargeDesktop: {
    breakpoint: { max: 5000, min: 1300 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1299, min: 1000 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 999, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 699, min: 0 },
    items: 1,
  },
};

export const avatarList = {
  avatars01: {
    image: avatars01,
  },
  avatars02: {
    image: avatars02,
  },
  avatars03: {
    image: avatars03,
  },
  avatars04: {
    image: avatars04,
  },
  avatars05: {
    image: avatars05,
  },
  avatars06: {
    image: avatars06,
  },
  avatars07: {
    image: avatars07,
  },
  avatars08: {
    image: avatars08,
  },
  avatars09: {
    image: avatars09,
  },
};

export const crouselSettings = {
  className: "center",
  centerMode: true,
  infinite: false,
  centerPadding: "25px",
  slidesToShow: 1,
  arrows: false,
  speed: 500,
};

export const reviewSettings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "25px",
  slidesToShow: 1,
  arrows: false,
  speed: 5000,
};

export const crouselSettings2 = {
  infinite: false,
  centerPadding: "25px",
  slidesToShow: 2,
  arrows: false,
  speed: 500,
  center: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const carouselSettingTestimonials = {
  className: "center",
  centerMode: true,
  infinite: false,
  arrows: false,
  centerPadding: "20px",
  slidesToShow: 1,
  speed: 500,
};

export const getWhatsAppUrlToOpen = (text) => {
  const { origin, pathname } = window.location;
  const msg = `Hey I found this ${text} extremely useful and decided to share with you.`;
  const currentUrl = `${origin}${pathname}`;
  const urlToOpen = encodeURIComponent(`${msg}\r\n${currentUrl}`);
  return urlToOpen;
};

const getContent = () => <div></div>;

export const getNotificationData = ({
  title,
  image,
  cancelText,
  disableCancel,
  link,
  linkText,
  successText,
  type,
  content,
  timer,
  handleSuccess,
  handleCancel,
  disableClose,
  centerAlign = false,
  noImg = false,
}) => {
  const obj = {
    cancelText: "Cancel",
    noImg,
    disableCancel: !!disableCancel,
    type: type ? type : "info",
    content: content || getContent,
    centerAlign: !!centerAlign,
    handleSuccess: () => {},
    handleCancel: () => {},
    disableClose: false,
  };

  if (title) {
    obj.title = title;
  }

  if (image) {
    obj.image = image;
  }

  if (cancelText) {
    obj.cancelText = cancelText;
  }

  if (link && linkText) {
    obj.link = link;
    obj.linkText = linkText;
  }

  if (successText) {
    obj.successText = successText;
  }

  if (timer) {
    obj.timer = timer;
  }

  if (typeof handleSuccess === "function") {
    obj.handleSuccess = handleSuccess;
  }

  if (typeof handleCancel === "function") {
    obj.handleCancel = handleCancel;
  }

  if (disableClose) {
    obj.disableClose = true;
  }

  return obj;
};

export const openLinkInNewTab = (urlToOpen) => {
  if (urlToOpen) {
    window.open(urlToOpen, "_blank", "noreferrer");
  } else {
    message.error("Link not found!");
  }
};

export const hasHtmlTags = (str) => {
  const htmlTagsRegex = /<[^>]+>/g;
  return htmlTagsRegex.test(str);
};

export const defaultCountry = countries.find((i) => i.code === "PK");

export const defaultUAE = countries.find((i) => i.code === "AE");

export const getError = (error, touched) => {
  if (typeof error !== "string") {
    if (error && error?.length && touched) {
      return error[0];
    }
  } else {
    if (error && touched) {
      return error;
    }
  }

  return "";
};

export const increaseDemoCount = () => {
  const count = localStorage.getItem("demo_count");
  let counter = 1;
  if (count) {
    counter = Number(count) + counter;
  } else {
    localStorage.setItem("demo_count", counter.toString());
    localStorage.setItem("demo_date", Date.now().toString());
  }
  localStorage.setItem("demo_count", counter.toString());
};

export const getCountryName = () => {
  const guess = moment.tz.guess();
  const countryName = moment.tz.zone(guess).countries();

  const cName = countries.find((i) => i.code === countryName[0]);

  if (cName && cName.title) {
    return cName.title;
  }

  return "Pakistan";
};

export const getUpcomingDay = (daysString, endTime) => {
  const days = daysString.split(",").map((day) => day.trim().toLowerCase());
  const currentTime = moment(); // Get current time
  const currentDay = currentTime.format("ddd").toLowerCase(); // Get current day abbreviation (e.g., "mon")
  const endTimeMoment = moment(endTime, "HH:mm:ss"); // Convert end time to moment object

  const dayIndex = (day) =>
    ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(day);

  for (let i = 0; i < days.length; i++) {
    const day = days[i];
    if (currentDay === day) {
      // If current time is after the end time, move to the next day in the list
      if (currentTime.isAfter(endTimeMoment)) {
        return days[(i + 1) % days.length]; // Use modulus to loop back to start
      } else {
        return day; // Return the current day if still within the time
      }
    }
    // If the current day index is before the listed day index, return that day
    if (dayIndex(day) > dayIndex(currentDay)) {
      return day;
    }
  }

  // If no day found in the list, return the first day in the next week
  return days[0];
};

export const groupArrayOfObjects = (list) => {
  const arr = [];
  const obj = {};
  list.map((item) => {
    const month = moment(item.invoice_date).format("MMMM YYYY");
    if (!obj[month]) {
      obj[month] = [item];
    } else {
      obj[month].push(item);
    }

    return true;
  });

  Object.keys(obj).forEach((key) => {
    arr.push({
      date: key,
      invoiceData: obj[key],
    });
  });

  return arr;
};

export const getLearningSubjects = [
  { id: "1", subject: "Math" },
  { id: "2", subject: "English" },
  { id: "13", subject: "Urdu" },
];

export const getStartEndTime = (time) => {
  switch (time) {
    case "morning":
      return { start: "05:00:00", end: "11:00:00" };
    case "afternoon":
      return { start: "12:00:00", end: "16:00:00" };
    case "evening":
      return { start: "17:00:00", end: "20:00:00" };
    case "night":
      return { start: "20:00:00", end: "05:00:00" };

    default:
      return { start: "14:00:00", end: "20:00:00" };
  }
};

export const getFilteredTimeSlots = (timeSlots, time) => {
  // Return the array as-is if it has only one item or if the first item already meets the condition
  if (timeSlots.length <= 1) return timeSlots;

  const targetStartTime = time.start;
  const targetEndTime = time.end;

  // Check if the first item already meets the time condition
  if (
    timeSlots[0].start_time >= targetStartTime &&
    timeSlots[0].start_time <= targetEndTime
  ) {
    return timeSlots; // Return as-is if the first item is already within range
  }

  // Find the first time slot that meets the condition
  const index = timeSlots.findIndex(
    (slot) =>
      slot.start_time >= targetStartTime && slot.start_time <= targetEndTime
  );

  if (index !== -1) {
    // Clone the array to avoid mutation
    const updatedSlots = [...timeSlots];
    const [matchedSlot] = updatedSlots.splice(index, 1);

    // Move the matched slot to the beginning
    updatedSlots.unshift(matchedSlot);
    return updatedSlots;
  }

  return timeSlots; // If no match found, return the original array
};
