export const subjects = [
  { id: "0", label: "Accounting", value: " Accounts" },
  { id: "1", label: "Additional Mathematics", value: "Add Math" },
  { id: "2", label: "Biology", value: "Biology" },
  { id: "3", label: "Business Studies", value: "Business Studies" },
  { id: "4", label: "Chemistry", value: "Chemistry" },
  { id: "5", label: "Computer Science", value: "Computer Science" },
  { id: "6", label: "Economics", value: "Economics" },
  {
    id: "7",
    label: "English - First Language",
    value: "English",
  },
  {
    id: "8",
    label: "English as a Second Language",
    value: "English",
  },
  { id: "9", label: "English Language", value: "English" },
  { id: "10", label: "English Literature", value: "English" },
  {
    id: "11",
    label: "Environmental Management",
    value: "Environmental Management",
  },
  { id: "12", label: "First Language Urdu", value: "Urdu" },
  { id: "13", label: "Food & Nutrition", value: "Food & Nutrition" },
  { id: "14", label: "Further Mathematics", value: "Math" },
  { id: "15", label: "Global Perspective", value: "Global Perspective" },
  { id: "16", label: "Islamiyat", value: "Islamiyat" },
  {
    id: "17",
    label: "Mathematics Syllabus D",
    value: "Math",
  },
  { id: "18", label: "Pakistan Studies", value: "Pakistan Studies" },
  { id: "19", label: "Physics", value: "Physics" },
  { id: "20", label: "Psychology", value: "Psychology" },
  { id: "21", label: "Second Language Urdu", value: "Urdu" },
  { id: "22", label: "Sociology", value: "Sociology" },
  { id: "23", label: "Business", value: "Business Studies" },
  {
    id: "24",
    label: "Combined Science: Synergy",
    value: "Chemistry",
  },
  {
    id: "25",
    label: "Combined Science: Trilogy",
    value: "Chemistry",
  },
  {
    id: "26",
    label: "Computer Science and IT",
    value: "Computer Studies",
  },
  { id: "27", label: "Environmental Science", value: "Science" },
  { id: "28", label: "Geography", value: "Geography" },
  { id: "29", label: "History", value: "History" },
  { id: "30", label: "ICT", value: "Computer Studies" },
  { id: "31", label: "Mathematics", value: "Math" },
  { id: "32", label: "Science", value: "Science" },
  { id: "33", label: "Statistics", value: "Statistics" },
  { id: "34", label: "Urdu", value: "Urdu" },
  { id: "35", label: "English", value: "English" },
  { id: "36", label: "Maths and Statistics", value: "Math" },
  { id: "37", label: "Business management", value: "Business management" },
  { id: "38", label: "English A", value: "English" },
  { id: "39", label: "English B", value: "English" },
  { id: "40", label: "Language B", value: "Urdu" },
];

export const cambridge = [
  {
    id: "0",
    label: "Accounting",
    value: " Accounts",
  },
  {
    id: "1",
    label: "Additional Mathematics",
    value: "Add Math",
  },
  {
    id: "2",
    label: "Biology",
    value: "Biology",
  },
  {
    id: "3",
    label: "Business Studies",
    value: "Business Studies",
  },
  {
    id: "4",
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    id: "5",
    label: "Computer Science",
    value: "Computer Science",
  },
  {
    id: "6",
    label: "Economics",
    value: "Economics",
  },
  {
    id: "7",
    label: "English - First Language",
    value: "English",
  },
  {
    id: "8",
    label: "English as a Second Language",
    value: "English",
  },
  {
    id: "9",
    label: "English Language",
    value: "English",
  },
  {
    id: "10",
    label: "English Literature",
    value: "English",
  },
  {
    id: "11",
    label: "Environmental Management",
    value: "Environmental Management",
  },
  {
    id: "12",
    label: "First Language Urdu",
    value: "Urdu",
  },
  {
    id: "13",
    label: "Food & Nutrition",
    value: "Food & Nutrition",
  },
  {
    id: "14",
    label: "Further Mathematics",
    value: "Math",
  },
  {
    id: "15",
    label: "Global Perspective",
    value: "Global Perspective",
  },
  {
    id: "16",
    label: "Islamiyat",
    value: "Islamiyat",
  },
  {
    id: "17",
    label: "Mathematics Syllabus D",
    value: "Math",
  },
  {
    id: "18",
    label: "Pakistan Studies",
    value: "Pakistan Studies",
  },
  {
    id: "19",
    label: "Physics",
    value: "Physics",
  },
  {
    id: "20",
    label: "Psychology",
    value: "Psychology",
  },
  {
    id: "21",
    label: "Second Language Urdu",
    value: "Urdu",
  },
  {
    id: "22",
    label: "Sociology",
    value: "Sociology",
  },
];

export const aqa = [
  {
    id: "0",
    label: "Accounting",
    value: " Accounts",
  },
  {
    id: "1",
    label: "Biology",
    value: "Biology",
  },
  {
    id: "2",
    label: "Business",
    value: "Business Studies",
  },
  {
    id: "3",
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    id: "4",
    label: "Combined Science: Synergy",
    value: "Chemistry",
  },
  {
    id: "5",
    label: "Combined Science: Trilogy",
    value: "Chemistry",
  },
  {
    id: "6",
    label: "Computer Science and IT",
    value: "Computer Studies",
  },
  {
    id: "7",
    label: "Economics",
    value: "Economics",
  },
  {
    id: "8",
    label: "Environmental Science",
    value: "Science",
  },
  {
    id: "9",
    label: "Geography",
    value: "Geography",
  },
  {
    id: "10",
    label: "History",
    value: "History",
  },
  {
    id: "11",
    label: "ICT",
    value: "Computer Studies",
  },
  {
    id: "12",
    label: "Mathematics",
    value: "Math",
  },
  {
    id: "13",
    label: "Physics",
    value: "Physics",
  },
  {
    id: "14",
    label: "Psychology",
    value: "Psychology",
  },
  {
    id: "15",
    label: "Science",
    value: "Science",
  },
  {
    id: "16",
    label: "Sociology",
    value: "Sociology",
  },
  {
    id: "17",
    label: "Statistics",
    value: "Statistics",
  },
  {
    id: "18",
    label: "Urdu",
    value: "Urdu",
  },
];

export const edExcel = [
  {
    id: "0",
    label: "Accounting",
    value: " Accounts",
  },
  {
    id: "1",
    label: "Biology",
    value: "Biology",
  },
  {
    id: "2",
    label: "Business",
    value: "Business Studies",
  },
  {
    id: "3",
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    id: "4",
    label: "Computer Science",
    value: "Computer Science",
  },
  {
    id: "5",
    label: "Economics",
    value: "Economics",
  },
  {
    id: "6",
    label: "English",
    value: "English",
  },
  {
    id: "7",
    label: "English as a second language",
    value: "English",
  },
  {
    id: "8",
    label: "English Language",
    value: "English",
  },
  {
    id: "9",
    label: "English Literature",
    value: "English",
  },
  {
    id: "10",
    label: "Geography",
    value: "Geography",
  },
  {
    id: "11",
    label: "History",
    value: "History",
  },
  {
    id: "12",
    label: "Maths and Statistics",
    value: "Math",
  },
  {
    id: "13",
    label: "Pakistan Studies",
    value: "Pakistan Studies",
  },
  {
    id: "14",
    label: "Physics",
    value: "Physics",
  },
  {
    id: "15",
    label: "Psychology",
    value: "Psychology",
  },
  {
    id: "16",
    label: "Urdu",
    value: "Urdu",
  },
];

export const ib = [
  {
    id: "0",
    label: "Biology",
    value: "Biology",
  },
  {
    id: "1",
    label: "Business management",
    value: "Business management",
  },
  {
    id: "2",
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    id: "3",
    label: "Computer Science",
    value: "Computer Science",
  },
  {
    id: "4",
    label: "Economics",
    value: "Economics",
  },
  {
    id: "5",
    label: "English A",
    value: "English",
  },
  {
    id: "6",
    label: "English B",
    value: "English",
  },
  {
    id: "7",
    label: "Geography",
    value: "Geography",
  },
  {
    id: "8",
    label: "History",
    value: "History",
  },
  {
    id: "9",
    label: "Language B",
    value: "Urdu",
  },
  {
    id: "10",
    label: "Mathematics",
    value: "Math",
  },
  {
    id: "11",
    label: "Physics",
    value: "Physics",
  },
  {
    id: "12",
    label: "Psychology",
    value: "Psychology",
  },
  {
    id: "13",
    label: "Urdu",
    value: "Urdu",
  },
];

export const grades = [
  {
    id: "0",
    label: "8 grade",
    value: "Grade 8",
  },
  {
    id: "1",
    label: "9 grade",
    value: "Grade 9",
  },
  {
    id: "2",
    label: "10 grade",
    value: "Grade 10",
  },
  {
    id: "3",
    label: "11 grade",
    value: "Grade 11",
  },
  {
    id: "4",
    label: "12 grade",
    value: "Grade 12",
  },
];

export const ocr = [
  {
    id: "0",
    label: "Business",
    value: "Business Studies",
  },
  {
    id: "1",
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    id: "2",
    label: "Computer Science",
    value: "Computer Science",
  },
  {
    id: "3",
    label: "Economics",
    value: "Economics",
  },
  {
    id: "4",
    label: "English",
    value: "English",
  },
  {
    id: "5",
    label: "Physics",
    value: "Physics",
  },
  {
    id: "6",
    label: "Maths",
    value: "Maths",
  },
];

export const vce = [
  {
    id: "0",
    label: "Maths",
    value: "Maths",
  },
  {
    id: "1",
    label: "Biology",
    value: "Biology",
  },
  {
    id: "2",
    label: "Chemistry",
    value: "Chemistry",
  },
  {
    id: "3",
    label: "Physics",
    value: "Physics",
  },
];

export const boards = [
  {
    id: "0",
    label: "Cambridge",
    value: "Cambridge",
  },
  {
    id: "1",
    label: "Oxford AQA",
    value: "Oxford AQA",
  },
  {
    id: "2",
    label: "Pearson Edexcel",
    value: "Pearson Edexcel",
  },
  {
    id: "3",
    label: "IB",
    value: "IB",
  },
  {
    id: "4",
    label: "OCR",
    value: "OCR",
  },
  {
    id: "5",
    label: "VCE",
    value: "VCE",
  },
];
