import React from "react";
import { Col, Row, Space } from "antd";
import { avatarList } from "src/modules/parent/utils";
import mathImage from "src/modules/parent/assets/svgs/maths.svg";
import { IMAGE_BASE_URL } from "src/modules/parent/config/constant";
import noImage from "../../../../assets/img/anonymous.jpeg";
import moment from "moment-timezone";
import FlatButton from "src/modules/parent/components/flat-button";
import { useNavigate } from "react-router-dom";
import TiktokPixel from "tiktok-pixel";
import { tiktok_pixel_id } from "src/modules/parent/config/constant";

import styles from "../../styles.module.css";

TiktokPixel.init(tiktok_pixel_id);

const Card = ({
  invoice_date,
  payment_status,
  id,
  payments,
  student,
  handlePay,
  payment_link,
  link,
  payment_method,
}) => {
  const navigate = useNavigate();
  const payment = payments && payments[0];
  const child = student;
  const linkIsExist = payment_method === "stripe" ? payment_link : link;

  const gotoInvoiceDetails = (e) => {
    if (payment_status === "unpaid") {
      handlePay({
        link: linkIsExist,
        view: `/invoices/${id}`,
        enrollType: payment?.enroll?.student_type,
      });
    } else {
      navigate(`/invoices/${id}`);
    }

    e.stopPropagation();
  };

  return (
    <div onClick={gotoInvoiceDetails} className={styles.cardContainer}>
      <Row
        align={"middle"}
        justify={"space-between"}
        className={styles.cardDateRow}
      >
        <div>{moment(invoice_date).format("DD/MM/YYYY")}</div>
        {payment_status === "paid" ? (
          <div id={styles.paid}>{"Paid"}</div>
        ) : (
          <Row gutter={[0, 10]} align={"middle"}>
            <Space size={"middle"}>
              <div id={styles.unPaid}>{"Unpaid"}</div>
              <FlatButton
                title={"Pay Now"}
                loading={false}
                type={"Quaternary"}
                onClick={gotoInvoiceDetails}
                className={["mb-2", styles.button].join(" ")}
              />
            </Space>
          </Row>
        )}
      </Row>

      <Col className="mt-10" span={24}>
        <Row align={"middle"} className={styles.cardBody}>
          <div className={styles.packageImage}>
            <img
              src={mathImage}
              style={{ width: "100%", height: "100%" }}
              alt={"PackageImage"}
            />
          </div>

          <div className={styles.cardTitle}>
            <div className={styles.truncate}>
              {payment?.enroll?.package?.title || ""}
            </div>
            <p>{`INV ${id}`}</p>
          </div>

          <div className={styles.avatar}>
            <img
              src={
                child?.avatar
                  ? avatarList[child?.avatar]?.image
                  : child?.image
                  ? `${IMAGE_BASE_URL}${child?.image}`
                  : noImage
              }
              style={{ width: "100%", height: "100%" }}
              alt={"ChildImage"}
            />
          </div>
        </Row>
      </Col>
    </div>
  );
};

const InvoiceCard = ({
  date,
  ItemIndex,
  invoiceData,
  handlePay,
  handleDropOut,
  xxl,
  xl,
  lg,
}) => {
  return (
    <div className={"mt-20 pr-20 pl-20"}>
      <Row className="mb-20">{xxl ? <h3>{date}</h3> : <h2>{date}</h2>}</Row>

      <Row gutter={[20, 20]}>
        {invoiceData.map((item, index) => (
          <Col
            key={`${ItemIndex}-${index}`}
            xxl={xxl || 8}
            xl={xl || 12}
            lg={lg || 12}
            md={24}
            sm={24}
            xs={24}
          >
            <Card
              {...item}
              handleDropOut={handleDropOut}
              handlePay={handlePay}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default InvoiceCard;
