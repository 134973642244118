import math from "./assets/subjectIlustration/math.svg";
import coding from "./assets/subjectIlustration/coding.svg";
import creativewriting from "./assets/subjectIlustration/creativewriting.svg";
import english from "./assets/subjectIlustration/english.svg";
import french from "./assets/subjectIlustration/french.svg";
import phonics from "./assets/subjectIlustration/phonics.svg";
import urdu from "./assets/subjectIlustration/urdu.svg";
import chemistry from "./assets/subjectIlustration/chemistry.svg";
import physics from "./assets/subjectIlustration/physics.svg";
import business from "./assets/subjectIlustration/business.svg";
import biology from "./assets/subjectIlustration/biology.svg";
import graphicDesigning from "./assets/subjectIlustration/graphic-designing.svg";
import engLit from "./assets/subjectIlustration/eng-lit.svg";
import economics from "./assets/subjectIlustration/economics.svg";
import arabic from "./assets/subjectIlustration/arabic.svg";
import quran from "./assets/subjectIlustration/quran.svg";
import accounts from "./assets/subjectIlustration/accounts.svg";
import history from "./assets/subjectIlustration/history.svg";
import socialStudies from "./assets/subjectIlustration/socialStudies.svg";
import wajiha_ahmed from "src/modules/parent/assets/img/ignite/teachers/wajiha_ahmed.png";
import nida_yadir from "src/modules/parent/assets/img/ignite/teachers/nida_yasir.jpeg";
import ambreen_salman from "src/modules/parent/assets/img/ignite/teachers/ambreen_salman.png";
import eman_tariq from "src/modules/parent/assets/img/ignite/teachers/eman_tariq.jpeg";
// import sadaf_batool from "src/modules/parent/assets/img/ignite/teachers/sadaf_batool.jpg";
import jawaria_aamir from "src/modules/parent/assets/img/ignite/teachers/jawaria_aamir.jpg";
import khushbakht_awan from "src/modules/parent/assets/img/ignite/teachers/khushbakht_awan.jpg";
import maha_shahab from "src/modules/parent/assets/img/ignite/teachers/maha_shahab.png";
import sameera_tariq from "src/modules/parent/assets/img/ignite/teachers/sameera_tariq.jpg";
import aymen_ashraf from "src/modules/parent/assets/img/ignite/teachers/aymen_ashraf.jpg";
import maheen from "src/modules/parent/assets/img/ignite/teachers/maheen.png";
import lina from "src/modules/parent/assets/img/ignite/teachers/lina.png";
import madiha_ahmed from "src/modules/parent/assets/img/ignite/teachers/madiha_ahmed.png";
import mahvish_sohail from "src/modules/parent/assets/img/ignite/teachers/mahvish_sohail.jpeg";
import laiba_marium from "src/modules/parent/assets/img/ignite/teachers/laiba_marium.jpg";

//banner
import bannerMobile1 from "../../pages/olevels/assets/banner/mobileBanner1.svg";

//no auth banner
import bannerNoAuthMobile1 from "src/modules/parent/pages/home/assets/banner/CardMobile1.svg";
// import bannerNoAuthMobile3 from "src/modules/parent/pages/home/assets/banner/CardMobile3.svg";
import bannerNoAuthWeb1 from "src/modules/parent/pages/home/assets/banner/CardWeb1-min.webp";
import bannerNoAuthWeb2 from "src/modules/parent/pages/home/assets/banner/CardWeb2.webp";
import bannerNoAuthWeb3 from "src/modules/parent/pages/home/assets/banner/CardWeb3.webp";

//no auth one 2 one banner
import noAuthOne2OneWeb1 from "src/modules/parent/pages/home/assets/banner2/CardWebBanner1.png";
import noAuthOne2OneWeb2 from "src/modules/parent/pages/home/assets/banner2/CardWebBanner2.png";
import noAuthOne2OneWeb3 from "src/modules/parent/pages/home/assets/banner2/CardWebBanner3.png";
import noAuthOne2OneMobile1 from "src/modules/parent/pages/home/assets/banner2/CardMobile1.svg";
import noAuthOne2OneMobile2 from "src/modules/parent/pages/home/assets/banner2/CardMobile2.svg";
import noAuthOne2OneMobile3 from "src/modules/parent/pages/home/assets/banner2/CardMobile3.svg";

export const kgToGrade7 = [
  {
    id: 1,
    label: "Nursery",
    value: "Nursery",
  },
  {
    id: 2,
    label: "KG",
    value: "KG",
  },
  {
    id: 3,
    label: "Grade 1",
    value: "Grade 1",
  },
  {
    id: 4,
    label: "Grade 2",
    value: "Grade 2",
  },
  {
    id: 5,
    label: "Grade 3",
    value: "Grade 3",
  },
  {
    id: 6,
    label: "Grade 4",
    value: "Grade 4",
  },
  {
    id: 7,
    label: "Grade 5",
    value: "Grade 5",
  },
  {
    id: 8,
    label: "Grade 6",
    value: "Grade 6",
  },
  {
    id: 9,
    label: "Grade 7",
    value: "Grade 7",
  },
];

export const gradesData = [
  {
    id: 1,
    label: "Nursery",
    value: "Nursery",
  },
  {
    id: 2,
    label: "KG",
    value: "KG",
  },
  {
    id: 3,
    label: "Grade 1",
    value: "Grade 1",
  },
  {
    id: 4,
    label: "Grade 2",
    value: "Grade 2",
  },
  {
    id: 5,
    label: "Grade 3",
    value: "Grade 3",
  },
  {
    id: 6,
    label: "Grade 4",
    value: "Grade 4",
  },
  {
    id: 7,
    label: "Grade 5",
    value: "Grade 5",
  },
  {
    id: 8,
    label: "Grade 6",
    value: "Grade 6",
  },
  {
    id: 9,
    label: "Grade 7",
    value: "Grade 7",
  },
  {
    id: 10,
    label: "Grade 8",
    value: "Grade 8",
  },
  {
    id: 11,
    label: "Grade 9",
    value: "Grade 9",
  },
  {
    id: 12,
    label: "Grade 10",
    value: "Grade 10",
  },
  {
    id: 13,
    label: "Grade 11",
    value: "Grade 11",
  },
  {
    id: 14,
    label: "Grade 12",
    value: "Grade 12",
  },
];

export const triviaGrades = [
  {
    id: 5,
    label: "Grade 3",
    value: "Grade 3",
  },
  {
    id: 6,
    label: "Grade 4",
    value: "Grade 4",
  },
  {
    id: 7,
    label: "Grade 5",
    value: "Grade 5",
  },
  {
    id: 8,
    label: "Grade 6",
    value: "Grade 6",
  },
  {
    id: 9,
    label: "Grade 7",
    value: "Grade 7",
  },
  {
    id: 10,
    label: "Grade 8",
    value: "Grade 8",
  },
];

export const olevelGrades = [
  {
    id: 0,
    label: "Grade 8",
    value: "Grade 8",
  },
  {
    id: 1,
    label: "Grade 9",
    value: "Grade 9",
  },
  {
    id: 2,
    label: "Grade 10",
    value: "Grade 10",
  },
  {
    id: 3,
    label: "Grade 11",
    value: "Grade 11",
  },
  {
    id: 4,
    label: "Grade 12",
    value: "Grade 12",
  },
];

export const cat4Grades = {
  title: "Grade",
  name: "grade",
  data: [
    {
      id: 0,
      label: "Grade 2",
      value: "Grade 2",
      grade: 2,
    },
    {
      id: 1,
      label: "Grade 3",
      value: "Grade 3",
      grade: 3,
    },
    {
      id: 2,
      label: "Grade 4",
      value: "Grade 4",
      grade: 4,
    },
    {
      id: 3,
      label: "Grade 5",
      value: "Grade 5",
      grade: 5,
    },
    {
      id: 4,
      label: "Grade 6",
      value: "Grade 6",
      grade: 6,
    },
    {
      id: 5,
      label: "Grade 7",
      value: "Grade 7",
      grade: 7,
    },
    {
      id: 6,
      label: "Grade 8",
      value: "Grade 8",
      grade: 8,
    },
    {
      id: 7,
      label: "Grade 11",
      value: "Grade 11",
      grade: 11,
    },
    {
      id: 8,
      label: "Grade 12",
      value: "Grade 12",
      grade: 12,
    },
  ],
};

export const getShortCourseGrades = gradesData.slice(0, 10);

export const feedbackText = ["Bad", "Bad", "Average", "Good", "Excellent"];

export const genderData = [
  {
    id: 1,
    label: "Male",
    value: "male",
  },
  {
    id: 2,
    label: "Female",
    value: "female",
  },
];

export const locationList = [
  { id: 1, label: "Online", value: "online" },
  { id: 1, label: "Onsite (Karachi only)", value: "onsite" },
];

export const priceList = [
  { id: 1, label: "Top Recommended", value: "" },
  { id: 2, label: "Low to High", value: "low_to_high" },
];

export const daysList = [
  {
    id: 2,
    name: "2",
    label: "2 Days (Per Week)",
    value: 2,
  },
  {
    id: 3,
    name: "3",
    label: "3 Days (Per Week)",
    value: 3,
  },
  {
    id: 4,
    name: "4",
    label: "4 Days (Per Week)",
    value: 4,
  },
  {
    id: 5,
    name: "5",
    label: "5 Days (Per Week)",
    value: 5,
  },
];

export const boardData = [
  {
    id: 1,
    label: "Cambridge",
    value: "Cambridge",
  },
  {
    id: 2,
    label: "Matric",
    value: "Matric",
  },
  {
    id: 3,
    label: "IB",
    value: "IB",
  },
  {
    id: 4,
    label: "Other",
    value: "Other",
  },
];

export const timePreferenceOptions = [
  {
    id: 1,
    name: "Morning",
    label: "Morning - (5 AM to 11 AM)",
    value: "morning",
  },
  {
    id: 2,
    name: "Afternoon",
    label: "Afternoon - (12 PM to 4 PM)",
    value: "afternoon",
  },
  {
    id: 3,
    name: "Evening",
    label: "Evening - (5 PM to 8 PM)",
    value: "evening",
  },
  {
    id: 4,
    name: "Night",
    label: "Night - (8 PM to 5 AM)",
    value: "night",
  },
];

export const textLimitForReadMore = 120;

export const faqs1 = [
  {
    id: 1,
    question: "What is Dot & Line and how does it work?",
    answer:
      "Dot and Line offers online tutoring with vetted and trained teachers from over 12 countries. You can search for a tutor based on your timezone, grade level, subject and board and chat with the teacher in real time, or book a free demo class! Our support does not end there. We have monthly progress reports, certificates, learning resources and more available to you!",
  },
  {
    id: 2,
    question: "What grade levels and subjects do you cater to?",
    answer:
      "From Kg to Grade 12, our virtual classroom doors are wide open. Our core focus lies with a range of academic and creative courses including our best sellers creative writing, English grammar, maths, coding, Urdu and exam preparation including CAT4, Naplan, IGCSE and GCSE, Pearson, IB and more. Classes take place in groups or one on one.",
  },
  {
    id: 3,
    question: "How do you ensure quality and vetting of your classes?",
    answer:
      "For starters, our tutors go through a strong screening and recruitment process. Only 4% make the cut! Selected tutors then go through a comprehensive teacher training program before we match them to students. Our training includes AI, online teaching tools, pedagogy and our values. They are continuously evaluated and offered a range of further training to ensure that your classes remain top notch!",
  },
  {
    id: 4,
    question: "What classes are best selling?",
    answer:
      "English, Math, Urdu and Coding always get a ton of love from our students! For Cambridge students, we excel in sciences and math! ",
  },
  {
    id: 5,
    question: "Do you have any institute?",
    answer:
      "Nope. We do not have an institute. We believe online learning is the future of education! Our tutors are based all around the world and you can access world class learning with a click of a button. We do offer offline classes. However these are very limited.",
  },
  {
    id: 6,
    question:
      "Is Dot & Line a suitable platform for a learner with special needs?",
    answer:
      "We are an inclusive community and  would love to cater to learners with special needs but it depends on the expertise and qualifications of our tutors. We do have some that are well equipped to handle students with special needs, so do ask them beforehand. Our academic counselors are here to assist.",
  },
  {
    id: 7,
    question: "Are your worksheets available for purchase?",
    answer:
      "Now, parents and students enrolled with Dot and Line, can access world class worksheets in mathematics, English and more through our web app! You can search for worksheets based on topic and grade level and practice at home! These worksheets are built by an inhouse team of educational experts, PhDs, illustrators and editors.",
  },
  {
    id: 8,
    question: "Can I book more than one demo at a time?",
    answer:
      "Book away! You have an option to book up to three demos on our app for various subjects to make an informed choice before you register.",
  },
];

export const faqs2 = [
  {
    id: 9,
    question:
      "How much do you charge for your programs and what payment methods do you accept?",
    answer:
      "We charge a minimum fee of PKR 850/hour or USD 6/hour- but this could vary based on your choice of subjects, number of hours per week, batch vs one on one classes and type of tutor. We are open to various payment options including IBFT, cash deposit via PayPro only for parents based in Pakistan and debit or credit card for parents based outside of Pakistan. We accept payment in 135 currencies through debit or credit card. Our payment gateway is completely secure",
  },
  {
    id: 10,
    question: "Do you provide any kinds of subscriptions?",
    answer:
      "We have an awesome 3-month and a 6-month subscription where you are eligible for big discounts! Ask your academic counselor for support!",
  },
  {
    id: 11,
    question: "Do you give sibling discounts?",
    answer:
      "Since our classes focus on small groups and customized attention and interaction, we encourage individual enrolments but some of our tutors do provide discounts to siblings. Check with your selected tutor and they will guide you.",
  },
];

export const primaryGrades = [
  "nursery",
  "kg",
  "grade 1",
  "grade 2",
  "grade 3",
  "grade 4",
  "grade 5",
];

export const secondaryGrades = [
  "grade 6",
  "grade 7",
  "grade 8",
  "grade 9",
  "grade 10",
];

export const intermediateGrades = ["grade 11", "grade 12"];

export const dropdownStyle = {
  border: "1px solid #B3B3B3",
  backgroundColor: "#fff",
  color: "#B3B3B3",
  borderRadius: "10px",
};

export const curriculum = [
  {
    name: "Math",
    curriculum:
      "Dot and Line Curriculum is designed by mathematics experts, PhDs and educationalists from around the globe. The lessons are based on the idea of spiral progression and scaffolding on prior concepts. Math K-6 provides practice worksheets on each concept as per the school curriculum, quizzes, critical thinking puzzles, tear-able activities and mental math skill building. Math K-6 is built upon common core, Cambridge and IB standards; which include basic operations, measurements, geometry, statistics, sequences and algebra.",
    grades: [
      {
        id: "0",
        title: "Grade 1",
        categories: [
          {
            id: "0",
            category: "Basic Operation",
            subCategories: [
              "Place Value and number representatives",
              "Comparing and ordering numbers",
              "Basic arithmetic",
              "Mental Maths",
              "Problem solving",
            ],
          },
          {
            id: "1",
            category: "Measurement",
            subCategories: [
              "Length",
              "Temperature",
              "Distance",
              "Capacity",
              "Mass",
            ],
          },
          {
            id: "2",
            category: "Geometry",
            subCategories: ["Geometric shapes", "Symmetry"],
          },
          {
            id: "3",
            category: "Sequences and Algebra",
            subCategories: ["Counting", "Sequences", "Patterns"],
          },
        ],
        outComes: [
          "Identify and write numbers up to 100, understand the concept of place value, and compare and order numbers up to 100 using various strategies such as counting by 1s, 5s, 10s, and 25s, counting backwards, skip counting, and using base ten blocks. They will also be able to add and subtract two-digit numbers using strategies such as regrouping, mental math, and counting.",
          "Estimate and measure lengths of objects using non-standard units such as their hands, fingers, or blocks, and understand the concept of longer, shorter, and equal lengths.",
          "Students will be able to identify the number of sides and vertices of various shapes, recognize and name equilateral shapes, differentiate between quadrilaterals and other polygons, identify symmetry in shapes, and recognize symmetric shapes",
          "Count to 100 by ones, fives, and tens and identify patterns in simple sequences",
        ],
      },
      {
        id: "1",
        title: "Grade 2",
        categories: [
          {
            id: "0",
            category: "Basic Operation",
            subCategories: [
              "Place Value",
              "Addition",
              "Subtraction",
              "Multiplication",
              "Rounding",
              "Estimation",
              "Fractions",
            ],
          },
          {
            id: "1",
            category: "Measurement",
            subCategories: [
              "Lengths and Units of Measurement",
              "Measuring Objects",
              "Estimating and Measuring in Meters and Kilometres",
              "Perimeter",
              "Mass and Weight",
              "Temperature",
              "Time",
              "Area",
            ],
          },
          {
            id: "2",
            category: "Geometry",
            subCategories: [
              "Sides and Vertices",
              "Introduction to Angles",
              "Equilateral Shapes",
              "Introduction to Symmetry",
              "Symmetric Shapes",
              "3D Shapes",
            ],
          },
          {
            id: "3",
            category: "Statistics",
            subCategories: [
              "Bar graphs",
              "Patterns",
              "Patterns involving time",
            ],
          },
        ],
        outComes: [
          "Understand the concept of place value, expand numbers, compare and order numbers, count by 2s, 3s, 4s, 5s, and 10s, add and subtract 2- and 3-digit numbers using regrouping, multiply using repeated addition and skip counting, and apply basic operations in real-life situations",
          "Develop their skills in numeracy and estimation. These lessons cover a range of mathematical concepts such as rounding off, skip counting, estimation, division, multiplication, fractions, and comparison of fractions",
          "Develop their ability to work with numbers and to estimate and make calculations with greater accuracy. Additionally, these lessons can help students to improve their problem-solving and critical thinking skills, as they learn how to approach mathematical problems and apply different strategies to solve them measure and estimate lengths, masses, and time intervals using appropriate units, compare and order different units of measurements, calculate the perimeter and area of 2D shapes using centimeters, and solve word problems related to these concepts",
          "Recognize and classify geometric shapes and understand their properties, such as sides, vertices, angles, symmetry, and real-world applications",
          "Identify, describe, and create patterns involving time, numbers, and shapes, using various strategies such as number lines, times tables, and repeating patterns. They will also be able to apply these skills to real-life situations, such as using a calendar or analyzing data represented in a bar graph",
        ],
      },
      {
        id: "2",
        title: "Grade 3",
        categories: [
          {
            id: "0",
            category: "Basic Operation",
            subCategories: [
              "Arithmetic",
              "Place Value",
              "Inequality",
              "Addition",
              "Subtraction",
              "Multiplication",
              "Rounding And Estimation",
              "Division",
              "Fractions",
            ],
          },
          {
            id: "1",
            category: "Measurement",
            subCategories: ["Length", "Perimeter", "Time", "Area"],
          },
          {
            id: "2",
            category: "Geometry",
            subCategories: [
              "Basic geometric concepts",
              "Lines and shapes",
              "Sorting and classifying shapes",
              "Puzzles",
              "Three-dimensional shapes",
            ],
          },
          {
            id: "3",
            category: "Sequence and Algebra",
            subCategories: [
              "Introduction to T-Tables",
              "Patterns in Two-, Five-, and Eight-Times Tables",
              "Extending and Predicting Positions in Times Tables",
              "Balancing Equations",
              "Adding and Subtracting Equations",
            ],
          },
        ],
        outComes: [
          "Develop a deep understanding of sharing, division, and fractions, including concepts such as equal parts, parts and wholes, ordering and comparing,. Gain proficiency in mental and long division, systematic search, and knowing when to multiply or divide",
          "Develop a strong foundation in basic math skills, including place value, inequalities, comparing and regrouping numbers, addition, subtraction, multiplication, rounding, and estimation",
          "Accurately measure length and time using appropriate units of measurement (centimeters, millimeters, meters, kilometers, hours, minutes, and seconds) and apply this knowledge to solve real-world problems. They will also develop skills in estimating, comparing, and ordering units of measurement, as well as calculating perimeter and elapsed time",
          "Understand and apply mathematical concepts related to area, volume, and temperature, and use these concepts to solve real-world problems. They will develop skills in measuring, estimating, and converting units, as well as using mathematical reasoning and critical thinking to analyze and solve problems",
          "Identify and describe the properties of 2D and 3D shapes, including edges, vertices, faces, prisms, pyramids, nets, and how to use them to solve problems and puzzles",
          "Analyze patterns in times tables and use these patterns to extend and predict positions in the sequence",
        ],
      },
      {
        id: "3",
        title: "Grade 4",
        categories: [
          {
            id: "0",
            category: "Basic Operation",
            subCategories: [
              "Place Value",
              "Addition",
              "Multiplication",
              "Division",
              "Mental Math",
              "Fractions",
              "Decimals",
            ],
          },
          {
            id: "1",
            category: "Measurement",
            subCategories: [
              "Time",
              "Temperature",
              "Perimeter",
              "Area",
              "Volume and Capacity",
              "Length",
              "Units of measurement",
            ],
          },
          {
            id: "2",
            category: "Geometry",
            subCategories: [
              "Basic geometric concepts",
              "Lines and shapes",
              "Angles",
              "Triangles and polygons",
              "Symmetry",
              "Lines",
            ],
          },
          {
            id: "3",
            category: "Sequence and Algebra",
            subCategories: [
              "Patterns",
              "Timetable (beginner and advance)",
              "Variables and Equations",
            ],
          },
        ],
        outComes: [
          "Fluently add and subtract multi-digit whole numbers using various strategies and methods, including the standard algorithm, regrouping, and mental math",
          "Identify and explain the different properties of 2D shapes, including their number of sides, angles, and vertices",
          "Develop a strong foundation in geometry and prepare themselves for more advanced concepts in later grades",
          "Convert between different units of measurement (e.g. millimetres to centimetres, metres to kilometres, etc.), calculate perimeter and area of polygons and irregular shapes, and determine the volume and capacity of rectangular prisms using appropriate units of measurement",
          "Effectively tell time, including identifying the hour, minute, and second hands on an analog clock, using both digital and analog time notation, and calculating elapsed time",
          "Use a protractor to measure angles, construct angles, identify and classify types of triangles and polygons, use parallel lines to identify angle relationships, identify and create symmetrical shapes, and solve puzzles and problems involving geometry concepts",
          "Identify, extend, create, and describe patterns using various methods such as Time tables and pattern rules, and apply these skills to solve problems involving increasing and decreasing sequences, repeating patterns, and time tables",
          "Identify and describe patterns in tables of values, use rules to extend patterns, and create their own patterns using variables and equations. They will also be introduced to basic concepts in algebra, such as variables and direct variation",
        ],
      },
      {
        id: "4",
        title: "Grade 5",
        categories: [
          {
            id: "0",
            category: "Basic Operation",
            subCategories: [
              "Place Value",
              "Inequality and Relationships",
              "Addition and Subtraction",
              "Multiplication and Division",
              "Factors and Prime Numbers",
              "Mental Math",
              "Long Division",
              "Order of Operations",
              "Fractions",
              "Decimals",
              "Rates and Ratio",
            ],
          },
          {
            id: "1",
            category: "Measurement",
            subCategories: ["Time", "Volume", "Mass", "Area", "Perimeter"],
          },
          {
            id: "2",
            category: "Geometry",
            subCategories: [
              "Shapes",
              "Symmetry",
              "Coordinate systems",
              "Grids and Maps",
              "Maps",
            ],
          },
          {
            id: "3",
            category: "Sequence and Algebra",
            subCategories: [
              "Patterns",
              "Timetable (beginner and advance)",
              "Variables and Equations",
              "Variation",
              "Algebraic puzzles",
            ],
          },
          {
            id: "4",
            category: "Statistics",
            subCategories: [
              "Graphs",
              "Mean",
              "Median",
              "Mode",
              "Range",
              "Frequency",
            ],
          },
        ],
        outComes: [
          "Understanding the value of digits in a number, the position of the digits, and their relationship to each other",
          "Understanding the relationship between numbers and how to compare them using greater than, less than, and equal to symbols",
          "Knowing how to add, subtract, multiply and divide numbers using various methods, including mental math, and being able to apply these skills to solve real-world problems.",
          "Understanding the concepts of factors and multiples",
          "Knowing and applying the rules for the order of operations",
          "Understanding the concept of decimals and being able to convert between fractions and decimals, perform operations with decimals, and apply them to real-world problems",
          "Understanding the concept of fractions, including proper, improper, and mixed fractions, and being able to perform operations with them",
        ],
      },
      {
        id: "5",
        title: "Grade 6",
        categories: [
          {
            id: "0",
            category: "Basic Operation",
            subCategories: [
              "Place Value",
              "Order of Operations",
              "Square and cube roots",
              "Factors and Multiples",
              "Fractions",
              "Decimals",
              "Addition and multiplication",
              "Profit",
              "HCF and LCM",
              "Percentages",
              "Taxes",
              "Zakat and Ushr",
            ],
          },
          {
            id: "1",
            category: "Measurement",
            subCategories: [
              "Area",
              "Volume",
              "Quadrilaterals",
              "Speed",
              "3-D Shapes",
              "Ratio",
              "Time",
              "Volume",
            ],
          },
          {
            id: "2",
            category: "Geometry",
            subCategories: [
              "Lines",
              "Angles",
              "Congruency and Similarity",
              "Reflections",
              "Turns and rotations",
              "Transformations",
            ],
          },
          {
            id: "3",
            category: "Sequence and Algebra",
            subCategories: [
              "Cartesian Plane",
              "Linear graphs",
              "Gradient of a line",
              "Graphing a line",
              "Polynomials",
              "Terms",
              "Expressions",
              "Equations",
              "Sequences",
            ],
          },
          {
            id: "4",
            category: "Statistics",
            subCategories: [
              "Space",
              "Probability",
              "Pictographs",
              "Stem and Leaf Diagram",
              "Measure of Central Tendency",
              "Frequency",
              "Line graphs",
              "Pie charts",
            ],
          },
        ],
        outComes: [
          "Apply mathematical concepts related to decimal operations, including rounding, significant figures, multiplying and dividing decimals by powers of 10, and using various properties of arithmetic such as associativity, commutativity, and distributivity",
          "Understand, represent, and perform operations with fractions and decimals, and compare and convert between them, using various visual models and strategies, and apply these skills in real-world contexts",
          "Identify and apply geometric concepts and properties to analyze and solve problems related to shapes and figures in two-dimensional space",
          "Identify and classify lines and angles, determine congruent and similar figures, perform and describe reflections, rotations, and translations, and use these concepts to solve real-life problems",
          "Understand and apply concepts related to polynomials, simplify expressions, create and solve equations, and analyze sequences using mathematical reasoning and skills related to the Cartesian plane and linear graphs",
          "Use various statistical methods and probability concepts to analyze and interpret data, and make informed decisions based on their findings",
        ],
      },
    ],
  },
  {
    name: "English",
    curriculum:
      "Dot and Line Curriculum is designed to help you improve your grammar, vocabulary, and pronunciation, while building your confidence in using English in a variety of contexts. Whether you're looking to improve your writing in school, travel the world, or simply connect with people from diverse cultures, our courses will provide you with the tools you need to succeed. From engaging lessons and interactive activities to personalized feedback and support, we're here to help you achieve your language goals and unlock your full potential. So, are you ready to take the first step towards mastering the world's most spoken language? Let's get started on this exciting journey together!",
    grades: [
      {
        id: "0",
        title: "Grade 1",
        categories: [
          {
            id: "0",
            category: "Speaking",
            subCategories: [
              "Pronouncing vowels and consonants",
              "Following directions",
              "Asking questions",
            ],
          },
          {
            id: "1",
            category: "Phonemes and Blending",
            subCategories: [
              "Bugs and insects",
              "Animals",
              "Emotions",
              "All about me",
              "Seasons",
              "Colours",
              "Superheroes",
              "Transportation",
              "Space and planets",
              "Numbers",
            ],
          },
          {
            id: "2",
            category: "Writing",
            subCategories: ["Tracing patterns", "Letters (a-z)"],
          },
        ],
        outComes: [
          "Use basic greetings and introductions to initiate and respond to social interactions in English",
          "Read and understand simple sentences and short stories with basic vocabulary and sight words",
          "Write simple sentences using basic vocabulary and proper letter formation",
        ],
      },
      {
        id: "1",
        title: "Grade 2",
        categories: [
          {
            id: "0",
            category: "Speaking",
            subCategories: [
              "Pronouncing sounds and three letter words",
              "Answering questions",
              "Storytelling",
              "Picture Descriptions",
              "Show and Tell",
            ],
          },
          {
            id: "1",
            category:
              "Comprehending basic stories - Famous Personalities covered",
            subCategories: [
              "Muhammad Ali Jinnah",
              "Hellen Keller",
              "Jane Goodall",
              "Faiz Ahmed Faiz",
              "Albert Einstein",
              "Sadequain",
              "Noor Jehan",
              "Abdul Sattar Edhi",
              "Maryam Mirzakhani",
              "Martin Luther King Jr.",
              "Zaha Hadid",
              "Malala Yousafzai",
            ],
          },
          {
            id: "2",
            category: "Writing",
            subCategories: [
              "Vocabulary Building",
              "Sight words",
              "Comprehension",
              "Three letter words",
            ],
          },
        ],
        outComes: [
          "Demonstrate the ability to speak in complete sentences using appropriate vocabulary and grammar",
          "Express ideas and opinions clearly and coherently",
          "Apply knowledge of language structures and conventions to improve speech, such as using past tense verbs or asking questions.",
          "Use appropriate intonation, stress, and rhythm to convey meaning and emotion.",
          "Demonstrate the ability to recognize and read common sight words accurately and fluently.",
          "Decode unfamiliar words using phonics, syllabication, and context clues.",
          "Use basic comprehension skills to identify main idea, sequence events, and make inferences.",
          "Demonstrate an understanding of different text structures.",
          "Apply knowledge of language structures and conventions.",
          "Use sentences to communicate information and knowledge effectively.",
        ],
      },
      {
        id: "2",
        title: "Grade 3",
        categories: [
          {
            id: "0",
            category: "Speaking",
            subCategories: [
              "Role play",
              "Interviewing",
              "Storytelling",
              "Picture Descriptions",
            ],
          },
          {
            id: "1",
            category: "Reading",
            subCategories: ["Comprehension"],
          },
          {
            id: "2",
            category: "Writing",
            subCategories: [
              "Vocabulary Building",
              "Sentences - Nouns, Verbs, Adjectives, and Adverbs",
              "Parts of speech",
              "Punctuation",
              "Dialogues",
              "Synonyms and Antonyms",
              "Prefixes and Suffixes",
              "Types of sentences",
              "Tenses",
            ],
          },
        ],
        outComes: [
          "Use new vocabulary words with confidence",
          "Identify and define common occupations and related vocabulary",
          "Recognize and articulate a range of emotions and related vocabulary",
          "Understand what constitutes a sentence and its basic components",
          "Identify and differentiate between nouns, pronouns, and adjectives in a sentence",
          "Use verbs in sentences and phrases with appropriate subject-verb agreement, including irregular verbs",
        ],
      },
      {
        id: "3",
        title: "Grade 4",
        categories: [
          {
            id: "0",
            category: "Speaking",
            subCategories: [
              "Role play",
              "Interviewing",
              "Storytelling",
              "Picture Descriptions",
              "News report",
              "Debate",
              "Interviews",
            ],
          },
          {
            id: "1",
            category: "Reading",
            subCategories: ["Novels", "Letters", "Accounts", "Biographies"],
          },
          {
            id: "2",
            category: "Writing",
            subCategories: [
              "Letters",
              "Descriptive essays",
              "Narrative essays",
              "Comprehension",
            ],
          },
        ],
        outComes: [
          "Read and comprehend grade-level text with accuracy, fluency, and understanding, making connections between the text and their own experiences and prior knowledges",
          "Express themselves effectively and confidently through oral presentations, role plays, interviews, debates, and other speaking activities, using appropriate vocabulary, tone, and body language",
          "Write cohesively and clearly, expressing their ideas, thoughts, and opinions in various genres such as letters, descriptive essays, and narrative essays, using appropriate grammar, punctuation, and spelling",
          "Expanded their vocabulary, including academic and content-specific vocabulary, and will be able to use context clues to determine the meaning of unfamiliar words",
          "Analyze and interpret text, identify author's purpose, point of view, and bias, and make inferences and draw conclusions based on evidence from the text",
          "Work collaboratively with their peers, engage in respectful and productive discussions, provide feedback to each other, and contribute to group projects and presentations.",
        ],
      },
      {
        id: "4",
        title: "Grade 5",
        categories: [
          {
            id: "0",
            category: "Speaking",
            subCategories: [
              "Role play",
              "Interviewing",
              "Storytelling",
              "Picture Descriptions",
              "News report",
              "Debate",
              "Interviews",
            ],
          },
          {
            id: "1",
            category: "Reading",
            subCategories: ["Novels", "Letters", "Accounts", "Biographies"],
          },
          {
            id: "2",
            category: "Writing",
            subCategories: [
              "Letters",
              "Descriptive essays",
              "Narrative essays",
              "Comprehension",
            ],
          },
        ],
        outComes: [
          "Read and comprehend grade-level text with accuracy, fluency, and understanding, making connections between the text and their own experiences and prior knowledges",
          "Express themselves effectively and confidently through oral presentations, role plays, interviews, debates, and other speaking activities, using appropriate vocabulary, tone, and body language",
          "Write cohesively and clearly, expressing their ideas, thoughts, and opinions in various genres such as letters, descriptive essays, and narrative essays, using appropriate grammar, punctuation, and spelling",
          "Expanded their vocabulary, including academic and content-specific vocabulary, and will be able to use context clues to determine the meaning of unfamiliar words",
          "Analyze and interpret text, identify author's purpose, point of view, and bias, and make inferences and draw conclusions based on evidence from the text",
          "Work collaboratively with their peers, engage in respectful and productive discussions, provide feedback to each other, and contribute to group projects and presentations.",
        ],
      },
      {
        id: "5",
        title: "Grade 6",
        categories: [
          {
            id: "0",
            category: "Speaking",
            subCategories: [
              "Discussion",
              "Presentation",
              "Debates",
              "Role-play",
            ],
          },
          {
            id: "1",
            category: "Reading",
            subCategories: ["Comprehension", "Vocabulary"],
          },
          {
            id: "2",
            category: "Writing",
            subCategories: [
              "Grammar and Punctuation",
              "Narratives",
              "Reports",
              "Persuasive Essays",
              "Summaries",
            ],
          },
        ],
        outComes: [
          "Developing skills to understand and interpret texts, including fiction, non-fiction, and poetry. Students are expected to make inferences, draw conclusions, and analyze characters and themes.",
          "Expanding students' vocabulary through reading and exposure to a variety of texts, and encouraging the use of new words in writing and speaking.",
          "Building on previous grammar knowledge, including parts of speech, tenses, sentence structure, and punctuation. Students learn more advanced grammar concepts, such as active and passive voice, direct and indirect speech, and modal verbs.",
          "Enhancing writing skills through various types of texts, such as narratives, reports, persuasive essays, and summaries. Students focus on organizing ideas, using appropriate vocabulary, and employing correct grammar and punctuation.",
          "Developing spelling strategies and understanding common spelling patterns, homophones, and homonyms.",
          "Encouraging effective communication through group discussions, presentations, and debates. Students develop their ability to listen actively, ask relevant questions, and express their opinions clearly and confidently.",
          "Encouraging students to think critically about the texts they read and the ideas they encounter. This includes evaluating arguments, making connections between texts and real-life experiences, and understanding the impact of context on meaning.",
          "Developing essential study skills, such as note-taking, summarizing, and using reference materials, to support independent learning and research.",
          "Media Literacy: Introducing students to different forms of media, such as newspapers, websites, and advertisements, and teaching them to analyze and evaluate the information presented.",
          "Exploring elements of drama and performance, including role-play, improvisation, and script analysis, to develop students' understanding of character, plot, and theme.",
        ],
      },
    ],
  },
  {
    name: "Creative Writing",
    curriculum:
      "Dot and Line Creative Writing Curriculum is designed to help you explore the art of storytelling, unleash your creativity, and nurture your writing skills. From crafting compelling characters and plotlines to mastering the art of descriptive writing, our courses are designed to help you develop your unique voice and bring your stories to life. Whether you're an aspiring novelist, a budding poet, or simply looking to express yourself through the written word, our engaging lessons will help you take your writing to the next level. Let's embark on this exciting journey together and unlock the power of your imagination!",
    grades: [
      {
        id: "0",
        title: "Level 1",
        categories: [
          {
            id: "0",
            category: "",
            subCategories: [
              "Grammatical error identification",
              "Adjectives",
              "Adverbs",
              "Picture Description",
              "Note to a Friend",
              "Paragraph Writing using visual prompts",
              "Intro to Essay Writing",
              "Use of Senses to Write",
            ],
          },
        ],
        outComes: [
          "Given a set of visual prompts, students will demonstrate their understanding of various aspects of creative writing, such as identifying and correcting grammatical errors, incorporating descriptive adjectives and adverbs to enhance their writing, writing a paragraph that describes a picture, writing a note to a friend that conveys a specific emotion or message, writing an introductory paragraph for an essay, and utilizing the senses to create vivid and engaging writing",
          "Using various creative writing techniques such as flowchart mapping, story mapping, dialogue writing with speech bubbles, and sequencing, students will develop their writing skills by creating descriptive and engaging written pieces, utilizing adverbial phrases to add depth and nuance to their writing, crafting opinion-based essays that convey their personal perspectives, and using journal writing as a tool for self-reflection and personal growth",
          "Through the exploration of narrative writing, back and forth stories, reviews and analysis writing, roleplays, acrostic poems, alternate beginnings, personal narratives, and journal entries, students will develop their creative writing skills by crafting engaging and compelling written pieces, utilizing different literary techniques to convey their ideas, thoughts, and emotions, and experimenting with different writing styles and formats to express their creativity and unique voice",
        ],
      },
      {
        id: "1",
        title: "Level 2",
        categories: [
          {
            id: "0",
            category: "",
            subCategories: [
              "Flowchart Mapping",
              "Story Mapping",
              "Dialogue Writing (Speech Bubbles)",
              "Adverbial Phrases",
              "Opinion-Base Essays",
              "Sequencing in Writing",
              "Journal Writing",
            ],
          },
        ],
        outComes: [
          "Given a set of visual prompts, students will demonstrate their understanding of various aspects of creative writing, such as identifying and correcting grammatical errors, incorporating descriptive adjectives and adverbs to enhance their writing, writing a paragraph that describes a picture, writing a note to a friend that conveys a specific emotion or message, writing an introductory paragraph for an essay, and utilizing the senses to create vivid and engaging writing",
          "Using various creative writing techniques such as flowchart mapping, story mapping, dialogue writing with speech bubbles, and sequencing, students will develop their writing skills by creating descriptive and engaging written pieces, utilizing adverbial phrases to add depth and nuance to their writing, crafting opinion-based essays that convey their personal perspectives, and using journal writing as a tool for self-reflection and personal growth",
          "Through the exploration of narrative writing, back and forth stories, reviews and analysis writing, roleplays, acrostic poems, alternate beginnings, personal narratives, and journal entries, students will develop their creative writing skills by crafting engaging and compelling written pieces, utilizing different literary techniques to convey their ideas, thoughts, and emotions, and experimenting with different writing styles and formats to express their creativity and unique voice",
        ],
      },
      {
        id: "2",
        title: "Level 3",
        categories: [
          {
            id: "0",
            category: "",
            subCategories: [
              "Narrative Writing",
              "Back and Forth Stories",
              "Reviews and Analysis Writing",
              "Roleplays",
              "Acrostic Poem",
              "Alternate Beginnings",
              "Personal Narrative and Journal Entry",
            ],
          },
        ],
        outComes: [
          "Given a set of visual prompts, students will demonstrate their understanding of various aspects of creative writing, such as identifying and correcting grammatical errors, incorporating descriptive adjectives and adverbs to enhance their writing, writing a paragraph that describes a picture, writing a note to a friend that conveys a specific emotion or message, writing an introductory paragraph for an essay, and utilizing the senses to create vivid and engaging writing",
          "Using various creative writing techniques such as flowchart mapping, story mapping, dialogue writing with speech bubbles, and sequencing, students will develop their writing skills by creating descriptive and engaging written pieces, utilizing adverbial phrases to add depth and nuance to their writing, crafting opinion-based essays that convey their personal perspectives, and using journal writing as a tool for self-reflection and personal growth",
          "Through the exploration of narrative writing, back and forth stories, reviews and analysis writing, roleplays, acrostic poems, alternate beginnings, personal narratives, and journal entries, students will develop their creative writing skills by crafting engaging and compelling written pieces, utilizing different literary techniques to convey their ideas, thoughts, and emotions, and experimenting with different writing styles and formats to express their creativity and unique voice",
        ],
      },
    ],
  },
  {
    name: "Coding",
    curriculum:
      "Dot and Line Coding Curriculum is designed to help you master the fundamentals of programming and take your skills to the next level. Whether you're a beginner or an experienced coder, our courses will provide you with the tools you need to build dynamic websites, develop mobile apps, analyse data, and much more. Get ready to unleash your creativity and transform your ideas into reality with our comprehensive coding curriculum.",
    grades: [
      {
        id: "0",
        title: "Level 1",
        categories: [
          {
            id: "0",
            category: "Scratch",
            subCategories: [
              "Introduction to Scratch UI & its attributes",
              "Concept of algorithm",
              "Sequencing",
              "Loops",
              "Parallelism & event handling",
            ],
          },
          {
            id: "1",
            category: "",
            subCategories: [
              "Extension tools",
              "Debugging",
              "Broadcast",
              "User input",
              "Conditions",
              "Story creation",
            ],
          },
          {
            id: "2",
            category: "",
            subCategories: [
              "Variables",
              "Random numbers",
              "Operators",
              "Cloning",
              "Game development",
            ],
          },
        ],
        outComes: [
          "Given a specific task or problem, create a project using Scratch that incorporates sequencing, loops, parallelism, and event handling, utilizing the Scratch UI and its attributes to design and implement a series of algorithms that solve the problem or complete the task at hand",
          "Using Scratch, develop an interactive story that utilizes user input, conditions, broadcast messages, and extension tools to create a branching narrative with multiple possible outcomes, incorporating debugging techniques to identify and resolve errors in the program",
          "Using Scratch, create a game that generates random numbers and uses variables, operators, and cloning to manage game elements such as score, health, enemies, and power-ups, incorporating user input and event handling to create an engaging and interactive gameplay experience",
        ],
      },
      {
        id: "1",
        title: "Level 2",
        categories: [
          {
            id: "0",
            category: "Edublock",
            subCategories: [
              "Introduction to Edublock UI",
              "Data types & variables",
              "User input",
              "Conditions",
              "Logical & comparison operators",
              "Understanding of text based syntax",
            ],
          },
          {
            id: "1",
            category: "",
            subCategories: [
              "For loop",
              "While loop",
              "Creating basic shapes using Turtle feature",
              "Creating multiple graphics",
              "Creating graphics using text based syntax",
            ],
          },
          {
            id: "2",
            category: "",
            subCategories: [
              "Lists",
              "Hangman game development",
              "Functions",
              "Tic tac toe game development",
            ],
          },
        ],
        outComes: [
          "Given a specific task or problem, create a project using Scratch that incorporates sequencing, loops, parallelism, and event handling, utilizing the Scratch UI and its attributes to design and implement a series of algorithms that solve the problem or complete the task at hand",
          "Using Scratch, develop an interactive story that utilizes user input, conditions, broadcast messages, and extension tools to create a branching narrative with multiple possible outcomes, incorporating debugging techniques to identify and resolve errors in the program",
          "Using Scratch, create a game that generates random numbers and uses variables, operators, and cloning to manage game elements such as score, health, enemies, and power-ups, incorporating user input and event handling to create an engaging and interactive gameplay experience",
        ],
      },
      {
        id: "2",
        title: "Level 3",
        categories: [
          {
            id: "0",
            category: "Introduction and installation of Python.",
            subCategories: [
              "Introduction to Python",
              "Uses",
              "Introduction to Pygame",
              "Installation of Pycharm",
            ],
          },
          {
            id: "1",
            category: "Review of core concepts",
            subCategories: [
              "Working on variables,data types, indentation, syntax, comment, operators.",
              "Explore to work with loop, conditions and user input using text based programming.",
            ],
          },
          {
            id: "2",
            category: "Creating Screen",
            subCategories: ["Create a color breezing screen using Pycharm."],
          },
          {
            id: "3",
            category: "Animation",
            subCategories: ["Devise a program to animate a cat movement."],
          },
          {
            id: "4",
            category: "Drawing",
            subCategories: ["Learn to develop and draw different shapes."],
          },
          {
            id: "5",
            category: "Quiz Game",
            subCategories: [
              "Creation of multiple screens.",
              "Addition of buttons on screens.",
              "Code MCQs.",
            ],
          },
          {
            id: "5",
            category: "Slide Puzzle Game",
            subCategories: [
              "Create a board and add tiles.",
              "Sliding through the mouse.",
              "Setting up buttons.",
            ],
          },
          {
            id: "6",
            category: "Squirrel eat squirrel",
            subCategories: [
              "Adding a player and its transformation.",
              "Working on enemy squirrels. (addition, movement)",
              "Creating grass background.",
              "Collision detection.",
            ],
          },
        ],
        outComes: [
          "Learn computer language in a fun,engaging and interactive way.",
          "Create/write their own codes to develop multiple games through Python.",
          "Understand the text based syntax.",
          "Become confident in problem solving.",
          "Establish a strong foundation for future learning outcomes.",
        ],
      },
    ],
  },
  {
    name: "French",
    curriculum:
      "Dot and Line Creative French Language Curriculum is designed by experts and is based on the idea of spiral progression and scaffolding on prior concepts. Our curriculum has been carefully designed to provide the tools and skills needed to become proficient in this rich and expressive language. Whether you are a complete beginner or looking to refine your existing skills, our program offers a range of engaging and interactive activities that will immerse the students in the language and culture of France. So, grab your pen and paper, and let's begin exploring the beauty and complexity of the French language together.",
    grades: [
      {
        id: "0",
        title: "Level 1",
        categories: [
          {
            id: "0",
            category: "",
            subCategories: [
              "French Alphabets (Intro and Pronounciation)",
              "Basic Vocabulary (Colours, Numbers, Animals, etc)",
              "Basic Grammar (Nouns, Tenses, Verbs, Adjectives)",
              "French Culture and History",
            ],
          },
        ],
        outComes: [
          "Using the French alphabets and basic vocabulary related to colours, numbers, animals, and more, students will develop their creative writing skills in French by crafting descriptive and engaging written pieces, utilizing basic grammar concepts such as nouns, tenses, verbs, and adjectives to add depth and nuance to their writing, and incorporating elements of French culture and history to create rich and vibrant stories that capture the essence of this beautiful language",
          "Through the exploration of various writing prompts related to travel, describing situations, places, people, and objects, making suggestions and giving opinions, creating fictional characters, describing feelings and emotions, and exchanging languages, students will develop their creative writing skills by crafting engaging and compelling written pieces that convey their thoughts, ideas, and perspectives in a clear and concise manner, utilizing different literary techniques to add depth and nuance to their writing, and experimenting with different writing styles and formats to express their creativity and unique voice",
          "Using various practice materials for the DELF test, including listening, reading, writing, and speaking comprehension exercises, as well as opportunities to interact with native students, students will develop their creative writing skills by crafting engaging and compelling written pieces that demonstrate their mastery of the French language, utilizing different literary techniques to convey their ideas and perspectives, and applying their language skills in real-world contexts to express themselves effectively and confidently",
        ],
      },
      {
        id: "1",
        title: "Level 2",
        categories: [
          {
            id: "0",
            category: "",
            subCategories: [
              "Talking about travels",
              "Describing a situation, place, person, object",
              "Making suggestions and giving opinions",
              "Describing a fictional character",
              "Describing feelings and emotions",
              "Exchange Languages",
            ],
          },
        ],
        outComes: [
          "Using the French alphabets and basic vocabulary related to colours, numbers, animals, and more, students will develop their creative writing skills in French by crafting descriptive and engaging written pieces, utilizing basic grammar concepts such as nouns, tenses, verbs, and adjectives to add depth and nuance to their writing, and incorporating elements of French culture and history to create rich and vibrant stories that capture the essence of this beautiful language",
          "Through the exploration of various writing prompts related to travel, describing situations, places, people, and objects, making suggestions and giving opinions, creating fictional characters, describing feelings and emotions, and exchanging languages, students will develop their creative writing skills by crafting engaging and compelling written pieces that convey their thoughts, ideas, and perspectives in a clear and concise manner, utilizing different literary techniques to add depth and nuance to their writing, and experimenting with different writing styles and formats to express their creativity and unique voice",
          "Using various practice materials for the DELF test, including listening, reading, writing, and speaking comprehension exercises, as well as opportunities to interact with native students, students will develop their creative writing skills by crafting engaging and compelling written pieces that demonstrate their mastery of the French language, utilizing different literary techniques to convey their ideas and perspectives, and applying their language skills in real-world contexts to express themselves effectively and confidently",
        ],
      },
      {
        id: "2",
        title: "Level 3",
        categories: [
          {
            id: "0",
            category: "",
            subCategories: [
              "Practice for DELF test",
              "Listening comprehension",
              "Reading comprehension",
              "Writing comprehension",
              "Speaking comprehension",
              "Interact with native students",
            ],
          },
        ],
        outComes: [
          "Using the French alphabets and basic vocabulary related to colours, numbers, animals, and more, students will develop their creative writing skills in French by crafting descriptive and engaging written pieces, utilizing basic grammar concepts such as nouns, tenses, verbs, and adjectives to add depth and nuance to their writing, and incorporating elements of French culture and history to create rich and vibrant stories that capture the essence of this beautiful language",
          "Through the exploration of various writing prompts related to travel, describing situations, places, people, and objects, making suggestions and giving opinions, creating fictional characters, describing feelings and emotions, and exchanging languages, students will develop their creative writing skills by crafting engaging and compelling written pieces that convey their thoughts, ideas, and perspectives in a clear and concise manner, utilizing different literary techniques to add depth and nuance to their writing, and experimenting with different writing styles and formats to express their creativity and unique voice",
          "Using various practice materials for the DELF test, including listening, reading, writing, and speaking comprehension exercises, as well as opportunities to interact with native students, students will develop their creative writing skills by crafting engaging and compelling written pieces that demonstrate their mastery of the French language, utilizing different literary techniques to convey their ideas and perspectives, and applying their language skills in real-world contexts to express themselves effectively and confidently",
        ],
      },
    ],
  },
];

export const subjectData = [
  {
    subject: "Math",
    image: math,
    description:
      "Empower your child's academic journey with Dot and Line Learning's online math classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in math. Sign up now to give your child the edge they need to excel in math!",
    oneOnOne:
      "Looking for personalized attention and guidance in your Math studies? Our One-on-One Live Maths tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your math studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your math exams. Enroll today and take the first step towards academic excellence!",
    shortCourse:
      "Our short chapter-wise courses for math cover all the important topics in the math curriculum. You will learn concepts and techniques that are essential for success, including ✔algorithms, ✔linear algebra, ✔fractions, ✔statistics, and ✔division. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your math exams/tests. Our course is designed to help you succeed in math and achieve your learning goals.",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Math Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Mathematics is a subject that many students find challenging. For some students, math is an abstract subject that requires logical thinking and problem-solving skills. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be very helpful to many students who find it difficult to cope up with the traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online math classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning math a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our live online math classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning math can be a daunting task for many students. At Dot and Line Learning, we make learning math a fun and exciting experience. Our live online math classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online math classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online math classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors from around the globe who are passionate about teaching math. Our tutors have extensive experience in teaching math to students of all classes. They have a deep understanding of the subject and use innovative teaching methods to make learning math easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online math classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Mathematics is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality math education to students in Classes 1-12. Our live online math classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalised attention, your child can unlock their full potential and excel in math.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Coding",
    image: coding,
    description:
      "Empower your child's academic journey with Dot and Line Learning's online coding classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in coding. Sign up now to give your child the edge they need to excel in coding!",
    oneOnOne:
      "Looking for personalized attention and guidance in your coding studies? Our One-on-One Live coding tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your coding studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your codingexams. Enroll today and take the first step towards academic excellence!",
    shortCourse:
      "Our short chapter-wise course for coding covers all the important topics in the coding curriculum. You will learn important concepts and techniques that are essential for success in coding, including ✔variables, ✔functions, ✔control flow, ✔algorithms, ✔programming,  and ✔data structure. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your coding exams. Our course is designed to help you succeed in coding and achieve your academic goals.",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Coding Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Coding is a subject that many students find challenging. For some students, coding is an abstract subject that requires proficiency in programming language, logical thinking and problem solving. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be very helpful to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online coding classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the programming languages thoroughly. Our online classes are designed to be interactive and engaging, making learning coding a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online coding  classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning coding can be a daunting task for many students. At Dot and Line Learning, we make learning codinga fun and exciting experience. Our Live online coding classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online coding classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online coding classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching coding. Our tutors have extensive experience in teaching coding to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning coding easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online coding classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Coding is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality coding education to students in Classes 1-12. Our Live online codingclasses are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in creative writing.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Creative Writing",
    image: creativewriting,
    description:
      "Empower your child's academic journey with Dot and Line Learning's online creative writing classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in creative writing. Sign up now to give your child the edge they need to excel in creative writing!",
    oneOnOne:
      "Looking for personalized attention and guidance in your creative writing studies? Our One-on-One Live creative writing tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your creative writing studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your creative writing exams. Enroll today and take the first step towards academic excellence!",
    shortCourse:
      "Our short chapter-wise course for creative writing covers all the important topics in the creative writing curriculum. You will learn important concepts and techniques that are essential for success in creative writing 12, including ✔vocabulary, ✔grammar, ✔reading comprehension, ✔writing, ✔speaking, ✔listening  and ✔critical thinking. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your creative writing exams. Our course is designed to help you succeed in creative writing and achieve your academic goals.",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Creative writing Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Creative writing is a subject that many students find challenging. For some students, creative writing is an abstract subject that requires polishing the imagination and writing style. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be helpful to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online creative writing classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning creative writing a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online creative writing classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning creative writing can be a daunting task for many students. At Dot and Line Learning, we make learning creative writing a fun and exciting experience. Our Live online creative writing classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online creative writing classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online creative writing classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching creative writing. Our tutors have extensive experience in teaching creative writing to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning creative writing easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online creative writing classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Creative writing is a crucial subject that is the doorway to all genres of writing and can unlock numerous oppertunities. At Dot and Line Learning, we are committed to providing quality creative writing education to students in Classes 1-12. Our Live online creative writing classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in creative writing.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Urdu",
    image: urdu,
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Urdu Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Urdu. Sign up now to give your child the edge they need to excel in Urdu!",
    oneOnOne:
      "Looking for personalized attention and guidance in your urdu studies? Our One-on-One Live Urdu tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Urdu studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Urdu exams. Enroll today and take the first step towards academic excellence!",
    shortCourse:
      "Our short chapter-wise course for phonics covers all the important topics in the Urdu  curriculum. You will learn important concepts and techniques that are essential for success in phonics , including ✔script, ✔pronunciation, ✔vocabulary, ✔grammar, ✔reading, and ✔writing techniques. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your urdu exams. Our course is designed to help you succeed in Urdu and achieve your academic goals.",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Urdu Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Urdu is a subject that many students find challenging. For some students, Urdu is an abstract subject that requires proficiency in basic literary skills and cultural understanding. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be helpful to many students who find it difficult to cope up with the traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Urdu classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly and develop love for storytelling and reading as well as conversational proficiency. Our online classes are designed to be interactive and engaging, making learning urdu a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Urdu classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning urdu can be a daunting task for many students. At Dot and Line Learning, we make learning Urdu a fun and exciting experience. Our Live online Urdu classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Urdu classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Urdu classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Urdu. Our tutors have extensive experience in teaching Urdu to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Urdu easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Urdu classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Urdu is a crucial subject that forms the foundation for creating our identity and scaffolding onto our cultural values. At Dot and Line Learning, we are committed to providing quality urdu education to students in Classes 1-12. Our Live online Urdu classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in creative writing.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Phonics",
    image: phonics,
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Phonics  Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in phonics. Sign up now to give your child the edge they need to excel in phonics!",
    oneOnOne:
      "Looking for personalized attention and guidance in your phonics studies? Our One-on-One live phonics tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your phonics studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve fluency in pronunciation and reading. Enroll today and take the first step towards academic excellence!",
    shortCourse:
      "Our short chapter-wise course for phonics covers all the important topics in the phonics  curriculum. You will learn important concepts and techniques that are essential for success in phonics , including ✔letter-sound correspondence, ✔phonemic awareness, ✔decoding, ✔encoding, ✔sight words, and ✔multi-sensory techniques. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your phonics exams. Our course is designed to help you succeed in phonics and achieve your academic goals.",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Phonics Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Phonics is a subject that many students find challenging. For some students, phonics is an abstract subject that requires proficiency in letter recognition and phonemic awareness. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be helpful to many students who find it difficult to cope up with the traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online phonics classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning phonics a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online phonics classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning phonics can be a daunting task for many students. At Dot and Line Learning, we make learning phonics a fun and exciting experience. Our Live online phonics classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online phonics classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online phonics classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching phonics. Our tutors have extensive experience in teaching phonics to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning phonics easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online phonics classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Phonics is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality phonics education to students in Classes 1-12. Our Live online phonics classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in creative writing.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "English",
    image: english,
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online English Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in English. Sign up now to give your child the edge they need to excel in English!",
    oneOnOne:
      "Looking for personalized attention and guidance in your english studies? Our One-on-One Live English tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your English studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your english exams. Enroll today and take the first step towards academic excellence!",
    shortCourse:
      "Our short chapter-wise course for English covers all the important topics in the english curriculum. You will learn important concepts and techniques that are essential for success in english 12, including ✔vocabulary, ✔grammar, ✔reading comprehension, ✔writing, ✔speaking, ✔listening  and ✔critical thinking. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your english exams. Our course is designed to help you succeed in English and achieve your academic goals.",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's English Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "English is a subject that many students find challenging. For some students, English is an abstract subject that requires polishing the creativity and inference skills. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online english classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning English a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online English classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning English can be a daunting task for many students. At Dot and Line Learning, we make learning english a fun and exciting experience. Our Live online English classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online English classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online English classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching English. Our tutors have extensive experience in teaching English to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning English easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online English classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "English is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality English education to students in Classes 1-12. Our Live online English classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in English.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "French",
    image: french,
    shortCourse:
      "Our short chapter-wise course for French covers all the important topics in the French curriculum. You will learn important concepts and techniques that are essential for success in French, including ✔vocabulary, ✔grammar, ✔reading, ✔writing, ✔speaking, ✔listening  and ✔immersion. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your French exams. Our course is designed to help you succeed in French and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your French studies? Our One-on-One Live French tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your French studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your French exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's online french classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in french. Sign up now to give your child the edge they need to excel in french!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's French Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "French is a subject that many students find challenging. For some students, French is an abstract subject that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online French classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning French a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online French classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning French can be a daunting task for many students. At Dot and Line Learning, we make learning French a fun and exciting experience. Our Live online French classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online French classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online French classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching French. Our tutors have extensive experience in teaching French to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning French easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online French classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "French is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality French education to students in Classes 1-12. Our Live online French classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in French.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Chemistry",
    image: chemistry,
    shortCourse:
      "Our short chapter-wise course for Chemistry covers all the important topics in the Chemistry curriculum. You will learn important concepts and techniques that are essential for success in Chemistry,  including ✔atoms, ✔molecules, ✔ions, ✔inorganic chemistry, ✔thermochemistry, ✔period properties and ✔chemical bonding. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your Chemistry exams. Our course is designed to help you succeed in Chemistry and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Chemistry studies? Our One-on-One Live Chemistry tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Chemistry studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Chemistry exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Chemistry Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Chemistry. Sign up now to give your child the edge they need to excel in Chemistry!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Chemistry Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Chemistry is a subject that many students find challenging. For some students, Chemistry is an abstract subject that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Chemistry classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Chemistry a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Chemistry classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Chemistry can be a daunting task for many students. At Dot and Line Learning, we make learning Chemistry a fun and exciting experience. Our Live online Chemistry classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Chemistry classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Chemistry classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Chemistry. Our tutors have extensive experience in teaching Chemistry to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Chemistry easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Chemistry classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Chemistry is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Chemistry education to students in Classes 1-12. Our Live online Chemistry classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Chemistry.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Physics",
    image: physics,
    shortCourse:
      "Our short chapter-wise course for Physics covers all the important topics in the Physics curriculum. You will learn important concepts and techniques that are essential for success in Physics, including ✔kinematics, ✔forces and dynamics, ✔work, ✔energy, ✔power, ✔electricity and ✔electric fields. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your Physics exams. Our course is designed to help you succeed in Physics and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Physics studies? Our One-on-One Live Physics tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Physics studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Physics exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Physics Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Physics. Sign up now to give your child the edge they need to excel in Physics!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Physics Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Physics is a subject that many students find challenging. For some students,Physics is an abstract subject that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Physics classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Physics a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Physics classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Physics can be a daunting task for many students. At Dot and Line Learning, we make learning Physics a fun and exciting experience. Our Live online Physics classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Physics classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Physics classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Physics. Our tutors have extensive experience in teaching Physics to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Physics easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Physics classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Physics is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Physics education to students in Classes 1-12. Our Live online Physics classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Physics.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Biology",
    image: biology,
    shortCourse:
      "Our short chapter-wise course for Biology  covers all the important topics in the Biology curriculum. You will learn important concepts and techniques that are essential for success in Biology, including ✔living organisms, ✔food webs, ✔respiratory system, ✔digestive system, ✔genetics, ✔biotechnology and ✔functions of cells. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your Biology exams. Our course is designed to help you succeed in Biology and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Biology studies? Our One-on-One Live Biology tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Biology studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Biology exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Biology Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Biology. Sign up now to give your child the edge they need to excel in Biology!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Biology Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Biology is a subject that many students find challenging. For some students, Biology is an abstract subject that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Biology classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Biology a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Biology classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Biology can be a daunting task for many students. At Dot and Line Learning, we make learning Biology a fun and exciting experience. Our Live online Biology classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Biology classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Biology classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Biology. Our tutors have extensive experience in teaching Biology to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Biology easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Biology classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Biology  is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Biology  education to students in Classes 1-12. Our Live online Biology classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Biology.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Business Studies",
    image: business,
    shortCourse:
      "Our short chapter-wise course for Business studies covers all the important topics in the Business studies curriculum. You will learn important concepts and techniques that are essential for success in Business studies, including ✔Cash flows, ✔Business proposals, ✔private sector, ✔public sector, ✔small and large businesses, ✔sole traders and ✔partnerships. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your Business studies exams. Our course is designed to help you succeed in Business studies and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in Business studies? Our One-on-One Live Business studies tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in Business studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Business studies exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Business studies Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Business Studies. Sign up now to give your child the edge they need to excel in Business studies!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Business studies Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Business studies is a subject that many students find challenging. For some students, Business studies is an abstract subject that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Business studies classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Business studies a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Business studies classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Business studies can be a daunting task for many students. At Dot and Line Learning, we make learning Business studies a fun and exciting experience. Our Live online Business studies classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Business studies classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Business studies classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Business studies. Our tutors have extensive experience in teaching Business studies to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Business studies easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Business studies classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Business studies is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Business studies education to students in Classes 1-12. Our Live online Business studies classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Business studies.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Accounts",
    image: accounts,
    shortCourse:
      "Our short chapter-wise course for Accounts covers all the important topics in the Accounts curriculum. You will learn important concepts and techniques that are essential for success in Accounts, including ✔income statements, ✔balance sheets, ✔company accounts, ✔club accounts, ✔bad debts, ✔depreciation and ✔journal entries. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your Accounts exams. Our course is designed to help you succeed in Accounts and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Accounts studies? Our One-on-One Live Accounts tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Accounts studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Accounts exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Accounts Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Accounts. Sign up now to give your child the edge they need to excel in Accounts!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Accounts Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Accounts is a subject that many students find challenging. For some students, Accounts is an abstract subject that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Accounts classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Accounts a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Accounts classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Accounts can be a daunting task for many students. At Dot and Line Learning, we make learning Accounts a fun and exciting experience. Our Live online Accounts classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Accounts classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Accounts classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Accounts. Our tutors have extensive experience in teaching Accounts to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Accounts easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Accounts Classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Accounts is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Accounts education to students in Classes 1-12. Our Live online Accounts classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Accounts.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "History",
    image: history,
    shortCourse:
      "Our short chapter-wise course for History covers all the important topics in the History curriculum. You will learn important concepts and techniques that are essential for success in History, including ✔the Mughal era, ✔Mongols, ✔World War 1, ✔World War 2, ✔British taking over India, ✔the partition of Pakistan and ✔the partition of Bangladesh. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your History exams. Our course is designed to help you succeed in History and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your History studies? Our One-on-One Live History tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your History studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your History exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online History Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in History. Sign up now to give your child the edge they need to excel in History!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's History Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "History is a subject that many students find challenging. For some students, History is an abstract subject that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online History classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning History  a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online History classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning History can be a daunting task for many students. At Dot and Line Learning, we make learning History a fun and exciting experience. Our Live online History classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online History classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online History classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching History. Our tutors have extensive experience in teaching History to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning History easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online History classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "History is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality History education to students in Classes 1-12. Our Live online History classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in History.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Social Studies",
    image: socialStudies,
    shortCourse:
      "Our short chapter-wise course for Social Studies covers all the important topics in the Social Studies Curriculum. You will learn important concepts and techniques that are essential for success in Social Studies, including ✔democracy, ✔individual rights, ✔collective rights, ✔civic responsibilities, ✔shared values, ✔rules of law and ✔diversity. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your Social Studies exams. Our course is designed to help you succeed in Social Studies and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Social Studies studies? Our One-on-One Live Social Studies tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Social Studies studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Social Studies exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Social Studies Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Social Studies. Sign up now to give your child the edge they need to excel in Social Studies!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Social Studies Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Social Studies is a subject that many students find challenging. For some students, Social Studies is an abstract subject that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Social Studies classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Social Studies a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Social Studies classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Social Studies can be a daunting task for many students. At Dot and Line Learning, we make learning Social Studies a fun and exciting experience. Our Live online Social Studies classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Social Studies classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Social Studies classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Social Studies. Our tutors have extensive experience in teaching Social Studies to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Social Studies easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Social Studies classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Social Studies is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Social Studies education to students in Classes 1-12. Our Live online Social Studies classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Social Studies.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Economics",
    image: economics,
    shortCourse:
      "Our short chapter-wise course for Economics covers all the important topics in the Economics curriculum. You will learn important concepts and techniques that are essential for success in Economics, including ✔basic concepts, ✔analytical thinking, ✔quantitative skills, ✔critical thinking, ✔familiarity with economic history, and  ✔knowledge of economic systems. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your Economics exams. Our course is designed to help you succeed in Economics and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Economics studies? Our One-on-One Live Economics tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Economics studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Economics exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Economics Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Economics. Sign up now to give your child the edge they need to excel in Economics!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Economics Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Economics is a subject that many students find challenging. For some students, Economics is an abstract subject that requires analytical and critical thinking skills. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Economics classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Economics a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Economics classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Economics can be a daunting task for many students. At Dot and Line Learning, we make learning Economics a fun and exciting experience. Our Live online Economics classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Economics classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Economics classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Economics. Our tutors have extensive experience in teaching Economics to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Economics easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Economics classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Economics is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Economics education to students in Classes 1-12. Our Live online Economics classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Economics.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Quran",
    image: quran,
    shortCourse:
      "Our short chapter-wise course for Quran covers all the important topics essential for Quranic learning. You will learn important concepts and techniques that are integral for success in learning and interpreting the Quran, including ✔Proficency in Arabic, ✔knowledge of Islamic History, ✔Critical thinking, ✔analytical skills, ✔pronunciation, and  ✔accurate dialect. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic. Our course is designed to help you succeed in familiarising with the Quran.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Quran studies? Our One-on-One Live Quran tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Quran studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Quran exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Quran Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Quran. Sign up now to give your child the edge they need to excel in Quran!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Quran Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Many students find learning the Quran to be challenging. For some students, Quran is an abstract entity that requires patience and perseverance. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Quran classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Quran a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Quran classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Quran can be a daunting task for many students. At Dot and Line Learning, we make learning Quran a fun and exciting experience. Our Live online Quran classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Quran classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Quran classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Quran. Our tutors have extensive experience in teaching Quran to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Quran easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Quran classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Quran is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Quran education to students in Classes 1-12. Our Live online Quran classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Quran.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Arabic",
    image: arabic,
    shortCourse:
      "Our short chapter-wise course for Arabic covers all the important topics in the Arabic curriculum. You will learn important concepts and techniques that are essential for success in Arabic, including ✔vocabulary, ✔grammar, ✔reading, ✔pronunciation, ✔speaking, ✔listening  and ✔arabic aphabet. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation of the Arabic Language. Our course is designed to help you succeed in Arabic and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Arabic studies? Our One-on-One Live Arabic tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Arabic studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Arabic exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Arabic Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Arabic. Sign up now to give your child the edge they need to excel in Arabic!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Arabic Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Arabic is a subject that many students find challenging. For some students, Arabic is an abstract subject that requires perseverance and a love for the language. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Arabic classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Arabic a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Arabic classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Arabic can be a daunting task for many students. At Dot and Line Learning, we make learning Arabic a fun and exciting experience. Our Live online Arabic classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Arabic classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Arabic classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Arabic. Our tutors have extensive experience in teaching Arabic to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Arabic easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Arabic classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Arabic is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Arabic education to students in Classes 1-12. Our Live online Arabic classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Arabic.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "Graphic Design",
    image: graphicDesigning,
    shortCourse:
      "Our short chapter-wise course for Graphic Design covers all the important topics in the Graphic Design curriculum. You will learn important concepts and techniques that are essential for success in Graphic Design, including ✔layout, ✔composition, ✔digital design tools, ✔design history, and ✔design theory. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your Graphic Design exams. Our course is designed to help you succeed in Graphic Design and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your Graphic Design studies? Our One-on-One Live Graphic Design tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your Graphic Design studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your Graphic Design exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online Graphic Design Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in Graphic Design. Sign up now to give your child the edge they need to excel in Graphic Designing!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's Graphic Design Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "Graphic Design is a subject that many students find challenging. For some students, Graphic Design is an abstract subject that requires expert tutoring and familiarity with softwares. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online Graphic Design classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning Graphic Design a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online Graphic Design classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning Graphic Design can be a daunting task for many students. At Dot and Line Learning, we make learning Graphic Design a fun and exciting experience. Our Live online Graphic Design classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online Graphic Design classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online Graphic Design classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching Graphic Design. Our tutors have extensive experience in teaching Graphic Design to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning Graphic Design easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online Arabic classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "Graphic Design is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality Graphic Design education to students in Classes 1-12. Our Live online Graphic Design classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in Graphic Design.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
  {
    subject: "English Literature",
    image: engLit,
    shortCourse:
      "Our short chapter-wise course for English Literature covers all the important topics in the English Literature curriculum. You will learn important concepts and techniques that are essential for success in English Literature, including ✔vocabulary, ✔grammar, ✔reading, ✔writing, ✔pronunciation, and ✔listening. With our chapter-wise approach, you can focus on one topic at a time and master each one before moving on to the next. This ensures that you have a solid foundation in each topic and are well-prepared for your English Literature exams. Our course is designed to help you succeed in English Literature and achieve your academic goals.",
    oneOnOne:
      "Looking for personalized attention and guidance in your English Literature studies? Our One-on-One Live English Literature tutoring online is the perfect solution! Our expert tutors will provide you with individualized support and help you overcome any challenges you may face in your English Literature studies. With interactive and engaging sessions tailored to your needs, you can maximize your potential and achieve top scores in your English Literature exams. Enroll today and take the first step towards academic excellence!",
    description:
      "Empower your child's academic journey with Dot and Line Learning's Online English Literature Classes for class 1 to 12. Our expert tutors use personalized teaching methods to ensure your child's success in English Literature. Sign up now to give your child the edge they need to excel in English Literature!",
    content: [
      {
        id: "0",
        name: "h2",
        data: "Boost Your Child's English Literature Skills with Dot and Line Learning's Online Classes for Class 1-12",
      },
      {
        id: "1",
        name: "p",
        data: "English Literature is a subject that many students find challenging. For some students, English Literature is an abstract subject that requires perseverance and a love for the language. With the advent of technology, the concept of online classes has gained immense popularity. Online classes have proved to be a boon to many students who find it difficult to cope up with traditional classroom learning.",
      },
      {
        id: "2",
        name: "p",
        data: "At Dot and Line Learning, we offer online English Literature classes for students in Classes 1-12 in Pakistan. Our experienced and qualified tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. Our online classes are designed to be interactive and engaging, making learning English Literature a fun and exciting experience.",
      },
      {
        id: "3",
        name: "h2",
        data: "Personalized Attention for Each Student",
      },
      {
        id: "4",
        name: "p",
        data: "At Dot and Line Learning, we understand that every student has a unique learning style. Our Live online English Literature classes are designed to cater to the individual needs of each student. Our experienced tutors provide personalized attention to each student to ensure that they understand the concepts thoroughly. We also offer one-on-one sessions for students who require additional help with specific topics.",
      },
      {
        id: "5",
        name: "h2",
        data: "Interactive and Engaging Learning Experience",
      },
      {
        id: "6",
        name: "p",
        data: "Learning English Literature can be a daunting task for many students. At Dot and Line Learning, we make learning English Literature a fun and exciting experience. Our Live online English Literature classes in Pakistan are designed to be interactive and engaging, making it easier for students to grasp the concepts. Our tutors use visual aids and real-life examples to help students understand complex concepts.",
      },
      {
        id: "7",
        name: "h2",
        data: "Flexible Learning Schedule",
      },
      {
        id: "8",
        name: "p",
        data: "We understand that students have different schedules and commitments. That's why we offer flexible learning schedules for our online English Literature classes. Our classes are conducted live, allowing students to interact with their tutors in real-time. We also provide recorded sessions for students who are unable to attend the live classes due to scheduling conflicts.",
      },
      {
        id: "9",
        name: "h2",
        data: "Affordable Pricing",
      },
      {
        id: "10",
        name: "p",
        data: "We believe that quality education should be accessible to everyone. Our online English Literature classes are priced affordably, making it accessible to students from all backgrounds. We also offer various payment plans to suit the needs of our students and their families.",
      },
      {
        id: "11",
        name: "h2",
        data: "Experienced and Qualified Tutors",
      },
      {
        id: "12",
        name: "p",
        data: "At Dot and Line Learning, we have a team of experienced and qualified tutors who are passionate about teaching English Literature. Our tutors have extensive experience in teaching English Literature to students of all Classes. They have a deep understanding of the subject and use innovative teaching methods to make learning English Literature easier for students.",
      },
      {
        id: "13",
        name: "h2",
        data: "Regular Assessments and Progress Reports",
      },
      {
        id: "14",
        name: "p",
        data: "We believe that regular assessments are essential to measure a student's progress. Our online English Literature classes include regular assessments to ensure that students understand the concepts thoroughly. We also provide progress reports to parents, giving them a clear understanding of their child's progress.",
      },
      {
        id: "15",
        name: "h2",
        data: "24/7 Technical Support",
      },
      {
        id: "16",
        name: "p",
        data: "We understand that technical issues can arise during online classes. That's why we offer 24/7 technical support to our students. Our technical support team is available to help students with any technical issues they may encounter during the classes.",
      },
      {
        id: "17",
        name: "p",
        data: "English Literature is a crucial subject that forms the foundation for many future career paths. At Dot and Line Learning, we are committed to providing quality English Literature education to students in Classes 1-12. Our Live online English Literature classes are designed to be interactive, engaging, and affordable, making it accessible to students from all backgrounds. With our experienced tutors and personalized attention, your child can unlock their full potential and excel in English Literature.",
      },
      {
        id: "18",
        name: "p",
        data: "Sign up now and give your child the edge they need to succeed!",
      },
    ],
  },
];

export const igniteTrainers = [
  {
    id: 1,
    name: "Maheen Adamjee",
    detail:
      "Maheen, co-founder Dot & Line, London School of Economics (LSE) graduate, an award winning journalist, a speaker at the World Bank and has a specialization in mathematics.",
    image: maheen,
    trainerLevel: "Level 1",
  },
  {
    id: 2,
    name: "Lina Ahmed",
    detail:
      "Lina, co-founder Dot & Line, London School of Economics (LSE) graduate, Teacher Training & Curriculum Design Expert, led Dot & Line's popular English programs in 12 countries.",
    image: lina,
    trainerLevel: "Level 1 & Level 3",
  },
  {
    id: 3,
    name: "Sameera Tariq",
    detail:
      "Ms. Sameera, an MBA graduate with UK teacher training, specializes in phonics and reading for ages 3-8. Her creative lessons with arts and crafts boost learning. Free demos showcase her engaging teaching, ideal for nurturing early literacy.",
    image: sameera_tariq,
    trainerLevel: "Level 2 - Early Years Education",
  },
  {
    id: 4,
    name: "Eman Tariq",
    detail:
      "Certified Cambridge tutor, our top corporate instructor for blue-chip companies.",
    image: eman_tariq,
    trainerLevel: "Level 2 - English (Secondary)",
  },
  {
    id: 5,
    name: "Ambreen Salman",
    detail:
      "Certified Teacher Trainer from Arizona State University, trained in STEM course from Pennsylvania University, our super tutor & teacher mentor.",
    image: ambreen_salman,
    trainerLevel: "Level 2 - Math (Secondary)",
  },
  {
    id: 6,
    name: "Wajiha Ahmed",
    detail:
      "With a bachelor's in Psychology, she began teaching as a student. She has 2 years of experience as a Teaching Partner at D&L, guiding students across curriculums, and also works as a Class Quality Evaluator.",
    image: wajiha_ahmed,
    trainerLevel: "Level 2 - Math (Primary)",
  },
  {
    id: 7,
    name: "Nida Yasir",
    detail:
      "With four years of experience, Ms. Nida unlocks each child’s potential through interactive learning, engaging activities, and assessments, fostering success, critical thinking, and academic excellence in an encouraging classroom environment.",
    image: nida_yadir,
    trainerLevel: "Level 2 - Creative Writing",
  },
  {
    id: 8,
    name: "Aymen Ashraf",
    detail:
      "With a Bachelor's in Electronic Engineering, a Post Graduate Diploma in Teaching Practice from LHU UK, and a Global Teaching Practice Diploma from Tellal, Dubai, she is committed to self-development and growth.",
    image: aymen_ashraf,
    trainerLevel: "Level 2 - English (Primary)",
  },
  {
    id: 9,
    name: "Khushbakht Awan",
    detail:
      "6 years of teaching expertise, specialized in human behavior, our super tutor and teacher mentor.",
    image: khushbakht_awan,
    trainerLevel: "Level 2 - Urdu Basics (Primary)",
  },
  {
    id: 10,
    name: "Maha Shahab",
    detail:
      "Ms. Maha Shahab specializes in primary years and has worked at top schools before Dot and Line. Using TPR and joyful methods, she's rated 5 stars by parents in 5 countries. With interactive tools, she unlocks children's potential",
    image: maha_shahab,
    trainerLevel: "Level 2 - STEAM/STEM (Primary)",
  },
  // {
  //   id: 10,
  //   name: "Sadaf Batool",
  //   detail:
  //     "Ms. Sadaf Batool has several years of teaching experience at LGS, Lahore. International teaching experience includes AIESEC in Poland.",
  //   image: sadaf_batool,
  //   trainerLevel: "Level 2 - Creative Writing (Primary)",
  // },
  {
    id: 11,
    name: "Jawaria Aamir",
    detail:
      "Ms. Jawaria Aamir, an IBA Karachi graduate with a Master's in International Relations, is a Cambridge teacher at Dot and Line Learning. She is a skilled mentor, praised for making classes enjoyable and productive",
    image: jawaria_aamir,
    trainerLevel: "Level 3 - Content Writing for Teachers",
  },

  {
    id: 12,
    name: "Madiha Ahmed",
    detail:
      "Ms. Madiha, an IELTS and SAT expert, has over 50 students scoring above the 90th percentile. With a Petroleum Engineering degree from Texas, she brings years of teaching experience from Abu Dhabi to Islamabad.",
    image: madiha_ahmed,
    trainerLevel: "Level 3 - Conflict Management",
  },
  {
    id: 13,
    name: "Mahvish Sohail",
    detail:
      "Ms. Mahvish Sohail, with 11+ years of teaching experience and an MSc in Marketing from Manchester Business School (UK), excels in using educational technologies. Her inspiring style and dedication ensure student success.",
    image: mahvish_sohail,
    trainerLevel: "Level 3 - Student Retention",
  },
  {
    id: 14,
    name: "Laiba Marium",
    detail:
      "Laiba, a product designer at Dot & Line, holds a communication design degree from IVS. She believes proficient design can alter perceptions and is passionate about crafting user-focused design solutions.",
    image: laiba_marium,
    trainerLevel: "Level 3 - Graphic Design for Teachers (Canva Basics)",
  },
];

export const testimonialData = [
  {
    id: "0",
    parent: {
      name: "Prem Puneet",
    },
    review:
      "Ms. Ruba truly excels in teaching additional mathematics, making complex concepts accessible and engaging. Always available to support and encourage, ms. Ruba not only deepens our understanding but also ignites a genuine interest in math. Highly recommended for anyone looking to master addmath!",
    rating: 5,
  },
  {
    id: "1",
    parent: {
      name: "Nada",
    },
    review:
      "I am so glad and thankful to Allah Taa'la that I found Ms. Rasima through Dot & Line. AlhamduliilAllah, my child has improved alot after taking her classes online. Ms. Rasima has surely boosted up his confidence and I am really looking forward to his CAIE results now!",
    rating: 5,
  },
  {
    id: "2",
    parent: {
      name: "Asma Babar",
    },
    review:
      "Miss Jaweria Aamir is an exceptional English teacher who prepared my child for the IGCSE ESL exam with sincerity and dedication. Her kind and sympathetic approach created a comfortable learning environment, fostering both academic growth and confidence. Highly recommended for anyone seeking quality English education!",
    rating: 5,
  },
  {
    id: "3",
    parent: {
      name: "Komal Faisal",
    },
    review:
      "Miss Tanya Ahmad's class showcased her profound knowledge of the Cambridge syllabus. Her well-crafted slides, enriched with keywords, facilitated easy understanding. The insightful worksheets, meticulously checked, pinpointed my weaknesses for improvement. The exceptional mind maps and emphasis on quality over quantity in past paper practice added immense value to the learning experience. I am really satisfied with her teaching skills.",
    rating: 5,
  },
  {
    id: "4",
    parent: {
      name: "Shehla",
    },
    review:
      "Ms Aroosa possesses a deep understanding of the subject matter. Her ability to simplify complex concepts and explain them in a clear and concise manner made learning enjoyable and effective for my child.",
    rating: 5,
  },
  {
    id: "5",
    parent: {
      name: "Rameez Jehan",
    },
    review:
      "Sir Ruman’s dedication to his students is commendable. He is an outstanding teacher who combines expertise, dedication, and passion to create an exceptional learning experience. I highly recommend his classes.",
    rating: 5,
  },
  {
    id: "6",
    parent: {
      name: "Fatima Ayubi",
    },
    review:
      "SHAQ is a very professional, hardworking instructor. With excellent communication and punctuality skills, he never misses a class. He did a crash course for May/June 2023 with my son and Alhamdulillah he secured an A* Grade.",
    rating: 5,
  },
  {
    id: "7",
    parent: {
      name: "Aroush",
    },
    review:
      "Ms Amira is a fantastic teacher, she understood my level of knowledge immediately and started coaching me accordingly. We were often completing 2 years of pastapapers in every session together while retaining most of the new topics covered",
    rating: 5,
  },
  {
    id: "8",
    parent: {
      name: "Amna Alay",
    },
    review:
      "Ms Zainab helped me a lot in a short amount of time and was just what I needed before the CAIEs. Very glad to have had her teach me, and would definitely recommend her services.",
    rating: 5,
  },
  {
    id: "9",
    parent: {
      name: "Afia hassan",
    },
    review:
      "Salwa is more confident now in solving Pastpaper questions. She has improved a lot in Biology during this time. Ms Hira is a very knowledgeable and committed teacher.",
    rating: 5,
  },
  {
    id: "10",
    parent: {
      name: "Asma Babar",
    },
    review:
      "I can safely say that I have found not only a great teacher, but also a good friend in her. Would absolutely recommend her for Maths/Add Maths, and I wish Ms Ruba the best of luck and success in all her future endeavors.",
    rating: 5,
  },
  {
    id: "11",
    parent: {
      name: "Jawaher Ali",
    },
    review:
      "Meri beti mukaalme mein bohat behtar hogayi hai Bohat Shukriya ke Ma'am Uzma ne itni mehnat ki Shukriya",
    rating: 5,
  },
  {
    id: "12",
    parent: {
      name: "Marium Laiba",
    },
    review:
      "In the past month, we have completed 5 years of topical and yearly past papers and the lacking I had of writing answers has decreased to the point that I am now able to write 8 mark and 12 marks Business answers with ease.",
    rating: 5,
  },
];

export const ratingAndReview = [
  {
    id: "0",
    parent: {
      name: "Mahnoor Mengal",
    },
    review: `Miss Mahvish is more than just a teacher. She is an inspiration and a mentor. Her passion for teaching shines through in every lesson.
      I love the way she interacts with her students; it’s easy to see that her students respect her and that she care about them.
      Thanks to her for her hard work in supporting my sons as they develop. Her patience and commitment to supporting my children has mean a lot to our family.
      With her guidance, our sons have developed into confident and capable boys. Thanks to her for being such an important part in our children’s development.
      Her expertise in teaching has put our minds at ease. We are so grateful to have her as our children’s teacher.`,
    rating: 5,
  },
  {
    id: "1",
    parent: {
      name: "Syeda Maliha",
    },
    review:
      "Yes Alhumdulliah she’s a great teacher.. her teaching pattern is so helpful nd Zain-ul-Abideen is Masha’Allah really satisfied with her teacher nd learns alot from her",
    rating: 5,
  },
  {
    id: "2",
    parent: {
      name: "Mrs Shoaib",
    },
    review:
      "Miss Nemrah Matllob is teaching creative writing to my son M. Hassan Shoaib. She is doing a good job, and my son has improved in creative writing skills. We are satisfied with her.",
    rating: 5,
  },
  {
    id: "3",
    parent: {
      name: "Afifa Zeeshan",
    },
    review:
      "Hi, I am truly satisfied from Ms. Namrah. She is quite brilliant teacher. I can see very much improvement in my son. I would love to continue with her.",
    rating: 5,
  },
  {
    id: "4",
    parent: {
      name: "Furqan Ansar",
    },
    review:
      " Ms.Madiha kafeel is a great teacher, My girls love her teaching method. She teaches them with love ,she makes so efforts on them. I am very happy with the teacher",
    rating: 5,
  },
  {
    id: "5",
    parent: {
      name: "Saba Farhan",
    },
    review:
      "My gratitude to you for all your hard work.I’m really satisfied with your ongoing classes:+1:",
    rating: 5,
  },
];

export const unauthBannerData = [
  {
    id: "0",
    web: bannerNoAuthWeb1,
    mobile: bannerNoAuthMobile1,
    titleColor: "rgba(0, 214, 137, 1)",
    titleColor2: "rgba(255, 255, 255, 1)",
    btnText: "Explore Online Classes",
    btnLink: "/courses?program_id=5",
    btnText2: "Join for Free!",
    btnLink2: "/signup",
  },
  {
    id: "2",
    title: "O/A LEVEL EXAM PREPARATION",
    description: "Sab kuch ek hi jagah mil jayega dost!",
    web: bannerNoAuthWeb2,
    mobile: bannerMobile1,
    titleColor: "rgba(44, 165, 141, 1)",
    titleColor2: "rgba(255, 255, 255, 1)",
    btnText: "Explore Online Classes",
    btnLink: "/exam-prep",
    btnText2: "Join for Free!",
    btnLink2: "/signup",
  },
  {
    id: "3",
    web: bannerNoAuthWeb3,
    mobile: bannerMobile1,
  },
  // {
  //   id: "4",
  //   web: bannerNoAuthWeb4,
  //   mobile: bannerMobile1,
  // },
];

export const unAuthOne2One = [
  {
    id: "0",
    web: noAuthOne2OneWeb1,
    mobile: noAuthOne2OneMobile1,
  },
  {
    id: "1",
    web: noAuthOne2OneWeb2,
    mobile: noAuthOne2OneMobile2,
  },
  {
    id: "2",
    web: noAuthOne2OneWeb3,
    mobile: noAuthOne2OneMobile3,
  },
];
