export const educationalQualification = [
  {
    id: "0",
    label: "Undergraduate",
    value: "Undergraduate",
  },
  {
    id: "1",
    label: "M.Phil",
    value: "M.Phil",
  },
  {
    id: "2",
    label: "Bachelors",
    value: "Bachelors",
  },
  {
    id: "3",
    label: "PhD",
    value: "PhD",
  },
  {
    id: "4",
    label: "Masters",
    value: "Masters",
  },
];

export const workStatus = [
  {
    id: "0",
    label: "Yes",
    value: "1",
  },
  {
    id: "1",
    label: "No",
    value: "0",
  },
];

export const teachingExperience = [
  {
    id: "0",
    label: "No Experience",
    value: "No Experience",
  },
  {
    id: "1",
    label: "5-10 Years",
    value: "5-10 Years",
  },
  {
    id: "2",
    label: "1-5 years",
    value: "1-5 years",
  },
  {
    id: "3",
    label: "10+ Years",
    value: "10+ Years",
  },
];

export const igniteDiploma = [
  {
    id: "0",
    label: "Level 1",
    value: "Level 1",
  },
  {
    id: "1",
    label: "Complete IGNITE Diploma (Level One, Level Two, and Level Three)",
    value: "Complete IGNITE Diploma (Level One, Level Two, and Level Three)",
  },
];

export const aboutUs = [
  {
    id: "0",
    label: "Influencer",
    value: "Influencer",
  },
  {
    id: "1",
    label: "Google Ad",
    value: "Google Ad",
  },
  {
    id: "2",
    label: "Word of Mouth",
    value: "Word of Mouth",
  },
  {
    id: "3",
    label: "Facebook Ad",
    value: "Facebook Ad",
  },
  {
    id: "4",
    label: "Facebook Groups",
    value: "Facebook Groups",
  },
  {
    id: "5",
    label: "Amal Academy Alumni Network",
    value: "Amal Academy Alumni Network",
  },
  {
    id: "6",
    label: "Bechlo Community",
    value: "Bechlo Community",
  },
  
];
