import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "src/modules/parent/components/footer";
import AppLoader from "src/modules/parent/components/app-loader";
import Header from "src/modules/parent/components/header";
import styles from "../../styles.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import pencil from "src/modules/parent/assets/svgs/pencil.svg";
import BasicFilters from "src/modules/parent/components/basicFilters";
import Card from "../../components/card";
import BlogComponent from "src/modules/parent/pages/blogs/components/blogComponent";

import * as actions from "src/modules/parent/store/actions";

const BlogDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const action = bindActionCreators(actions, dispatch);
  const [blogName, setBlogName] = useState([]);
  const [text, setText] = useState();
  const [editorBlogs, setEditorBlogs] = useState(null);
  const [blogData, setBlogData] = useState(null);
  const { blogCategories, blogPosts } = useSelector(
    (state) => state.generalReducer
  );

  useEffect(() => {
    if (blogPosts) {
      const editor = {
        ...blogPosts,
        posts: blogPosts.posts.slice(0, 3),
      };

      setEditorBlogs(editor);
      setLoading(false);
      setBlogName(blogPosts.label);
      setBlogData(blogPosts);
    }
  }, [blogPosts]);

  useEffect(() => {
    if (!blogCategories) {
      action.getCategories();
    } else {
      if (location && location.pathname) {
        const path = location.pathname.split("/");
        const label = path[2].toLowerCase();
        const cat = label.split("-").join(" ");

        findBlog(cat);
      }
    }

    return () => {
      action.resetBlogs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogCategories, location]);

  const findBlog = (categoryName) => {
    if (blogCategories && blogCategories.length) {
      const category = blogCategories.find(
        (i) => i.label.toLowerCase() === categoryName
      );

      if (category) {
        setLoading(true);
        setBlogName(category.label);
        action.getPostsByCategory([category], 100, true);
      } else {
        navigate("/blogs");
      }
    }
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const basicFilterHandler = (page, pageSize) => {
    // const category = blogName.toString().toLowerCase();
    console.log("🚀 ~ basicFilterHandler ~ blogName:", text);
    if (text) {
      navigate(`/blogs/${text.split(" ").join("-")}`);
      setText();
    }
    // navigate(`/blogs/${category.split(" ").join("-")}`);
  };

  const handleGoBack = () => {
    navigate("/blogs");
  };

  return (
    <div>
      <Header />
      <div className={styles.mainHeadingContainer}>
        <div className="app-container pt-50 pb-50">
          <Row>
            <Col>
              <div onClick={handleGoHome} className={styles.routeName}>
                {"Home"}
              </div>
            </Col>
            <div className={styles.slash}>{"/"}</div>

            <Col onClick={handleGoBack} className={styles.routeName}>
              {"Blogs"}
            </Col>

            <div className={styles.slash}>{"/"}</div>

            {blogName && <Col>{blogName}</Col>}
          </Row>

          <Row className="pt-30" gutter={[24, 24]}>
            <BasicFilters
              allowClear={false}
              gradeFilterDisabled
              title={"Search Blog"}
              subjects={text}
              setSubjects={setText}
              keywordList={blogCategories}
              basicFilterHandler={basicFilterHandler}
            />
          </Row>

          <Row justify={"center"} className="pt-30">
            {blogName && (
              <div
                className={[
                  "font-20 mb-20 font-weight-500",
                  styles.headingStyle,
                ].join(" ")}
              >
                {`${blogName} Blogs`}
              </div>
            )}
          </Row>

          {loading ? (
            <div className="d-flex justify-content-center mt-50 pt-40 mb-50">
              <AppLoader className="font-28" />
            </div>
          ) : (
            <Fragment>
              {editorBlogs && (
                <BlogComponent editors data={editorBlogs} moreHide />
              )}

              <Col className="mb-20" span={24}>
                <div className={styles.banner}>
                  <h1 className={styles.banner_title}>
                    {"What We Value At Dot and Line Learning"}
                  </h1>

                  <p>
                    {
                      "At Dot and Line, we cherish real connections, fostering a supportive community that nurtures growth. We prioritize collaboration, empathy, and personalized learning experiences to empower individuals and inspire lifelong curiosity."
                    }
                  </p>

                  <div className={styles.pencil}>
                    <img src={pencil} alt={"pencil"} />
                  </div>
                </div>
              </Col>

              {blogData && blogData.posts && blogData.posts.length > 3 && (
                <Row>
                  {blogData.posts.slice(3).map((item, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                      <Card
                        {...item}
                        category={blogName}
                        key={item.toString()}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </Fragment>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BlogDetailPage;
