import { io } from "socket.io-client";

require("dotenv").config();

const { REACT_APP_CHAT_API } = process.env;

const URL = REACT_APP_CHAT_API;

const chatRoute = `${URL}/chat`;
const inboxRoute = `${URL}/inbox`;

const chatSocket = io(chatRoute);
const inboxSocket = io(inboxRoute);

export { chatSocket, inboxSocket };
