import React from "react";

import { Col, Row } from "antd";
import Carousel from "react-multi-carousel";
import { isMobile } from "react-device-detect";

import { responsive } from "src/modules/parent/utils";
import Card from "../../card/card";

import styles from "../styles.module.css";

const StepNo3 = ({
  data = [],
  handleShowModal = () => {},
  handleBookADemo = () => {},
  handleDetails = () => {},
  handleWhatsApp = () => {},
}) => (
  <Row
    gutter={[0, 30]}
    className={isMobile ? "mt-15 w-100" : "mt-50 w-100"}
    justify={"space-evenly"}
  >
    <Col className={"text-align-center"} span={24}>
      <span className={styles.connect}>
        Feel free to connect with us on our WhatsApp:{" "}
        <span onClick={handleWhatsApp} className={styles.colorGreen}>
          +92 346 0086795
        </span>
      </span>
    </Col>

    {data && data.length ? (
      <Col span={isMobile ? 22 : 20}>
        <Carousel responsive={responsive}>
          {data.map((item) => (
            <Card
              {...item}
              key={item.id.toString()}
              handleShowModal={handleShowModal}
              handleBookADemo={handleBookADemo}
              handleClickCard={handleDetails}
            />
          ))}
        </Carousel>
      </Col>
    ) : (
      <Col className="mt-50 mb-50 text-align-center" span={20}>
        <h3>{"No result found!"}</h3>
      </Col>
    )}
  </Row>
);

export default React.memo(StepNo3);
