import React from "react";

import { Layout, Menu, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import arrowIcon from "../../assets/svgs/arrow.svg";
import arrowIconDark from "../../assets/svgs/arrowDark.svg";

import * as actions from "src/modules/parent/store/actions";

const SubjectLabel = ({ subjects = [], selectedId }) => {
  if (subjects && !subjects.length) {
    return null;
  }

  return subjects?.map((subject) => ({
    key: subject?.id,
    label: (
      <Link
        to={`/learning-library/resources/${subject?.subject.toLowerCase()}`}
      >
        <span className={"submenu_label"}>
          <Row>
            <Space size={"middle"}>
              {selectedId === subject?.id ? (
                <img src={arrowIcon} alt="" />
              ) : (
                <img src={arrowIconDark} alt="" />
              )}

              {subject?.subject}
            </Space>
          </Row>
        </span>
      </Link>
    ),
  }));
};

const LayoutSidebar = ({ width = 300 }) => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const { activeLearningMenu, learningSubjects } = useSelector(
    (state) => state.learningReducer
  );
  const [value] = activeLearningMenu;

  const menuItems = [
    {
      key: "learning-resources",
      label: "Learning Resources",
      children: SubjectLabel({ subjects: learningSubjects, selectedId: value }),
    },
  ];

  const siderStyle = {
    border: "1px solid #ebebeb",
    borderRadius: "5px",
    overflow: "auto",
    height: "100%",
  };

  return (
    <Layout.Sider width={width} style={siderStyle}>
      <Menu
        mode="inline"
        items={menuItems}
        selectedKeys={activeLearningMenu}
        defaultOpenKeys={activeLearningMenu}
        openKeys={[activeLearningMenu[1]]}
        defaultSelectedKeys={activeLearningMenu}
        onSelect={({ keyPath }) => action.setActiveLearningMenu(keyPath)}
      />
    </Layout.Sider>
  );
};

export default React.memo(LayoutSidebar);
