/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useMemo, useRef, useState } from "react";
import CardsWrapper from "../cards-wrapper";
import noImage from "../../assets/img/no_photo.png";
import styles from "./styles.module.css";
import { Col, DatePicker, Form, message, Row } from "antd";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../modal";
import TextInput from "../text-input";
import moment from "moment-timezone";
import FlatButton from "../flat-button";
import { boardData, genderData, gradesData } from "../../utils/static-data";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";
import SelectStudentAvatar from "./select-student-avatar";
import { avatarList, containsNumbersOrSpecialCharacters } from "../../utils";
import actionTypes from "../../store/action-types";
import { IMAGE_BASE_URL } from "../../config/constant";
import SelectInput from "../select-input";
import dayjs from "dayjs";
import { boards } from "../../pages/olevels/components/banner/staticData";
import ReactPixel from "react-facebook-pixel";
import { fb_pixel_id_summer } from "src/modules/parent/config/constant";
import { addChildSaveBtnClick, trackEvents } from "../../utils/events/query";

ReactPixel.init(fb_pixel_id_summer);

const dateFormat = "YYYY/MM/DD";

function ChildDetails({ child, edit = false, isOnboarding = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef();
  const action = bindActionCreators(actions, dispatch);
  const { id } = useSelector((state) => state.authReducer.userInfo);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [newStudent, setNewStudent] = useState({
    name: undefined,
    gender: undefined,
    level: undefined,
    board: undefined,
    boardOther: undefined,
    school: undefined,
    avatar: "",
    image: "",
    dob: child?.dob ? dayjs(child?.dob) : dayjs()?.subtract(1095, "day"),
  });

  useEffect(() => {
    if (edit) {
      const temp = {
        name: child?.name,
        gender: child?.gender,
        level: child?.level,
        school: child?.school,
        dob: moment(child?.dob),
      };
      const tempBoard = boardData?.find((e) => e?.value === child?.board);
      if (tempBoard) {
        temp.board = tempBoard?.value;
      } else {
        temp.board = "Other";
        temp.boardOther = child?.board;
      }
      if (child?.avatar) {
        temp.avatar = child?.avatar;
        temp.image = "";
        setProfileImage(avatarList[child?.avatar]?.image);
      } else if (child?.image) {
        const image = `${IMAGE_BASE_URL}${child?.image}`;
        temp.image = image;
        temp.avatar = "";
        setProfileImage(image);
      }
      setNewStudent({ ...temp });
    }
  }, [edit]);

  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
    }
  }, [errorMessage]);

  const isValidated = () => {
    const { name, dob, gender, level, board, school, boardOther } = newStudent;
    if (
      !name ||
      !dob ||
      !gender ||
      !level ||
      !board ||
      !school ||
      (board === "Other" && !boardOther)
    ) {
      seterrorMessage("Please fill all required fields");
      return false;
    }
    return true;
  };

  const navigationHandler = () => {
    const [paramKey, paramValue] = window?.location?.search?.split("="); // Getting route params
    if (paramKey === "?redirect_to" && paramValue) {
      navigate(paramValue);
    } else {
      navigate("/my-children");
    }
  };

  const submitHandler = () => {
    if (isValidated()) {
      setLoading(true);
      const data = {
        ...newStudent,
        dob: newStudent?.dob.format("YYYY-MM-DD"),
      };
      if (data.board === "Other") {
        data.board = data.boardOther;
      }
      delete data["boardOther"];
      if (edit) {
        data.id = child.id;
        if (data.avatar) {
          data.image = "";
        }
        action
          .updateChild(data)
          .then((response) => {
            action.getChildren(id);
            setLoading(false);
            message.success(response);
            navigationHandler();
          })
          .catch(() => setLoading(false));
      } else {
        if (!data.avatar) {
          delete data["avatar"];
        }
        action
          .addChild(data)
          .then((response) => {
            setLoading(false);

            if (!edit) {
              trackEvents(addChildSaveBtnClick);
            }

            message.success(response);
            if (isOnboarding) {
              dispatch({ type: actionTypes.IS_ONBOARDING, payload: false });
            }
            navigationHandler();
          })
          .catch(() => setLoading(false));
      }
    }
  };

  const selectAvatarHandler = (item, key) => {
    const avatar = item
      .split("/static/media/")[1]
      .split(".")[0]
      .split("-")
      .join("");
    const temp = { ...newStudent, avatar, image: "" };
    setNewStudent(temp);
    setSelectedAvatar(key);
    setProfileImage(item);
  };

  const onImageChange = (e) => {
    if (e?.target?.files && e?.target?.files[0]) {
      const temp = { ...newStudent, image: e?.target?.files[0], avatar: "" };
      setNewStudent(temp);
      setProfileImage(URL.createObjectURL(e?.target?.files[0]));
    }
  };

  const getBoard = useMemo(() => {
    switch (newStudent.level) {
      case "Grade 8":
      case "Grade 9":
      case "Grade 10":
      case "Grade 11":
      case "Grade 12":
        return boards;

      default:
        return boardData;
    }
  }, [newStudent]);

  return (
    <Fragment>
      <ModalComponent
        isModalVisible={showAvatarModal}
        onCancel={() => setShowAvatarModal(false)}
        Component={
          <SelectStudentAvatar
            selectedAvatar={selectedAvatar}
            selectAvatarHandler={selectAvatarHandler}
            setShowAvatarModal={setShowAvatarModal}
          />
        }
      />
      <CardsWrapper className={styles.wrapper}>
        <Form className={"text-align-center"}>
          <Row
            gutter={[5, 5]}
            className={[styles.wrapper_col, "mt-30 mb-30"].join(" ")}
          >
            <Col span={24} className="mt-10">
              <div className={styles.profile_image_container}>
                <div className={styles.avatar_div}>
                  <div className={styles.profile_image}>
                    <img alt="profile" src={profileImage || noImage} />
                    <input
                      ref={inputRef}
                      onChange={onImageChange}
                      type="file"
                      className="d-none-imp"
                    />
                  </div>
                  <div
                    onClick={() => setShowAvatarModal(true)}
                    className={"green-color mt-15 cursor-pointer"}
                  >
                    Select Avatar
                  </div>
                  <div
                    onClick={() => inputRef?.current?.click()}
                    className={"green-color mt-4 cursor-pointer"}
                  >
                    Upload
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <p
                className={
                  "f-xl font-weight-500-imp mt-20 mb-15 green-color text-align-left"
                }
              >
                <span>Personal Information</span>
                <span className="red-color">*</span>
              </p>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={styles.col1_space}>
                <Form.Item
                  hasFeedback={!newStudent.name && errorMessage ? true : false}
                  validateStatus={
                    !newStudent.name && errorMessage ? "error" : "success"
                  }
                >
                  <TextInput
                    required
                    allowClear
                    label={"Full Name"}
                    placeholder={"Enter full name"}
                    value={newStudent.name}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!containsNumbersOrSpecialCharacters(value)) {
                        setNewStudent({ ...newStudent, name: value });
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={styles.col2_space}>
                <Form.Item
                  hasFeedback={
                    !newStudent.gender && errorMessage ? true : false
                  }
                  validateStatus={
                    !newStudent.gender && errorMessage ? "error" : "success"
                  }
                >
                  <SelectInput
                    required
                    allowClear
                    label={"Gender"}
                    value={newStudent.gender}
                    placeholder={"Select gender"}
                    onChange={(e) =>
                      setNewStudent({ ...newStudent, gender: e })
                    }
                    options={genderData}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={styles.col1_space}>
                <Form.Item
                  hasFeedback={!newStudent.dob && errorMessage ? true : false}
                  validateStatus={
                    !newStudent.dob && errorMessage ? "error" : "success"
                  }
                >
                  <p className={styles.input_label}>Date of Birth</p>
                  <DatePicker
                    required
                    className={styles.dob}
                    format={"YYYY-MM-DD"}
                    placeholder={"Select date of birth"}
                    defaultValue={dayjs(newStudent.dob, dateFormat)}
                    onChange={(e) => setNewStudent({ ...newStudent, dob: e })}
                    disabledDate={(current) =>
                      current &&
                      current?.valueOf() > moment()?.subtract(1095, "days")
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <p
                className={
                  "f-xl font-weight-500-imp mt-40 mb-15 green-color text-align-left"
                }
              >
                <span>Educational Information</span>
                <span className="red-color">*</span>
              </p>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={styles.col1_space}>
                <Form.Item
                  hasFeedback={!newStudent.level && errorMessage ? true : false}
                  validateStatus={
                    !newStudent.level && errorMessage ? "error" : "success"
                  }
                >
                  <SelectInput
                    required
                    allowClear
                    label={"Grade"}
                    placeholder={"Select grade"}
                    value={newStudent.level}
                    onChange={(e) =>
                      setNewStudent({
                        ...newStudent,
                        board: undefined,
                        level: e,
                      })
                    }
                    options={gradesData}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={styles.col2_space}>
                <Form.Item
                  hasFeedback={
                    !newStudent.school && errorMessage ? true : false
                  }
                  validateStatus={
                    !newStudent.school && errorMessage ? "error" : "success"
                  }
                >
                  <TextInput
                    required
                    allowClear
                    label={"School"}
                    value={newStudent.school}
                    placeholder={"Enter school"}
                    onChange={(e) =>
                      setNewStudent({ ...newStudent, school: e.target.value })
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={styles.col1_space}>
                <Form.Item
                  hasFeedback={!newStudent.board && errorMessage ? true : false}
                  validateStatus={
                    !newStudent.board && errorMessage ? "error" : "success"
                  }
                >
                  <SelectInput
                    required
                    allowClear
                    label={"Board"}
                    placeholder={"Select board"}
                    value={newStudent.board}
                    onChange={(e) => setNewStudent({ ...newStudent, board: e })}
                    options={getBoard}
                  />
                </Form.Item>
              </div>
            </Col>
            {newStudent.board === "Other" && (
              <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                <div className={styles.col2_space}>
                  <Form.Item
                    hasFeedback={
                      !newStudent.boardOther && errorMessage ? true : false
                    }
                    validateStatus={
                      !newStudent.boardOther && errorMessage
                        ? "error"
                        : "success"
                    }
                  >
                    <TextInput
                      required
                      allowClear
                      label={"Board Name"}
                      value={newStudent.boardOther}
                      placeholder={"Enter board name"}
                      onChange={(e) =>
                        setNewStudent({
                          ...newStudent,
                          boardOther: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
            )}
            <Col span={24}>
              <div className={styles.btn_div}>
                <div>
                  <FlatButton
                    title={edit ? "Update" : "Save"}
                    width={"217px"}
                    loading={loading}
                    onClick={submitHandler}
                    className={[styles.col1_space, "mt-30"].join(" ")}
                  />
                </div>
                <div>
                  <FlatButton
                    title={"Cancel"}
                    width={"217px"}
                    type={"Secondary"}
                    className={[styles.col2_space, "mt-30"].join(" ")}
                    onClick={() => navigate("/my-children")}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </CardsWrapper>
    </Fragment>
  );
}

export default ChildDetails;
