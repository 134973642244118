export const countries = [
  {
    title: "Afghanistan",
    icon: "🇦🇫",
    code: "AF",
    value: "+93",
    mask: "99-999-9999",
    id: "0",
  },
  {
    title: "Albania",
    icon: "🇦🇱",
    code: "AL",
    value: "+355",
    mask: "(999)999-999",
    id: "1",
  },
  {
    title: "Algeria",
    icon: "🇩🇿",
    code: "DZ",
    value: "+213",
    mask: "99-999-9999",
    id: "2",
  },
  {
    title: "American Samoa",
    icon: "🇦🇸",
    code: "AS",
    value: "+1684",
    mask: "(684)999-9999",
    id: "3",
  },
  {
    title: "Andorra",
    icon: "🇦🇩",
    code: "AD",
    value: "+376",
    mask: "999-999",
    id: "4",
  },
  {
    title: "Angola",
    icon: "🇦🇴",
    code: "AO",
    value: "+244",
    mask: "(999)999-999",
    id: "5",
  },
  {
    title: "Anguilla",
    icon: "🇦🇮",
    code: "AI",
    value: "+1264",
    mask: "(264)999-9999",
    id: "6",
  },
  {
    title: "Antigua and Barbuda",
    icon: "🇦🇬",
    code: "AG",
    value: "+1268",
    mask: "(268)999-9999",
    id: "7",
  },
  {
    title: "Aruba",
    icon: "🇦🇼",
    code: "AW",
    value: "+297",
    mask: "999-9999",
    id: "8",
  },
  {
    title: "Australia",
    icon: "🇦🇺",
    code: "AU",
    value: "+61",
    mask: "9-9999-9999",
    id: "9",
  },
  {
    title: "Austria",
    icon: "🇦🇹",
    code: "AT",
    value: "+43",
    mask: "(999)999-9999",
    id: "10",
  },
  {
    title: "Azerbaijan",
    icon: "🇦🇿",
    code: "AZ",
    value: "+994",
    mask: "99-999-99-99",
    id: "11",
  },
  {
    title: "Bahamas",
    icon: "🇧🇸",
    code: "BS",
    value: "+1242",
    mask: "(242)999-9999",
    id: "12",
  },
  {
    title: "Bahrain",
    icon: "🇧🇭",
    code: "BH",
    value: "+973",
    mask: "9999-9999",
    id: "13",
  },
  {
    title: "Bangladesh",
    icon: "🇧🇩",
    code: "BD",
    value: "+880",
    mask: "1999-999999",
    id: "14",
  },
  {
    title: "Barbados",
    icon: "🇧🇧",
    code: "BB",
    value: "+1246",
    mask: "(246)999-9999",
    id: "15",
  },
  {
    title: "Belarus",
    icon: "🇧🇾",
    code: "BY",
    value: "+375",
    mask: "(99)999-99-99",
    id: "16",
  },
  {
    title: "Belgium",
    icon: "🇧🇪",
    code: "BE",
    value: "+32",
    mask: "(999)999-999",
    id: "17",
  },
  {
    title: "Belize",
    icon: "🇧🇿",
    code: "BZ",
    value: "+501",
    mask: "999-9999",
    id: "18",
  },
  {
    title: "Bermuda",
    icon: "🇧🇲",
    code: "BM",
    value: "+1441",
    mask: "(441)999-9999",
    id: "19",
  },
  {
    title: "Bhutan",
    icon: "🇧🇹",
    code: "BT",
    value: "+975",
    mask: ["17-999-999", "77-999-999", "9-999-999"],
    id: "20",
  },
  {
    title: "Bosnia and Herzegovina",
    icon: "🇧🇦",
    code: "BA",
    value: "+387",
    mask: ["99-9999", "99-99999"],
    id: "21",
  },
  {
    title: "Botswana",
    icon: "🇧🇼",
    code: "BW",
    value: "+267",
    mask: "99-999-999",
    id: "22",
  },
  {
    title: "Brazil",
    icon: "🇧🇷",
    code: "BR",
    value: "+55",
    mask: ["(99)9999-9999", "(99)99999-9999"],
    id: "23",
  },
  {
    title: "British Indian Ocean Territory",
    icon: "🇮🇴",
    code: "IO",
    value: "+246",
    mask: "999-9999",
    id: "24",
  },
  {
    title: "Brunei Darussalam",
    icon: "🇧🇳",
    code: "BN",
    value: "+673",
    mask: "999-9999",
    id: "25",
  },
  {
    title: "Bulgaria",
    icon: "🇧🇬",
    code: "BG",
    value: "+359",
    mask: "(999)999-999",
    id: "26",
  },
  {
    title: "Burkina Faso",
    icon: "🇧🇫",
    code: "BF",
    value: "+226",
    mask: "99-99-9999",
    id: "27",
  },
  {
    title: "Burundi",
    icon: "🇧🇮",
    code: "BI",
    value: "+257",
    mask: "99-99-9999",
    id: "28",
  },
  {
    title: "Cambodia",
    icon: "🇰🇭",
    code: "KH",
    value: "+855",
    mask: "99-999-999",
    id: "29",
  },
  {
    title: "Cameroon",
    icon: "🇨🇲",
    code: "CM",
    value: "+237",
    mask: "9999-9999",
    id: "30",
  },
  {
    title: "Canada",
    icon: "🇨🇦",
    code: "CA",
    value: "+1",
    mask: "(999)999-9999",
    id: "31",
  },
  {
    title: "Cape Verde",
    icon: "🇨🇻",
    code: "CV",
    value: "+238",
    mask: "(999)99-99",
    id: "32",
  },
  {
    title: "Cayman Islands",
    icon: "🇰🇾",
    code: "KY",
    value: "+345",
    mask: "(345)999-9999",
    id: "33",
  },
  {
    title: "Chad",
    icon: "🇹🇩",
    code: "TD",
    value: "+235",
    mask: "99-99-99-99",
    id: "34",
  },
  {
    title: "Chile",
    icon: "🇨🇱",
    code: "CL",
    value: "+56",
    mask: "9-9999-9999",
    id: "35",
  },
  {
    title: "China",
    icon: "🇨🇳",
    code: "CN",
    value: "+86",
    mask: ["(999)9999-999", "(999)9999-9999", "99-99999-99999"],
    id: "36",
  },
  {
    title: "Christmas Island",
    icon: "🇨🇽",
    code: "CX",
    value: "+61",
    mask: "9-9999-9999",
    id: "37",
  },
  {
    title: "Cocos (Keeling) Islands",
    icon: "🇨🇨",
    code: "CC",
    value: "+61",
    mask: "9-9999-9999",
    id: "38",
  },
  {
    title: "Colombia",
    icon: "🇨🇴",
    code: "CO",
    value: "+57",
    mask: "(999)999-9999",
    id: "39",
  },
  {
    title: "Comoros",
    icon: "🇰🇲",
    code: "KM",
    value: "+269",
    mask: "99-99999",
    id: "40",
  },
  {
    title: "Congo",
    icon: "🇨🇬",
    code: "CG",
    value: "+242",
    mask: "99-99999",
    id: "41",
  },
  {
    title: "Cook Islands",
    icon: "🇨🇰",
    code: "CK",
    value: "+682",
    mask: "99-999",
    id: "42",
  },
  {
    title: "Costa Rica",
    icon: "🇨🇷",
    code: "CR",
    value: "+506",
    mask: "9999-9999",
    id: "43",
  },
  {
    title: "Croatia",
    icon: "🇭🇷",
    code: "HR",
    value: "+385",
    mask: "99-999-999",
    id: "44",
  },
  {
    title: "Cuba",
    icon: "🇨🇺",
    code: "CU",
    value: "+53",
    mask: "9-999-9999",
    id: "45",
  },
  {
    title: "Cyprus",
    icon: "🇨🇾",
    code: "CY",
    value: "+357",
    mask: "99-999-999",
    id: "46",
  },
  {
    title: "Czech Republic",
    icon: "🇨🇿",
    code: "CZ",
    value: "+420",
    mask: "(999)999-999",
    id: "47",
  },
  {
    title: "Djibouti",
    icon: "🇩🇯",
    code: "DJ",
    value: "+253",
    mask: "99-99-99-99",
    id: "48",
  },
  {
    title: "Dominica",
    icon: "🇩🇲",
    code: "DM",
    value: "+1767",
    mask: "(767)999-9999",
    id: "49",
  },
  {
    title: "Ecuador",
    icon: "🇪🇨",
    code: "EC",
    value: "+593",
    mask: ["9-999-9999", "99-999-9999"],
    id: "50",
  },
  {
    title: "Egypt",
    icon: "🇪🇬",
    code: "EG",
    value: "+20",
    mask: "(999)999-9999",
    id: "51",
  },
  {
    title: "El Salvador",
    icon: "🇸🇻",
    code: "SV",
    value: "+503",
    mask: "99-99-9999",
    id: "52",
  },
  {
    title: "Equatorial Guinea",
    icon: "🇬🇶",
    code: "GQ",
    value: "+240",
    mask: "99-999-9999",
    id: "53",
  },
  {
    title: "Eritrea",
    icon: "🇪🇷",
    code: "ER",
    value: "+291",
    mask: "9-999-999",
    id: "54",
  },
  {
    title: "Estonia",
    icon: "🇪🇪",
    code: "EE",
    value: "+372",
    mask: ["999-9999", "9999-9999"],
    id: "55",
  },
  {
    title: "Ethiopia",
    icon: "🇪🇹",
    code: "ET",
    value: "+251",
    mask: "99-999-9999",
    id: "56",
  },
  {
    title: "Falkland Islands (Malvinas)",
    icon: "🇫🇰",
    code: "FK",
    value: "+500",
    mask: "99999",
    id: "57",
  },
  {
    title: "Faroe Islands",
    icon: "🇫🇴",
    code: "FO",
    value: "+298",
    mask: "999-999",
    id: "58",
  },
  {
    title: "Fiji",
    icon: "🇫🇯",
    code: "FJ",
    value: "+679",
    mask: "99-99999",
    id: "59",
  },
  {
    title: "Finland",
    icon: "🇫🇮",
    code: "FI",
    value: "+358",
    mask: "(999)999-99-99",
    id: "60",
  },
  {
    title: "France",
    icon: "🇫🇷",
    code: "FR",
    value: "+33",
    mask: "(999)999-999",
    id: "61",
  },
  {
    title: "Gabon",
    icon: "🇬🇦",
    code: "GA",
    value: "+241",
    mask: "9-99-99-99",
    id: "62",
  },
  {
    title: "Gambia",
    icon: "🇬🇲",
    code: "GM",
    value: "+220",
    mask: "(999)99-99",
    id: "63",
  },
  {
    title: "Georgia",
    icon: "🇬🇪",
    code: "GE",
    value: "+995",
    mask: "(999)999-999",
    id: "64",
  },
  {
    title: "Germany",
    icon: "🇩🇪",
    code: "DE",
    value: "+49",
    mask: [
      "999-999",
      "(999)99-99",
      "(999)99-999",
      "(999)99-9999",
      "(999)999-9999",
      "(9999)999-9999",
    ],
    id: "65",
  },
  {
    title: "Ghana",
    icon: "🇬🇭",
    code: "GH",
    value: "+233",
    mask: "(999)999-999",
    id: "66",
  },
  {
    title: "Gibraltar",
    icon: "🇬🇮",
    code: "GI",
    value: "+350",
    mask: "999-99999",
    id: "67",
  },
  {
    title: "Greece",
    icon: "🇬🇷",
    code: "GR",
    value: "+30",
    mask: "(999)999-9999",
    id: "68",
  },
  {
    title: "Guadeloupe",
    icon: "🇬🇵",
    code: "GP",
    value: "+590",
    mask: "(999)999-999",
    id: "69",
  },
  {
    title: "Guam",
    icon: "🇬🇺",
    code: "GU",
    value: "+1671",
    mask: "(671)999-9999",
    id: "70",
  },
  {
    title: "Guatemala",
    icon: "🇬🇹",
    code: "GT",
    value: "+502",
    mask: "9-999-9999",
    id: "71",
  },
  {
    title: "Guernsey",
    icon: "🇬🇬",
    code: "GG",
    value: "+44",
    mask: "(9999)999999",
    id: "72",
  },
  {
    title: "Guinea",
    icon: "🇬🇳",
    code: "GN",
    value: "+224",
    mask: "99-999-999",
    id: "73",
  },
  {
    title: "Guinea-Bissau",
    icon: "🇬🇼",
    code: "GW",
    value: "+245",
    mask: "9-999999",
    id: "74",
  },
  {
    title: "Guyana",
    icon: "🇬🇾",
    code: "GY",
    value: "+592",
    mask: "999-9999",
    id: "75",
  },
  {
    title: "Haiti",
    icon: "🇭🇹",
    code: "HT",
    value: "+509",
    mask: "99-99-9999",
    id: "76",
  },
  {
    title: "Honduras",
    icon: "🇭🇳",
    code: "HN",
    value: "+504",
    mask: "9999-9999",
    id: "77",
  },
  {
    title: "Hong Kong",
    icon: "🇭🇰",
    code: "HK",
    value: "+852",
    mask: "9999-9999",
    id: "78",
  },
  {
    title: "Hungary",
    icon: "🇭🇺",
    code: "HU",
    value: "+36",
    mask: "(999)999-999",
    id: "79",
  },
  {
    title: "Iceland",
    icon: "🇮🇸",
    code: "IS",
    value: "+354",
    mask: "999-9999",
    id: "80",
  },
  {
    title: "India",
    icon: "🇮🇳",
    code: "IN",
    value: "+91",
    mask: "(9999)999-999",
    id: "81",
  },
  {
    title: "Indonesia",
    icon: "🇮🇩",
    code: "ID",
    value: "+62",
    mask: [
      "99-999-99",
      "99-999-999",
      "99-999-9999",
      "(899)999-999",
      "(899)999-99-999",
    ],
    id: "82",
  },
  {
    title: "Iraq",
    icon: "🇮🇶",
    code: "IQ",
    value: "+964",
    mask: "(999)999-9999",
    id: "83",
  },
  {
    title: "Ireland",
    icon: "🇮🇪",
    code: "IE",
    value: "+353",
    mask: "(999)999-999",
    id: "84",
  },
  {
    title: "Isle of Man",
    icon: "🇮🇲",
    code: "IM",
    value: "+44",
    mask: "(9999)999999",
    id: "85",
  },
  {
    title: "Israel",
    icon: "🇮🇱",
    code: "IL",
    value: "+972",
    mask: ["9-999-9999", "59-999-9999"],
    id: "86",
  },
  {
    title: "Italy",
    icon: "🇮🇹",
    code: "IT",
    value: "+39",
    mask: "(999)9999-999",
    id: "87",
  },
  {
    title: "Jamaica",
    icon: "🇯🇲",
    code: "JM",
    value: "+1876",
    mask: "(876)999-9999",
    id: "88",
  },
  {
    title: "Japan",
    icon: "🇯🇵",
    code: "JP",
    value: "+81",
    mask: ["(999)999-999", "99-9999-9999"],
    id: "89",
  },
  {
    title: "Jersey",
    icon: "🇯🇪",
    code: "JE",
    value: "+44",
    mask: "(9999)9999-999999",
    id: "90",
  },
  {
    title: "Jordan",
    icon: "🇯🇴",
    code: "JO",
    value: "+962",
    mask: "9-9999-9999",
    id: "91",
  },
  {
    title: "Kazakhstan",
    icon: "🇰🇿",
    code: "KZ",
    value: "+7",
    mask: ["(699)999-99-99", "(799)999-99-99"],
    id: "92",
  },
  {
    title: "Kiribati",
    icon: "🇰🇮",
    code: "KI",
    value: "+686",
    mask: "99-999",
    id: "93",
  },
  {
    title: "Korea, Democratic People's Republic of Korea",
    icon: "🇰🇵",
    code: "KP",
    value: "+850",
    mask: [
      "999-999",
      "9999-9999",
      "99-999-999",
      "999-9999-999",
      "191-999-9999",
      "9999-9999999999999",
    ],
    id: "94",
  },
  {
    title: "Korea, Republic of South Korea",
    icon: "🇰🇷",
    code: "KR",
    value: "+82",
    mask: "99-999-9999",
    id: "95",
  },
  {
    title: "Kosovo",
    icon: "🇽🇰",
    code: "XK",
    value: "+383",
    mask: ["99-999-999", "999-999-999"],
    id: "96",
  },
  {
    title: "Kuwait",
    icon: "🇰🇼",
    code: "KW",
    value: "+965",
    mask: "9999-9999",
    id: "97",
  },
  {
    title: "Kyrgyzstan",
    icon: "🇰🇬",
    code: "KG",
    value: "+996",
    mask: "(999)999-999",
    id: "98",
  },
  {
    title: "Laos",
    icon: "🇱🇦",
    code: "LA",
    value: "+856",
    mask: ["99-999-999", "(2099)999-999"],
    id: "99",
  },
  {
    title: "Latvia",
    icon: "🇱🇻",
    code: "LV",
    value: "+371",
    mask: "99-999-999",
    id: "100",
  },
  {
    title: "Lebanon",
    icon: "🇱🇧",
    code: "LB",
    value: "+961",
    mask: ["9-999-999", "99-999-999"],
    id: "101",
  },
  {
    title: "Lesotho",
    icon: "🇱🇸",
    code: "LS",
    value: "+266",
    mask: "9-999-9999",
    id: "102",
  },
  {
    title: "Liberia",
    icon: "🇱🇷",
    code: "LR",
    value: "+231",
    mask: "99-999-999",
    id: "103",
  },
  {
    title: "Lithuania",
    icon: "🇱🇹",
    code: "LT",
    value: "+370",
    mask: "(999)99-999",
    id: "104",
  },
  {
    title: "Luxembourg",
    icon: "🇱🇺",
    code: "LU",
    value: "+352",
    mask: "(999)999-999",
    id: "105",
  },
  {
    title: "Madagascar",
    icon: "🇲🇬",
    code: "MG",
    value: "+261",
    mask: "99-99-99999",
    id: "106",
  },
  {
    title: "Malawi",
    icon: "🇲🇼",
    code: "MW",
    value: "+265",
    mask: ["1-999-999", "9-9999-9999"],
    id: "107",
  },
  {
    title: "Malaysia",
    icon: "🇲🇾",
    code: "MY",
    value: "+60",
    mask: ["9-999-999", "99-999-999", "(999)999-999", "99-999-9999"],
    id: "108",
  },
  {
    title: "Maldives",
    icon: "🇲🇻",
    code: "MV",
    value: "+960",
    mask: "999-9999",
    id: "109",
  },
  {
    title: "Mali",
    icon: "🇲🇱",
    code: "ML",
    value: "+223",
    mask: "99-99-9999",
    id: "110",
  },
  {
    title: "Malta",
    icon: "🇲🇹",
    code: "MT",
    value: "+356",
    mask: "9999-9999",
    id: "111",
  },
  {
    title: "Marshall Islands",
    icon: "🇲🇭",
    code: "MH",
    value: "+692",
    mask: "999-9999",
    id: "112",
  },
  {
    title: "Martinique",
    icon: "🇲🇶",
    code: "MQ",
    value: "+596",
    mask: "(999)99-99-99",
    id: "113",
  },
  {
    title: "Mauritania",
    icon: "🇲🇷",
    code: "MR",
    value: "+222",
    mask: "99-99-9999",
    id: "114",
  },
  {
    title: "Mauritius",
    icon: "🇲🇺",
    code: "MU",
    value: "+230",
    mask: "999-9999",
    id: "115",
  },
  {
    title: "Mayotte",
    icon: "🇾🇹",
    code: "YT",
    value: "+262",
    mask: "99999-9999",
    id: "116",
  },
  {
    title: "Mexico",
    icon: "🇲🇽",
    code: "MX",
    value: "+52",
    mask: ["99-99-9999", "(999)999-9999"],
    id: "117",
  },
  {
    title: "Micronesia, Federated States of Micronesia",
    icon: "🇫🇲",
    code: "FM",
    value: "+691",
    mask: "999-9999",
    id: "118",
  },
  {
    title: "Moldova",
    icon: "🇲🇩",
    code: "MD",
    value: "+373",
    mask: "9999-9999",
    id: "119",
  },
  {
    title: "Monaco",
    icon: "🇲🇨",
    code: "MC",
    value: "+377",
    mask: ["99-999-999", "(999)999-999"],
    id: "120",
  },
  {
    title: "Mongolia",
    icon: "🇲🇳",
    code: "MN",
    value: "+976",
    mask: "99-99-9999",
    id: "121",
  },
  {
    title: "Montserrat",
    icon: "🇲🇸",
    code: "MS",
    value: "+1664",
    mask: "(664)999-9999",
    id: "122",
  },
  {
    title: "Morocco",
    icon: "🇲🇦",
    code: "MA",
    value: "+212",
    mask: "99-9999-999",
    id: "123",
  },
  {
    title: "Mozambique",
    icon: "🇲🇿",
    code: "MZ",
    value: "+258",
    mask: "99-999-999",
    id: "124",
  },
  {
    title: "Myanmar",
    icon: "🇲🇲",
    code: "MM",
    value: "+95",
    mask: ["999-999", "9-999-999", "99-999-999"],
    id: "125",
  },
  {
    title: "Namibia",
    icon: "🇳🇦",
    code: "NA",
    value: "+264",
    mask: "99-999-9999",
    id: "126",
  },
  {
    title: "Nauru",
    icon: "🇳🇷",
    code: "NR",
    value: "+674",
    mask: "999-9999",
    id: "127",
  },
  {
    title: "Nepal",
    icon: "🇳🇵",
    code: "NP",
    value: "+977",
    mask: "99-999-999",
    id: "128",
  },
  {
    title: "Netherlands",
    icon: "🇳🇱",
    code: "NL",
    value: "+31",
    mask: "99-999-9999",
    id: "129",
  },
  {
    title: "New Caledonia",
    icon: "🇳🇨",
    code: "NC",
    value: "+687",
    mask: "99-9999",
    id: "130",
  },
  {
    title: "New Zealand",
    icon: "🇳🇿",
    code: "NZ",
    value: "+64",
    mask: ["9-999-999", "(999)999-999", "(999)999-9999"],
    id: "131",
  },
  {
    title: "Nicaragua",
    icon: "🇳🇮",
    code: "NI",
    value: "+505",
    mask: "9999-9999",
    id: "132",
  },
  {
    title: "Niger",
    icon: "🇳🇪",
    code: "NE",
    value: "+227",
    mask: "99-99-9999",
    id: "133",
  },
  {
    title: "Nigeria",
    icon: "🇳🇬",
    code: "NG",
    value: "+234",
    mask: ["99-999-99", "99-999-999", "(999)999-9999"],
    id: "134",
  },
  {
    title: "Niue",
    icon: "🇳🇺",
    code: "NU",
    value: "+683",
    mask: "9999",
    id: "135",
  },
  {
    title: "Norfolk Island",
    icon: "🇳🇫",
    code: "NF",
    value: "+672",
    mask: "399-999",
    id: "136",
  },
  {
    title: "Northern Mariana Islands",
    icon: "🇲🇵",
    code: "MP",
    value: "+1670",
    mask: "(670)999-9999",
    id: "137",
  },
  {
    title: "Norway",
    icon: "🇳🇴",
    code: "NO",
    value: "+47",
    mask: "(999)99-999",
    id: "138",
  },
  {
    title: "Oman",
    icon: "🇴🇲",
    code: "OM",
    value: "+968",
    mask: "99-999-999",
    id: "139",
  },
  {
    title: "Pakistan",
    icon: "🇵🇰",
    code: "PK",
    value: "+92",
    mask: "(999)999-9999",
    id: "140",
  },
  {
    title: "Palau",
    icon: "🇵🇼",
    code: "PW",
    value: "+680",
    mask: "999-9999",
    id: "141",
  },
  {
    title: "Panama",
    icon: "🇵🇦",
    code: "PA",
    value: "+507",
    mask: "999-9999",
    id: "142",
  },
  {
    title: "Papua New Guinea",
    icon: "🇵🇬",
    code: "PG",
    value: "+675",
    mask: "(999)99-999",
    id: "143",
  },
  {
    title: "Paraguay",
    icon: "🇵🇾",
    code: "PY",
    value: "+595",
    mask: "(999)999-999",
    id: "144",
  },
  {
    title: "Peru",
    icon: "🇵🇪",
    code: "PE",
    value: "+51",
    mask: "(999)999-999",
    id: "145",
  },
  {
    title: "Philippines",
    icon: "🇵🇭",
    code: "PH",
    value: "+63",
    mask: "(999)999-9999",
    id: "146",
  },
  {
    title: "Poland",
    icon: "🇵🇱",
    code: "PL",
    value: "+48",
    mask: "(999)999-999",
    id: "147",
  },
  {
    title: "Portugal",
    icon: "🇵🇹",
    code: "PT",
    value: "+351",
    mask: "99-999-9999",
    id: "148",
  },
  {
    title: "Puerto Rico",
    icon: "🇵🇷",
    code: "PR",
    value: "+1939",
    mask: ["(787) 999 9999", "(939) 999 9999"],
    id: "149",
  },
  {
    title: "Qatar",
    icon: "🇶🇦",
    code: "QA",
    value: "+974",
    mask: "9999-9999",
    id: "150",
  },
  {
    title: "Romania",
    icon: "🇷🇴",
    code: "RO",
    value: "+40",
    mask: "99-999-9999",
    id: "151",
  },
  {
    title: "Russia",
    icon: "🇷🇺",
    code: "RU",
    value: "+7",
    mask: "(999)999-99-99",
    id: "152",
  },
  {
    title: "Rwanda",
    icon: "🇷🇼",
    code: "RW",
    value: "+250",
    mask: "(999)999-999",
    id: "153",
  },
  {
    title: "Saint Barthelemy",
    icon: "🇧🇱",
    code: "BL",
    value: "+590",
    mask: "999-99-99-99",
    id: "154",
  },
  {
    title: "Saint Kitts and Nevis",
    icon: "🇰🇳",
    code: "KN",
    value: "+1869",
    mask: "(869)999-9999",
    id: "155",
  },
  {
    title: "Saint Lucia",
    icon: "🇱🇨",
    code: "LC",
    value: "+1758",
    mask: "(758)999-9999",
    id: "156",
  },
  {
    title: "Saint Martin",
    icon: "🇲🇫",
    code: "MF",
    value: "+590",
    mask: "(999)999-999",
    id: "157",
  },
  {
    title: "Saint Pierre and Miquelon",
    icon: "🇵🇲",
    code: "PM",
    value: "+508",
    mask: "99-9999",
    id: "158",
  },
  {
    title: "Samoa",
    icon: "🇼🇸",
    code: "WS",
    value: "+685",
    mask: "99-9999",
    id: "159",
  },
  {
    title: "San Marino",
    icon: "🇸🇲",
    code: "SM",
    value: "+378",
    mask: "9999-999999",
    id: "160",
  },
  {
    title: "Sao Tome and Principe",
    icon: "🇸🇹",
    code: "ST",
    value: "+239",
    mask: "99-99999",
    id: "161",
  },
  {
    title: "Saudi Arabia",
    icon: "🇸🇦",
    code: "SA",
    value: "+966",
    mask: ["99-999-9999", "59-9999-9999"],
    id: "162",
  },
  {
    title: "Serbia",
    icon: "🇷🇸",
    code: "RS",
    value: "+381",
    mask: "99-999-9999",
    id: "163",
  },
  {
    title: "Seychelles",
    icon: "🇸🇨",
    code: "SC",
    value: "+248",
    mask: "9-999-999",
    id: "164",
  },
  {
    title: "Sierra Leone",
    icon: "🇸🇱",
    code: "SL",
    value: "+232",
    mask: "99-999999",
    id: "165",
  },
  {
    title: "Singapore",
    icon: "🇸🇬",
    code: "SG",
    value: "+65",
    mask: "9999-9999",
    id: "166",
  },
  {
    title: "Slovakia",
    icon: "🇸🇰",
    code: "SK",
    value: "+421",
    mask: "(999)999-999",
    id: "167",
  },
  {
    title: "Solomon Islands",
    icon: "🇸🇧",
    code: "SB",
    value: "+677",
    mask: ["99999", "999-9999"],
    id: "168",
  },
  {
    title: "Somalia",
    icon: "🇸🇴",
    code: "SO",
    value: "+252",
    mask: ["9-999-999", "99-999-999"],
    id: "169",
  },
  {
    title: "South Africa",
    icon: "🇿🇦",
    code: "ZA",
    value: "+27",
    mask: "99-999-9999",
    id: "170",
  },
  {
    title: "South Sudan",
    icon: "🇸🇸",
    code: "SS",
    value: "+211",
    mask: "99-999-9999",
    id: "171",
  },
  {
    title: "Spain",
    icon: "🇪🇸",
    code: "ES",
    value: "+34",
    mask: "(999)999-999",
    id: "172",
  },
  {
    title: "Sri Lanka",
    icon: "🇱🇰",
    code: "LK",
    value: "+94",
    mask: "99-999-9999",
    id: "173",
  },
  {
    title: "Sudan",
    icon: "🇸🇩",
    code: "SD",
    value: "+249",
    mask: "99-999-9999",
    id: "174",
  },
  {
    title: "Suriname",
    icon: "🇸🇷",
    code: "SR",
    value: "+597",
    mask: ["999-999", "999-9999"],
    id: "175",
  },
  {
    title: "Switzerland",
    icon: "🇨🇭",
    code: "CH",
    value: "+41",
    mask: "99-999-9999",
    id: "176",
  },
  {
    title: "Syrian Arab Republic",
    icon: "🇸🇾",
    code: "SY",
    value: "+963",
    mask: "99-9999-999",
    id: "177",
  },
  {
    title: "Taiwan",
    icon: "🇹🇼",
    code: "TW",
    value: "+886",
    mask: ["9999-9999", "9-9999-9999"],
    id: "178",
  },
  {
    title: "Tajikistan",
    icon: "🇹🇯",
    code: "TJ",
    value: "+992",
    mask: "99-999-9999",
    id: "179",
  },
  {
    title: "Tanzania, United Republic of Tanzania",
    icon: "🇹🇿",
    code: "TZ",
    value: "+255",
    mask: "99-999-9999",
    id: "180",
  },
  {
    title: "Thailand",
    icon: "🇹🇭",
    code: "TH",
    value: "+66",
    mask: ["99-999-999", "99-999-9999"],
    id: "181",
  },
  {
    title: "Timor-Leste",
    icon: "🇹🇱",
    code: "TL",
    value: "+670",
    mask: ["999-9999", "779-99999", "789-99999"],
    id: "182",
  },
  {
    title: "Togo",
    icon: "🇹🇬",
    code: "TG",
    value: "+228",
    mask: "99-999-999",
    id: "183",
  },
  {
    title: "Tokelau",
    icon: "🇹🇰",
    code: "TK",
    value: "+690",
    mask: "9999",
    id: "184",
  },
  {
    title: "Tonga",
    icon: "🇹🇴",
    code: "TO",
    value: "+676",
    mask: "99999",
    id: "185",
  },
  {
    title: "Trinidad and Tobago",
    icon: "🇹🇹",
    code: "TT",
    value: "+1868",
    mask: "(868)999-9999",
    id: "186",
  },
  {
    title: "Tunisia",
    icon: "🇹🇳",
    code: "TN",
    value: "+216",
    mask: "99-999-999",
    id: "187",
  },
  {
    title: "Turkey",
    icon: "🇹🇷",
    code: "TR",
    value: "+90",
    mask: "(999)999-9999",
    id: "188",
  },
  {
    title: "Turks and Caicos Islands",
    icon: "🇹🇨",
    code: "TC",
    value: "+1649",
    mask: "(249)999-999",
    id: "189",
  },
  {
    title: "Tuvalu",
    icon: "🇹🇻",
    code: "TV",
    value: "+688",
    mask: ["29999", "909999"],
    id: "190",
  },
  {
    title: "Uganda",
    icon: "🇺🇬",
    code: "UG",
    value: "+256",
    mask: "(999)999-999",
    id: "191",
  },
  {
    title: "Ukraine",
    icon: "🇺🇦",
    code: "UA",
    value: "+380",
    mask: "(99)999-99-99",
    id: "192",
  },
  {
    title: "United Arab Emirates",
    icon: "🇦🇪",
    code: "AE",
    value: "+971",
    mask: "59-999-9999",
    id: "193",
  },
  {
    title: "United Kingdom",
    icon: "🇬🇧",
    code: "GB",
    value: "+44",
    mask: "99-9999-9999",
    id: "194",
  },
  {
    title: "United States",
    icon: "🇺🇸",
    code: "US",
    value: "+1",
    mask: "(999)999-9999",
    id: "195",
  },
  {
    title: "Uruguay",
    icon: "🇺🇾",
    code: "UY",
    value: "+598",
    mask: "9-999-99-99",
    id: "196",
  },
  {
    title: "Uzbekistan",
    icon: "🇺🇿",
    code: "UZ",
    value: "+998",
    mask: "99-999-9999",
    id: "197",
  },
  {
    title: "Vanuatu",
    icon: "🇻🇺",
    code: "VU",
    value: "+678",
    mask: ["99999", "99-99999"],
    id: "198",
  },
  {
    title: "Vietnam",
    icon: "🇻🇳",
    code: "VN",
    value: "+84",
    mask: ["99-9999-999", "(999)9999-999"],
    id: "199",
  },
  {
    title: "Wallis and Futuna",
    icon: "🇼🇫",
    code: "WF",
    value: "+681",
    mask: "99-9999",
    id: "200",
  },
  {
    title: "Zambia",
    icon: "🇿🇲",
    code: "ZM",
    value: "+260",
    mask: "99-999-9999",
    id: "201",
  },
  {
    title: "Zimbabwe",
    icon: "🇿🇼",
    code: "ZW",
    value: "+263",
    mask: "9-999999",
    id: "202",
  },
  {
    title: "Denmark",
    icon: "🇩🇰",
    code: "DK",
    value: "+45",
    mask: "99-99-99-99",
    id: "203",
  },
];
