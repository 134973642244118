import React, { useEffect, useRef } from "react";
import { MessageBox } from "react-chat-elements";
import DayTag from "../day-tag";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import actionTypes from "../../../../store/action-types";
import { chatSocket } from "../../../../config/socket";
import moment from "moment-timezone";

const MessageCard = ({
  data = [],
  userId,
  sender,
  receiver,
  conversationId,
}) => {
  const messageListReferance = useRef(null);
  const dispatch = useDispatch();
  const { onScrollToBottom } = useSelector((state) => state.chatReducer);

  useEffect(() => {
    let mount = true;

    if (mount) {
      return () => {
        const leave_room = `chat_${conversationId}`;
        chatSocket.emit("leave", { room: leave_room, user_id: userId });
        mount = false;
      };
    }
  }, [conversationId, userId]);

  useEffect(() => {
    if (onScrollToBottom && messageListReferance.current) {
      dispatch({ type: actionTypes.SCROLL_TO_BOTTOM, payload: false });

      messageListReferance.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [data, dispatch, messageListReferance, onScrollToBottom]);

  const groupedDays = (messages) => {
    return messages.reduce((acc, el, i) => {
      const messageDay = moment(el.created_at).format("YYYY-MM-DD");
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  };

  const generateItems = (messages) => {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
      (x, y) => moment(y, "YYYY-MM-DD").unix() - moment(x, "YYYY-MM-DD").unix()
    );
    const items = sortedDays.reduce((acc, date) => {
      const sortedMessages = days[date].sort(
        (x, y) => new Date(y.created_at) - new Date(x.created_at)
      );
      return acc.concat([...sortedMessages, { type: "day", date, id: date }]);
    }, []);
    return items;
  };

  const isCurrentUser = useCallback(
    (senderId) => {
      if (senderId === userId) return true;
      return false;
    },
    [userId]
  );

  const getTagTitle = useCallback((date) => {
    const today = moment();
    const otherDate = moment(date);
    if (today.isSame(otherDate, "day")) {
      return "Today";
    } else if (today.isSame(otherDate.clone().add(1, "day"), "day")) {
      return "Yesterday";
    } else if (today.isBefore(otherDate.clone().subtract(1, "year"), "day")) {
      return `${moment(date).format("DD MMMM YYYY")}`;
    } else {
      return `${moment(date).format("ddd, MMM DD")}`;
    }
  }, []);

  return (
    <>
      {generateItems(data)
        .reverse()
        .map((item) => {
          if (item.type && item.type === "day") {
            return <DayTag key={item.id} title={getTagTitle(item.date)} />;
          }

          return (
            <div ref={messageListReferance} key={item.id}>
              <MessageBox
                key={item.id}
                id={item.id.toString()}
                date={new Date(item.created_at)}
                title={isCurrentUser(item.sender_id) ? sender : receiver}
                forwarded={false}
                notch={false}
                retracted={false}
                status={"sent"}
                className={isCurrentUser(item.sender_id) ? "" : "myMessage"}
                titleColor={isCurrentUser(item.sender_id) ? "#1890ff" : ""}
                position={isCurrentUser(item.sender_id) ? "right" : "left"}
                type={"text"}
                text={item.message_text}
              />
            </div>
          );
        })}
    </>
  );
};

export default React.memo(MessageCard);
