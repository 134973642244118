import React from "react";

import { Button, Space } from "antd";

import Text from "src/modules/parent/components/text";
import ModalComponent from "src/modules/parent/components/modal";
import InfoImg from "src/modules/parent/assets/notificationSvgs/info.svg";

import styles from "../styles.module.css";

const AccountInUse = ({
  show = true,
  setShow = () => {},
  handleForgotPassword = () => {},
}) => (
  <ModalComponent
    onCancel={() => setShow(false)}
    closable={false}
    isModalVisible={show}
  >
    <Space
      className={"w-100 mt-20"}
      size={"large"}
      align={"center"}
      direction={"vertical"}
    >
      <Text
        size={"XXL"}
        className={"mb-20"}
        center
        font={"SEMIBOLD"}
        title={"Account in Use"}
      />

      <img src={InfoImg} width={100} alt={"Success"} />

      <div className="text-align-center">
        <div>{"An account already exists with this email address."}</div>
        <div>
          {
            "Please use the forgot password option to generate a new password or contact"
          }
        </div>
        <div>
          <span className="link-tag">
            studentsuccess@dotandlinelearning.com
          </span>{" "}
          / <span className="link-tag">+923474000862</span> for assistance.
        </div>
      </div>

      <Button onClick={handleForgotPassword} className={styles.forgotButton}>
        {"Forgot Password"}
      </Button>
    </Space>
  </ModalComponent>
);

export default AccountInUse;
