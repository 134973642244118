import React from "react";

import { Row, Space } from "antd";
import { isMobile } from "react-device-detect";
import moment from "moment-timezone";

import Text from "src/modules/parent/components/text";
import FlatButton from "src/modules/parent/components/flat-button";
import pdfFile from "src/modules/parent/assets/img/pdf-file.png";
import eyeIcon from "../../../assets/svgs/eye.svg";

import styles from "./styles.module.css";

const Card = ({
  name,
  picture,
  type,
  created_at,
  link,
  filepath,
  handleView = () => {},
  handleClick = () => {},
}) => (
  <div className="white_box p-20">
    <Row>
      <Space size={"large"}>
        <div className={styles.teacherImage} style={{ width: 71, height: 71 }}>
          <img src={picture} alt={"teacherImage"} />
        </div>

        <Row justify={"center"}>
          <Space direction={"vertical"}>
            <Text
              color={"rgba(0, 94, 255, 1)"}
              size={"M2"}
              lHeight={"XS"}
              handleClick={handleClick}
              className={"cursor-pointer text-underline"}
              font="SEMIBOLD"
            >
              {name}
            </Text>

            <Text color={"rgba(153, 153, 153, 1)"} lHeight={"S"} size={"XS"}>
              {`Posted on ${moment(created_at).format("DD MMM YYYY, hh:mm a")}`}
            </Text>
          </Space>
        </Row>
      </Space>
    </Row>

    <Text
      color={"rgba(85, 85, 85, 1)"}
      className={"mt-30"}
      size={"M2"}
      font="SEMIBOLD"
    >
      {type}
    </Text>

    <Row
      gutter={[0, 20]}
      justify={isMobile ? "start" : "space-between"}
      align={"bottom"}
      className={"mt-10"}
    >
      <div className={styles.rightIcon}>
        <div className={styles.pdfIcon}>
          <img
            src={pdfFile}
            alt={"pdfIcon"}
            style={{ width: "50px", height: "50px" }}
          />
        </div>

        <Row className={styles.name}>
          <Space direction={"vertical"}>
            <Text color={"rgba(0, 94, 255, 1)"} size={"S"} font="SEMIBOLD">
              {type}
            </Text>

            <Text color={"rgba(153, 153, 153, 1)"} size={"S"}>
              {"PDF"}
            </Text>
          </Space>
        </Row>
      </div>

      <FlatButton
        type={"Secondary"}
        height={33}
        width={isMobile ? "100%" : 110}
        preIcon={<img src={eyeIcon} alt="viewIcon" />}
        className={styles.joinClassBtn}
        title={"View"}
        onClick={() => handleView({ type, link: link ? link : filepath })}
      />
    </Row>
  </div>
);

export default React.memo(Card);
