import React from "react";
import styles from "../../styles.module.css";
import { noPackageCoverImage } from "src/modules/parent/utils";

const PackageInfo = ({ title, name, image }) => (
  <div className={styles.bg_cover}>
    <div className={styles.bg_cover_overlay1} />
    <div className={styles.bg_cover_overlay2} />

    <div className={styles.bg_cover_overlay3}>
      <p className={styles.p_name}>{title}</p>
      <p className={styles.t_name}>
        <span className="font-weight-300">By</span> {name}
      </p>
    </div>

    <img src={image || noPackageCoverImage} alt="Cover" />
  </div>
);

export default React.memo(PackageInfo);
