import React, { useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, message } from "antd";
import FlatButton from "../../components/flat-button";
import gif from "../../assets/gif/7.gif";
import logo from "../../assets/img/dotlinelogowithname.png";
import * as actions from "../../store/actions";
import CardsWrapper from "src/modules/parent/components/cards-wrapper";
import TextInput from "src/modules/parent/components/text-input";
import { error, validateEmail } from "../../utils";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = bindActionCreators(actions, dispatch);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);

  const isValidated = () => {
    if (!email) {
      error("Please enter your email first!");
      setIsError(true);
      return false;
    } else if (!validateEmail(email)) {
      error("Please enter a valid email!");
      setIsError(true);
      return false;
    }
    return true;
  };

  const submitHandler = async () => {
    if (isValidated()) {
      setLoading(true);
      const data = { email };
      action
        .resetPassword(data)
        .then((response) => {
          setLoading(false);
          message.success(response);
          navigate("/login");
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div className={"container auth-form"}>
      <div className="inner-container w-100">
        <div className="logo-div">
          <Link to={"/"}>
            <img src={logo} alt={"brand-logo"} />
          </Link>
        </div>
        <CardsWrapper>
          <Fragment>
            <div className={"form-wrapper"}>
              <div className={"header-text"}>Reset Password</div>
              <p className="white-color text-align-center mb-40 font-weight-300">
                Please type your registered email, we will <br /> send a new
                password on that email.
              </p>
              <Form className="text-align-center">
                <Form.Item
                  hasFeedback={email.length < 1 && isError ? true : false}
                  validateStatus={
                    email.length < 1 && isError ? "error" : "success"
                  }
                >
                  <TextInput
                    required
                    allowClear
                    value={email}
                    label={"Email"}
                    placeholder={"Enter Email"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <div>
                  <FlatButton
                    isForm
                    width={"200px"}
                    title={"Send Email"}
                    loading={loading}
                    onClick={submitHandler}
                    className={"mt-20 mb-10"}
                  />
                </div>
                <div className="white-color mb-30">
                  Back to{" "}
                  <Link to={"/login"} className={"link-text"}>
                    Login
                  </Link>
                </div>
              </Form>
            </div>
            <div className={"image-wrapper"}>
              <img src={gif} alt="GIF" />
            </div>
          </Fragment>
        </CardsWrapper>
      </div>
    </div>
  );
};

export default ResetPassword;
