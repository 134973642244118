import React, { useState, useCallback, useEffect, useRef } from "react";

import { Row, Col, Button, message } from "antd";
import { isBrowser, isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import YouTube from "react-youtube";
import TiktokPixel from "tiktok-pixel";
import { logEvent } from "firebase/analytics";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import slugify from "@sindresorhus/slugify";
import { parsePhoneNumberWithError } from "libphonenumber-js";

import {
  BannerComponent,
  TabComponent,
  CourseFilter,
  Testimonials,
  FloatingButton,
  Form,
} from "./components";
import SuccessModal from "./components/modal/success";
import { analytics } from "src/modules/parent/config/firebase";
import ModalComponent from "src/modules/parent/components/modal";
import BlogComponent from "src/modules/parent/pages/blogs/components/blogComponent";
import OurPartners from "src/modules/parent/components/partners";
import ContactModal from "src/modules/parent/components/modal/contactModal";
import Footer from "src/modules/parent/components/footer";
import Header from "src/modules/parent/components/header";
import { tabData, testimonials } from "./staticData";
import Text from "src/modules/parent/components/text";
import achivements from "./assets/webp/achivements.png";
import {
  epContactBtnWhatsapp,
  examPrepBookDemoHero,
  trackEvents,
} from "src/modules/parent/utils/events/query";
import events from "src/modules/parent/utils/events";
import {
  phoneNumberNotification,
  phoneValidateNotification,
} from "src/modules/parent/utils/notificationData";

import {
  tiktok_pixel_id,
  fb_pixel_id_olevel,
  IS_DEV_ENV,
  fb_pixel_id_examPrep,
} from "src/modules/parent/config/constant";
import YoutubeModal from "../../components/modal/youtube-modal";
import { examPrepLinks } from "../../utils/links";
import img1 from "./assets/webp/img1.png";
import img2 from "./assets/webp/img2.png";
import ExamPrepModal from "../../components/modal/examPrepModal";

import * as actions from "src/modules/parent/store/actions";

import styles from "./styles.module.css";
import actionTypes from "../../store/action-types";

TiktokPixel.init(tiktok_pixel_id);
ReactPixel.init(fb_pixel_id_olevel);
ReactPixel.init(fb_pixel_id_examPrep);

const whatsappMobile = "https://wa.me/";
const influencerPageView = events.google.examPrep.influencerPageView;
const submitFormBadar = events.facebook.examPrep.submitFormBadar;

const opts2 = {
  height: window?.innerWidth < 991 ? 250 : 520,
  width: "100%",
  playerVars: {
    autoplay: 0,
    rel: 0,
  },
};

const OLevels = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { keywordList } = useSelector((state) => state.subjectReducer);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [teachers, setTeachers] = React.useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [data, setData] = useState({});
  const [blogData, setBlogData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [params] = useSearchParams();
  const isInfluencer = params.get("influencer");
  const playerRef = React.useRef(null);
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);

  useEffect(() => {
    onSubmit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInfluencer) {
      localStorage.setItem("influencer_exam_prep", isInfluencer);

      if (!IS_DEV_ENV) {
        logEvent(analytics, influencerPageView, {
          details: isInfluencer,
        });
      }
    }
  }, [isInfluencer]);

  useEffect(() => {
    actions.getPostsOlevel().then((postData) => {
      setBlogData(postData);
    });
  }, []);

  const onSubmit = useCallback(() => {
    setIsLoading(true);

    actions
      .getOlevelTeachers({})
      .then((response) => {
        if (response && response.length === 0) return;

        setTeachers(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  };

  const handleWhatsApp = () => {
    window.open(`${whatsappMobile}923460086793`);
  };

  const setModalAndMedia = (videoId) => {
    setShowModal(true);
    setModalData({ url: videoId });
  };

  const navigateToDetails = (e, title, id) => {
    const slugTitle = title ? slugify(title) : "";
    const route = `/courses/${slugTitle}/${id}`;

    navigate(route);
  };

  const handleClickContact = useCallback(() => {
    trackEvents(epContactBtnWhatsapp);

    if (!isBrowser) handleWhatsApp();
    else setShowContactModal(true);
  }, []);

  const handleClick = (key) => {
    switch (key) {
      case "Live Classes":
        handleShowAssistMe();
        break;

      case "Experienced Instructors":
        handleShowAssistMe();
        break;

      case "Past Paper Revision":
        handleShowAssistMe();
        break;

      case "Complete Resources":
        handleShowAssistMe();
        break;

      default:
        break;
    }
  };

  const onHandleClose = () => {
    setShowConfirmModal(false);
    navigate("/exam-preparation", {
      state: {
        notFound: true,
      },
    });
  };

  const handleChange = useCallback((key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const handleShowAssistMe = useCallback(() => {
    setShow(true);
  }, []);

  const handleFallBack = useCallback(({ ...formData }) => {
    setData(formData);
    setFormModal(true);
  }, []);

  const saveToDB = useCallback(
    (number) => {
      setFormLoading(true);

      actions
        .addFormFields({ ...data, phone: number })
        .then((response) => {
          setFormLoading(false);
          ReactPixel.trackSingle(fb_pixel_id_examPrep, submitFormBadar);
          setData({});
          setFormModal(false);
          setShowConfirmModal(true);
        })
        .catch((error) => {
          setFormLoading(false);
          setData({});
        });
    },
    [data]
  );

  const handleSubmit = useCallback(() => {
    const { parent_name, board, grade, subject, phone } = data;

    if (!parent_name || !board || !grade || !subject || !phone) {
      return message.error("Please fill all required fields!");
    }

    const phoneNumber = parsePhoneNumberWithError(phone);

    if (phoneNumber.isValid()) {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: phoneValidateNotification(phoneNumber?.number, saveToDB),
      });
    } else {
      return dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: phoneNumberNotification,
      });
    }
  }, [data, dispatch, saveToDB]);

  const handleBookADemo = () => {
    trackEvents(examPrepBookDemoHero);
    handleShowAssistMe();
  };

  return (
    <div>
      {modalData && showModal && (
        <YoutubeModal
          show={showModal}
          url={modalData.url}
          setShow={setShowModal}
        />
      )}

      <Header />

      <BannerComponent handleBookDemo={handleBookADemo} />

      <div className={"mb-30 mt-20 px-50"}>
        <TabComponent data={tabData} handleClick={handleClick} />
      </div>

      <div ref={myRef1} style={{ textAlign: "center" }}>
        <Text
          size={"XXL"}
          center
          color={"rgba(44, 44, 44, 1)"}
          font={"SEMIBOLD"}
          lHeight={"S"}
          className={"mb-20"}
          title={"Our Teachers"}
        />

        <span className={styles.certifiedText}>
          50+ Dot & Line tutors are <span>internationally certified</span> and{" "}
          <span>subject experts</span> with <span>decades of experience</span>
        </span>
      </div>

      <div className="app-container mt-20">
        <CourseFilter
          data={teachers}
          isButtonDisabled
          isLoading={isLoading}
          isCarousel
          handleProfileVideo={setModalAndMedia}
          viewDetails={navigateToDetails}
        />
      </div>

      <Row className="mt-20" justify={"center"}>
        <Button onClick={handleShowAssistMe} className={styles.demoButton}>
          {"Book a Free Demo"}
        </Button>
      </Row>

      <img
        src={isMobile ? img2 : img1}
        style={{ width: "100%", marginTop: "20px" }}
        alt={"pearson"}
      />

      <div className="app-container">
        <Row justify={"center"}>
          <Text
            size={"XXL"}
            center
            color={"rgba(44, 44, 44, 1)"}
            font={"SEMIBOLD"}
            lHeight={"S"}
            className={"mb-20 mt-20"}
            title={"Our Achievements"}
          />
        </Row>

        <Row justify={"center"}>
          <Text
            size={"M"}
            center
            color={"#2C2C2C"}
            lHeight={"S"}
            className={"mb-20"}
            title={
              "We have delivered countless hours of quality education, consistently earning top ratings from our students. Our global presence reflects our commitment to excellence and our ability to reach learners in multiple countries."
            }
          />
        </Row>

        <Row justify={"center"}>
          <Col xxl={12} xl={12} lg={16} md={24} sm={24} xs={24}>
            <img
              src={achivements}
              className={styles.achivements}
              alt={"achivements"}
            />
          </Col>
        </Row>
      </div>

      <Row className="mt-20 mb-20" justify={"center"}>
        <Button onClick={handleShowAssistMe} className={styles.demoButton}>
          {"Book a Free Demo"}
        </Button>
      </Row>

      <div className={styles.hr} />

      <div className={"mb-20 mt-20"}>
        <Text
          size={"XXL"}
          center
          font={"SEMIBOLD"}
          lHeight={"S"}
          title={"Testimonials"}
        />

        <Testimonials data={testimonials} />
      </div>

      <div className={styles.hr} />

      <OurPartners />

      <Row className="mt-20" justify={"center"}>
        <Button onClick={handleShowAssistMe} className={styles.demoButton}>
          {"Book a Free Demo"}
        </Button>
      </Row>

      <div className={styles.hr} />

      <Text
        size={"XXL"}
        className={"mb-20 mt-20"}
        center
        font={"SEMIBOLD"}
        lHeight={"S"}
        title={"Live Class Snippets!"}
      />

      <Row gutter={[20, 20]} className={"mt-20 mb-20"} justify={"center"}>
        <div style={{ width: isBrowser ? "70%" : "90%", position: "relative" }}>
          <YouTube
            ref={playerRef}
            videoId={"KpjooUXhoVI"}
            opts={opts2}
            onReady={onPlayerReady}
            loading={"eager"}
          />

          <div
            className={styles.overlayClass}
            onClick={() => setModalAndMedia("KpjooUXhoVI")}
          />
        </div>
      </Row>

      <div ref={myRef2} className={styles.hrw} />

      {blogData && (
        <Col span={24}>
          <Text
            size={"XXL"}
            color={"rgba(0, 94, 255, 1)"}
            center
            font={"SEMIBOLD"}
            lHeight={"S"}
            title={"CAIE EXAM PREP RESOURCES"}
          />

          <Row justify={"center"}>
            <Text
              center
              size={"M"}
              font={"LIGHTER"}
              lHeight={"S"}
              className={"app-container mb-20 mt-20"}
              title={
                "Cambridge paper structure; Subject Guides, Exam notes and topic wise revision summaries. Explore below"
              }
            />
          </Row>

          <div
            className={[
              "app-container pt-20 pb-20 pr-20 pl-20 mb-50",
              styles.blogs,
            ].join(" ")}
          >
            {blogData && (
              <BlogComponent isOlevel showTitle={false} data={blogData} />
            )}
          </div>
        </Col>
      )}

      <Footer links={examPrepLinks} />

      <FloatingButton handlePress={handleClickContact} />

      <ContactModal
        show={showContactModal}
        title={"Contact Representative"}
        name={"Muhammad Taha Arif:"}
        color={"#2CA58D"}
        number={"+923460086793"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal(false)}
      />

      <ModalComponent
        maskClosable
        closable={false}
        isModalVisible={showConfirmModal}
        onCancel={onHandleClose}
        bodyClassName={"d-flex justify-content-center align-items-center"}
        Component={<SuccessModal onOk={onHandleClose} />}
      />

      <ExamPrepModal
        show={show}
        onHandleFallback={handleFallBack}
        handleClose={() => setShow(false)}
      />

      <Form
        setShow={setFormModal}
        keywordList={keywordList}
        data={data}
        isLoading={formLoading}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        show={formModal}
      />
    </div>
  );
};

export default OLevels;
