import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";

import { Row, Col } from "antd";
import FlatButton from "src/modules/parent/components/flat-button";
import AppLoader from "../../../../components/app-loader";
import arrow from "../../assets/svgs/arrow.svg";
import Carousel from "react-multi-carousel";
import slugify from "@sindresorhus/slugify";
import ReactPixel from "react-facebook-pixel";
import { fb_pixel_id_summer } from "src/modules/parent/config/constant";
import {
  crouselSettings,
  responsiveBlogs,
  responsiveForPackageCards,
} from "src/modules/parent/utils";
import { isMobile } from "react-device-detect";
import SelectInput from "src/modules/parent/components/select-input";
import { getShortCourseGrades } from "src/modules/parent/utils/static-data";
import styles from "./styles.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FreeClassCard from "src/modules/parent/components/free-class-card";
import CardItem from "src/modules/parent/pages/olevels/components/cardItem";
import {
  trackEvents,
  unAuthBookDemoTeacherCard,
  unAuthExploreMoreBtn,
} from "src/modules/parent/utils/events/query";

ReactPixel.init(fb_pixel_id_summer);

const PackageCard = lazy(() =>
  import("src/modules/parent/components/package-card")
);

const CourseCard = ({
  data,
  heading = "",
  button = "",
  buttonlink,
  type,
  onClick = () => {},
  isGradeEnabled = false,
  handleGradeChange = () => {},
  handleShowModal = () => {},
  handleBookDemo = () => {},
  handleChat = () => {},
  viewDetails = () => {},
}) => {
  const [grade, setGrade] = useState();

  useEffect(() => {
    if (grade) {
      handleGradeChange(grade);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grade]);

  const handleClick = useCallback(
    (item) => {
      const titleRoute = slugify(item.title);

      onClick(`/courses/${titleRoute}/${item?.id}`);
    },
    [onClick]
  );

  const handleCardClick = useCallback(
    (item) => {
      trackEvents(unAuthBookDemoTeacherCard);
      handleClick(item);
    },
    [handleClick]
  );

  const handleClickExploreMore = useCallback(
    (e) => {
      e.preventDefault();

      trackEvents(unAuthExploreMoreBtn);
      onClick(buttonlink);

      e.stopPropagation();
    },
    [buttonlink, onClick]
  );

  const CardItems = useCallback(
    ({ item }) => (
      <Suspense fallback={<AppLoader className="font-28" />}>
        {type === "course" ? (
          <>
            <PackageCard {...item} item={item} key={item?.id} />

            <Row justify={"center"} className="mb-5">
              <FlatButton
                title={"Book a Free Demo"}
                width={"96%"}
                height={"40px"}
                onClick={() => handleCardClick(item)}
                className={styles.bookAdemoBtn}
              />
            </Row>
          </>
        ) : type === "teacher" ? (
          <CardItem
            item={item}
            handleChat={handleChat}
            handleShowModal={handleShowModal}
            handleBookDemo={handleBookDemo}
            viewPackageDetailHandler={(e, title, id) => {
              e.preventDefault();
              viewDetails({ title, id });
              e.stopPropagation();
            }}
          />
        ) : (
          <FreeClassCard key={item.id} item={item} />
        )}
      </Suspense>
    ),
    [
      handleBookDemo,
      handleCardClick,
      handleChat,
      handleShowModal,
      type,
      viewDetails,
    ]
  );

  return (
    <Col span={24}>
      <Row
        gutter={[0, 10]}
        justify={"space-between"}
        align={"middle"}
        className={"mb-20 app-container"}
      >
        <div className={"titleHeading"}>{heading}</div>

        {isGradeEnabled && (
          <Col xxl={7} xl={7} lg={7} md={8} sm={24} xs={24}>
            <SelectInput
              allowClear
              className={"w-100"}
              placeholder={"Choose Grade Level"}
              value={grade}
              onChange={setGrade}
              options={getShortCourseGrades}
            />
          </Col>
        )}
      </Row>

      <div>
        {isMobile ? (
          <>
            {data && data.length > 0 ? (
              <Slider {...crouselSettings}>
                {data.map((item) => (
                  <CardItems item={item} key={item?.id} />
                ))}
              </Slider>
            ) : (
              <div className="w-100">
                <h2 className="f-sm text-align-center pt-50 mt-50 mb-50">
                  No result was found.
                </h2>
              </div>
            )}
          </>
        ) : (
          <div className={"app-container"}>
            {data && data.length > 0 ? (
              <Carousel
                responsive={
                  type !== "free" ? responsiveBlogs : responsiveForPackageCards
                }
              >
                {data.map((item) => (
                  <CardItems item={item} key={item?.id} />
                ))}
              </Carousel>
            ) : (
              <Row justify={"center"}>
                <h2 className="f-sm text-align-center pt-50 mt-50 mb-50">
                  No result was found.
                </h2>
              </Row>
            )}
          </div>
        )}

        <div className="d-flex justify-content-center mt-30 mb-40">
          {button && type !== "free" && (
            <FlatButton
              type={"Secondary"}
              title={button}
              className={styles.buttonStyle}
              postIcon={<img src={arrow} alt={""} />}
              onClick={handleClickExploreMore}
            />
          )}
        </div>
      </div>
    </Col>
  );
};

export default React.memo(CourseCard);
