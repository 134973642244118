import actionTypes from "../../action-types";

const INITIAL_STATE = {
  showContactUsModal: false,
  blogCategories: null,
  blogPosts: null,
  blogs: null,
  notificationData: null,
  is_Olevel: false,
  teacherModal: false,
};

const generalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACT_US_MODAL: {
      return {
        ...state,
        showContactUsModal: action.payload,
      };
    }

    case actionTypes.IS_OLEVEL: {
      return {
        ...state,
        is_Olevel: action.payload,
      };
    }

    case actionTypes.TEACHER_MODAL: {
      return {
        ...state,
        teacherModal: action.payload,
      };
    }

    case actionTypes.BLOG_CATEGORIES: {
      return {
        ...state,
        blogCategories: action.payload,
      };
    }

    case actionTypes.BLOG_POSTS: {
      return {
        ...state,
        blogPosts: action.payload,
      };
    }

    case actionTypes.BLOGS: {
      return {
        ...state,
        blogs: action.payload,
      };
    }

    case actionTypes.NOTIFICATIONDATA: {
      return {
        ...state,
        notificationData: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default generalReducer;
