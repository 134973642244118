import { Space } from "antd";
import personaliseImg from "./assets/webp/personalise.webp";
import teacherIcon from "./assets/webp/teacher.webp";
import globalIcon from "./assets/webp/globally.webp";
import classesIcon from "./assets/webp/classes.webp";
import environ from "./assets/svg/map.svg";
import check from "./assets/svg/check-circle.svg";
import star from "./assets/svg/star.svg";
import dollarIcon from "./assets/webp/dollar.webp";
import clock from "./assets/webp/clock.webp";
import mapPin from "./assets/webp/map-pin.webp";
import calendar from "./assets/webp/calendar.webp";
import moment from "moment-timezone";
import triviaFaqCard from "../../assets/svgs/TriviaFaqCard.svg";
import learningSvg from "../../assets/svgs/learning.webp";
import agesSvg from "../../assets/svgs/ages.webp";
import classSvg from "../../assets/svgs/class.webp";
import videoChatSvg from "../../assets/svgs/video_chat.webp";
import TriviaPP from "src/modules/parent/assets/img/Trivia PP.png";
import TriviaRF from "src/modules/parent/assets/img/Trivia RF.png";
import TriviaGK from "src/modules/parent/assets/img/Trivia GK.png";
import TrophyCup1st from "src/modules/parent/assets/img/Trophy Cup 1st.png";
import TrophyCup2nd from "src/modules/parent/assets/img/Trophy Cup 2nd.png";
import TrophyCup3rd from "src/modules/parent/assets/img/Trophy Cup 3rd.png";

export const TrophyImg = [
  { src: TrophyCup2nd, alt: "Trophy Cup 2nd", price: "PKR 5000" },
  { src: TrophyCup1st, alt: "Trophy Cup 1st", price: "PKR 10000" },
  { src: TrophyCup3rd, alt: "Trophy Cup 3rd", price: "PKR 3000" },
];

export const roundDetailImg = [
  { src: TriviaGK, alt: "General Knowledge" },
  { src: TriviaPP, alt: "Picture Puzzle" },
  { src: TriviaRF, alt: "Rapid Fire" },
];

export const classTypeList = [
  {
    key: "one_to_one",
    label: "One-on-One",
    value: "one_to_one",
  },
  {
    key: "batch",
    label: "Batch (3+ students)",
    value: "batch",
  },
];

export const wtDotnlinData = [
  {
    id: "0",
    title: "Live, online classes",
    color: "#EC1847",
    icon: videoChatSvg,
  },
  {
    id: "1",
    title: "World class teachers",
    color: "#7DE003",
    icon: classSvg,
  },
  {
    id: "2",
    title: "Flexible learning options",
    color: "#005EFF",
    icon: learningSvg,
  },
  {
    id: "3",
    title: "Learner ages 3 - 18",
    color: "#FDC53E",
    icon: agesSvg,
  },
];

export const faqsCardData = [
  {
    icon: triviaFaqCard,
    question: "Who can participate in the Trivia Competition?",
    answer:
      "Students from Grade 3 to 8 across the globe can participate in the competition for free.",
  },
  {
    icon: triviaFaqCard,
    question: "How can I register for the competition?",
    answer:
      "You can register by clicking on the ‘Registration now’ button on Dot & Line’s website.",
  },
  {
    icon: triviaFaqCard,
    question: "How will winners be decided?",
    answer:
      "The top 10 students from the Qualifying Round will advance to the Final Round. The top 3 winners from each age category will receive cash prizes up to PKR 10,000.",
  },
  {
    icon: triviaFaqCard,
    question: "Will there be certificates for participation?",
    answer:
      "Yes, all participants will receive e-certificates for their participation.",
  },
  {
    icon: triviaFaqCard,
    question: "What happens if I get disconnected during the competition?",
    answer:
      "If you face technical issues, try to reconnect immediately. However, lost time will not be compensated.",
  },
  {
    icon: triviaFaqCard,
    question: "Who do I contact for further queries?",
    answer: (
      <>
        You can reach out to our support team at <span style={{ color: "#005eff" }}>0346-4000862</span> or{" "}
        <span style={{ color: "#005eff" }}>
          studentsuccess@dotandlinelearning.com
        </span>{" "}
        for any questions.
      </>
    ),
  },
];

export const priceList = [
  {
    key: "top_recommended",
    label: "Top Recommended",
    value: "top_recommended",
  },
  {
    key: "low_to_high",
    label: "Low to High",
    value: "low_to_high",
  },
];

export const daysList = [
  {
    key: "2",
    label: "2 Days (per week)",
    value: 2,
  },
  {
    key: "3",
    label: "3 Days (per week)",
    value: 3,
  },
  {
    key: "4",
    label: "4 Days (per week)",
    value: 4,
  },
  {
    key: "5",
    label: "5 Days (per week)",
    value: 5,
  },
];

export const timeList = [
  {
    key: "morning",
    name: "Morning",
    label: (
      <Space>
        {"Morning - 5 AM to 11 AM"}
        <span style={{ fontSize: "10px" }}>({moment.tz.guess()})</span>
      </Space>
    ),
    value: "morning",
  },
  {
    key: "afternoon",
    name: "Afternoon",
    label: (
      <Space>
        {"Afternoon - 12 PM to 4 PM"}
        <span style={{ fontSize: "10px" }}>({moment.tz.guess()})</span>
      </Space>
    ),
    value: "afternoon",
  },
  {
    key: "evening",
    name: "Evening",
    label: (
      <Space>
        {"Evening - 5 PM to 8 PM"}
        <span style={{ fontSize: "10px" }}>({moment.tz.guess()})</span>
      </Space>
    ),
    value: "evening",
  },
  {
    key: "night",
    name: "Night",
    label: (
      <Space>
        {"Night - 8 PM to 5 AM"}
        <span style={{ fontSize: "10px" }}>({moment.tz.guess()})</span>
      </Space>
    ),
    value: "night",
  },
];

export const tabData = [
  {
    id: "0",
    title: "Live personalised classes",
    color: "#EC1847",
    icon: personaliseImg,
  },
  {
    id: "1",
    title: "Certified teachers",
    color: "#7DE003",
    icon: teacherIcon,
  },
  {
    id: "2",
    title: "Rated 5 stars globally",
    color: "#005EFF",
    icon: globalIcon,
  },
  {
    id: "3",
    title: "Affordable Classes",
    color: "#FDC53E",
    icon: classesIcon,
  },
];

export const reviewData = [
  {
    id: "0",
    name: "Yasira Mazhar",
    country: "Australia",
    review: `Joining dot and line proved to be a great decision! Arham is really enjoying his classes, learning a lot, and he actually looks forward to his class. Ms. Ayesha is a wonderful teacher, and I really admire her teaching style. She pays attention to small details and conducts the class very smoothly, which keeps Arham hooked and interested. Plus, she is extremely cooperative.`,
  },
  {
    id: "1",
    name: "Farwa",
    country: "Australia",
    review: `Miss Maheen is doing a wonderful job so far and we are very delighted with the entire experience.
    Rubab has been learning a lot from her. Her pen gripping has improved so much since she started taking classes with Miss Maheen.
    Overall it's been great so far and I'd give her a 10/10 Dot & Line is a great platform and I'm so glad I discovered it online. I'm sitting in Australia and yet my daughter is getting coached by Pakistani teachers.
    The dashboard itself is so easy to use and also there are so many courses to choose from.
    I think I'll be a regular here!`,
  },
  {
    id: "2",
    name: "Mahnood Mengal",
    country: "Qatar",
    review: `Miss Mahvish is more than just a teacher. She is an inspiration and a mentor. Her passion for teaching shines through in every lesson.
      I love the way she interacts with her students; it’s easy to see that her students respect her and that she cares about them.
      . Her patience and commitment to supporting my children has mean a lot to our family. 
      With her guidance, our sons have developed into confident and capable boys. 
      Her expertise in teaching has put our minds at ease. We are so grateful to have her as our children’s teacher.`,
  },
  {
    id: "3",
    name: "Uzma Javed",
    country: "Canada",
    review: `Afeen has gotten very comfortable with Ms Sana in a very short time..she is doing amazing so far.
      Ms Sana herself is very friendly and makes kids comfortable. Her way of teaching and interacting with kids is very smooth and loving. She is definitely making learning a great experience!`,
  },
  {
    id: "4",
    name: "Ayesha Haider",
    country: "Canada",
    review:
      "I am thrilled to share my experience with Dot and Line's Faiza Raheel, an exceptional creative writing and literature teacher. Her dedication and expertise in teaching my son who is in grade 9, have been truly remarkable. Faiza’s innovative approach and engaging lessons have significantly improved my child’s writing skills and literary understanding. Her passion for teaching and commitment to student success are evident in every class. I highly recommend Ms Faiza Raheel for anyone looking to enhance their creative writing and literature skills. She is an outstanding educator!I am thrilled to share my experience with Dot and Line's Faiza Raheel, an exceptional creative writing and literature teacher. Her dedication and expertise in teaching my son who is in grade 9, have been truly remarkable. Faiza’s innovative approach and engaging lessons have significantly improved my child’s writing skills and literary understanding. Her passion for teaching and commitment to student success are evident in every class. I highly recommend Ms Faiza Raheel for anyone looking to enhance their creative writing and literature skills. She is an outstanding educator!",
  },
  {
    id: "5",
    name: "Samina Faisal Khan",
    country: "Pakistan",
    review: `I have never encountered a teacher who makes learning Maths so enjoyable and fun! Her expertise and experience shine through in her teaching, and the fact that my son looks forward to his online ""Maths"" classes is nothing less than a miraculous adventure for us all.

      Her loving and considerate nature is such that even I have revisited lessons from my own 4th grade, finding meaning in them that was once elusive. She makes it so easy to learn Maths as basic life skills that are practical rather than merely cramming numbers and figures that would have never made much sense.
      
      Our family is immensely thankful to Mahvish for her dedication and focus, which have enabled Huzaifa to advance from 3rd to 4th grade Math in remarkably little time.
      
      Thank you Dot and Line for this platform and connecting us both!`,
  },
  {
    id: "6",
    name: "Wajiha Irfan",
    country: "Saudi Arabia",
    review:
      "I'm overjoyed as a parent by Shaq's outstanding help with my child's IGCSE physics preparation. His dedication and enthusiasm were evident right away. Shaq excites pupils by deftly breaking down difficult ideas into simpler terms. He provides a great deal of assistance, creating a conducive learning atmosphere. Shaq's comprehensive syllabus coverage and exam methods are crucial for success. Strongly advised!",
  },
  {
    id: "7",
    name: "Saba Aamir",
    country: "Pakistan",
    review: `I wanted to take a moment to express my heartfelt gratitude for the incredible impact you have had on my daughter's learning journey. Your dedication, patience, and enthusiasm for coding have truly made a difference in her life.

      Your ability to make complex concepts accessible and exciting has not only fostered her interest in coding but has also instilled in her a love for problem-solving and creativity. 
      
      Thank you for going above and beyond in your teaching. Your passion for the subject is contagious, and your support has been instrumental in her growth.
      She waits for your class. We feel incredibly fortunate to have you as her teacher.`,
  },
  {
    id: "7",
    name: "Erum Zeeshan",
    country: "Dubai",
    review:
      "Miss Jaweria Aamir is an exceptional English teacher who prepared my child for the IGCSE ESL exam with sincerity and dedication. Her kind and sympathetic approach created a comfortable learning environment, fostering both academic growth and confidence. Highly recommended for anyone seeking quality English education!",
  },
  {
    id: "7",
    name: "Dr Muhammad Ishtiaq",
    country: "Riyadh",
    review: `It was a great experience taking biology classes at Dot and Line! My daughter improved a lot during this time. Moreover, she feels more confident!
      I am grateful to Dot and Line for engaging teachers like Dr. Tanya. She is qualified, experienced, well-mannered and committed! I am really impressed by her method of teaching, hard work and perseverance, her command over her subject, and her concern for her students. Her feedback sessions are commendable.`,
  },
];
export const triviaReviewData = [
  {
    id: "0",
    name: "Sadia Sajad",
    city:"Melbourne",
    country: "Australia",
    review: `Thank you so much! We truly appreciate Dot & Line for providing such engaging learning opportunities. The Trivia Competition was a fun and enriching experience for the kids. Looking forward to more!`,
  },
  {
    id: "1",
    name: "Rabail Salman",
    city:"Karachi",
    country: "Pakistan",
    review: `Thanks alot...they did a lot of preparations since yesterday...Alhamdulilah...and thanks to u who have provided such a platform for the kids...i myself am soo excited for this whole trivia...`,
  },
  {
    id: "2",
    name: "Areeba Anwer",
    city:"Lahore",
    country: "Pakistan",
    review: `Thanks a lot masooma batool...im also privilege that my kids got such an opportunity and platform to explore themselves and increase their knowledge as well while preparing for this trivia..thanks again!`,
  },
  {
    id: "3",
    name: "Malika",
    city:"Riyadh",
    country: "KSA",
    review: `This was our first time joining, and we’re so impressed! The event was well-organized, and the kids had a blast. Can’t wait for the next one!`,
  },
  {
    id: "4",
    name: "Zofishan",
    city:"Dubai",
    country: "UAE",
    review:
      "Such a fun and engaging competition! My child loved participating and learned so much in the process. Looking forward to more!",
  },
];

export const aboutData = [
  {
    id: "0",
    image: environ,
    text: "In Over 14 Countries",
    description: "International Tutors and Students",
  },
  {
    id: "1",
    image: check,
    text: "400,000+ Live Classes",
    description: "Completed",
  },
  {
    id: "2",
    image: star,
    text: "4.6/5 Average Rating",
    description: "Given by 7000+ Global Learners",
  },
];

export const filterData = [
  {
    id: "0",
    label: "Price",
    name: "price",
    icon: dollarIcon,
    items: priceList,
  },
  {
    id: "1",
    label: "Days",
    name: "days",
    icon: calendar,
    items: daysList,
  },
  {
    id: "2",
    label: "Time",
    name: "time_slot",
    icon: clock,
    items: timeList,
  },
  {
    id: "3",
    label: "Class Type",
    name: "enrollment_mode",
    icon: mapPin,
    items: classTypeList,
  },
];
