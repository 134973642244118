import actionTypes from "../../action-types";

const INITIAL_TEACHER_DATA = {
  teachers: [],
  currentPage: 1,
  perPage: 10,
  total: 0,
  loading: true,
};

const INITIAL_PROFESSIONAL_TEACHER = {
  teachers: [],
  currentPage: 1,
  perPage: 10,
  total: 0,
  loading: false,
};

const INITIAL_STATE = {
  topTeachers: null,
  teacherData: { ...INITIAL_TEACHER_DATA },
  subjectTeachers: { ...INITIAL_TEACHER_DATA },
  professionalTeachers: { ...INITIAL_PROFESSIONAL_TEACHER },
};

const teacherReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_TOP_TEACHERS: {
      return {
        ...state,
        topTeachers: action.payload,
      };
    }
    case actionTypes.SET_TEACHER_DATA: {
      return {
        ...state,
        teacherData: { ...state.teacherData, ...action.payload },
      };
    }

    case actionTypes.SUBJECT_TEACHERS: {
      return {
        ...state,
        subjectTeachers: { ...state.subjectTeachers, ...action.payload },
      };
    }
    case actionTypes.PROFESSIONAL_TEACHER: {
      return {
        ...state,
        professionalTeachers: action.payload,
      };
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        teacherData: { ...INITIAL_TEACHER_DATA },
      };
    }
    default: {
      return state;
    }
  }
};

export default teacherReducer;
