import React from "react";
import { Modal } from "antd";
import styles from "./../styles.module.css";
import FlatButton from "../../flat-button";

const ConfirmModal = ({
  isModalVisible,
  onCancel,
  description = "",
  style = {},
  closable = true,
  maskClosable = true,
  bodyStyle = {},
  footer = null,
  centered = true,
  bodyClassName = "",
  header = "",
  loading,
  onConfirm,
}) => {
  const buttonStyle = {
    height: "37px",
    fontSize: "16px",
    fontWeight: "600",
  };

  return (
    <Modal
      style={style}
      footer={footer}
      centered={centered}
      closable={closable}
      onCancel={onCancel}
      bodyStyle={bodyStyle}
      open={isModalVisible}
      maskClosable={maskClosable}
      className={[styles.modal_container, "g-modal"].join(" ")}
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.23)" }}
    >
      <div className={[styles.modal_body_scrollable, bodyClassName].join(" ")}>
        <p className="f-md text-align-center mb-10">{header}</p>
        <p className="f-sm text-align-center mb-0">{description}</p>

        {footer && (
          <div>
            <div className={styles.action_buttons}>
              <FlatButton
                type={"Tertiary"}
                title={"No"}
                className={"mr-20"}
                style={buttonStyle}
                onClick={onCancel}
              />
              <FlatButton
                loading={loading}
                style={buttonStyle}
                onClick={onConfirm}
                title={"Yes"}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmModal;
