import React from "react";

import { Button, Space } from "antd";

import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const NoData = ({ name, onHandleContact = () => {} }) => (
  <div className={styles.noDataDiv}>
    <Space direction={"vertical"}>
      <Text
        color={"#303030"}
        size={"M"}
        center
        lHeight={"S"}
        title={`Hey! Batches for ${name} are full at the moment. Click here to know more about when the next batch is starting or for One to One tutoring.`}
      />

      <Button onClick={onHandleContact} className={styles.contactBtn}>
        <Text
          size={"M"}
          lHeight={"S"}
          color={"#FFFFFF"}
          title={"Contact Representative"}
        />
      </Button>
    </Space>
  </div>
);

export default React.memo(NoData);
