import React, { useEffect, useRef, useState } from "react";

import { Row, Space } from "antd";
import { isMobile } from "react-device-detect";

import { ArrowLeftOutlined } from "@ant-design/icons";
import chat_menu_icon from "../../assets/svgs/chat_menu_icon.svg";
import Text from "src/modules/parent/components/text";
import noUserImage from "../../../../assets/img/no_user.png";

import styles from "../../styles.module.css";
import "../../index.css";

const anonymousAvatar = noUserImage;

export default function ChatHeader({
  handleCloseChat,
  handleClickContact,
  isContact,
  user,
  handleBack = () => {},
}) {
  const optionsRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (isContact) {
      setShowOptions(false);
    }
  }, [isContact]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (optionsRef?.current && !optionsRef.current.contains(e?.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.chat_header}>
      <Row>
        <Space size={"middle"}>
          {isMobile && (
            <ArrowLeftOutlined
              onClick={handleBack}
              className={styles.arrowLeft}
            />
          )}

          <img
            src={user?.picture || anonymousAvatar}
            alt={"teacherPicture"}
            className={styles.dp}
          />

          <Text className="overflow-elipsis" size={"M"} font={"SEMIBOLD"}>
            {user?.name || ""}
          </Text>
        </Space>
      </Row>

      {!isContact && (
        <div className="position-relative">
          <div className={styles.menu_div} onClick={() => setShowOptions(true)}>
            <img src={chat_menu_icon} alt="" className={styles.menu_icon} />
          </div>
          {showOptions && (
            <div className={styles.menu_list} ref={optionsRef}>
              <div className={styles.menu_item} onClick={handleClickContact}>
                {"Contact Info"}
              </div>
              <div className={styles.menu_item} onClick={handleCloseChat}>
                Close Chat
              </div>
              {/* <div className={styles.menu_item} onClick={() => setShowReportChatModal(true)}>
                Report Chat
              </div> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
