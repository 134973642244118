import React from "react";

import { Button, Col, Row } from "antd";
import { kgToGrade7 } from "src/modules/parent/utils/static-data";
import SelectInput from "src/modules/parent/components/select-input";

import styles from "../styles.module.css";

const StepNo1 = ({ handleNext, grade, setGrade, isLoading = false }) => (
  <>
    <p className={styles.description}>
      {"Answer some easy questions to help us find your teacher!"}
    </p>

    <div className={"mt-30"}>
      <h2>{"What grade is your child in?"}</h2>

      <Col span={24}>
        <SelectInput
          allowClear
          className={"w-95"}
          value={grade}
          placeholder={"Select Grade"}
          onChange={setGrade}
          options={kgToGrade7}
        />
      </Col>

      <Row justify={"center"}>
        <Button
          loading={isLoading}
          onClick={handleNext}
          className={styles.nextButton}
        >
          {"Next"}
        </Button>
      </Row>
    </div>
  </>
);

export default React.memo(StepNo1);
