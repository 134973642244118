import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import { Button, Col, Row, Space } from "antd";
import { isMobile } from "react-device-detect";

import { PackageInfo, UpcomingClass, CollapsableView } from "./component";
import {
  useFetchEnrolment,
  useFetchInvoices,
  useFetchAssignments,
} from "./apiController";
import { Overview, Invoices, Assignment } from "./component/enrollment-tabs";
import Text from "src/modules/parent/components/text";
import AppLoader from "src/modules/parent/components/app-loader";
import Header from "src/modules/parent/components/header";
import Footer from "src/modules/parent/components/footer";
import { groupArrayOfObjects } from "../../utils";
import arrowUp from "./assets/webp/arrowUp.webp";
import { tabMobile, tabs } from "./staticData";
import {
  baselineViewWeb,
  progressReportViewWeb,
  certificateViewWeb,
  trackEvents,
} from "src/modules/parent/utils/events/query";

import styles from "./styles.module.css";

const EnrolledCourse = () => {
  const [params] = useSearchParams();
  const tabName = params.get("tab");
  const [tabId, setTabId] = useState("0");
  const { id } = useParams();
  const [invoices, setInvoices] = useState([]);
  const { enrolmentData, enrolmentLoading, enrolmentError } =
    useFetchEnrolment(id);
  const { invoiceData, invoiceLoading } = useFetchInvoices(id);
  const { data: baselineData } = useFetchAssignments(id, "baseLineForms");
  const { data: certificateData } = useFetchAssignments(id, "certificate");
  const { data: assignmentData } = useFetchAssignments(id, "assignments");
  const { data: progressReportData } = useFetchAssignments(
    id,
    "progressReports"
  );
  const { teacher, slot } = enrolmentData || {};

  useEffect(() => {
    if (tabName) {
      const findtab = tabs.find((tab) => tab.tabName === tabName.toLowerCase());

      if (findtab) {
        handleTrackEvent(findtab.id);
        setTabId(findtab.id);
      }
    }
  }, [tabName]);

  useEffect(() => {
    if (invoiceData && invoiceData.length) {
      const data = groupArrayOfObjects(invoiceData);
      setInvoices(data);
    }
  }, [invoiceData]);

  const handleTrackEvent = (id) => {
    let eventKey;

    switch (id) {
      case "2":
        eventKey = baselineViewWeb;
        break;

      case "3":
        eventKey = progressReportViewWeb;
        break;

      case "4":
        eventKey = certificateViewWeb;
        break;

      default:
        break;
    }

    if (eventKey) {
      trackEvents(eventKey);
    }
  };

  const onHandleSelect = (option) => {
    const { itemId } = option || {};

    handleTrackEvent(itemId);
    setTabId(itemId);
  };

  const renderViews = useMemo(() => {
    if (!enrolmentData) return null;

    switch (tabId) {
      case "0":
        return (
          <Overview
            data={enrolmentData.package}
            slot={slot}
            teacher={teacher}
          />
        );

      case "1":
        return <Invoices data={invoices} loadingInvoices={invoiceLoading} />;

      case "2":
        return (
          <Assignment
            data={baselineData}
            teacher={teacher}
            type={"Baseline Form"}
          />
        );

      case "3":
        return (
          <Assignment
            data={progressReportData}
            teacher={teacher}
            type={"Progress Report"}
          />
        );

      case "4":
        return (
          <Assignment
            data={certificateData}
            teacher={teacher}
            type={"Certificate"}
          />
        );

      case "5":
        return (
          <Assignment data={assignmentData} teacher={teacher} type={"Notes"} />
        );

      default:
        return null;
    }
  }, [
    assignmentData,
    baselineData,
    certificateData,
    enrolmentData,
    invoiceLoading,
    invoices,
    progressReportData,
    slot,
    tabId,
    teacher,
  ]);

  const getIcon = useCallback(
    (tabData) => {
      if (tabData.id === tabId) {
        return tabData.iconColor;
      }

      return tabData.icon;
    },
    [tabId]
  );

  return (
    <div>
      <Header />

      {enrolmentLoading ? (
        <div className="d-flex justify-content-center mt-50 pt-40 mb-50">
          <AppLoader className="font-28" />
        </div>
      ) : !enrolmentError && enrolmentData ? (
        <div className={"app-container mt-30 mb-30"}>
          <PackageInfo
            name={teacher?.name}
            title={enrolmentData.package?.title}
            image={enrolmentData.package?.image || ""}
          />

          {isMobile && (
            <Button
              onClick={() => onHandleSelect({ itemId: "5" })}
              className={styles.notesBtn}
            >
              <Space align={"center"}>
                <Text
                  color={"rgba(255, 255, 255, 1)"}
                  size={"S"}
                  font={"SEMIBOLD"}
                  title={"Notes and Resources"}
                />

                <img src={arrowUp} alt={"RightArrow"} />
              </Space>
            </Button>
          )}

          <div className={styles.container}>
            {slot && (
              <UpcomingClass slot={slot} enrollmentId={enrolmentData?.id} />
            )}
            <Row gutter={[20, 20]} className={"mt-20"}>
              {!isMobile && (
                <Col xxl={6} xl={6} lg={8} md={24} sm={24} xs={24}>
                  <CollapsableView
                    tabs={tabs}
                    selectedTab={{ itemId: tabId }}
                    setSelectedTab={onHandleSelect}
                  />
                </Col>
              )}

              {isMobile && (
                <Row
                  className="w-100"
                  align={"middle"}
                  justify={"space-around"}
                >
                  {tabMobile.map((tab) => (
                    <img
                      key={tab.id}
                      onClick={() => onHandleSelect({ itemId: tab.id })}
                      width={tabId === tab.id ? 65 : 50}
                      height={tabId === tab.id ? 75 : 50}
                      src={getIcon(tab)}
                      alt="icon1"
                    />
                  ))}
                </Row>
              )}

              <Col xxl={18} xl={18} lg={16} md={24} sm={24} xs={24}>
                {tabId && (
                  <Text
                    size={"M"}
                    className={isMobile ? "pb-20 pt-20" : "p-20"}
                    font={"SEMIBOLD"}
                    color={"#0E153A"}
                    title={tabs[tabId]?.title}
                  />
                )}

                {renderViews}
              </Col>
            </Row>
          </div>
        </div>
      ) : null}

      <Footer />
    </div>
  );
};

export default EnrolledCourse;
