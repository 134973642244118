import React from "react";
import styles from "./../styles.module.css";
import { Avatar, Col, Row } from "antd";
import FlatButton from "../../flat-button";

function importAll(r) {
  return r.keys().map(r);
}

const avatars = importAll(require.context("../../../assets/avatars", false, /\.(png|jpe?g|svg)$/));

const SelectStudentAvatar = ({ selectedAvatar, selectAvatarHandler, setShowAvatarModal }) => {
  return (
    <div>
      <div className="text-align-center heading-title mb-10">Select Avatar</div>
      <Row>
        {avatars.map((item, index) => (
          <Col key={index} xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
            <Avatar
              src={item.default}
              style={{ width: "auto", height: "auto" }}
              className={`${styles.kids_avatar} ${selectedAvatar === index ? styles.selected : ""}`}
              onClick={() => selectAvatarHandler(item.default, index)}
            />
          </Col>
        ))}
      </Row>
      <div className="mt-40 mb-10 d-flex justify-content-center">
        <FlatButton width={"200px"} title={"Ok"} loading={false} onClick={() => setShowAvatarModal(false)} />
      </div>
    </div>
  );
};

export default SelectStudentAvatar;
