import { textLimitForReadMore } from "src/modules/parent/utils/static-data";
import {
  ReadOutlined,
  StarFilled,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import React, { Fragment, useState } from "react";
import { Col, Row } from "antd";

import styles from "./../styles.module.css";
import noImage from "./../../../assets/img/anonymous.jpeg";
import location from "./../../../assets/img/location-black.png";
import img1 from "./../../../assets/img/degree.png";
import {
  getWhatsAppUrlToOpen,
  qualificationFormatter,
} from "src/modules/parent/utils";
import JWVideoPlayer from "src/modules/parent/components/jw-video-player";
import FlatButton from "src/modules/parent/components/flat-button";
import { isBrowser } from "react-device-detect";
import {
  MOBILE_WHATSAPP_URL,
  WEB_WHATSAPP_URL,
} from "src/modules/parent/config/constant";

const BasicInfo = ({ teacher, reviews, handleScroll, handleScrollReview }) => {
  const [readMore, setReadMore] = useState(false);

  const getText = () => {
    return getWhatsAppUrlToOpen("teacher profile");
  };

  const handleAssist = () => {
    window.location.pathname = `/chat/${teacher.id}`;
  };

  return (
    <Row className="pt-10" gutter={[20, 20]}>
      <Col xxl={13} xl={13} lg={24} md={24} sm={24} xs={24}>
        <div>
          <div className="d-row mt-25">
            <div className={styles.profile_div}>
              <img src={teacher?.picture || noImage} alt="" />
            </div>
            <div className="ml-15 d-column justify-content-center">
              <p className={"f-xl blue-color mb-5"}>{teacher?.name}</p>

              <Fragment>
                {reviews && reviews.length > 0 && (
                  <Fragment>
                    <div className={[styles.ratings_div, "d-row"].join(" ")}>
                      <p className="mb-0 f-md">{teacher?.ratings}</p>
                      <span className="d-row mt-4 ml-6">
                        <StarFilled className="font-20" />
                      </span>
                    </div>

                    <p
                      onClick={() => {
                        handleScrollReview(true);

                        setTimeout(() => {
                          handleScrollReview(false);
                        }, 1000);
                      }}
                      className="grey-color f-sm font-weight-500-imp mb-0 cursor-pointer text-hover-underline"
                    >{`${reviews.length} total ${
                      reviews.length <= 1 ? "review" : "reviews"
                    }`}</p>
                  </Fragment>
                )}
              </Fragment>
            </div>
          </div>
          {qualificationFormatter(teacher) && (
            <div className="d-row mt-20">
              <img src={img1} alt="" style={{ height: "28px" }} />
              <p className="mb-0 ml-8 f-sm">
                {qualificationFormatter(teacher)}
              </p>
            </div>
          )}
          {teacher && teacher?.subjects ? (
            <div className="d-row mt-15">
              <ReadOutlined className="font-26" />
              <p className="mb-0 ml-8 f-sm">{teacher?.subjects?.join(", ")}</p>
            </div>
          ) : null}
          {(teacher?.city || teacher?.country) && (
            <div className="d-row mt-15">
              <img src={location} className={styles.cap} alt="" />
              <p className="mb-0 ml-8 f-sm">
                {teacher?.city ? `${teacher?.city}, ` : ""}
                {teacher?.country ? `${teacher?.country}` : ""}
              </p>
            </div>
          )}
          {teacher?.bio && (
            <div className="d-row mt-10">
              <UserOutlined className="font-26" />
              <p className="mb-0 ml-8 f-sm">
                {teacher?.bio.length > textLimitForReadMore && readMore
                  ? teacher?.bio
                  : teacher?.bio?.slice(0, textLimitForReadMore)}
                {teacher?.bio.length > textLimitForReadMore && (
                  <Fragment>
                    {!readMore && "... "}
                    <span
                      onClick={() => setReadMore((prev) => !prev)}
                      className="blue-color font-weight-400 text-hover-underline"
                    >
                      <i>{readMore ? " See less" : "See more"}</i>
                    </span>
                  </Fragment>
                )}
              </p>
            </div>
          )}
          <div className={styles.mobile_btns}>
            <Row className={styles.btnRow} gutter={[0, 10]}>
              <div className={styles.total_reviews}>
                <FlatButton
                  title={"My Courses"}
                  height={"45px"}
                  width={"120px"}
                  onClick={() => {
                    handleScroll(true);

                    setTimeout(() => {
                      handleScroll(false);
                    }, 1000);
                  }}
                  className={[styles.my_courses_button].join(" ")}
                />
              </div>

              {isBrowser ? (
                <FlatButton
                  height={"45px"}
                  className="mr-10"
                  title={
                    <div>
                      <span>Share</span>
                      <WhatsAppOutlined className="font-18 ml-15" />
                    </div>
                  }
                  onClick={() => window.open(`${WEB_WHATSAPP_URL}${getText()}`)}
                  width={"120px"}
                />
              ) : (
                <a
                  href={`${MOBILE_WHATSAPP_URL}${getText()}`}
                  data-action={"share/whatsapp/share"}
                  rel={"noreferrer"}
                  target={"_blank"}
                >
                  <FlatButton
                    height={"45px"}
                    className="mr-10"
                    title={
                      <div>
                        <span>Share</span>
                        <WhatsAppOutlined className="font-18 ml-15" />
                      </div>
                    }
                    width={"120px"}
                  />
                </a>
              )}

              {isBrowser && (
                <FlatButton
                  // preIcon={
                  //   <img src={hand} style={{ marginleft: "10px" }} alt="hand" />
                  // }
                  type={"Secondary"}
                  height={"45px"}
                  className={styles.button}
                  title={"Chat"}
                  onClick={handleAssist}
                />
              )}
            </Row>
          </div>
        </div>
      </Col>
      <Col xxl={2} xl={1} lg={0} md={0} sm={0} xs={0} />
      {teacher?.video_id && (
        <Col xxl={9} xl={10} lg={24} md={24} sm={24} xs={24}>
          <JWVideoPlayer
            url={teacher.video_id}
            title={teacher?.name}
            thumbnail={teacher?.picture}
            teacherId={teacher?.id}
          />
        </Col>
      )}
    </Row>
  );
};

export default BasicInfo;
