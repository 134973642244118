import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../../components/footer";
import Header from "../../components/header";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import pencil from "src/modules/parent/assets/svgs/pencil.svg";
import BasicFilters from "../../components/basicFilters";
import BlogComponent from "./components/blogComponent";
import { subjectData } from "../../utils/static-data";

import * as actions from "src/modules/parent/store/actions";

const popularSubjects = [
  {
    id: "0",
    title: "Math",
  },
  {
    id: "1",
    title: "English",
  },
  {
    id: "2",
    title: "Phonics",
  },
  {
    id: "3",
    title: "Creative Writing",
  },
  {
    id: "4",
    title: "French",
  },
  {
    id: "5",
    title: "Urdu",
  },
  {
    id: "6",
    title: "Coding",
  },
];

const Blogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const [subjects, setSubjects] = useState([]);
  const [blogName, setBlogName] = useState([]);
  const { blogs, blogCategories } = useSelector(
    (state) => state.generalReducer
  );
  const { keywordList } = useSelector((state) => state.subjectReducer);

  useEffect(() => {
    action.getCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (blogCategories && blogCategories.length) {
      action.getPostsByCategory(blogCategories, 3);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogCategories]);

  const courseFilterHandler = (page, pageSize) => {
    const data = subjects.toLowerCase();
    const route = data.split(" ").join("-");

    const initialSubject = subjectData.find(
      (i) => i.subject.toLowerCase() === route
    );

    if (initialSubject) {
      navigate(`/online-${route}`, {
        state: {
          subject: subjects,
          grade: "",
        },
      });
    } else {
      navigate("/courses?program_id=5");
    }
  };

  const basicFilterHandler = (page, pageSize) => {
    const name = blogName.toString().toLowerCase();
    const blogExist = blogs.some((i) => i.label.toLowerCase() === name);

    if (blogExist) {
      navigate(`/blogs/${name.split(" ").join("-")}`);
    }
  };

  const handlePopularSelect = (data) => {
    setSubjects(data.title);
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div>
      <Header />

      <div className={styles.mainHeadingContainer}>
        <div className="app-container pt-50 pb-50">
          <Row>
            <Col>
              <div onClick={handleGoHome} className={styles.routeName}>
                {"Home"}
              </div>
            </Col>
            <div className={styles.slash}>{"/"}</div>

            <Col>{"Blogs"}</Col>
          </Row>

          <Row className="pt-30" gutter={[24, 24]}>
            <BasicFilters
              allowClear={false}
              gradeFilterDisabled
              title={"Search Blog"}
              subjects={blogName}
              setSubjects={setBlogName}
              keywordList={blogCategories}
              basicFilterHandler={basicFilterHandler}
            />
          </Row>

          <Row justify={"center"} className="pt-30">
            <div
              className={[
                "font-20 mb-20 font-weight-500",
                styles.headingStyle,
              ].join(" ")}
            >
              {"Blogs: Dot and Line"}
            </div>

            <div className={"font-18"}>
              {
                "Unlock your true potential through the power of real connection"
              }
            </div>
          </Row>

          {blogs && blogs.length > 0 && (
            <BlogComponent key={"blog-1"} data={blogs[0]} />
          )}

          <Col className="mb-20" span={24}>
            <div className={styles.banner}>
              <h1 className={styles.banner_title}>
                {"What We Value At Dot and Line Learning"}
              </h1>

              <p>
                {
                  "At Dot and Line, we cherish real connections, fostering a supportive community that nurtures growth. We prioritize collaboration, empathy, and personalized learning experiences to empower individuals and inspire lifelong curiosity."
                }
              </p>

              <div className={styles.pencil}>
                <img src={pencil} alt={"pencil"} />
              </div>
            </div>
          </Col>

          {blogs &&
            blogs.length > 0 &&
            blogs
              .slice(1, 4)
              .map((blogData, blogIndex) => (
                <BlogComponent key={blogIndex} data={blogData} />
              ))}
        </div>
      </div>

      <div className={styles.mainSearchContainer}>
        <div className="app-container pt-50 pb-50 trophy-container">
          <h2 className="sub-title">{"Find More Courses"}</h2>

          <Row gutter={[24, 24]}>
            <BasicFilters
              gradeFilterDisabled
              subjects={subjects}
              setSubjects={setSubjects}
              keywordList={keywordList}
              basicFilterHandler={courseFilterHandler}
            />
          </Row>

          <h2 className="sub-title mt-30">{"Popular Searches"}</h2>

          <Row gutter={[20, 20]}>
            {popularSubjects.map((i) => (
              <Col
                xxl={3}
                xl={4}
                lg={5}
                md={6}
                sm={8}
                xs={10}
                span={3}
                key={i.id}
              >
                <div
                  onClick={() => handlePopularSelect(i)}
                  className={styles.tabs}
                >
                  {i.title}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div className={styles.mainHeadingContainer}>
        <div className="app-container mb-50 pt-50 pb-50">
          {blogs &&
            blogs.length > 0 &&
            blogs
              .slice(4)
              .map((blogData, blogIndex) => (
                <BlogComponent key={blogIndex} data={blogData} />
              ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Blogs;
