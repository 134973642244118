import React from "react";
import { Row, Col } from "antd";
import styles from "./styles.module.css";

const steps = [
  { number: 1, label: "Account" },
  { number: 2, label: "Personal Information" },
  { number: 3, label: "Participant’s Information" },
];

const getStepColor = (currentStep, stepNumber) => {
  if (currentStep === stepNumber) {
    return styles.activeStep;
  }

  if (currentStep < stepNumber) {
    return styles.inActiveStep;
  }

  return styles.completedStep;
};

const CustomStepper = ({ currentStep = 1 }) => (
  <div className={styles.stepperContainer}>
    <Row justify="center" className={styles.stepperRow}>
      <div className={styles.stepperLine}></div>

      {steps.map((step) => (
        <Col span={8} key={step.number} className={styles.stepperCol}>
          <div
            className={`${styles.stepCircle} ${getStepColor(
              currentStep,
              step.number
            )}`}
          >
            {step.number}
          </div>
          <p
            className={`${styles.stepLabel} ${
              currentStep === step.number ? styles.activeLabel : ""
            }`}
          >
            {step.label}
          </p>
        </Col>
      ))}
    </Row>
  </div>
);

export default CustomStepper;
