import React from "react";

import { Space, Button } from "antd";
import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";
import { isBrowser, isMobile } from "react-device-detect";


const location = [
  "Pakistan",
  "UAE",
  "Saudi Arabia",
  "UK",
  "Oman",
  "Qatar",
  "Australia",
];

const TriviaTextComponent = ({ handleClick = () => { } }) => (
  <div className={styles.textContent}>
    <Space size={isBrowser ? "middle" : "small"} direction={"vertical"}>
      {!isMobile && 
      <div className={styles.list}>
        {location.map((i, index) => {
          return (
            <span className={styles.point} key={index.toString()}>
              <span>•</span>
              {i}
            </span>
          );
        })}
      </div>}

      <Text
        size={isBrowser ? "XXL4" : "XXL2"}
        color={"rgb(255, 255, 255)"}
        font={"SEMIBOLD"}
        lHeight={isBrowser && "S"}
        title={isMobile ? "Trivia Competition 2025!" : "Get Ready for the 4th Online Trivia Competition!"}
      />

      <Text
        size={isBrowser ? "XXL" : "M"}
        color={isBrowser ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)"}
        font={"SEMIBOLD"}
        lHeight={isBrowser && "S"}
        title={"On 12th & 13th April 2025"}
      />

      <div className={styles.buttonContainer}>
        <Button onClick={handleClick} className={styles.searchButton}>
          {"Register Now for Free"}
        </Button>
      </div>
    </Space>
  </div>
);
export default React.memo(TriviaTextComponent);
