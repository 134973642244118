import {
  CalendarOutlined,
  CheckOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { capitalizeWords, getConvertedTime } from "../../../utils";
import styles from "./../styles.module.css";

const Step1 = ({ packageDetail, selectedSlot, setSelectedSlot }) => {
  return (
    <div className={"mb-10"}>
      <p>Click the card below to select.</p>
      <Row gutter={[0, 12]}>
        {packageDetail?.time_slot &&
          packageDetail?.time_slot?.length !== 0 &&
          packageDetail?.time_slot?.map((slot, index) => (
            <Col key={index} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <div
                className={styles.time_card}
                onClick={() => setSelectedSlot(slot?.id)}
              >
                <div className="d-row dark-grey-color d-row">
                  <CalendarOutlined className="font-18 mt-2 mr-10" />
                  <p className="mb-0">{capitalizeWords(slot.days)}</p>
                </div>
                <div className="d-row mt-5 dark-grey-color d-row">
                  <FieldTimeOutlined className="font-20 mt-2 mr-10" />
                  <p className="mb-0">
                    {getConvertedTime(
                      slot.start_time,
                      slot.end_time,
                      slot.time_zone
                    )}
                  </p>
                </div>
                {Number(selectedSlot) === Number(slot?.id) && (
                  <div className={styles.slot_check}>
                    <CheckOutlined className="font-20" />
                  </div>
                )}
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default Step1;
