import React from "react";

import ModalComponent from "src/modules/parent/components/modal";
import RequestDemo from "src/modules/parent/pages/package-details/request-demo-card";
import styles from "./styles.module.css";

const RescheduleModal = ({
  isReschedule,
  setIsReschedule,
  timeSlots = [],
  packageDetail,
  handleDemoReschedule = () => {},
  loadingId,
}) => (
  <ModalComponent
    containerClassName={styles.modalContainer}
    isModalVisible={isReschedule}
    onCancel={() => setIsReschedule(false)}
    maskClosable
    footer={false}
  >
    <RequestDemo
      title={"Reschedule a Demo Class"}
      isScheduled
      loadingId={loadingId}
      handleDemoRescheduled={handleDemoReschedule}
      packageDetail={packageDetail}
      timeSlots={timeSlots}
    />
  </ModalComponent>
);

export default React.memo(RescheduleModal);
