import React from "react";

import { Modal } from "antd";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import TopicMastered from "./topicMastered";
import ClassesTaken from "./classesTaken";
import Certificate from "./certificate";
import Invoices from "./invoices";

import styles from "./styles.module.css";

const ProgressTrackerModal = ({
  isVisible = false,
  onCancel = () => {},
  handleView = () => {},
  onAssistMe = () => {},
  data,
}) => {
  const navigate = useNavigate();

  const onHandleClick = (id) => {
    if (id) {
      navigate(id);
    }
  };

  return (
    <Modal
      closable
      onCancel={onCancel}
      maskClosable
      centered
      footer={false}
      width={isMobile ? "100vw" : "55vw"}
      className={[styles.modal, isMobile ? "" : "g-modal"].join(" ")}
      open={isVisible}
    >
      <div className={"mt-10 mb-10"}>
        {data && data?.type === "topics" && (
          <TopicMastered
            data={data?.data}
            handleClose={onCancel}
            onHandleClick={(id) =>
              onHandleClick(`course/enroll/${id}?tab=progress_reports`)
            }
          />
        )}

        {data && data?.type === "classes" && (
          <ClassesTaken
            data={data?.data}
            onHandleClose={onCancel}
            onHandleAssistMe={onAssistMe}
            onTeacherClick={(teacherId) =>
              onHandleClick(`teachers/${teacherId}`)
            }
            onHandleClick={(id) => onHandleClick(`course/enroll/${id}`)}
          />
        )}

        {data && data?.type === "certificates" && (
          <Certificate
            data={data?.data}
            handleView={handleView}
            onHandleClose={onCancel}
            onHandleTeacherClick={(teacherId) =>
              onHandleClick(`teachers/${teacherId}`)
            }
            onHandleClick={(id) =>
              onHandleClick(`course/enroll/${id}?tab=certificates`)
            }
          />
        )}

        {data && data?.type === "invoices" && (
          <Invoices
            data={data?.data}
            onClose={onCancel}
            onHandleClose={onCancel}
            onHandleClick={() => onHandleClick(`invoices`)}
          />
        )}
      </div>
    </Modal>
  );
};

export default React.memo(ProgressTrackerModal);
