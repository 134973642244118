import React from "react";

import { Col, Row, Space } from "antd";

import Text from "src/modules/parent/components/text";
import whyDnl from "../../assets/webp/whydnl.webp";

import styles from "./styles.module.css";

const WhyDNL = () => (
  <div className={"app-container pt-50 pb-50"}>
    <Row gutter={[100, 20]} align={"middle"}>
      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Row justify={"center"}>
          <img
            src={whyDnl}
            width={"100%"}
            style={{ maxWidth: "500px" }}
            alt={"banner"}
          />
        </Row>
      </Col>

      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Space direction={"vertical"} size={"large"}>
          <Text
            size={"XXL2"}
            font={"SEMIBOLD"}
            lHeight={"S"}
            color={"#29292A"}
            title={"Why Dot and Line?"}
          />

          <Text
            size={"XL"}
            lHeight={"M"}
            color={"#404040"}
            className={"mt-10 mb-10"}
            title={
              "At Dot & Line we believe every child can reach their full potential with the help of a great teacher"
            }
          />

          <span className={styles.text}>
            As a globally trusted brand we have students from{" "}
            <span>over 14 different countries</span>. We provide highly
            qualified tutors for <span>one-on-one tutoring</span> and have a
            high success rate preparing students for the Naplan test.
          </span>
        </Space>
      </Col>
    </Row>
  </div>
);

export default React.memo(WhyDNL);
