require("dotenv").config();

const {
  NODE_ENV,
  REACT_APP_IMAGE_BASE_URL_DEV,
  REACT_APP_IMAGE_BASE_URL_PROD,
  REACT_APP_GOOGLE_CLIENT_DEV,
  REACT_APP_GOOGLE_CLIENT_PROD,
  REACT_APP_API_BASE_URL_DEV,
  REACT_APP_API_BASE_URL_PROD,
  REACT_APP_GOOGLE_PLACES_API_KEY,
  REACT_APP_JW_LIBRARY_URL,
  REACT_APP_FACEBOOK_CIENT_ID,
  REACT_APP_CHAT_API,
  REACT_APP_FACEBOOK_PIXEL_ID_1,
  REACT_APP_FACEBOOK_PIXEL_ID_2,
  REACT_APP_FACEBOOK_PIXEL_ID_3,
  REACT_APP_FACEBOOK_PIXEL_ID_4,
  REACT_APP_TIKTOK_PIXEL_ID,
  REACT_APP_FACEBOOK_PIXEL_ID_ExamPrep,
} = process.env;

const IS_DEV_ENV = NODE_ENV === "development";

const API_BASE_URL = IS_DEV_ENV
  ? REACT_APP_API_BASE_URL_DEV
  : REACT_APP_API_BASE_URL_PROD;
const IMAGE_BASE_URL = IS_DEV_ENV
  ? REACT_APP_IMAGE_BASE_URL_DEV
  : REACT_APP_IMAGE_BASE_URL_PROD;
const GOOGLE_CLIENT_ID = IS_DEV_ENV
  ? REACT_APP_GOOGLE_CLIENT_DEV
  : REACT_APP_GOOGLE_CLIENT_PROD;
const GOOGLE_PLACES_API_KEY = REACT_APP_GOOGLE_PLACES_API_KEY;
const JW_LIBRARY_URL = REACT_APP_JW_LIBRARY_URL;
const FACEBOOK_CIENT_ID = REACT_APP_FACEBOOK_CIENT_ID;
const WEB_WHATSAPP_URL = "https://web.whatsapp.com/send?text=";
const MOBILE_WHATSAPP_URL = "whatsapp://send?text=";
const CHAT_APP_API = `${REACT_APP_CHAT_API}/api`;
const fb_pixel_id_olevel = REACT_APP_FACEBOOK_PIXEL_ID_1;
const fb_pixel_id_ignite = REACT_APP_FACEBOOK_PIXEL_ID_2;
const fb_pixel_id_summer = REACT_APP_FACEBOOK_PIXEL_ID_3;
const fb_pixel_id_recruitment = REACT_APP_FACEBOOK_PIXEL_ID_4;
const tiktok_pixel_id = REACT_APP_TIKTOK_PIXEL_ID;
const fb_pixel_id_examPrep = REACT_APP_FACEBOOK_PIXEL_ID_ExamPrep;

export {
  API_BASE_URL,
  IMAGE_BASE_URL,
  GOOGLE_CLIENT_ID,
  GOOGLE_PLACES_API_KEY,
  JW_LIBRARY_URL,
  FACEBOOK_CIENT_ID,
  WEB_WHATSAPP_URL,
  MOBILE_WHATSAPP_URL,
  IS_DEV_ENV,
  CHAT_APP_API,
  fb_pixel_id_olevel,
  fb_pixel_id_ignite,
  fb_pixel_id_recruitment,
  tiktok_pixel_id,
  fb_pixel_id_summer,
  fb_pixel_id_examPrep,
};
