import { object, string } from "yup";

const phoneRegExp = /^(\+?\d{1,4}[\s-]?)?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/;
const nameRegExp = /^[^\d]*$/;

export const igniteFormSchema = object().shape({
  name: string()
    .matches(nameRegExp, "Name should not contain any number!")
    .required("Name is required"),
  email: string()
    .email("Email is invalid!")
    .trim()
    .nullable()
    .required("Email is required"),
  // email: string().email("Email is invalid!").required("Email is required"),
  whatsapp_no: string()
    .matches(phoneRegExp, "Whatsapp number is invalid")
    .required("Whatsapp number is required"),
  contact_no: string().required("Contact number is required"),
  city: string().required("City is required"),
  // country: string().required("Country is required"),
  dob: string().required("Date of Birth is required"),
  // address: string().required("Address is required"),
  // qualification: string().required("Qualification is required"),
  // major: string().required("Major is required"),
  // education_year: number().required("Education year is required"),
  // university: string().required("Name of University/Institute is required"),
  // personal_statement: string().required("Answer is required"),
  // is_internet: string(),
  // is_currently_working: string(),
  // is_taught_online: string(),
  // teaching_experience: string(),
  // have_laptop: string(),
  // purchasing: string(),
  // about_us: string(),
});
