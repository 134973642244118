const events = {
  google: {
    signup: {
      joinButtonClick: "GA_Signup_AfterJoin", //join button on signup page
      registerBtnClick: "GA_RegisterSignupLogin", //register button click on signup modal (not logged in user)
      registerSuccess: "GA_RegisterNow", // register successfull on sign up modal (not logged in user)
      modalRegisterSuccessButton: "GA_RegisterationSuccessful", //book a course (enrollment) when success on sign up modal (not logged In user)
      makePaymentBtnModal: "GA_MakePaymentButton", //make payment button after successful enrollment (sign up modal)
      findCourseBtnSuccess: "GA_FindaCourseButton_AfterSignup", // find a course button on successfull signup on signup page
    },

    naplan: {
      assistMeDemo: "naplan_book_a_free_demo_assist_me", //book a free demo button on hero section
      teacherCardDemo: "naplan_book_a_free_demo_teacher_card", //book a demo button on teacher card carousel
      carousel: "naplan_teacher_carousel", //teacher carousel card click
      gradeFilter: "naplan_grade_level_filter", //grade filter click on teacher carousel
      influencerPageView: "naplan_influencer_pageView", //influencer landing page
    },

    unAuthPage: {
      bookDemoK8: "GA_K8_BookADemoButton", //unauth page teacher card book a demo button
      exploreMoreClassesBtn: "GA_K8_ExploreMoreClasses", //unauth page explore more classes button
      bookDemoCoursePage: "GA_K8_BookAFreeDemo_CoursePage", //book a demo button on package details page under teacher video
      requestDemoModal: "GA_RequestButton_CoursePage", // request button to request a demo class on package details page
      joinForFreeBtn: "GA_JoinForFreeButton", //join for free button on unauthenticated page
      contactRepresentativeModal: "GA_ContactARepresentative_MainPage", // contact a representative button anywhere on the website (Modal)
    },

    examPrep: {
      chatWithTutorCourseCard: "GA_ChatwithTutor_CourseCard", //chat button on tutor card on exam prep tutor result page
      bookDemoHero: "FBK_BookAFreeDemo_HeroSection", //exam prep page book a demo Button
      influencerPageView: "ep_influencer_pageView", //exam prep landing page
      influencerBookDemo: "ep_influencer_book_demo", //exam prep influencer book demo
      contactWhatsappBtn: "GA_learnmore_mainpage", //whatsapp button on exam prep page
    },

    authPage: {
      learningLibraryHeader: "learning_library_dashboard", //learning library header click
      joinClassUpcomingClasses: "join_class_attend_class_dashboard", // join class button on upcoming classes section (logged In user)
      attendDemoUpcomingClasses: "attend_demo_dashboard", //click attend demo button on upcoming classes section (logged In user)
      whatsNew: "whats_new", //whats new section button click (authenticated dashboard)
      postReviewBtnTeacherCard: "review_teacher_dashboard", //post a review button when successfull under teacher avatar on authenticated dashboard
      chatWithTutorRecommended: "chat_with_tutor_dashboard_recommended_classes", //chat with tutor button on classes recommended section (authenticated dashboard)
      chatBtnTeacherAvatar: "chat_with_tutor_teacher_card_dashboard", // chat button under the teacher avatar on authenticated dashboard
      bookADemoRecommended: "book_a_free demo_dashboard", // book a demo button on recommended classes section (authenticated dashboard)
    },

    enrollment: {
      baselineWeb: "baseline_form_web", // baseline view on enrollment tab
      certificateWeb: "certificate_web", // certificate view on enrollment tab
      progressReportWeb: "progress_report_web", // progress report view on enrollment tab
    },

    becomeTeacher: {
      applyNow: "apply_become_a_TP", // apply now button on become a teacher landing page
    },

    addChildren: {
      saveClicked: "GA_SaveClicked_AddaChild", //add child success response
    },

    packageCard: {
      click: "GA_K8_CardClicked", //old package card click
    },

    freeClasses: {
      influencerPageLand: "influencer_freeclasses", //free class landing page
    },

    ignite: {
      influencerPageView: "ignite_influencer_pageView", //ignite landing page
      applyNowButton: "ignite_page_form_click", //ignite page apply now button
      submitForm: "ignite_page_form_submit", //ignite page submit form
      landingPageClick: "Ignite_landing_page_click", // landing page click from are you a teacher button
    },

    invoice: {
      cardPayNowButton: "GA_PayNowButton", //card pay now button which redirect to payment link
    },
  },

  facebook: {
    signup: {
      joinButtonClick: "FBK_Signup_AfterJoin", //join button on signup page
      modalRegisterSuccessButton: "FBK_RegisterationSuccessful", //book a course (enrollment) when success on sign up modal (not logged In user)
      registerBtnClick: "FBK_RegisterSignupLogin", //register button click on signup modal (not logged in user)
      registerSuccess: "FBK_RegisterNow", // register successfull on sign up modal (not logged in user)
      makePaymentBtnModal: "FBK_MakePaymentButton", //make payment button after successful enrollment (sign up modal)
      findCourseBtnSuccess: "FBK_FindaCourseButton_AfterSignup", // find a course button on successfull signup on signup page
    },

    addChildren: {
      saveClicked: "FBK_SaveClicked_AddaChild", //add child success response
    },

    examPrep: {
      contactWhatsappBtn: "FBK_learnmore_mainpage", //whatsapp button on exam prep page
      submitFormBadar: "Lead", //submit form on badar page
      chatWithTutorCourseCard: "FBK_ChatwithTutor_CourseCard", //chat button on tutor card on exam prep tutor result page
      bookDemoHero: "FBK_BookAFreeDemo_HeroSection", //exam prep page book a demo Button
    },

    unAuthPage: {
      bookDemoK8: "FBK_BookADemoButton", //unauth page teacher card book a demo button
      exploreMoreClassesBtn: "FBK_K8_ExploreMoreClasses", //unauth page explore more classes button
      bookDemoCoursePage: "FBK_BookAFreeDemo_CoursePage", //book a demo button on package details page under teacher video
      requestDemoModal: "FBK_RequestButton_CoursePage", // request button to request a demo class on package details page
      joinForFreeBtn: "FBK_JoinForFreeButton", //join for free button on unauthenticated page
    },

    packageCard: {
      click: "FBK_K8_CardClicked", //old package card click
    },

    ignite: {
      applyNowButton: "ignite_apply_now", //ignite page apply now button
      submitForm: "ignite_page_form_submit", //ignite page submit form
      submitApplication: "SubmitApplication", //ignite form submit button
    },

    invoice: {
      cardPayNowButton: "FBK_PayNowButton", //card pay now button which redirect to payment link
    },
  },

  tiktok: {
    signup: {
      modalRegisterSuccessButton: "TT_RegisterationSuccessful", //book a course (enrollment) when success on sign up modal (not logged In user)
      makePaymentBtnModal: "TT_MakePaymentButton", //make payment button after successful enrollment (sign up modal)
      registerSuccess: "TT_RegisterNow", // register successfull on sign up modal (not logged in user)
    },

    examPrep: {
      contactWhatsappBtn: "TK_learnmore_mainpage", //whatsapp button on exam prep page
    },

    invoice: {
      cardPayNowButton: "TT_PayNowButton", //card pay now button which redirect to payment link
    },
  },
};

export default events;
