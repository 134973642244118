export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    style={{ marginTop: "5px" }}
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M9 4.25V14.75M3.75 9.5H14.25"
      stroke="#005eff"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
