import React, { forwardRef, useState, useEffect, useCallback } from "react";
import { Select, Row, Col } from "antd";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";

import styles from "./styles.module.css";
import Text from "../text";

const PhoneWithFlagInput = forwardRef(
  (
    {
      name="",
      label,
      style={},
      onChange,
      onBlur,
      selectedCountry,
      showSearch,
      value,
      dropdownStyle = {},
      countryOnChange = () => {},
      inputPlaceholder,
      countryPlaceholder,
      selectedId,
      error = false,
    },
    ref
  ) => {
    const { countries } = useSelector((state) => state.profileReducer);
    const [selectedMask, setSelectedMask] = useState("");
    const [countryCode, setCountryCode] = useState("");

    useEffect(() => {
      const selectedCountryData = countries.find(
        (country) => country.value === selectedCountry
      );
      if (selectedCountryData) {
        const mask = Array.isArray(selectedCountryData.mask)
          ? selectedCountryData.mask[0]
          : selectedCountryData.mask;
        if (
          mask !== selectedMask ||
          selectedCountryData.value !== countryCode
        ) {
          setSelectedMask(mask);
          setCountryCode(selectedCountryData.value); // Set the country code
        }
      }
    }, [countries, selectedCountry, selectedMask, countryCode]);

    const handleInputChange = (e) => {
      const inputValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
      const selectedCountryData = countries.find(
        (country) => country.id === selectedId
      );

      if (selectedCountryData && Array.isArray(selectedCountryData.mask)) {
        const matchingMask = selectedCountryData.mask.find(
          (mask) => inputValue.length <= mask.replace(/[^\d]/g, "").length
        );
        setSelectedMask(matchingMask || selectedCountryData.mask[0]);
      }

      onChange(e); // Trigger the parent's onChange function
    };

    const optionRender = useCallback(
      (data) => (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <img
            alt={"Flag"}
            className={styles.dropdown_flag}
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${
              data.code || "PK"
            }.svg`}
            style={{ width: "20px", height: "15px" }}
          />
          <span>{data?.title}</span>
        </div>
      ),
      []
    );

    // Properly escape country code and combine with mask
    const getEscapedMask = () => {
      // Escape 9 in country code
      const escapedCountryCode = countryCode.replace(/9/g, "\\9");
      const escapedMask = selectedMask.replace(/9/g, "9") || "(999)999-9999"; // Fallback mask
      return `${escapedCountryCode} ${escapedMask}`;
    };

    return (
      <div>
        {label && <p className={styles.input_label}>{label}</p>}
        <Row gutter={[10, 10]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Select
              name={name}
              ref={ref}
              style={{ ...dropdownStyle, borderRadius: "8px", height: "50px" }}
              onChange={countryOnChange}
              showSearch={showSearch}
              placeholder={countryPlaceholder}
              optionFilterProp={"title"}
              defaultValue={selectedId}
              options={countries.map((country) => ({
                ...country,
                value: country.id,
                label: optionRender(country),
              }))}
            />
          </Col>

          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <InputMask
              name={name}
              value={value}
              style={style}
              mask={getEscapedMask()} // Escaped mask with country code
              onChange={handleInputChange}
              onBlur={onBlur}
              placeholder={inputPlaceholder || `${countryCode} ${selectedMask}`}
              className={"phone-number-input"}
            />
          </Col>
        </Row>
        {error && (
          <Text className="mt-8" color="RED" size="XS">
            {error}
          </Text>
        )}
      </div>
    );
  }
);

export default PhoneWithFlagInput;
