import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import styles from "./styles.module.css";
import { Collapse } from "antd";
import { faqs1, faqs2 } from "../../utils/static-data";

const FAQs = () => {
  return (
    <div>
      <Header />
      <div className="app-container">
        <div className={"faqs"}>
          <div className="mt-50 mb-50">
            <Collapse defaultActiveKey={["1"]}>
              <p className={[styles.heading1, "pb-20"].join(" ")}>Support - FAQs</p>
              {faqs1.map((item) => (
                <Collapse.Panel key={item.id} header={<span className={styles.heading3}>{`${item.id}. ${item.question}`}</span>}>
                  <p className={styles.paragraph}>{item.answer}</p>
                </Collapse.Panel>
              ))}
              <p className={[styles.heading1, "mt-50 pt-30 pb-20"].join(" ")}>Payments, Subscriptions and Discounts - FAQs</p>
              {faqs2.map((item, index) => (
                <Collapse.Panel key={item.id} header={<span className={styles.heading3}>{`${index + 1}. ${item.question}`}</span>}>
                  <p className={styles.paragraph}>{item.answer}</p>
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
