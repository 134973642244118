import { useState, useEffect } from "react";

import * as actions from "src/modules/parent/store/actions";

const useFetchData = (id) => {
  const [enrolmentData, setEnrolmentData] = useState(null);
  const [enrolmentLoading, setEnrolmentLoading] = useState(true);
  const [enrolmentError, setEnrolmentError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      actions
        .getEnrolmentById(id)
        .then((response) => {
          setEnrolmentLoading(false);
          setEnrolmentData(response);
        })
        .catch((error) => {
          setEnrolmentError(error);
          setEnrolmentLoading(false);
        });
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, [id]);

  return { enrolmentData, enrolmentLoading, enrolmentError };
};

export default useFetchData;
