import React from "react";

import Text from "src/modules/parent/components/text";
import styles from "../../styles.module.css";

const DayTag = ({ title }) => {
  return (
    <div className={styles.tagWrapper}>
      <div className={styles.tag}>
        <Text color={"#FFFFFF"} size={"S"}>
          {title}
        </Text>
      </div>
    </div>
  );
};

export default DayTag;
