import React from "react";

import { List, Avatar, Checkbox } from "antd";
import { useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import { avatarList } from "src/modules/parent/utils";
import { IMAGE_BASE_URL } from "src/modules/parent/config/constant";
import noImage from "src/modules/parent/assets/img/anonymous.jpeg";
import AddChildComponent from "./addChild";

import styles from "./styles.module.css";

const SelectChildComponent = ({
  disabledIds = [],
  checkedIds = [],
  formikRef,
  initialValues,
  handleChange = () => {},
  showInEligibleModal = () => {},
  handleSubmit = () => {},
  handleValidate = () => {},
}) => {
  const { children } = useSelector((state) => state.profileReducer);

  const getImage = (child) => {
    return child?.avatar
      ? avatarList[child?.avatar]?.image
      : child?.image
      ? `${IMAGE_BASE_URL}${child?.image}`
      : noImage;
  };

  return (
    <div className={"mt-20 mb-20"}>
      <Text
        size={"S"}
        font={"REGULAR"}
        center
        color={"rgba(200, 200, 208, 1)"}
        title={
          "Select the child you would like to register for Trivia Competition 2025"
        }
      />

      <List
        itemLayout="horizontal"
        className="mt-20 mb-10"
        dataSource={children}
        renderItem={(student) => {
          const isDisabled = disabledIds.has(student.id);
          const isChecked = checkedIds.has(student.id);
          const onChange = () => handleChange(student.id);

          return (
            <List.Item
              key={student.id.toString()}
              onClick={isDisabled ? showInEligibleModal : () => {}}
              style={{
                opacity: isDisabled ? 0.5 : 1,
                cursor: isDisabled ? "pointer" : "",
                margin: isBrowser ? "0px 40px" : "",
              }}
            >
              <Checkbox
                onChange={onChange}
                checked={isChecked}
                disabled={isDisabled}
                className={styles.checkBoxStyle}
              />

              <List.Item.Meta
                className={styles.listItem}
                avatar={
                  <Avatar shape={"square"} src={getImage(student)} size={50} />
                }
                title={
                  <Text size={"S"} font={"SEMIBOLD"} title={student.name} />
                }
                description={
                  <Text
                    size={"S"}
                    font={"REGULAR"}
                    color={"rgba(39, 39, 42, 1)"}
                    title={student.level}
                  />
                }
              />
            </List.Item>
          );
        }}
      />

      <AddChildComponent
        formikRef={formikRef}
        removedValue={0}
        handleValidate={handleValidate}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default SelectChildComponent;
