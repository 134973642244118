import React, { Fragment } from "react";
import { Button, Dropdown, Space } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import FlatButton from "../../flat-button";
import CoursesMenuOptions from "../coursesMenuOptions";
import dropdownIcon from "../assets/svgs/dropdown.svg";
import styles from "./../styles.module.css";
import ReactPixel from "react-facebook-pixel";
import { fb_pixel_id_summer } from "src/modules/parent/config/constant";
import {
  trackEvents,
  joinForFreeBtnUnAuthPage,
} from "src/modules/parent/utils/events/query";

ReactPixel.init(fb_pixel_id_summer);

const NoAuthContent = ({ handleTeacherModal = () => {} }) => {
  const navigate = useNavigate();

  const handleJoinForFreeBtn = () => {
    trackEvents(joinForFreeBtnUnAuthPage);
    navigate("/signup");
  };

  return (
    <Fragment>
      <div
        className={[
          styles.web_header,
          "align-items-center",
          styles.header,
        ].join(" ")}
      >
        <Dropdown
          className={"ml-20 mr-20"}
          overlay={<CoursesMenuOptions />}
          placement={"bottomLeft"}
        >
          <div className={"d-flex cursor-pointer"}>
            <span className={styles.navbar_item}>Find your Tutor</span>
            <span className={[styles.navbar_item, "ml-10"].join(" ")}>
              <img src={dropdownIcon} alt={"Dropdown"} />
            </span>
          </div>
        </Dropdown>
        <NavLink
          to={"/trivia"}
          activeclassname={styles.active_navbar_item}
          className={[styles.navbar_item, "ml-20 mr-20"].join(" ")}
        >
          <span>Trivia 2025</span>
        </NavLink>
        <span className="newPillsTag">new</span>
      </div>
      <div
        className={[
          styles.web_header,
          "justify-content-end",
          "align-items-center",
        ].join(" ")}
      >
        <Space size={"middle"}>
          <Button onClick={handleTeacherModal} className={styles.teacherButton}>
            {"Are you a Teacher?"}
          </Button>

          <FlatButton
            onClick={() => navigate("/login")}
            type={"Secondary"}
            width={"100px"}
            style={{ borderRadius: "10px" }}
            title={"Log in"}
          />
          <FlatButton
            title={"Join for free!"}
            width={"130px"}
            type={"Quaternary"}
            style={{ borderRadius: "10px" }}
            onClick={handleJoinForFreeBtn}
          />
        </Space>
      </div>
    </Fragment>
  );
};

export default NoAuthContent;
