import React from "react";

import { Row, Space } from "antd";

import Text from "src/modules/parent/components/text";
import filterImg from "../../assets/png/Filter.png";

import styles from "../../styles.module.css";

const FilterMobile = ({
  handleClearAll = () => {},
  handleFilters = () => {},
  count = null,
  selectedItem = {},
}) => (
  <Row justify={"center"}>
    <Space size={"middle"} align={"center"} direction={"vertical"}>
      <Row
        className={styles.filterIconContainer}
        onClick={handleFilters}
        align={"middle"}
      >
        <Text
          size={"M2"}
          font={"SEMIBOLD"}
          className={styles.hoverFilters}
          color={"rgba(0, 94, 255, 1)"}
          title={"Additional Filters"}
        />

        <img src={filterImg} alt={"filter"} />

        {count > 0 && <div className={styles.filterCount}>{count}</div>}
      </Row>

      {Object.keys(selectedItem).length > 0 && (
        <Row>
          <Text
            handleClick={handleClearAll}
            size={"M2"}
            className={styles.hoverFilters}
            color={"rgba(0, 0, 0, 1)"}
            title={"Clear all"}
          />
        </Row>
      )}
    </Space>
  </Row>
);

export default React.memo(FilterMobile);
