/* eslint-disable no-unused-vars */
import actionTypes from "../../action-types";
import {
  GET_PACKAGES,
  BULK_PACKAGES,
  TEACHER_RECOMMENDED_PACKAGE,
  BOOK_COURSE,
  GET_FREE_CLASSES,
  GET_SHORT_COURSE_PACKAGE,
  GET_SUBJECT_TEACHERS,
  GET_RECOMMENDED_COURSES,
  GET_PROFESSIONAL_PACKAGE,
  GET_PROFESSIONAL_TEACHERS,
  GET_WORKSHOP_PACKAGES,
  MARK_ATTENDANCE,
  GET_SHORT_COURSE_PACKAGES,
  GET_OLEVEL_TEACHERS,
  GET_OLEVEL_SUBJECTS,
  GET_SUBJECTS_BY_GRADE,
} from "../../../config/api";
import {
  axiosErrorHandler,
  createQueryString,
  getFilteredTimeSlots,
  getStartEndTime,
} from "../../../utils";
import { getRequest, postRequest } from "src/modules/parent/config/network";
import { message } from "antd";

export function fetchTopFreeClasses() {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FREE_CLASSES_LOADER,
      payload: true,
    });
    try {
      const queryObj = { limit: 50 };
      const queryParams = createQueryString(queryObj);
      const endpoint = `${GET_FREE_CLASSES}?${queryParams}`;
      const response = await getRequest(endpoint);
      dispatch({
        type: actionTypes.SET_TOP_FREE_CLASSES,
        payload: response?.data?.data?.data,
      });
      dispatch({
        type: actionTypes.FREE_CLASSES_LOADER,
        payload: false,
      });
    } catch (error) {
      axiosErrorHandler(error);
      dispatch({
        type: actionTypes.SET_TOP_FREE_CLASSES,
        payload: [],
      });
      dispatch({
        type: actionTypes.FREE_CLASSES_LOADER,
        payload: false,
      });
    }
  };
}

export function fetchSummerClassPackages(params) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SUMMER_CLASSES_PACKAGES_LOADING,
      payload: true,
    });
    try {
      const queryParams = params ? createQueryString(params) : "";
      const endpoint = `${GET_SHORT_COURSE_PACKAGES}?${queryParams}`;
      const response = await getRequest(endpoint);
      if (response && response.data) {
        dispatch({
          type: actionTypes.SUMMER_CLASSES_PACKAGES,
          payload: response.data,
        });
      }
      dispatch({
        type: actionTypes.SUMMER_CLASSES_PACKAGES_LOADING,
        payload: false,
      });
    } catch (error) {
      axiosErrorHandler(error);
      dispatch({
        type: actionTypes.SUMMER_CLASSES_PACKAGES,
        payload: [],
      });
      dispatch({
        type: actionTypes.SUMMER_CLASSES_PACKAGES_LOADING,
        payload: false,
      });
    }
  };
}

export function fetchShortCoursePackages(isMath, params) {
  return async (dispatch) => {
    dispatch({
      type: isMath
        ? actionTypes.MATH_PACKAGES_LOADING
        : actionTypes.CODING_PACKAGES_LOADING,
      payload: true,
    });
    try {
      const queryParams = params ? createQueryString(params) : "";
      const endpoint = `${GET_SHORT_COURSE_PACKAGES}?${queryParams}`;
      const response = await getRequest(endpoint);
      if (response && response.data) {
        dispatch({
          type: isMath
            ? actionTypes.MATH_PACKAGES
            : actionTypes.CODING_PACKAGES,
          payload: response.data,
        });
      }
      dispatch({
        type: isMath
          ? actionTypes.MATH_PACKAGES_LOADING
          : actionTypes.CODING_PACKAGES_LOADING,
        payload: false,
      });
    } catch (error) {
      axiosErrorHandler(error);
      dispatch({
        type: isMath ? actionTypes.MATH_PACKAGES : actionTypes.CODING_PACKAGES,
        payload: [],
      });
      dispatch({
        type: isMath
          ? actionTypes.MATH_PACKAGES_LOADING
          : actionTypes.CODING_PACKAGES_LOADING,
        payload: false,
      });
    }
  };
}

export function fetchPackages() {
  return async (dispatch) => {
    try {
      const endpoint = `${BULK_PACKAGES}`;
      const { data } = await getRequest(endpoint, false);
      if (data) {
        const getPackageWithHeading = data.getPackageWithHeading;
        let arr = [];

        getPackageWithHeading[0]?.data.forEach((item) => {
          const getTimeSlot = getFilteredTimeSlots(
            item?.time_slot,
            getStartEndTime()
          );

          arr.push({
            ...item,
            time_slot: getTimeSlot,
          });
        });

        dispatch({
          type: actionTypes.SET_BULK_PACKAGES,
          payload: {
            ...data,
            getPackageWithHeading: [{ ...getPackageWithHeading[0], data: arr }],
          },
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SET_BULK_PACKAGES,
        payload: [],
      });
      axiosErrorHandler(error);
    }
  };
}

export function setPackageData(packages) {
  return async (dispatch) => {
    const payloadObj = {
      packages: packages,
      currentPage: 1,
      perPage: 6,
      total: 6,
      loading: false,
    };

    dispatch({
      type: actionTypes.SET_PACKAGE_DATA,
      payload: payloadObj,
    });
  };
}

export function fetchPaginatedPackages(queryObj) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_PACKAGE_DATA,
      payload: { loading: true },
    });
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_PACKAGES}?${queryParams}`;
    try {
      const { data } = await getRequest(endpoint, false);

      if (data?.data?.data) {
        const packageData = JSON.parse(JSON.stringify(data?.data?.data));
        let arr = [];

        if (queryObj && !queryObj.time_slot) {
          packageData.forEach((item) => {
            const getTimeSlot = getFilteredTimeSlots(
              item?.time_slot,
              getStartEndTime(queryObj?.time_slot)
            );

            arr.push({
              ...item,
              time_slot: getTimeSlot,
            });
          });
        }

        const payloadObj = {
          packages: arr.length > 0 ? arr : packageData,
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({ type: actionTypes.SET_PACKAGE_DATA, payload: payloadObj });
      }
    } catch (error) {
      const payloadObj = {
        loading: false,
        packages: [],
        currentPage: 1,
        perPage: 10,
        total: 0,
      };
      dispatch({ type: actionTypes.SET_PACKAGE_DATA, payload: payloadObj });
      axiosErrorHandler(error);
    }
  };
}

export function getMorePackages(packageId) {
  const payloadEmptyObj = {
    packages: [],
    currentPage: 1,
    perPage: 10,
    total: 0,
    loading: false,
  };

  return async (dispatch) => {
    dispatch({
      type: actionTypes.MORE_COURSES,
      payload: { ...payloadEmptyObj, loading: true },
    });

    const endpoint = `${TEACHER_RECOMMENDED_PACKAGE}/${packageId}`;
    try {
      const { data } = await getRequest(endpoint, false);
      if (data?.data?.data) {
        const payloadObj = {
          packages: JSON.parse(JSON.stringify(data?.data?.data)),
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({ type: actionTypes.MORE_COURSES, payload: payloadObj });
      }
    } catch (error) {
      dispatch({ type: actionTypes.MORE_COURSES, payload: payloadEmptyObj });
      axiosErrorHandler(error);
    }
  };
}

export function getWorkShopPackages(queryObj) {
  const payloadEmptyObj = {
    packages: [],
    currentPage: 1,
    perPage: 10,
    total: 0,
    loading: false,
  };

  return async (dispatch) => {
    dispatch({
      type: actionTypes.WORKSHOP_PACKAGES,
      payload: { ...payloadEmptyObj, loading: true },
    });
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_WORKSHOP_PACKAGES}?${queryParams}`;
    try {
      const { data } = await getRequest(endpoint, false);
      if (data?.data?.data) {
        const payloadObj = {
          packages: JSON.parse(JSON.stringify(data?.data?.data)),
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({ type: actionTypes.WORKSHOP_PACKAGES, payload: payloadObj });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.WORKSHOP_PACKAGES,
        payload: payloadEmptyObj,
      });
      axiosErrorHandler(error);
    }
  };
}

export function searchSubjectPackages(queryObj) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_SUBJECT_PACKAGES_LOADING,
      payload: { loading: true },
    });
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_PACKAGES}?${queryParams}`;
    try {
      const { data } = await getRequest(endpoint, false);
      if (data?.data?.data) {
        const packageData = JSON.parse(JSON.stringify(data?.data?.data));
        let arr = [];

        if (queryObj && !queryObj.time_slot) {
          packageData.forEach((item) => {
            const getTimeSlot = getFilteredTimeSlots(
              item?.time_slot,
              getStartEndTime()
            );

            arr.push({
              ...item,
              time_slot: getTimeSlot,
            });
          });
        }

        const payloadObj = {
          packages: arr.length > 0 ? arr : packageData,
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({
          type: actionTypes.SET_SUBJECT_PACKAGES,
          payload: payloadObj,
        });
      }
    } catch (error) {
      const payloadObj = {
        loading: false,
        packages: [],
        currentPage: 1,
        perPage: 10,
        total: 0,
      };
      dispatch({ type: actionTypes.SET_SUBJECT_PACKAGES, payload: payloadObj });
      axiosErrorHandler(error);
    }
  };
}

export function markAnAttendance(enrolId) {
  return new Promise(async (resolve, reject) => {
    const endpoint = `${MARK_ATTENDANCE}/${enrolId}`;
    try {
      const { data } = await getRequest(endpoint);
      resolve(data);
    } catch (error) {
      console.log("🚀 ~ returnnewPromise ~ error:", error);
      // axiosErrorHandler(error);
    }
  });
}

export function searchTeacherBySubject(subject) {
  return async (dispatch) => {
    const payloadEmptyObj = {
      teachers: [],
      currentPage: 1,
      perPage: 10,
      total: 0,
      loading: false,
    };

    dispatch({ type: actionTypes.SUBJECT_TEACHERS, payload: payloadEmptyObj });
    const queryParams = `subject=${subject}`;
    const endpoint = `${GET_SUBJECT_TEACHERS}?${queryParams}`;

    try {
      const { data } = await getRequest(endpoint, false);
      if (data?.data?.data) {
        const payloadObj = {
          teachers: JSON.parse(JSON.stringify(data?.data?.data)),
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({
          type: actionTypes.SUBJECT_TEACHERS,
          payload: payloadObj,
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SUBJECT_TEACHERS,
        payload: payloadEmptyObj,
      });
      axiosErrorHandler(error);
    }
  };
}

export function setBlogData(data) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.BLOG_DATA, payload: data });
  };
}

export function searchShortCoursesBySubject(subject) {
  return async (dispatch) => {
    const queryParams = `subject=${subject}`;
    const endpoint = `${GET_SHORT_COURSE_PACKAGE}?${queryParams}`;
    try {
      const { data } = await getRequest(endpoint, false);
      if (data?.data?.data) {
        const payloadObj = {
          packages: JSON.parse(JSON.stringify(data?.data?.data)),
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({
          type: actionTypes.SHORT_COURSES,
          payload: payloadObj,
        });
      }
    } catch (error) {
      const payloadObj = {
        packages: [],
        currentPage: 1,
        perPage: 10,
        total: 0,
        loading: false,
      };
      dispatch({ type: actionTypes.SHORT_COURSES, payload: payloadObj });
      axiosErrorHandler(error);
    }
  };
}

export function getRecommendedCourses(queryObj) {
  return new Promise(async (resolve, reject) => {
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_RECOMMENDED_COURSES}?limit=2&${queryParams}`;
    try {
      const { data } = await getRequest(endpoint, false);

      if (data?.data?.data) {
        resolve(data?.data?.data);
      }
    } catch (error) {
      reject();
      axiosErrorHandler(error);
    }
  });
}

export function getOlevelSubjects(queryObj, isOlevel) {
  return async (dispatch) => {
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_OLEVEL_SUBJECTS}?${queryParams}`;
    try {
      const { data } = await getRequest(endpoint, false);
      if (data && data.data) {
        if (!isOlevel) {
          dispatch({ type: actionTypes.OLEVEL_SUBJECTS, payload: data?.data });
        } else {
          dispatch({
            type: actionTypes.OLEVEL_SUBJECTS_DOWN,
            payload: data?.data,
          });
        }
      }
    } catch (error) {
      console.log("🚀 ~ return ~ error:", error);
      // axiosErrorHandler(error);
    }
  };
}

export async function getExamPrepSubjects(queryObj) {
  const queryParams = createQueryString(queryObj);
  const endpoint = `${GET_OLEVEL_SUBJECTS}?${queryParams}`;
  try {
    const { data } = await getRequest(endpoint, false);
    if (data && data.data) {
      return data?.data;
    }
  } catch (error) {
    console.log("🚀 ~ return ~ error:", error);
    // axiosErrorHandler(error);
  }
}

export function getSubjectByGradeLevel(queryObj) {
  return new Promise(async (resolve, reject) => {
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_SUBJECTS_BY_GRADE}?${queryParams}`;
    try {
      const { data } = await getRequest(endpoint, false);

      if (data?.data) {
        resolve(data?.data);
      }
    } catch (error) {
      reject();
      axiosErrorHandler(error);
    }
  });
}

export function getOlevelTeachers(queryObj) {
  return new Promise(async (resolve, reject) => {
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_OLEVEL_TEACHERS}?${queryParams}`;
    try {
      const { data } = await getRequest(endpoint, false);

      if (data?.data?.data) {
        resolve(data?.data?.data);
      }
    } catch (error) {
      reject();
      axiosErrorHandler(error);
    }
  });
}

export function fetchPaginatedFreeClasses(queryObj) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_FREE_CLASSES_DATA,
      payload: { loading: true },
    });
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_FREE_CLASSES}?${queryParams}`;
    try {
      const { data } = await getRequest(endpoint);
      if (data?.data?.data) {
        const payloadObj = {
          freeClasses: JSON.parse(JSON.stringify(data?.data?.data)),
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({
          type: actionTypes.SET_FREE_CLASSES_DATA,
          payload: payloadObj,
        });
      }
    } catch (error) {
      const payloadObj = {
        loading: false,
        freeClasses: [],
        currentPage: 1,
        perPage: 10,
        total: 0,
      };
      dispatch({
        type: actionTypes.SET_FREE_CLASSES_DATA,
        payload: payloadObj,
      });
      axiosErrorHandler(error);
    }
  };
}

export function bookCourse(params) {
  return new Promise(async function (resolve, reject) {
    try {
      const formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      const endpoint = BOOK_COURSE;
      const response = await postRequest(endpoint, formData);
      if (response?.data?.status === "success") {
        message.success(response?.data?.message);
        resolve();
      }
    } catch (error) {
      axiosErrorHandler(error);
      reject();
    }
  });
}

export function getProfessionalPackages(queryObj) {
  const payloadEmptyObj = {
    packages: [],
    currentPage: 1,
    perPage: 8,
    total: 0,
    loading: false,
  };

  return async (dispatch) => {
    dispatch({
      type: actionTypes.PROFESSIONAL_PACKAGE,
      payload: { ...payloadEmptyObj, loading: true },
    });
    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_PROFESSIONAL_PACKAGE}?program_id=4&${queryParams}`;
    try {
      const { data } = await getRequest(endpoint);
      if (data?.data?.data) {
        const payloadObj = {
          packages: JSON.parse(JSON.stringify(data?.data?.data)),
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({
          type: actionTypes.PROFESSIONAL_PACKAGE,
          payload: payloadObj,
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.PROFESSIONAL_PACKAGE,
        payload: payloadEmptyObj,
      });
      axiosErrorHandler(error);
    }
  };
}

export function getProfessionalTeachers(queryObj) {
  const payloadEmptyObj = {
    teachers: [],
    currentPage: 1,
    perPage: 50,
    total: 0,
    loading: false,
  };

  return async (dispatch) => {
    dispatch({
      type: actionTypes.PROFESSIONAL_TEACHER,
      payload: { ...payloadEmptyObj, loading: true },
    });

    const queryParams = createQueryString(queryObj);
    const endpoint = `${GET_PROFESSIONAL_TEACHERS}?professional_courses=true&${queryParams}`;
    try {
      const { data } = await getRequest(endpoint);
      if (data?.data?.data) {
        const payloadObj = {
          teachers: JSON.parse(JSON.stringify(data?.data?.data)),
          currentPage: data?.data?.meta?.current_page,
          perPage: data?.data?.meta?.per_page,
          total: data?.data?.meta?.total,
          loading: false,
        };
        dispatch({
          type: actionTypes.PROFESSIONAL_TEACHER,
          payload: payloadObj,
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.PROFESSIONAL_TEACHER,
        payload: payloadEmptyObj,
      });
      axiosErrorHandler(error);
    }
  };
}
