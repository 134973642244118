import React from "react";

import { Row, Col, Space } from "antd";

import { isMobile } from "react-device-detect";
import moment from "moment-timezone";
import Button from "src/modules/parent/components/flat-button";
import Text from "src/modules/parent/components/text";
import book_blue from "../../assets/svgs/book_blue.svg";
import { getTimezone } from "src/modules/parent/utils";
import yellow_calendar from "../../assets/svgs/yellow_calendar.svg";
import eyeIcon from "src/modules/parent/assets/webp/eye.webp";

import styles from "./styles.module.css";

const getTime = (givenTime) => {
  var currentTime = new Date();
  let time = moment(givenTime, "hh:mm A");
  let formattedTime = time.format("HH:mm:ss");
  var givenDateTime = moment(formattedTime, "HH:mm:ss");
  var differenceMs = givenDateTime.diff(currentTime);
  var duration = moment.duration(differenceMs);

  var hoursDifference = duration.asHours();
  var minutesDifference = duration.asMinutes();

  if (Math.abs(hoursDifference) >= 1) {
    return `${Math.round(hoursDifference)} hours`;
  } else {
    return `${Math.round(minutesDifference)} minutes`;
  }
};

const convertedTime = (classData) => {
  if (classData && classData.slot) {
    const { slot } = classData;

    if (slot?.start_time && slot?.end_time) {
      const { start } = getTimezone(
        slot?.start_time,
        slot?.end_time,
        slot?.time_zone
      );

      const time = getTime(start);

      if (time.includes("-")) {
        return `${classData?.package?.title}`;
      } else {
        return `${classData?.package?.title}, starting in ${time}`;
      }
    }
  }
};

const ClassesTab = ({
  demo = null,
  classData = null,
  handleReschedule = () => {},
  handleMarkAttendance = () => {},
  attendDemo = () => {},
  handleShowDemos = () => {},
}) => (
  <div className={isMobile ? "mb-20" : "white_box mb-20 p-20"}>
    <Text
      color="rgba(0, 94, 255, 1)"
      size="XL"
      font="SEMIBOLD"
      className="mb-20"
    >
      {"Classes coming up"}
    </Text>

    <Row gutter={[20, 20]}>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <Row className="white_box p-15 h-100">
          <Space align={"start"} size={"middle"}>
            <div className={styles.schedule_icon_div}>
              <img src={book_blue} alt="" />
            </div>

            <div>
              {classData ? (
                <Text font="LIGHTER" className={"mb-10"} lHeight={"S"} size="S">
                  {convertedTime(classData)}
                </Text>
              ) : (
                <Text font="LIGHTER" className={"mb-10"} lHeight={"S"} size="S">
                  {"No Classes scheduled for today"}
                </Text>
              )}

              <Button
                disabled={!classData}
                className={styles.startClassBtn}
                height={30}
                onClick={() => handleMarkAttendance(classData)}
                type="Quaternary"
                title={"Join Class"}
              />
            </div>
          </Space>
        </Row>
      </Col>

      {isMobile && (
        <Text
          font="SEMIBOLD"
          className={"ml-20"}
          lHeight={"S"}
          size={"L"}
          title={"Booked Demos"}
        />
      )}

      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <Row className={styles.attendClassDemo}>
          <Space align={"start"} size={"middle"}>
            <div className={styles.callendar_icon_div}>
              <img src={yellow_calendar} alt="" />
            </div>

            <div>
              {demo ? (
                <Text font="LIGHTER" className={"mb-10"} lHeight={"S"} size="S">
                  {`You have an upcoming demo scheduled for ${demo?.package?.title}`}
                </Text>
              ) : (
                <Text font="LIGHTER" className={"mb-10"} lHeight={"S"} size="S">
                  {"No demo scheduled"}
                </Text>
              )}

              <Space size={"middle"}>
                <Button
                  className={styles.startClassBtn}
                  type="custom"
                  disabled={!demo}
                  height={30}
                  onClick={() => attendDemo(demo?.id)}
                  title={"Attend Demo"}
                  style={{
                    border: "1px solid rgba(252, 207, 19, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    background: "rgba(252, 207, 19, 1)",
                  }}
                />

                {demo && (
                  <Text
                    font={"LIGHTER"}
                    color={"rgba(252, 207, 19, 1)"}
                    className={styles.rescheduleBtn}
                    handleClick={() => handleReschedule(demo?.id)}
                    size="S"
                  >
                    {"Reschedule"}
                  </Text>
                )}
              </Space>
            </div>

            {demo && (
              <div onClick={handleShowDemos} className={styles.eyeStyle}>
                <img src={eyeIcon} alt={"eyeImage"} />
              </div>
            )}
          </Space>
        </Row>
      </Col>
    </Row>
  </div>
);

export default React.memo(ClassesTab);
