/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import AppRoutes from "./routes";
import * as actions from "./modules/parent/store/actions";
import ModalComponent from "./modules/parent/components/modalComponent";

import ContactUs from "./modules/parent/pages/contact-us";
import actionTypes from "./modules/parent/store/action-types";
import RegistrationModal from "src/modules/parent/components/registrationModal";
import { isBrowser } from "react-device-detect";

import { isSupported } from "firebase/messaging";
import {
  requestPermission,
  onMessageListener,
} from "./modules/parent/config/firebase";

function App() {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const [show, setShow] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const { fromTelenor, userInfo, isAuthenticated } = useSelector(
    (state) => state.authReducer
  );
  const { notificationData, showRegisterModal } = useSelector(
    (state) => state.notificationReducer
  );

  const getToken = async () => {
    const supported = await isSupported();

    if (supported && userInfo) {
      requestPermission();
      const payload = await onMessageListener();
      const data = payload;

      return Promise.resolve(data);
    }

    return Promise.reject(supported);
  };

  useEffect(() => {
    if (isAuthenticated) {
      action.checkStudentEnrolments();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userInfo) {
      const unsubscribe = getToken();

      return () => {
        unsubscribe.catch((err) => console.log("failed: ", err));
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (notificationData) {
      setShow(true);

      if (notificationData.timer) {
        setTimeout(() => {
          setShow(false);
          dispatch({ type: actionTypes.NOTIFICATIONDATA, payload: null });
        }, notificationData.timer);
      }
    }
  }, [notificationData]);

  useEffect(() => {
    if (showRegisterModal && showRegisterModal.show) {
      setRegisterModal(true);
    } else {
      setRegisterModal(false);
    }
  }, [showRegisterModal]);

  useEffect(() => {
    // Checking if user came from my-telenor appi
    if (!fromTelenor) {
      const { search } = window?.location;
      const params = search?.split("?")?.slice(1);
      action.updateFromTelenorStatus(params?.includes("telenor=app"));
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    dispatch({ type: actionTypes.NOTIFICATIONDATA, payload: null });
  };

  const handleRegisterModal = () => {
    setRegisterModal(false);
    dispatch({ type: actionTypes.SHOW_REGISTER_MODAL, payload: null });
  };

  return (
    <Fragment>
      <AppRoutes />
      <ContactUs />

      {show && (
        <ModalComponent
          show={show}
          image={notificationData?.image}
          handleClose={handleClose}
          centerAlign={notificationData?.centerAlign}
          noImg={notificationData?.noImg}
          cancelBtnText={notificationData?.cancelText}
          handleCancelBtn={() => {
            notificationData?.handleCancel();
            handleClose();
          }}
          handleSuccessBtn={() => {
            notificationData?.handleSuccess();
            handleClose();
          }}
          link={notificationData?.link}
          linkText={notificationData?.linkText}
          type={notificationData?.type}
          title={notificationData?.title}
          disableCancelBtn={notificationData?.disableCancel}
          successBtnText={notificationData?.successText}
          content={notificationData?.content}
          disableClose={notificationData?.disableClose}
        />
      )}

      {registerModal && (
        <RegistrationModal
          setShow={handleRegisterModal}
          width={isBrowser ? "60%" : "100%"}
          show={registerModal}
        />
      )}
    </Fragment>
  );
}

export default App;
