import React from "react";

import { Col, Row } from "antd";
import { Formik } from "formik";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import Text from "src/modules/parent/components/text";
import TextInput from "src/modules/parent/components/text-input";
import { loginFormSchema } from "../../../schema";
import googleLoginIcon from "../../../assets/svgs/googleLogin.svg";

import styles from "./styles.module.css";

const initialValues = {
  email: "",
  password: "",
};

const LoginComponent = ({
  formikRef,
  handleSubmit = () => {},
  onGoogleLogin = () => {},
}) => {
  const navigate = useNavigate();
  const handleForgotPassword = () => {
    navigate("/reset-password");
  };

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validateOnMount
        validationSchema={loginFormSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange }) => (
          <Row className={"mt-20 mb-10"} gutter={[20, 20]}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Text
                size={"S"}
                lHeight={"M"}
                color={"rgba(39, 39, 42, 1)"}
                title={"Email"}
              />

              <TextInput
                type={"email"}
                required={true}
                allowClear={true}
                placeholder={"Email"}
                error={errors.email && touched.email ? errors.email : ""}
                value={values.email}
                onChange={handleChange("email")}
              />
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Text
                size={"S"}
                lHeight={"M"}
                color={"rgba(39, 39, 42, 1)"}
                title={"Password"}
              />

              <TextInput
                required={true}
                type={"password"}
                placeholder={"********"}
                value={values.password}
                error={
                  errors.password && touched.password ? errors.password : ""
                }
                onChange={handleChange("password")}
              />
            </Col>
          </Row>
        )}
      </Formik>

      <Row justify={isMobile ? "center" : "end"}>
        <Text
          size={"S"}
          handleClick={handleForgotPassword}
          className={"cursor-pointer"}
          color={"rgba(0, 94, 255, 1)"}
          title={"Forgot Password"}
        />
      </Row>

      <div className={styles.horizontalLine}>
        <hr />
        <div className={styles.orText}>OR</div>
        <hr />
      </div>

      <Row
        className={"mt-20 mb-20 cursor-pointer user-select-none"}
        justify={"center"}
      >
        <img
          onClick={onGoogleLogin}
          src={googleLoginIcon}
          className={styles.image}
          alt={"google-login"}
        />
      </Row>
    </div>
  );
};

export default LoginComponent;
