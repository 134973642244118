import React from "react";

import ModalComponent from "../../components/modalComponent";

import styles from "./styles.module.css";

const guidelines = [
  {
    id: "0",
    text: "Parents can receive a full refund requested within 24 hours of purchase/payment. Note that the 24-hour refund window only applies until the first class's start time.",
  },
  {
    id: "1",
    text: "Once a payment is made, should the learner decide not to continue with that instructor, they can use that payment against any other course on the Dot and Line platform for four months from the date of the purchase.",
  },
  {
    id: "2",
    text: "Parents receive a full refund if the teacher cancels the course.",
  },
  {
    id: "3",
    text: "In the event that a teacher changes the date or time of a class meeting before the class’s start date, parents may request a full refund.",
  },
  {
    id: "4",
    text: "If your learner or you are dissatisfied with your current instructor you reserve the right to be transferred to another instructor mid session.",
  },
  {
    id: "5",
    text: "Refunds can only be issued to the original form of payment used for your purchase on Dot and Line so this refund is traceable.",
  },
  {
    id: "6",
    text: "Fee is non-refundable unless based on the above terms and conditions",
  },
];

const Guidelines = ({ show, setShow }) => {
  const guidelineContent = () => {
    return (
      <div className={styles.modal}>
        <div className={styles.list}>
          <ul>
            {guidelines.map((i, ind) => (
              <li key={i.id} className={styles.guideline}>
                {i.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <ModalComponent
      customStyles={styles.modalWidth}
      title={"Terms and Conditions"}
      handleSuccessBtn={() => setShow(false)}
      show={show}
      handleClose={() => setShow(false)}
      successBtnText={"Okay"}
      content={guidelineContent}
    />
  );
};

export default Guidelines;
