import React, { useCallback, useMemo } from "react";

import { Row, Space } from "antd";
import { isBrowser } from "react-device-detect";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";

import Text from "src/modules/parent/components/text";
import actionTypes from "src/modules/parent/store/action-types";
import FlatButton from "src/modules/parent/components/flat-button";
import { zoomLinkNotification } from "src/modules/parent/utils/notificationData";
import { markAnAttendance } from "src/modules/parent/store/actions";
import { getUpcomingDay, openLinkInNewTab } from "src/modules/parent/utils";

import styles from "../../styles.module.css";

const UpcomingClass = ({ slot, enrollmentId }) => {
  const dispatch = useDispatch();
  const getDay = useMemo(() => {
    const day = getUpcomingDay(slot.days, slot.end_time);
    console.log("🚀 ~ getDay ~ day:", day);

    if (day) {
      return moment().day(day).format("dddd");
    }
  }, [slot.days, slot.end_time]);

  const getStartTime = useMemo(() => {
    return moment(slot.start_time, "HH:mm").format("hh:mm A");
  }, [slot.start_time]);

  const getEndTime = useMemo(() => {
    return moment(slot.end_time, "HH:mm").format("hh:mm A");
  }, [slot.end_time]);

  const markAttendance = (enrolId) => {
    return new Promise((resolve, reject) => {
      markAnAttendance(enrolId).then(resolve).catch(reject);
    });
  };

  const onAttendClass = useCallback(() => {
    if (slot && slot?.zoom_link) {
      const link = slot?.zoom_link;
      openLinkInNewTab(link);
      markAttendance(enrollmentId);
    } else {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: zoomLinkNotification,
      });
    }
  }, [dispatch, enrollmentId, slot]);

  return (
    <Row
      justify={"space-between"}
      align={"middle"}
      gutter={[10, 10]}
      className={styles.upcomingClassWrapper}
    >
      <Row justify={"center"} gutter={[0, 10]}>
        <Text size={"S"} font={"LIGHTER"} lHeight={"S"} className={"mr-5"}>
          {"Upcoming Class:"}
        </Text>

        <Text
          size={"S"}
          lHeight={"S"}
          className={"text-align-center"}
          font={"SEMIBOLD"}
        >
          {`${getDay}, ${getStartTime} - ${getEndTime} (${
            slot.time_zone || "Asia/Karachi"
          })`}
        </Text>
      </Row>

      <div style={{ margin: isBrowser ? "0" : "auto" }}>
        <Space>
          <FlatButton
            type={"Quaternary"}
            height={33}
            width={148}
            className={styles.joinClassBtn}
            title={"Join Class"}
            onClick={onAttendClass}
          />
        </Space>
      </div>
    </Row>
  );
};

export default React.memo(UpcomingClass);
