/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import fbIcon from "src/modules/parent/assets/img/svg/fb.svg";
import instaIcon from "src/modules/parent/assets/img/svg/insta.svg";
import webIcon from "src/modules/parent/assets/img/web.png";
import utubeIcon from "src/modules/parent/assets/img/youtube-logo.png";
// import playstoreIcon from "src/modules/parent/assets/img/playstore-min.png";
import linkedinIcon from "src/modules/parent/assets/img/linkedin-logo.png";
import {
  FieldTimeOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions";
import moment from "moment-timezone";
import { defaultLinks } from "../../utils/links";
import actionTypes from "src/modules/parent/store/action-types";

const Footer = ({ links = null }) => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const { fb, instagram, youtube, linkedIn, website } = links || defaultLinks;

  const handleAssistMe = () => {
    dispatch({ type: actionTypes.ASSIST_ME_MODAL, payload: true });
  };

  return (
    <section className={styles.footer}>
      <div className={styles.footer_row1}>
        <div className={"app-container"}>
          <Row justify={"center"} gutter={[48, 48]}>
            {/* <Col xs={24} sm={12} md={12} lg={12} xl={6}>
              <p className="font-20 font-weight-600 blue-color mb-15">
                Get The App
              </p>
              <div className="d-column">
                <a
                  href={
                    "https://play.google.com/store/apps/details?id=com.dnlparentapp.production"
                  }
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <img
                    className={styles.playstore_button}
                    src={playstoreIcon}
                    alt=""
                  />
                </a>
                <Link to={"/"}>Apple Play Button here</Link>
              </div>
            </Col> */}
            <Col xs={24} sm={24} md={8} lg={8} xl={7}>
              <p className="font-20 font-weight-600 blue-color mb-15">Learn</p>
              <div className={styles.links}>
                {/* <div>
                  <Link to={"/courses?program_id=5"}>Explore More Classes</Link>
                </div> */}
                <div onClick={handleAssistMe} className={styles.link}>
                  Find a Tutor
                </div>
                <div>
                  <Link to={"/exam-prep"}>Explore Exam Prep Classes</Link>
                </div>
                <div>
                  <Link to={"/naplan"}>Explore NAPLAN Prep Classes</Link>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={7}>
              <p className="font-20 font-weight-600 blue-color mb-15">
                Support
              </p>
              <div className={styles.links}>
                <div>
                  <a onClick={() => action.setContactUsModal(true)}>
                    Contact Us
                  </a>
                </div>
                <div>
                  <Link to={"/faqs"}>FAQs</Link>
                </div>
                <div>
                  <Link to={"/blogs"}>Blogs</Link>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={7}>
              <p className="font-20 font-weight-600 blue-color mb-15">
                Reach Us
              </p>
              <ul className="p-10">
                <li>
                  <p className="font-14 font-weight-600 dark-grey-color mb-10">
                    WhatsApp
                  </p>
                  <div className="d-row align-items-center mb-15">
                    <div className="dark-grey-color mr-8">
                      <WhatsAppOutlined className="font-22" />
                    </div>
                    <div className="d-column blue-color font-weight-500">
                      <a
                        href={"https://wa.me/+923474106202"}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                      >
                        <span className="font-14">Finance: +923474106202</span>
                      </a>
                      <a
                        href={"https://wa.me/+923474000862"}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                      >
                        <span className="font-14">Operations: +923474000862</span>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <p className="font-14 font-weight-600 dark-grey-color mb-10">
                    Email
                  </p>
                  <div>
                    <div className="d-row align-items-center mb-15">
                      <div className="dark-grey-color mr-8">
                        <MailOutlined className="mt-3 font-22" />
                      </div>
                      <div className="d-column blue-color font-weight-500">
                        <a
                          href={
                            "https://mail.google.com/mail/?view=cm&fs=1&to=info@dotandlinelearning.com"
                          }
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          <span className="font-14 word-break-all">
                            info@dotandlinelearning.com
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="font-13 font-weight-500 dark-grey-color mb-0">
                      Student or Teacher Complaints or Questions
                    </p>
                    <div className="d-row align-items-center mb-15">
                      <div className="dark-grey-color mr-8">
                        <MailOutlined className="mt-3 font-22" />
                      </div>
                      <div className="d-column blue-color font-weight-500">
                        <a
                          href={
                            "https://mail.google.com/mail/?view=cm&fs=1&to=studentsuccess@dotandlinelearning.com"
                          }
                          target={"_blank"}
                          rel={"noopener noreferrer"}
                        >
                          <span className="font-14 word-break-all">
                            studentsuccess@dotandlinelearning.com
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="font-13 font-weight-500 dark-grey-color mb-0">
                      Payment Related Queries
                    </p>
                    <div className="d-row align-items-center mb-15">
                      <div className="dark-grey-color mr-8">
                        <MailOutlined className="mt-3 font-22" />
                      </div>
                      <div className="d-column blue-color font-weight-500">
                        <a
                          href={
                            "https://mail.google.com/mail/?view=cm&fs=1&to=accounts@dotandlinelearning.com"
                          }
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          <span className="font-14 word-break-all">
                            accounts@dotandlinelearning.com
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <p className="font-13 font-weight-500 dark-grey-color mb-0">Teacher Partner Queries</p>
                    <div className="d-row align-items-center mb-15">
                      <div className="dark-grey-color mr-8">
                        <MailOutlined className="mt-3 font-22" />
                      </div>
                      <div className="d-column blue-color font-weight-500">
                        <a
                          href={"https://mail.google.com/mail/?view=cm&fs=1&to=recruitment@dotandlinelearning.com"}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          <span className="font-14 word-break-all">recruitment@dotandlinelearning.com</span>
                        </a>
                      </div>
                    </div>
                  </div> */}
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.footer_row2}>
        <div className={"app-container"}>
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <p className="font-20 font-weight-600 blue-color mb-0">
                <span className="font-22">©</span> {new Date().getFullYear()}{" "}
                Dot &amp; Line
              </p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
              <div className={styles.terms_and_privacy}>
                <Link
                  target={"_blank"}
                  className="mr-25"
                  to={"/terms-and-conditions"}
                >
                  Term and conditions
                </Link>
                <Link target={"_blank"} to={"/privacy-and-policy"}>
                  Privacy and Policy
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={5} xxl={6}>
              <div className={[styles.social_icons, "d-flex mt-5"].join(" ")}>
                <a href={fb} target={"_blank"} rel={"noreferrer"}>
                  <img src={fbIcon} alt="Fb" />
                </a>
                <a href={instagram} target={"_blank"} rel={"noreferrer"}>
                  <img src={instaIcon} alt="Insta" />
                </a>
                <a target={"_blank"} rel={"noreferrer"} href={youtube}>
                  <img src={utubeIcon} alt="Youtube" />
                </a>
                <a target={"_blank"} rel={"noreferrer"} href={linkedIn}>
                  <img src={linkedinIcon} alt="Linkedin" />
                </a>
                <a href={website} target={"_blank"} rel={"noreferrer"}>
                  <img src={webIcon} alt="Web" />
                </a>
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={5} xxl={6}>
              <div className="d-flex">
                <div className="blue-color d-flex align-items-center mr-6">
                  <FieldTimeOutlined className="font-24" />
                </div>
                <p className="font-20 font-weight-600 blue-color mb-0">
                  {moment?.tz?.guess()}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Footer;
