import React, { useState } from "react";

import { Modal } from "antd";
import styles from "./styles.module.css";
import SignUp from "./components/signup";
import Login from "./components/login";

const RegistrationModal = ({ show = true, setShow, width = "70%" }) => {
  const [step, setStep] = useState(0);
  const handleClose = () => setShow(false);

  const handleLogin = () => setStep(1);
  const handleSignUp = () => setStep(0);

  return (
    <Modal
      closable
      onCancel={handleClose}
      maskClosable
      centered
      footer={false}
      width={width}
      className={[styles.modal, "g-modal"].join(" ")}
      open={show}
    >
      {step === 0 && <SignUp handleLogin={handleLogin} />}
      {step === 1 && <Login handleSignUp={handleSignUp} />}
    </Modal>
  );
};

export default RegistrationModal;
