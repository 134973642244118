import React from "react";

import { Row, Col } from "antd";
import { isMobile } from "react-device-detect";
import Carousel from "react-multi-carousel";

import Text from "src/modules/parent/components/text";
import { responsive2 } from "src/modules/parent/utils";
import step1 from "../../assets/svgs/step1.svg";
import step2 from "../../assets/svgs/step2.svg";
import step3 from "../../assets/svgs/step3.svg";
import step4 from "../../assets/svgs/step4.svg";

import styles from "./styles.module.css";

const data = [
  {
    id: "0",
    title: "Digital Application",
    image: step1,
  },
  {
    id: "1",
    title: "Two rounds of interviews",
    image: step2,
  },
  {
    id: "2",
    title: "Acceptance and Signing of Contract",
    image: step3,
  },
  {
    id: "3",
    title: "Commence Teacher Training Online",
    image: step4,
  },
];

const Steps = () => {
  return (
    <div className={styles.stepper}>
      <Row justify={"center"} className={"mb-50"}>
        <Text
          size={"XXL"}
          font={"SEMIBOLD"}
          color={"rgba(44, 44, 44, 1)"}
          lHeight={"S"}
          title={"Steps to become a Teacher Partner"}
          className={styles.becomeATeacherTxt}
        />
      </Row>

      {isMobile ? (
        <Carousel responsive={responsive2}>
          {data.map((item) => (
            <div className={styles.card}>
              <div className={styles.imgHeight}>
                <img
                  src={item.image}
                  style={{ width: "100%", height: "100%" }}
                  alt={"step1"}
                />
              </div>

              <Text
                size={"S"}
                color={"#000000"}
                lHeight={"S"}
                title={item.title}
                className={"text-align-center"}
              />
            </div>
          ))}
        </Carousel>
      ) : (
        <Row align={"middle"} justify={"center"}>
          {data.map((item) => (
            <Col key={item.id} xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
              <div className={styles.card}>
                <div className={styles.imgHeight}>
                  <img
                    src={item.image}
                    style={{ width: "100%", height: "165px" }}
                    alt={"step1"}
                  />
                </div>

                <Text
                  size={"S"}
                  color={"#000000"}
                  lHeight={"S"}
                  title={item.title}
                  className={"text-align-center pr-30 pl-30"}
                />
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default Steps;
