import React from "react";

import { Row, Col } from "antd";

import Text from "src/modules/parent/components/text";
import { naplanGrades } from "../../staticData";

import styles from "./styles.module.css";

const SearchBoxWeb = ({ selectedItem = [], handleClick = () => {} }) => (
  <div>
    <Text size={"L"} font={"SEMIBOLD"} title={"Choose Grade Level"} />

    <Row className={"mt-20"} gutter={[20, 20]}>
      {naplanGrades.map((item) => (
        <Col key={item.id} xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
          <div
            onClick={() => handleClick({ value: item.id, label: item.value })}
            className={
              selectedItem.some((i) => i === item.id)
                ? styles.gradeViewSelected
                : styles.gradeView
            }
          >
            <Text
              size={"S"}
              color={"#000000"}
              font={"SEMIBOLD"}
              title={item.label}
            />
          </div>
        </Col>
      ))}
    </Row>
  </div>
);

export default React.memo(SearchBoxWeb);
