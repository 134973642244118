import styles from "./styles.module.css";

function FabIcon({ icon, handleClick }) {
  return (
    <div onClick={handleClick} className={styles.fab}>
      <img src={icon} style={{ width: "40px", height: "40px" }} alt="fabIcon" />
    </div>
  );
}

export default FabIcon;
