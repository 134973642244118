import React, { useState, useEffect, useCallback } from "react";

import { Col, Form, Row, Table } from "antd";
import { useParams } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";

import TextInput from "src/modules/parent/components/text-input";
import {
  capitilizeFirstLetter,
  getLearningSubjects,
} from "src/modules/parent/utils";
import Modal from "src/modules/parent/components/modal";
import SelectInput from "src/modules/parent/components/select-input";
import Text from "src/modules/parent/components/text";
import { IMAGE_BASE_URL } from "src/modules/parent/config/constant";
import FlatButton from "src/modules/parent/components/flat-button";
import LearningLayout from "../layout";

import * as actions from "src/modules/parent/store/actions";

import styles from "./styles.module.css";
import { isMobile } from "react-device-detect";

const LearningResources = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const [subjectName, setSubjectName] = useState("");
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [videoData, setVideoData] = useState();
  const { activeLearningMenu } = useSelector((state) => state.learningReducer);
  const action = bindActionCreators(actions, dispatch);
  const {
    learningGrades,
    learningCategory,
    learningResources,
    resourcesLoading,
  } = useSelector((state) => state.learningReducer);
  const [value] = activeLearningMenu;
  const [grade, setGrade] = useState();

  const getResources = useCallback(
    (params) => {
      action.getResourcesData({ ...params });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (value) {
      setGrade(undefined);
      setFilters({ title: "", type: undefined, grade_id: undefined });
    }
  }, [value]);

  useEffect(() => {
    const debouncing = debounce(() => {
      if (filters) {
        getResources(filters);
      }
    }, 500);

    debouncing();

    return () => {
      debouncing.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (param && param.subjectName) {
      const nameOfSubject = param.subjectName;
      const subject = getLearningSubjects.find(
        (i) => nameOfSubject.toLowerCase() === i.subject.toLowerCase()
      );

      if (subject) {
        action.setActiveLearningMenu([subject.id, "learning-resources"]);
        setFilters({ subject: subject.id });
      }

      setSubjectName(nameOfSubject);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const setFileData = (record) => {
    const isVideo = record.type === "mp4";
    const isMediaLink = record?.link.includes(
      "literacy.s3.ap-south-1.amazonaws.com"
    );

    setShowPDFModal(true);
    setVideoData({
      title: record?.title,
      url: isVideo
        ? record?.link
        : isMediaLink
        ? record.link
        : `${IMAGE_BASE_URL}${record?.link}`,
      type: record?.type,
    });
  };

  return (
    <LearningLayout>
      {showPDFModal && videoData && (
        <Modal
          destroyOnClose
          showOkButton={false}
          containerClassName={styles.modalStyle}
          maskClosable={false}
          isModalVisible={showPDFModal}
          title={videoData.title}
          onCancel={() => setShowPDFModal(false)}
        >
          <Row className="mt-20">
            <iframe
              className={styles.pdf}
              title={videoData.title}
              src={`${videoData.url}#toolbar=0`}
            />
          </Row>
        </Modal>
      )}

      <Row>
        <Text
          size={"L"}
          font={"SEMIBOLD"}
          title={`Learning Resources > ${capitilizeFirstLetter(subjectName)}`}
        />
      </Row>

      <Form className="mt-30">
        <Row align={"middle"} gutter={[15, 10]} className="mt-25">
          <Col xxl={7} xl={7} lg={24} md={7} sm={24} xs={24}>
            <TextInput
              allowClear
              value={filters?.title}
              className={styles.txtInput}
              placeholder={"Search by title"}
              suffix={<SearchOutlined />}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, title: e.target.value }))
              }
            />
          </Col>

          {learningCategory.length || learningGrades.length
            ? !isMobile && (
                <Col xxl={3} xl={3} lg={4} md={3} sm={4} xs={3}>
                  <Row justify={"end"}>
                    <Text>Filters</Text>
                  </Row>
                </Col>
              )
            : null}

          <Col xxl={7} xl={7} lg={10} md={7} sm={10} xs={12}>
            {learningCategory && learningCategory.length > 0 && (
              <SelectInput
                allowClear
                className="w-100"
                value={filters?.type}
                placeholder={"Select category"}
                options={learningCategory}
                defaultValue={"standard"}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, type: value }))
                }
              />
            )}
          </Col>

          <Col xxl={7} xl={7} lg={10} md={7} sm={10} xs={12}>
            {learningGrades && learningGrades.length > 0 && (
              <SelectInput
                allowClear
                className="w-100"
                value={grade}
                placeholder={"Select Grade"}
                options={learningGrades}
                onChange={(value) => {
                  const fgrade = learningGrades.find((i) => i.value === value);

                  if (!value) {
                    setGrade();
                    const clonefilters = { ...filters };
                    delete clonefilters.grade_id;
                    setFilters(clonefilters);
                  }

                  if (fgrade && fgrade.key) {
                    setGrade(value);
                    setFilters((prev) => ({ ...prev, grade_id: fgrade.key }));
                  }
                }}
              />
            )}
          </Col>
        </Row>

        <Table
          bordered
          size="small"
          scroll={{ x: 600 }}
          className={"mt-30"}
          loading={resourcesLoading}
          pagination={{ hideOnSinglePage: true, defaultPageSize: 10 }}
          dataSource={learningResources?.map((el, index) => {
            return {
              id: (index + 1).toString(),
              key: index.toString(),
              link: el?.link,
              title: el?.title,
              grade: el?.grade?.title,
              subject: el?.subject?.subject,
              type: el.type,
            };
          })}
          columns={[
            {
              title: "#",
              dataIndex: "id",
              className: "text-center",
              width: 50,
            },
            {
              title: "Title",
              dataIndex: "title",
              render: (record, data) => (
                <div onClick={() => setFileData(data)} className={styles.link}>
                  {record}
                </div>
              ),
            },
            {
              width: 150,
              title: "Level",
              render: (record) => <>{record?.grade}</>,
            },
            {
              width: 150,
              title: "Subject",
              render: (record) => <>{record?.subject}</>,
            },
            {
              title: "Action",
              render: (record) => {
                return (
                  <>
                    {record.type === "mp4" ? (
                      <FlatButton
                        title={"View Video"}
                        type={"Secondary"}
                        className={styles.buttonStyle}
                        onClick={() => {}}
                      />
                    ) : (
                      <FlatButton
                        type={"Secondary"}
                        title={"View PDF"}
                        className={styles.buttonStyle}
                        onClick={() => setFileData(record)}
                      />
                    )}
                  </>
                );
              },
            },
          ]}
        />
      </Form>
    </LearningLayout>
  );
};

export default React.memo(LearningResources);
