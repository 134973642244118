import React, { useCallback, useState } from "react";

import { Space } from "antd";

import CollapsableItem from "./collapsableItem";

import styles from "../../styles.module.css";

const CollapsableView = ({ tabs = [], selectedTab, setSelectedTab }) => {
  const [collapsedId, setCollapsedId] = useState();

  const onHandlePress = useCallback(
    (item) => {
      if (item.type === "collapse") {
        if (typeof collapsedId !== "string") {
          setCollapsedId(item.id);
        } else {
          setCollapsedId();
        }
      } else {
        setSelectedTab({
          itemId: item.id,
          collapsedId: null,
        });
      }
    },
    [collapsedId, setSelectedTab]
  );

  const onHandleCollapsePress = useCallback(
    (item, id) => {
      setSelectedTab({
        itemId: item.id,
        collapsedId: id,
      });
    },
    [setSelectedTab]
  );

  return (
    <div className={styles.collapsableView}>
      <Space size={"small"} direction={"vertical"}>
        {tabs.map((tab) => (
          <CollapsableItem
            {...tab}
            key={tab.id}
            isOpened={collapsedId === tab.id}
            selectedTab={selectedTab}
            handleClick={() => onHandlePress(tab)}
            handleCollapseClick={(i) => onHandleCollapsePress(tab, i)}
          />
        ))}
      </Space>
    </div>
  );
};

export default React.memo(CollapsableView);
