import actionTypes from "../../action-types";
import { countries } from "src/modules/parent/utils/static-data/countries";
import { cities } from "src/modules/parent/utils/static-data/cities";

const INITIAL_STATE = {
  cities: cities,
  countries: countries,
  children: null,
  loadingDemos: true,
  demos: [],
  loadingMyCourses: true,
  myCourses: [],
  loadingInvoices: true,
  invoices: null,
  cards: null,
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.COUNTRIES: {
      return {
        ...state,
        countries: [...action.payload],
      };
    }

    case actionTypes.CITIES: {
      return {
        ...state,
        cities: [...action.payload],
      };
    }
    case actionTypes.SET_CHILDREN: {
      return {
        ...state,
        children: action.payload,
      };
    }
    case actionTypes.REMOVE_CHILD: {
      return {
        ...state,
        children: state.children?.filter(
          (child) => child.id !== action.payload
        ),
      };
    }
    case actionTypes.SET_INVOICE_DATA:
    case actionTypes.SET_PAYMENT_CARDS:
    case actionTypes.SET_DEMOS_AND_COURSES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.ADD_PAYMENT_CARDS: {
      return {
        ...state,
        cards: action.reset
          ? action.payload
          : [...state?.cards, { ...action.payload }],
      };
    }
    case actionTypes.LOG_OUT: {
      return {
        ...state,
        children: null,
        loadingDemos: true,
        demos: [],
        loadingMyCourses: true,
        myCourses: [],
        loadingInvoices: true,
        invoices: null,
        cards: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default profileReducer;
