import React, { Fragment } from "react";
import { Col, Row } from "antd";
import parser from "html-react-parser";
import JWVideoPlayer from "src/modules/parent/components/jw-video-player";
import InstructorInfo from "../instructor-info";

const PackageInfo = ({ packageDetail, isMobile }) => {
  return (
    <Fragment>
      <Row className="pt-10" gutter={[20, 20]}>
        <Col xxl={13} xl={13} lg={24} md={24} sm={24} xs={24}>
          <div>
            <p className={"f-xl blue-color mb-20"}>{packageDetail?.title}</p>
            {!isMobile && (
              <div className={"f-sm mt-15 mb-0 dark-grey-color"}>
                {packageDetail?.description &&
                  parser(packageDetail?.description)}
              </div>
            )}
            {isMobile && (
              <InstructorInfo
                isMobile={isMobile}
                packageDetail={packageDetail}
              />
            )}
          </div>
        </Col>
        <Col xxl={2} xl={1} lg={0} md={0} sm={0} xs={0} />
        {packageDetail?.teacher?.video_id && (
          <Col xxl={9} xl={10} lg={24} md={24} sm={24} xs={24}>
            <JWVideoPlayer
              url={packageDetail?.teacher?.video_id}
              title={packageDetail?.teacher?.name}
              thumbnail={packageDetail?.teacher?.picture}
              teacherId={packageDetail?.teacher?.id}
            />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default PackageInfo;
