import React from "react";

import { Row, Space } from "antd";

import Text from "src/modules/parent/components/text";
import signupImg from "../../../assets/svgs/signup.svg";
import loginImg from "../../../assets/svgs/login.svg";
import seletedIcon from "../../../assets/svgs/selectedIcon.svg";

import styles from "./styles.module.css";

const data = [
  {
    id: "0",
    title: "New Sign up!",
    text: "Signup today to secure your spot in the trivia competition!",
    image: signupImg,
  },
  {
    id: "1",
    title: "Login",
    text: "Already a member? Log in now to secure your spot in the trivia competition!",
    image: loginImg,
  },
];

const Item = ({ id, title, text, image, selected, handleClick = () => {} }) => (
  <div
    onClick={() => handleClick(id)}
    className={[styles.auth, selected ? styles.selected : {}].join(" ")}
  >
    <Space size={"middle"}>
      <img src={selected ? seletedIcon : image} alt="signup" />

      <Space direction={"vertical"}>
        <Text size={"M"} font={"NORMAL"} title={title} />

        <Text
          size={"S"}
          color={"rgba(110, 110, 110, 1)"}
          lHeight={"S"}
          font={"REGULAR"}
          title={text}
        />
      </Space>
    </Space>
  </div>
);

const AuthenticationComponent = ({ handleClick = () => {}, authId }) => (
  <Row className={"mt-20 mb-20"} justify={"center"}>
    <Space size={"middle"} direction={"vertical"}>
      {data.map((item) => (
        <Item
          {...item}
          key={item.id}
          selected={authId === item.id}
          handleClick={handleClick}
        />
      ))}
    </Space>
  </Row>
);

export default AuthenticationComponent;
