import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../src/modules/parent/assets/styles/global.scss";
import "react-multi-carousel/lib/styles.css";
import App from "./App";
import "antd/dist/reset.css";
import "react-chat-elements/dist/main.css";
import "./fonts.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./modules/parent/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
