/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import CardsWrapper from "../../components/cards-wrapper";
import Footer from "../../components/footer";
import Header from "../../components/header";
import * as actions from "../../store/actions";
import styles from "./styles.module.css";
import addIcon from "./../../assets/img/add_green.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ChildrenListItem from "./children-list-item";
import AppLoader from "../../components/app-loader";

const MyChildren = () => {
  const dispatch = useDispatch();
  const action = bindActionCreators(actions, dispatch);
  const { id } = useSelector((state) => state.authReducer.userInfo);
  const { children } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    action.getChildren(id);
  }, []);

  return (
    <div>
      <Header />
      <div className="inner-container">
        <CardsWrapper className={styles.wrapper_body}>
          <div className={"w-100"}>
            <div className="d-flex align-items-center justify-content-between mb-20">
              <p className="f-xl white-color mb-0">My Children</p>
              <div>
                <Link to={"/child/add"}>
                  <div className={styles.add_button}>
                    <span>Add</span>
                    <img
                      className={styles.add_icon}
                      src={addIcon}
                      alt="Add Icon"
                    />
                  </div>
                </Link>
              </div>
            </div>
            {children ? (
              <Fragment>
                {children?.length !== 0 ? (
                  <Fragment>
                    {children?.map((child) => (
                      <ChildrenListItem child={child} key={child?.id} />
                    ))}
                    <div>
                      <Link to={"/child/add"}>
                        <div className="d-flex-inline align-items-center cursor-pointer mb-30">
                          <img
                            className={styles.add_icon}
                            src={addIcon}
                            alt="Add Icon"
                          />
                          <p className="f-md font-weight-400-imp green-color ml-10 mb-0">
                            Another Child
                          </p>
                        </div>
                      </Link>
                    </div>
                  </Fragment>
                ) : (
                  <p className="f-md font-weight-400-imp text-align-center pt-50 pb-50 mt-50 mb-50 white-color">
                    No child added yet!
                  </p>
                )}
              </Fragment>
            ) : (
              <div className="d-flex justify-content-center white-color mt-50 mb-50 pt-50 pb-50">
                <AppLoader className="font-28" />
              </div>
            )}
          </div>
        </CardsWrapper>
      </div>
      <Footer />
    </div>
  );
};

export default MyChildren;
