import caie from "src/modules/parent/assets/boards/caie.png";
import ib from "src/modules/parent/assets/boards/ib.png";
import ocr from "src/modules/parent/assets/boards/ocr.png";
import oxford from "src/modules/parent/assets/boards/oxford.png";
import pearson from "src/modules/parent/assets/boards/pearson.png";
import vce from "src/modules/parent/assets/boards/vce.png";

export const boardImages = {
  cambridge: caie,
  IB: ib,
  OCR: ocr,
  OxfordAQA: oxford,
  PearsonEdExcel: pearson,
  VCE: vce,
};
