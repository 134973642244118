import React, { useState } from "react";

import { Col, Row } from "antd";
import { Formik } from "formik";

import Text from "src/modules/parent/components/text";
import TextInput from "src/modules/parent/components/text-input";
import PhoneWithFlagInput from "src/modules/parent/components/phone-input";
import { defaultCountry } from "src/modules/parent/utils";
import { registrationFormSchema } from "../../../schema";
import { countries } from "src/modules/parent/utils/static-data/countries";

const initialValues = {
  parent_name: "",
  email: "",
  phone: "",
  country: "Pakistan",
  password: "",
};

const SignUpComponent = ({
  formikRef,
  defaultValues,
  handleSubmit = () => {},
}) => {
  const [country, setCountry] = useState(defaultCountry);

  const countryOnChange = (e) => {
    const searchPhone = countries.find((i) => i.id === e.toString());
    if (searchPhone) {
      setCountry(searchPhone);
      formikRef?.current?.setFieldValue("country", searchPhone.title);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={defaultValues || initialValues}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={registrationFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange }) => (
        <Row className={"mt-30 mb-30"} gutter={[20, 20]}>
          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Text
              size={"S"}
              lHeight={"M"}
              color={"rgba(39, 39, 42, 1)"}
              title={"Parent Name"}
            />

            <TextInput
              required={true}
              allowClear={true}
              placeholder={"John Doe"}
              value={values.parent_name}
              error={
                errors.parent_name && touched.parent_name
                  ? errors.parent_name
                  : ""
              }
              onChange={handleChange("parent_name")}
            />
          </Col>

          <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Text
              size={"S"}
              lHeight={"M"}
              color={"rgba(39, 39, 42, 1)"}
              title={"Contact No."}
            />

            <PhoneWithFlagInput
              allowClear={true}
              showSearch={true}
              inputPlaceholder={"Phone"}
              value={values.phone}
              selectedId={defaultCountry.id}
              countryOnChange={countryOnChange}
              countryPlaceholder={"Select"}
              dropdownStyle={{ width: "100%" }}
              error={errors.phone && touched.phone ? errors.phone : ""}
              selectedCountry={country?.value}
              onChange={handleChange("phone")}
            />
          </Col>

          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Text
              size={"S"}
              lHeight={"M"}
              color={"rgba(39, 39, 42, 1)"}
              title={"Parent Email"}
            />

            <TextInput
              type={"email"}
              required={true}
              allowClear={true}
              placeholder={"Email"}
              error={errors.email && touched.email ? errors.email : ""}
              value={values.email}
              onChange={handleChange("email")}
            />
          </Col>

          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Text
              size={"S"}
              lHeight={"M"}
              color={"rgba(39, 39, 42, 1)"}
              title={"Create Password"}
            />

            <TextInput
              required={true}
              type={"password"}
              placeholder={"********"}
              value={values.password}
              error={errors.password && touched.password ? errors.password : ""}
              onChange={handleChange("password")}
            />
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default SignUpComponent;
