import React, { Suspense, useState, useEffect, useCallback } from "react";

import { Row, Col, Space } from "antd";
import { isBrowser, isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { BannerComponent, CoursesComponent } from "./components";
import YoutubeModal from "src/modules/parent/components/modal/youtube-modal";
import ContactModal from "src/modules/parent/components/modal/contactModal";
import {
  ClassSnippet,
  Review,
} from "src/modules/parent/pages/international/components";
import { tabData, reviewData, aboutData } from "./staticData";
import { unAuthOne2One } from "src/modules/parent/utils/static-data";
import AssistMeModal from "src/modules/parent/components/modal/assistMe";
import { RequestDemoModal } from "src/modules/parent/pages/home/components/modal";
import { FloatingButton } from "src/modules/parent/pages/olevels/components";
import {
  checkIsAuthenticated,
  crouselSettings,
  getTimeSlots,
} from "src/modules/parent/utils";
import AppLoader from "src/modules/parent/components/app-loader";
import Footer from "src/modules/parent/components/footer";
import Header from "src/modules/parent/components/header";
import Text from "src/modules/parent/components/text";

import * as actions from "src/modules/parent/store/actions";

import styles from "./styles.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EXPIRY_TIME = 15 * 60 * 1000;
const whatsappMobile = "https://wa.me/";
const videoLink = {
  browser: "hK6MrngW_E0",
  mobile: "hK6MrngW_E0",
};

const LazyBannerNoAuth = React.lazy(() =>
  import("src/modules/parent/pages/home/components/banner")
);
const LazyGetStats = React.lazy(() =>
  import("src/modules/parent/pages/home/components/get-stats")
);

const FindATutor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const action = bindActionCreators(actions, dispatch);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showContactModal2, setShowContactModal2] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(() => {
    const savedData = localStorage.getItem("selectedItem");
    const savedTimestamp = localStorage.getItem("selectedItemTimestamp");

    if (savedData && savedTimestamp) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(savedTimestamp);

      // If the filter is older than 15 minutes, clear it
      if (elapsedTime > EXPIRY_TIME) {
        localStorage.removeItem("selectedItem");
        localStorage.removeItem("selectedItemTimestamp");
        return {}; // Return empty object if expired
      }

      // Otherwise, return the saved filter
      return JSON.parse(savedData);
    }

    // If there's no saved data, return the default value
    return {};
  });
  const [showAssistMe, setShowAssistMe] = useState(false);
  const [packageDetails, setPackageDetails] = useState(null);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [timeSlots, setTimeSlots] = useState(null);
  const { packageReducer } = useSelector((state) => state);
  const { keywordList } = useSelector((state) => state.subjectReducer);
  const [page, setPage] = useState(1);
  const [pageJump, setPageJump] = useState(false);
  const { packageData } = packageReducer;
  const { packages, loading } = packageData;

  useEffect(() => {
    if (state) {
      if (state.teacherPackages && state.teacherPackages.length) {
        const dataPackages = state.teacherPackages;
        action.setPackageData(dataPackages);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (keywordList && !keywordList.length) {
      action.fetchKeywords();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordList]);

  useEffect(() => {
    if (selectedItem) {
      localStorage.setItem("selectedItem", JSON.stringify(selectedItem));
      localStorage.setItem(
        "selectedItemTimestamp",
        new Date().getTime().toString()
      );
    }
  }, [selectedItem]);

  useEffect(() => {
    if (videoId) {
      setShowModal(true);
    }
  }, [videoId]);

  const handleBookADemo = useCallback((data) => {
    if (data && data?.time_slot) {
      const time_slots = getTimeSlots(data?.time_slot);

      setPackageDetails(data);
      setTimeSlots(time_slots);
      setShowDemoModal(true);
    }
  }, []);

  const handleWhatsApp = useCallback((isFloating) => {
    const phone = isFloating ? "923474000862" : "923474106511";
    window.open(`${whatsappMobile}${phone}`);
  }, []);

  const handleContactUsBtn = () => {
    window.open(`${whatsappMobile}923460086795`);
  };

  const handleClickContact = useCallback((isFloating) => {
    if (!isBrowser) handleWhatsApp(isFloating);
    else {
      if (isFloating) setShowContactModal2(true);
      else setShowContactModal(true);
    }
  }, []);

  const navigateToChat = (id) => {
    if (checkIsAuthenticated()) {
      navigate(`/chat/${id}`);
    } else {
      action.setRegisterData({
        show: true,
        type: "teacherChat",
        chat: {
          teacherId: id,
        },
      });
    }
  };

  return (
    <div>
      {videoId && showModal && (
        <YoutubeModal
          show={showModal}
          url={videoId}
          setShow={(data) => {
            setShowModal(data);
            setVideoId("");
          }}
        />
      )}

      <Header />

      <BannerComponent
        isLoading={loading}
        page={page}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handlePageJump={setPageJump}
        setPage={setPage}
      />

      {!isMobile && (
        <div className="app-container mt-20">
          <Row gutter={[20, 20]} className={styles.aboutCenter}>
            {tabData.map((item) => (
              <Col key={item.id} xxl={6} xl={6} lg={6} md={12} sm={12} xs={24}>
                <div className={styles.tabs}>
                  <Row justify={"center"}>
                    <Space size={"middle"} direction={"horizontal"}>
                      <img src={item.icon} alt={"checkmark"} />

                      <Text
                        size={"M2"}
                        color={item.color}
                        font={"SEMIBOLD"}
                        title={item.title}
                      />
                    </Space>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}

      <CoursesComponent
        data={packages}
        pageJump={pageJump}
        grade={selectedItem?.grades && selectedItem?.grades[0]}
        subject={selectedItem?.keywords && selectedItem?.keywords[0]}
        setPageJump={setPageJump}
        handleBookADemo={handleBookADemo}
        handleShowModal={setVideoId}
        handleChat={navigateToChat}
        paginationHandler={setPage}
        handleContact={handleContactUsBtn}
      />

      <Review isHeading={false} reviewsData={reviewData} />

      <div className="mb-20">
        <Suspense fallback={<AppLoader className="font-28" />}>
          <LazyBannerNoAuth
            data={unAuthOne2One}
            handleClick={() => handleClickContact(false)}
          />
        </Suspense>
      </div>

      <Row justify={"center"} className="mt-30 mb-30">
        <Text
          size={"XXL"}
          font={"SEMIBOLD"}
          color={"rgba(44, 44, 44, 1)"}
          title={"Why Choose Dot & Line Tutors?"}
        />
      </Row>

      <Row gutter={[20, 20]} className={styles.appStats} justify={"center"}>
        {isMobile ? (
          <Suspense fallback={<AppLoader className="font-28" />}>
            <div className="app-container">
              <Slider {...crouselSettings}>
                {aboutData.map((item, index) => (
                  <Col className="mb-5 mt-5" key={item.id}>
                    <LazyGetStats
                      image={item.image}
                      text={item.text}
                      description={item.description}
                    />
                  </Col>
                ))}
              </Slider>
            </div>
          </Suspense>
        ) : (
          <Suspense fallback={<AppLoader className="font-28" />}>
            <Row justify={"center"} gutter={[30, 20]}>
              {aboutData.map((item, index) => (
                <Col key={item.id}>
                  <LazyGetStats
                    image={item.image}
                    text={item.text}
                    description={item.description}
                  />
                </Col>
              ))}
            </Row>
          </Suspense>
        )}
      </Row>

      <ClassSnippet videoLink={videoLink} customStyles={styles.classSnippet} />

      <Footer />

      {showDemoModal && (
        <RequestDemoModal
          isVisible={showDemoModal}
          onCancel={() => setShowDemoModal(false)}
          packageData={packageDetails}
          timeSlots={timeSlots}
        />
      )}

      {/* WHATSAPP FLOATING BUTTON */}
      <FloatingButton
        handlePress={() => {
          handleClickContact(true);
        }}
      />

      <ContactModal
        show={showContactModal}
        title={"Contact Academic Counselor"}
        name={"Sarah Merchant:"}
        color={"#2ca58d"}
        number={"+923474106511"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal(false)}
      />

      <ContactModal
        show={showContactModal2}
        title={"Contact Representative"}
        name={"Masooma Batool:"}
        color={"#2ca58d"}
        number={"+923474000862"}
        className={styles.contactBtn}
        setShow={() => setShowContactModal2(false)}
      />

      <AssistMeModal
        show={showAssistMe}
        handleClose={() => setShowAssistMe(false)}
        handleBookADemo={handleBookADemo}
        handleShowVideo={setVideoId}
      />
    </div>
  );
};

export default React.memo(FindATutor);
