import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Row, Col } from "antd";
import { isBrowser } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import {
  GreetingText,
  WhatsNew,
  MyChildrens,
  ProgressTracker,
  Card,
  ClassesTab,
  ChatWidget,
} from "../../components";
import {
  ReviewModal,
  // FreeClassModal,
  RequestDemoModal,
  ProgressTrackerModal,
  DemosModal,
} from "../../components/modal";
import Modal from "src/modules/parent/components/modal";

import AppLoader from "src/modules/parent/components/app-loader";
import SuccessModal from "src/modules/parent/components/trivia-registration-form/components/modal/success";
import TriviaRegistrationForm from "src/modules/parent/components/trivia-registration-form";
import Text from "src/modules/parent/components/text";
import girl from "../../assets/svgs/girl.svg";
import total_packages from "../../assets/svgs/total_packages.svg";
import { markAnAttendance } from "src/modules/parent/store/actions";
import AssistMeModal from "src/modules/parent/components/modal/assistMe";
import {
  attendClass,
  checkIsAuthenticated,
  getFreeClassMessage,
  getTimeSlots,
  groupArrayOfObjects,
  openLinkInNewTab,
} from "src/modules/parent/utils";
import hours_taught from "../../assets/svgs/hours_taught.svg";
import students_retention_rate from "../../assets/svgs/students_retention_rate.svg";
import demo_conversion_rate from "../../assets/svgs/demo_conversion_rate.svg";
import actionTypes from "src/modules/parent/store/action-types";
import YoutubeModal from "src/modules/parent/components/modal/youtube-modal";
import {
  zoomLinkNotification,
  // freeClassNotification,
  onSiteNotification,
} from "src/modules/parent/utils/notificationData";
import { crouselSettings } from "src/modules/parent/utils";
import * as actions from "src/modules/parent/store/actions";
import {
  attendDemoBtnUpcomingClasses,
  bookDemoRecommendedClasses,
  chatWithTutorBtnRecommendedSection,
  chatWithTutorBtnTeacherAvatar,
  joinClassBtnUpcomingClasses,
  postReviewBtnTeacherCard,
  trackEvents,
} from "src/modules/parent/utils/events/query";

import styles from "../../styles.module.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const greetings = [
  "Unlock your true potential, you are capable of amazing things!",
  "Learning can be fun so stay curious!",
  "Mistakes are the stepping stones to success - embrace them with a smile!",
  "Connect, Learn and Grow, because great minds learn together!",
];

const AuthenticatedHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [demoId, setDemoId] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAssistMe, setShowAssistMe] = useState(false);
  const [showAllDemos, setShowAllDemos] = useState(false);
  const [showRescheduledDemoModal, setShowRescheduledDemoModal] =
    useState(false);
  const [demoPackage, setDemoPackage] = useState({});
  const [timeSlots, setTimeSlots] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  // const [isFreeClassLoading, setIsFreeClassLoading] = useState(false);
  // const [showFreeClassModal, setShowFreeClassModal] = useState(false);
  const [reviewData, setReviewData] = useState({});
  // const [freeClassData, setFreeClassData] = useState({});
  const action = bindActionCreators(actions, dispatch);
  const [counting, setCounting] = useState(false);
  const {
    userInfo,
    selectedChild,
    childLoading,
    dashboardData,
    whatsNewData,
    eventData,
    trackerData,
  } = useSelector((state) => state.authReducer);
  const {
    progress_tracker,
    enrollments,
    teachers,
    recomended_packages,
    trivia_packages,
    // free_packages,
  } = dashboardData || {};
  const { children } = useSelector((state) => state.profileReducer);
  const [progressData, setProgressData] = useState(null);
  const [showProgressModal, setShowProgressModal] = useState(false);

  useEffect(() => {
    if (videoId) {
      setShowModal(true);
    }
  }, [videoId]);

  useEffect(() => {
    if (!childLoading) {
      setTimeout(() => {
        setCounting(true);
      }, 500);
    }
  }, [childLoading]);

  const onSelectChild = useCallback(
    (id, isParent = false) => {
      setCounting(false);

      action.getUpcomingEventsData(!isParent ? id : "0010");
      action.getWhatsNewData(!isParent ? id : null);

      action.getDashboardData(id);
      action.getTrackerData(id);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (children && !children.length) {
      if (userInfo && userInfo.id) {
        onSelectChild(userInfo.id, true);
      }
    }
  }, [children, onSelectChild, userInfo]);

  useEffect(() => {
    if (children && children.length) {
      actions
        .getStudentEvent()
        .then((e) => {
          if (e && e.student_id) {
            return onSelectChild(e.student_id);
          }

          return onSelectChild(children[0].id);
        })
        .catch((error) => {
          console.log("🚀 ~ actions.getStudentEvent ~ error:", error);
        });
    }
  }, [children, onSelectChild]);

  useEffect(() => {
    if (userInfo.id) {
      action.getChildren(userInfo.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const getValue = useCallback(
    (key) => {
      if (progress_tracker && counting) {
        return Number(key);
      }
      return 0;
    },
    [counting, progress_tracker]
  );

  const getChildName = useMemo(() => {
    if (selectedChild && children && children.length) {
      const getChild = children.find((child) => child.id === selectedChild);

      if (getChild) {
        const name =
          getChild.name.charAt(0).toUpperCase() + getChild.name.slice(1);

        return `${name}`;
      }
    }

    return "";
  }, [children, selectedChild]);

  const markAttendance = (enrolId) => {
    return new Promise((resolve, reject) => {
      markAnAttendance(enrolId).then(resolve).catch(reject);
    });
  };

  const onClickChat = (id) => {
    navigate(`/chat/${id}`);
  };

  const goToProgress = (id) => {
    navigate(`/course/enroll/${id}?tab=progress_reports`);
  };

  const handleView = (params) => {
    const { type, link } = params;
    setVideoData({ title: type, url: link });
    setShowPDFModal(true);
  };

  const onAttendClass = (item) => {
    if (item && item?.package && item?.package?.package_mode !== "online") {
      dispatch({
        type: actionTypes.NOTIFICATIONDATA,
        payload: onSiteNotification,
      });
    } else {
      if (item?.slot && item?.slot?.zoom_link) {
        const link = item?.slot?.zoom_link;

        trackEvents(joinClassBtnUpcomingClasses);
        openLinkInNewTab(link);
        markAttendance(item.id);
      } else {
        dispatch({
          type: actionTypes.NOTIFICATIONDATA,
          payload: zoomLinkNotification,
        });
      }
    }
  };

  const onHandleReview = (item) => {
    setReviewData(item);
    setShowReviewModal(true);
  };

  // const onRegisterFreeClass = () => {
  //   setIsFreeClassLoading(true);

  //   if (freeClassData) {
  //     const data = {
  //       teacher_id: Number(freeClassData?.teacher_id),
  //       package_id: Number(freeClassData?.package_id),
  //     };

  //     action
  //       .registerFreeClass(data)
  //       .then(() => {
  //         setIsFreeClassLoading(false);
  //         setShowFreeClassModal(false);

  //         dispatch({
  //           type: actionTypes.NOTIFICATIONDATA,
  //           payload: freeClassNotification,
  //         });
  //       })
  //       .catch(() => {
  //         setIsFreeClassLoading(false);
  //         setShowFreeClassModal(false);
  //       });
  //   }
  // };

  const handleBookADemo = (item) => {
    const time_slots = item?.time_slots || item?.time_slot;
    const slots = getTimeSlots(time_slots);
    setShowDemoModal(true);
    setTimeSlots(slots);
    setDemoPackage(item);
  };

  const onHandleShow = (idDemo) => {
    if (eventData && eventData.demo && eventData.demo.length) {
      const findDemo = eventData.demo.find((item) => item.id === idDemo);

      if (findDemo) {
        const times = findDemo?.package?.time_slots || [];
        const slots = getTimeSlots(times, findDemo?.demo_date);
        setShowRescheduledDemoModal(true);
        setTimeSlots(slots);
        setDemoPackage(findDemo.package);
        setDemoId(findDemo.id);
      }
    }
  };

  const handleDemoRescheduled = (item, index) => {
    if (demoId) {
      setLoadingId(index);

      const data = {
        id: demoId,
        slot_id: item.id,
        date: moment(item.date).format("YYYY-MM-DD"),
      };

      actions
        .demoReschedule(data)
        .then(() => {
          setLoadingId(null);
          setShowRescheduledDemoModal(false);
          setShowAllDemos(false);

          if (selectedChild) {
            onSelectChild(selectedChild);
          }
        })
        .catch(() => {
          setShowAllDemos(false);
          setLoadingId(null);
          setShowRescheduledDemoModal(false);
        });
    }
  };

  const getCurrentGreeting = useMemo(() => {
    const startDate = moment("2024-01-01"); // Start date in 'YYYY-MM-DD' format
    const today = moment();
    const diffInDays = today.diff(startDate, "days");

    // Calculate the index based on days passed and update every 6 days
    const currentIndex = Math.floor(diffInDays / 6) % greetings.length;
    return greetings[currentIndex];
  }, []);

  const onSetProgressTracker = useCallback(
    (key) => {
      let data = {};

      switch (key) {
        case "Topics Mastered":
          data.type = "topics";
          data.data = trackerData.topic_mastered;
          break;

        case "Classes taken":
          data.type = "classes";
          data.data = trackerData.classes_taken;
          break;

        case "Certificates Received":
          data.type = "certificates";
          data.data = trackerData.certificates_received;
          break;

        case "Pending Invoices":
          data.type = "invoices";
          data.data = groupArrayOfObjects(trackerData.pending_invoices);
          break;

        default:
          break;
      }

      setProgressData(data);
      setShowProgressModal(true);
    },
    [trackerData]
  );

  const onHandleNamePress = (id) => {
    if (id) {
      navigate(`/teachers/${id}`);
    }
  };

  const onAttendDemo = useCallback(
    (idDemo) => {
      if (eventData && eventData.demo && eventData.demo.length) {
        const findDemo = eventData.demo.find((item) => item.id === idDemo);

        if (findDemo) {
          const link = findDemo?.slot?.zoom_link;

          if (link) {
            trackEvents(attendDemoBtnUpcomingClasses);
            window.open(link, "_blank");
          }
        }
      }
    },
    [eventData]
  );

  // const handleRegisterFreeClass = (item) => {
  //   setShowFreeClassModal(true);
  //   setFreeClassData({
  //     teacher_id: item?.teacher?.id,
  //     package_id: item?.id,
  //     teacherName: item?.teacher?.name,
  //     date: moment(item?.slots?.start_date).format("YYYY-MM-DD"),
  //   });
  // };

  const handleRegisterTriviaCompetition = () => {
    setFormVisible(true);
  };

  const handleJoin = (item) => {
    if (item?.slots) {
      const slotDay = moment(item?.slots?.start_time)
        ?.format("ddd")
        ?.toLocaleLowerCase();
      const isFree = true;
      if (attendClass(item?.slots, slotDay, isFree)) {
        if (item?.zoom_link) {
          openLinkInNewTab(item?.zoom_link);
        }
      } else {
        getFreeClassMessage(item?.slots, slotDay);
      }
    }
  };

  const onToggleDemosModal = () => setShowAllDemos((prev) => !prev);

  return (
    <Fragment>
      {showModal && videoId && (
        <YoutubeModal
          show={showModal}
          url={videoId}
          setShow={(data) => {
            setVideoId(null);
            setShowModal(data);
          }}
        />
      )}

      <div className="inner-container mb-50">
        {childLoading && <AppLoader fullPage />}

        <GreetingText
          image={girl}
          name={userInfo?.name || ""}
          title={getCurrentGreeting}
        />

        {!isBrowser && (
          <MyChildrens
            data={children}
            handleSelect={onSelectChild}
            loading={childLoading}
            onHandleAdd={() => navigate("/child/add?redirect_to=/")}
            selectedChild={selectedChild}
          />
        )}

        {!isBrowser && <div className={"hrow mt-20"} />}

        <Row gutter={[20, 20]} className={"mt-24"}>
          <Col
            className="h-100-imp"
            xxl={16}
            xl={16}
            lg={16}
            md={24}
            sm={24}
            xs={24}
          >
            <ClassesTab
              classData={eventData?.class}
              handleMarkAttendance={onAttendClass}
              handleReschedule={onHandleShow}
              attendDemo={onAttendDemo}
              handleShowDemos={onToggleDemosModal}
              demo={
                eventData?.demo && eventData?.demo.length
                  ? eventData.demo[0]
                  : null
              }
            />

            {!isBrowser && <div className={"hrow mt-30 mb-30"} />}

            <WhatsNew
              data={whatsNewData}
              handleVideoClick={setVideoId}
              isLoading={childLoading}
            />
          </Col>

          <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            {isBrowser && (
              <MyChildrens
                data={children}
                handleSelect={onSelectChild}
                loading={childLoading}
                onHandleAdd={() => navigate("/child/add?redirect_to=/")}
                selectedChild={selectedChild}
              />
            )}

            <div className={isBrowser ? "white_box p-20" : "mt-10 mb-10"}>
              <Text color={"rgba(0, 94, 255, 1)"} font="SEMIBOLD" size="L">
                {"Progress Tracker"}
              </Text>

              {isBrowser ? (
                <>
                  <ProgressTracker
                    title={"Topics Mastered"}
                    image={total_packages}
                    counting={counting}
                    handleClick={onSetProgressTracker}
                    getValue={() => getValue(progress_tracker?.topic_mastered)}
                  />

                  <ProgressTracker
                    title={"Classes taken"}
                    image={hours_taught}
                    counting={counting}
                    handleClick={onSetProgressTracker}
                    getValue={() => getValue(progress_tracker?.classes_taken)}
                  />

                  <ProgressTracker
                    title={"Certificates Received"}
                    image={students_retention_rate}
                    counting={counting}
                    handleClick={onSetProgressTracker}
                    getValue={() =>
                      getValue(progress_tracker?.certificates_received)
                    }
                  />

                  <ProgressTracker
                    title={"Pending Invoices"}
                    image={demo_conversion_rate}
                    counting={counting}
                    handleClick={onSetProgressTracker}
                    getValue={() =>
                      getValue(progress_tracker?.pending_invoices)
                    }
                  />
                </>
              ) : (
                <Slider {...crouselSettings}>
                  <ProgressTracker
                    title={"Topics Mastered"}
                    image={total_packages}
                    counting={counting}
                    handleClick={onSetProgressTracker}
                    getValue={() => getValue(progress_tracker?.topic_mastered)}
                  />

                  <ProgressTracker
                    title={"Classes taken"}
                    image={hours_taught}
                    counting={counting}
                    handleClick={onSetProgressTracker}
                    getValue={() => getValue(progress_tracker?.classes_taken)}
                  />

                  <ProgressTracker
                    title={"Certificates Received"}
                    image={students_retention_rate}
                    counting={counting}
                    handleClick={onSetProgressTracker}
                    getValue={() =>
                      getValue(progress_tracker?.certificates_received)
                    }
                  />

                  <ProgressTracker
                    title={"Pending Invoices"}
                    image={demo_conversion_rate}
                    counting={counting}
                    handleClick={onSetProgressTracker}
                    getValue={() =>
                      getValue(progress_tracker?.pending_invoices)
                    }
                  />
                </Slider>
              )}
            </div>
          </Col>

          {!isBrowser && <div className={"hrow"} />}

          <Card
            type={"course"}
            title={"Trivia Competition 2025!"}
            isPrimaryBtn
            isSecondaryBtn
            primaryBtnText={"Register Now!"}
            secondaryBtnText={"Attend Class"}
            isTrivia
            onPressPrimaryBtn={handleRegisterTriviaCompetition}
            onPressSecondaryBtn={handleJoin}
            data={trivia_packages}
          />

          {!isBrowser && <div className={"hrow"} />}

          {enrollments && enrollments.length > 0 && (
            <Card
              type={"course"}
              title={getChildName ? `${getChildName}'s Classes` : "Classes"}
              isPrimaryBtn
              isSecondaryBtn
              onPressPrimaryBtn={onAttendClass}
              onPressSecondaryBtn={(item) => goToProgress(item.id)}
              data={enrollments}
            />
          )}

          {!isBrowser && <div className={"hrow"} />}

          {/* Free Classes */}
          {/* {free_packages && free_packages.length > 0 && (
            <Card
              type={"course"}
              isPrimaryBtn
              isSecondaryBtn
              primaryBtnText={"Register Now!"}
              secondaryBtnText={"Attend Class"}
              title={"Free Classes"}
              data={free_packages}
              onPressPrimaryBtn={handleRegisterFreeClass}
              onPressSecondaryBtn={handleJoin}
            />
          )} */}

          <Card
            type={"course"}
            title={
              getChildName
                ? `Classes Recommended for ${getChildName}!`
                : "Classes Recommended"
            }
            isPrimaryBtn
            isSecondaryBtn
            primaryBtnText={"Chat with Tutor"}
            secondaryBtnText={"Book a Demo"}
            onPressSecondaryBtn={(i) => {
              trackEvents(bookDemoRecommendedClasses);
              handleBookADemo(i);
            }}
            onPressPrimaryBtn={(item) => {
              trackEvents(chatWithTutorBtnRecommendedSection);
              onClickChat(item?.teacher?.id);
            }}
            data={recomended_packages}
          />

          {!isBrowser && <div className={"hrow"} />}

          {teachers && teachers.length > 0 && (
            <Card
              type={"teacher"}
              title={getChildName ? `${getChildName}'s Teachers` : "Teachers"}
              data={teachers}
              onPressPrimaryBtn={(id) => {
                trackEvents(chatWithTutorBtnTeacherAvatar);
                onClickChat(id);
              }}
              onPressNameClick={onHandleNamePress}
              onPressSecondaryBtn={onHandleReview}
            />
          )}
        </Row>
      </div>

      {/* {isBrowser && <AppBanner />} */}

      <ReviewModal
        isVisible={showReviewModal}
        onCancel={() => setShowReviewModal(false)}
        onSubmit={() => {
          trackEvents(postReviewBtnTeacherCard);
          setShowReviewModal(false);
        }}
        {...reviewData}
      />

      {/* Free Classes */}
      {/* {freeClassData && showFreeClassModal && (
        <FreeClassModal
          isVisible={showFreeClassModal}
          onCancel={() => setShowFreeClassModal(false)}
          date={freeClassData?.date}
          onHandleRegister={onRegisterFreeClass}
          teacherName={freeClassData?.teacherName}
          isLoading={isFreeClassLoading}
        />
      )} */}

      {showDemoModal && (
        <RequestDemoModal
          isVisible={showDemoModal}
          onCancel={() => {
            setShowAssistMe(false);
            setShowDemoModal(false);
          }}
          packageData={demoPackage}
          timeSlots={timeSlots}
        />
      )}

      {showRescheduledDemoModal && (
        <RequestDemoModal
          isVisible={showRescheduledDemoModal}
          isScheduled
          onCancel={() => setShowRescheduledDemoModal(false)}
          packageData={demoPackage}
          timeSlots={timeSlots}
          loadingId={loadingId}
          handleDemoRescheduled={handleDemoRescheduled}
        />
      )}

      <ProgressTrackerModal
        data={progressData}
        handleView={handleView}
        onAssistMe={() => setShowAssistMe(true)}
        onCancel={() => setShowProgressModal(false)}
        isVisible={showProgressModal}
      />

      {showPDFModal && videoData && (
        <Modal
          destroyOnClose
          showOkButton={false}
          containerClassName={styles.modalStyle}
          maskClosable={false}
          isModalVisible={showPDFModal}
          title={videoData.title}
          onCancel={() => {
            setVideoData(null);
            setShowPDFModal(false);
          }}
        >
          <Row className="mt-20">
            <iframe
              className={styles.pdf}
              title={videoData.title}
              src={`${videoData.url}#toolbar=0`}
            />
          </Row>
        </Modal>
      )}

      {showAllDemos && (
        <DemosModal
          isVisible={showAllDemos}
          handleDemoReschedule={onHandleShow}
          handleAttendDemo={onAttendDemo}
          onCancel={onToggleDemosModal}
          data={eventData?.demo}
        />
      )}

      <ChatWidget />

      {showAssistMe && (
        <AssistMeModal
          show={showAssistMe}
          handleClose={() => setShowAssistMe(false)}
          handleBookADemo={handleBookADemo}
          handleShowVideo={setVideoId}
        />
      )}

      {/* Trivia Registration Form */}
      {formVisible && (
        <TriviaRegistrationForm
          show={formVisible}
          setShow={setFormVisible}
          userAlreadyLoggedIn={checkIsAuthenticated()}
          onSuccess={() => setShowSuccessModal(true)}
        />
      )}

      {/* Trivia Successfull Registration */}
      <SuccessModal
        show={showSuccessModal}
        onSuccess={() => setShowSuccessModal(false)}
        setShow={setShowSuccessModal}
      />
    </Fragment>
  );
};

export default AuthenticatedHome;
