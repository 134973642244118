import React, { useCallback, useMemo } from "react";

const sizes = {
  XXL4: "clamp(32px, 5vw, 40px)",
  XXL3: "clamp(24px, 3vw, 32px)",
  XXL2: "clamp(20px, 2.8vw, 28px)",
  XXL: "clamp(18px, 2.5vw, 22px)",
  XL: "clamp(16px, 2.3vw, 20px)",
  L: "clamp(16px, 2.5vw, 20px)",
  M: "clamp(14px, 2vw, 18px)",
  M2: "clamp(12px, 1.8vw, 16px)",
  S: "clamp(10px, 1.5vw, 14px)",
  XS: "clamp(8px, 1.2vw, 12px)",
  XXS: "clamp(6px, 1vw, 10px)",
  DEFAULT: "clamp(12px, 2vw, 16px)",
};

const lineHeight = {
  DEFAULT: 1,
  XXS: 1.1,
  XS: 1.3,
  S: 1.5,
  M: 1.7,
  M2: 1.6,
  L: 1.9,
  XL: 2.3,
  XXL: 2.5,
  XXL2: 2.6,
};

const fontWeight = {
  LIGHTEST: "300",
  LIGHTER: "400",
  NORMAL: "500",
  SEMIBOLD: "600",
  BOLD: "700",
  BOLDER: "900",
};

const Text = ({
  customStyles,
  title,
  size,
  font,
  color,
  lHeight,
  bold,
  className,
  children,
  center,
  handleClick = () => {},
  hoveredTitle = "",
}) => {
  const getFontWeight = useCallback(() => {
    if (bold) {
      return fontWeight.BOLD;
    } else if (font && !bold) {
      return fontWeight[font];
    }
    return fontWeight.NORMAL;
  }, [bold, font]);

  const defaultStyle = useMemo(() => {
    return {
      fontSize: size ? sizes[size] : sizes.DEFAULT,
      color: color ? color : "#000",
      lineHeight: lHeight ? lineHeight[lHeight] : lineHeight.XXS,
      fontWeight: getFontWeight(),
    };
  }, [color, getFontWeight, lHeight, size]);

  const getTextStyles = useCallback(() => {
    let stylesData = { ...defaultStyle };

    if (customStyles) {
      stylesData = { ...stylesData, ...customStyles };
    }

    if (center) {
      stylesData.textAlign = "center";
    }

    return stylesData;
  }, [center, customStyles, defaultStyle]);

  return (
    <div
      title={hoveredTitle || children} // Show full text on hover
      onClick={handleClick}
      className={className}
      style={getTextStyles()}
    >
      {title || children}
    </div>
  );
};

export default Text;
