import React from "react";

import { Row, Col, Space } from "antd";
import Carousel from "react-multi-carousel";
import { isBrowser } from "react-device-detect";

import Text from "src/modules/parent/components/text";
import { responsive2 } from "src/modules/parent/utils";

import styles from "../../styles.module.css";

const TabComponent = ({ data = [], handleClick = () => {} }) => {
  return isBrowser ? (
    <Row gutter={[20, 20]}>
      {data.map((item) => (
        <Col key={item.id} xxl={6} xl={6} lg={12} md={12} sm={24} xs={24}>
          <div onClick={() => handleClick(item.title)} className={styles.tab}>
            <div className={styles.tabtitle}>
              <Space align={"center"}>
                <img src={item.icon} alt={"TabImage1"} />

                <Text size={"M"} font={"SEMIBOLD"} title={item.title} />
              </Space>
            </div>

            <div className={styles.hrow} />

            <div className={styles.tabDescription}>
              <Text
                size={"S"}
                color={"#2C2C2C"}
                font={"500"}
                center
                title={item.description}
              />
            </div>
          </div>
        </Col>
      ))}
    </Row>
  ) : (
    <Carousel responsive={responsive2}>
      {data.map((item) => (
        <div
          key={item.id}
          onClick={() => handleClick(item.title)}
          className={styles.tab}
        >
          <div className={styles.tabtitle}>
            <Space align={"center"}>
              <img src={item.icon} alt={"TabImage1"} />

              <Text size={"M"} font={"SEMIBOLD"} title={item.title} />
            </Space>
          </div>

          <div className={styles.hrow} />

          <div className={styles.tabDescription}>
            <Text
              size={"S"}
              color={"#2C2C2C"}
              font={"500"}
              center
              title={item.description}
            />
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default React.memo(TabComponent);
