import React from "react";

import { Col } from "antd";
import Carousel from "react-multi-carousel";

import { responsiveBlogs } from "src/modules/parent/utils";

const Testimonials = ({ data = [] }) => (
  <div className={"mt-30 mb-30"}>
    <Carousel
      autoPlay
      autoPlaySpeed={3000}
      infinite
      responsive={responsiveBlogs}
    >
      {data.map((item) => (
        <Col key={item.id}>
          <img
            src={item.image}
            width={"100%"}
            style={{ padding: "10px" }}
            alt="testimonials"
          />
        </Col>
      ))}
    </Carousel>
  </div>
);

export default React.memo(Testimonials);
