import { legacy_createStore as createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
  // Here in 'whitelist', we define the reducers which we want to save in the persistence storage.
  whitelist: [
    "authReducer",
    "profileReducer",
    "packageReducer",
    "subjectReducer",
    "teacherReducer",
    "generalReducer",
    "chatReducer",
    "learningReducer",
  ],
  // Here in 'blacklist', we define the reducers which we do not want to save in the persistence storage.
  blacklist: ["notificationReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk);
export const store = createStore(persistedReducer, middleware);
export const persistor = persistStore(store);
