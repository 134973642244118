import React, { useState, useCallback, useEffect } from "react";

import { Row, Space } from "antd";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { isBrowser, isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

import Text from "src/modules/parent/components/text";
import { emptyKeysRemover } from "src/modules/parent/utils";
import img1 from "../../assets/png/img1.png";
import img2 from "../../assets/png/img2.png";
import img3 from "../../assets/png/img3.png";
import SelectModal from "src/modules/parent/pages/olevels/components/selectModal";
import { SearchBoxComponent, FilterComponent, FilterMobile } from "../index";
import FilterModalComponent from "../filterMobile/modal";
import * as actions from "src/modules/parent/store/actions";

import styles from "../../styles.module.css";

const initialOptions = {
  page: 1,
  limit: 6,
};

const excludedKeys = ["grades", "keywords", "teacherPackages"];

const BannerComponent = ({
  isLoading = false,
  page = 1,
  selectedItem = {},
  setSelectedItem = () => {},
  setPage = () => {},
  handlePageJump = () => {},
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const action = bindActionCreators(actions, dispatch);
  const [dropdownData, setDropdownData] = useState(null);
  const [filterToggle, setFilterToggle] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const { subjectReducer } = useSelector((state) => state);
  const { keywordList } = subjectReducer;

  useEffect(() => {
    // Calculate count excluding specific keys
    const count = Object.keys(selectedItem).filter(
      (key) => !excludedKeys.includes(key)
    ).length;

    setFilterCount(count);
  }, [selectedItem]); // Runs whenever selectedItem changes

  useEffect(() => {
    if (state && Object.keys(state).length) {
      setSelectedItem(state);
    }
  }, [setSelectedItem, state]);

  const filterHandler = useCallback((queryObj) => {
    action.fetchPaginatedPackages(emptyKeysRemover(queryObj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = useCallback(
    (page, trigger, otherParams) => {
      const items = otherParams ? otherParams : selectedItem;
      const params = {
        ...items,
        ...initialOptions,
      };

      if (page) {
        params.page = page;
      }

      setFilterToggle(false);
      filterHandler(params);

      // if (trigger) {
      //   handlePageJump(trigger);
      // }
    },
    [filterHandler, selectedItem]
  );

  useEffect(() => {
    if (page) {
      onSearch(page, false, state);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleItemSelect = useCallback(
    ({ name, label }) => {
      setShowSelectModal(false);
      setPage(1);
      setSelectedItem((prev) => ({
        ...prev,
        [name]: prev[name]?.includes(label) ? [] : [label],
      }));
    },
    [setPage, setSelectedItem]
  );

  const handleClear = useCallback(
    (name) => {
      const cloneSelectedItems = { ...selectedItem };

      delete cloneSelectedItems[name];

      setSelectedItem(cloneSelectedItems);
      onSearch(page, false, cloneSelectedItems);
    },
    [onSearch, page, selectedItem, setSelectedItem]
  );

  const removeItem = (key) => {
    setSelectedItem((prev) => {
      const newSelectedItem = { ...prev };
      delete newSelectedItem[key];
      return newSelectedItem;
    });
  };

  const handleClearAll = useCallback(() => {
    setSelectedItem({});
    onSearch(page, false, {});
  }, [onSearch, page, setSelectedItem]);

  const onSelect = useCallback(
    (item) => {
      setShowSelectModal(true);

      setDropdownData({
        ...item,
        data: item?.name === "keywords" ? keywordList : item.data,
      });
    },
    [keywordList]
  );

  const handleChange = useCallback(
    ({ name, key }) => {
      setPage(1);
      setSelectedItem((prev) => ({
        ...prev,
        [name]: key,
      }));
    },
    [setPage, setSelectedItem]
  );

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <Space className={"w-100"} size={"large"} direction={"vertical"}>
          {isBrowser && (
            <Text
              className={styles.textFirst}
              color={"rgba(44, 44, 44, 1)"}
              font={"LIGHTER"}
              title={"Your Journey to"}
            />
          )}

          {isBrowser && (
            <Text
              className={styles.titleText}
              color={"rgba(44, 44, 44, 1)"}
              font={"SEMIBOLD"}
              title={"Great Grades!"}
            />
          )}

          {isMobile && (
            <Row justify={"center"} align={"middle"}>
              <Text
                className={[styles.textFirst, "mr-5"].join(" ")}
                color={"rgba(44, 44, 44, 1)"}
                font={"LIGHTER"}
                title={"Your Journey to"}
              />

              <Text
                className={styles.titleText}
                color={"rgba(44, 44, 44, 1)"}
                font={"SEMIBOLD"}
                title={"Great Grades!"}
              />
            </Row>
          )}

          <SearchBoxComponent
            isLoading={isLoading}
            handleClear={handleClear}
            selectedItem={selectedItem}
            handleSearch={() => onSearch(page, true)}
            handleSelectField={onSelect}
          />

          {isBrowser ? (
            <FilterComponent
              handleChange={handleChange}
              selectedItem={selectedItem}
              handleClear={handleClear}
            />
          ) : (
            <FilterMobile
              count={filterCount}
              handleClearAll={handleClearAll}
              selectedItem={selectedItem}
              handleFilters={() => setFilterToggle(true)}
            />
          )}
        </Space>
      </div>

      {isBrowser && <img src={img1} alt="rocket" className={styles.img1} />}
      <img src={img2} alt="img2" className={styles.img2} />
      {isBrowser && <img src={img3} alt="img3" className={styles.img3} />}

      <SelectModal
        isMultiple={dropdownData?.isMultiple}
        show={showSelectModal}
        select={dropdownData}
        handleSelectItem={handleItemSelect}
        selectedItem={selectedItem}
        setShow={setShowSelectModal}
      />

      <FilterModalComponent
        show={filterToggle}
        selectedItem={selectedItem}
        onHandleChange={handleChange}
        onClear={removeItem}
        handleApplyFilter={() => onSearch(page, true)}
        setShow={setFilterToggle}
      />
    </div>
  );
};

export default React.memo(BannerComponent);
