import React from "react";

import { Row, Modal } from "antd";
import FlatButton from "src/modules/parent/components/flat-button";
import Text from "src/modules/parent/components/text";
import whatsapp from "src/modules/parent/assets/svgs/whatsappWhite.svg";

import styles from "./styles.module.css";
import {
  contactRepresentativeModal,
  trackEvents,
} from "src/modules/parent/utils/events/query";

const whatsappWeb = "https://web.whatsapp.com/send";

const ContactModal = ({
  show = false,
  setShow = () => {},
  title,
  name,
  number,
  className,
  color,
}) => {
  const handleWhatsApp = () => {
    window.open(`${whatsappWeb}?phone=${number}`);
    trackEvents(contactRepresentativeModal);
  };

  return (
    <Modal
      closable
      onCancel={() => setShow(false)}
      maskClosable
      centered
      footer={false}
      width={"70%"}
      className={[styles.modal, "g-modal"].join(" ")}
      open={show}
    >
      <Text
        size={"XL"}
        className={"text-align-center mt-20"}
        font={"SEMIBOLD"}
        title={title}
      />

      <Row justify={"center"} className={"mt-40"}>
        <Text size={"M"} title={name} />
        <Text
          size={"M"}
          className={"ml-10 cursor-pointer"}
          handleClick={handleWhatsApp}
          color={color}
          title={number}
        />
      </Row>

      <Row justify={"center"} className={"mt-40 mb-20"}>
        <FlatButton
          title={"WhatsApp Now!"}
          height={"40px"}
          preIcon={<img src={whatsapp} className={"mr-10"} alt={"whatsapp"} />}
          onClick={handleWhatsApp}
          className={className}
        />
      </Row>
    </Modal>
  );
};

export default React.memo(ContactModal);
