import { Col, Row } from "antd";
import React from "react";
import Text from "src/modules/parent/components/text";
import style from "../styles.module.css";
import { dayName } from "src/modules/parent/utils";

const TimeSlot = ({ packageDetail, activeTab, setActiveTab }) => {
  //const [activeTab, setActiveTab] = useState(0);
  function convertTo12Hour(timeStr) {
    let [hours, minutes] = timeStr.split(":").map(Number);
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM case
    return `${hours}:${String(minutes).padStart(2, "0")} ${period}`;
  }
  //console.log(`package detail=====>${JSON.stringify(packageDetail)}`);
  return (
    <>
      <Row
        gutter={[0, 10]}
        style={{ padding: " 20px 24px" }}
        className={style.timeslotcontainer}
      >
        <Col span={24}>
          <Text
            hoveredTitle={" "}
            size={"XXL4"}
            font={"BOLD"}
            color={"#005EFF"}
          >
            {packageDetail?.title}
          </Text>
        </Col>
        <Col span={24}>
          <Text hoveredTitle={" "} font={"LIGHTER"} size={"M"} color={"#000"}>
            By {packageDetail?.teacher?.name}
          </Text>
        </Col>
      </Row>
      <Row
        style={{ "margin-top": "15px", padding: "0px 24px" }}
        className={style.timeslotcontainer}
      >
        {packageDetail &&
          packageDetail.time_slot &&
          packageDetail.time_slot.length > 0 &&
          packageDetail.time_slot.map((item, index) => {
            return (
              <Col
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
                sm={4}
                xs={8}
                key={index}
                onClick={() => setActiveTab(index)}
              >
                <Text
                  hoveredTitle={" "}
                  center
                  className={style.tabs}
                  size={"XXL3"}
                  font={activeTab === index ? "BOLD" : "NORMAL"}
                  color={activeTab === index ? "#005EFF" : "#646464"}
                >
                  Slot {index + 1}
                </Text>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: activeTab === index ? "100%" : "0%",
                    height: "2px",
                    backgroundColor: "#005EFF",
                    transition: "width 0.3s ease-in-out",
                  }}
                />
              </Col>
            );
          })}
      </Row>
      <Row
        style={{
          backgroundColor: "#EC7E18",
          padding: "15px",
          margin: "10px 0",
        }}
        className={style.timeslotcontainer}
      >
        <Col span={24}>
          <Text hoveredTitle={" "} center color={"#fff"}>
            {packageDetail.time_slot[activeTab].slot_days_relation.length > 0
              ? `${packageDetail.time_slot[activeTab].slot_days_relation.length} days a week`
              : `${
                  packageDetail.time_slot[activeTab].days.split(",").length
                } days a week`}
          </Text>
        </Col>
      </Row>
      <div style={{ padding: "0px 24px 24px 24px" }}>
        {packageDetail &&
        packageDetail.time_slot &&
        packageDetail.time_slot.length > 0 &&
        packageDetail.time_slot[activeTab].slot_days_relation.length > 0
          ? packageDetail.time_slot[activeTab].slot_days_relation?.map(
              (item, index) => {
                return (
                  <Row justify={"space-between"} style={{ padding: "10px 0" }}>
                    <Col span={12}>
                      <Text hoveredTitle={" "} font={"SEMIBOLD"} size={"XL"}>
                        {dayName(item.day)}
                      </Text>
                    </Col>
                    <Col style={{ textAlign: "end" }} span={12}>
                      <Text
                        hoveredTitle={" "}
                        font={"NORMAL"}
                        size={"XL"}
                      >{`${convertTo12Hour(
                        item.start_time
                      )} - ${convertTo12Hour(item.end_time)} (${
                        packageDetail.time_slot[activeTab].time_zone
                      })`}</Text>
                    </Col>
                  </Row>
                );
              }
            )
          : packageDetail &&
            packageDetail.time_slot &&
            packageDetail.time_slot.length > 0 &&
            packageDetail.time_slot[activeTab].days
              .split(",")
              ?.map((day, index) => {
                return (
                  <Row justify={"space-between"} style={{ padding: "10px 0" }}>
                    <Col span={12}>
                      <Text hoveredTitle={" "} font={"SEMIBOLD"} size={"XL"}>
                        {dayName(day)}
                      </Text>
                    </Col>
                    <Col style={{ textAlign: "end" }} span={12}>
                      <Text
                        hoveredTitle={" "}
                        font={"NORMAL"}
                        size={"XL"}
                      >{`${convertTo12Hour(
                        packageDetail.time_slot[activeTab].start_time
                      )} - ${convertTo12Hour(
                        packageDetail.time_slot[activeTab].end_time
                      )} (${
                        packageDetail.time_slot[activeTab].time_zone
                      })`}</Text>
                    </Col>
                  </Row>
                );
              })}
      </div>
    </>
  );
};

export default TimeSlot;
