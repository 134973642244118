import React from "react";
import { Link } from "react-router-dom";
import styles from "./../styles.module.css";
import logo from "./../../../assets/img/dotlinelogowithname.png";

const HeaderLogo = () => {
  return (
    <div className="d-flex align-items-center">
      <Link className={styles.logo_div} to={"/"}>
        <img src={logo} alt="Brand-logo" />
      </Link>
    </div>
  );
};

export default HeaderLogo;
