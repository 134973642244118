import React, { lazy, Suspense } from "react";

import { Col, Row, Space, Button } from "antd";
import Carousel from "react-multi-carousel";
import Slider from "react-slick";
import AppLoader from "src/modules/parent/components/app-loader";

import {
  responsiveForPackageCards,
  crouselSettings,
} from "src/modules/parent/utils";
import FlatButton from "src/modules/parent/components/flat-button";
import Text from "src/modules/parent/components/text";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./styles.module.css";
import { isMobile } from "react-device-detect";

const PackageCard = lazy(() =>
  import("src/modules/parent/components/package-card")
);

const TeacherCard = lazy(() => import("../../components/teacher-card"));

const checkBooking = (data) => {
  if (data.is_booked && data.package_type === "free package") {
    return false;
  }

  return true;
};

const checkAnotherBooking = (data) => {
  if (data.package_type === "free package") {
    if (!data.is_booked) {
      return false;
    } else {
      return true;
    }
  }

  return true;
};

const FallBackButton = ({ handleBtnClick = () => {}, text = "" }) => (
  <Row justify={"center"}>
    <Button onClick={handleBtnClick} className={styles.searchButton}>
      {text}
    </Button>
  </Row>
);

const onClick = (type) => {
  if (type === "course") {
    window.location.href = "/courses?program_id=5";
  } else {
    window.location.pathname = "/teachers";
  }
};

const Card = ({
  data = [],
  title,
  onPressPrimaryBtn = () => {},
  onPressSecondaryBtn = () => {},
  onPressNameClick = () => {},
  isPrimaryBtn,
  isSecondaryBtn,
  isTrivia = false,
  primaryBtnText = "Attend Class",
  secondaryBtnText = "View Progress",
  type = "course",
}) => (
  <Col span={24}>
    <div className={isMobile ? "mt-10 mb-10" : "white_box p-20"}>
      <Text
        color="rgba(0, 94, 255, 1)"
        size="XL"
        font="SEMIBOLD"
        className="mb-10"
      >
        {title}
      </Text>

      {isMobile ? (
        <>
          {data && data.length > 0 ? (
            <Slider {...crouselSettings}>
              {data.map((item) => (
                <Suspense
                  key={item?.id}
                  fallback={<AppLoader className="font-28" />}
                >
                  {type === "course" ? (
                    <>
                      <PackageCard
                        packageId={item.package ? item?.package.id : item.id}
                        enrollmentId={item.package ? item.id : null}
                        packageData={item?.package ? item?.package : item}
                        teacher={item?.teacher}
                        isTrivia={isTrivia}
                        item={item}
                        key={item?.id}
                      />

                      <Space
                        className={styles.footButton}
                        direction={"vertical"}
                      >
                        {isPrimaryBtn && checkBooking(item) && (
                          <FlatButton
                            type={"Quaternary"}
                            className={styles.btn}
                            title={primaryBtnText}
                            onClick={() => onPressPrimaryBtn(item)}
                          />
                        )}

                        {isSecondaryBtn && checkAnotherBooking(item) && (
                          <FlatButton
                            type={"Secondary"}
                            className={styles.btn}
                            title={secondaryBtnText}
                            onClick={() => onPressSecondaryBtn(item)}
                          />
                        )}
                      </Space>
                    </>
                  ) : (
                    <TeacherCard
                      {...item}
                      key={item?.id}
                      onHandleClick={onPressPrimaryBtn}
                      onHandleReview={onPressSecondaryBtn}
                      onHandleNameClick={onPressNameClick}
                    />
                  )}
                </Suspense>
              ))}
            </Slider>
          ) : (
            <div className="w-100">
              <h2 className="f-sm text-align-center pt-50 mt-50 mb-50">
                <FallBackButton
                  handleBtnClick={() => onClick(type)}
                  text={
                    type === "course" ? "Explore classes" : "Explore teachers"
                  }
                />
              </h2>
            </div>
          )}
        </>
      ) : (
        <div>
          {data && data.length > 0 ? (
            <Carousel responsive={responsiveForPackageCards}>
              {data.map((item) => (
                <Suspense
                  key={item?.id}
                  fallback={<AppLoader className="font-28" />}
                >
                  {type === "course" ? (
                    <>
                      <PackageCard
                        packageId={item.package ? item?.package.id : item.id}
                        enrollmentId={item.package ? item.id : null}
                        packageData={item?.package ? item?.package : item}
                        teacher={item?.teacher}
                        isTrivia={isTrivia}
                        item={item}
                        key={item?.id}
                      />

                      <Space
                        className={styles.footButton}
                        direction={"vertical"}
                      >
                        {isPrimaryBtn && checkBooking(item) && (
                          <FlatButton
                            type={"Quaternary"}
                            className={styles.btn}
                            title={primaryBtnText}
                            onClick={() => onPressPrimaryBtn(item)}
                          />
                        )}

                        {isSecondaryBtn && checkAnotherBooking(item) && (
                          <FlatButton
                            type={"Secondary"}
                            className={styles.btn}
                            title={secondaryBtnText}
                            onClick={() => onPressSecondaryBtn(item)}
                          />
                        )}
                      </Space>
                    </>
                  ) : (
                    <TeacherCard
                      {...item}
                      key={item?.id}
                      onHandleClick={onPressPrimaryBtn}
                      onHandleReview={onPressSecondaryBtn}
                      onHandleNameClick={onPressNameClick}
                    />
                  )}
                </Suspense>
              ))}
            </Carousel>
          ) : (
            <Row justify={"center"}>
              <h2 className="f-sm text-align-center pt-50 mt-50 mb-50">
                <FallBackButton
                  handleBtnClick={() => onClick(type)}
                  text={
                    type === "course" ? "Explore classes" : "Explore teachers"
                  }
                />
              </h2>
            </Row>
          )}
        </div>
      )}
    </div>
  </Col>
);

export default React.memo(Card);
