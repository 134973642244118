export const cities = [
  {
    id: 85329,
    name: "Abbottabad",
  },
  {
    id: 85330,
    name: "Adilpur",
  },
  {
    id: 85331,
    name: "Ahmedpur East",
  },
  {
    id: 85332,
    name: "Ahmadpur Sial",
  },
  {
    id: 85333,
    name: "Akora",
  },
  {
    id: 85334,
    name: "Alik Ghund",
  },
  {
    id: 85335,
    name: "Alipur Chatha",
  },
  {
    id: 85336,
    name: "Alizai",
  },
  {
    id: 85337,
    name: "Aman Garh",
  },
  {
    id: 85338,
    name: "Amirabad",
  },
  {
    id: 85339,
    name: "Arifwala",
  },
  {
    id: 85340,
    name: "Ashanagro Koto",
  },
  {
    id: 85341,
    name: "Attock Tehsil",
  },
  {
    id: 85342,
    name: "Awārān District",
  },
  {
    id: 85343,
    name: "Baddomalhi",
  },
  {
    id: 85344,
    name: "Badin",
  },
  {
    id: 85345,
    name: "Baffa",
  },
  {
    id: 85346,
    name: "Bagarji",
  },
  {
    id: 85347,
    name: "Bahawalnagar",
  },
  {
    id: 85348,
    name: "Bahawalpur",
  },
  {
    id: 85349,
    name: "Bakhri Ahmad Khan",
  },
  {
    id: 85350,
    name: "Bandhi",
  },
  {
    id: 85351,
    name: "Bannu",
  },
  {
    id: 85352,
    name: "Barishal",
  },
  {
    id: 85353,
    name: "Barkhan",
  },
  {
    id: 85354,
    name: "Basirpur",
  },
  {
    id: 85355,
    name: "Basti Dosa",
  },
  {
    id: 85356,
    name: "Bat Khela",
  },
  {
    id: 85357,
    name: "Battagram",
  },
  {
    id: 85358,
    name: "Battagram District",
  },
  {
    id: 85359,
    name: "Begowala",
  },
  {
    id: 85360,
    name: "Bela",
  },
  {
    id: 85361,
    name: "Berani",
  },
  {
    id: 85362,
    name: "Bhag",
  },
  {
    id: 85363,
    name: "Bhakkar",
  },
  {
    id: 85364,
    name: "Bhalwal",
  },
  {
    id: 85365,
    name: "Bhan",
  },
  {
    id: 85366,
    name: "Bhawana",
  },
  {
    id: 85367,
    name: "Bhera",
  },
  {
    id: 85368,
    name: "Bhimbar",
  },
  {
    id: 85369,
    name: "Bhiria",
  },
  {
    id: 85370,
    name: "Bhit Shah",
  },
  {
    id: 85371,
    name: "Bhopalwala",
  },
  {
    id: 85372,
    name: "Bozdar Wada",
  },
  {
    id: 85373,
    name: "Bulri",
  },
  {
    id: 85374,
    name: "Buner District",
  },
  {
    id: 85375,
    name: "Bārkhān District",
  },
  {
    id: 85376,
    name: "Burewala",
  },
  {
    id: 85377,
    name: "Chak",
  },
  {
    id: 85378,
    name: "Chak Azam Saffo",
  },
  {
    id: 85380,
    name: "Chak Jhumra",
  },
  {
    id: 85381,
    name: "Chak One Hundred Twenty Nine Left",
  },
  {
    id: 85382,
    name: "Chak Thirty-one -Eleven Left",
  },
  {
    id: 85383,
    name: "Chak Two Hundred Forty-Nine TDA",
  },
  {
    id: 85384,
    name: "Chakwal",
  },
  {
    id: 85385,
    name: "Chaman",
  },
  {
    id: 85386,
    name: "Chamber",
  },
  {
    id: 85387,
    name: "Charsadda",
  },
  {
    id: 85388,
    name: "Chawinda",
  },
  {
    id: 85389,
    name: "Rabwah",
  },
  {
    id: 85390,
    name: "Cherat Cantonement",
  },
  {
    id: 85391,
    name: "Chhor",
  },
  {
    id: 85392,
    name: "Chichawatni",
  },
  {
    id: 85393,
    name: "Chiniot",
  },
  {
    id: 85394,
    name: "Chishtian",
  },
  {
    id: 85395,
    name: "Chitral",
  },
  {
    id: 85396,
    name: "Choa Saidanshah",
  },
  {
    id: 85397,
    name: "Chowki Jamali",
  },
  {
    id: 85399,
    name: "Chuhar Jamali",
  },
  {
    id: 85400,
    name: "Chunian",
  },
  {
    id: 85401,
    name: "Chāgai District",
  },
  {
    id: 85402,
    name: "Dadhar",
  },
  {
    id: 85403,
    name: "Dadu",
  },
  {
    id: 85404,
    name: "Daira Din Panah",
  },
  {
    id: 85405,
    name: "Dajal",
  },
  {
    id: 85406,
    name: "Dalbandin",
  },
  {
    id: 85407,
    name: "Dandot RS",
  },
  {
    id: 85408,
    name: "Daromehar",
  },
  {
    id: 85409,
    name: "Darya Khan",
  },
  {
    id: 85410,
    name: "Darya Khan Marri",
  },
  {
    id: 85411,
    name: "Daska",
  },
  {
    id: 85413,
    name: "Daulatpur",
  },
  {
    id: 85414,
    name: "Daultala",
  },
  {
    id: 85415,
    name: "Daur",
  },
  {
    id: 85416,
    name: "Dera Bugti",
  },
  {
    id: 85417,
    name: "Dera Bugti District",
  },
  {
    id: 85418,
    name: "Dera Ghazi Khan",
  },
  {
    id: 85419,
    name: "Dera Ismail Khan",
  },
  {
    id: 85420,
    name: "Dera Ismāīl Khān District",
  },
  {
    id: 85421,
    name: "Dhanot",
  },
  {
    id: 85422,
    name: "Dhaunkal",
  },
  {
    id: 85423,
    name: "Dhoro Naro",
  },
  {
    id: 85424,
    name: "Digri",
  },
  {
    id: 85425,
    name: "Dijkot",
  },
  {
    id: 85426,
    name: "Dinan Bashnoian Wala",
  },
  {
    id: 85427,
    name: "Dinga",
  },
  {
    id: 85428,
    name: "Dipalpur",
  },
  {
    id: 85429,
    name: "Diplo",
  },
  {
    id: 85430,
    name: "Doaba",
  },
  {
    id: 85431,
    name: "Dokri",
  },
  {
    id: 85432,
    name: "Duki",
  },
  {
    id: 85433,
    name: "Dullewala",
  },
  {
    id: 85434,
    name: "Dunga Bunga",
  },
  {
    id: 85435,
    name: "Dunyapur",
  },
  {
    id: 85436,
    name: "Eminabad",
  },
  {
    id: 85437,
    name: "Faisalabad",
  },
  {
    id: 85438,
    name: "Faqirwali",
  },
  {
    id: 85439,
    name: "Faruka",
  },
  {
    id: 85440,
    name: "Fazilpur",
  },
  {
    id: 85441,
    name: "Fort Abbas",
  },
  {
    id: 85442,
    name: "Gadani",
  },
  {
    id: 85443,
    name: "Gambat",
  },
  {
    id: 85444,
    name: "Garh Maharaja",
  },
  {
    id: 85445,
    name: "Garhi Khairo",
  },
  {
    id: 85446,
    name: "Garhiyasin",
  },
  {
    id: 85447,
    name: "Gharo",
  },
  {
    id: 85448,
    name: "Ghauspur",
  },
  {
    id: 85449,
    name: "Ghotki",
  },
  {
    id: 85450,
    name: "Gilgit",
  },
  {
    id: 85451,
    name: "Gojra",
  },
  {
    id: 85452,
    name: "Goth Garelo",
  },
  {
    id: 85453,
    name: "Goth Phulji",
  },
  {
    id: 85454,
    name: "Goth Radhan",
  },
  {
    id: 85455,
    name: "Gujar Khan",
  },
  {
    id: 85456,
    name: "Gujranwala",
  },
  {
    id: 85457,
    name: "Gujrat",
  },
  {
    id: 85458,
    name: "Gulishah Kach",
  },
  {
    id: 85459,
    name: "Gwadar",
  },
  {
    id: 85460,
    name: "Hadali",
  },
  {
    id: 85461,
    name: "Hafizabad",
  },
  {
    id: 85462,
    name: "Hala",
  },
  {
    id: 85463,
    name: "Hangu",
  },
  {
    id: 85464,
    name: "Haripur",
  },
  {
    id: 85465,
    name: "Harnai",
  },
  {
    id: 85466,
    name: "Harnoli",
  },
  {
    id: 85467,
    name: "Harunabad",
  },
  {
    id: 85468,
    name: "Hasilpur",
  },
  {
    id: 85469,
    name: "Haveli Lakha",
  },
  {
    id: 85470,
    name: "Havelian",
  },
  {
    id: 85471,
    name: "Hazro",
  },
  {
    id: 85472,
    name: "Hingorja",
  },
  {
    id: 85473,
    name: "Hujra Shah Muqeem",
  },
  {
    id: 85474,
    name: "Hyderabad",
  },
  {
    id: 85475,
    name: "Islamabad",
  },
  {
    id: 85476,
    name: "Islamkot",
  },
  {
    id: 85477,
    name: "Jacobabad",
  },
  {
    id: 85478,
    name: "Jahanian Shah",
  },
  {
    id: 85479,
    name: "Jalalpur Jattan",
  },
  {
    id: 85480,
    name: "Jalalpur Pirwala",
  },
  {
    id: 85481,
    name: "Jampur",
  },
  {
    id: 85482,
    name: "Jamshoro",
  },
  {
    id: 85483,
    name: "Jand",
  },
  {
    id: 85484,
    name: "Jandiala Sher Khan",
  },
  {
    id: 85485,
    name: "Jaranwala",
  },
  {
    id: 85486,
    name: "Jati",
  },
  {
    id: 85487,
    name: "Jatoi Shimali",
  },
  {
    id: 85488,
    name: "Jauharabad",
  },
  {
    id: 85489,
    name: "Jhal Magsi District",
  },
  {
    id: 85490,
    name: "Jhang",
  },
  {
    id: 85491,
    name: "Jhang Sadar",
  },
  {
    id: 85492,
    name: "Jhawarian",
  },
  {
    id: 85493,
    name: "Jhelum",
  },
  {
    id: 85494,
    name: "Jhol",
  },
  {
    id: 85495,
    name: "Jiwani",
  },
  {
    id: 85496,
    name: "Johi",
  },
  {
    id: 85497,
    name: "Jāfarābād District",
  },
  {
    id: 85498,
    name: "Jām Sāhib",
  },
  {
    id: 85499,
    name: "Kabirwala",
  },
  {
    id: 85500,
    name: "Kadhan",
  },
  {
    id: 85501,
    name: "Kahna Nau",
  },
  {
    id: 85503,
    name: "Kahuta",
  },
  {
    id: 85504,
    name: "Kakad Wari Dir Upper",
  },
  {
    id: 85505,
    name: "Kalabagh",
  },
  {
    id: 85506,
    name: "Kalaswala",
  },
  {
    id: 85507,
    name: "Kalat",
  },
  {
    id: 85508,
    name: "Kaleke Mandi",
  },
  {
    id: 85509,
    name: "Kallar Kahar",
  },
  {
    id: 85510,
    name: "Kalur Kot",
  },
  {
    id: 85511,
    name: "Kalāt District",
  },
  {
    id: 85512,
    name: "Kamalia",
  },
  {
    id: 85513,
    name: "Kamar Mushani",
  },
  {
    id: 85514,
    name: "Kambar",
  },
  {
    id: 85515,
    name: "Kamoke",
  },
  {
    id: 85516,
    name: "Kamra",
  },
  {
    id: 85517,
    name: "Kandhkot",
  },
  {
    id: 85518,
    name: "Kandiari",
  },
  {
    id: 85519,
    name: "Kandiaro",
  },
  {
    id: 85520,
    name: "Kanganpur",
  },
  {
    id: 85521,
    name: "Karachi",
  },
  {
    id: 85522,
    name: "Karak",
  },
  {
    id: 85523,
    name: "Karaundi",
  },
  {
    id: 85524,
    name: "Kario Ghanwar",
  },
  {
    id: 85525,
    name: "Karor",
  },
  {
    id: 85526,
    name: "Kashmor",
  },
  {
    id: 85527,
    name: "Kasur",
  },
  {
    id: 85528,
    name: "Keshupur",
  },
  {
    id: 85529,
    name: "Keti Bandar",
  },
  {
    id: 85530,
    name: "Khadan Khak",
  },
  {
    id: 85531,
    name: "Khadro",
  },
  {
    id: 85532,
    name: "Khairpur",
  },
  {
    id: 85533,
    name: "Khairpur Mir’s",
  },
  {
    id: 85534,
    name: "Khairpur Nathan Shah",
  },
  {
    id: 85535,
    name: "Khairpur Tamiwali",
  },
  {
    id: 85536,
    name: "Khalabat",
  },
  {
    id: 85537,
    name: "Khandowa",
  },
  {
    id: 85538,
    name: "Khanewal",
  },
  {
    id: 85539,
    name: "Khanga Dogran",
  },
  {
    id: 85540,
    name: "Khangarh",
  },
  {
    id: 85541,
    name: "Khanpur",
  },
  {
    id: 85542,
    name: "Khanpur Mahar",
  },
  {
    id: 85543,
    name: "Kharan",
  },
  {
    id: 85544,
    name: "Kharian",
  },
  {
    id: 85545,
    name: "Khewra",
  },
  {
    id: 85546,
    name: "Khurrianwala",
  },
  {
    id: 85547,
    name: "Khushab",
  },
  {
    id: 85548,
    name: "Khuzdar",
  },
  {
    id: 85549,
    name: "Khuzdār District",
  },
  {
    id: 85550,
    name: "Khārān District",
  },
  {
    id: 85551,
    name: "Kohat",
  },
  {
    id: 85552,
    name: "Kohlu",
  },
  {
    id: 85553,
    name: "Kot Addu Tehsil",
  },
  {
    id: 85554,
    name: "Kot Diji",
  },
  {
    id: 85555,
    name: "Kot Ghulam Muhammad",
  },
  {
    id: 85556,
    name: "Kot Malik Barkhurdar",
  },
  {
    id: 85557,
    name: "Kot Mumin",
  },
  {
    id: 85558,
    name: "Kot Radha Kishan",
  },
  {
    id: 85559,
    name: "Kot Rajkour",
  },
  {
    id: 85560,
    name: "Kot Samaba",
  },
  {
    id: 85561,
    name: "Kot Sultan",
  },
  {
    id: 85562,
    name: "Kotli",
  },
  {
    id: 85563,
    name: "Kotli District",
  },
  {
    id: 85564,
    name: "Kotli Loharan",
  },
  {
    id: 85565,
    name: "Kotri",
  },
  {
    id: 85566,
    name: "Kulachi",
  },
  {
    id: 85567,
    name: "Kundian",
  },
  {
    id: 85568,
    name: "Kunjah",
  },
  {
    id: 85569,
    name: "Kunri",
  },
  {
    id: 85570,
    name: "Lachi",
  },
  {
    id: 85571,
    name: "Ladhewala Waraich",
  },
  {
    id: 85572,
    name: "Lahore",
  },
  {
    id: 85573,
    name: "Lakhi",
  },
  {
    id: 85574,
    name: "Lakki",
  },
  {
    id: 85575,
    name: "Lala Musa",
  },
  {
    id: 85576,
    name: "Lalian",
  },
  {
    id: 85577,
    name: "Landi Kotal",
  },
  {
    id: 85578,
    name: "Larkana",
  },
  {
    id: 85579,
    name: "Lasbela District",
  },
  {
    id: 85580,
    name: "Layyah",
  },
  {
    id: 85581,
    name: "Layyah District",
  },
  {
    id: 85582,
    name: "Liliani",
  },
  {
    id: 85583,
    name: "Lodhran",
  },
  {
    id: 85584,
    name: "Loralai",
  },
  {
    id: 85585,
    name: "Loralai District",
  },
  {
    id: 85586,
    name: "Mach",
  },
  {
    id: 85587,
    name: "Madeji",
  },
  {
    id: 85588,
    name: "Mailsi",
  },
  {
    id: 85589,
    name: "Malakwal",
  },
  {
    id: 85590,
    name: "Malakwal City",
  },
  {
    id: 85591,
    name: "Malir Cantonment",
  },
  {
    id: 85592,
    name: "Mamu Kanjan",
  },
  {
    id: 85593,
    name: "Mananwala",
  },
  {
    id: 85594,
    name: "Mandi Bahauddin",
  },
  {
    id: 85595,
    name: "Mangla",
  },
  {
    id: 85596,
    name: "Mankera",
  },
  {
    id: 85597,
    name: "Mansehra",
  },
  {
    id: 85598,
    name: "Mardan",
  },
  {
    id: 85599,
    name: "Mastung",
  },
  {
    id: 85600,
    name: "Mastung District",
  },
  {
    id: 85601,
    name: "Matiari",
  },
  {
    id: 85602,
    name: "Matli",
  },
  {
    id: 85603,
    name: "Mehar",
  },
  {
    id: 85604,
    name: "Mehmand Chak",
  },
  {
    id: 85605,
    name: "Mehrabpur",
  },
  {
    id: 85606,
    name: "Mian Channun",
  },
  {
    id: 85607,
    name: "Mianke Mor",
  },
  {
    id: 85608,
    name: "Mianwali",
  },
  {
    id: 85609,
    name: "Minchinabad",
  },
  {
    id: 85610,
    name: "Mingora",
  },
  {
    id: 85611,
    name: "Miran Shah",
  },
  {
    id: 85612,
    name: "Miro Khan",
  },
  {
    id: 85613,
    name: "Mirpur Bhtoro",
  },
  {
    id: 85614,
    name: "Mirpur District",
  },
  {
    id: 85615,
    name: "Mirpur Khas",
  },
  {
    id: 85616,
    name: "Mirpur Mathelo",
  },
  {
    id: 85617,
    name: "Mirpur Sakro",
  },
  {
    id: 85618,
    name: "Mirwah Gorchani",
  },
  {
    id: 85619,
    name: "Mitha Tiwana",
  },
  {
    id: 85620,
    name: "Mithi",
  },
  {
    id: 85621,
    name: "Moro",
  },
  {
    id: 85622,
    name: "Moza Shahwala",
  },
  {
    id: 85623,
    name: "Multan",
  },
  {
    id: 85624,
    name: "Muridke",
  },
  {
    id: 85625,
    name: "Murree",
  },
  {
    id: 85626,
    name: "Mustafabad",
  },
  {
    id: 85627,
    name: "Muzaffargarh",
  },
  {
    id: 85628,
    name: "Muzaffarābād",
  },
  {
    id: 85629,
    name: "Mūsa Khel District",
  },
  {
    id: 85630,
    name: "Nabisar",
  },
  {
    id: 85631,
    name: "Nankana Sahib",
  },
  {
    id: 85632,
    name: "Narang Mandi",
  },
  {
    id: 85633,
    name: "Narowal",
  },
  {
    id: 85634,
    name: "Nasirabad",
  },
  {
    id: 85635,
    name: "Nasīrābād District",
  },
  {
    id: 85636,
    name: "Naudero",
  },
  {
    id: 85637,
    name: "Naukot",
  },
  {
    id: 85638,
    name: "Naushahra Virkan",
  },
  {
    id: 85639,
    name: "Naushahro Firoz",
  },
  {
    id: 85640,
    name: "Nawabshah",
  },
  {
    id: 85641,
    name: "Nazir Town",
  },
  {
    id: 85642,
    name: "New Bādāh",
  },
  {
    id: 85643,
    name: "New Mirpur",
  },
  {
    id: 85644,
    name: "Noorabad",
  },
  {
    id: 85645,
    name: "North Wazīristān Agency",
  },
  {
    id: 85646,
    name: "Nowshera",
  },
  {
    id: 85647,
    name: "Nowshera Cantonment",
  },
  {
    id: 85648,
    name: "Nushki",
  },
  {
    id: 85650,
    name: "Okara",
  },
  {
    id: 85651,
    name: "Ormara",
  },
  {
    id: 85652,
    name: "Pabbi",
  },
  {
    id: 85653,
    name: "Pad Idan",
  },
  {
    id: 85654,
    name: "Paharpur",
  },
  {
    id: 85655,
    name: "Pakpattan",
  },
  {
    id: 85656,
    name: "Panjgūr District",
  },
  {
    id: 85657,
    name: "Pano Aqil",
  },
  {
    id: 85658,
    name: "Pasni",
  },
  {
    id: 85659,
    name: "Pasrur",
  },
  {
    id: 85660,
    name: "Pattoki",
  },
  {
    id: 85661,
    name: "Peshawar",
  },
  {
    id: 85662,
    name: "Phalia",
  },
  {
    id: 85663,
    name: "Pind Dadan Khan",
  },
  {
    id: 85664,
    name: "Pindi Bhattian",
  },
  {
    id: 85665,
    name: "Pindi Gheb",
  },
  {
    id: 85666,
    name: "Pir Jo Goth",
  },
  {
    id: 85667,
    name: "Pir Mahal",
  },
  {
    id: 85668,
    name: "Pishin",
  },
  {
    id: 85669,
    name: "Pithoro",
  },
  {
    id: 85670,
    name: "Qadirpur Ran",
  },
  {
    id: 85671,
    name: "Qila Saifullāh District",
  },
  {
    id: 85672,
    name: "Quetta",
  },
  {
    id: 85673,
    name: "Quetta District",
  },
  {
    id: 85674,
    name: "Rahim Yar Khan",
  },
  {
    id: 85675,
    name: "Raiwind",
  },
  {
    id: 85676,
    name: "Raja Jang",
  },
  {
    id: 85677,
    name: "Rajanpur",
  },
  {
    id: 85678,
    name: "Rajo Khanani",
  },
  {
    id: 85679,
    name: "Ranipur",
  },
  {
    id: 85680,
    name: "Rasulnagar",
  },
  {
    id: 85681,
    name: "Ratodero",
  },
  {
    id: 85682,
    name: "Rawala Kot",
  },
  {
    id: 85683,
    name: "Rawalpindi",
  },
  {
    id: 85684,
    name: "Rawalpindi District",
  },
  {
    id: 85685,
    name: "Renala Khurd",
  },
  {
    id: 85686,
    name: "Risalpur Cantonment",
  },
  {
    id: 85687,
    name: "Rohri",
  },
  {
    id: 85688,
    name: "Rojhan",
  },
  {
    id: 85689,
    name: "Rustam",
  },
  {
    id: 85690,
    name: "Sadiqabad",
  },
  {
    id: 85691,
    name: "Sahiwal",
  },
  {
    id: 85692,
    name: "Sakrand",
  },
  {
    id: 85693,
    name: "Samaro",
  },
  {
    id: 85694,
    name: "Sambrial",
  },
  {
    id: 85695,
    name: "Sanghar",
  },
  {
    id: 85696,
    name: "Sangla Hill",
  },
  {
    id: 85697,
    name: "Sanjwal",
  },
  {
    id: 85698,
    name: "Sann",
  },
  {
    id: 85699,
    name: "Sarai Alamgir",
  },
  {
    id: 85700,
    name: "Sarai Naurang",
  },
  {
    id: 85701,
    name: "Sarai Sidhu",
  },
  {
    id: 85702,
    name: "Sargodha",
  },
  {
    id: 85703,
    name: "Sehwan",
  },
  {
    id: 85704,
    name: "Setharja Old",
  },
  {
    id: 85705,
    name: "Shabqadar",
  },
  {
    id: 85706,
    name: "Shahdad Kot",
  },
  {
    id: 85707,
    name: "Shahdadpur",
  },
  {
    id: 85708,
    name: "Shorkot",
  },
  {
    id: 85709,
    name: "Shahpur",
  },
  {
    id: 85710,
    name: "Shahpur Chakar",
  },
  {
    id: 85711,
    name: "Shahr Sultan",
  },
  {
    id: 85712,
    name: "Shakargarh",
  },
  {
    id: 85713,
    name: "Sharqpur",
  },
  {
    id: 85714,
    name: "Sheikhupura",
  },
  {
    id: 85715,
    name: "Shikarpur",
  },
  {
    id: 85716,
    name: "Shingli Bala",
  },
  {
    id: 85717,
    name: "Shinpokh",
  },
  {
    id: 85718,
    name: "Shorkot",
  },
  {
    id: 85719,
    name: "Shujaabad",
  },
  {
    id: 85720,
    name: "Sialkot",
  },
  {
    id: 85721,
    name: "Sibi",
  },
  {
    id: 85722,
    name: "Sillanwali",
  },
  {
    id: 85723,
    name: "Sinjhoro",
  },
  {
    id: 85724,
    name: "Skardu",
  },
  {
    id: 85725,
    name: "Sobhodero",
  },
  {
    id: 85726,
    name: "Sodhra",
  },
  {
    id: 85727,
    name: "Sohbatpur",
  },
  {
    id: 85728,
    name: "South Wazīristān Agency",
  },
  {
    id: 85729,
    name: "Sukheke Mandi",
  },
  {
    id: 85730,
    name: "Sukkur",
  },
  {
    id: 85731,
    name: "Surab",
  },
  {
    id: 85732,
    name: "Surkhpur",
  },
  {
    id: 85733,
    name: "Swabi",
  },
  {
    id: 85734,
    name: "Sīta Road",
  },
  {
    id: 85735,
    name: "Talagang",
  },
  {
    id: 85736,
    name: "Talamba",
  },
  {
    id: 85737,
    name: "Talhar",
  },
  {
    id: 85738,
    name: "Tandlianwala",
  },
  {
    id: 85739,
    name: "Tando Adam",
  },
  {
    id: 85740,
    name: "Tando Allahyar",
  },
  {
    id: 85741,
    name: "Tando Bago",
  },
  {
    id: 85742,
    name: "Tando Jam",
  },
  {
    id: 85743,
    name: "Tando Mitha Khan",
  },
  {
    id: 85744,
    name: "Tando Muhammad Khan",
  },
  {
    id: 85745,
    name: "Tangi",
  },
  {
    id: 85746,
    name: "Tangwani",
  },
  {
    id: 85747,
    name: "Tank",
  },
  {
    id: 85748,
    name: "Taunsa",
  },
  {
    id: 85749,
    name: "Thal",
  },
  {
    id: 85750,
    name: "Tharu Shah",
  },
  {
    id: 85751,
    name: "Thatta",
  },
  {
    id: 85752,
    name: "Thul",
  },
  {
    id: 85753,
    name: "Toba Tek Singh",
  },
  {
    id: 85754,
    name: "Topi",
  },
  {
    id: 85755,
    name: "Turbat",
  },
  {
    id: 85756,
    name: "Ubauro",
  },
  {
    id: 85757,
    name: "Umarkot",
  },
  {
    id: 85758,
    name: "Umerkot District",
  },
  {
    id: 85759,
    name: "Upper Dir",
  },
  {
    id: 85760,
    name: "Usta Muhammad",
  },
  {
    id: 85761,
    name: "Uthal",
  },
  {
    id: 85762,
    name: "Utmanzai",
  },
  {
    id: 85763,
    name: "Vihari",
  },
  {
    id: 85764,
    name: "Wana",
  },
  {
    id: 85765,
    name: "Warah",
  },
  {
    id: 85766,
    name: "Wazirabad",
  },
  {
    id: 85767,
    name: "Yazman",
  },
  {
    id: 85768,
    name: "Zafarwal",
  },
  {
    id: 85769,
    name: "Zahir Pir",
  },
  {
    id: 85770,
    name: "Zaida",
  },
  {
    id: 85771,
    name: "Zhob",
  },
  {
    id: 85772,
    name: "Zhob District",
  },
  {
    id: 85773,
    name: "Ziarat",
  },
  {
    id: 85774,
    name: "Ziārat District",
  },
  {
    id: 143765,
    name: "Chuhar Kana",
  },
  {
    id: 143766,
    name: "Dhok Awan",
  },
  {
    id: 143767,
    name: "Daud Khel",
  },
  {
    id: 143768,
    name: "Ferozewala",
  },
  {
    id: 143769,
    name: "Gujranwala Division",
  },
  {
    id: 143770,
    name: "Hasan Abdal",
  },
  {
    id: 143771,
    name: "Kohror Pakka",
  },
  {
    id: 143772,
    name: "Mandi Bahauddin District",
  },
  {
    id: 143773,
    name: "Multan District",
  },
  {
    id: 143774,
    name: "Pakki Shagwanwali",
  },
  {
    id: 143775,
    name: "Qila Didar Singh",
  },
  {
    id: 143776,
    name: "Rahimyar Khan District",
  },
  {
    id: 143777,
    name: "Shahkot Tehsil",
  },
  {
    id: 143778,
    name: "Umerkot",
  },
  {
    id: 143779,
    name: "Wah",
  },
  {
    id: 143780,
    name: "Warburton",
  },
  {
    id: 143781,
    name: "West Punjab",
  },
];
