import React from "react";

import { Space } from "antd";

import Text from "src/modules/parent/components/text";
import { avatarList } from "src/modules/parent/utils";
import { IMAGE_BASE_URL } from "src/modules/parent/config/constant";
import noImage from "src/modules/parent/assets/img/anonymous.jpeg";

import styles from "../../styles.module.css";

const getImage = (image, avatar) => {
  if (!image && !avatar) {
    return noImage;
  }

  if (image) {
    if (image.startsWith("https")) {
      return image;
    }

    return `${IMAGE_BASE_URL}${image}`;
  }

  if (avatar) {
    return avatarList[avatar]?.image;
  }
};

const childCard = ({
  selected = false,
  image,
  avatar,
  name,
  isLoading = false,
  id,
  handleSelect = () => {},
}) => (
  <div
    onClick={!isLoading ? () => handleSelect(id) : () => {}}
    className={isLoading ? styles.childCardLoading : styles.childCard}
    style={{
      backgroundColor: selected ? "#fccf13" : "",
      opacity: isLoading ? "0.7" : "1",
    }}
  >
    <Space align={"center"} direction={"vertical"}>
      <div className={styles.profileImg}>
        <img
          src={getImage(image, avatar)}
          alt={"childImage"}
          draggable="false"
        />
      </div>

      <Text
        size="S"
        className={"text-align-center"}
        lHeight={"S"}
        font="SEMIBOLD"
      >
        {name}
      </Text>
    </Space>
  </div>
);

export default React.memo(childCard);
