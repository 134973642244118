import React from "react";

import { Space, Button } from "antd";

import Text from "src/modules/parent/components/text";

import rocket from "../../../assets/rocket.png";

import styles from "../styles.module.css";

const SuccessModal = ({ onOk = () => {} }) => (
  <div>
    <Space align={"center"} size={"large"} direction={"vertical"}>
      <img src={rocket} width={"100px"} alt="Success" />

      <Text
        size={"L"}
        center
        className={"pr-20 pl-20"}
        color={"#2C2C2C"}
        lHeight={"S"}
        title={"All Done!"}
      />

      <Text
        size={"M2"}
        center
        className={"pr-20 pl-20"}
        color={"#2C2C2C"}
        lHeight={"S"}
        title={"A Dot & Line academic counselor will be in touch shortly."}
      />

      <Button onClick={onOk} className={styles.successBtn}>
        <Text size={"M2"} lHeight={"S"} color={"#FFFFFF"} title={"Okay"} />
      </Button>
    </Space>
  </div>
);

export default React.memo(SuccessModal);
