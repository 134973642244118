import React from "react";
import { Col, Row, Button } from "antd";

import styles from "../styles.module.css";

const subjects = [
  {
    id: "0",
    name: "English",
  },
  {
    id: "1",
    name: "Math",
  },
  {
    id: "2",
    name: "Urdu",
  },
  {
    id: "3",
    name: "Coding",
  },
  {
    id: "4",
    name: "NAPLAN",
  },
];

const StepNo1 = ({ handleNext, setSubject, subject }) => {
  const handleSubjectSelect = (i) => setSubject(i);

  return (
    <Col className={styles.rightColumn} span={24}>
      <p className={["font-18", styles.description].join(" ")}>
        {
          "Pick a subject you’re interested in and begin your exciting learning journey with us!"
        }
      </p>

      <div className={styles.subjectContainer}>
        <Row justify={"center"} gutter={[20, 20]}>
          {subjects.map((i) => (
            <Col key={i.id} xxl={8} xl={8} lg={12} md={12} sm={12} xs={12}>
              <div
                key={i.id}
                onClick={() => handleSubjectSelect(i)}
                className={
                  subject === i.name ? styles.selectedTab : styles.tabs
                }
              >
                {i.name}
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <Row justify={"center"}>
        <Button onClick={handleNext} className={styles.searchButton}>
          {"Next"}
        </Button>
      </Row>
    </Col>
  );
};

export default React.memo(StepNo1);
