import React from "react";
import FlatButton from "src/modules/parent/components/flat-button";
import styles from "./../styles.module.css";
import img1 from "./../../../assets/img/success-check.png";

const DemoBooked = ({ okHandler, packageTitle = "", teacherName = "" }) => {
  return (
    <div className="d-column justify-content-center align-items-center mt-30 mb-30 ml-50 mr-50">
      <img className={styles.success_img} src={img1} alt="Success" />
      <p className={[styles.modal_heading, "font-weight-500 text-align-center mt-10"].join(" ")}>
        Great grades,
        <br />
        here we come!
      </p>
      <p className="text-align-center dark-grey-color font-18 font-weight-300 mb-30">
        Your demo class for <span className="blue-color font-weight-500">{packageTitle}</span> with <br />
        <span className="blue-color font-weight-500">{teacherName}</span> has been confirmed. Please join the
        demo class on the selected date and time!
      </p>
      <div className="d-row justify-content-center">
        <FlatButton onClick={okHandler} width={"219px"} className="mr-25" title={"Got it"} />
      </div>
    </div>
  );
};

export default DemoBooked;
