import React from "react";

import { Col, Modal, Row, Space } from "antd";
import { isMobile } from "react-device-detect";

import Text from "src/modules/parent/components/text";

import styles from "./styles.module.css";

const TeacherModal = ({ show = false, setShow, goToRoute = () => {} }) => (
  <Modal
    closable
    onCancel={() => setShow(false)}
    maskClosable
    centered
    footer={false}
    width={isMobile ? "100%" : "800px"}
    className={"g-modal"}
    open={show}
  >
    <div className="mt-20">
      <Row justify={"center"}>
        <Text
          size={"M"}
          className={"mt-10"}
          font={"SEMIBOLD"}
          title={"What would you like to apply for?"}
        />
      </Row>

      <Row gutter={[20, 10]} justify={"center"} className={"mt-40"}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <div onClick={() => goToRoute("/ignite")} className={styles.card1}>
            <Space direction={"vertical"}>
              <Text
                size={"XXL"}
                font={"NORMAL"}
                color={"white"}
                lHeight={"S"}
                title={"IGNITE Teacher Training"}
                className={styles.txt}
                center
              />

              <Text
                size={"XS"}
                color={"white"}
                lHeight={"S"}
                title={
                  "A comprehensive 3 Part Teacher Training by Dot & Line. Your ticket to a successful teaching career."
                }
                center
              />
            </Space>
          </div>
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <div
            onClick={() => goToRoute("/teacher/recruitment")}
            className={styles.card2}
          >
            <Space direction={"vertical"}>
              <Text
                size={"XXL"}
                font={"NORMAL"}
                color={"white"}
                className={styles.txt}
                lHeight={"S"}
                title={"Become a Tutor"}
                center
              />

              <Text
                size={"XS"}
                color={"white"}
                lHeight={"S"}
                title={
                  "Kickstart your journey to financial independence with an online teaching career today."
                }
                center
              />
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  </Modal>
);

export default React.memo(TeacherModal);
