import React, { Fragment } from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";

function AppLoader({ className = "", fullPage = false, text = "" }) {
  return (
    <Fragment>
      {fullPage ? (
        <div className={styles.full_page}>
          <div className="d-column">
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 60, color: "#fff" }} spin />
              }
            />
            <span className="font-20 mt-20">{text || "Loading..."}</span>
          </div>
        </div>
      ) : (
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 60, color: "#fff" }} spin />
          }
        />
      )}
    </Fragment>
  );
}

export default AppLoader;
