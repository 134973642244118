import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Space } from "antd";
import moment from "moment-timezone";
import slugify from "@sindresorhus/slugify";
import ReactPixel from "react-facebook-pixel";

import {
  capitilizeFirstLetter,
  getConvertedTime,
  getGrades,
  getPackageAmount,
  noPackageCoverImage,
} from "../../utils";
import { fb_pixel_id_summer } from "src/modules/parent/config/constant";

import styles from "./styles.module.css";
import star from "../../assets/img/star.png";
import avatar from "../../assets/img/avatar.jpg";
import clock_icon from "src/modules/parent/assets/svgs/clock.svg";
import union from "src/modules/parent/assets/svgs/grade1.svg";
import "react-lazy-load-image-component/src/effects/blur.css";
import { K8CardClick, trackEvents } from "../../utils/events/query";

ReactPixel.init(fb_pixel_id_summer);

const getTime = (data, isDemo) => {
  if (isDemo) {
    if (data.time_slot && data.time_slot.length) {
      const slot = data.time_slot.find((i) => i.id === data?.demo?.slot_id);
      return [slot];
    }

    if (data.slot) {
      return [data.slot];
    }
    return [];
  } else {
    if (data.slot) {
      return [data.slot];
    }

    if (data.slots) {
      return [data.slots];
    }

    if (data.time_slots && data?.time_slots.length) {
      return data?.time_slots;
    }

    if (data.time_slot && data.time_slot.length) {
      return data.time_slot;
    }

    return [];
  }
};

const PackageCard = ({
  item,
  teacher,
  packageData,
  enrollmentId,
  isTrivia,
}) => {
  const navigate = useNavigate();
  const packageDetail = useMemo(() => packageData || item, [packageData, item]);

  const [subjects, setSubjects] = useState([]);

  const slot = useMemo(() => getTime(item, !!item.demo), [item]);
  const days = useMemo(
    () =>
      item?.package_type === "free package"
        ? moment(item.slots.start_date).format("ddd, DD MMM YYYY")
        : `${slot?.length > 0 ? slot[0]?.days : ""}`,
    [item, slot]
  );

  const titleRoute = useMemo(
    () => (packageDetail ? slugify(packageDetail?.title) : ""),
    [packageDetail]
  );

  const gotoUrl = useMemo(() => {
    if (isTrivia) {
      return `#`;
    }
    if (enrollmentId) {
      return `/course/enroll/${enrollmentId}`;
    }
    return item.package_type === "free package"
      ? `/free-classes/${item?.id}`
      : `/courses/${titleRoute}/${packageDetail?.id}`;
  }, [
    enrollmentId,
    isTrivia,
    item?.id,
    item.package_type,
    packageDetail?.id,
    titleRoute,
  ]);

  const isOnline = useMemo(
    () =>
      packageDetail?.package_mode?.toLowerCase() === "online" ||
      item?.package_type === "free package",
    [packageDetail, item]
  );

  const isPro = item.is_professional;

  useEffect(() => {
    if (packageDetail) {
      if (packageDetail.subjects && packageDetail.subjects.length) {
        setSubjects(packageDetail.subjects.map((i) => i.subject));
      } else if (packageDetail.subject && packageDetail.subject.subject) {
        setSubjects([packageDetail.subject.subject]);
      }
    }
  }, [packageDetail]);

  const viewPackageDetailHandler = useCallback(
    (event) => {
      event.preventDefault();

      if (isTrivia) {
        return;
      }

      trackEvents(K8CardClick);

      navigate(gotoUrl, { state: { isProfessional: isPro } });
      event.stopPropagation();
    },
    [isTrivia, navigate, gotoUrl, isPro]
  );

  const handleTeacher = useCallback(
    (event) => {
      event.preventDefault();
      navigate(`/teachers/${item.teacher.id}`);
      event.stopPropagation();
    },
    [navigate, item.teacher.id]
  );

  return (
    <a
      className={styles.card}
      href={gotoUrl}
      onClick={viewPackageDetailHandler}
    >
      {item && (
        <div>
          <div className={styles.card_wrapper}>
            <div className={styles.package_cover}>
              <LazyLoadImage
                src={packageDetail.image}
                width="100%"
                height={175}
                placeholderSrc={noPackageCoverImage}
                effect="blur"
                alt="cover"
              />

              <div
                className={[
                  styles.tag,
                  isOnline ? styles.online_tag : styles.onsite_tag,
                ].join(" ")}
              >
                {isOnline ? "online" : "Onsite"}
              </div>
            </div>

            <div className={styles.card_body}>
              <div className={styles.reviews}>
                <div style={{ display: "flex", flexGrow: 1 }}>
                  {teacher?.ratings > 0 && (
                    <>
                      <img src={star} alt="" />
                      <span>{teacher.ratings}</span>
                    </>
                  )}
                </div>

                <div className={styles.days_tag}>
                  {item?.demo
                    ? moment(item?.demo?.demo_date || item?.demo_date)
                        .format("ddd")
                        .toUpperCase()
                    : slot.length > 1
                    ? "Multiple Slots"
                    : days
                        ?.split(",")
                        ?.map((el) => capitilizeFirstLetter(el))
                        ?.join(", ")
                        .toUpperCase()}
                </div>
              </div>

              <div className={styles.enroll_package_name}>
                {packageDetail.title}
              </div>

              {isPro ? (
                <div className={styles.gradesDiv}>
                  <div className={styles.grades}>
                    <img src={union} alt="" />
                    <p className={[styles.lHeight, styles.timeSlot].join(" ")}>
                      {subjects.join(", ")}
                    </p>
                  </div>
                </div>
              ) : (
                <div className={styles.gradesDiv}>
                  <div className={styles.grades}>
                    <img src={union} alt="" />
                    <p className={[styles.lHeight, styles.timeSlot].join(" ")}>
                      Grade {getGrades(packageDetail)}
                    </p>
                  </div>

                  <div className={styles.grades}>
                    <img src={union} alt="" />
                    <p className={[styles.lHeight, styles.timeSlot].join(" ")}>
                      {item?.type === "course"
                        ? item?.course?.title
                        : subjects.join(", ")}
                    </p>
                  </div>
                </div>
              )}

              <div className="mt-10">
                <Space className={styles.class_timing}>
                  <img
                    src={clock_icon}
                    width={16}
                    className={styles.class_timing_icon}
                    alt="packageImage"
                  />
                  <p className={["mb-0", styles.timeSlot].join(" ")}>
                    {slot.length > 0 &&
                      getConvertedTime(
                        slot[0]?.start_time || "",
                        slot[0]?.end_time || "",
                        slot[0]?.time_zone || "",
                        item.package_type === "free package"
                      )}
                  </p>
                </Space>
              </div>

              {item.amount > 0 && (
                <div className="mt-10 mb-6">
                  <p className={[styles.price, styles.lHeight].join(" ")}>
                    {getPackageAmount(item)}
                  </p>
                </div>
              )}

              <div className={styles.package_teacher_profile}>
                <img alt="" src={teacher?.picture || avatar} />
                <a href={`/teachers/${teacher?.id}`} onClick={handleTeacher}>
                  <span className="overflow-elipsis blue-color hyperlinked">
                    {teacher?.name || ""}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </a>
  );
};

export default React.memo(PackageCard);
