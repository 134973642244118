import React, { useEffect } from "react";
import styles from "./styles.module.css";
import YouTube from "react-youtube";

const video = [
  {
    id: 1734,
    url: "dlqRnZH3W5I",
  },
  {
    id: 8986,
    url: "t0qr-NzzscA",
  },
  {
    id: 7072,
    url: "KNI_tTnONpk",
  },
];

const JWVideoPlayer = ({
  title,
  url,
  teacherId,
  height,
  stopVideo,
  autoStart = false,
  isProfessional,
}) => {
  const playerRef = React.useRef(null);

  useEffect(() => {
    if (stopVideo) {
      if (playerRef.current) {
        playerRef.current.internalPlayer.stopVideo();
      }
    }
  }, [stopVideo]);

  const getProfessionalVideo = () => {
    const videoUrl = video.find((i) => i.id === teacherId);

    if (videoUrl && videoUrl.url) {
      return videoUrl.url;
    } else {
      return url;
    }
  };

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    if (!autoStart) {
      event.target.pauseVideo();
    }
  };

  const opts = {
    height: height ? height : window?.innerWidth < 991 ? 300 : 350,
    width: "100%",
    playerVars: {
      autoplay: autoStart ? 1 : 0,
      rel: 0,
    },
  };

  return (
    <div className={styles.video_box}>
      {url ? (
        <YouTube
          ref={playerRef}
          videoId={isProfessional ? getProfessionalVideo() : url}
          opts={opts}
          onReady={onPlayerReady}
          title={title}
          loading={"eager"}
        />
      ) : null}
    </div>
  );
};

export default JWVideoPlayer;
