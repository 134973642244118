import React from "react";

import { Row, Space } from "antd";
import Carousel from "react-multi-carousel";

import { responsivePartnerCard } from "src/modules/parent/utils";

import wefi from "../../assets/partners/wefi.svg";
import british from "../../assets/partners/british.svg";
import caie from "../../assets/partners/caie.webp";
import ib from "../../assets/partners/ib.webp";
import pearson from "../../assets/partners/pearson.webp";
import qa from "../../assets/partners/qa.webp";
import ocr from "../../assets/partners/ocr.webp";
import vce from "../../assets/partners/vce.svg";

import styles from "./styles.module.css";

const data = [
  {
    id: "0",
    image: wefi,
  },
  {
    id: "1",
    image: british,
  },
  {
    id: "2",
    image: pearson,
  },
  {
    id: "3",
    image: caie,
  },
  {
    id: "4",
    image: qa,
  },
  {
    id: "5",
    image: ib,
  },
  {
    id: "6",
    image: ocr,
  },
  {
    id: "7",
    image: vce,
  },
];

const OurPartners = () => (
  <div className="pt-20 pb-30">
    <Row justify={"center"}>
      <Space align={"center"} direction={"vertical"}>
        <div className={"font-24 w-100 font-weight-600"}>
          {"Our Affiliations"}
        </div>

        <div className="pr-20 pl-20 text-align-center">
          We have collaborated with over{" "}
          <span className={styles.companies}>10 colleges and companies</span>
        </div>
      </Space>
    </Row>

    <div className={styles.partners}>
      <Carousel
        infinite
        autoPlay
        autoPlaySpeed={3000}
        responsive={responsivePartnerCard}
      >
        {data.map((item) => (
          <img
            key={item.id}
            src={item.image}
            style={{ padding: "0px 20px" }}
            alt={"partners"}
          />
        ))}
      </Carousel>
    </div>
  </div>
);

export default React.memo(OurPartners);
