import React from "react";

import { Row } from "antd";

import Text from "src/modules/parent/components/text";
import FlatButton from "src/modules/parent/components/flat-button";

const FooterComponent = ({
  isPrevious = false,
  isLast = false,
  isDisabled = false,
  isLoading = false,
  onHandlePrevious = () => {},
  onHandleNext = () => {},
}) => {
  return (
    <Row
      className={"mt-10"}
      align={"middle"}
      justify={!isPrevious ? "end" : "space-between"}
    >
      {isPrevious && (
        <Text
          size={"M"}
          className={"ml-20 cursor-pointer"}
          handleClick={!isLoading ? onHandlePrevious : () => {}}
          color={"rgba(110, 110, 110, 1)"}
          title={"Previous"}
        />
      )}

      <FlatButton
        title={isLast ? "Register" : "Next"}
        type={"Quaternary"}
        width={"150px"}
        height={"40px"}
        loading={isLoading}
        disabled={isDisabled}
        onClick={onHandleNext}
      />
    </Row>
  );
};

export default FooterComponent;
