import React, { Suspense } from "react";

import { Modal, Space } from "antd";
import { isMobile } from "react-device-detect";
import Carousel from "react-multi-carousel";

import AppLoader from "src/modules/parent/components/app-loader";
import FlatButton from "src/modules/parent/components/flat-button";
import PackageCard from "src/modules/parent/components/package-card";
import { responsiveArticles } from "src/modules/parent/utils";

import styles from "./styles.module.css";

const DemosModal = ({
  data = [],
  isVisible = false,
  onCancel = () => {},
  handleDemoReschedule = () => {},
  handleAttendDemo = () => {},
}) => (
  <Modal
    closable
    onCancel={onCancel}
    maskClosable
    centered
    footer={false}
    width={isMobile ? "100vw" : "70vw"}
    className={[styles.modal, isMobile ? "" : "g-modal"].join(" ")}
    open={isVisible}
  >
    <div className="mt-20">
      {data && data.length > 0 && (
        <Carousel responsive={responsiveArticles}>
          {data.map((item) => (
            <Suspense
              key={item?.id}
              fallback={<AppLoader className="font-28" />}
            >
              <>
                <PackageCard
                  key={item?.id}
                  packageData={item?.package ? item?.package : item}
                  teacher={item?.teacher}
                  item={item}
                />

                <Space className={styles.footButton} direction={"vertical"}>
                  <FlatButton
                    type={"Quaternary"}
                    className={styles.btnAttend}
                    title={"Attend Demo"}
                    onClick={() => handleAttendDemo(item?.id)}
                  />

                  <FlatButton
                    type={"Secondary"}
                    className={styles.btnReschedule}
                    title={"Reschedule Demo"}
                    onClick={() => handleDemoReschedule(item?.id)}
                  />
                </Space>
              </>
            </Suspense>
          ))}
        </Carousel>
      )}
    </div>
  </Modal>
);

export default React.memo(DemosModal);
